import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/recyclerdata';
import jiedao from '@/components/street/jiedao';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import ServiceStreet from '@/api/service';
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '',
      tableKey: 0,
      list: [
        /*{
        id:1,
        name:"wz",
        phone:"15811326418",
        street:"塘沽街道",
        entryTime:"2022-12-12 23:59:59",
        workNumber:27,
        num:20,
        registerNum:18,
        kitchenNum:100,
        visitNum:11,
        exchangeNum:12,
        recyclerNum:13,
        harmfulNum:14,
        comment:"bz",
        systemScore:116,
        auditScore:18,
        status:0
        },{
        id:2,
        name:"wz1",
        phone:"15811326419",
        street:"北塘街道",
        entryTime:"2022-12-12 23:59:59",
        workNumber:27,
        num:20,
        registerNum:18,
        kitchenNum:100,
        visitNum:11,
        exchangeNum:12,
        recyclerNum:13,
        harmfulNum:14,
        comment:"bz1",
        systemScore:116,
        auditScore:'',
        status:1
        }*/
      ],
      total: 0,
      listLoading: true,
      listQuery: {
        key: '',
        // 搜索
        currentPage: 1,
        numOfPage: 10,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1,
        street: '',
        monthDate: ""
      },
      listLotsend: [],
      // 一键发放及发放的集合
      streets: [],
      // 街道下拉列表
      defaultStreet: "泰达街道",
      // 默认街道
      yearOptions: [{
        name: '2022',
        value: "2022"
      }, {
        name: '2023',
        value: "2023"
      }, {
        name: '2024',
        value: "2024"
      }],
      Options: [{
        name: '1月',
        value: "01"
      }, {
        name: '2月',
        value: "02"
      }, {
        name: '3月',
        value: "03"
      }, {
        name: '4月',
        value: "04"
      }, {
        name: '5月',
        value: "05"
      }, {
        name: '6月',
        value: "06"
      }, {
        name: '7月',
        value: "07"
      }, {
        name: '8月',
        value: "08"
      }, {
        name: '9月',
        value: "09"
      }, {
        name: '10月',
        value: "10"
      }, {
        name: '11月',
        value: "11"
      }, {
        name: '12月',
        value: "12"
      }],
      dialogSettingFormVisible: false,
      settingform: {
        id: "",
        registerNum: "",
        kitchenNum: "",
        visitNum: "",
        exchangeNum: "",
        recyclerWeight: "",
        harmfulWeight: ""
      },
      rules: {
        registerNum: [{
          required: true,
          message: '注册人数是必填项'
        }],
        kitchenNum: [{
          required: true,
          message: '厨余分类袋数是必填项'
        }],
        visitNum: [{
          required: true,
          message: '上门拜访是必填项'
        }],
        exchangeNum: [{
          required: true,
          message: '积分核销是必填项'
        }],
        recyclerWeight: [{
          required: true,
          message: '可回收物回收重量是必填项'
        }],
        harmfulWeight: [{
          required: true,
          message: '有害垃圾回收重量是必填项'
        }]
      },
      dialogCheckFormVisible: false,
      checkform: {
        id: "",
        auditScore: "",
        //审核评分
        comment: ""
      },
      checkRules: {
        auditScore: [{
          required: true,
          message: '审核评分是必填项'
        }]
      }
    };
  },
  created: function created() {
    this.loadBaseWork();
    this.getJiedao(); // 街道列表
  },
  watch: {},
  methods: {
    renderheader: function renderheader(h, _ref) {
      var column = _ref.column,
          $index = _ref.$index;
      return h('span', {}, [h('span', {}, column.label.split('-')[0]), h('br'), h('span', {}, column.label.split('-')[1])]);
    },
    getJiedao: function getJiedao() {
      var _this = this;

      ServiceStreet.getArtjied().then(function (res) {
        _this.streets = res.data;
        _this.listQuery.street = _this.streets[0].street; // 街道默认选择第一个

        _this.getList();
      });
    },
    streetChange: function streetChange(street) {
      // 街道列表改变赋值
      this.listQuery.street = street;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      this.listQuery.monthDate = this.listQuery.year + "-" + this.listQuery.month;
      Service.getList(this.listQuery).then(function (response) {
        _this2.list = response.data.list;
        _this2.total = response.data.totalRows;
        _this2.listLoading = false;
      });
    },
    // 审核页面
    check: function check(row) {
      this.dialogCheckFormVisible = true; // console.log(row);

      this.checkform.id = row.id;

      if (row.auditScore == -1) {
        this.checkform.auditScore = 0;
      } else {
        this.checkform.auditScore = row.auditScore;
      }

      this.checkform.comment = row.comment;
    },
    // 审核页面-保存
    checkSave: function checkSave() {
      var _this3 = this;

      this.$refs['checkform'].validate(function (valid) {
        if (valid) {
          console.log(_this3.checkform);
          Service.checkSave(_this3.checkform).then(function (res) {
            _this3.$message({
              message: res.data.data,
              type: 'success'
            });

            _this3.dialogCheckFormVisible = false;

            _this3.getList();
          });
        }
      });
    },
    loadBaseWork: function loadBaseWork() {
      var _this4 = this;

      Service.settingLoad().then(function (response) {
        if (response.data.success == true) {
          _this4.settingform.id = response.data.id;
          _this4.settingform.registerNum = response.data.data.registerNum;
          _this4.settingform.kitchenNum = response.data.data.kitchenNum;
          _this4.settingform.visitNum = response.data.data.visitNum;
          _this4.settingform.exchangeNum = response.data.data.exchangeNum;
          _this4.settingform.recyclerWeight = response.data.data.recyclerWeight;
          _this4.settingform.harmfulWeight = response.data.data.harmfulWeight;
        }
      });
    },
    // 设置基础工作量
    settingBaseWork: function settingBaseWork() {
      var _this5 = this;

      this.dialogSettingFormVisible = true;
      /*this.settingform.id = "";
      this.settingform.registerNum = "";
      this.settingform.kitchenNum = "";
      this.settingform.visitNum = "";
      this.settingform.exchangeNum = "";
      this.settingform.recyclerWeight = "";
      this.settingform.harmfulWeight = "";*/

      Service.settingLoad().then(function (response) {
        if (response.data.success == true) {
          _this5.settingform.id = response.data.id;
          _this5.settingform.registerNum = response.data.data.registerNum;
          _this5.settingform.kitchenNum = response.data.data.kitchenNum;
          _this5.settingform.visitNum = response.data.data.visitNum;
          _this5.settingform.exchangeNum = response.data.data.exchangeNum;
          _this5.settingform.recyclerWeight = response.data.data.recyclerWeight;
          _this5.settingform.harmfulWeight = response.data.data.harmfulWeight;
        }
      });
    },
    // 设置基础工作量-保存
    settingSave: function settingSave() {
      var _this6 = this;

      this.$refs['settingform'].validate(function (valid) {
        if (valid) {
          Service.settingSave(_this6.settingform).then(function (res) {
            _this6.$message({
              message: res.data.data,
              type: 'success'
            });

            _this6.dialogSettingFormVisible = false;
          });
        }
      });
    },
    // 导出
    exportlist: function exportlist() {
      location.href = '/operation/recycler/excel/work/list?' + qs.stringify(this.listQuery);
    },
    // 选择的table复选框
    handleSelectionChange: function handleSelectionChange(rows) {
      var _this7 = this;

      this.listLotsend = [];
      rows.forEach(function (item, index) {
        _this7.listLotsend.push(item);
      });
      console.log(this.listLotsend);
    },
    // 发放
    send: function send(row) {
      var _this8 = this;

      var msg = "";

      if (row.auditScore != -1) {
        msg = "确认发放审核评分（" + row.auditScore + "分）？";
      } else {
        msg = "确认发放绩效评分（" + row.systemScore + "分）？";
      }

      this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.send(row).then(function (res) {
          _this8.$message({
            message: res.data.data,
            type: 'success'
          });

          _this8.dialogCheckFormVisible = false;

          _this8.getList();
        });
      }).catch(function () {
        /*this.$message({
        	message: '发放失败',
        	type: 'error'
        })*/
      });
    },
    // 一键发放
    lotsend: function lotsend() {
      var _this9 = this;

      if (this.listLotsend.length == 0) {
        this.$message({
          message: "请选择要发放绩效的督导员",
          type: 'error'
        });
        return;
      }

      var listLotsendValid = [];
      this.listLotsend.forEach(function (item, index) {
        if (item.status == 1) {
          listLotsendValid.push(item);
        }
      });

      if (listLotsendValid.length != 0) {
        var msg = "";
        console.log(listLotsendValid);
        listLotsendValid.forEach(function (item, index) {
          msg += item.name + ",";
        });
        this.$message({
          message: msg + "绩效已经发放",
          type: 'error'
        });
        return;
      }

      this.$confirm("确认发放督导员绩效吗", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        var ids = "";

        _this9.listLotsend.forEach(function (item, index) {
          ids += item.id + ",";
        });

        Service.lotsend(ids).then(function (res) {
          _this9.$message({
            message: res.data.data,
            type: 'success'
          });

          _this9.dialogCheckFormVisible = false;

          _this9.getList();
        });
      }).catch(function () {
        _this9.$message({
          message: '一键发放失败',
          type: 'error'
        });
      });
    }
  }
};