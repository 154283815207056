"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.number.constructor");

var _qs = _interopRequireDefault(require("qs"));

var _service = _interopRequireDefault(require("@/api/service"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _noeladdstreet = _interopRequireDefault(require("@/components/street/noeladdstreet"));

var _addrselect = _interopRequireDefault(require("@/components/street/addrselect"));

var _devicescore = _interopRequireDefault(require("@/api/devicescore"));

var _devicewarning = _interopRequireDefault(require("@/api/devicewarning"));

var _protodeliver = _interopRequireDefault(require("@/api/protodeliver"));

var _devicemouthpiece = _interopRequireDefault(require("@/api/devicemouthpiece"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var map = null;
var marker = null;
var _default = {
  name: 'ComplexTable',
  components: {
    Pagination: _Pagination.default,
    addrselect: _addrselect.default,
    noel: _noeladdstreet.default
  },
  directives: {
    waves: _waves.default
  },
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      maps: false,
      xiugaistatus: '',
      // statuadds: '',
      listsd: [{
        type: 0,
        info: '禁用'
      }, {
        type: 1,
        info: '已启用'
      }],
      lists: [],
      xinxi: '',
      dialog: false,
      forms: {
        pic: "",
        pass: '',
        name: '',
        lat: '',
        lon: '',
        mark: '',
        streetId: '',
        communityId: '',
        regionId: '',
        status: '',
        comment: '',
        number: '',
        address: '',
        type: '',
        rankingStatus: "",
        // 排行榜状态
        faceStatus: "",
        // 人脸状态
        mouthpieceTime: "",
        //投口开关时间设置
        logoutBackSecond: "",
        // 退出登录时间
        mainpageBackSecond: "",
        // 主页返回广告页时间
        faceBackSecond: "" // 人脸识别页面返回时间

      },
      dialogFormVisible: false,
      form: {
        id: '',
        property: '',
        // 物业
        housing: '' // 小区

      },
      tableKey: 0,
      list: [],
      total: 0,
      listLoad: true,
      listLoading: true,
      listQuery: {
        streetId: '',
        communityId: '',
        regionId: '',
        currentPage: 1,
        numOfPage: 20,
        type: '',
        key: ''
      },
      temp: {
        id: '',
        property: '',
        // 物业
        housing: '' // 小区

      },
      dialogVisible: false,
      detailData: {},
      name: '',
      rules: {
        property: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }],
        housing: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      },
      rule: {
        name: [{
          required: true,
          message: '设备名称是必填项'
        }],
        type: [{
          required: true,
          message: '设备类型是必填项'
        }],
        address: [{
          required: true,
          message: '具体地址是必填项'
        }],
        mouthpieceTime: [{
          required: true,
          message: "投口开关时间设置是必填项"
        }],
        logoutBackSecond: [{
          required: true,
          message: "退出登录时间设置是必填项"
        }],
        mainpageBackSecond: [{
          required: true,
          message: "主页返回广告页时间设置是必填项"
        }],
        faceBackSecond: [{
          required: true,
          message: "人脸识别页面返回时间是必填项"
        }],
        faceStatus: [{
          required: true,
          message: "人脸识别状态"
        }],
        rankingStatus: [{
          required: true,
          message: "排行榜状态"
        }],
        status: [{
          required: true,
          message: '状态是必填项'
        }]
      },
      addr: {
        community: '',
        number: '',
        region: '',
        street: '',
        streetId: '',
        communityId: '',
        regionId: ''
      },
      daochu: [],
      exporterweimas: [],
      yundayin: {
        mark: '',
        sn: '570200335',
        x: 30,
        y: 0
      },
      shangbaolist: {
        currentPage: 1,
        numOfPage: 20,
        binId: ''
      },
      jiedaoId: '',
      disdayin: false,
      xinxis: '',
      datalist: '',
      streetId: '',
      communityId: '',
      regionId: '',
      Options: [],
      kong: '',
      geocoder: '',
      marker: '',
      disscore: false,
      // 积分设置是否显示
      listScoreLoading: true,
      // 积分加载
      tableKeyScore: 0,
      listScore: [],
      ss: {
        scoreSetting: 1
      },
      // 默认单独配置
      mbId: "",
      // 编辑积分时赋值
      diswarning: false,
      // 满桶预警是否显示
      listWarningLoading: true,
      // 满桶预警加载
      tableKeyWarning: 0,
      // 表key
      listWarning: [],
      // 表数据
      disreport: false,
      // 投递次数报表是否显示
      listReportLoading: true,
      // 投递次数报表加载
      tableKeyReport: 0,
      // 投递次数报表key
      listReport: [],
      // 投递次数报表数据
      dismouthpiece: false,
      // 投口参数设置是否显示
      listMouthpieceLoading: true,
      // 投口参数设置加载
      tableKeyMouthpiece: 0,
      // 投口参数设置key
      listMouthpiece: [],
      // 投口参数设置数据
      devicedialog: false,
      // 设备图片弹框
      images: [],
      disabled: true,
      //禁用积分设置input开关
      typesOptions: [{
        // 厨余类型字典
        typeInfo: '厨余垃圾',
        type: 400
      }, {
        typeInfo: '其他垃圾',
        type: 405
      }, {
        typeInfo: '可回收物',
        type: 406
      }, {
        typeInfo: '有害垃圾',
        type: 407
      }],
      verifyUserOptions: [{
        // 设备投口验证用户列表
        label: "验证用户-需要",
        value: 1
      }, {
        // 设备投口验证用户列表
        label: "验证用户-不需要",
        value: 0
      }],
      dismouthpiecetime: false,
      // 设备投口添加时间
      mouthpiecetimeforms: {
        // 设备投口添加时间prop
        begTime: "",
        endTime: ""
      },
      mouthpiecetimeRow: "",
      // 设备投口添加时间row，用来赋值给行信息
      mbIdMouthpiece: "",
      // 设备投口设备编码
      disSync: false,
      // 信息同步到新设备
      syncforms: {
        newMbId: "",
        // 同步到新设备编码
        oldMbId: "" // 旧设备编码

      }
    };
  },
  watch: {
    "forms.rankingStatus": function formsRankingStatus(val) {
      if (val == 0) {
        this.forms.rankingStatus = 0;
      } else if (val == 1) {
        this.forms.rankingStatus = 1;
      }
    },
    "forms.faceStatus": function formsFaceStatus(val) {
      if (val == 0) {
        this.forms.faceStatus = 0;
      } else if (val == 1) {
        this.forms.faceStatus = 1;
      }
    },
    "forms.status": function formsStatus(val) {
      if (val == 0) {
        this.forms.status = 0;
      } else if (val == 1) {
        this.forms.status = 1;
      }
    },
    "ss.scoreSetting": function ssScoreSetting(val) {
      if (val == 0) {
        // 0 默认
        this.getScoreList("");
        this.disabled = true;
      } else {
        //1 单独设置积分
        this.getScoreList(this.mbId);
        this.disabled = false; // 单独设置
      }
    }
  },
  created: function created() {
    this.getList();
    this.getType();
  },
  methods: {
    exortlist: function exortlist() {
      location.href = '/operation/recycling/bins/recyclingBox?' + _qs.default.stringify(this.listQuery);
    },
    uploadImg: function uploadImg(data) {
      this.forms.pic = data.key;
      console.log(this.forms.pic); //console.log(this.forms.pic)
    },
    mapsd: function mapsd() {
      this.maps = true;
    },
    showInfoClick: function showInfoClick(e) {//console.log(e)
    },
    initMap: function initMap(lons, lats) {
      var newarrsd = [];
      map = null;
      marker = null;
      var self = this;
      marker, map = new AMap.Map('amapContainer', {
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        center: [lons, lats],
        resizeEnable: true,
        zoom: 14
      });
      newarrsd.push(lons, lats); // if(lons){

      marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: [lons, lats],
        offset: new AMap.Pixel(-20, -50)
      });
      marker.setMap(map);
      self.getmaps(newarrsd); // }else{
      // }

      map.on('click', function (e) {
        var arrs = [];
        arrs.push(e.lnglat.getLng(), e.lnglat.getLat());

        if (marker) {
          console.log(arrs);
          marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()]);
          self.getmaps(arrs);
        } else {
          console.log(arrs);
          marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: [e.lnglat.getLng(), e.lnglat.getLat()],
            offset: new AMap.Pixel(-20, -50)
          });
          marker.setMap(map);
          self.getmaps(arrs);
        }
      });
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: '天津市',
          suggest: self.forms.pass,
          input: 'pickerInput',
          placeSearchOptions: {
            map: map,
            pageSize: 10
          },
          searchResultsContainer: 'searchResults'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$notify({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.forms.lat = positionResult.position.lat;
          self.forms.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$notify({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        }); // 选取了某个POI

        poiPicker.on('poiPicked', function (poiResult) {
          var arrs = [];
          map.setCenter(poiResult.item.location); // positionPicker.start(poiResult.item.location)

          arrs.push(poiResult.item.location.lng, poiResult.item.location.lat);
          self.getmaps(arrs);

          if (marker) {
            marker.setPosition([poiResult.item.location.lng, poiResult.item.location.lat]);
          } else {
            marker = new AMap.Marker({
              icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              position: [poiResult.item.location.lng, poiResult.item.location.lat]
            });
            marker.setMap(map);
          }
        });
      }
    },
    getmaps: function getmaps(arrs) {
      console.log(arrs);
      var self = this;
      self.forms.lon = arrs[0];
      self.forms.lat = arrs[1];
      console.log(arrs);
      map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '天津'
        });
        geocoder.getAddress(arrs, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            console.log(status, result);
            self.forms.address = result.regeocode.formattedAddress;
          }
        });
      });
    },
    addpar: function addpar() {
      var self = this;

      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new AMap.Marker({
        position: new AMap.LngLat(this.forms.lon, this.forms.lat)
      });
      var lnglat = {};
      var arrs = [];
      lnglat.lng = Number(this.forms.lon);
      lnglat.lat = Number(this.forms.lat);
      arrs.push(this.forms.lon, Number(this.forms.lat));
      this.$emit("giveLnglat", lnglat);
      map.add(this.marker);
      map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '天津'
        });
        geocoder.getAddress(arrs, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            console.log(status, result);
            self.forms.address = result.regeocode.formattedAddress;
          }
        });
      });
      this.maps = false;
    },
    getType: function getType() {
      var _this = this;

      _service.default.getTypes().then(function (res) {
        res.data.forEach(function (item, index) {
          if (item.type != 20) {
            // 20,"DTU厨余投递套件"
            _this.Options.push(item);
          }
        });
      });
    },

    /*statuss(row) {
    	if (row == '已启用') {
    		this.statuadds = 1
    	} else {
    		this.statuadds = 0
    	}
    },*/
    handleSelectionChange: function handleSelectionChange(row) {
      var _this2 = this;

      this.daochu = [];
      row.forEach(function (item, index) {
        _this2.daochu.push(item.mark);
      }); // console.log(this.daochu)
    },
    shangbao: function shangbao(row) {
      var _this3 = this;

      this.xinxi = row; // console.log(this.xinxi)

      this.shangbaolist.binId = row.id;
      this.dialog = true;

      _service.default.shangbaocishu(this.shangbaolist).then(function (res) {
        // console.log(res.data.list)
        _this3.listLoad = false;
        _this3.lists = res.data.list;
      });
    },
    exporterweima: function exporterweima() {
      var _this4 = this;

      this.exporterweimas = [];
      this.daochu.forEach(function (item, index) {
        _this4.exporterweimas.push('TDKB' + item);

        if (_this4.exporterweimas.length == _this4.daochu.length) {
          setTimeout(function () {
            _this4.exporterweimas.forEach(function (item, index) {
              _service.default.exporterweima(item).then(function (res) {
                // console.log(res)
                var blob = new Blob([res.data], {
                  type: 'image/png'
                });

                if (window.navigator.msSaveOrOpenBlob) {
                  navigator.msSaveBlob(blob);
                } else {
                  var elink = document.createElement('a');
                  elink.download = '';
                  elink.style.display = 'none';
                  elink.href = URL.createObjectURL(blob);
                  document.body.appendChild(elink);
                  elink.click();
                  document.body.removeChild(elink);
                }
              });
            });
          }, 200);
        }
      });
    },
    listadd: function listadd() {
      this.getList();
    },
    noelchange: function noelchange(data) {
      this.listQuery.streetId = data.streetId;
      this.listQuery.communityId = data.communityId;
      this.listQuery.regionId = data.regionId;
    },
    addrChange: function addrChange(data) {
      console.log(data);
      this.addr.community = data.community;
      this.addr.number = data.floor;
      this.addr.region = data.region;
      this.addr.street = data.street;
      this.forms.streetId = data.streetId;
      this.forms.communityId = data.communityId;
      this.forms.regionId = data.regionId;
      this.forms.number = data.floor;
      this.addr.streetId = data.streetId;
      this.addr.communityId = data.communityId;
      this.addr.regionId = data.regionId;
    },
    Sure: function Sure() {
      var _this5 = this;

      if (this.xiugaistatus == true) {
        this.$refs['datas'].validate(function (valid) {
          if (valid) {
            _this5.forms.mixType = 1;

            _service.default.xiugaihuishouxiang(_this5.forms).then(function (res) {
              // console.log(res)
              if (res.data.data == '该设备名称已被使用!') {
                _this5.$notify({
                  title: '失败',
                  message: '该设备名称已被使用!',
                  type: 'error',
                  duration: 2000
                });
              } else if (res.data.data == '非运营无权操作') {
                _this5.$notify({
                  title: '失败',
                  message: '非运营无权操作',
                  type: 'error',
                  duration: 2000
                });
              } else {
                _this5.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success',
                  duration: 2000
                });

                _this5.dialogFormVisible = false;

                _this5.getList();
              }
            }).catch(function (res) {
              console.log(res);

              _this5.$notify({
                title: '失败',
                message: '设备名称重复',
                type: 'error',
                duration: 2000
              });
            });
          }
        });
      } else {
        console.log(this.forms);
        this.$refs['datas'].validate(function (valid) {
          if (valid) {
            console.log('添加');
            delete _this5.forms.mixType;

            _service.default.xiugaihuishouxiang(_this5.forms).then(function (res) {
              console.log(res);

              if (res.data.data == '该设备名称已被使用!') {
                _this5.$notify({
                  title: '失败',
                  message: '该设备名称已被使用!',
                  type: 'error',
                  duration: 2000
                });
              } else if (res.data.data == '非运营无权操作') {
                _this5.$notify({
                  title: '失败',
                  message: '非运营无权操作',
                  type: 'error',
                  duration: 2000
                });
              } else {
                _this5.$notify({
                  title: '成功',
                  message: '添加成功',
                  type: 'success',
                  duration: 2000
                });

                _this5.dialogFormVisible = false;

                _this5.getList();
              }
            }).catch(function (res) {
              console.log(res);

              _this5.$notify({
                title: '失败',
                message: '设备名称重复',
                type: 'error',
                duration: 2000
              });
            });
          }
        });
      } // console.log(this.forms)

    },
    addMarks: function addMarks(lon, lat) {
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    close: function close() {
      this.dialogVisible = false;
    },
    getList: function getList() {
      var _this6 = this;

      this.listLoading = true;

      _service.default.huishouxiangList(this.listQuery).then(function (response) {
        _this6.list = response.data.list;
        _this6.total = response.data.totalRows; // Just to simulate the time of the request

        _this6.listLoading = false;
      });
    },
    save: function save() {
      var data = this.form;
    },
    add: function add() {
      var _this7 = this;

      this.addr.region = '';
      this.addr.community = '';
      this.addr.street = '';
      this.addr.floor = '';
      this.xiugaistatus = false;
      this.dialogFormVisible = true; // this.dialogVisible = true

      this.forms.pic = '';
      this.forms.type = ''; // this.forms.address = ''

      this.forms.address = "天津市滨海新区北塘经济区梅州道";
      this.forms.mark = '';
      this.forms.number = '';
      this.forms.name = '';
      this.forms.regionId = '';
      this.forms.communityId = '';
      this.forms.regionId = '';
      this.forms.comment = '';
      this.forms.status = '1'; // this.statuadds = ''

      this.forms.faceStatus = "1";
      this.forms.rankingStatus = "1";
      this.forms.mouthpieceTime = "5,15,5";
      this.forms.logoutBackSecond = 240;
      this.forms.mainpageBackSecond = 30;
      this.forms.faceBackSecond = 60;
      setTimeout(function (res) {
        _this7.initMap(117.704066, 39.021119); // map.on('click', (e) => {
        // this.form.lon = e.lnglat.getLng()
        // this.form.lat = e.lnglat.getLat()
        // })

      }, 500);
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    edit: function edit(row) {
      var _this8 = this;

      console.log(row);
      this.dialogFormVisible = true;
      this.xiugaistatus = true;

      if (row.kitchenRegion) {
        this.addr.region = row.kitchenRegion.region;
        this.addr.street = row.kitchenRegion.street;
        this.addr.community = row.kitchenRegion.community;
        this.addr.number = row.number;
        this.addr.streetId = row.streetId;
        this.addr.communityId = row.communityId;
        this.addr.regionId = row.regionId;
      } else {
        this.addr.region = '';
        this.addr.street = '';
        this.addr.community = '';
        this.addr.number = '';
      }

      this.forms.streetId = row.streetId;
      this.forms.communityId = row.communityId;
      this.forms.regionId = row.regionId;
      this.streetId = row.streetId;
      this.communityId = row.communityId;
      this.regionId = row.regionId;
      this.forms.type = row.type;
      this.forms.lon = row.lon;
      this.forms.lat = row.lat;
      this.forms.pic = row.pic;
      this.forms.mark = row.mark;
      this.forms.address = row.address;
      this.kong = '';
      setTimeout(function (res) {
        if (row.lon == 0) {
          _this8.initMap(117.704066, 39.021119);
        } else {
          _this8.initMap(row.lon, row.lat);
        } // map.on('click', (e) => {
        // this.form.lon = e.lnglat.getLng()
        // this.form.lat = e.lnglat.getLat()
        // })

      }, 500);
      console.log(this.forms);

      if (row.status == 0) {
        this.forms.status = 0; //this.statuadds = 0
      } else {
        this.forms.status = 1; //this.statuadds = 1
      }

      this.forms.rankingStatus = row.rankingStatus;
      this.forms.faceStatus = row.faceStatus;
      this.forms.mouthpieceTime = row.mouthpieceTime;
      this.forms.logoutBackSecond = row.logoutBackSecond;
      this.forms.mainpageBackSecond = row.mainpageBackSecond;
      this.forms.faceBackSecond = row.faceBackSecond;
      this.forms.name = row.name;
      this.forms.comment = row.comment;
      console.log(this.forms);
    },
    danger: function danger(row) {
      var _this9 = this;

      _service.default.checkRecyclingBins(row.id).then(function (res) {
        console.log(res);

        if (res.data.statusCode == 503) {
          _this9.$message({
            title: '成功',
            message: '当前设备已关联其他数据暂不能删除',
            type: 'error',
            duration: 2000
          });
        } else {
          _this9.$confirm('确定要删除这条设备？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }).then(function () {
            _service.default.dangerhuishou(row.id).then(function (res) {
              _this9.$message({
                title: '成功',
                message: '删除成功',
                type: 'success',
                duration: 2000
              });

              _this9.getList();
            });
          }).catch(function () {
            _this9.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        }
      });
    },
    dayin: function dayin() {
      this.yundayin.mark = this.xinxis.mark;

      _service.default.dayinhuishouxiang(this.yundayin).then(function (res) {// console.log(res)
      });
    },
    xiazai: function xiazai() {
      this.exporterweimas = '';
      this.exporterweimas = 'TDKB' + this.xinxis.mark;
      location.href = '/open/qrcode/picture/bin?content=' + this.exporterweimas; // Service.exporterweima(this.exporterweimas).then((res)=>{
      // 					  // console.log(res)
      // 					  const blob = new Blob([res.data], {
      // 						type: 'image/png'
      // 					  })
      // 					  if (window.navigator.msSaveOrOpenBlob) {
      // 						navigator.msSaveBlob(blob)
      // 					  } else {
      // 						const elink = document.createElement('a')
      // 						elink.download = ''
      // 						elink.style.display = 'none'
      // 						elink.href = URL.createObjectURL(blob)
      // 						document.body.appendChild(elink)
      // 						elink.click()
      // 						document.body.removeChild(elink)
      // 						}
      // 						})
    },
    erweima: function erweima(row) {
      this.disdayin = true;
      this.xinxis = row;
    },
    getScoreList: function getScoreList(mbId) {
      var _this10 = this;

      // 积分设置查询
      this.listScoreLoading = true;

      _devicescore.default.getList(mbId).then(function (res) {
        _this10.listScore = res.data.data;
      });

      this.listScoreLoading = false;
    },
    scoreSetting: function scoreSetting(row) {
      this.disscore = true;
      this.ss.scoreSetting = row.scoreSetting;
      this.mbId = row.mark;

      if (row.scoreSetting == 0) {
        this.getScoreList("");
      } else {
        this.getScoreList(row.mark);
      }
    },
    SureScore: function SureScore() {
      var _this11 = this;

      var self = this;
      this.listScore.forEach(function (e) {
        e.mbId = _this11.mbId;
        e.scoreSetting = _this11.ss.scoreSetting;
      });
      console.log(this.listScore);
      var j = JSON.stringify(this.listScore);

      if (this.ss.scoreSetting == 0) {
        _devicescore.default.updateRecyclingBinsScoreSettingBymbId({
          "mbId": this.mbId,
          "scoreSetting": this.ss.scoreSetting
        }).then(function (res) {
          if (res.data.status == 0) {
            self.$message({
              type: 'success',
              message: '修改成功!'
            });

            _this11.getList();

            _this11.disscore = false;
          } else {
            self.$message({
              type: 'success',
              message: '修改失败!'
            }); // this.getList()
          }
        });
      } else {
        _devicescore.default.updateRecyclingBinsScore(j).then(function (res) {
          if (res.data.status == 0) {
            self.$message({
              type: 'success',
              message: '修改成功!'
            });

            _this11.getList();

            _this11.disscore = false;
          } else {
            self.$message({
              type: 'success',
              message: '修改失败!'
            }); // this.getList()
          }
        });
      }
    },
    cancle: function cancle() {
      // 设置积分取消
      // this.getScoreList(this.mbId);
      this.disscore = false;
    },
    // 满桶预警
    getWarnView: function getWarnView(row) {
      var _this12 = this;

      this.diswarning = true;
      this.listWarningLoading = true;

      _devicewarning.default.getWarnView(row.mark).then(function (res) {
        console.log(res.data.data);
        _this12.listWarning = res.data.data;
      });

      this.listWarningLoading = false;
    },
    // 设备投递记录次数报表
    getProtoDeliverCount: function getProtoDeliverCount(row) {
      var _this13 = this;

      this.disreport = true;
      this.listReportLoading = true;

      _protodeliver.default.getProtoDeliverCount(row.mark).then(function (res) {
        console.log(res.data);
        _this13.listReport = res.data.data;
      });

      this.listReportLoading = false;
    },
    // 设备投口参数设置
    getMouthpieceList: function getMouthpieceList(row) {
      var _this14 = this;

      this.dismouthpiece = true;
      this.listMouthpieceLoading = true;
      this.mbIdMouthpiece = row.mark;

      _devicemouthpiece.default.getByMbId(row.mark).then(function (res) {
        //console.log(res.data.data);
        _this14.listMouthpiece = res.data.data;

        _this14.listMouthpiece.forEach(function (item, index) {
          var time = [];

          if (item.time != '' && item.time != null) {
            var strTimes = item.time.split(",");

            for (var i = 0; i < strTimes.length; i++) {
              var begTime = strTimes[i].split('-')[0];
              var endTime = strTimes[i].split('-')[1];
              time.push({
                "begTime": begTime,
                "endTime": endTime
              });
            }
          }

          item.time = time;
        });
      });

      this.listMouthpieceLoading = false;
    },
    addDeviceTimeDialog: function addDeviceTimeDialog(row) {
      // 添加投口投递时间显示
      this.dismouthpiecetime = true;
      this.mouthpiecetimeRow = row;
    },
    addMouthpiecetimeConfirm: function addMouthpiecetimeConfirm() {
      var _this15 = this;

      // 添加定时投递确认
      if (this.mouthpiecetimeforms.begTime == "" || this.mouthpiecetimeforms.begTime == null) {
        this.$message({
          type: 'error',
          message: '开始时间不能不能为空!'
        });
        return;
      }

      if (this.mouthpiecetimeforms.endTime == "" || this.mouthpiecetimeforms.endTime == null) {
        this.$message({
          type: 'error',
          message: '结束时间不能不能为空!'
        });
        return;
      }

      if (this.mouthpiecetimeforms.begTime >= this.mouthpiecetimeforms.endTime) {
        this.$message({
          type: 'error',
          message: '开始时间不能大于等于结束时间!'
        });
        return;
      }

      this.listMouthpiece.forEach(function (item, index) {
        if (item.num == _this15.mouthpiecetimeRow.num) {
          // 判断投口来给某个行赋值
          item.time.push({
            "begTime": _this15.mouthpiecetimeforms.begTime,
            "endTime": _this15.mouthpiecetimeforms.endTime
          });
        }
      });
      this.dismouthpiecetime = false; // console.log(this.listMouthpiece);
    },
    delMouthpiecetimeConfirm: function delMouthpiecetimeConfirm(time, i) {
      // 删除定时投递确认
      time.splice(i, 1);
    },
    copyMouthpiece: function copyMouthpiece() {
      // 复制设备投口信息
      console.log(this.$refs.listMouthpieceRef.selection);
      var list = this.$refs.listMouthpieceRef.selection;

      if (list.length != 1) {
        this.$message({
          type: "error",
          message: "请选择一条数据进行复制操作！"
        });
        return;
      } //  复制基本信息


      var copyRow = {};
      copyRow.mbId = list[0].mbId;
      copyRow.type = list[0].type;
      copyRow.typeInfo = list[0].typeInfo;
      copyRow.verifyUserStatus = list[0].verifyUserStatus;
      copyRow.time = []; // 查找最大的投口

      copyRow.num = Number(this.listMouthpiece[this.listMouthpiece.length - 1].num) + 1;
      this.listMouthpiece.push(copyRow);
      console.log(this.listMouthpiece);
    },
    deleteMouthpiece: function deleteMouthpiece() {
      var _this16 = this;

      this.$confirm('确定要清空数据重新配置投口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _devicemouthpiece.default.updateRecyclingBinsMouthpiece(_this16.mbIdMouthpiece).then(function (res) {
          _this16.$message({
            title: '成功',
            message: '重置成功',
            type: 'success',
            duration: 2000
          });

          _this16.listMouthpiece = [];
        });
      }).catch(function () {
        _this16.$message({
          type: 'info',
          message: '已取消重置'
        });
      });
    },
    addMouthpiece: function addMouthpiece() {
      // 添加设备投口信息
      //  复制基本信息
      var insertRow = {};
      insertRow.mbId = this.mbIdMouthpiece;
      insertRow.type = 400;
      insertRow.typeInfo = '厨余垃圾';
      insertRow.verifyUserStatus = 1;
      insertRow.time = []; // 查找最大的投口

      if (this.listMouthpiece.length == 0) {
        insertRow.num = 1;
      } else {
        insertRow.num = Number(this.listMouthpiece[this.listMouthpiece.length - 1].num) + 1;
      }

      this.listMouthpiece.push(insertRow);
      console.log(this.listMouthpiece);
    },
    mouthpieceConfirm: function mouthpieceConfirm() {
      var _this17 = this;

      var j = JSON.stringify(this.listMouthpiece);

      _devicemouthpiece.default.batch(j).then(function (res) {
        if (res.data.status == 0) {
          _this17.$message({
            type: "success",
            message: "设备投口参数设置成功!"
          });
        } else {
          _this17.$message({
            type: "error",
            message: "设备投口参数设置失败!"
          });
        }
      });

      this.dismouthpiece = false; // 关闭设备投口
    },
    typesChange: function typesChange(row) {
      // 投递类型为厨余垃圾，验证用户默认为需要,同时修改typeInfo
      this.typesOptions.forEach(function (item, index) {
        if (row.type == item.type) {
          row.typeInfo = item.typeInfo;
        }
      });
      console.log(row);

      if (row.type == 400) {
        row.verifyUserStatus = 1;
      }
    },
    imgs: function imgs(e, index) {
      var _this18 = this;

      // 查看设备图片
      this.images = [];
      console.log(e);
      this.devicedialog = true;
      setTimeout(function () {
        _this18.images.push(e); // 单个文件


        _this18.$refs.carousel.setActiveItem(index);
      }, 200);
    },
    changeType: function changeType(v) {
      // add or update 设备类型改变对应字段隐藏
      this.forms.faceStatus = "1";
      this.forms.rankingStatus = "1";
      this.forms.mouthpieceTime = "5,15,5";
      this.forms.logoutBackSecond = 240;
      this.forms.mainpageBackSecond = 30;
      this.forms.faceBackSecond = 60;
    },
    // 信息同步到新设备
    getSync: function getSync(row) {
      var _this19 = this;

      this.syncforms.newMbId = "";
      this.$confirm('确定当前设备已无法正常使用？需要将信息迁移到新设备上？请谨慎操作！！！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this19.syncforms.oldMbId = row.mark;
        _this19.disSync = true;
      }).catch(function () {
        _this19.$message({
          type: 'info',
          message: '已取消更换'
        });
      });
    },
    // 同步到新设备编码
    addSyncConfirm: function addSyncConfirm() {
      var _this20 = this;

      if (this.syncforms.oldMbId == "" || this.syncforms.oldMbId == null) {
        this.$message({
          type: 'error',
          message: '旧设备编码不能为空!'
        });
        return;
      }

      if (this.syncforms.newMbId == "" || this.syncforms.newMbId == null) {
        this.$message({
          type: 'error',
          message: '新设备编码不能为空!'
        });
        return;
      }

      _service.default.updateRecyclingBinsMbIdChange(this.syncforms).then(function (res) {
        console.log(res);

        if (res.data.statusCode != 200) {
          _this20.$notify({
            title: '失败',
            message: res.data.error,
            type: 'error',
            duration: 2000
          });
        } else {
          _this20.$notify({
            title: '成功',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });

          _this20.disSync = false;

          _this20.getList();
        }
      });
    },
    viewLog: function viewLog(rows) {
      this.$router.push({
        path: "devicelog",
        query: {
          mark: rows.mark
        }
      });
    }
  }
};
exports.default = _default;