import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
import Service from '@/api/service';
var map = null;
export default {
  name: 'ComplexTable',
  filters: {},
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      zhinenglist: [],
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 1000
      }
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getZhinengList();
    this.initMap();
  },
  methods: {
    initMap: function initMap() {
      map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        zoom: 10
      });
    },
    addMarks: function addMarks(lon, lat, type) {
      var icon = new AMap.Icon({
        // 图标尺寸
        // size: new AMap.Size(25, 34),
        // 图标的取图地址
        image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png' // 图标所用图片大小
        // imageSize: new AMap.Size(135, 40),
        // 图标取图偏移量
        // imageOffset: new AMap.Pixel(-9, -3)

      });

      if (type == 1) {
        icon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(60, 101),
          // 图标的取图地址
          image: '/static/images/tong.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(25, 34)
        });
      } else if (type == 2) {
        icon = new AMap.Icon({
          // 图标尺寸
          size: new AMap.Size(186, 190),
          // 图标的取图地址
          image: '/static/images/zhineng.png',
          // 图标所用图片大小
          imageSize: new AMap.Size(25, 25)
        });
      }

      var marker = new AMap.Marker({
        icon: icon,
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    getList: function getList() {
      var _this = this;

      var self = this;
      Service.huishouxiangList(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.totalRows;

        _this.list.forEach(function (item) {
          self.addMarks(item.lon, item.lat, 1);
        });
      });
    },
    getZhinengList: function getZhinengList() {
      var _this2 = this;

      var self = this;
      Service.getbinsList(this.listQuery).then(function (response) {
        console.log(response.data.list);
        _this2.zhinenglist = response.data.list;

        _this2.zhinenglist.forEach(function (item) {
          self.addMarks(item.deviceGpsLng, item.deviceGpsLat, 2);
        });
      });
    }
  }
};