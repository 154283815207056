import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/Input/Inputnum.js';
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, _defineProperty(_statusMap, "10", 'primary'), _defineProperty(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '1': '派单中',
        '5': '回收中',
        '10': '已完成',
        '-1': '已删除'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      lists: {
        num: ''
      },
      dialoges: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        status: 0
      },
      beizhuTemp: {
        id: '',
        remark: ''
      },
      Options: [{
        name: '待处理',
        value: 0
      }, {
        name: '已标记',
        value: 1
      }, {
        name: '已删除',
        value: 2
      }],
      Option: [{
        name: '全部',
        value: 0
      }, {
        name: '快速订单',
        value: 2
      }, {
        name: '预约订单',
        value: 5
      }, {
        name: '自助订单',
        value: 11
      }],
      dialogs: false,
      alertlist: ''
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    biaoji: function biaoji(e, a) {
      var _this = this;

      if (a == 1) {
        this.$confirm('是否将本条消息移入“已标记”列表?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.websitedit({
            id: e.id,
            status: 1
          }).then(function (res) {
            _this.$message({
              type: 'success',
              message: '标记成功'
            });

            _this.getList();
          });
        }).catch(function () {
          _this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else if (a == 2) {
        this.$confirm('是否将本条消息移入“已删除”列表?', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.websitedit({
            id: e.id,
            status: 2
          }).then(function (res) {
            _this.$message({
              type: 'success',
              message: '删除成功'
            });

            _this.getList();
          });
        }).catch(function () {
          _this.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        Service.websitedit({
          id: e.id,
          status: 0
        }).then(function (res) {
          _this.$message({
            type: 'success',
            message: '取消标记成功'
          });

          _this.getList();
        });
      }
    },
    shezhiprint: function shezhiprint() {
      var _this2 = this;

      console.log(this.lists);
      Service.shebeiprint(this.lists).then(function (res) {
        console.log(res);

        if (res.data.data) {
          _this2.dialoges = false;

          _this2.$notify({
            title: '成功',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });
        } else {
          _this2.dialoges = false;

          _this2.$notify({
            title: '失败',
            message: res.data.error,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    zizhu: function zizhu() {
      this.lists.num = '';
      this.dialoges = true;
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = e.goods;
    },
    exportExcel: function exportExcel() {
      location.href = '/operation/order/export?' + qs.stringify(this.listQuery);
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this3 = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this3.initMap(row.lon, row.lat);
      }, 500);
    },
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      Service.websitlist(this.listQuery).then(function (response) {
        console.log(response);
        _this4.list = response.data.data.list;
        _this4.total = response.data.data.totalRows;
        setTimeout(function () {
          _this4.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    beizhu: function beizhu(row) {
      this.beizhuTemp.remark = row.remark || '';
      this.beizhuTemp.id = row.id;
      this.beizhuVisible = true;
    },
    doRemark: function doRemark() {
      var _this5 = this;

      Service.editwebsit(this.beizhuTemp).then(function (res) {
        _this5.beizhuVisible = false; // if (res.data.statusCode == 200) {

        _this5.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        _this5.getList(); // }

      });
    },
    chongpai: function chongpai(row) {
      var _this6 = this;

      Service.orderRecover(row.id).then(function (res) {
        if (res.data.statusCode) {
          _this6.$notify({
            title: '成功',
            message: '重派成功',
            type: 'success',
            duration: 2000
          });

          _this6.getList();
        }
      });
    },
    deleteOrder: function deleteOrder(row) {
      var _this7 = this;

      this.$confirm('此操作将永久删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.orderDel(row.id).then(function (res) {
          _this7.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};