import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination';
import dizhiService from '@/api/banner';
export default {
  name: 'Shequ',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      rules: {
        name: [{
          required: true,
          message: "奖品名称是必填项"
        }],
        pic: [{
          required: true,
          message: "奖品图片是必选项"
        }],
        number: [{
          required: true,
          message: "奖品数量是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必选项"
        }],
        status: [{
          required: true,
          message: "状态是必选项"
        }]
      },
      SureStreet: null,
      communityList: [{
        region: '上架',
        id: 1
      }, {
        region: '下架',
        id: 0
      }],
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        street: '',
        currentPage: 1,
        numOfPage: 20
      },
      id: null,
      street: null,
      // 存放街道数据
      searchStreet: null,
      // 存放查询街道数据
      form: {
        pic: '',
        name: '',
        number: '',
        status: '',
        street: '',
        id: ''
      },
      titles: '添加商品'
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    },
    SureStreet: function SureStreet(val) {
      if (val) {
        console.log(val);
        val.street ? this.form.street = val.street : this.form.street = val;
      }
    },
    allchecked: function allchecked(val) {
      var _this = this;

      this.streetlist.forEach(function (item, index) {
        _this.allchecked ? item.checked = true : item.checked = false;
      });
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    uploadImg: function uploadImg(data) {
      this.form.pic = data.key;
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.getluckList(this.listQuery).then(function (res) {
        console.log(res);
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
        _this2.listLoading = false;
      });
    },
    add: function add() {
      var _this3 = this;

      this.titles = '添加商品';
      this.dialogVisible = true;
      this.SureStreet = null;
      Object.keys(this.form).forEach(function (key) {
        _this3.form[key] = '';
      });
    },
    showUpdate: function showUpdate(row) {
      var _this4 = this;

      this.SureStreet = row.street;
      Object.keys(this.form).forEach(function (key) {
        _this4.form[key] = row[key];
      });
      this.titles = '修改商品';
      this.dialogVisible = true;
    },
    save: function save() {
      var _this5 = this;

      console.log(this.form); // if(!this.SureStreet){
      // 	this.$notify({
      // 		title: '失败',
      // 		message: '请选择街道',
      // 		type: 'error',
      // 		duration: 2000
      // 	})
      // 	return false
      // }

      var data = this.form;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (data.id) {
            delete data.createTime;
            delete data.serialNumber;
            delete data.updateTime;
            Service.editluckshop(data).then(function (res) {
              _this5.dialogVisible = false;

              _this5.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success',
                duration: 2000
              });

              _this5.getList();
            });
          } else {
            delete data.id;
            Service.addluckshop(data).then(function (res) {
              if (res.data.error) {
                _this5.$notify({
                  title: '失败',
                  message: res.data.error,
                  type: 'error',
                  duration: 2000
                });

                return false;
              }

              _this5.dialogVisible = false;

              _this5.$notify({
                title: '成功',
                message: '新增成功',
                type: 'success',
                duration: 2000
              });

              _this5.getList();
            });
          }
        }
      });
    },
    del: function del(row) {
      var _this6 = this;

      console.log(row);
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        dizhiService.delShequ(row.id).then(function (res) {
          if (res.data.body.data != 'ok') {
            _this6.$message({
              type: 'error',
              message: res.data.body.data
            });

            return false;
          }

          _this6.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this6.getList();
        });
      }).catch(function () {});
    }
  }
};