var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.street,
              callback: function ($$v) {
                _vm.street = $$v
              },
              expression: "street",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                "value-key": "id",
                filterable: "",
                placeholder: "请选择问题状态",
                clearable: true,
              },
              on: { change: _vm.getList },
              model: {
                value: _vm.listQuery.problemState,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "problemState", $$v)
                },
                expression: "listQuery.problemState",
              },
            },
            _vm._l(_vm.streetList, function (obj) {
              return _c("el-option", {
                key: obj.type,
                attrs: { label: obj.text, value: obj.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入设备名称或督导员姓名查询" },
            model: {
              value: _vm.listQuery.searchData,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "searchData", $$v)
              },
              expression: "listQuery.searchData",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary" },
              on: { click: _vm.getList },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("deviceshangbao:export")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportList },
                },
                [_vm._v("导出")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("deviceshangbao:pic")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportPic },
                },
                [_vm._v("导出图片")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.deviceName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备码", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mbId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "巡查图片", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "touxiang" },
                      _vm._l(scope.row.pics, function (item) {
                        return _c("img", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: { src: "http://pic.touduila.com/" + item },
                          on: {
                            click: function ($event) {
                              return _vm.dakai(scope.row.pics)
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "问题描述", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.issue))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备地址", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.deviceAddress))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导员", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.userName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上报时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updateTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "问题状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.problemState == 1
                      ? _c("span", [_vm._v("未解决")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.problemState == 2
                      ? _c("span", [_vm._v("已解决")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "解决时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.completeDate))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "问题修复图片", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "touxiang" }, [
                      scope.row.repairPic
                        ? _c("img", {
                            staticStyle: { width: "100px", height: "100px" },
                            attrs: {
                              src:
                                "http://pic.touduila.com/" +
                                scope.row.repairPic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.dakai(scope.row.repairPic)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "问题修复上报人", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.repairName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "问题进展", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.problemDesc))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("deviceshangbao:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.problemState == 1 &&
                    _vm.hasPerm("deviceshangbao:solve")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.SureUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("解决")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.problemState == 2 &&
                    _vm.hasPerm("deviceshangbao:solve")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "info" },
                            on: {
                              click: function ($event) {
                                return _vm.SureUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("解决")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: "编辑" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "dataForm", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "问题进展" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.desc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "desc", $$v)
                      },
                      expression: "form.desc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.dialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _vm._l(_vm.imgs, function (item) {
            return _c("img", {
              staticStyle: { width: "500px", height: "500px" },
              attrs: { src: "http://pic.touduila.com/" + item },
            })
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请先选择问题修复图片然后确定解决",
            visible: _vm.dialogsd,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogsd = $event
            },
          },
        },
        [
          _vm.uploadPic.length < 1
            ? _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    action: "/operator/tools/upload",
                    "list-type": "picture-card",
                    name: "photo",
                    "show-file-list": false,
                    "on-success": _vm.uploadImg,
                    "auto-upload": true,
                  },
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                  _c("strong"),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "el-upload-list el-upload-list--picture-card" },
            _vm._l(_vm.uploadPic, function (item) {
              return _c(
                "li",
                { staticClass: "el-upload-list__item is-success" },
                [
                  _c("img", {
                    staticClass: "el-upload-list__item-thumbnail",
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "el-upload-list__item-actions" }, [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload-list__item-delete",
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(item)
                          },
                        },
                      },
                      [_c("i", { staticClass: "el-icon-delete" })]
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogsd = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Surejiejue } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }