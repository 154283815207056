var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入设备编码" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n\t\t\t查询\n\t\t\t")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { margin: "auto" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "行动站ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.siteId)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备编码" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.mbId)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.deviceName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _c("span", [_vm._v(_vm._s(scope.row.street) + " -")]),
                      _vm._v(" "),
                      scope.row.community
                        ? _c("span", [
                            _vm._v(_vm._s(scope.row.community) + "-"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      scope.row.region
                        ? _c("span", [_vm._v(" " + _vm._s(scope.row.region))])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.updateTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打印扣积分", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.stickerPoints))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排行榜", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.rank == 0
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("开启"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.rank == 1
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("关闭"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "标牌回收", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.bind == 0
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("开启"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.bind == 1
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("关闭"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收打印机", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.print == 0
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("开启"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.print == 1
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("关闭"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备状态", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.state == 0
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("开启"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == 1
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("关闭"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "称重拍照", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.weightPic == 0
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("开启"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.weightPic == 1
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v("关闭"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("Recyclableequipment:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("Recyclableequipment:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "安卓屏设备编辑", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "125px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备编码",
                    "label-width": _vm.formLabelWidth,
                    prop: "mbId",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.mbId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mbId", $$v)
                      },
                      expression: "form.mbId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "deviceName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.deviceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceName", $$v)
                      },
                      expression: "form.deviceName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "关联行动站",
                    "label-width": _vm.formLabelWidth,
                    prop: "siteId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      on: {
                        change: function ($event) {
                          return _vm.getChangeSite($event)
                        },
                      },
                      model: {
                        value: _vm.form.siteId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "siteId", $$v)
                        },
                        expression: "form.siteId",
                      },
                    },
                    _vm._l(_vm.sites, function (item) {
                      return _c("el-option", {
                        key: item.uuid,
                        attrs: { label: item.stationName, value: item.uuid },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地址", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "street", $$v)
                      },
                      expression: "form.street",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.community,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "community", $$v)
                      },
                      expression: "form.community",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "联系电话",
                    "label-width": _vm.formLabelWidth,
                    prop: "phone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "账户退出系统时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "homepage",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.homepage,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "homepage", $$v)
                      },
                      expression: "form.homepage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "其他页面返回时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "subpage",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.subpage,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "subpage", $$v)
                      },
                      expression: "form.subpage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "资源回收页面返回时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "resourcepage",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "120" },
                    model: {
                      value: _vm.form.resourcepage,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "resourcepage", $$v)
                      },
                      expression: "form.resourcepage",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-纸张大小",
                    "label-width": _vm.formLabelWidth,
                    prop: "labelBegin",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "400,300" },
                    model: {
                      value: _vm.form.labelBegin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "labelBegin", $$v)
                      },
                      expression: "form.labelBegin",
                    },
                  }),
                  _vm._v("输入宽高格式如： 400,300\n\t\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-二维码位置",
                    "label-width": _vm.formLabelWidth,
                    prop: "qrCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "36,95,3" },
                    model: {
                      value: _vm.form.qrCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "qrCode", $$v)
                      },
                      expression: "form.qrCode",
                    },
                  }),
                  _vm._v(
                    "打印位置坐标 和打印大小 如：x,y,5 (数大打印的越大)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-订单ID",
                    "label-width": _vm.formLabelWidth,
                    prop: "nameText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "150,95,1,0" },
                    model: {
                      value: _vm.form.nameText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nameText", $$v)
                      },
                      expression: "form.nameText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-电话",
                    "label-width": _vm.formLabelWidth,
                    prop: "phoneText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "20,50,1,0" },
                    model: {
                      value: _vm.form.phoneText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phoneText", $$v)
                      },
                      expression: "form.phoneText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-行动站",
                    "label-width": _vm.formLabelWidth,
                    prop: "stationNameText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "详细位置",
                      value: "15,225,1,0",
                    },
                    model: {
                      value: _vm.form.stationNameText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stationNameText", $$v)
                      },
                      expression: "form.stationNameText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-下单时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "createTimeText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "15,260,1,0" },
                    model: {
                      value: _vm.form.createTimeText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "createTimeText", $$v)
                      },
                      expression: "form.createTimeText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-回收重量",
                    "label-width": _vm.formLabelWidth,
                    prop: "weightText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "150,140,1,0" },
                    model: {
                      value: _vm.form.weightText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "weightText", $$v)
                      },
                      expression: "form.weightText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机参数-预估金额",
                    "label-width": _vm.formLabelWidth,
                    prop: "estimateText",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", value: "150,185,1,0" },
                    model: {
                      value: _vm.form.estimateText,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "estimateText", $$v)
                      },
                      expression: "form.estimateText",
                    },
                  }),
                  _vm._v(
                    "格式：x,y,5,0 (横坐标,纵坐标,字体大小,是否翻转)\n\t\t\t\t"
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备照片",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "http://pic.touduila.com/" + item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "待机图片",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: {
                        src: "https://pic.touduila.com/huishou_banner3.jpg",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/huishou_banner3.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/huishou_banner3.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v =
                                "https://pic.touduila.com/huishou_banner3.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: {
                        src: "https://pic.touduila.com/xianxuanxing.jpg",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/xianxuanxing.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/xianxuanxing.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v =
                                "https://pic.touduila.com/xianxuanxing.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: {
                        src: "https://pic.touduila.com/huishou_banner1%20.jpg",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value:
                          "https://pic.touduila.com/huishou_banner1%20.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/huishou_banner1%20.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v =
                                "https://pic.touduila.com/huishou_banner1%20.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: { src: "https://pic.touduila.com/xd_banner.jpg" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/xd_banner.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/xd_banner.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "https://pic.touduila.com/xd_banner.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: {
                        src: "https://pic.touduila.com/taida_banner_2.jpg",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/taida_banner_2.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/taida_banner_2.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v =
                                "https://pic.touduila.com/taida_banner_2.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: {
                        src: "https://pic.touduila.com/taida_banner_1.jpg",
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.standbyPics,
                          expression: "standbyPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/taida_banner_1.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.standbyPics)
                          ? _vm._i(
                              _vm.standbyPics,
                              "https://pic.touduila.com/taida_banner_1.jpg"
                            ) > -1
                          : _vm.standbyPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.standbyPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v =
                                "https://pic.touduila.com/taida_banner_1.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.standbyPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.standbyPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.standbyPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "banner图",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "container" }, [
                    _c("img", {
                      staticStyle: { width: "100%", height: "170px" },
                      attrs: { src: "https://pic.touduila.com/u1.jpg" },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.bannerPics,
                          expression: "bannerPics",
                        },
                      ],
                      staticClass: "checkbox",
                      attrs: {
                        type: "checkbox",
                        value: "https://pic.touduila.com/u1.jpg",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.bannerPics)
                          ? _vm._i(
                              _vm.bannerPics,
                              "https://pic.touduila.com/u1.jpg"
                            ) > -1
                          : _vm.bannerPics,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.bannerPics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "https://pic.touduila.com/u1.jpg",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.bannerPics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.bannerPics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.bannerPics = $$c
                          }
                        },
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排行榜",
                    "label-width": _vm.formLabelWidth,
                    prop: "rank",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.rank,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "rank", $$v)
                        },
                        expression: "form.rank",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "回收标牌",
                    "label-width": _vm.formLabelWidth,
                    prop: "bind",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bind,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "bind", $$v)
                        },
                        expression: "form.bind",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "回收打印机",
                    "label-width": _vm.formLabelWidth,
                    prop: "print",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.print,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "print", $$v)
                        },
                        expression: "form.print",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否称重拍照",
                    "label-width": _vm.formLabelWidth,
                    prop: "weightPic",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.weightPic,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "weightPic", $$v)
                        },
                        expression: "form.weightPic",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "state",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "弹框内容",
                    "label-width": _vm.formLabelWidth,
                    prop: "ruleDisplay",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.form.ruleDisplay,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "ruleDisplay", $$v)
                      },
                      expression: "form.ruleDisplay",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印扣积分",
                    "label-width": _vm.formLabelWidth,
                    prop: "stickerPoints",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "150px" },
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.stickerPoints,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stickerPoints", $$v)
                      },
                      expression: "form.stickerPoints",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Addz } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }