"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.number.constructor");

var _dizhi = _interopRequireDefault(require("@/api/dizhi"));

//
//
//
//
//
//
var _default = {
  name: 'Xiaoqu',
  props: {
    value: [String, Number, Object],
    jiedao: [String, Number, Object],
    shequ: [String, Number, Object]
  },
  data: function data() {
    return {
      // 社区列表
      list: []
    };
  },
  computed: {
    selectVal: {
      get: function get() {
        if (this.value) {
          return this.value;
        } else {
          return null;
        }
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    'shequ': function shequ(val) {
      console.log(val);

      if (val) {
        this.getData(val.name);
      } else {
        this.value = '';
      }
    }
  },
  created: function created() {
    this.getData(this.value);
  },
  methods: {
    // 获取街道
    getData: function getData(community) {
      var _this = this;

      var jiedao = this.jiedao;
      var street = '';

      if (jiedao) {
        street = jiedao["street"];

        if (community) {
          _dizhi.default.xiaoquList({
            currentPage: 1,
            numOfPage: 10000,
            street: street,
            community: community
          }).then(function (res) {
            var list = res.data.list;
            console.log(list);
            _this.list = list;

            if (!list.includes(_this.value)) {
              _this.selectVal = '';
            }
          });
        } else {
          this.list = [];
        }
      } else {
        this.list = [];
      }
    }
  }
};
exports.default = _default;