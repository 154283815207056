import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import jiedao from '@/components/street/jiedaolist';
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  data: function data() {
    return {
      gridData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      dialog: false,
      searchStreet: null,
      list: JSON.parse(this.$route.query.data),
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      forms: {
        typeInfo: '',
        //打卡类型
        coin: '',
        //奖励积分
        ruler: '',
        //活动图片上传要求
        status: '',
        // 开启/关闭状态
        pic: '',
        //图片
        street: '',
        //街道
        role: '',
        //小程序角色
        des: '' //活动说明

      },
      communityList: [{
        region: '开启',
        id: 1
      }, {
        region: '关闭',
        id: 0
      }],
      addstatus: '添加类型',
      datas: [{
        name: '居民小程序',
        value: 1
      }, {
        name: '生态城小程序',
        value: 40
      }, {
        name: '泰达投对小程序',
        value: 80
      }, {
        name: '什邡智慧分类',
        value: 70
      }, {
        name: '所有',
        value: -1
      }],
      dialogTableVisible: false
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.forms.street = val;
      } else {
        this.forms.street = '';
      }
    }
  },
  methods: {
    // addType() {
    // 	this.forms.id ? delete this.forms.id : ''
    // 	this.dialog = true
    // 	Object.keys(this.forms).forEach(key => {this.forms[key] = '', this.searchStreet = ''})
    // },
    uploadImgs: function uploadImgs(data) {
      console.log(data);
      this.forms.pic = data.key;
    },
    edit: function edit(row) {
      var _this = this;

      this.dialog = true;
      this.forms.id = row.id;
      this.searchStreet = row.street;
      Object.keys(this.forms).forEach(function (key) {
        _this.forms[key] = row[key];
      });
    },
    record: function record(row) {
      this.dialogTableVisible = true;
      Service.seemanageslist({
        id: row.id
      }).then(function (res) {
        console.log(res);
      });
    } // saves() {
    // 	Service.addoreditmanages(this.forms).then((res) => {
    // 		if(res.data != '添加成功'){
    // 			this.$message({
    // 				type: 'success',
    // 				message: res.data
    // 			});
    // 		} else {
    // 			this.$message({
    // 				type: 'success',
    // 				message: '操作成功'
    // 			});
    // 		}
    // 		this.getList()
    // 		this.dialog = false
    // 	})
    // }

  }
};