import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/Input/Inputnum.js';
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
import noel from '@/components/street/noeladdstreet';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination,
    noel: noel
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, _defineProperty(_statusMap, "10", 'primary'), _defineProperty(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '-1': '删除订单',
        '0': '取消订单',
        '1': '回收中',
        '2': '已完成'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      lists: {
        printNum: '',
        sn: ""
      },
      dialoges: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        street: '',
        community: '',
        region: '',
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        // DELETE_ENUM(-1,"删除订单"),
        // CANCEL_ENUM(0,"取消订单"),
        // STATE_ENUM(1,"回收中"),
        // END_ENUM(2,"已完成");
        status: '',
        reserveType: 0,
        statisticalMethod: 1,
        orderType: 0,
        streetMethod: 1,
        type: "" // 垃圾类型

      },
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '已完成',
        value: 2
      }, {
        name: '回收中',
        value: 1
      }, {
        name: '取消订单',
        value: 0
      }, {
        name: '已删除',
        value: -1
      }],
      Option: [{
        name: '全部',
        value: 0
      }, {
        name: '预约订单',
        value: 1
      }, {
        name: '自助打印订单',
        value: 2
      }, {
        name: '自助贴纸订单',
        value: 3
      }, {
        name: '行动站订单-称重',
        value: 4
      }, {
        name: '行动站订单-未称重',
        value: 5
      }, {
        name: '行动站标牌',
        value: 6
      }, {
        name: '快速订单',
        value: 7
      }],
      methods: [{
        name: "按下单日期统计",
        value: 1
      }, {
        name: "按回收日期统计",
        value: 2
      }],
      typesOptions: [{
        typeInfo: '全部',
        type: 0
      }, {
        // 厨余类型字典
        typeInfo: '可回收',
        type: 2
      }, {
        typeInfo: '低值可回收',
        type: 4
      }, {
        typeInfo: '有害垃圾',
        type: 5
      }],
      streetMethodOptions: [{
        typeInfo: '按用户街道查找',
        type: 1
      }, {
        // 厨余类型字典
        typeInfo: '按督导员街道查找',
        type: 2
      }],
      dialogs: false,
      alertlist: [],
      goodsType: []
    };
  },
  watch: {
    time: function time(val) {
      if (val == null || val == '') {
        this.listQuery.startTime = '';
        this.listQuery.endTime = '';
      } else {
        this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    // 获取回收物类型
    Service.getGoodsType().then(function (res) {
      _this.goodsType = res.data.data;
      console.log(res.data.data);
    });

    if (this.$route.params.data) {
      this.listQuery.key = this.$route.params.data.location.name;
      this.getList();
    } else {
      this.getList();
    }
  },
  methods: {
    shezhiprint: function shezhiprint() {
      var _this2 = this;

      console.log(this.lists);
      Service.shebeiprintNew(this.lists).then(function (res) {
        // 自助回收订单码
        // console.log(res)
        if (res.data.data) {
          _this2.dialoges = false;

          _this2.$notify({
            title: '成功',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });
        } else {
          _this2.dialoges = false;

          _this2.$notify({
            title: '失败',
            message: res.data.error,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    noelchange: function noelchange(data) {
      console.log(data);
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    zizhu: function zizhu() {
      this.lists.printNum = '';
      this.dialoges = true;
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = [];

      if (e.goods == null || e.goods.length == 0) {
        var inserRow = {};
        inserRow.typeInfo = e.typeInfo;
        inserRow.weight = e.weight;
        inserRow.pic = e.pic;
        this.alertlist.push(inserRow);
      } else {
        this.alertlist = e.goods;
      }
    },
    exportExcel: function exportExcel() {
      // 回收订单管理-个人回收订单导出
      // location.href = '/manage/order/excel?' + qs.stringify(this.listQuery)
      Service.exportOrderList(this.listQuery).then(function (res) {
        var content = res.data;
        var blob = new Blob([content]);
        var fileName = '订单记录.xls';

        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象

          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this3 = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this3.initMap(row.lon, row.lat);
      }, 500);
    },
    getList: function getList() {
      var _this4 = this;

      this.listLoading = true;
      Service.personalOrderListNew(this.listQuery).then(function (response) {
        //console.log(response.data);
        _this4.list = response.data.data.list;
        _this4.total = response.data.data.totalRows;

        _this4.list.forEach(function (item, index) {
          if (item.userPic) {
            item.userPic = item.userPic.replace("http://pic.touduila.com/", "");
          }
        });

        setTimeout(function () {
          _this4.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    beizhu: function beizhu(row) {
      this.beizhuTemp.remark = row.remark || '';
      this.beizhuTemp.orderId = row.id;
      this.beizhuVisible = true;
    },
    doRemark: function doRemark() {
      var _this5 = this;

      Service.orderRemark(this.beizhuTemp).then(function (res) {
        _this5.beizhuVisible = false;

        if (res.data.statusCode == 200) {
          _this5.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });

          _this5.getList();
        }
      });
    },

    /*chongpai(row) {
    	Service.orderRecover(row.id).then(res => {
    		if (res.data.statusCode) {
    			this.$notify({
    				title: '成功',
    				message: '重派成功',
    				type: 'success',
    				duration: 2000
    			})
    				this.getList()
    		}
    	})
    },*/
    deleteOrder: function deleteOrder(row) {
      var _this6 = this;

      this.$confirm('此操作将永久删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.orderDel(row.id).then(function (res) {
          _this6.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this6.getList();
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};