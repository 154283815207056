//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
export default {
  name: 'devicelog',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        mark: this.$route.query.mark
      },
      devicedialog: false,
      // 设备图片弹框
      images: []
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getRecyclingBinsLogList(this.listQuery).then(function (response) {
        console.log(response); //this.list = response.data.list
        //this.total = response.data.totalRows

        _this.list = response.data.data.list;
        _this.total = response.data.data.rowsCount;
        _this.listLoading = false;
      });
    },
    imgs: function imgs(e, index) {
      var _this2 = this;

      // 查看设备图片
      this.images = [];
      console.log(e);
      this.devicedialog = true;
      setTimeout(function () {
        _this2.images.push(e); // 单个文件


        _this2.$refs.carousel.setActiveItem(index);
      }, 200);
    }
  }
};