import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/devicequestion';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        phone: "" // 查询条件

      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      dialogAddOrUpdateFormVisible: false,
      form: {
        type: 0,
        phone: "",
        street: "",
        mbId: "",
        //pic:"",
        //status:0,
        unionId: ""
      },
      showStreetOrMbId: 0,
      streets: [],
      // 街道列表
      devices: [],
      // 设备列表
      mbIds: [],
      // 被选择的设备列表
      rules: {
        phone: [{
          required: true,
          message: "联系电话是必填项"
        }],
        type: [{
          required: true,
          message: "使用范围是必填项"
        }]
      }
    };
  },
  watch: {
    "form.type": function formType(val) {
      if (val == 0) {
        this.showStreetOrMbId = 0;
      } else if (val == 1) {
        this.showStreetOrMbId = 1;
      } //console.log(this.mbIds);


      this.form.street = "";
      this.form.mbId = "";
      this.mbIds = [];
    }
  },
  created: function created() {
    this.getJiedao(); // 街道列表

    this.queryAllRecyclingBins(); // 设备列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getList(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    add: function add() {
      this.form.unionId = "";
      this.form.type = 0;
      this.form.phone = '';
      this.form.street = "";
      this.form.mbId = "";
      this.mbIds = []; // this.form.pic="";
      // this.form.status=0;

      this.dialogAddOrUpdateFormVisible = true;
    },
    edit: function edit(rows) {
      this.form.unionId = rows.unionId;
      this.form.type = rows.type;
      this.form.phone = rows.phone;
      this.form.street = rows.street;
      this.form.mbId = rows.mbId;
      this.mbIds = rows.mbId.split(","); // console.log(rows.mbId.split(","));
      // this.form.pic = rows.pic;
      // this.form.status =rows.status;

      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          console.log(_this2.form.type == 0 && _this2.form.street == '');

          if (_this2.form.type == 0 && _this2.form.street == '') {
            // 街道
            _this2.$message({
              message: "街道是必填项",
              type: 'error'
            });

            return;
          } else if (_this2.form.type == 1 && _this2.mbIds.length == 0) {
            // 设备
            _this2.$message({
              message: "设备是必填项",
              type: 'error'
            });

            return;
          }

          _this2.form.mbId = _this2.mbIds.join(",");

          if (_this2.form.unionId == null || _this2.form.unionId == '') {
            // 联合ID
            Service.add(_this2.form).then(function (res) {
              // console.log(res);
              if (res.data.status == 1) {
                _this2.$message({
                  message: res.data.msg,
                  type: 'error'
                }); //this.mbIds=[];
                //this.form.mbId="";

              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          } else {
            // 修改
            Service.update(_this2.form).then(function (res) {
              if (res.data.status == 1) {
                _this2.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          }
        }
      });
    },
    del: function del(row) {
      var _this3 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.del(row.unionId).then(function (res) {
          if (res.data.error) {
            _this3.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this3.getList();
        });
      }).catch(function () {});
    },
    getJiedao: function getJiedao() {
      var _this4 = this;

      ServiceStreet.getArtjied().then(function (res) {
        console.log(res.data);
        _this4.streets = res.data;
      });
    },
    streetChange: function streetChange(street) {
      // 街道列表改变赋值
      this.form.street = street;
    },
    queryAllRecyclingBins: function queryAllRecyclingBins() {
      var _this5 = this;

      // 设备列表
      Service.queryAllRecyclingBins().then(function (res) {
        console.log(res.data);
        _this5.devices = res.data;
      });
    },
    deviceChange: function deviceChange(mbId) {
      var _this6 = this;

      this.mbIds = [];
      mbId.forEach(function (item, index) {
        _this6.mbIds.push(item);
      }); //this.form.mbId = arr.join(",");
    }
  }
};