//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import dizhiService from '@/api/dizhi';
import userjiedao from '@/components/street/userjiedao';
import Service from '@/api/service';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from "@/utils";
import ServiceProject from '@/api/project'; // 项目api

export default {
  components: {
    userjiedao: userjiedao,
    Pagination: Pagination
  },
  data: function data() {
    return {
      total: 0,
      searchStreet: null,
      listLoading: false,
      listQuery: {
        numOfPage: 20,
        currentPage: 1,
        userInfo: '',
        userType: '',
        street: '',
        shopInfo: '',
        type: '',
        cycleStart: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        cycleEnd: parseTime(new Date(), '{y}-{m}-{d}'),
        projectId: ""
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      statusLists: [{
        name: '全部',
        value: ''
      }, {
        name: '虚拟商品',
        value: '虚拟商品'
      }, {
        name: '实体商品',
        value: '实体商品'
      }],
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '线上用户',
        value: 0
      }, {
        name: '线下用户',
        value: 2
      }],
      list: [],
      projectFieldVisibleIndex: false // 街道项目显示

    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      var _this = this;

      this.listQuery.street = val.street;
      ServiceProject.getProjectsByStreet(this.listQuery.street).then(function (res) {
        _this.projectList = res.data.list;

        if (_this.projectList.length == 0) {
          _this.projectFieldVisibleIndex = false;
          _this.listQuery.projectId = "";
        } else {
          _this.projectFieldVisibleIndex = true;
        }
      });
      this.getList();
    },
    time: function time(val) {
      if (val == null || val == '') {
        this.listQuery.cycleStart = '';
        this.listQuery.cycleEnd = '';
      } else {
        this.listQuery.cycleStart = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.cycleEnd = parseTime(val[1], '{y}-{m}-{d}');
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      Service.jifenhuanlist(this.listQuery).then(function (res) {
        // console.log(res)
        _this2.list = res.data.list;
        _this2.total = res.data.totalRows;
      });
    },
    handleSelectionChange: function handleSelectionChange() {},
    handleFilter: function handleFilter() {
      this.getList();
    },
    add: function add() {},
    exports: function exports() {
      location.href = '/operation/shopintegra/exportOwnerData?' + qs.stringify(this.listQuery); // Service.exportjifenhuanlist().then((res)=>{
      // 	console.log(res)
      // })
    }
  },
  created: function created() {
    this.getList();
  }
};