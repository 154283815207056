var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("jiedao", {
                model: {
                  value: _vm.searchStreet,
                  callback: function ($$v) {
                    _vm.searchStreet = $$v
                  },
                  expression: "searchStreet",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { placeholder: "请选择资料类型", clearable: true },
                  model: {
                    value: _vm.listQuery.type1,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "type1", $$v)
                    },
                    expression: "listQuery.type1",
                  },
                },
                _vm._l(_vm.typeList1, function (obj) {
                  return _c("el-option", {
                    key: obj.value,
                    attrs: { label: obj.name, value: obj.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { placeholder: "请选择资料类型", clearable: true },
                  model: {
                    value: _vm.listQuery.type2,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "type2", $$v)
                    },
                    expression: "listQuery.type2",
                  },
                },
                _vm._l(_vm.typeList2, function (obj) {
                  return _c("el-option", {
                    key: obj.value,
                    attrs: { label: obj.name, value: obj.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v("查 询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("zhouziliaoHome:add")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.add()
                    },
                  },
                },
                [_vm._v("添 加")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("zhouziliaoHome:create")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.batchCreate },
                },
                [_vm._v("批量生成")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("zhouziliaoHome:download")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadPdfAll },
                },
                [_vm._v("批量pdf下载")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("zhouziliaoHome:download")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadWordAll },
                },
                [_vm._v("批量word下载")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.datalist,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "编号", width: "60" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "类型", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == "积分卡发放记录" ||
                    scope.row.type == "积分兑换情况" ||
                    scope.row.type == "厨余袋发放记录" ||
                    scope.row.type == "其他物资发放记录"
                      ? _c("span", [
                          _vm._v("物资发放记录单-" + _vm._s(scope.row.type)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != "积分卡发放记录" &&
                    scope.row.type != "积分兑换情况" &&
                    scope.row.type != "厨余袋发放记录" &&
                    scope.row.type != "其他物资发放记录"
                      ? _c("span", [_vm._v(_vm._s(scope.row.type))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "资料图片", "min-width": "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.pics.split(","), function (i, index) {
                    return scope.row.pics
                      ? _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            margin: "5px",
                          },
                          attrs: { src: "http://pic.touduila.com/" + i },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(scope.row.pics, index)
                            },
                          },
                        })
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "资料表图片", "min-width": "150" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.filePics != undefined &&
                      scope.row.filePics != null &&
                      scope.row.filePics != ""
                      ? _vm._l(
                          scope.row.filePics.split(","),
                          function (i, index) {
                            return _c("img", {
                              staticStyle: {
                                width: "100px",
                                height: "100px",
                                margin: "5px",
                              },
                              attrs: { src: "http://pic.touduila.com/" + i },
                              on: {
                                click: function ($event) {
                                  return _vm.imgs(scope.row.filePics, index)
                                },
                              },
                            })
                          }
                        )
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "统计周期",
              prop: "cycleStart",
              sortable: "",
              "sort-method": function (a, b) {
                return Date.parse(a.cycleStart) - Date.parse(b.cycleStart)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(scope.row.cycleStart) +
                          " -- " +
                          _vm._s(scope.row.cycleEnd) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "添加时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(scope.row.createTime) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上传人", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.uploadName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "生成文件状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color:
                            scope.row.fileStatus == 1 ? "#67C23A" : "#C03639",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.fileStatus == 1 ? "已生成" : "未生成"
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操 作", align: "center", width: "170" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("zhouziliaoHome:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.exportWordFile(scope.row)
                          },
                        },
                      },
                      [_vm._v("导出word")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.exportPdfFile(scope.row)
                          },
                        },
                      },
                      [_vm._v("导出Pdf")]
                    ),
                    _vm._v(" "),
                    _vm.hasPerm("zhouziliaoHome:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "海报", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加/编辑周资料统计", visible: _vm.FormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.FormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "editForm", attrs: { model: _vm.editForm } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择类型" },
                      model: {
                        value: _vm.editForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "type", $$v)
                        },
                        expression: "editForm.type",
                      },
                    },
                    _vm._l(_vm.editTypes, function (i) {
                      return _c("el-option", {
                        key: i.value,
                        attrs: { label: i.name, value: i.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      model: {
                        value: _vm.editForm.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "street", $$v)
                        },
                        expression: "editForm.street",
                      },
                    },
                    _vm._l(_vm.editStreets, function (i) {
                      return _c("el-option", {
                        key: i.street,
                        attrs: { label: i.street, value: i.street },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "统计周期",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "开始时间",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.editForm.cycleStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "cycleStart", $$v)
                      },
                      expression: "editForm.cycleStart",
                    },
                  }),
                  _vm._v(" 至\n\t\t\t\t    "),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "结束时间",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.editForm.cycleEnd,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "cycleEnd", $$v)
                      },
                      expression: "editForm.cycleEnd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    staticStyle: { width: "480px" },
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.editForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editForm, "remark", $$v)
                      },
                      expression: "editForm.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "资料图片",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadPic",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "auto-upload": false,
                        "on-change": _vm.handleChange,
                        multiple: "",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.pics.length != 0
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: {
                                move: _vm.onPicMove,
                                list: _vm.pics,
                                handle: ".dargBtn",
                                animation: 200,
                                filter: ".undraggable",
                              },
                            },
                            _vm._l(_vm.pics, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass:
                                    "el-upload-list__item is-success",
                                  class: "draggable",
                                  staticStyle: { float: "left" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dargBtn" },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "drag" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass:
                                      "el-upload-list__item-thumbnail",
                                    staticStyle: {
                                      width: "150px",
                                      height: "140px",
                                    },
                                    attrs: {
                                      src: "http://pic.touduila.com/" + item,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-actions",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handlePicRemove(item)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "资料表图片",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadFilePic",
                      staticStyle: { "margin-bottom": "10px" },
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "auto-upload": false,
                        "on-change": _vm.handleFileChange,
                        multiple: "",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.filePics.length != 0
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: {
                                move: _vm.onFilePicMove,
                                list: _vm.filePics,
                                handle: ".dargBtn",
                                animation: 200,
                                filter: ".undraggable",
                              },
                            },
                            _vm._l(_vm.filePics, function (item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass:
                                    "el-upload-list__item is-success",
                                  class: "draggable",
                                  staticStyle: { float: "left" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "dargBtn" },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "drag" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("img", {
                                    staticClass:
                                      "el-upload-list__item-thumbnail",
                                    staticStyle: {
                                      width: "150px",
                                      height: "140px",
                                    },
                                    attrs: {
                                      src: "http://pic.touduila.com/" + item,
                                      alt: "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-actions",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleFilePicRemove(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.FormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateWorkData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }