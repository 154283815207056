import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import noel from '@/components/street/noeladdstreet';
import jiedao from '@/components/street/jiedao';
import qs from 'qs';
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import { parseTime } from '@/utils';
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao,
    noel: noel
  },
  data: function data() {
    var _ref;

    return _ref = {
      searchStreet: null,
      listLoading: true,
      list: [],
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      options: [{
        value: '选项1',
        label: 'YD终端投递运营'
      }, {
        value: '选项2',
        label: 'WDF终端投递运营'
      }],
      value: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }, _defineProperty(_ref, "listQuery", {
      startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
      endTime: parseTime(new Date().getTime() + 3600 * 1000 * 24, '{y}-{m}-{d}'),
      currentPage: 1,
      numOfPage: 20,
      street: '',
      search: '',
      community: '',
      region: ''
    }), _defineProperty(_ref, "time", [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date().getTime() + 3600 * 1000 * 24]), _ref;
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    noelchange: function noelchange(data) {
      console.log(data);
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.recordByOp(this.listQuery).then(function (res) {
        console.log(res.data);
        _this.list = res.data.list;
        _this.total = res.data.totalRows;
        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    exports: function exports() {
      Service.recordsExcel(this.listQuery).then(function (res) {
        console.log(res);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var elink = document.createElement('a');
          elink.download = '报表.xlsx';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    }
  }
};