import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _Service;

import request from '@/utils/request';
var Service = (_Service = {
  loginByUsername: function loginByUsername(params) {
    return request({
      url: '/loginByUsername',
      method: 'post',
      params: params
    });
  },
  logout: function logout(params) {
    return request({
      url: '/logout',
      method: 'post',
      params: params
    });
  },
  // 获取用户信息
  getUserInfo: function getUserInfo(params) {
    return request({
      url: '/getUserInfo',
      method: 'get',
      params: params
    });
  },
  // 首页统计
  homeTotal: function homeTotal() {
    return request({
      url: '/operation/stats/total',
      method: 'post'
    });
  },
  getShenhe: function getShenhe() {
    return request({
      url: '/operator/tools/miniapps/audit/switch',
      method: 'get'
    });
  },
  setShenhe: function setShenhe(params) {
    return request({
      url: '/operator/tools/miniapps/audit/switch',
      method: 'post',
      params: params
    });
  },
  // 首页订单曲线
  homeOrders: function homeOrders(params) {
    return request({
      url: '/operation/stats/orders',
      method: 'post',
      params: params
    });
  },
  // 回收员列表
  recyclerList: function recyclerList(params) {
    return request({
      url: '/operation/recycler/list',
      method: 'get',
      params: params
    });
  },
  updateRecyclerStreet: function updateRecyclerStreet(params) {
    return request({
      url: '/operation/recycler/street',
      method: 'post',
      params: params
    });
  },
  //空港管理员
  //督导员列表
  kgDudaoList: function kgDudaoList(params) {
    return request({
      url: '/operation/kg/recycler/list',
      method: 'get',
      params: params
    });
  },
  //添加督导员
  kgdudaoAdd: function kgdudaoAdd(params) {
    return request({
      url: '/operation/kg/recycler/add',
      method: 'post',
      params: params
    });
  },
  //编辑修改
  kgdudaoEdit: function kgdudaoEdit(params) {
    return request({
      url: '/operation/kg/recycler/edit',
      method: 'post',
      params: params
    });
  },
  //本月回收员打卡情况
  kgdudaoRecord: function kgdudaoRecord(params) {
    return request({
      url: '/operation/kg/recycler/record',
      method: 'post',
      params: params
    });
  },
  // 修改社区
  modifyCommunity: function modifyCommunity(params) {
    return request({
      url: '/operation/recycler/modifyCommunity',
      method: 'get',
      params: params
    });
  },
  // 修改小区
  updateRegion: function updateRegion(params) {
    return request({
      url: '/operation/recycler/updateRegion',
      method: 'get',
      params: params
    });
  },
  // 修改照片
  modifyPic: function modifyPic(params) {
    return request({
      url: '/operation/recycler/modifyPic',
      method: 'get',
      params: params
    });
  },
  // 回收员详情
  recyclerDetail: function recyclerDetail(params) {
    return request({
      url: '/operation/recycler/detail',
      method: 'post',
      params: params
    });
  },
  // 回收员添加备注
  recyclerComment: function recyclerComment(params) {
    return request({
      url: '/operation/recycler/comment',
      method: 'post',
      params: params
    });
  },
  // 回收员审批
  recyclerAudit: function recyclerAudit(params) {
    return request({
      url: '/operation/recycler/audit',
      method: 'post',
      params: params
    });
  },
  // 订单列表
  newOrder: function newOrder(params) {
    return request({
      url: '/operator/nearest/order',
      method: 'get',
      params: params
    });
  },
  // 订单列表
  orderList: function orderList(params) {
    return request({
      url: '/operation/order/list',
      method: 'get',
      params: params
    });
  },
  orderyunLists: function orderyunLists(params) {
    return request({
      url: '/operation/order/cloudAccount',
      method: 'get',
      params: params
    });
  },
  // 取消订单
  orderDel: function orderDel(orderId) {
    return request({
      url: '/operation/order/delete/' + orderId,
      method: 'DELETE'
    });
  },
  // 重新派单
  orderRecover: function orderRecover(orderId) {
    return request({
      url: '/operation/order/recover/' + orderId,
      method: 'post'
    });
  },
  //
  orderRemark: function orderRemark(params) {
    return request({
      url: '/operation/order/remark/',
      method: 'post',
      params: params
    });
  },
  // 后台下单
  addOrder: function addOrder(ownerId) {
    return request({
      url: '/operation/owner/derive/' + ownerId,
      method: 'post'
    });
  },
  // 设置价格
  getPrice: function getPrice() {
    return request({
      url: '/operation/owner/indicative/price',
      method: 'get'
    });
  },
  setPrice: function setPrice(data) {
    return request({
      url: '/operation/owner/indicative/price',
      method: 'post',
      data: data
    });
  },
  owerLists: function owerLists(params) {
    return request({
      url: '/operation/owner/residentUserList',
      method: 'post',
      params: params
    });
  },
  owerList: function owerList(params) {
    return request({
      url: '/operation/owner/list',
      method: 'get',
      params: params
    });
  },
  studentList: function studentList(params) {
    return request({
      url: '/operation/owner/studentList',
      method: 'get',
      params: params
    });
  },
  studentOrderList: function studentOrderList(params) {
    return request({
      url: '/operation/order/studentList',
      method: 'get',
      params: params
    });
  },
  getIncreasedUser: function getIncreasedUser(params) {
    return request({
      url: '/operation/owner/getIncreasedUser',
      method: 'get',
      params: params
    });
  },
  lingtoudiList: function lingtoudiList(params) {
    return request({
      url: '/operation/owner/zeroKitchenOwner',
      method: 'get',
      params: params
    });
  },
  updateLocation: function updateLocation(data) {
    return request({
      url: '/operation/owner/location?userId=' + data.userId,
      method: 'post',
      data: data
    });
  },
  updateLocations: function updateLocations(params) {
    console.log(params);
    return request({
      url: '/operation/owner/modify/location',
      method: 'get',
      params: params
    });
  },
  // 获取开放小区
  getCommunities: function getCommunities(params) {
    return request({
      url: '/operation/recycler/communities',
      method: 'get',
      params: params
    });
  },
  // 获取开放小区
  addCommunitie: function addCommunitie(data) {
    return request({
      url: '/operation/recycler/community',
      method: 'post',
      data: data
    });
  },
  // 获取开放小区
  updateCommunitie: function updateCommunitie(data) {
    return request({
      url: '/operation/recycler/community',
      method: 'put',
      data: data
    });
  },
  delCommunitie: function delCommunitie(params) {
    return request({
      url: '/operation/recycler/community',
      method: 'delete',
      params: params
    });
  },
  // 更新用户地域限制
  updateUserLocation: function updateUserLocation(params) {
    return request({
      url: '/operator/position/limited',
      method: 'post',
      params: params
    });
  },
  // 用户注销
  jifengUser: function jifengUser(params) {
    return request({
      url: '/operation/owner/mergeDelFlagByAdmin',
      method: 'post',
      params: params
    });
  },
  // 回收箱列表
  huishouxiangList: function huishouxiangList(params) {
    return request({
      url: '/operation/recycling/bins/list',
      method: 'get',
      params: params
    });
  },
  // 设备操作日志列表
  getRecyclingBinsLogList: function getRecyclingBinsLogList(params) {
    return request({
      url: '/recyclingBinsLog/getPageList',
      method: 'get',
      params: params
    });
  },
  getDati: function getDati(params) {
    return request({
      url: '/operation/problem/list',
      method: 'get',
      params: params
    });
  },
  // 投对拍接口
  touduipaiPhoto: function touduipaiPhoto(params) {
    return request({
      url: '/operator/touduiphoto/list',
      method: 'get',
      params: params
    });
  },
  updateTouduipaiStatus: function updateTouduipaiStatus(data) {
    return request({
      url: '/operator/touduiphoto/updateTouDuiPhotoStatus',
      method: 'put',
      data: data
    });
  },
  toudiPhoto: function toudiPhoto(params) {
    return request({
      url: '/operation/recycling/bins/photo/list',
      method: 'get',
      params: params
    });
  },
  deletePhoto: function deletePhoto(id) {
    return request({
      url: '/operation/recycling/bins/photo/' + id,
      method: 'delete'
    });
  },
  createQrcode: function createQrcode(params) {
    return request({
      url: '/operator/qrcode/creation',
      method: 'post',
      params: params
    });
  },
  getQrcode: function getQrcode(id) {
    return request({
      url: '/operator/qrcode/' + id,
      method: 'get'
    });
  },
  deleteDati: function deleteDati(id) {
    return request({
      url: '/operation/problem/delete/' + id,
      method: 'delete'
    });
  },
  addDati: function addDati(data) {
    return request({
      url: '/operation/problem/add',
      method: 'post',
      data: data
    });
  },
  updateDati: function updateDati(data) {
    return request({
      url: '/operation/problem/update',
      method: 'put',
      data: data
    });
  },
  getBaoming: function getBaoming(params) {
    return request({
      url: '/operation/problem/report/list',
      method: 'get',
      params: params
    });
  },
  getDatiDesc: function getDatiDesc() {
    return request({
      url: '/operation/problem/introduceList',
      method: 'get'
    });
  },
  updateDatiDesc: function updateDatiDesc(data) {
    return request({
      url: '/operation/problem/updateIntroduce',
      method: 'put',
      data: data
    });
  },
  getYaoqingma: function getYaoqingma(params) {
    return request({
      url: '/operation/invite/getCodelist',
      method: 'get',
      params: params
    });
  },
  getYaoqingList: function getYaoqingList() {
    return request({
      url: '/operation/invite/list',
      method: 'get'
    });
  },
  // getChuyuList(params) {
  // 	return request({
  // 		url: '/operator/kitchenWaste/list',
  // 		method: 'get',
  // 		params
  // 	})
  // },
  getChuyuList: function getChuyuList(params) {
    return request({
      url: '/operator/kitchenWaste/list',
      method: 'get',
      params: params
    });
  },
  updateChuyuStatus: function updateChuyuStatus(data) {
    return request({
      url: '/operator/kitchenWaste/updateKitchenStatus',
      method: 'put',
      data: data
    });
  },
  // 获取街道列表
  getJiedaoList: function getJiedaoList(params) {
    return request({
      url: '/operation/supervisorRecord/getKitchenRegionTree',
      method: 'get',
      params: params
    });
  },
  // 获取活动类型
  getHuodongl: function getHuodongl() {
    return request({
      url: '/operation/stats/ativityType',
      method: 'get'
    });
  },
  // 获取表格数据
  getAtivityList: function getAtivityList(params) {
    return request({
      url: '/operation/stats/ativityList',
      method: 'get',
      params: params
    });
  },
  // 获取终端垃圾投递
  // 点击添加活动
  Upactivity: function Upactivity(data) {
    return request({
      url: '/operation/stats/addAtivity',
      method: 'post',
      data: data
    });
  },
  updateUpactivity: function updateUpactivity(data) {
    return request({
      url: '/operation/stats/updateAtivity',
      method: 'post',
      data: data
    });
  },
  delActivity: function delActivity(id) {
    return request({
      url: '/operation/stats/deleteAtivity?id=' + id,
      method: 'DELETE'
    });
  },
  getArea: function getArea(param) {
    return request({
      url: '/operation/supervisorRecord/getKitchenRegionTree?param=' + param,
      method: 'get'
    });
  },
  getArtlist: function getArtlist(params) {
    return request({
      url: '/operation/stats/accounts',
      method: 'get',
      params: params
    });
  },
  getArtjied: function getArtjied() {
    return request({
      url: '/operation/stats/streets',
      method: 'get'
    });
  },
  updateJied: function updateJied(data) {
    // 修改账号管理
    return request({
      url: '/operation/stats/account',
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  },
  delJied: function delJied(Id) {
    return request({
      url: '/operation/stats/account?accountId=' + Id,
      method: 'DELETE'
    });
  },
  TianJied: function TianJied(data) {
    // 添加
    return request({
      url: '/operation/stats/account',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  },
  TianChuyu: function TianChuyu(data) {
    return request({
      url: '/operator/kitchenWaste/addUserKitchenRecord?userId=' + data,
      method: 'post'
    });
  },
  // 居民列表二维码组号解绑查询
  getUserByqrcodeQroup: function getUserByqrcodeQroup(params) {
    return request({
      url: '/operator/kitchenWaste/getUserByQrcodeGroup?qrcodeGroup=' + params,
      method: 'get'
    });
  },
  // 确认解绑
  UnbindKitchenRecord: function UnbindKitchenRecord(qrcodeGroup) {
    return request({
      url: '/operator/kitchenWaste/unbundlingUserKitchen?qrcodeGroup=' + qrcodeGroup,
      method: 'DELETE'
    });
  },
  getbinsList: function getbinsList(params) {
    return request({
      url: '/operation/fl/binsList',
      method: 'get',
      params: params
    });
  },
  getNbinsList: function getNbinsList(bins) {
    return request({
      url: '/operation/fl/binsPoint?bins=' + bins,
      method: 'get'
    });
  },
  recordByOp: function recordByOp(params) {
    return request({
      url: '/operation/fl/bins/records',
      method: 'get',
      params: params
    });
  },
  mergeBinById: function mergeBinById(params) {
    return request({
      // url: '/operation/fl/mergeBinById?deviceName=' + data.deviceName + '&deviceRegion=' + data.deviceRegion + '&id=' +
      // 	data.id + '&purchaseTime=' + data.purchaseTime + '&deviceAddress=' + data.deviceAddress +
      // 	'&street=' + data.street,
      url: '/operation/fl/mergeBinById',
      method: 'post',
      params: params
    });
  },
  getUserTotleCoin: function getUserTotleCoin(params) {
    return request({
      url: '/operation/owner/getUserTotleCoin?userId=' + params,
      method: 'get'
    });
  },
  recordsExcel: function recordsExcel(params) {
    return request({
      url: '/operation/fl/bins/records/excel',
      method: 'post',
      responseType: 'blob',
      params: params
    });
  },
  chaoshishangbao: function chaoshishangbao(params) {
    return request({
      url: '/operation/fl/ws/history?mbId=' + params,
      method: 'get'
    });
  },
  // Web
  Weblogin: function Weblogin(params) {
    return request({
      url: '/web/platform/login?name=' + params.username + '&pwd=' + params.password,
      method: 'get'
    });
  },
  Weberror: function Weberror() {
    return request({
      url: '/web/platform/logout',
      method: 'get'
    });
  },
  getIndex: function getIndex() {
    return request({
      url: '/web/platform/statsStreet',
      method: 'get'
    });
  },
  getdudaoyuan: function getdudaoyuan() {
    return request({
      url: '/web/platform/getRecycler',
      method: 'get'
    });
  },
  gethuodong: function gethuodong() {
    return request({
      url: '/web/platform/activity/timeline',
      method: 'get'
    });
  },
  dudaoshenhe: function dudaoshenhe(params) {
    return request({
      url: '/operation/recycler/auditSmartBin?recyclerId=' + params.recyclerId + '&status=' + params.status,
      method: 'get'
    });
  },
  dudaodaka: function dudaodaka(params) {
    return request({
      url: '/inspect/punchInRecord?userId=' + params,
      method: 'get'
    });
  },
  danweilist: function danweilist(params) {
    return request({
      url: '/operation/owner/company/list',
      method: 'get',
      params: params
    });
  },
  homeVisitlist: function homeVisitlist(params) {
    return request({
      url: '/operation/homeVisit/inUser',
      method: 'get',
      params: params
    });
  },
  ownerCashlist: function ownerCashlist(params) {
    return request({
      url: '/operation/owner/ownerCash',
      method: 'get',
      params: params
    });
  },
  exportOwnerCash: function exportOwnerCash(params) {
    return request({
      url: '/operation/owner/exportOwnerCash',
      method: 'post',
      responseType: 'blob',
      params: params
    });
  },
  checkRecyclingBins: function checkRecyclingBins(param) {
    // 检查设备是否能删除
    return request({
      url: '/operation/recycling/bins/checkRecyclingBins/' + param,
      method: 'get'
    });
  },
  dangerhuishou: function dangerhuishou(param) {
    // 删除回收箱
    return request({
      url: '/operation/recycling/bins/bins/' + param,
      method: 'delete'
    });
  },
  updateRecyclingBinsMbIdChange: function updateRecyclingBinsMbIdChange(params) {
    return request({
      url: '/recycling/binsChange/updateRecyclingBinsMbIdChange',
      method: 'post',
      params: params
    });
  },
  xiugaihuishou: function xiugaihuishou(params) {
    return request({
      url: '/operator/kitchenWaste/addUserKitchenRecord',
      method: 'post',
      params: params
    });
  },
  xiugaihuishouxiang: function xiugaihuishouxiang(params) {
    return request({
      url: '/operation/recycling/bins/addOrUpdateRecyBins',
      method: 'post',
      params: params
    });
  },
  exportlist: function exportlist() {
    return request({
      url: '/operation/owner/exportlist',
      method: 'get'
    });
  },
  exporterweima: function exporterweima(params) {
    return request({
      url: '/open/qrcode/picture/bin?content=' + params,
      method: 'get',
      responseType: 'blob',
      params: params
    });
  },
  dayinhuishouxiang: function dayinhuishouxiang(params) {
    return request({
      url: '/operation/recycling/bins/printbin',
      method: 'post',
      params: params
    });
  },
  deleteexport: function deleteexport(params) {
    return request({
      url: '/operation/owner/deleteexport?key_name=' + params,
      method: 'DELETE'
    });
  },
  exportusers: function exportusers(params) {
    return request({
      url: '/operation/owner/exportOwner',
      method: 'get',
      params: params
    });
  },
  exportVisitRecord: function exportVisitRecord(params) {
    return request({
      url: '/operation/homeVisit/exportInUser',
      method: 'get',
      params: params
    });
  },
  shangbaocishu: function shangbaocishu(params) {
    return request({
      url: '/operation/recycling/bins/records',
      method: 'get',
      params: params
    });
  },
  getshoplist: function getshoplist(params) {
    return request({
      url: '/operation/shopintegra/list',
      method: 'get',
      params: params
    });
  },
  addshoplist: function addshoplist(params) {
    return request({
      url: '/operation/shopintegra/addShop',
      method: 'post',
      params: params
    });
  },
  dangershoplist: function dangershoplist(params) {
    return request({
      url: '/operation/shopintegra/updateShop',
      method: 'delete',
      params: params
    });
  },
  xiugaishoplist: function xiugaishoplist(params) {
    return request({
      url: '/operation/shopintegra/updateShop',
      method: 'post',
      params: params
    });
  },
  xiugaibeizhu: function xiugaibeizhu(params) {
    return request({
      url: '/operation/owner/companyRemarks?locationId=' + params.locationId,
      method: 'post',
      params: params
    });
  },
  jifenhuanlist: function jifenhuanlist(params) {
    return request({
      url: '/operation/shopintegra/exchangelist',
      method: 'get',
      params: params
    });
  },
  exportjifenhuanlist: function exportjifenhuanlist(params) {
    return request({
      url: '/operation/shopintegra/exportOwnerData',
      method: 'get',
      responseType: 'blob',
      params: params
    });
  },
  getljprice: function getljprice(params) {
    return request({
      url: '/operation/owner/indicative/price',
      method: 'get',
      params: params
    });
  },
  gettaizhanglist: function gettaizhanglist(params) {
    return request({
      url: '/operation/owner/companyInfo',
      method: 'get',
      params: params
    });
  },
  getshujushenhe: function getshujushenhe(params) {
    return request({
      url: '/recycle/KitchenBinList',
      method: 'get',
      params: params
    });
  },
  shujushenheup: function shujushenheup(data) {
    return request({
      url: '/recycle/updateKitchenBinList',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  },
  xiugaishuju: function xiugaishuju(params) {
    return request({
      url: '/recycle/updateKitchenBinRole',
      method: 'get',
      params: params
    });
  },
  exportdaka: function exportdaka(params) {
    return request({
      url: '/operation/recycler/newExcelPunch',
      method: 'post',
      responseType: 'blob',
      params: params
    });
  },
  dakaxiangq: function dakaxiangq(params) {
    return request({
      url: '/operation/recycler/punchRecord',
      method: 'post',
      params: params
    });
  },
  dakaxiangqingjilu: function dakaxiangqingjilu(params) {
    return request({
      url: '/operation/recycler/punchRecordList',
      method: 'post',
      params: params
    });
  },
  chuyuceping: function chuyuceping(params) {
    return request({
      url: '/operator/kitchenWaste/assess',
      method: 'post',
      params: params
    });
  },
  zhinengjiankong: function zhinengjiankong(params) {
    return request({
      url: '/operation/fl/monitorList',
      method: 'get',
      params: params
    });
  },

  /**
   *
   * @param {*} params
   * Name	Description
  channelNo
  integer($int32)
  (query)
  通道号,，非必选，默认为1
  
  deviceSerial *
  string
  (query)
  直播源，例如427734222，均采用英文符号，限制50个
  
  protocol
  integer($int32)
  (query)
  流播放协议，1-ezopen、2-hls、3-rtmp，默认为1
  
  quality
  integer($int32)
  (query)
  视频清晰度，1-高清（主码流）、2-流畅（子码流）
  
  type
  string
  (query)
  ezopen协议地址的类型，1-预览，2-本地录像回放，3-云存储录像回放，非必选，默认为1
   */
  getJiankongInfo: function getJiankongInfo(params) {
    return request({
      url: '/operation/fl/monitor',
      method: 'get',
      params: params
    });
  },
  Addjiankong: function Addjiankong(params) {
    return request({
      url: '/operation/fl/mergeMonitor',
      method: 'post',
      params: params
    });
  },
  huishoupaidan: function huishoupaidan(params) {
    return request({
      url: '/operation/recycler/orderRole?userId=' + params,
      method: 'get',
      params: params
    });
  },
  shenheimgs: function shenheimgs(params) {
    return request({
      url: '/recycle/domnloadimg?id=' + params,
      responseType: 'blob',
      method: 'get'
    });
  },
  studentimgs: function studentimgs(params) {
    return request({
      url: '/operation/order/exportStudentPic?ids=' + params,
      responseType: 'blob',
      method: 'get'
    });
  },
  webshipings: function webshipings() {
    return request({
      url: '/toTaiDa/tokenValue',
      method: 'post'
    });
  }
}, _defineProperty(_Service, "webshipings", function webshipings() {
  return request({
    url: '/toTaiDa/tokenValue',
    method: 'post'
  });
}), _defineProperty(_Service, "chuyudaitongji", function chuyudaitongji(params) {
  return request({
    url: '/operator/kitchenbin/accountList',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "duihuanjilist", function duihuanjilist(params) {
  return request({
    url: '/operation/fl/smjList',
    mrthod: 'post',
    params: params
  });
}), _defineProperty(_Service, "exitduihuanji", function exitduihuanji(params) {
  return request({
    url: '/operation/fl/modifySmj',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "shoplists", function shoplists(params) {
  return request({
    url: '/operation/fl/smjDetails',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "editshoplists", function editshoplists(params) {
  return request({
    url: '/operation/fl/editGoods',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "rukuduihuanji", function rukuduihuanji(params) {
  return request({
    url: '/operation/fl/smjRecord',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "duihuanjiruku", function duihuanjiruku(params) {
  return request({
    url: '/operation/fl/smjGoods',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "wuziruku", function wuziruku(params) {
  return request({
    url: '/operator/kitchenbin/inStockList',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "wuzichuku", function wuzichuku(params) {
  return request({
    url: '/operator/kitchenbin/outStockList',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "KitchenBag", function KitchenBag(params) {
  return request({
    url: '/operation/recycler/recyclerStatus?userId=' + params,
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "xiaoqulaji", function xiaoqulaji(params) {
  return request({
    url: '/operation/userAddress/getRegionRecoveryType',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getAllRegionType", function getAllRegionType() {
  return request({
    url: '/operation/userAddress/getAllRegionType',
    method: 'get'
  });
}), _defineProperty(_Service, "RegionRecoveryType", function RegionRecoveryType(params) {
  return request({
    url: '/operation/userAddress/modifyRegionRecoveryType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(params)
  });
}), _defineProperty(_Service, "getTypes", function getTypes() {
  return request({
    url: '/operation/recycling/bins/getBinType',
    method: 'get'
  });
}), _defineProperty(_Service, "shebeisheshi", function shebeisheshi(params) {
  return request({
    url: '/recycle/updateProtoRole',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "shebeiprint", function shebeiprint(params) {
  return request({
    url: '/operation/order/selfCode',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "exportLists", function exportLists(params) {
  return request({
    url: '/inspect/problemReportExport',
    responseType: 'blob',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "Completepayment", function Completepayment(params) {
  return request({
    url: '/operation/order/updateOrderStatus',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "zhinengdayinji", function zhinengdayinji(params) {
  return request({
    url: '/operation/fl/printList',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "Addpriter", function Addpriter(params) {
  return request({
    url: '/operation/fl/modifyPrint',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "Addspriter", function Addspriter(params) {
  return request({
    url: '/operation/fl/addPrint',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "Priterstatus", function Priterstatus(params) {
  return request({
    url: '/operation/fl/statusPrint',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "jiankongstatus", function jiankongstatus(params) {
  return request({
    url: '/operation/fl/updateMonitorStatus',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "cloudaccount", function cloudaccount(params) {
  return request({
    url: '/operation/accountCould2/queryList',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getAccountWasteRecord", function getAccountWasteRecord(params) {
  return request({
    url: '/operation/accountCould2/accountWasteRecord',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "exportAccountWasteRecord", function exportAccountWasteRecord(params) {
  return request({
    url: '/operation/accountCould2/exportAccountWasteRecord',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getAccountWorkRecordParam", function getAccountWorkRecordParam(params) {
  return request({
    url: '/operation/accountCould2/accountWorkRecordParam',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "exportAccountWorkRecordParam", function exportAccountWorkRecordParam(params) {
  return request({
    url: '/operation/accountCould2/exportAccountWorkRecordParam',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "edituserzhuang", function edituserzhuang(params) {
  return request({
    url: '/operation/accountCould2/updateUserPassword',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "editStatususer", function editStatususer(params) {
  return request({
    url: '/operation/accountCould2/updateUserStates',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "selectdudao", function selectdudao(params) {
  return request({
    url: '/operator/kitchenbin/recyclerList',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "feetbangdai", function feetbangdai(params) {
  return request({
    url: '/operator/kitchenbin/addKitchenBag',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "ExportCoins", function ExportCoins(params) {
  return request({
    url: '/operation/owner/exporSortUserCoin',
    method: 'post',
    responseType: 'blob',
    params: params
  });
}), _defineProperty(_Service, "getresource", function getresource(params) {
  return request({
    url: '/operator/resource/get/manage',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "editresource", function editresource(params) {
  return request({
    url: '/operator/resource/insert/manage',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "tabledak", function tabledak(params) {
  return request({
    url: '/operation/recycler/newPunchRecordTable',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "charubeizhu", function charubeizhu(params) {
  return request({
    url: '/operation/recycler/newRemark',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getCode", function getCode() {
  return request({
    url: '/defaultKaptcha/productCode',
    method: 'get',
    responseType: 'blob'
  });
}), _defineProperty(_Service, "VerificationCode", function VerificationCode(params) {
  return request({
    url: '/defaultKaptcha/checkCode',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "exportwenti", function exportwenti(params) {
  return request({
    url: '/inspect/exportPic',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}), _defineProperty(_Service, "updatechengguo", function updatechengguo(params) {
  return request({
    url: '/operation/recycler/resultsUpload',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getPricelist", function getPricelist() {
  return request({
    url: '/operation/owner/getPriceList',
    method: 'get'
  });
}), _defineProperty(_Service, "setPricesdef", function setPricesdef(params) {
  return request({
    url: '/operation/owner/setPrice',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "deletePricesdef", function deletePricesdef(params) {
  return request({
    url: '/operation/owner/deletePrice',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "websitlist", function websitlist(params) {
  return request({
    url: '/website/consultList',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "websitedit", function websitedit(params) {
  return request({
    url: '/website/consultRevise',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "editwebsit", function editwebsit(params) {
  return request({
    url: '/website/modifyRemark',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "useraddresshis", function useraddresshis(params) {
  return request({
    url: '/operation/owner/location/history',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "updatedudaoyuan", function updatedudaoyuan(params) {
  return request({
    url: '/operation/recycler/recycleRole',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "searchusercard", function searchusercard(params) {
  return request({
    url: '/operation/owner/baseInfo?cardNumber=' + params,
    method: 'get'
  });
}), _defineProperty(_Service, "shoplistxuni", function shoplistxuni(params) {
  return request({
    url: '/operation/shopintegra/virtualGoods?shopId=' + params,
    method: 'get'
  });
}), _defineProperty(_Service, "editshopcdks", function editshopcdks(params) {
  return request({
    url: '/operation/shopintegra/updateCdk',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "surecdks", function surecdks(params) {
  return request({
    url: '/operation/shopintegra/verifyCdk?cdk=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "deletecdk", function deletecdk(params) {
  return request({
    url: '/operation/shopintegra/deleteCdk?cdkId=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "addstreetprice", function addstreetprice(params) {
  return request({
    url: '/operation/owner/setting/add',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "seestreetprice", function seestreetprice(params) {
  return request({
    url: '/operation/owner/setting/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "updatestreetprice", function updatestreetprice(params) {
  return request({
    url: '/operation/owner/setting/update',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getmanageActivity", function getmanageActivity(params) {
  return request({
    url: '/operation/style/type/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "manageActivity", function manageActivity(params) {
  return request({
    url: '/operation/style/type/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "addoreditmanages", function addoreditmanages(params) {
  return request({
    url: '/operation/style/manageActivity',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "seemanageslist", function seemanageslist(params) {
  return request({
    url: '/operation/style/type/history',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getmanageuser", function getmanageuser(params) {
  return request({
    url: '/operation/style/user/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "shenhemanuser", function shenhemanuser(params) {
  return request({
    url: '/operation/style/user/validate',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "yijianshenheuser", function yijianshenheuser(params) {
  return request({
    url: '/operation/style/user/many',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "delshenheuser", function delshenheuser(params) {
  return request({
    url: '/operation/style/user/del',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "WebsitewebNews", function WebsitewebNews(params) {
  return request({
    url: '/website/newsList',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "WebsiteaddNews", function WebsiteaddNews(params) {
  return request({
    url: '/website/addNews',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "WebsitenewsDetails", function WebsitenewsDetails(params) {
  return request({
    url: '/website/newsDetails',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "WebsitenewsSetUp", function WebsitenewsSetUp() {
  return request({
    url: '/website/newsSetUp',
    method: 'get'
  });
}), _defineProperty(_Service, "WebsitesetUp", function WebsitesetUp(params) {
  return request({
    url: '/website/setUp',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  });
}), _defineProperty(_Service, "Websiteclient", function Websiteclient() {
  return request({
    url: '/website/client',
    method: 'get'
  });
}), _defineProperty(_Service, "WebsitenewsDetails", function WebsitenewsDetails(params) {
  return request({
    url: '/website/client',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "newexportuserlist", function newexportuserlist(params) {
  return request({
    url: '/operation/owner/residentUserExcel',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "newchuyulists", function newchuyulists(params) {
  return request({
    url: '/operation/owner/kitchenBag',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "newschuyudetails", function newschuyudetails(params) {
  return request({
    url: '/operation/owner/kitchenRecord',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "allinshop", function allinshop(params) {
  return request({
    url: '/operation/shopintegra/batchAdd',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "shoptables", function shoptables(params) {
  return request({
    url: '/operation/shopintegra/codeGoods',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "shopingonff", function shopingonff(params) {
  return request({
    url: '/operation/shopintegra/onOff',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "deleteshopoff", function deleteshopoff(params) {
  //删除兑换码id
  return request({
    url: '/operation/shopintegra/deleteCdk',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "batchDeleteShopoff", function batchDeleteShopoff(params) {
  // 批量删除兑换码ids
  return request({
    url: '/operation/shopintegra/batchDelete',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "duihuanjidetailsd", function duihuanjidetailsd(params) {
  return request({
    url: '/operation/shopintegra/userCode',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "deletenewslist", function deletenewslist(params) {
  return request({
    url: '/website/deleteNews',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "logInlists", function logInlists(params) {
  return request({
    url: '/sys/loginHistory',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getluckList", function getluckList(params) {
  return request({
    url: '/operator/prize/prizes',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "addluckshop", function addluckshop(params) {
  return request({
    url: '/operator/prize/add',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getzhongLucky", function getzhongLucky(params) {
  return request({
    url: '/operator/prize/prizeUser',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getzhongLuckyExport", function getzhongLuckyExport(params) {
  return request({
    url: '/operator/prize/prizeUserExport',
    method: 'get',
    responseType: 'blob',
    params: params
  });
}), _defineProperty(_Service, "editluckshop", function editluckshop(params) {
  return request({
    url: '/operator/prize/edit',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getluckyRule", function getluckyRule() {
  return request({
    url: '/operator/prize/getRule',
    method: 'get'
  });
}), _defineProperty(_Service, "setluckyRule", function setluckyRule(params) {
  return request({
    url: '/operator/prize/setup',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "addluckyRule", function addluckyRule(params) {
  return request({
    url: '/operator/prize/addSetup',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "updateluckyRule", function updateluckyRule(params) {
  return request({
    url: '/operator/prize/updateSetup',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "Luckyhexiao", function Luckyhexiao(params) {
  return request({
    url: '/operator/prize/hexiao',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getProvinceCityDistrict", function getProvinceCityDistrict(params) {
  // 省
  return request({
    url: '/operation/chinaRegion/getChinaRegions',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getScreenCustom", function getScreenCustom(params) {
  // 定制基础类
  return request({
    url: '/operation/statsPlatScreenCustom/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "manageRole", function manageRole(params) {
  // 定制基础类
  return request({
    url: '/operation/recycler/manageRole',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "deviceManageRole", function deviceManageRole(params) {
  // 回收员-智能厢房权限设置
  return request({
    url: '/operation/recycler/deviceManageRole',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "getDatiResult", function getDatiResult(params) {
  // 答题-答题记录
  return request({
    url: '/operation/problem/op/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "exportDatiResult", function exportDatiResult(params) {
  // 答题记录导出
  return request({
    url: '/operation/problem/excel/list',
    method: 'post',
    responseType: 'blob',
    params: params
  });
}), _defineProperty(_Service, "cumulativeQuery", function cumulativeQuery(params) {
  // 数据统计-积分查询-累计积分排名
  return request({
    url: '/operation/coin/cumulative/query',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "surplusQuery", function surplusQuery(params) {
  // 数据统计-积分查询-剩余积分排名
  return request({
    url: '/operation/coin/surplus/query',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getNotice", function getNotice(params) {
  return request({
    url: '/operation/coin/notice/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "addOrUpdateNotice", function addOrUpdateNotice(params) {
  return request({
    url: '/operation/coin/notice/edit',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "deleteNotice", function deleteNotice(params) {
  return request({
    url: '/operation/coin/notice/delete',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getSceneLocation", function getSceneLocation(params) {
  return request({
    url: '/aiPicDataset/getSceneLocation?street=' + params,
    method: 'get'
  });
}), _defineProperty(_Service, "picRecordList", function picRecordList(params) {
  return request({
    url: '/aiPicDataset/picRecord',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "deleteRecord", function deleteRecord(params) {
  return request({
    url: '/aiPicDataset/delete/record?id=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "ruleSetList", function ruleSetList(params) {
  return request({
    url: '/aiPicDataset/ruleSet/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "addOrUpdateRule", function addOrUpdateRule(params) {
  return request({
    url: '/aiPicDataset/ruleSet',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: params
  });
}), _defineProperty(_Service, "deleteRule", function deleteRule(params) {
  return request({
    url: '/aiPicDataset/deleteRule?id=' + params,
    method: 'get'
  });
}), _defineProperty(_Service, "getTags", function getTags(params) {
  return request({
    url: '/aiPicDataset/getLabel?level=1',
    method: 'get'
  });
}), _defineProperty(_Service, "personalOrderList", function personalOrderList(params) {
  return request({
    url: '/operation/order/personal',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "startOrderListNew", function startOrderListNew(params) {
  return request({
    url: '/manage/order/start/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "personalOrderListNew", function personalOrderListNew(params) {
  return request({
    url: '/manage/order/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "orderDelNew", function orderDelNew(orderId) {
  return request({
    url: '/manage/order/start/delete?orderId=' + orderId,
    method: 'post'
  });
}), _defineProperty(_Service, "orderBackNew", function orderBackNew(orderId) {
  return request({
    url: '/manage/order/del/reject?orderId=' + orderId,
    method: 'post'
  });
}), _defineProperty(_Service, "seestreetpriceNew", function seestreetpriceNew(params) {
  return request({
    url: '/operation/owner/setting/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "updatestreetpriceNew", function updatestreetpriceNew(params) {
  console.log(params);
  return request({
    url: '/operation/owner/setting/update',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "shebeiprintNew", function shebeiprintNew(params) {
  return request({
    url: '/manage/order/printer',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "exportOrderList", function exportOrderList(params) {
  // 订单记录导出
  return request({
    url: '/manage/order/excel',
    method: 'post',
    responseType: 'blob',
    params: params
  });
}), _defineProperty(_Service, "getGoodsType", function getGoodsType() {
  return request({
    url: '/manage/order/goods/type',
    method: 'get'
  });
}), _defineProperty(_Service, "downloadPdf", function downloadPdf(url) {
  return request({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}), _defineProperty(_Service, "batchHuodongCreate", function batchHuodongCreate(params) {
  return request({
    url: '/operation/stats/batchHuodongCreate?ids=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "batchZhouziliaoCreate", function batchZhouziliaoCreate(params) {
  return request({
    url: '/operation/stats/batchZhouziliaoCreate?ids=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "getTypeList", function getTypeList() {
  return request({
    url: '/operation/point/device/type/list',
    method: 'get'
  });
}), _defineProperty(_Service, "addType", function addType(params) {
  return request({
    url: '/operation/point/add/type',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "updateType", function updateType(params) {
  return request({
    url: '/operation/point/update/type',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "getPointList", function getPointList(params) {
  return request({
    url: '/operation/point/data/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "addPoint", function addPoint(params) {
  return request({
    url: '/operation/point/manage/add',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "updatePoint", function updatePoint(params) {
  return request({
    url: '/operation/point/manage/update',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "deletePoint", function deletePoint(params) {
  return request({
    url: '/operation/point/manage/delete?id=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "pointPrintPic", function pointPrintPic(params) {
  return request({
    url: '/operation/point/qrcode/picture/print?mark=' + params,
    method: 'post'
  });
}), _defineProperty(_Service, "pointDownPic", function pointDownPic(params) {
  return request({
    url: '/operation/point/qrcode/picture/download?content=' + params,
    method: 'post',
    responseType: 'blob'
  });
}), _defineProperty(_Service, "getPointLogList", function getPointLogList(params) {
  return request({
    url: '/operation/point/modify/record',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "pointAndDevice", function pointAndDevice(params) {
  return request({
    url: '/operation/point/and/device?mark=' + params,
    method: 'get'
  });
}), _defineProperty(_Service, "getVoluntarysettingList", function getVoluntarysettingList(params) {
  return request({
    url: '/operation/rule/set/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "addVoluntarysetting", function addVoluntarysetting(params) {
  return request({
    url: '/operation/add/rule/set',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "updateVoluntarysetting", function updateVoluntarysetting(params) {
  return request({
    url: '/operation/edit/rule/set',
    method: 'post',
    params: params
  });
}), _defineProperty(_Service, "gettVoluntaryok", function gettVoluntaryok(params) {
  return request({
    url: '/operation/supervision/signup/list',
    method: 'get',
    params: params
  });
}), _defineProperty(_Service, "editVoluntaryok", function editVoluntaryok(params) {
  return request({
    url: '/operation/supervision/data/check',
    method: 'post',
    params: params
  });
}), _Service);
export default Service;