import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
export default {
  name: 'Chaoshishangbao',
  data: function data() {
    return {
      mbId: '',
      tableData: [],
      name: ''
    };
  },
  created: function created() {
    this.mbId = this.$route.query.mbId;
    this.name = this.$route.query.name;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      Service.chaoshishangbao(this.mbId).then(function (res) {
        console.log(res.data);
        _this.tableData = res.data;
      });
    }
  }
};