import request from '@/utils/request'; // 分拣场地web-api

var Service = {
  // 分拣场地开始
  getPlacesList: function getPlacesList(params) {
    // 加载分拣场地页面
    return request({
      url: '/kSortingPlace/getList',
      method: 'get',
      params: params
    });
  },
  updatePlace: function updatePlace(params) {
    return request({
      url: '/kSortingPlace/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  checkPlaceDelete: function checkPlaceDelete(params) {
    return request({
      url: '/kSortingPlace/checkDelete?mbId=' + params,
      method: 'get'
    });
  },
  delPlace: function delPlace(params) {
    return request({
      url: '/kSortingPlace/delete?mbId=' + params,
      method: 'post'
    });
  },
  // 分拣场下拉列表
  getPlaces: function getPlaces() {
    return request({
      url: '/kSortingPlace/getKitchenSortingPlaces',
      method: 'get'
    });
  },
  // 分拣场地结束
  // RFID开始
  getRfidList: function getRfidList(params) {
    // 加载分拣场地页面
    return request({
      url: '/kSortingRfid/getList',
      method: 'get',
      params: params
    });
  },
  updateRfid: function updateRfid(params) {
    return request({
      url: '/kSortingRfid/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  insertRfid: function insertRfid(params) {
    return request({
      url: '/kSortingRfid/insert',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  // RFID结束
  getReport1: function getReport1(params) {
    // 加载分拣场地页面
    return request({
      url: '/kSortingData/getKitchenSortingDataReport1',
      method: 'get',
      params: params
    });
  },
  getReport2: function getReport2(params) {
    // 加载分拣场地页面
    return request({
      url: '/kSortingData/getKitchenSortingDataReport2',
      method: 'get',
      params: params
    });
  },
  getReport3: function getReport3(params) {
    // 加载分拣场地页面
    return request({
      url: '/kSortingData/getKitchenSortingDataReport3',
      method: 'get',
      params: params
    });
  }
  /*del(params) {
    return request({
      url: '/recyclingBinsQr/delete?unionId='+params,
      method: 'post'
    })
  },
  queryAllRecyclingBins(){
    return request({
      url: '/operation/recycling/bins/queryAllRecyclingBins',
      method: 'get'
    })
  },
  add(params) {
    return request({
      url: '/recyclingBinsQr/insert',
      method: 'post',
  	headers: {
  		'Content-Type': 'application/json'
  	},
  	data: params
    })
  },
  update(params) {
    return request({
      url: '/recyclingBinsQr/update',
      method: 'post',
  	headers: {
  		'Content-Type': 'application/json'
  	},
  	data: params
    })
  }*/

};
export default Service;