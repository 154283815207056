//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
import { parseTime } from '@/utils';
export default {
  name: 'Wuziguanli',
  components: {
    jiedao: jiedao
  },
  data: function data() {
    return {
      listLoading: false,
      form: {
        tel: ''
      },
      list: null,
      total: 0,
      listQuery: {
        street: '',
        goods: '厨余袋'
      },
      searchStreet: '',
      listsd: [{
        info: '厨余袋',
        type: '厨余袋'
      }, {
        info: '厨余桶',
        type: '厨余桶'
      }]
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    exportExcel: function exportExcel() {
      location.href = '/operator/material/exportMaterialRecordExcel?startTime=' + this.listQuery.startTime + '&endTime=' + this.listQuery.endTime + '&currentPage=1' + '&numOfPage=20' + '&phone=' + this.listQuery.phone;
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.chuyudaitongji(this.listQuery).then(function (res) {
        setTimeout(function () {
          _this.list = res.data.data;
          _this.listLoading = false;
        }, 1000);
      });
    }
  }
};