import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/recyclertracks';
import jiedao from '@/components/street/jiedao';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
var map = null;
var overlaysList = null;
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      listQuery: {
        weixinId: this.$route.query.weixinId,
        nowTime: this.$route.query.nowTime
      },
      positions: []
    };
  },
  created: function created() {
    this.initMap();
  },
  mounted: function mounted() {
    var _this = this;

    setTimeout(function () {
      _this.getListMap(); // 放到tab

    }, 1000);
    this.initMap();
  },
  methods: {
    // 地图开始
    initMap: function initMap() {
      map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        zoom: 13,
        center: new AMap.LngLat(117.706870, 39.020960) // mapStyle: 'amap://styles/darkblue'

      });
    },
    addMarks: function addMarks(item) {
      var lon = item.newLon;
      var lat = item.newLat;
      var workTimeHm = item.workTimeHm;
      var id = item.id;
      var arr = [];
      arr.push([lon, lat]);
      var infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -50)
      });
      var self = this;
      var icon = new AMap.Icon({});
      icon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(60, 101),
        // 图标的取图地址
        image: 'http://pic.touduila.com/Fp_XxfNfjBxyHWrmExLcB5nvMxE0',
        // 图标所用图片大小
        imageSize: new AMap.Size(25, 26)
      });
      var marker = new AMap.Marker({
        icon: icon,
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setLabel({
        offset: new AMap.Pixel(0, 0),
        //设置文本标注偏移量
        content: "<div class='info'>" + workTimeHm + "(" + id + ")</div>",
        //设置文本标注内容
        direction: 'top' //设置文本标注方位

      });
      marker.setMap(map);
    },
    getListMap: function getListMap() {
      var _this2 = this;

      this.positions = []; // this.positions.push([117.700138,39.095539]);

      Service.getSelfTracks({
        nowTime: this.listQuery.nowTime,
        weixinId: this.listQuery.weixinId
      }).then(function (response) {
        var _AMap$Polyline;

        map.clearMap(map.getAllOverlays('marker'));
        response.data.data.forEach(function (item) {
          _this2.addMarks(item); // this.positions.push([item.newLon,item.newLat]);


          if (item.newLon) {
            _this2.positions.push([item.newLon, item.newLat]);
          }

          _this2.fitMap();
        }); // console.log(this.positions);
        // 1.把想自适应的部分的点装在一个透明的覆盖物图层里

        var polyline = new AMap.Polyline((_AMap$Polyline = {
          path: _this2.positions,
          //以5个点的坐标创建一个隐藏的多边形
          map: map,
          strokeColor: '#3366FF',
          // 线颜色
          strokeOpacity: 1,
          // 线透明度
          geodesic: false
        }, _defineProperty(_AMap$Polyline, "strokeOpacity", 1), _defineProperty(_AMap$Polyline, "strokeWeight", 8), _defineProperty(_AMap$Polyline, "strokeStyle", "solid"), _defineProperty(_AMap$Polyline, "showDir", true), _defineProperty(_AMap$Polyline, "strokeDasharray", [10, 5]), _defineProperty(_AMap$Polyline, "ineJoin", 'round'), _defineProperty(_AMap$Polyline, "lineCap", 'round'), _AMap$Polyline));
        overlaysList = map.getAllOverlays('polyline'); //获取多边形图层
      });
    },
    fitMap: function fitMap() {
      // 自适应
      setTimeout(function () {
        map.setFitView(overlaysList);
      }, 0);
    } // 地图结束

  }
};