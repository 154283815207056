import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
/* Layout */

import Layout from "@/views/layout/Layout";
import Index from "@/views/layout/index";
/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/

export var constantRouterMap = [{
  path: "/redirect",
  component: Layout,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return import("@/views/redirect/index");
    }
  }]
}, {
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return import("@/views/login/authredirect");
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return import("@/views/errorPage/404");
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return import("@/views/errorPage/401");
  },
  hidden: true
},
/******增加隐藏的路径（跳转二级菜单+打印）需增加功能路径说明如：【活动管理-活动管理-下载打印】 */

/**活动管理-活动管理-下载打印 */

/**周资料统计-周资料统计-导表 */

/**周资料统计-周资料统计-导表 */
{
  path: "/printing",
  component: Index,
  redirect: "noredirect",
  meta: {
    title: "活动打印",
    icon: "house"
  },
  hidden: true,
  children: [{
    path: "printinghuodong",
    component: function component() {
      return import("@/views/printing/huodong");
    },
    name: "printinghuodong",
    meta: {
      title: "活动打印"
    }
  }, {
    path: "printingzhouziliao",
    component: function component() {
      return import("@/views/printing/zhouziliao");
    },
    name: "printingzhouziliao",
    meta: {
      title: "周资料打印"
    }
  }, {
    path: "printingzhouziliaoAll",
    component: function component() {
      return import("@/views/printing/zhouziliaoAll");
    },
    name: "printingzhouziliaoAll",
    meta: {
      title: "周资料打印"
    }
  }]
}, {
  path: "/exports",
  component: Layout,
  redirect: "index",
  hidden: true,
  meta: {
    title: "导出任务",
    icon: "international"
  },
  children: [{
    path: "dashboard",
    component: function component() {
      return import("@/views/layout/daochu");
    },
    name: "Dashboard",
    meta: {
      title: "导出任务",

      /**数据统计-居民用户数据-导出excel */
      icon: "dashboard"
    }
  }]
}, {
  path: "/common",
  component: Layout,
  redirect: "index",
  hidden: true,
  meta: {
    title: "公用二级菜单跳转，不包括menu里面的菜单",
    icon: "international"
  },
  children: [{
    path: "chaoshishangbao",
    component: function component() {
      return import("@/views/device/chaoshishangbao");
    },
    name: "chaoshishangbao",
    hidden: true,
    meta: {
      title: "超时上报"
      /**设备管理-智能设备列表-心跳时间轨迹，兑换机管理-心跳时间轨迹 */

    }
  }, {
    path: "secondusers",
    hidden: true,
    component: function component() {
      return import("@/views/front/seconduser");
    },
    name: "secondusers",
    meta: {
      title: "用户信息"
      /**居民用户管理-居民用户列表-厨余袋物资+投递记录 */

    }
  }, {
    path: "index",
    component: function component() {
      return import("@/views/order/index");
    },
    name: "order",
    meta: {
      title: "个人回收订单"
      /**居民用户管理-居民用户列表-可回收订单 */

    }
  }, {
    path: "sysLoginlist",
    component: function component() {
      return import("@/views/sys/sysLoginlist");
    },
    name: "登录log",
    meta: {
      title: "登录记录"
      /**账号权限-账号管理-登录log */

    },
    hidden: true
  }, {
    hidden: true,
    path: "/front/daka",
    component: function component() {
      return import("@/views/huishou/daka");
    },
    name: "dakajilu",
    meta: {
      title: "打卡记录"
      /**回收员管理-个体回收员-查看 */

    }
  }, {
    path: "/device/shoplists",
    component: function component() {
      return import("@/views/device/shoplists");
    },
    name: "shoplists",
    hidden: true,
    meta: {
      title: "商品详情"
      /**兑换机管理-查看详情a- */

    }
  }, {
    path: "/device/rukulists",
    component: function component() {
      return import("@/views/device/rukulists");
    },
    name: "rukulists",
    hidden: true,
    meta: {
      title: "入库记录"
      /**兑换机管理-查看详情-入库记录 */

    }
  }, {
    hidden: true,
    path: "/front/jifenmingxi",
    component: function component() {
      return import("@/views/front/jifenmingxi");
    },
    name: "jifenmingxi",
    meta: {
      title: "积分明细"
      /**居民用户管理-居民用户列表-中的积分 */

    }
  }, {
    hidden: true,
    path: "/front/kaoqing",
    component: function component() {
      return import("@/views/huishou/getikao");
    },
    name: "huishouyuankaoqing",
    meta: {
      title: "回收员考勤"
      /**个体回收员-查看详情 */

    }
  }, {
    hidden: true,
    path: "/front/taizhang",
    component: function component() {
      return import("@/views/front/taizhang");
    },
    name: "单位台账",
    meta: {
      title: "单位台账"
      /** */

    }
  }, {
    path: "/carbon/recordtype",
    component: function component() {
      return import("@/views/carbon/typerecord");
    },
    name: "recordtype",
    hidden: true,
    meta: {
      title: "操作列表"
      /** 减碳打卡-打卡类型-操作记录 */

    }
  }, {
    path: "huodongbaoming",
    component: function component() {
      return import("@/views/tongji/baoming");
    },
    name: "huodongbaoming",
    hidden: true,
    meta: {
      title: "宣传活动"
      /**活动管理-活动管理-选择投对分类小程序下拉框列表（操作，点击报名人数） */

    }
  }, {
    path: "sysLoginlist",
    component: function component() {
      return import("@/views/sys/fhtsysLoginlist");
    },
    name: "登录分后台log",
    meta: {
      title: "登录记录"
      /**账号权限-账号管理-登录log */

    },
    hidden: true
  }]
}, {
  path: "/shujutongji",

  /**数据统计-平台数据统计-查看街道数据 */
  component: Layout,
  redirect: "index",
  hidden: true,
  children: [{
    hidden: true,
    path: "street",
    component: function component() {
      return import("@/views/data/street");
    },
    name: "datastreet",
    meta: {
      title: "街道统计"
    }
  }, {
    hidden: true,
    path: "region",
    component: function component() {
      return import("@/views/data/region");
    },
    name: "dataregion",
    meta: {
      title: "小区统计"
    }
  }, {
    hidden: true,
    path: "community",
    component: function component() {
      return import("@/views/data/community");
    },
    name: "datacommunity",
    meta: {
      title: "社区统计"
    }
  }]
}
/**
 * 二级跳转页面 把表access_authority的hidden设置为0
 * viewGp(e){
this.$router.push({
	path:'RecyclableGp',
	params: {
	　　datas: e
	}
})  
},
 */
// {
//   path: "/",
//   component: Layout,
//   redirect: "index",
//   // alwaysShow: true,
//   meta: {
//     title: "回收员管理",
//     icon: "user2"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/huishou/geti"),
//       name: "huishouyuan",
//       meta: {
//         title: "个体回收员"
//       }
//     },
//     {
//       path: "xiaoquhuishouyuan",
//       component: () => import("@/views/huishou/xiaoquhuishouyuan"),
//       name: "xiaoquhuishouyuan",
//       meta: {
//         title: "小区回收员"
//         // roles: ['admin']
//       }
//     }
//   ]
// }
];
export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
export var asyncRouterMap = [// {
//   path: "/exports",
//   component: Layout,
//   redirect: "index",
//   hidden: true,
//   meta: {
//     title: "导出任务",
//     icon: "international"
//   },
//   children: [
//     {
//       path: "dashboard",
//       component: () => import("@/views/layout/daochu"),
//       name: "Dashboard",
//       meta: {
//         title: "导出任务",
//         icon: "dashboard"
//       }
//     }
//   ]
// },
// {
// 	path: '/daping',
// 	component: Layout,
// 	redirect: 'index',
// 	meta: {
// 		title: '数据大屏',
// 		icon: 'international',
// 		roles: ['admin']
// 	},
// 	children: [
// 	]
// },

/*{
  path: "/shujutongji",
  component: Layout,
  redirect: "index",
  hidden: true,
  children: [
    {
      hidden: true,
      path: "street",
      component: () => import("@/views/data/street"),
      name: "datastreet",
      meta: {
        title: "街道统计"
      }
    },
    {
      hidden: true,
      path: "region",
      component: () => import("@/views/data/region"),
      name: "dataregion",
      meta: {
        title: "小区统计"
      }
    },
    {
      hidden: true,
      path: "community",
      component: () => import("@/views/data/community"),
      name: "datacommunity",
      meta: {
        title: "社区统计"
      }
    }
  ]
},*/

/* {
   path: "/konggangdudao",
   component: Layout,
   redirect: "noredirect",
   alwaysShow: true, 
   meta: {
     title: "督导员列表",
     roles: ["kgAdmin"],
     icon: "lock"
     
   },

   children: [
     {
       path: "index",
       component: () => import("@/views/konggangdudao/index"),
       name: "konggangdudao",
       meta: {
         title: "督导员列表"
       }
     },
     {
       path: "dakalist",
       component: () => import("@/views/konggangdudao/dakalist"),
       name: "dakalist",
       hidden: true,
       meta: {
         title: "打卡记录"
       }
     }
   ]
 },*/
// {
//   path: "/device",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "设备管理",
//     icon: "trash"
//   },
//   children: [
//     {
//       path: "map",
//       component: () => import("@/views/device/map"),
//       name: "lajixiangmap",
//       meta: {
//         title: "回收箱地图"
//       }
//     },
//     {
//       path: "list",
//       component: () => import("@/views/device/index"),
//       name: "lajixiang",
//       meta: {
//         title: "回收箱列表"
//       }
//     },
//     // {
//     //   path: 'index',
//     //   component: () => import('@/views/demo/index'),
//     //   name: 'zhongduanxianshi',
//     //   meta: {
//     //     title: '终端地图'
//     //   }
//     // },
//     {
//       path: "zhineng",
//       component: () => import("@/views/device/zhineng"),
//       name: "zhongduanguanli",
//       meta: {
//         title: "智能设备列表"
//       }
//     },
//     {
//       path: "duihuanji",
//       component: () => import("@/views/device/duihuanji"),
//       name: "duihuanjiguanli",
//       meta: {
//         title: "兑换机管理"
//       }
//     },
//     {
//       path: "shoplists",
//       component: () => import("@/views/device/shoplists"),
//       name: "shoplists",
//       hidden: true,
//       meta: {
//         title: "商品详情"
//       }
//     },
//     {
//       path: "rukulists",
//       component: () => import("@/views/device/rukulists"),
//       name: "rukulists",
//       hidden: true,
//       meta: {
//         title: "入库记录"
//       }
//     },
//     {
//       path: "chaoshishangbao",
//       component: () => import("@/views/device/chaoshishangbao"),
//       name: "chaoshishangbao",
//       hidden: true,
//       meta: {
//         title: "超时上报"
//       }
//     },
//     {
//       path: "deviceshangbao",
//       component: () => import("@/views/device/deviceshangbao"),
//       name: "deviceshangbao",
//       // hidden: true,
//       meta: {
//         title: "问题上报列表"
//       }
//     },
//     {
//       path: "devicejiankong",
//       component: () => import("@/views/device/jiankong"),
//       name: "jiankongshebei",
//       // hidden: true,
//       meta: {
//         title: "监控设备管理"
//       }
//     },
//     {
//       path: "Printermanagement",
//       component: () => import("@/views/device/Printer"),
//       name: "打印机管理",
//       // hidden: true,
//       meta: {
//         title: "打印机管理"
//       }
//     }
//   ]
// },
// {
//   path: '/map',
//   component: Layout,
//   redirect: 'noredirect',
//   meta: {
//     title: '业务版图',
//     icon: 'lock'
//   },
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/map/index'),
//       name: 'yewubantu',
//       meta: {
//         title: '业务版图'
//       }
//     }]
// },
// {
//   path: "/order",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "回收订单管理",
//     icon: "list",
//     roles: ["taidaAdmin", "admin"]
//   },
//   roles: ["admin"],
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/order/index"),
//       name: "order",
//       meta: {
//         title: "个人回收订单"
//       }
//     },
//     {
//       path: "danweiindex",
//       component: () => import("@/views/order/danweiindex"),
//       name: "danweiorder",
//       meta: {
//         title: "单位回收订单"
//       }
//     },
//     {
//       path: "studentindex",
//       component: () => import("@/views/order/studentindex"),
//       name: "studentindex",
//       meta: {
//         title: "学生回收订单"
//       }
//     },
//     {
//       path: "price",
//       component: () => import("@/views/front/price"),
//       name: "price",
//       meta: {
//         title: "设置回收价格"
//       }
//     }
//   ]
// },
// {
//   path: "/front",
//   component: Layout,
//   redirect: "noredirect",
//   // alwaysShow: true,
//   meta: {
//     title: "居民用户管理",
//     icon: "user"
//   },
//   roles: ["admin"],
//   children: [
//     {
//       path: "users",
//       component: () => import("@/views/front/users"),
//       name: "users",
//       meta: {
//         title: "居民用户列表"
//       }
//     },
//     {
//       path: "secondusers",
//       hidden: true,
//       component: () => import("@/views/front/seconduser"),
//       name: "secondusers",
//       meta: {
//         title: "用户信息"
//       }
//     },
//     {
//       path: "danweiusers",
//       component: () => import("@/views/front/danweiusers"),
//       name: "danweiusers",
//       meta: {
//         title: "单位用户列表",
//         roles: ["taidaAdmin", "admin"]
//       }
//     },
//     {
//       path: "student",
//       component: () => import("@/views/front/student"),
//       name: "student",
//       meta: {
//         title: "学生用户列表",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "shanghuusers",
//       component: () => import("@/views/front/shanghuusers"),
//       name: "shanghuusers",
//       meta: {
//         title: "商户/单位列表",
//         roles: ["admin"]
//       }
//     },
//     {
//       hidden: true,
//       path: "jifenmingxi",
//       component: () => import("@/views/front/jifenmingxi"),
//       name: "jifenmingxi",
//       meta: {
//         title: "积分明细",
//         roles: ["admin"]
//       }
//     },
//     {
//       hidden: true,
//       path: "kaoqing",
//       component: () => import("@/views/huishou/getikao"),
//       name: "huishouyuankaoqing",
//       meta: {
//         title: "回收员考勤",
//         roles: ["admin"]
//       }
//     },
//     {
//       hidden: true,
//       path: "daka",
//       component: () => import("@/views/huishou/daka"),
//       name: "dakajilu",
//       meta: {
//         title: "打卡记录",
//         roles: ["admin"]
//       }
//     },
//     {
//       hidden: true,
//       path: "taizhang",
//       component: () => import("@/views/front/taizhang"),
//       name: "单位台账",
//       meta: {
//         title: "单位台账",
//         roles: ["admin"]
//       }
//     }
//   ]
// },
// {
//   path: "/userdata",
//   component: Layout,
//   redirect: "noredirect",
//   // alwaysShow: true,
//   meta: {
//     title: "数据统计",
//     icon: "user"
//   },
//   roles: ["admin"],
//   children: [
//     {
//       path: "dashboard",
//       component: () => import("@/views/dashboard/index"),
//       name: "Dashboard",
//       meta: {
//         title: "面板",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "index",
//       component: () => import("@/views/data/index"),
//       name: "dataIndex",
//       meta: {
//         title: "平台数据统计"
//       }
//     },
//     {
//       path: "users",
//       component: () => import("@/views/userdata/users"),
//       name: "users",
//       meta: {
//         title: "居民用户数据"
//       }
//     },
//     {
//       path: "danweiusers",
//       component: () => import("@/views/userdata/homeVisit"),
//       name: "danweiusers",
//       meta: {
//         title: "入户拜访数据"
//       }
//     },
//     {
//       path: "ownerCash",
//       component: () => import("@/views/userdata/ownerCash"),
//       name: "ownerCash",
//       meta: {
//         title: "提现数据汇总"
//       }
//     }
//   ]
// },
// {
//   path: "/dizhi",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "地址维护",
//     icon: "house",
//     roles: ["admin",'kgAdmin']
//   },
//   children: [
//     {
//       path: "jiedao",
//       component: () => import("@/views/dizhi/jiedao"),
//       name: "jiedao",
//       meta: {
//         title: "街道管理"
//       }
//     },
//     {
//       path: "shequ",
//       component: () => import("@/views/dizhi/shequ"),
//       name: "shequ",
//       meta: {
//         title: "社区管理"
//       }
//     },
//     {
//       path: "xiaoqu",
//       component: () => import("@/views/dizhi/xiaoqu"),
//       name: "xiaoquguanli",
//       meta: {
//         title: "小区管理"
//       }
//     }
//   ]
// },
// {
//   path: "/huodong",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "活动管理",
//     roles: ["admin"],
//     icon: "house"
//   },
//   children: [
//     {
//       path: "huodong",
//       component: () => import("@/views/huodong/huodong"),
//       name: "huodong",
//       meta: {
//         title: "活动管理"
//       }
//     }
//   ]
// },
// {
//   path: "/printing",
//   component: Index,
//   redirect: "noredirect",
//   meta: {
//     title: "活动打印",
//     icon: "house",
//     roles: ["admin"],
//   },
//   hidden: true,
//   children: [
//     {
//       path: "printinghuodong",
//       component: () => import("@/views/printing/huodong"),
//       name: "printinghuodong",
//       meta: {
//         title: "活动打印"
//       }
//     }, {
//       path: "printingzhouziliao",
//       component: () => import("@/views/printing/zhouziliao"),
//       name: "printingzhouziliao",
//       meta: {
//         title: "周资料打印"
//       }
//     }, {
//       path: "printingzhouziliaoAll",
//       component: () => import("@/views/printing/zhouziliaoAll"),
//       name: "printingzhouziliaoAll",
//       meta: {
//         title: "周资料打印"
//       }
//     }
//   ]
// },
// {
//   path: "/dati",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "答题",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "dati",
//       component: () => import("@/views/dati/index"),
//       name: "dati",
//       meta: {
//         title: "答题"
//       }
//     },
//     {
//       path: "baoming",
//       component: () => import("@/views/dati/baoming"),
//       name: "baoming",
//       meta: {
//         title: "报名管理"
//       }
//     },
//     {
//       path: "shuoming",
//       component: () => import("@/views/dati/shuoming"),
//       name: "shuoming",
//       meta: {
//         title: "答题说明"
//       }
//     }
//   ]
// },
// {
// 	path: '/guanli',
// 	component: Layout,
// 	redirect: 'noredirect',
// 	alwaysShow: true, // will always show the root menu
// 	meta: {
// 		title: '回收管理',
// 		icon: 'lock',
// 		roles: ['taidaAdmin', 'admin']
// 	},
// 	children: [{
// 			path: 'price',
// 			component: () =>
// 				import('@/views/front/price'),
// 			name: 'price',
// 			meta: {
// 				title: '设置回收价格',
// 			}
// 		}
// ,
// {
// 	path: 'createOrder',
// 	component: () =>
// 		import('@/views/front/createOrder'),
// 	name: 'createOrder',
// 	meta: {
// 		title: '创建回收单'
// 	}
// }
// 	]
// },
// {
// 	path: '/yaoqing',
// 	component: Layout,
// 	redirect: 'noredirect',
// 	alwaysShow: true, // will always show the root menu
// 	meta: {
// 		title: '邀请运营',
// 		icon: 'lock'
// 	},
// 	children: [
// 		// {
// 		//   path: 'yaoqingma',
// 		//   component: () => import('@/views/yaoqing/yaoqingma'),
// 		//   name: 'yaoqingma',
// 		//   meta: {
// 		//     title: '邀请码列表'
// 		//   }
// 		// },
// 		{
// 			path: 'yaoqinglist',
// 			component: () =>
// 				import('@/views/yaoqing/yaoqinglist'),
// 			name: 'yaoqingma',
// 			meta: {
// 				title: '邀请信息列表'
// 			}
// 		}
// 	]
// },
//	{
//		path: '/jifenguanli',
//		component: Layout,
//		redirect: 'noredirect',
//		meta: {
//			title: '积分管理',
//			icon: 'trash'
//		},
//		children: [{
//				path: 'jifenguanli',
//				component: () =>
//					import('@/views/jifenguanli/record'),
//				name: 'jifenguanli',
//				meta: {
//					title: '积分管理'
//				}
//			}
//
//		]
//	},
// {
//   path: "/chuyu",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "投递运营",
//     roles: ["admin"],
//     icon: "lock"
//   },
//   children: [
//     {
//       path: "chuyu",
//       component: () => import("@/views/chuyu/chuyulist"),
//       name: "chuyu",
//       meta: {
//         title: "厨余投递运营"
//       }
//     },
//     {
//       path: "chuyuceping",
//       component: () => import("@/views/chuyu/chuyucepinglist"),
//       name: "chuyuceping",
//       meta: {
//         title: "厨余测评记录"
//       }
//     },
//     {
//       path: "zhongduanchuyu",
//       component: () => import("@/views/chuyu/zhongduanchuyulist"),
//       name: "zhongduanchuyu",
//       meta: {
//         title: "终端投递运营(智能)"
//       }
//     },
//     {
//       path: "jifenka",
//       component: () => import("@/views/chuyu/jifenka"),
//       name: "jifenka",
//       meta: {
//         title: "积分卡"
//       }
//     }
//     //			{
//     //				path: 'jifenduihuan',
//     //				component: () =>
//     //					import('@/views/chuyu/jifenduihuan'),
//     //				name: 'jifenduihuan',
//     //				meta: {
//     //					title: '积分兑换列表'
//     //				}
//     //			},
//     // {
//     //  path: 'dizhi',
//     //  component: () =>
//     //					import('@/views/chuyu/dizhi'),
//     //  name: 'dizhi',
//     //  meta: {
//     //    title: '小区管理'
//     //  }
//     // }
//   ]
// },
// {
//   path: "/jifen",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "积分管理",
//     icon: "list",
//     roles: ["admin",'kgAdmin'],
//   },
//   children: [
//     {
//       path: "record",
//       component: () => import("@/views/jifenguize/jifenguize"),
//       name: "jifenguize",
//       meta: {
//         title: "积分规则",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "jifenduihuan",
//       component: () => import("@/views/chuyu/jifenduihuan"),
//       name: "jifenduihuan",
//       meta: {
//         title: "积分用户列表",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "shoplist",
//       component: () => import("@/views/shangchen/shoplist"),
//       name: "shop",
//       meta: {
//         title: "积分商品管理",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "duihuanlist",
//       component: () => import("@/views/shangchen/duihuanlist"),
//       name: "duihuan",
//       meta: {
//         title: "积分商品兑换记录",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "duihuanjilist",
//       component: () => import("@/views/shangchen/duihuanjilist"),
//       name: "duihuanji",
//       meta: {
//         title: "自助兑换记录",
//         roles: ["admin"]
//       }
//     }
//   ]
// },
// {
//   path: "/luckdraw",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "抽奖管理",
//     roles: ["admin"],
//     icon: "list"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/luckdraw/index"),
//       name: "luckindex",
//       meta: {
//         title: "奖品管理"
//       }
//     },
//     {
//       path: "list",
//       component: () => import("@/views/luckdraw/list"),
//       name: "lucklist",
//       meta: {
//         title: "中奖列表"
//       }
//     },
//     {
//       path: "setting",
//       component: () => import("@/views/luckdraw/set"),
//       name: "luckset",
//       meta: {
//         title: "设置"
//       }
//     }
//   ]
// },

/*{
  path: "/volunteer",
  component: Layout,
  redirect: "noredirect",
  alwaysShow: true, 
  meta: {
    title: "志愿督导管理",
    roles: ["admin"],
    icon: "list"
  },

  children: [
    {
      path: "index",
      component: () => import("@/views/volunt/index"),
      name: "volunteerindex",
      meta: {
        title: "申请列表"
      }
    },
    {
      path: "list",
      component: () => import("@/views/volunt/list"),
      name: "volunteerset",
      meta: {
        title: "设置"
      }
    }
  ]
},*/
// {
//   path: "/carbon",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "减碳打卡",
//     icon: "lock",
//     roles: ["admin"],
//   },
//   children: [
//     {
//       path: "type",
//       component: () => import("@/views/carbon/type"),
//       name: "type",
//       meta: {
//         title: "打卡类型"
//       }
//     },
//     {
//       path: "list",
//       component: () => import("@/views/carbon/list"),
//       name: "list",
//       meta: {
//         title: "参与列表"
//       }
//     },
//     {
//       path: "recordtype",
//       component: () => import("@/views/carbon/typerecord"),
//       name: "recordtype",
//       hidden: true,
//       meta: {
//         title: "操作列表"
//       }
//     }
//   ]
// },
// {
//   path: "/zhouziliao",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "周资料统计",
//     roles: ["admin"],
//     icon: "lock"
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/tongji/zhouziliao"),
//       name: "zhouziliaoHome",
//       meta: {
//         title: "周资料统计"
//       }
//     }
//   ]
// },

/* {
    path: "/konggangbianmin",
    component: Layout,
    redirect: "noredirect",
    meta: {
      title: "便民服务数据",
      icon: "lock",
      roles: ["kgAdmin"]
    },
   
    children: [
      {
        path: "list",
        component: () => import("@/views/konggangbianmin/list"),
        name: "konggangbianmin",
        meta: {
          title: "便民服务数据"
        }
      },
      {
        path: "type",
        component: () => import("@/views/konggangbianmin/type"),
        name: "type",
        meta: {
          title: "类型设置"
        }
      }
    ]
  },*/
// {
//   path: "/management",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "物资管理",
//     icon: "trash",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "record",
//       component: () => import("@/views/management/record"),
//       name: "management",
//       meta: {
//         title: "物资台账"
//       }
//     },
//     {
//       path: "rukurecord",
//       component: () => import("@/views/management/ruku"),
//       name: "wuzirukujilu",
//       meta: {
//         title: "物资入库记录"
//       }
//     },
//     {
//       path: "fafangrecord",
//       component: () => import("@/views/management/fafang"),
//       name: "wuzifafangjilu",
//       meta: {
//         title: "物资发放记录"
//       }
//     }
//   ]
// },
// {
//   path: "/Cloudaccount",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "云台账",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "account",
//       component: () => import("@/views/Cloudaccount/account"),
//       name: "account",
//       meta: {
//         title: "账号管理"
//       }
//     },
//     {
//       path: "classification",
//       component: () => import("@/views/Cloudaccount/classification"),
//       name: "classification",
//       meta: {
//         title: "分类台账"
//       }
//     },
//     {
//       path: "workrecord",
//       component: () => import("@/views/Cloudaccount/workrecord"),
//       name: "workrecord",
//       meta: {
//         title: "工作记录"
//       }
//     }
//   ]
// },
// {
//   path: "/tongji",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "云平台",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "shuju",
//       component: () => import("@/views/tongji/shuju"),
//       name: "shuju",
//       meta: {
//         title: "厨余上报审核列表",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "data",
//       component: () => import("@/views/tongji/data"),
//       name: "data",
//       meta: {
//         title: "小区统计"
//       }
//     },
//     {
//       path: "zhanghaoguanlion",
//       component: () => import("@/views/tongji/zhanghaoguanli"),
//       name: "zhanghaoguanlion",
//       meta: {
//         title: "账号管理",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "street",
//       component: () => import("@/views/tongji/street"),
//       name: "street",
//       meta: {
//         title: "开放街道",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "editstreet",
//       // hidden:true,
//       component: () => import("@/views/tongji/editstreet"),
//       name: "editstreet",
//       meta: {
//         title: "街道设置",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "screensetting",
//       // hidden:true,
//       component: () => import("@/views/tongji/screensetting"),
//       name: "screensetting",
//       meta: {
//         title: "大屏设置",
//         roles: ["admin"]
//       }
//     },
//     {
//       path: "huodongbaoming",
//       component: () => import("@/views/tongji/baoming"),
//       name: "huodongbaoming",
//       hidden: true,
//       meta: {
//         title: "宣传活动",
//         roles: ["admin"]
//       }
//     }
//   ]
// },
// {
//   path: "/website",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "官网运营",
//     icon: "list",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/website/userlist"),
//       name: "website",
//       meta: {
//         title: "官网运营"
//       }
//     },
//     {
//       path: "newsindex",
//       component: () => import("@/views/website/newslist"),
//       name: "newslist",
//       meta: {
//         title: "新闻列表"
//       }
//     }
//   ]
// },
// {
//   path: "/resource",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "资源管理",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "resource",
//       component: () => import("@/views/resource/index"),
//       name: "resource",
//       meta: {
//         title: "资源管理"
//       }
//     }
//   ]
// },
// {
//   path: "/newsetting",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "系统设置",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "setting",
//       component: () => import("@/views/website/newsetting"),
//       name: "newsetting",
//       meta: {
//         title: "系统设置"
//       }
//     }
//   ]
// },
// {
//   path: "/basicsetting",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "基础设置",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "setting",
//       component: () => import("@/views/basicsetting/index"),
//       name: "setting",
//       meta: {
//         title: "基础设置"
//       }
//     }
//   ]
// },
// {
//   path: "/quanxian",
//   component: Layout,
//   redirect: "noredirect",
//   alwaysShow: true, // will always show the root menu
//   meta: {
//     title: "账号权限",
//     icon: "lock",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "sysrole",
//       component: () => import("@/views/sys/sysuser_v2"),
//       name: "sysrole",
//       meta: {
//         title: "账号管理"
//       }
//     },
//     {
//       path: "juese",
//       component: () => import("@/views/sys/sysrole_v2"),
//       name: "juese",
//       meta: {
//         title: "角色管理"
//       }
//     },
//     {
//       path: "sysLoginlist",
//       component: () => import("@/views/sys/sysLoginlist"),
//       name: "登录log",
//       meta: {
//         title: "登录记录"
//       },
//       hidden: true
//     }
//   ]
// },
// {
//   path: "/banner",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "banner管理",
//     icon: "list",
//     roles: ["taidaAdmin", "admin"]
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/banner/index"),
//       name: "banner",
//       meta: {
//         title: "banner管理"
//       }
//     }
//   ]
// },
// {
//   path: "/error",
//   component: Layout,
//   redirect: "noredirect",
//   meta: {
//     title: "不用功能",
//     icon: "list",
//     roles: ["admin"]
//   },
//   children: [
//     {
//       path: "index",
//       component: () => import("@/views/demo/index2"),
//       name: "shujutongji",
//       meta: {
//         title: "数据大屏"
//       }
//     },
//     {
//       path: "reli",
//       component: () => import("@/views/daping/reli"),
//       name: "reli",
//       meta: {
//         title: "热力地图"
//       }
//     },
//     {
//       path: "dizhi",
//       component: () => import("@/views/dizhi/index"),
//       name: "wuyeguanli",
//       meta: {
//         title: "物业管理"
//       }
//     },
//     {
//       path: "huodongjilu",
//       component: () => import("@/views/tongji/huodongjilu"),
//       name: "huodongjilu",
//       meta: {
//         title: "活动记录"
//       }
//     },
//     {
//       path: "xuanchuanhuodong",
//       component: () => import("@/views/tongji/xuanchuanhuodong"),
//       name: "xuanchuanhuodong",
//       meta: {
//         title: "宣传活动"
//       }
//     },
//     {
//       path: "yaoqinglist",
//       component: () => import("@/views/yaoqing/yaoqinglist"),
//       name: "yaoqingma",
//       meta: {
//         title: "邀请信息列表"
//       }
//     },
//     {
//       path: "jingpinxiaoqu",
//       component: () => import("@/views/demo/index3"),
//       name: "jingpinxiaoqu",
//       meta: {
//         title: "精品小区管理"
//       }
//     },
//     {
//       path: "weilan",
//       hidden: true,
//       component: () => import("@/views/dizhi/weilan"),
//       name: "weilan",
//       meta: {
//         title: "绘制小区线"
//       }
//     }, {
//           path: "touduipai",
//           component: () => import("@/views/touduipai/record"),
//           name: "touduipai",
//           name: "touduipai",
//           meta: {
//             title: "投对拍记录"
//           }
//         },
//     {
//       path: "lingtoudi",
//       component: () => import("@/views/front/lingtoudi"),
//       name: "lingtoudi",
//       meta: {
//         title: "零厨余用户",
//         roles: ["admin"]
//       }
//     },
//   ]
// },
{
  path: "*",
  redirect: "/404",
  hidden: true
}];