import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addrselect from '@/components/street/noeladdstreet';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
var map = null;
export default {
  components: {
    addrselect: addrselect,
    Pagination: Pagination
  },
  data: function data() {
    return {
      editstatus: false,
      dialogvideo: false,
      marker: '',
      streetsc: [{
        name: '已关闭',
        value: 0
      }, {
        name: '已打开',
        value: 1
      }],
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        status: '',
        streetId: ''
      },
      total: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      FormVisible: false,
      form: {
        status: '',
        address: '',
        name: '',
        streetId: '',
        sn: '',
        communityId: '',
        regionId: '',
        lon: '',
        lat: ''
      },
      forms: {
        pass: ''
      },
      formLabelWidth: '120px',
      listLoading: true,
      list: [],
      stree: '',
      puts: {
        pass: '',
        id: '',
        Jid: ''
      },
      po: '',
      Xiupass: {
        pass: '',
        id: '',
        Jid: ''
      },
      Visible: false,
      mpas: '',
      lng: '',
      lat: '',
      rules: {
        name: [{
          required: true,
          message: "设备名称是必填项"
        }],
        sn: [{
          required: true,
          message: "SN码是必选项"
        }],
        status: [{
          required: true,
          message: "状态是必选项"
        }]
      },
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      jianji: '',
      kong: '',
      streetadd: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    initMap: function initMap(lon, lat) {
      var self = this;
      map = new AMap.Map('amapContainer', {
        center: [lon, lat],
        resizeEnable: true,
        zoom: 14
      });
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: '天津市',
          suggest: self.forms.pass,
          input: 'pickerInput'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$notify({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.form.lat = positionResult.position.lat;
          self.form.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$notify({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        }); // 选取了某个POI

        poiPicker.on('poiPicked', function (poiResult) {
          self.form.lat = poiResult.item.location.lat;
          self.form.lon = poiResult.item.location.lng;
          map.setCenter(poiResult.item.location);
          positionPicker.start(poiResult.item.location);
        });
      }
    },
    addrChange: function addrChange(data) {
      console.log(data);
      this.addr.street = data.street;
      this.form.street = data.street;
      this.form.streetId = data.streetId;

      if (data.community) {
        this.addr.community = data.community;
        this.form.communityId = data.communityId;
      } else {
        this.addr.community = '';
        this.form.communityId = '';
      }

      if (data.region) {
        this.addr.region = data.region;
        this.form.regionId = data.regionId;
      } else {
        this.addr.region = '';
        this.form.regionId = '';
      }
    },
    Tzhang: function Tzhang() {
      var _this = this;

      this.editstatus = true;
      this.streetadd = false;
      this.addr.community = '';
      this.addr.region = '';
      this.addr.street = '';
      this.form.address = '', this.form.status = '', this.form.name = '', this.form.streetId = '', this.form.sn = '', this.form.communityId = '', this.form.regionId = '', this.form.lon = '', this.form.lat = '';
      this.dialogFormVisible = true;
      setTimeout(function (res) {
        _this.initMap(117.715093, 39.027941); // map.on('click', (e) => {
        // this.form.lon = e.lnglat.getLng()
        // this.form.lat = e.lnglat.getLat()
        // })

      }, 500);
    },
    addpar: function addpar() {
      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new AMap.Marker({
        position: new AMap.LngLat(this.form.lon, this.form.lat)
      });
      var lnglat = {};
      lnglat.lng = Number(this.form.log);
      lnglat.lat = Number(this.form.lat);
      this.$emit("giveLnglat", lnglat);
      map.add(this.marker);
    },
    Begin: function Begin(Jid) {
      console.log(Jid);
      this.form.streetId = Jid;
    },
    Addz: function Addz() {
      var _this2 = this;

      if (!this.streetadd) {
        if (this.form.streetId == '') {
          this.$notify({
            title: '失败',
            message: '街道不能为空',
            type: 'error',
            duration: 2000
          });
          return false;
        }
      }

      if (this.form.lon == '' && this.form.lat == '') {
        this.$notify({
          title: '失败',
          message: '地图坐标不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          delete _this2.form.kitchenRegion;
          delete _this2.form.updateTime;
          delete _this2.form.createTime;
          delete _this2.form.today;
          delete _this2.form.yesterday;
          delete _this2.form.thisMonth;
          delete _this2.form.lastMonth;
          delete _this2.form.total;
          delete _this2.form.comment;
          delete _this2.form.model;
          delete _this2.form.pkey;
          delete _this2.form.gprs; // updateTime: 2021-01-08 15:53:56
          // createTime:

          if (!_this2.editstatus) {
            Service.Addpriter(_this2.form).then(function (res) {
              if (res.data.error) {
                _this2.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogFormVisible = false;

                _this2.getList();
              }
            });
          } else {
            Service.Addspriter(_this2.form).then(function (res) {
              if (res.data.error) {
                _this2.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogFormVisible = false;

                _this2.getList();
              }
            });
          }
        }
      });
    },
    getList: function getList() {
      var _this3 = this;

      Service.zhinengdayinji(this.listQuery).then(function (res) {
        _this3.list = res.data.data.list;
        _this3.total = res.data.data.totalRows;
        _this3.listLoading = false;
      });
    },
    getJiedao: function getJiedao() {
      var _this4 = this;

      Service.getArtjied().then(function (res) {
        _this4.stree = res.data;
      });
    },
    Street: function Street(Jid) {
      var _this5 = this;

      this.puts.Jid = Jid;
      console.log(this.puts);
      Service.updateJied(this.puts).then(function (res) {
        if (res.status == 200) {
          _this5.$message({
            message: '修改成功',
            type: 'success'
          });

          _this5.FormVisible = false;

          _this5.getList();
        }
      });
    },
    Streets: function Streets() {
      console.log(this.form);
    },
    Str: function Str(id, pass) {
      this.puts.id = id;
      this.puts.pass = pass;
    },
    edit: function edit(rows) {
      var _this6 = this;

      this.editstatus = false;
      console.log(rows);

      if (rows.kitchenRegion) {
        this.addr.street = rows.kitchenRegion.street, this.addr.community = rows.kitchenRegion.community;
        this.addr.region = rows.kitchenRegion.region;
        this.addr.streetId = rows.streetId;
        this.form.streetId = rows.streetId;
      } else {
        this.addr.street = rows.street;
        this.addr.streetId = rows.streetId;
        this.form.streetId = rows.streetId;
      }

      this.form = rows;
      this.streetadd = true;
      console.log();

      if (rows.streetId) {
        this.streetId = rows.streetId;
      }

      if (rows.communityId) {
        this.communityId = rows.communityId;
      }

      if (rows.regionId) {
        this.regionId = rows.regionId;
      }

      this.dialogFormVisible = true;
      setTimeout(function (res) {
        _this6.initMap(rows.lon, rows.lat); // map.on('click', (e) => {
        // this.form.lon = e.lnglat.getLng()
        // this.form.lat = e.lnglat.getLat()
        // })

      }, 500);
      this.FormVisible = true;
    },
    addMarks: function addMarks(lon, lat) {
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(lon, lat)
      });
      var lnglat = {};
      lnglat.lng = Number(lon);
      lnglat.lat = Number(lat);
      this.$emit("giveLnglat", lnglat);
      map.add(this.marker);
    },
    del: function del(e, rows) {
      var _this7 = this;

      var arr = {};

      if (e) {
        arr.status = 1;
        arr.sn = rows.sn;
        Service.Priterstatus(arr).then(function (res) {
          console.log(res);

          if (res.status == 200) {
            _this7.$message({
              message: '操作成功',
              type: 'success'
            });

            _this7.getList();
          }
        });
      } else {
        arr.status = 0;
        arr.sn = rows.sn;
        Service.Priterstatus(arr).then(function (res) {
          console.log(res);

          if (res.status == 200) {
            _this7.$message({
              message: '操作成功',
              type: 'success'
            });

            _this7.getList();
          }
        });
      }
    }
  },
  update: function update() {
    this.Street(Jid);
    this.del(rows);
    this.getJiedao();
  }
};