var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "24px",
            "font-weight": "bold",
            "padding-top": "50px",
          },
        },
        [
          _vm.data.street
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _vm._v(_vm._s(_vm.data.street) + " -  "),
              ])
            : _vm._e(),
          _vm.data.communit
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _vm._v(_vm._s(_vm.data.communit) + " -  "),
              ])
            : _vm._e(),
          _vm.data.region
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _vm._v(_vm._s(_vm.data.region) + " -  "),
              ])
            : _vm._e(),
          _vm.data.name
            ? _c("div", { staticStyle: { display: "inline-block" } }, [
                _vm._v(_vm._s(_vm.data.name)),
              ])
            : _vm._e(),
          _vm._v("督导员巡检记录\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: {
            width: "90%",
            margin: "50px auto 0",
            "font-size": "18px",
          },
          attrs: { data: _vm.tableData, stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "deviceName", label: "设备名称", width: "400" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "mbId", label: "设备码", width: "300" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "address", label: "巡检地址", width: "450" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "punchDate", label: "巡检日期", width: "200" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "punchTime", label: "巡检时间", width: "200" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }