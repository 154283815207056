import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/service';
import ServiceDtu from '@/api/devicedtu';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import noel from '@/components/street/noeladdstreet';
import pointSelect from '@/components/street/pointselect';
import devicemouthpiece from '@/api/devicemouthpiece';
var map = null;
var marker = null;
export default {
  name: 'devicedtu',
  components: {
    Pagination: Pagination,
    pointSelect: pointSelect,
    noel: noel
  },
  directives: {
    waves: waves
  },
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  filters: {},
  data: function data() {
    var _ref;

    return _ref = {
      formLabelWidth: '120px',
      formLabelMinWidth: '75px',
      maps: true,
      addOrUpdateStatus: '',
      //  判断添加还是修改
      forms: {
        mbId: '',
        name: '',
        street: "",
        community: "",
        region: "",
        streetId: '',
        communityId: '',
        regionId: '',
        lat: '',
        lon: '',
        begTime: '',
        // 开始时间
        endTime: '',
        // 结束时间
        formId: "",
        //来源id
        times: "",
        mark: "",
        // 点位编码
        pointName: "",
        // 点位名称
        pic: ""
      },
      dialogFormVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        street: "",
        community: "",
        region: "",
        streetId: '',
        communityId: '',
        regionId: '',
        currentPage: 1,
        numOfPage: 20,
        type: '',
        key: ''
      },
      dialogVisible: false,
      detailData: {},
      name: '',
      rule: {
        mbId: [{
          required: true,
          message: '设备编码是必填项'
        }],
        name: [{
          required: true,
          message: '设备名称是必填项'
        }],
        formId: [{
          required: true,
          message: '设备来源是必填项'
        }] // mark:[{
        // 	required: true,
        // 	message: '点位是必填项'
        // }]

      },
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: '',
        communityId: '',
        regionId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      kong: '',
      geocoder: '',
      marker: '',
      dismouthpiece: false,
      // 投口参数设置是否显示
      listMouthpieceLoading: true,
      // 投口参数设置加载
      tableKeyMouthpiece: 0,
      // 投口参数设置key
      listMouthpiece: [],
      // 投口参数设置数据
      listMouthpieceQuery: {
        mbId: "",
        formId: ""
      },
      // 投口加载查询条件
      images: [],
      disabled: true,
      //禁用积分设置input开关
      typesOptions: [{
        // 厨余类型字典
        typeInfo: '厨余垃圾',
        type: 400
      }, {
        typeInfo: '其他垃圾',
        type: 405
      }, {
        typeInfo: '可回收物',
        type: 406
      }, {
        typeInfo: '有害垃圾',
        type: 407
      }],
      verifyUserOptions: [{
        // 设备投口验证用户列表
        label: "验证用户-需要",
        value: 1
      }, {
        // 设备投口验证用户列表
        label: "验证用户-不需要",
        value: 0
      }],
      sources: [{
        // 设备来源
        label: "映蓝",
        value: "yl"
      }],
      mbIdMouthpiece: "",
      // 设备投口设备编码
      mainBoardDialag: false,
      // 更换新设备主板
      mainBoardForm: {
        newMbId: "",
        // 设备编码
        oldMbId: "",
        formId: ""
      },
      mainBoardFormRule: {
        newMbId: [{
          required: true,
          message: '新设备编码是必填项'
        }]
      },
      mainBoardTiHuanDialag: false,
      // 替换原设备主板
      mainBoardTiHuanForm: {
        newMbId: "",
        // 设备编码
        oldMbId: "",
        formId: ""
      },
      mainBoardTiHuanFormRule: {
        oldMbId: [{
          required: true,
          message: '原设备编码是必填项'
        }]
      }
    }, _defineProperty(_ref, "images", ""), _defineProperty(_ref, "dialog", false), _ref;
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      ServiceDtu.getList(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.totalRows;
        _this.listLoading = false;
      });
    },
    add: function add() {
      var _this2 = this;

      // 添加弹框
      this.addr.region = '';
      this.addr.community = '';
      this.addr.street = '';
      this.addr.mark = "";
      this.addr.pointName = "";
      this.addOrUpdateStatus = false; //  判断修改or添加状态

      this.disabled = false;
      this.dialogFormVisible = true;
      this.forms.mbId = '';
      this.forms.name = '';
      this.forms.streetId = '';
      this.forms.communityId = '';
      this.forms.regionId = '';
      this.forms.street = "";
      this.forms.community = "";
      this.forms.region = "";
      this.forms.begTime = "";
      this.forms.endTime = "";
      this.forms.lat = "";
      this.forms.lon = "";
      this.forms.formId = "";
      this.forms.times = "";
      this.forms.mark = "";
      this.forms.pointName = "";
      this.forms.pic = "";
      setTimeout(function (res) {
        _this2.initMap(117.715093, 39.027941);
      }, 500);
    },
    addMainBoardView: function addMainBoardView(row) {
      var _this3 = this;

      // 更换新设备主板
      this.$confirm('确定要更换新设备主板编号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this3.mainBoardDialag = true;
        _this3.mainBoardForm.newMbId = "";
        _this3.mainBoardForm.oldMbId = row.mbId;
        _this3.mainBoardForm.formId = row.formId;
      });
    },
    editChoice: function editChoice(row) {
      if (row.name) {
        this.edit(row);
      } else {
        this.addMainBoardViewTiHuan(row);
      }
    },
    addMainBoardViewTiHuan: function addMainBoardViewTiHuan(row) {
      var _this4 = this;

      // 替换原设备主板
      this.$confirm('是否作为新设备？开始配置信息', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否'
      }).then(function (action) {
        if (action == 'confirm') {
          _this4.edit(row);
        }
      }).catch(function (action) {
        if (action == 'cancel') {
          _this4.mainBoardTiHuanDialag = true;
          _this4.mainBoardTiHuanForm.newMbId = row.mbId;
          _this4.mainBoardTiHuanForm.oldMbId = "";
          _this4.mainBoardTiHuanForm.formId = row.formId;
        }
      });
    },
    saveMainBoard: function saveMainBoard() {
      var _this5 = this;

      this.$refs['mainBoardForm'].validate(function (valid) {
        if (valid) {
          ServiceDtu.move(_this5.mainBoardForm).then(function (res) {
            if (res.data.statusCode == 200) {
              _this5.$alert('更换成功', '', {
                confirmButtonText: '确定',
                callback: function callback(action) {
                  _this5.dialogFormVisible = false;

                  _this5.getList();
                }
              });
            } else {
              _this5.$alert('更换失败', '', {
                confirmButtonText: '确定',
                callback: function callback(action) {}
              });
            }
          }).catch(function (res) {
            _this5.$alert('更换失败', '', {
              confirmButtonText: '确定',
              callback: function callback(action) {}
            });
          });
          _this5.mainBoardDialag = false;
        }
      });
    },
    saveMainBoardTiHuan: function saveMainBoardTiHuan() {
      var _this6 = this;

      this.$refs['mainBoardTiHuanForm'].validate(function (valid) {
        if (valid) {
          ServiceDtu.move(_this6.mainBoardTiHuanForm).then(function (res) {
            if (res.data.statusCode == 200) {
              _this6.$alert(res.data.data, '', {
                confirmButtonText: '确定',
                callback: function callback(action) {
                  _this6.dialogFormVisible = false;

                  _this6.getList();
                }
              });
            } else {
              _this6.$alert('更换失败', '', {
                confirmButtonText: '确定',
                callback: function callback(action) {}
              });
            }
          }).catch(function (res) {
            _this6.$alert('更换失败', '', {
              confirmButtonText: '确定',
              callback: function callback(action) {}
            });
          });
          _this6.mainBoardTiHuanDialag = false;
        }
      });
    },

    /*uploadImg(data) {
    	this.forms.pic = data.key
    },*/

    /*mapsd() {
    	this.maps = true
    },*/
    addOrUpdateDtu: function addOrUpdateDtu() {
      var _this7 = this;

      this.$refs['datas'].validate(function (valid) {
        if (valid) {
          if (_this7.forms.street == '' || _this7.forms.street == null) {
            _this7.$message({
              title: '失败',
              message: '街道是必填项!',
              type: 'error',
              duration: 2000
            });

            return;
          }

          if (_this7.forms.begTime != "" && _this7.forms.endTime == "") {
            _this7.$message({
              type: 'error',
              message: '结束时间不能为空!'
            });

            return;
          }

          if (_this7.forms.begTime == "" && _this7.forms.endTime != "") {
            _this7.$message({
              type: 'error',
              message: '开始时间不能为空!'
            });

            return;
          }

          if (_this7.forms.begTime != "" && _this7.forms.endTime != "") {
            if (_this7.forms.begTime >= _this7.forms.endTime) {
              _this7.$message({
                type: 'error',
                message: '开始时间不能大于等于结束时间!'
              });

              return;
            }
          }

          if (_this7.forms.formId == "" || _this7.forms.formId == null) {
            _this7.$message({
              type: 'error',
              message: '设备来源是必选项!'
            });

            return;
          }

          if (_this7.forms.begTime == "" && _this7.forms.endTime == "") {
            _this7.forms.times = "";
          } else {
            _this7.forms.times = _this7.forms.begTime + "-" + _this7.forms.endTime;
          } // console.log(this.forms);
          // return;
          // console.log(this.forms.begTime,this.forms.endTime);


          if (_this7.addOrUpdateStatus) {
            // 编辑
            ServiceDtu.updateDtu(_this7.forms).then(function (res) {
              _this7.$message({
                title: '成功',
                message: res.data.data,
                type: 'success',
                duration: 2000
              });

              _this7.dialogFormVisible = false;

              _this7.getList();
            }).catch(function (res) {
              console.log(res);

              _this7.$message({
                title: '失败',
                message: '修改失败',
                type: 'error',
                duration: 2000
              });
            });
          } else {
            // 添加
            ServiceDtu.addDtu(_this7.forms).then(function (res) {
              if (res.data.data == '设备已存在') {
                console.log(res);

                _this7.$message({
                  title: '失败',
                  message: res.data.data,
                  type: 'error',
                  duration: 2000
                });
              } else {
                _this7.$message({
                  title: '成功',
                  message: res.data.data,
                  type: 'success',
                  duration: 2000
                });

                _this7.dialogFormVisible = false;

                _this7.getList();
              }
            }).catch(function (res) {
              console.log(res);

              _this7.$message({
                title: '失败',
                message: '添加失败',
                type: 'error',
                duration: 2000
              });
            });
          }
        }
      });
    },
    addrChange: function addrChange(data) {
      // 添加or编辑街道，小区，社区
      console.log(data);
      this.forms.streetId = data.streetId;
      this.forms.communityId = data.communityId;
      this.forms.regionId = data.regionId;
      this.forms.street = data.street;
      this.forms.community = data.community;
      this.forms.region = data.region;
      this.forms.mark = data.mark;
      this.forms.pointName = data.pointName;
      this.addr.streetId = data.streetId;
      this.addr.communityId = data.communityId;
      this.addr.regionId = data.regionId;
      this.addr.community = data.community;
      this.addr.region = data.region;
      this.addr.street = data.street;
      this.addr.mark = data.mark;
      this.addr.pointName = data.pointName;
    },
    edit: function edit(row) {
      var _this8 = this;

      // 编辑弹框
      this.dialogFormVisible = true;
      this.addOrUpdateStatus = true;
      this.disabled = true; //if (row.region) {

      this.addr.street = row.street;
      this.addr.community = row.community;
      this.addr.region = row.region;
      this.addr.pointName = row.pointName;
      this.addr.mark = row.mark;
      /*this.addr.streetId = row.streetId
      this.addr.communityId = row.communityId
      this.addr.regionId = row.regionId*/

      /*} else {
      	this.addr.region = ''
      	this.addr.street = ''
      	this.addr.community = ''
      }*/

      /*this.forms.streetId = row.streetId
      this.forms.communityId = row.communityId
      this.forms.regionId = row.regionId*/

      this.forms.street = row.street;
      this.forms.community = row.community;
      this.forms.region = row.region;
      this.forms.pointName = row.pointName;
      this.forms.mark = row.mark;
      /*this.streetId = row.streetId
      this.communityId = row.communityId
      this.regionId = row.regionId*/

      this.forms.lon = row.lon;
      this.forms.lat = row.lat;
      this.forms.mbId = row.mbId;
      this.forms.name = row.name;
      this.forms.formId = row.formId;
      this.forms.pic = row.pic; //console.log(this.forms);

      if (row.times == "") {
        this.forms.begTime = "";
        this.forms.endTime = "";
      } else {
        this.forms.begTime = row.times.split("-")[0];
        this.forms.endTime = row.times.split("-")[1];
      }

      this.kong = '';
      setTimeout(function (res) {
        _this8.initMap(row.lon, row.lat);
      }, 500); // console.log(this.forms)
    },
    uploadImg: function uploadImg(data) {
      this.forms.pic = data.key;
      console.log(this.forms.pic);
    },
    loadImage: function loadImage(e) {
      var _this9 = this;

      this.dialog = true;
      setTimeout(function () {
        _this9.images = e;
      }, 200);
    },
    danger: function danger(row) {
      var _this10 = this;

      // 删除
      this.$confirm('此操作将删除此DTU设备信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        ServiceDtu.del(row).then(function (res) {
          _this10.$message({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this10.getList();
        });
      }).catch(function () {
        _this10.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 设备投口参数设置
    getMouthpieceList: function getMouthpieceList(row) {
      var _this11 = this;

      this.dismouthpiece = true;
      this.listMouthpieceLoading = true;
      this.listMouthpieceQuery.mbId = row.mbId;
      this.listMouthpieceQuery.formId = row.formId;
      ServiceDtu.dtuDisplay(this.listMouthpieceQuery).then(function (res) {
        console.log(res.data.data);
        _this11.listMouthpiece = res.data.data;
      });
      this.listMouthpieceLoading = false;
    },
    copyMouthpiece: function copyMouthpiece() {
      // 复制设备投口信息
      // console.log(this.$refs.listMouthpieceRef.selection);
      var list = this.$refs.listMouthpieceRef.selection;

      if (list.length != 1) {
        this.$message({
          type: "error",
          message: "请选择一条数据进行复制操作！"
        });
        return;
      } //  复制基本信息


      var copyRow = {};
      copyRow.mbId = list[0].mbId;
      copyRow.formId = list[0].formId;
      copyRow.type = list[0].type;
      copyRow.typeInfo = list[0].typeInfo;
      copyRow.checkState = 0; // 不需要的字段dtu没法控制

      copyRow.coin = list[0].coin; // 查找最大的投口

      copyRow.num = Number(this.listMouthpiece[this.listMouthpiece.length - 1].num) + 1;
      this.listMouthpiece.push(copyRow);
      console.log(this.listMouthpiece);
    },
    addMouthpiece: function addMouthpiece() {
      // 添加设备投口信息
      //  复制基本信息
      var insertRow = {}; //insertRow.mbId =  this.mbIdMouthpiece;

      insertRow.mbId = this.listMouthpieceQuery.mbId;
      insertRow.formId = this.listMouthpieceQuery.formId;
      insertRow.type = 400;
      insertRow.typeInfo = '厨余垃圾';
      insertRow.checkState = 1;
      insertRow.coin = 10; // 查找最大的投口

      if (this.listMouthpiece.length == 0) {
        insertRow.num = 1;
      } else {
        insertRow.num = Number(this.listMouthpiece[this.listMouthpiece.length - 1].num) + 1;
      }

      this.listMouthpiece.push(insertRow);
      console.log(this.listMouthpiece);
    },
    deleteMouthpiece: function deleteMouthpiece() {
      var _this12 = this;

      this.$confirm('确定要清空数据重新配置投口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        ServiceDtu.delConfig(_this12.listMouthpieceQuery).then(function (res) {
          _this12.$message({
            title: '成功',
            message: '重置成功',
            type: 'success',
            duration: 2000
          }); //this.getMouthpieceList()


          _this12.listMouthpiece = [];
        });
      }).catch(function () {
        _this12.$message({
          type: 'info',
          message: '已取消重置'
        });
      });
    },

    /*getMouthpieceList() {// 删除投口后加载页面
    	this.listMouthpieceQuery.mbId =  row.mbId;
    	this.listMouthpieceQuery.formId = row.formId
    	ServiceDtu.dtuDisplay(this.listMouthpieceQuery).then(res => {
    		console.log(res.data.data)
    		this.listMouthpiece =  res.data.data
    	})
    },*/
    mouthpieceConfirm: function mouthpieceConfirm() {
      var _this13 = this;

      // console.log(this.listMouthpiece);
      var flag = false;
      this.listMouthpiece.forEach(function (item) {
        if (item.coin < 0 && !flag) {
          flag = true;
        }
      });

      if (flag) {
        this.$message({
          type: "error",
          message: "投递积分设置错误，请重新设置!"
        });
        return;
      }

      var j = JSON.stringify(this.listMouthpiece);
      ServiceDtu.configuration(j).then(function (res) {
        // console.log(res.data);
        if (res.data.statusCode == 200) {
          _this13.$message({
            type: "success",
            message: "设备投口参数设置成功!"
          });
        } else {
          _this13.$message({
            type: "error",
            message: "设备投口参数设置失败!"
          });
        }
      });
      this.dismouthpiece = false; // 关闭设备投口
    },
    typesChange: function typesChange(row) {
      // 投递类型为厨余垃圾，验证用户默认为需要,同时修改typeInfo
      // console.log(row);

      /*this.typesOptions.forEach((item,index)=>{
      	if(row.type == item.type){
      		row.typeInfo = item.typeInfo;
      	}
      
      })
      if(row.type==400){
      	row.checkState = 1;
      } */
      if (row.type == 400) {
        row.coin = 10;
      } else {
        row.coin = 0;
      }

      row.checkState = 0; //dtu没法控制
    },
    changeStartTime: function changeStartTime(e) {
      // console.log(e);
      if (e == null) {
        this.forms.begTime = "";
      }
    },
    changeEndTime: function changeEndTime(e) {
      if (e == null) {
        this.forms.endTime = "";
      }
    },
    initMap: function initMap(lons, lats) {
      var newarrsd = [];
      map = null;
      marker = null;
      var self = this;
      marker, map = new AMap.Map('amapContainer', {
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        center: [lons, lats],
        resizeEnable: true,
        zoom: 14
      });
      newarrsd.push(lons, lats);
      marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: [lons, lats],
        offset: new AMap.Pixel(-20, -50)
      });
      marker.setMap(map);
      self.getmaps(newarrsd);
      map.on('click', function (e) {
        var arrs = [];
        arrs.push(e.lnglat.getLng(), e.lnglat.getLat());

        if (marker) {
          //console.log(arrs)
          marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()]);
          self.getmaps(arrs);
        } else {
          // console.log(arrs)
          marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: [e.lnglat.getLng(), e.lnglat.getLat()],
            offset: new AMap.Pixel(-20, -50)
          });
          marker.setMap(map);
          self.getmaps(arrs);
        }
      });
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: '天津市',
          suggest: self.forms.pass,
          input: 'pickerInput',
          placeSearchOptions: {
            map: map,
            pageSize: 10
          },
          searchResultsContainer: 'searchResults'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$message({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.forms.lat = positionResult.position.lat;
          self.forms.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$message({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        }); // 选取了某个POI

        poiPicker.on('poiPicked', function (poiResult) {
          var arrs = [];
          map.setCenter(poiResult.item.location); // positionPicker.start(poiResult.item.location)

          arrs.push(poiResult.item.location.lng, poiResult.item.location.lat);
          self.getmaps(arrs);

          if (marker) {
            marker.setPosition([poiResult.item.location.lng, poiResult.item.location.lat]);
          } else {
            marker = new AMap.Marker({
              icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              position: [poiResult.item.location.lng, poiResult.item.location.lat]
            });
            marker.setMap(map);
          }
        });
      }
    },
    getmaps: function getmaps(arrs) {
      // console.log(arrs)
      var self = this;
      self.forms.lon = arrs[0];
      self.forms.lat = arrs[1]; // console.log(arrs)

      map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '天津'
        });
        geocoder.getAddress(arrs, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            // console.log(status, result)
            self.forms.address = result.regeocode.formattedAddress;
          }
        });
      });
    },

    /*addpar() {
    	let self = this
    	if (this.marker) {
    		this.marker.setMap(null);
    		this.marker = null;
    	}
    	this.marker = new AMap.Marker({
    		position: new AMap.LngLat(this.forms.lon, this.forms.lat)
    	});
    	let lnglat = {}
    	let arrs = []
    	lnglat.lng = Number(this.forms.lon)
    	lnglat.lat = Number(this.forms.lat)
    	arrs.push(this.forms.lon, Number(this.forms.lat))
    	this.$emit("giveLnglat", lnglat);
    	map.add(this.marker);
    	map.plugin('AMap.Geocoder', function() {
    		var geocoder = new AMap.Geocoder({
    			// city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
    			city: '天津'
    		})
    		geocoder.getAddress(arrs, function(status, result) {
    			if (status === 'complete' && result.info === 'OK') {
    				// result为对应的地理位置详细信息
    				console.log(status, result)
    				self.forms.address = result.regeocode.formattedAddress
    			}
    		})
    	})
    	this.maps = false
    },*/
    noelchange: function noelchange(data) {
      // 查询条件
      //console.log(data);
      //this.listQuery.streetId = data.streetId
      //this.listQuery.communityId = data.communityId
      //this.listQuery.regionId = data.regionId
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    addMarks: function addMarks(lon, lat) {
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    }
  },
  activated: function activated() {
    console.log("dtu activated");
  },
  deactivated: function deactivated() {
    console.log("dtu deactivated");
  }
};