"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _service = _interopRequireDefault(require("@/api/service"));

var _auth = require("@/utils/auth");

var user = {
  state: {
    user: '',
    status: '',
    code: '',
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    setting: {
      articlePlatform: []
    },
    buttons: []
  },
  mutations: {
    SET_CODE: function SET_CODE(state, code) {
      state.code = code;
    },
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_INTRODUCTION: function SET_INTRODUCTION(state, introduction) {
      state.introduction = introduction;
    },
    SET_SETTING: function SET_SETTING(state, setting) {
      state.setting = setting;
    },
    SET_STATUS: function SET_STATUS(state, status) {
      state.status = status;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_BUTTONS: function SET_BUTTONS(state, buttons) {
      state.buttons = buttons;
    }
  },
  actions: {
    // 用户名登录
    LoginByUsername: function LoginByUsername(_ref, userInfo) {
      var commit = _ref.commit;
      var username = userInfo.username.trim();
      return new Promise(function (resolve, reject) {
        _service.default.loginByUsername({
          'username': username,
          'password': userInfo.password
        }).then(function (response) {
          var res = response.data;
          var redirect = res.data.redirect;

          if (res.status === 200 && redirect != "") {
            // 用户有菜单权限
            commit('SET_TOKEN', res.data.token);
            (0, _auth.setToken)(res.data.token);
            resolve(res);
          } else if (res.status === 200 && redirect == "") {
            // 用户无菜单权限
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetUserInfo: function GetUserInfo(_ref2) {
      var commit = _ref2.commit,
          state = _ref2.state;
      return new Promise(function (resolve, reject) {
        _service.default.getUserInfo().then(function (response) {
          if (response.data.status !== 200) {
            reject('error');
          }

          var data = response.data.data;

          if (data.roles && data.roles.length > 0) {
            // 验证返回的roles是否是一个非空数组
            commit('SET_ROLES', data.roles);
          } else {
            reject('getInfo: roles must be a non-null array !');
          }

          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          commit('SET_INTRODUCTION', data.introduction);
          commit("SET_BUTTONS", data.userBtns); // 按钮权限

          resolve(response);
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
          state = _ref3.state;
      return new Promise(function (resolve, reject) {
        _service.default.logout().then(function () {
          commit('SET_TOKEN', '');
          commit('SET_ROLES', []);
          commit('SET_BUTTONS', []);
          (0, _auth.removeToken)();
          resolve();
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    },
    // 动态修改权限
    ChangeRoles: function ChangeRoles(_ref5, role) {
      var commit = _ref5.commit,
          dispatch = _ref5.dispatch;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', role);
        (0, _auth.setToken)(role);

        _service.default.getUserInfo().then(function (response) {
          var data = response.data;
          commit('SET_ROLES', data.roles);
          commit('SET_NAME', data.name);
          commit('SET_AVATAR', data.avatar);
          commit('SET_INTRODUCTION', data.introduction);
          dispatch('GenerateRoutes', data); // 动态修改权限后 重绘侧边菜单

          resolve();
        });
      });
    }
  }
};
var _default = user;
exports.default = _default;