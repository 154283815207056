var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "设备名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "设备编码",
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.kitchenRegion
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.kitchenRegion.street) + " "),
                          scope.row.kitchenRegion.community
                            ? _c("span", [_vm._v("，")])
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(scope.row.kitchenRegion.community) +
                                " "
                            ),
                          ]),
                          _vm._v(" ，"),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.kitchenRegion.region) +
                                " ， " +
                                _vm._s(scope.row.number)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "具体地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "坐标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.lon) + "," + _vm._s(scope.row.lat)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备照片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      scope.row.pic
                        ? _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src: "http://pic.touduila.com/" + scope.row.pic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgs(scope.row.pic, 0)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排行榜", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 25
                      ? _c("span", [
                          scope.row.rankingStatus == "1"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v("已启用"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.rankingStatus == "0"
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("禁用"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != 25
                      ? _c("span", [
                          _c(
                            "span",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("—")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "人脸识别", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 25
                      ? _c("span", [
                          scope.row.faceStatus == "1"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v("已启用"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.faceStatus == "0"
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("禁用"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != 25
                      ? _c("span", [
                          _c(
                            "span",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("—")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == "1"
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("已启用"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "0"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("禁用"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.addperson))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "变更时间", width: "151" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updateTime))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "设备图片", visible: _vm.devicedialog },
          on: {
            "update:visible": function ($event) {
              _vm.devicedialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }