import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'xiaoquhuishouyuan',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '10': 'success',
        '5': 'info',
        '-1': 'danger'
      };
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '10': '合格',
        '5': '待审核',
        '-1': '拒绝'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    var _listQuery;

    return {
      form: {
        recyclerId: '',
        comment: ''
      },
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: (_listQuery = {
        role: 20,
        currentPage: 1,
        numOfPage: 20
      }, _defineProperty(_listQuery, "role", 20), _defineProperty(_listQuery, "status", 0), _listQuery),
      Options: [{
        name: '查询全部',
        value: 0
      }, {
        name: '待审核',
        value: 5
      }, {
        name: '审核通过',
        value: 10
      }, {
        name: '审核拒绝',
        value: -1
      }],
      statusOptions: ['published', 'draft', 'deleted'],
      showReviewer: false,
      temp: {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        type: '',
        status: 'published'
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: 'Edit',
        create: 'Create'
      },
      dialogPvVisible: false,
      detailData: {},
      rules: {
        type: [{
          required: true,
          message: 'type is required',
          trigger: 'change'
        }],
        timestamp: [{
          type: 'date',
          required: true,
          message: 'timestamp is required',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      },
      downloadLoading: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    close: function close() {
      this.dialogPvVisible = false;
      var comment = this.form.comment;
      Service.recyclerComment(this.form).then(function (res) {
        location.reload();
      });
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.recyclerList(this.listQuery).then(function (response) {
        console.log(response.data.list);
        _this.list = response.data.list;
        _this.total = response.data.totalRows; // Just to simulate the time of the request

        _this.listLoading = false;
      });
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    handleDetail: function handleDetail(row) {
      console.log(row);
      this.dialogPvVisible = true;
      this.detailData = row;
      this.form.recyclerId = row.weixinId;
      this.form.comment = row.auditComment; // Service.recyclerDetail({
      //   recyclerId :row.weixinId
      // }).then((res)=>{
      //     console.log(res)
      // })
    },
    tongguo: function tongguo(row) {
      var _this2 = this;

      Service.recyclerAudit({
        recyclerId: row.weixinId,
        status: 10
      }).then(function (res) {
        _this2.$notify({
          title: '成功',
          message: '通过',
          type: 'success',
          duration: 2000
        });

        _this2.getList();
      });
    },
    jujue: function jujue(row) {
      var _this3 = this;

      Service.recyclerAudit({
        recyclerId: row.weixinId,
        status: -1
      }).then(function (res) {
        _this3.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        _this3.getList();
      });
    }
  }
};