var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.list.length
      ? _c(
          "div",
          [
            _c(
              "el-form-item",
              { staticStyle: { margin: "25px 0" }, attrs: { label: "楼号" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择楼号" },
                    model: {
                      value: _vm.form.louhao,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "louhao", $$v)
                      },
                      expression: "form.louhao",
                    },
                  },
                  _vm._l(_vm.louhaoList, function (obj) {
                    return _c("el-option", {
                      key: obj.id,
                      attrs: { label: obj.buildingNumber, value: obj },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticStyle: { margin: "25px 0" }, attrs: { label: "单元号" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择单元号" },
                    model: {
                      value: _vm.form.danyuan,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "danyuan", $$v)
                      },
                      expression: "form.danyuan",
                    },
                  },
                  _vm._l(_vm.danyuanList, function (obj) {
                    return _c("el-option", {
                      key: obj.id,
                      attrs: { label: obj.unitNumber, value: obj },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { staticStyle: { margin: "25px 0" }, attrs: { label: "门牌号" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择门牌号" },
                    model: {
                      value: _vm.form.menpai,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "menpai", $$v)
                      },
                      expression: "form.menpai",
                    },
                  },
                  _vm._l(_vm.menpaiList, function (obj) {
                    return _c("el-option", {
                      key: obj.id,
                      attrs: { label: obj.homeNumber, value: obj },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c(
          "div",
          [
            _c(
              "el-form-item",
              { attrs: { label: "楼号" } },
              [
                _c("el-input", {
                  attrs: { type: "input" },
                  model: {
                    value: _vm.form.louhao,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "louhao", $$v)
                    },
                    expression: "form.louhao",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "单元号" } },
              [
                _c("el-input", {
                  attrs: { type: "input" },
                  model: {
                    value: _vm.form.danyuan,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "danyuan", $$v)
                    },
                    expression: "form.danyuan",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "门牌号" } },
              [
                _c("el-input", {
                  attrs: { type: "input" },
                  model: {
                    value: _vm.form.menpai,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "menpai", $$v)
                    },
                    expression: "form.menpai",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }