import _objectSpread from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRouterMap, constantRouterMap } from '@/router';
import Layout from "@/views/layout/Layout";
import pages from "@/router/page";
/*
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */


function filterAsyncRouter(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res;
}

var str = '';

function filterMenuRouter(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (!tmp.component) {
      tmp.component = Layout;
    } else {
      tmp.component = pages[tmp.component];
    }

    tmp.meta = {
      title: tmp.title,
      icon: tmp.icon,
      keepAlive: tmp.keepAlive
    };

    if (tmp.children && tmp.children.length > 0) {
      tmp.children = filterMenuRouter(tmp.children, roles);
    }

    tmp.name = "router" + tmp.id;
    tmp.hidden = !tmp.hidden; // if(tmp.component){
    //   str += `"${tmp.component}":  () => import("@${tmp.component}"),\n`
    // }
    // console.log(str)
    // {
    //   path: "/",
    //   component: Layout,
    //   redirect: "index",
    //   // alwaysShow: true,
    //   meta: {
    //     title: "回收员管理",
    //     icon: "user2"
    //   },
    //   children: [
    //     {
    //       path: "index",
    //       component: () => import("@/views/huishou/geti"),
    //       name: "huishouyuan",
    //       meta: {
    //         title: "个体回收员"
    //       }
    //     },
    //     {
    //       path: "xiaoquhuishouyuan",
    //       component: () => import("@/views/huishou/xiaoquhuishouyuan"),
    //       name: "xiaoquhuishouyuan",
    //       meta: {
    //         title: "小区回收员"
    //         // roles: ['admin']
    //       }
    //     }
    //   ]
    // }

    res.push(tmp);
  });
  return res;
}

var permission = {
  state: {
    routers: constantRouterMap,
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      console.log(state, routers);
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var roles = data.roles,
            accessAuthorityDtos = data.accessAuthorityDtos;
        var accessedRouters = filterMenuRouter(accessAuthorityDtos, roles); //  console.log(accessedRouters)
        // if (roles.includes('admin')) {
        //   accessedRouters = asyncRouterMap
        // } else {
        //   accessedRouters = filterAsyncRouter(asyncRouterMap, roles)
        // }

        accessedRouters.concat(asyncRouterMap);
        commit('SET_ROUTERS', accessedRouters);
        resolve();
      });
    }
  }
};
export default permission;