import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
export default {
  data: function data() {
    return {
      userInfo: '',
      listQuery: '',
      name: '',
      listLoading: true,
      list: [],
      lists: [],
      nums: ''
    };
  },
  mounted: function mounted() {
    this.userInfo = this.$route.params.data;
    this.name = this.$route.params.name;
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      if (this.name == 'chuyu') {
        Service.newchuyulists({
          userId: this.userInfo.id
        }).then(function (res) {
          console.log(res);
          _this.nums = res.data.bagCount;
          _this.list = res.data.kitchenBags;
          _this.listLoading = false;
        });
      } else {
        Service.newschuyudetails({
          userId: this.userInfo.id
        }).then(function (res) {
          _this.lists = res.data.records;
          _this.nums = res.data.count;
          _this.listLoading = false;
        });
      }
    }
  }
};