import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      uploadPic: '',
      ruku: {
        name: '',
        pic: '',
        goods: '',
        mbId: '',
        ways: ''
      },
      dialog: false,
      name: this.$route.query.name,
      total: 0,
      list: '',
      listLoading: false,
      listQuery: {
        mbId: this.$route.query.ids,
        currentPage: 1,
        numOfPage: 20
      },
      forms: {
        coin: '',
        goodsName: '',
        mbId: '',
        ways: ''
      },
      dialogFormVisible: false,
      formLabelWidth: '120px'
    };
  },
  created: function created() {
    if (this.listQuery.mbId) {
      this.getList();
    }
  },
  methods: {
    handleRemove: function handleRemove(item) {
      var _this = this;

      // var index = this.uploadPic.indexOf(item);
      // if (index > -1) {
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this.uploadPic = '';
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      }); // }
    },
    edit: function edit(e) {
      console.log(e);
      this.forms.mbId = e.mbId;
      this.forms.ways = e.ways;
      this.forms.goodsName = e.goodsName;
      this.forms.coin = e.coin;
      this.dialogFormVisible = true;
    },
    rukuad: function rukuad(e) {
      var self = this;
      this.$router.push({
        path: '/device/rukulists',
        query: {
          mbId: e.mbId,
          ways: e.ways,
          name: self.name,
          names: e.goodsName
        }
      });
    },
    Sures: function Sures() {
      var _this2 = this;

      console.log(this.ruku);
      Service.duihuanjiruku(this.ruku).then(function (res) {
        _this2.dialog = false;
        console.log(res);

        _this2.$message({
          message: '入库成功',
          type: 'success'
        });

        _this2.getList();
      });
    },
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];

      if (!this.ruku.pic.includes(response.key)) {
        this.ruku.pic = response.key;
        this.ruku.pic = response.key;
      }

      this.uploadPic = this.ruku.pic;
      this.$refs.upload.clearFiles();
    },
    rukus: function rukus(e) {
      this.ruku.mbId = e.mbId;
      this.ruku.ways = e.ways;
      this.dialog = true;
    },
    getList: function getList() {
      var _this3 = this;

      Service.shoplists(this.listQuery).then(function (res) {
        console.log(res);
        _this3.list = res.data.data;
        _this3.total = res.data.data.totalRows;
      });
    },
    Sure: function Sure() {
      var _this4 = this;

      Service.editshoplists(this.forms).then(function (res) {
        console.log(res);
        _this4.dialogFormVisible = false;
        console.log(res);

        _this4.$message({
          message: '修改成功',
          type: 'success'
        });

        _this4.getList();
      });
    }
  }
};