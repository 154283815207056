import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import * as Util from '@/utils/index';
import PanelGroup from "./components/PanelGroup";
import LineChart from "./components/LineChart";
export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup: PanelGroup,
    LineChart: LineChart
  },
  data: function data() {
    return {
      swicthList: [],
      touduiData: false,
      touduiGuanli: false,
      form: {
        start: '',
        end: ''
      },
      lineChartData: null
    };
  },
  created: function created() {
    this.form.start = Util.parseTime(Date.now() - 60 * 60 * 1000 * 24 * 30, '{y}-{m}-{d}');
    this.form.end = Util.parseTime(Date.now(), '{y}-{m}-{d}');
    this.init();
  },
  methods: {
    init: function init() {
      var self = this;
      Service.homeOrders(this.form).then(function (res) {
        var appointingCount = [];
        var completed_count = [];
        var day = [];
        var recycling_count = [];
        var total_count = [];
        console.log(res.data);
        res.data.forEach(function (item) {
          appointingCount.push(item.appointingCount);
          completed_count.push(item.completed_count);
          day.push(item.day);
          recycling_count.push(item.recycling_count);
          total_count.push(item.total_count);
        });
        self.lineChartData = {
          appointingCount: appointingCount,
          completed_count: completed_count,
          day: day,
          recycling_count: recycling_count,
          total_count: total_count
        };
      });
      Service.getShenhe().then(function (res) {
        if (res.status == 200) {
          res.data.forEach(function (item) {
            if (~item.appName.indexOf('数据平台')) {
              self.touduiData = item.opened;
            }

            if (~item.appName.indexOf('运营')) {
              self.touduiGuanli = item.opened;
            }
          });
        }
      });
    },
    swicth: function swicth(appid, val) {
      var _this = this;

      Service.setShenhe({
        appId: appid,
        status: val ? 1 : 0
      }).then(function (res) {
        _this.$message({
          message: '操作成功',
          type: 'success'
        });
      });
    }
  }
};