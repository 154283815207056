var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "banner图片", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pic
                      ? _c("img", {
                          staticStyle: { width: "100%", height: "170px" },
                          attrs: {
                            src: "http://pic.touduila.com/" + scope.row.pic,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动说明" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.des)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动图片上传要求" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.ruler)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color: scope.row.status == 1 ? "#67C23A" : "#C03639",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status == 0 ? "关闭" : "开启"))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "针对客户端" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(_vm.datas, function (item, index) {
                    return item.value == scope.row.role
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(item.name)),
                        ])
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "针对街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.street)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.adminName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialog, title: _vm.addstatus },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.forms, "label-width": "120px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打卡类型" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.typeInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "typeInfo", $$v)
                      },
                      expression: "forms.typeInfo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖励积分" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "coin", $$v)
                      },
                      expression: "forms.coin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动图片上传要求" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.ruler,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "ruler", $$v)
                      },
                      expression: "forms.ruler",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.forms.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "status", $$v)
                        },
                        expression: "forms.status",
                      },
                    },
                    _vm._l(_vm.communityList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.region, value: obj.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "针对客户端" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.forms.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "role", $$v)
                        },
                        expression: "forms.role",
                      },
                    },
                    _vm._l(_vm.datas, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "针对街道" } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.searchStreet,
                      callback: function ($$v) {
                        _vm.searchStreet = $$v
                      },
                      expression: "searchStreet",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "banner图" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        "show-file-list": false,
                        "on-success": _vm.uploadImgs,
                        "auto-upload": true,
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    [
                      _vm.forms.pic
                        ? _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.forms.pic,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动说明" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.forms.des,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "des", $$v)
                      },
                      expression: "forms.des",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saves } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }