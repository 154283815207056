import request from '@/utils/request';
/**
 * 投递列表
 */

var Service = {
  getProtoDeliverCount: function getProtoDeliverCount(params) {
    // 设备投递记录次数报表
    return request({
      url: '/protoDeliver/getProtoDeliverCount?mbId=' + params,
      method: 'get'
    });
  }
};
export default Service;