import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/Input/Inputnum.js';
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import schoolName from '@/components/School/schoolName';
import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination,
    schoolName: schoolName
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, _defineProperty(_statusMap, "10", 'primary'), _defineProperty(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '1': '派单中',
        '5': '回收中',
        '10': '已完成',
        '-1': '已删除'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      lists: {
        num: ''
      },
      dialoges: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      dialog: false,
      imgs: [],
      searchSchool: null,
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        isStudent: 1,
        numOfPage: 20,
        status: 1,
        // 1:派单中,5:回收中,10:已完成,-1:已删除
        lable: 1,
        reserveType: 0,
        school: ''
      },
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      Options: [{
        name: '查询全部',
        value: 0
      }, {
        name: '派单中',
        value: 1
      }, {
        name: '回收中',
        value: 5
      }, {
        name: '已完成',
        value: 10
      }, {
        name: '已删除',
        value: -1
      }],
      Option: [{
        name: '全部',
        value: 0
      }, {
        name: '快速订单',
        value: 2
      }, {
        name: '预约订单',
        value: 5
      }, {
        name: '自助订单',
        value: 11
      }],
      dialogs: false,
      alertlist: '',
      imgsp: [],
      seedialogs: false,
      seedata: ''
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    chasee: function chasee(e) {
      this.seedialogs = true;
      this.seedata = e.slice(0, 1);
    },
    dakai: function dakai(e) {
      console.log(e);
      this.dialog = true;
      this.imgs = e;
    },
    shezhiprint: function shezhiprint() {
      var _this = this;

      console.log(this.lists);
      Service.shebeiprint(this.lists).then(function (res) {
        console.log(res);

        if (res.data.data) {
          _this.dialoges = false;

          _this.$notify({
            title: '成功',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });
        } else {
          _this.dialoges = false;

          _this.$notify({
            title: '失败',
            message: res.data.error,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    zizhu: function zizhu() {
      this.lists.num = '';
      this.dialoges = true;
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = e.goods;
    },
    exportExcel: function exportExcel() {
      location.href = '/operation/order/export?' + qs.stringify(this.listQuery);
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this2 = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this2.initMap(row.lon, row.lat);
      }, 500);
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.studentOrderList(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows;

        _this3.list.forEach(function (item, index) {
          item.weight = item.weight.toFixed(2);

          if (item.studentPic) {
            item.studentPic = item.studentPic.split(',');
          }
        });

        setTimeout(function () {
          _this3.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    beizhu: function beizhu(row) {
      this.beizhuTemp.remark = row.remark || '';
      this.beizhuTemp.orderId = row.id;
      this.beizhuVisible = true;
    },
    handleSelectionChange: function handleSelectionChange(e) {
      var _this4 = this;

      console.log(this.imgsp);
      this.imgsp = [];
      e.forEach(function (item, index) {
        console.log(item.id);

        _this4.imgsp.push({
          id: item.id
        });
      });
    },
    doRemark: function doRemark() {
      var _this5 = this;

      Service.orderRemark(this.beizhuTemp).then(function (res) {
        _this5.beizhuVisible = false;

        if (res.data.statusCode == 200) {
          _this5.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });

          _this5.getList();
        }
      });
    },
    exportimg: function exportimg() {
      if (!this.imgsp) {
        this.$notify({
          title: '失败',
          message: '请选择导出项',
          type: 'error',
          duration: 2000
        });
      } else {
        var ids = '';
        this.imgsp.forEach(function (item) {
          ids = ids + "," + item.id;
        });
        console.log(ids);
        this.getimgs(ids);
      }
    },
    exportimgSolo: function exportimgSolo(id) {
      if (!this.imgsp) {
        this.$notify({
          title: '失败',
          message: '请选择导出项',
          type: 'error',
          duration: 2000
        });
      } else {
        this.getimgs(id);
      }
    },
    getimgs: function getimgs(id) {
      Service.studentimgs(id).then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res.data], {
          type: 'application/zip'
        }));
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', '导出图片'); // 文件名

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 下载完成移除元素

        window.URL.revokeObjectURL(url); // 释放掉blob对象
      });
    },
    chongpai: function chongpai(row) {
      var _this6 = this;

      Service.orderRecover(row.id).then(function (res) {
        if (res.data.statusCode) {
          _this6.$notify({
            title: '成功',
            message: '重派成功',
            type: 'success',
            duration: 2000
          });

          _this6.getList();
        }
      });
    },
    deleteOrder: function deleteOrder(row) {
      var _this7 = this;

      this.$confirm('此操作将永久删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.orderDel(row.id).then(function (res) {
          _this7.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};