import request from '@/utils/request';
var tongjiService = {
  getBaomingList: function getBaomingList(params) {
    return request({
      url: '/operation/stats/activitySingUpList',
      method: 'get',
      params: params
    });
  },
  addStreet: function addStreet(data) {
    return request({
      url: '/operation/stats/addStreet',
      method: 'post',
      data: data
    });
  },
  streetList: function streetList(params) {
    return request({
      url: '/operation/stats/getAllStatusStreet',
      method: 'get',
      params: params
    });
  },
  getUserList: function getUserList(params) {
    return request({
      url: '/operation/stats/list',
      method: 'get',
      params: params
    });
  },
  addUserAuth: function addUserAuth(data) {
    return request({
      url: '/operation/stats/auditUserForStreet',
      method: 'post',
      data: data
    });
  },
  deleteTown: function deleteTown(id) {
    return request({
      url: '/operation/userAddress/deleteTown/' + id,
      method: 'delete'
    });
  },
  updateStatus: function updateStatus(params) {
    return request({
      url: '/operation/stats/updateStatus',
      method: 'put',
      params: params
    });
  },
  getXiaoquList: function getXiaoquList(params) {
    return request({
      url: '/operation/supervisorRecord/allRegion',
      method: 'get',
      params: params
    });
  },
  getSupervisorRecord: function getSupervisorRecord(params) {
    return request({
      url: '/operation/supervisorRecord/getSupervisorRecord',
      method: 'get',
      params: params
    });
  },
  updateSupervisorRecord: function updateSupervisorRecord(data) {
    return request({
      url: '/operation/supervisorRecord/updateSupervisorRecord',
      method: 'post',
      data: data
    });
  },
  editstreet: function editstreet(params) {
    return request({
      url: '/operation/userAddress/modifyStreet',
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    });
  },
  exportrecycle: function exportrecycle(params) {
    return request({
      url: '/recycle/all',
      method: 'post',
      params: params
    });
  },
  updateScreenNameSps: function updateScreenNameSps(params) {
    return request({
      url: '/operation/startPlatformStreet/updateScreenNameSps',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  }
};
export default tongjiService;