var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        "value-key": "id",
        filterable: "",
        placeholder: "请选择学校",
        clearable: true,
      },
      on: { change: _vm.changes },
      model: {
        value: _vm.schooVal,
        callback: function ($$v) {
          _vm.schooVal = $$v
        },
        expression: "schooVal",
      },
    },
    _vm._l(_vm.schoolList, function (obj) {
      return _c("el-option", { key: obj, attrs: { label: obj, value: obj } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }