import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/dizhi';
export default {
  name: 'addrselect',
  props: {
    showFloor: {
      type: Boolean,
      default: true
    },
    street: [String, Number, Object],
    community: [String, Number, Object],
    region: [String, Number, Object],
    floor: [String, Number, Object],
    streetId: [String, Number, Object],
    communityId: [String, Number, Object],
    regionId: [String, Number, Object],
    mark: [String, Number, Object],
    pointName: [String, Number, Object]
  },
  data: function data() {
    return {
      form: {
        community: '',
        region: '',
        street: '',
        floor: '',
        communityId: '',
        regionId: '',
        streetId: '',
        mark: '',
        pointName: ''
      },
      // 社区列表
      streetList: [],
      shequList: [],
      communityList: [],
      pointList: []
    };
  },
  watch: {
    streetId: function streetId(val) {
      if (val) {
        this.form.streetId = val;
      }
    },
    communityId: function communityId(val) {
      if (val) {
        this.form.communityId = val;
      }
    },
    regionId: function regionId(val) {
      if (val) {
        this.form.regionId = val;
      }
    },
    mark: function mark(val) {
      // console.log(val);
      if (val) {
        this.form.mark = val;
      }
    },
    street: function street(val) {
      this.form.street = val; //console.log(val)

      this.form.community = '';
      this.form.region = '';
      this.form.floor = '';
    },
    community: function community(val) {
      //console.log(val)
      this.form.community = val;
      this.form.region = '';
      this.form.floor = '';
    },
    region: function region(val) {
      //console.log(val)
      this.form.region = val;
      this.form.floor = '';
    },
    floor: function floor(val) {
      this.form.floor = val;
    },
    pointName: function pointName(val) {
      this.form.pointName = val;
    },
    // 监听选择的社区
    'form.street': function formStreet(val) {
      var _this = this;

      if (val) {
        this.shequList = [];
        this.communityList = [];
        this.pointList = []; // this.form.mark = ""
        // this.form.pointName  = ''

        if (val) {
          Service.shequList({
            currentPage: 1,
            numOfPage: 10000,
            streetName: val
          }).then(function (res) {
            // console.log(res)
            _this.shequList = res.data;

            if (!_this.form.community && !_this.form.region) {
              _this.getPoints();
            }

            _this.change();
          });
        }
      }
    },
    'form.community': function formCommunity(val) {
      var _this2 = this;

      // console.log("111"+val+"222")
      if (val) {
        var streetName = this.form.street;
        this.communityList = [];
        this.pointList = []; // this.form.mark = ""
        // this.form.pointName  = ''

        if (val) {
          Service.xiaoquList({
            currentPage: 1,
            numOfPage: 10000,
            street: streetName,
            community: val
          }).then(function (res) {
            _this2.communityList = res.data.list;

            if (!_this2.form.region) {
              _this2.getPoints();
            }

            _this2.change();
          });
        }
      }
    },
    // 监听选择的小区
    'form.region': function formRegion(val) {
      // this.form.mark = ""
      // this.form.pointName  = ''
      this.pointList = [];
      this.getPoints();
      this.change();
    },
    // 监听选择的小区
    'form.floor': function formFloor(val) {
      this.change();
    },
    'form.pointName': function formPointName(val) {
      this.change();
    }
  },
  created: function created() {
    this.getJiedao();
    this.init();
  },
  methods: {
    streetTest: function streetTest(name) {
      this.form.street = name.street; // console.log(name)

      this.form.streetId = name.id; // this.change()
    },
    communityTest: function communityTest(name) {
      this.form.community = name.name;
      this.form.communityId = name.id; // this.change()
    },
    regionTest: function regionTest(name) {
      this.form.region = name.region;
      this.form.regionId = name.id; // this.change()
    },
    pointTest: function pointTest(name) {
      this.form.mark = name.mark;
      this.form.pointName = name.pointName;
    },
    change: function change() {
      this.$emit('addrChange', this.form);
    },
    init: function init() {
      // 把页面值传入控件
      this.form.street = this.street;
      this.form.region = this.region;
      this.form.community = this.community;
      this.form.streetId = this.streetId;
      this.form.regionId = this.regionId;
      this.form.communityId = this.communityId;
      this.form.floor = this.floor;
      this.form.mark = this.mark;
      this.form.pointName = this.pointName;
    },
    // 获取街道
    getJiedao: function getJiedao() {
      var _this3 = this;

      Service.streetList().then(function (res) {
        _this3.streetList = res.data;
      });
    },
    // 点位列表
    getPoints: function getPoints() {
      var _this4 = this;

      // console.log(this.form);
      Service.queryPointInformation({
        "street": this.form.street,
        "community": this.form.community,
        "region": this.form.region
      }).then(function (res) {
        _this4.pointList = res.data;
      });
    }
  }
};