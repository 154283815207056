//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
export default {
  name: 'Datishuoming',
  data: function data() {
    return {
      form: {
        'id': 0,
        'introduce': '',
        'title': '',
        'type': 0,
        'perDayNumber': 0,
        'singleNumber': 0,
        'pass': 0,
        'coin': 0
      },
      rules: {
        title: [{
          required: true,
          message: '活动标题为必填项',
          trigger: 'blur'
        }],
        introduce: [{
          required: true,
          message: '活动说明为必填项',
          trigger: 'blur'
        }],
        perDayNumber: [{
          required: true,
          message: '每日参与次数为必填项',
          trigger: 'blur'
        }],
        singleNumber: [{
          required: true,
          message: '单次题目数量为必填项',
          trigger: 'blur'
        }],
        pass: [{
          required: true,
          message: '及格题目数为必填项',
          trigger: 'blur'
        }],
        coin: [{
          required: true,
          message: '活动奖励积分为必填项',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getData();
  },
  methods: {
    getData: function getData() {
      var _this = this;

      Service.getDatiDesc().then(function (res) {
        _this.form = res.data[0];
      });
    },
    updateData: function updateData() {
      var _this2 = this;

      console.log(this.form);
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          Service.updateDatiDesc(_this2.form).then(function (res) {
            _this2.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success',
              duration: 2000
            });
          });
        }
      });
    }
  }
};