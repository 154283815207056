var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("screensetting:insert")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { margin: "30px" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.addScreenSetting },
                },
                [_vm._v("添加大屏设置")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "城市（区/县）" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.districtName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.streetName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "大屏类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.dataType == 0 ? "区县设置" : "街道设置"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道logo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.logoPic
                      ? _c("img", {
                          staticStyle: { width: "80%" },
                          attrs: {
                            src: "http://pic.touduila.com/" + scope.row.logoPic,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(
                                "http://pic.touduila.com/" + scope.row.logoPic
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "监控封面" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.devicePic
                      ? _c("img", {
                          staticStyle: { width: "80%" },
                          attrs: {
                            src:
                              "http://pic.touduila.com/" + scope.row.devicePic,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(
                                "http://pic.touduila.com/" + scope.row.devicePic
                              )
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "显示名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.screenName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(scope.row.status == 0 ? "已关闭" : "已开启")
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "监控" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.deviceNo)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.updateTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "临时数据" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("screensetting:edittemp")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editTemplateData(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑临时数据")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("screensetting:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 1 && _vm.hasPerm("screensetting:close")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(scope.row)
                              },
                            },
                          },
                          [_vm._v("关闭")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status === 0 && _vm.hasPerm("screensetting:close")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.updateStatus(scope.row)
                              },
                            },
                          },
                          [_vm._v("开启")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加大屏设置", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "insertForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据大屏类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "dataType",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.dataType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dataType", $$v)
                        },
                        expression: "form.dataType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("区县设置"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("街道设置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "省",
                    "label-width": _vm.formLabelWidth,
                    prop: "provinceId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省" },
                      on: { change: _vm.getCity },
                      model: {
                        value: _vm.form.provinceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "provinceId", $$v)
                        },
                        expression: "form.provinceId",
                      },
                    },
                    _vm._l(_vm.province, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "市",
                    "label-width": _vm.formLabelWidth,
                    prop: "cityId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择市" },
                      on: { change: _vm.getDistrict },
                      model: {
                        value: _vm.form.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cityId", $$v)
                        },
                        expression: "form.cityId",
                      },
                    },
                    _vm._l(_vm.city, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区/县",
                    "label-width": _vm.formLabelWidth,
                    prop: "districtId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区/县" },
                      on: { change: _vm.changeDistrict },
                      model: {
                        value: _vm.form.districtId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "districtId", $$v)
                        },
                        expression: "form.districtId",
                      },
                    },
                    _vm._l(_vm.district, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      on: { change: _vm.Begin },
                      model: {
                        value: _vm.form.streetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "streetId", $$v)
                        },
                        expression: "form.streetId",
                      },
                    },
                    _vm._l(_vm.stree, function (i) {
                      return _c("el-option", {
                        key: i.street,
                        attrs: { label: i.street, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据大屏名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "screenName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.screenName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "screenName", $$v)
                      },
                      expression: "form.screenName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "监控编码",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.deviceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceNo", $$v)
                      },
                      expression: "form.deviceNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "监控封面",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.devicePic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.devicePic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemove(_vm.item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "logo",
                    "label-width": _vm.formLabelWidth,
                    prop: "logoPic",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadLogo",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadLogoImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.logoPic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src: "http://pic.touduila.com/" + _vm.logoPic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleLogoRemove(_vm.item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑大屏配置", visible: _vm.FormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.FormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateForm",
              attrs: { model: _vm.forms, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据大屏类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "dataType",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.forms.dataType,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "dataType", $$v)
                        },
                        expression: "forms.dataType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("区县设置"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("街道设置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "省",
                    "label-width": _vm.formLabelWidth,
                    prop: "provinceId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省" },
                      on: { change: _vm.getCity },
                      model: {
                        value: _vm.forms.provinceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "provinceId", $$v)
                        },
                        expression: "forms.provinceId",
                      },
                    },
                    _vm._l(_vm.province, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "市",
                    "label-width": _vm.formLabelWidth,
                    prop: "cityId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择市" },
                      on: { change: _vm.getDistrict },
                      model: {
                        value: _vm.forms.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "cityId", $$v)
                        },
                        expression: "forms.cityId",
                      },
                    },
                    _vm._l(_vm.city, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区/县",
                    "label-width": _vm.formLabelWidth,
                    prop: "districtId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区/县" },
                      on: { change: _vm.changeDistrict },
                      model: {
                        value: _vm.forms.districtId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "districtId", $$v)
                        },
                        expression: "forms.districtId",
                      },
                    },
                    _vm._l(_vm.district, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      on: { change: _vm.Begin },
                      model: {
                        value: _vm.forms.streetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "streetId", $$v)
                        },
                        expression: "forms.streetId",
                      },
                    },
                    _vm._l(_vm.stree, function (i) {
                      return _c("el-option", {
                        key: i.street,
                        attrs: { label: i.street, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据大屏名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "screenName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.screenName,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "screenName", $$v)
                      },
                      expression: "forms.screenName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "监控编码",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.deviceNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "deviceNo", $$v)
                      },
                      expression: "forms.deviceNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "监控封面",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.devicePic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.devicePic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemove(_vm.item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "logo",
                    "label-width": _vm.formLabelWidth,
                    prop: "logoPic",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadLogo",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadLogoImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.logoPic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src: "http://pic.touduila.com/" + _vm.logoPic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleLogoRemove(_vm.item)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.FormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateScreenSetting },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑大屏配置临时数据",
            visible: _vm.FormTemplateVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.FormTemplateVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "updateTemplateForm",
              attrs: { model: _vm.templateForm, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "省", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.templateForm.provinceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "provinceName", $$v)
                      },
                      expression: "templateForm.provinceName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "市", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.templateForm.cityName,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "cityName", $$v)
                      },
                      expression: "templateForm.cityName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "区/县", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.templateForm.districtName,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "districtName", $$v)
                      },
                      expression: "templateForm.districtName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.templateForm.streetName,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "streetName", $$v)
                      },
                      expression: "templateForm.streetName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "工作人员",
                    "label-width": _vm.formLabelWidth,
                    prop: "workPerson",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.workPerson,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "workPerson", $$v)
                      },
                      expression: "templateForm.workPerson",
                    },
                  }),
                  _vm._v("  （填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "宣传活动",
                    "label-width": _vm.formLabelWidth,
                    prop: "activity",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.activity,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "activity", $$v)
                      },
                      expression: "templateForm.activity",
                    },
                  }),
                  _vm._v("（填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "减碳排放量",
                    "label-width": _vm.formLabelWidth,
                    prop: "carbonReduce",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.carbonReduce,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "carbonReduce", $$v)
                      },
                      expression: "templateForm.carbonReduce",
                    },
                  }),
                  _vm._v("（填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "参与用户数",
                    "label-width": _vm.formLabelWidth,
                    prop: "userCount",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.userCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "userCount", $$v)
                      },
                      expression: "templateForm.userCount",
                    },
                  }),
                  _vm._v("（填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "智能回收设备",
                    "label-width": _vm.formLabelWidth,
                    prop: "device",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.device,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "device", $$v)
                      },
                      expression: "templateForm.device",
                    },
                  }),
                  _vm._v("（填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "覆盖小区",
                    "label-width": _vm.formLabelWidth,
                    prop: "village",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.templateForm.village,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "village", $$v)
                      },
                      expression: "templateForm.village",
                    },
                  }),
                  _vm._v("（填写正整数）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              false
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "四分类订单(次数)",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderCount",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.templateForm.orderCount,
                          callback: function ($$v) {
                            _vm.$set(_vm.templateForm, "orderCount", $$v)
                          },
                          expression: "templateForm.orderCount",
                        },
                      }),
                      _vm._v(
                        "（依次填写：厨余垃圾-可回收物-有害垃圾-其他垃圾）次数，用“-”分割\n        "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "四分类订单(重量)",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderWeight",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.orderWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "orderWeight", $$v)
                      },
                      expression: "templateForm.orderWeight",
                    },
                  }),
                  _vm._v(
                    "（依次填写：厨余垃圾-可回收物-有害垃圾-其他垃圾）重量，用“-”分割\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "开展场所",
                    "label-width": _vm.formLabelWidth,
                    prop: "place",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.place,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "place", $$v)
                      },
                      expression: "templateForm.place",
                    },
                  }),
                  _vm._v(
                    "（依次填写：开展小区,开展企业) 用英文逗号“,”分割\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户分布",
                    "label-width": _vm.formLabelWidth,
                    prop: "placeUsers",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.placeUsers,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "placeUsers", $$v)
                      },
                      expression: "templateForm.placeUsers",
                    },
                  }),
                  _vm._v(
                    "（依次填写：小区用户数,企业用户数) 用英文逗号“,”分割\n        "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "参与用户数",
                    "label-width": _vm.formLabelWidth,
                    prop: "userCounts",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.userCounts,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "userCounts", $$v)
                      },
                      expression: "templateForm.userCounts",
                    },
                  }),
                  _vm._v("（填写10日的数据) 用英文逗号“,”分割\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "本月发放积分量",
                    "label-width": _vm.formLabelWidth,
                    prop: "coin",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "coin", $$v)
                      },
                      expression: "templateForm.coin",
                    },
                  }),
                  _vm._v("（填写10日的数据) 用英文逗号“,”分割\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "工作人员督导",
                    "label-width": _vm.formLabelWidth,
                    prop: "sorting",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.sorting,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "sorting", $$v)
                      },
                      expression: "templateForm.sorting",
                    },
                  }),
                  _vm._v("（填写至少2个督导员id) 用英文逗号“,”分割\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "监控配置",
                    "label-width": _vm.formLabelWidth,
                    prop: "monitorConfig",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.templateForm.monitorConfig,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateForm, "monitorConfig", $$v)
                      },
                      expression: "templateForm.monitorConfig",
                    },
                  }),
                  _vm._v("（填写一个设备编码）\n        "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "宣传活动",
                    "label-width": _vm.formLabelWidth,
                    prop: "activityPic",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadActive",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": true,
                        "on-success": _vm.uploadActivityImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.activityPic.length != 0
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        _vm._l(_vm.activityPic, function (i, index) {
                          return _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src: "http://pic.touduila.com/" + i,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleActivityRemove(i)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.templateForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.templateForm, "status", $$v)
                        },
                        expression: "templateForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("已关闭"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("已开启"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v("（开启则页面读取临时数据）\n        "),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.FormTemplateVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateScreenTemplateData },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }