//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/kitchensorting';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
import draggable from 'vuedraggable';
import { parseTime } from "@/utils";
export default {
  components: {
    Pagination: Pagination,
    draggable: draggable
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        placeName: "",
        // 查询条件
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}')
      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      dialogAddOrUpdateFormVisible: false,
      form: {
        id: "",
        placeName: 0,
        mbId: "",
        streetId: "",
        address: "",
        status: 0,
        password: "",
        uploadDataTimeYn: "",
        uploadDataTime: ""
      },
      streets: [],
      rules: {
        placeName: [{
          required: true,
          message: "分拣场命名是必填项"
        }],
        mbId: [{
          required: true,
          message: "分拣场安卓屏设备码是必填项"
        }],
        streetId: [{
          required: true,
          message: "放置街道是必填项",
          trigger: ['change', 'blur']
        }],
        status: [{
          required: true,
          message: "状态是必填项"
        }],
        password: [{
          required: true,
          message: "安卓屏登录后台口令是必填项"
        }],
        uploadDataTimeYn: [{
          required: true,
          message: "是否上报实时称重是必填项"
        }],
        uploadDataTime: [{
          required: true,
          message: "称重实时上报时间是必填项"
        }]
      }
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      } else {
        this.listQuery.startTime = '';
        this.listQuery.endTime = '';
      }
    }
  },
  created: function created() {
    this.getJiedao(); // 街道列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getPlacesList(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    getJiedao: function getJiedao() {
      var _this2 = this;

      ServiceStreet.getArtjied().then(function (res) {
        console.log(res.data);
        _this2.streets = res.data;
      });
    },
    streetChange: function streetChange(streetId) {
      // 街道列表改变赋值
      this.form.streetId = streetId;
    },
    edit: function edit(rows) {
      this.form.id = rows.id;
      this.form.placeName = rows.placeName;
      this.form.mbId = rows.mbId;
      this.form.streetId = rows.streetId;
      this.form.address = rows.address;
      this.form.status = rows.status;
      this.form.password = rows.password;
      this.form.uploadDataTimeYn = rows.uploadDataTimeYn;
      this.form.uploadDataTime = rows.uploadDataTime;
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          Service.updatePlace(_this3.form).then(function (res) {
            if (res.data.status == 1) {
              _this3.$message({
                message: res.data.msg,
                type: 'error'
              });
            } else {
              _this3.$message({
                message: '操作成功',
                type: 'success'
              });

              _this3.dialogAddOrUpdateFormVisible = false;

              _this3.getList();
            }
          });
        }
      });
    },
    del: function del(row) {
      var _this4 = this;

      //console.log(row)
      Service.checkPlaceDelete(row.mbId).then(function (res) {
        console.log(res);

        if (res.data.status == 1) {
          _this4.$notify({
            title: '失败',
            message: res.data.msg,
            type: 'error',
            duration: 2000
          });

          return false;
        } else {
          _this4.$confirm('是否删除当前数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            Service.delPlace(row.mbId).then(function (res) {
              _this4.$message({
                type: 'success',
                message: '删除成功!'
              });

              _this4.getList();
            });
          }).catch(function () {});
        }
      });
    }
  }
};