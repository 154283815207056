import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/fhtsys';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        roleName: '',
        page: 1,
        limit: 20
      },
      addFormVisible: false,
      editFormVisible: false,
      addForm: {
        roleName: '',
        authorityIds: '' // 选中的节点id

      },
      editForm: {
        roleId: '',
        roleName: '',
        authorityIds: ''
      },
      roleRules: {
        roleName: [{
          required: true,
          message: '不允许为空',
          trigger: 'blur'
        }]
      },
      addFilterText: '',
      // 过滤的选择
      editFilterText: '',
      // 过滤的选择
      treeExpandData: [0, 1, 4],
      // 展开的节点 默认全部展开 :default-expand-all="true"
      defaultTree: [],
      // 默认选中的checkbox
      defaultProps: {
        id: "id",
        label: 'name',
        path: "path",
        parentId: "parentId",
        authorityType: "authorityType",
        icon: "icon",
        sort: "sort",
        children: 'children'
      },
      data2: [],
      data3: [],
      checked: "",
      // 是否选中
      checkStrictly: "false" //编辑tree是否强关联

    };
  },
  created: function created() {
    this.getList();
  },
  watch: {
    addFilterText: function addFilterText(val) {
      this.$refs.addIntertree.filter(val);
    },
    editFilterText: function editFilterText(val) {
      this.$refs.editIntertree.filter(val);
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getList(this.listQuery.roleName).then(function (res) {
        _this.list = res.data.data;
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addLoadRole: function addLoadRole() {
      var _this2 = this;

      this.defaultTree = [];
      this.addFormVisible = true, Service.getAuthoritys().then(function (res) {
        _this2.data2 = res.data.data;
      });
    },
    addRole: function addRole() {
      var _this3 = this;

      var self = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          _this3.addForm.authorityIds = _this3.$refs.addIntertree.getCheckedKeys().join(",") + "," + _this3.$refs.addIntertree.getHalfCheckedKeys().join(","); // 子节点 + 父节点

          Service.saveRole(self.addForm).then(function (res) {
            if (res.data.error) {
              _this3.$notify({
                title: '失败',
                message: res.data.error,
                type: 'error',
                duration: 2000
              });
            } else {
              _this3.addFormVisible = false;

              _this3.$notify({
                title: '成功',
                message: '添加成功',
                type: 'success',
                duration: 2000,
                onClose: function onClose() {
                  self.getList();
                }
              });
            }
          });
        }
      });
    },
    editRole: function editRole() {
      var _this4 = this;

      var self = this;
      this.$refs['editForm'].validate(function (valid) {
        if (valid) {
          _this4.editForm.authorityIds = _this4.$refs.editIntertree.getCheckedKeys().join(",") + "," + _this4.$refs.editIntertree.getHalfCheckedKeys().join(","); // 子节点 + 父节点
          //this.editForm.authorityIds = this.$refs.editIntertree.getCheckedKeys().join(",");

          console.log(_this4.editForm.authorityIds);
          Service.updateRole(self.editForm).then(function (res) {
            _this4.editFormVisible = false;

            _this4.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success',
              duration: 2000,
              onClose: function onClose() {
                self.getList();
              }
            });
          });
        }
      });
    },
    editLoadRole: function editLoadRole(row) {
      var _this5 = this;

      this.defaultTree = [];
      this.editFormVisible = true;
      this.editForm.roleName = row.roleName;
      this.editForm.roleId = row.id;
      /* Service.getAuthoritys().then(res=>{
           this.data2 = res.data.data;
            this.defaultTree= [1,2,3];
       });*/
      // 加载权限树

      this.checkStrictly = true; //重点：给数节点赋值之前 先设置为true

      this.$nextTick(function () {
        var arr = [];
        Service.getAccessRoleAuthorityByRoleId({
          "roleId": row.id
        }).then(function (res) {
          _this5.data3 = res.data.data.accessAuthoritys;
          res.data.data.roleAuthoritys.forEach(function (item) {
            //console.log(item.aid);
            _this5.defaultTree.push(item.aid);

            arr.push(item.aid);
          });
          _this5.checkStrictly = false; //重点： 赋值完成后 设置为false
        }); //加载角色-权限
      });
    },

    /*delRole(row) {
      const self = this
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        server.delRoles([row.roleId]).then(res => {
          console.log(res)
          self.$message({
            type: 'success',
            message: '删除成功!',
            onClose() {
              self.getList()
            }
          })
        })
      }).catch(() => {
         this.$message({
              type: 'info',
              message: '已取消删除'
         });
      })
    },*/
    // 全部选中
    chooseAll: function chooseAll() {
      this.idList = ""; // 判断按钮的状态

      if (this.checked) {
        // 设置
        this.$refs.addIntertree.setCheckedNodes(this.data2);

        for (var i = 0; i < this.$refs.addIntertree.getCheckedNodes().length; i++) {
          this.idList += this.$refs.addIntertree.getCheckedNodes()[i].ID + ",";
        }

        this.idList = this.idList.slice(0, this.idList.length - 1);
      } else {
        this.$refs.addIntertree.setCheckedNodes([]);
        this.idList = "";
      }
    },
    chooseAllEdit: function chooseAllEdit() {
      this.idList = ""; // 判断按钮的状态

      if (this.checked) {
        // 设置
        this.$refs.editIntertree.setCheckedNodes(this.data3);

        for (var i = 0; i < this.$refs.editIntertree.getCheckedNodes().length; i++) {
          this.idList += this.$refs.editIntertree.getCheckedNodes()[i].ID + ",";
        }

        this.idList = this.idList.slice(0, this.idList.length - 1);
      } else {
        this.$refs.editIntertree.setCheckedNodes([]);
        this.idList = "";
      }
    },
    closeInterface: function closeInterface() {
      // this.$refs.addIntertree.store.nodesMap[0].expanded = false;
      this.$refs.editIntertree.store.nodesMap[0].expanded = false;
    },
    filterNode: function filterNode(value, data) {
      if (!value) {
        return true;
      }

      return data.name.indexOf(value) !== -1;
    },
    handleDragStart: function handleDragStart(node, ev) {
      console.log('drag start', node);
      console.log('ev=', ev);
    },
    handleDragEnter: function handleDragEnter(draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.name);
      console.log('ev=', ev);
    },
    handleDragLeave: function handleDragLeave(draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.name);
      console.log('ev=', ev);
    },
    handleDragOver: function handleDragOver(draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.name);
      console.log('ev=', ev);
    },
    handleDragEnd: function handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.name, dropType);
      console.log('ev=', ev);
    },
    handleDrop: function handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.name, dropType);
      console.log('ev=', ev);
    },
    allowDrop: function allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.name === '二级 3-1') {
        return type !== 'inner';
      } else {
        return true;
      }
    },
    allowDrag: function allowDrag(draggingNode) {
      return draggingNode.data.name.indexOf('三级 3-2-2') === -1;
    }
  }
};