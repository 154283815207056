//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination';
import dizhiService from '@/api/banner';
import { parseTime } from "@/utils";
export default {
  name: 'Shequ',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      rules: {
        name: [{
          required: true,
          message: "奖品名称是必填项"
        }],
        pic: [{
          required: true,
          message: "奖品图片是必选项"
        }],
        number: [{
          required: true,
          message: "奖品数量是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必选项"
        }],
        status: [{
          required: true,
          message: "状态是必选项"
        }]
      },
      SureStreet: null,
      prizeTypes: [{
        id: 0,
        name: '虚拟奖品'
      }, {
        id: 1,
        name: '实体奖品'
      }],
      timeTypes: [{
        id: 0,
        name: '按中奖时间查找'
      }, {
        id: 1,
        name: '按核销时间查找'
      }],
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        street: '',
        key: '',
        prizeType: "",
        // 奖品类型
        timeType: 0,
        // 日期类型
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20
      },
      searchStreet: null // 存放查询街道数据

    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      }
    },
    time: function time(val) {
      console.log(val);
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    uploadImg: function uploadImg(data) {
      this.form.pic = data.key;
    },
    getList: function getList() {
      var _this = this;

      console.log(this.listQuery);
      this.listLoading = true;
      Service.getzhongLucky(this.listQuery).then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    Search: function Search() {
      this.getList();
    },
    hexiao: function hexiao(row) {
      var _this2 = this;

      Service.Luckyhexiao({
        id: row.id
      }).then(function (res) {
        _this2.$notify({
          title: '成功',
          message: '核销成功',
          type: 'success',
          duration: 2000
        });

        _this2.getList();
      });
    },
    exportList: function exportList() {
      Service.getzhongLuckyExport(this.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var elink = document.createElement('a');
          elink.download = '中奖列表.xls';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    }
  }
};