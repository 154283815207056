//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import actionService from '@/api/actionstation';
import waves from '@/directive/waves'; // waves directive

var map = null;
export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: '',
        community: '',
        region: '',
        streetId: '',
        siteId: this.$route.query.siteId,
        // 行动站id
        gpCode: this.$route.query.gpCode // 标牌编码

      },
      total: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      Visible: false,
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      kong: '',
      uploadPic: [],
      sites: [] //行动站列表

    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      actionService.xdzManageGpUsageRecord(this.listQuery).then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.rowsCount;
        _this.listLoading = false;
      });
    }
  }
};