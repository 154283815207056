var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入查询条件" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查询\n\t\t")]
          ),
          _vm._v(" "),
          _vm.hasPerm("chuyu:export")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("chuyu:insert")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "success" },
                  on: { click: _vm.addjilu },
                },
                [_vm._v("添加投递记录")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "100%" },
          attrs: { title: "添加厨余投递记录", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "80%", display: "block" },
                    attrs: { autocomplete: "off" },
                    on: { focus: _vm.tiaochu },
                    model: {
                      value: _vm.chanames,
                      callback: function ($$v) {
                        _vm.chanames = $$v
                      },
                      expression: "chanames",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shows,
                    expression: "shows",
                  },
                ],
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入查询条件" },
                model: {
                  value: _vm.lQuery.key,
                  callback: function ($$v) {
                    _vm.$set(_vm.lQuery, "key", $$v)
                  },
                  expression: "lQuery.key",
                },
              }),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shows,
                    expression: "shows",
                  },
                ],
                staticClass: "filter-item",
                staticStyle: { width: "120px" },
                attrs: { placeholder: "二维码组号" },
                model: {
                  value: _vm.lQuery.qrcodeGroup,
                  callback: function ($$v) {
                    _vm.$set(_vm.lQuery, "qrcodeGroup", $$v)
                  },
                  expression: "lQuery.qrcodeGroup",
                },
              }),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shows,
                      expression: "shows",
                    },
                  ],
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  on: { change: _vm.handleF },
                  model: {
                    value: _vm.lQuery.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.lQuery, "type", $$v)
                    },
                    expression: "lQuery.type",
                  },
                },
                _vm._l(_vm.Options, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shows,
                      expression: "shows",
                    },
                  ],
                  staticClass: "filter-item",
                  staticStyle: { width: "120px" },
                  on: { change: _vm.handleF },
                  model: {
                    value: _vm.lQuery.role,
                    callback: function ($$v) {
                      _vm.$set(_vm.lQuery, "role", $$v)
                    },
                    expression: "lQuery.role",
                  },
                },
                _vm._l(_vm.datas, function (item) {
                  return _c("el-option", {
                    key: item.name,
                    attrs: { label: item.name, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shows,
                      expression: "shows",
                    },
                    { name: "waves", rawName: "v-waves" },
                  ],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilters },
                },
                [_vm._v("查询")]
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shows,
                      expression: "shows",
                    },
                  ],
                  key: _vm.tableKey,
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: {
                    data: _vm.datalist,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: _vm.$t("table.id"),
                      prop: "id",
                      sortable: "custom",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "昵称", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.nickName))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "头像", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("img", {
                              attrs: {
                                src: scope.row.avatarUrl,
                                width: "30",
                                alt: "",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "可回收订单数", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.ownerOrderCount)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "厨余投递量", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.kitchenUserRecordCount)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "领袋数量", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.kitchenUserCount)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "二维码组号", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [_vm._v(_vm._s(scope.row.qrcodeGroup))]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "二维码绑定时间",
                      align: "center",
                      width: "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.lastKitchenTime)),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "所在城市", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.country) +
                                  " ，" +
                                  _vm._s(scope.row.province) +
                                  " ， " +
                                  _vm._s(scope.row.city)
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "电话", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeUserLocation(scope.row)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shows,
                    expression: "shows",
                  },
                ],
                attrs: {
                  total: _vm.totals,
                  page: _vm.lQuery.currentPage,
                  limit: _vm.lQuery.numOfPage,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.lQuery, "currentPage", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.lQuery, "numOfPage", $event)
                  },
                  pagination: _vm.en,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
            "default-expand-all": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递人", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.nickName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "地址", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "二维码组号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.qrcodeGroup))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "二维码编号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.qrcodeId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "网格员", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.recyclerName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "获得积分", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.coin))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }