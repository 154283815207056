//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import { parseTime } from '@/utils';
export default {
  name: 'Wuziguanli',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  data: function data() {
    return {
      lists: '',
      lQuery: {
        queryInfo: ''
      },
      listLoading: false,
      time: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      form: {
        tel: ''
      },
      list: null,
      total: 0,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        num: '',
        street: ''
      },
      searchStreet: '',
      beizhuVisible: false,
      addr: {
        recyclerId: '',
        bagNumber: ''
      },
      shows: false
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.addr.street = val.street;
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
        this.addr.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    changeUserLocation: function changeUserLocation(e) {
      this.addr.recyclerId = e.weixinId;
      this.shows = false;
    },
    handleFilters: function handleFilters() {
      var _this = this;

      this.shows = true;
      Service.selectdudao(this.lQuery).then(function (res) {
        console.log(res);
        _this.lists = res.data.data;
        _this.listLoading = false;
      });
    },
    Sures: function Sures() {
      var _this2 = this;

      Service.feetbangdai(this.addr).then(function (res) {
        console.log(res);

        if (res.data.data) {
          _this2.$message({
            message: res.data.data,
            type: 'success'
          });

          _this2.getList();

          _this2.beizhuVisible = false;
        } else {
          _this2.$message({
            message: res.data.error,
            type: 'error'
          });
        }
      });
    },
    exportlist: function exportlist() {
      this.beizhuVisible = true;
      this.addr.recyclerId = '', this.addr.bagNumber = '';
      this.shows = false;
      this.lQuery.queryInfo = '';
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    exportExcel: function exportExcel() {
      location.href = '/operator/kitchenbin/exportInStock?' + qs.stringify(this.listQuery);
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.wuziruku(this.listQuery).then(function (res) {
        setTimeout(function () {
          _this3.list = res.data.list;
          _this3.total = res.data.totalRows; //						this.listQuery.currentPage = res.data.totalPages

          _this3.listLoading = false;
        }, 1000);
      });
    }
  }
};