import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dizhiService from '@/api/dizhi';
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import Street from '@/components/street/jiedao';
export default {
  name: 'Price',
  components: {
    Pagination: Pagination,
    Street: Street
  },
  data: function data() {
    return {
      dialogVisibles: false,
      titles: '添加街道回收方式',
      // dialogVisible: false,
      form: {
        province: '',
        city: '',
        street: '' // tag: ''

      },
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      // streetList: [{
      // 		type: '自助回收',
      // 	},
      // 	{
      // 		type: '预约回收',
      // 	}
      // ],
      list: [],
      titlestwo: '',
      formData: [],
      // rules: {
      // 	street: [{
      // 		required: true,
      // 		message: '街道是必填项',
      // 		trigger: 'blur'
      // 	}],
      // 	tag: [{
      // 		required: true,
      // 		message: '回收类型是必填项',
      // 		trigger: 'blur'
      // 	}]
      // },
      rulesd: {
        title: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }]
      },
      //status: '',
      datas: ''
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /*add() {
    	this.dialogVisible = true
    },*/
    showUpdate: function showUpdate(e) {
      var _this = this;

      this.dialogVisibles = true; // this.status = a

      this.titlestwo = e.street;
      Service.seestreetpriceNew({
        street: e.street //,
        // tag: a

      }).then(function (res) {
        console.log(res);
        _this.formData = res.data.data;

        if (_this.formData) {
          _this.formData.forEach(function (item) {
            if (item.rewardType == 2) {
              // 积分
              item.unitPrice = item.unitPrice * 100;
            }
          });
        } // if (res.data.data[0]) {
        // 	this.formData = res.data.data[0].list
        // } else {
        // 	this.form.province = e.province
        // 	this.form.city = e.city
        // 	this.form.street = e.street
        // 	// this.form.tag = a
        // 	this.datas = e
        // 	this.formData = []
        // 	this.save()
        // }

      });
    },
    savePrice: function savePrice() {
      // 保存
      if (this.formData.length != 0) {
        this.formData.forEach(function (item, index) {
          var unitPrice = item.unitPrice;

          if (item.rewardType == 2) {
            unitPrice = unitPrice / 100;
          }

          Service.updatestreetpriceNew({
            id: item.id,
            unitPrice: unitPrice,
            maxWeight: item.maxWeight == null ? 0 : item.maxWeight
          }).then(function (res) {});
        });
        this.$notify({
          title: '成功',
          message: '保存成功',
          type: 'success',
          duration: 2000
        });
        this.dialogVisibles = false;
        this.getList();
      }
    },

    /*save() {
    	this.dialogVisible = false
    	Service.addstreetprice(this.form).then((res) => {
    		this.showUpdate(this.datas, this.form.tag)
    	})
    	},*/
    // addchange(e) {
    // 	console.log(e)
    // 	this.form.street = e.street
    // 	this.form.city = e.city
    // 	this.form.province = e.province
    // },
    getList: function getList(street) {
      var _this2 = this;

      this.listLoading = true;
      var param = Object.assign({}, this.listQuery, street || {});
      dizhiService.streetList(param).then(function (res) {
        console.log(res);
        var response = res.data;
        _this2.list = response;
        _this2.total = response.length;
        _this2.listLoading = false;
      });
    } // setPrice() {
    // 	Service.setPrice(this.formData).then(res => {
    // 		if (res.data.statusCode === 200) {
    // 			this.$notify({
    // 				title: '成功',
    // 				message: '保存成功',
    // 				type: 'success',
    // 				duration: 2000
    // 			})
    // 			this.getPrice()
    // 			location.reload();
    // 		}
    // 	})
    // },
    // setPrices() {
    // 	Service.setPrice(this.formDatas).then(res => {
    // 		if (res.data.statusCode === 200) {
    // 			this.$notify({
    // 				title: '成功',
    // 				message: '保存成功',
    // 				type: 'success',
    // 				duration: 2000
    // 			})
    // 			location.reload();
    // 		}
    // 	})
    // }

  }
};