import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    // 加载页面
    return request({
      url: '/recyclingBinsAccount/getList',
      method: 'get',
      params: params
    });
  },
  del: function del(params) {
    // 删除
    return request({
      url: '/recyclingBinsAccount/delete?id=' + params,
      method: 'post'
    });
  },
  add: function add(params) {
    // 添加
    return request({
      url: '/recyclingBinsAccount/insert',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  update: function update(params) {
    return request({
      url: '/recyclingBinsAccount/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  }
};
export default Service;