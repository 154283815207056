//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import devicescore from '@/api/devicescore';
export default {
  data: function data() {
    return {
      formLabelWidth: '120px',
      list: null,
      tableKey: 0
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      devicescore.getList("").then(function (res) {
        _this.list = res.data.data;
      });
    },
    Sure: function Sure() {
      var _this2 = this;

      var self = this; //console.log(JSON.stringify(this.list));

      var j = JSON.stringify(this.list);
      devicescore.updateRecyclingBinsScore(j).then(function (res) {
        console.log(res);

        if (res.data.status == 0) {
          self.$message({
            type: 'success',
            message: '修改成功!'
          });

          _this2.getList();
        } else {
          self.$message({
            type: 'success',
            message: '修改失败!'
          });

          _this2.getList();
        }
      });
    },
    cancle: function cancle() {
      this.getList();
    }
  }
};