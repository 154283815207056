var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "streetSelect", staticClass: "filter-item" },
    [
      _c(
        "el-select",
        {
          attrs: { placeholder: "请选择社区" },
          model: {
            value: _vm.form.community,
            callback: function ($$v) {
              _vm.$set(_vm.form, "community", $$v)
            },
            expression: "form.community",
          },
        },
        _vm._l(_vm.shequList, function (obj) {
          return _c("el-option", {
            key: obj.id,
            attrs: { label: obj.name, value: obj.name },
          })
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "el-select",
        {
          attrs: { placeholder: "请选择小区" },
          model: {
            value: _vm.form.region,
            callback: function ($$v) {
              _vm.$set(_vm.form, "region", $$v)
            },
            expression: "form.region",
          },
        },
        _vm._l(_vm.communityList, function (obj) {
          return _c("el-option", {
            key: obj.id,
            attrs: { label: obj.region, value: obj.region },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }