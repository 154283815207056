import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import screenSettingService from '@/api/screensetting';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 10
      },
      total: 0,
      tableKey: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      FormVisible: false,
      FormTemplateVisible: false,
      // 临时数据
      devicePic: '',
      // 图片上传
      logoPic: '',
      // 图片上传
      activityPic: [],
      // 宣传活动-多图上传
      form: {
        dataType: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        streetId: '',
        screenName: '',
        deviceNo: '',
        devicePic: '',
        logoic: ''
      },
      forms: {
        dataType: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        streetId: '',
        screenName: '',
        deviceNo: '',
        devicePic: '',
        logoic: ''
      },
      templateForm: {
        id: "",
        screenSettingId: "",
        provinceName: "",
        cityName: "",
        districtName: "",
        streetName: "",
        workPerson: "",
        activity: "",
        carbonReduce: "",
        userCount: "",
        device: "",
        village: "",
        orderCount: "1-2-3-4",
        // 订单次数，业务暂时不用
        orderWeight: "",
        place: "",
        placeUsers: "",
        userCounts: "",
        coin: "",
        sorting: "",
        monitorConfig: "",
        activityPic: "",
        status: ""
      },
      rules: {
        dataType: [{
          required: true,
          message: "数据大屏类型是必填项"
        }],
        provinceId: [{
          required: true,
          message: "省是必填项"
        }],
        cityId: [{
          required: true,
          message: "市是必填项"
        }],
        districtId: [{
          required: true,
          message: "区/县是必填项"
        }],

        /*streetId: [{
        required: true,
        message: "街道是必填项"
        }],*/
        screenName: [{
          required: true,
          message: "数据大屏名称是必填项"
        }],

        /*deviceNo: [{
        required: true,
        message: "监控编码是必填项"
        }],
        devicePic: [{
        required: true,
        message: "监控封面是必填项"
        }],
        logoPic: [{
        required: true,
        message: "logo是必填项"
        }],*/
        workPerson: [{
          required: true,
          message: "工作人员是必填项"
        }],
        activity: [{
          required: true,
          message: "宣传活动是必填项"
        }],
        carbonReduce: [{
          required: true,
          message: "减碳排放量是必填项"
        }],
        userCount: [{
          required: true,
          message: "参与用户数是必填项"
        }],
        device: [{
          required: true,
          message: "智能回收设备是必填项"
        }],
        village: [{
          required: true,
          message: "覆盖小区是必填项"
        }],

        /* orderCount:[{
            	required: true,
        	message: "四分类订单(次数)是必填项"
          }],*/
        orderWeight: [{
          required: true,
          message: "四分类订单(重量)是必填项"
        }],
        place: [{
          required: true,
          message: "开展场所是必填项"
        }],
        placeUsers: [{
          required: true,
          message: "用户分布是必填项"
        }],
        userCounts: [{
          required: true,
          message: "参与用户数是必填项"
        }],
        coin: [{
          required: true,
          message: "本月发放积分量是必填项"
        }],
        sorting: [{
          required: true,
          message: "工作人员(分拣：督导)是必填项"
        }],
        monitorConfig: [{
          required: true,
          message: "监控编码是必填项"
        }],

        /* activityPic:[{
            	required: true,
        	message: "宣传活动图片是必填项"
          }], */
        status: [{
          required: true,
          message: "状态是必填项"
        }]
      },
      formLabelWidth: '120px',
      listLoading: true,
      list: [],
      stree: '',
      province: '',
      // 省
      city: '',
      // 市
      district: '',
      // 区
      Visible: false
    };
  },
  created: function created() {
    this.getList(), this.getJiedao();
    this.getProvince();
  },
  watch: {},
  methods: {
    addScreenSetting: function addScreenSetting() {
      this.dialogFormVisible = true;
      this.form.dataType = '';
      this.form.provinceId = '';
      this.form.cityId = '';
      this.form.districtId = '';
      this.form.streetId = '';
      this.form.screenName = '';
      this.form.deviceNo = '';
      this.devicePic = '';
      this.logoPic = '';
    },
    editTemplateData: function editTemplateData(rows) {
      var _this = this;

      // 加载临时数据界面
      this.FormTemplateVisible = true;
      screenSettingService.getSettingTempDataById({
        "screenSettingId": rows.id
      }).then(function (res) {
        if (res.data.data != null) {
          _this.templateForm = res.data.data;
          _this.templateForm.provinceName = rows.provinceName;
          _this.templateForm.cityName = rows.cityName;
          _this.templateForm.districtName = rows.districtName;
          _this.templateForm.streetName = rows.streetName;
          _this.templateForm.screenSettingId = rows.id;

          if (res.data.data.activityPic != null) {
            _this.activityPic = res.data.data.activityPic.split(",");
          }

          if (res.data.id != null) {
            _this.templateForm.id = res.data.data.id;
          }
        } else {
          _this.templateForm.provinceName = rows.provinceName;
          _this.templateForm.cityName = rows.cityName;
          _this.templateForm.districtName = rows.districtName;
          _this.templateForm.streetName = rows.streetName;
          _this.templateForm.screenSettingId = rows.id;
          _this.templateForm.id = 0;
        }
      });
    },
    handleRemove: function handleRemove(item) {
      var _this2 = this;

      var index = this.devicePic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        //	this.lists.pic = ''
        _this2.devicePic = '';
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = []; //if (!this.lists.pic.includes(response.key)) {
      //	this.lists.pic = response.key;
      //	}

      this.devicePic = response.key;
      this.$refs.upload.clearFiles();
    },
    handleLogoRemove: function handleLogoRemove(item) {
      var _this3 = this;

      var index = this.logoPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        //	this.lists.pic = ''
        _this3.logoPic = '';
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    uploadLogoImg: function uploadLogoImg(response, file, fileList) {
      fileList = []; //if (!this.lists.pic.includes(response.key)) {
      //	this.lists.pic = response.key;
      //	}

      this.logoPic = response.key;
      this.$refs.uploadLogo.clearFiles();
    },
    Begin: function Begin(Jid) {
      // 街道列表改变赋值
      this.form.streetId = Jid;
    },
    getList: function getList() {
      var _this4 = this;

      screenSettingService.getList(this.listQuery).then(function (res) {
        _this4.list = res.data.list; //this.total = res.data.length

        _this4.total = res.data.totalRows;
        _this4.listLoading = false;
      });
    },
    getProvince: function getProvince() {
      var _this5 = this;

      Service.getProvinceCityDistrict({
        "parentId": 100000
      }).then(function (res) {
        _this5.province = res.data.data;
      });
    },
    getCity: function getCity(provinceId) {
      var _this6 = this;

      this.form.provinceId = provinceId;
      Service.getProvinceCityDistrict({
        "parentId": provinceId
      }).then(function (res) {
        _this6.city = res.data.data;
      });
    },
    getDistrict: function getDistrict(cityId) {
      var _this7 = this;

      this.form.cityId = cityId;
      Service.getProvinceCityDistrict({
        "parentId": cityId
      }).then(function (res) {
        _this7.district = res.data.data;
      });
    },
    changeDistrict: function changeDistrict(districtId) {
      this.form.districtId = districtId;
    },
    getJiedao: function getJiedao() {
      var _this8 = this;

      Service.getArtjied().then(function (res) {
        _this8.stree = res.data;
      });
    },
    updateStatus: function updateStatus(rows) {
      var _this9 = this;

      screenSettingService.updateScreenSettingStatusById({
        "id": rows.id,
        "status": rows.status === 1 ? 0 : 1
      }).then(function (res) {
        if (res.status == 200) {
          _this9.$message({
            message: '修改成功',
            type: 'success'
          });

          _this9.getList();
        }
      });
    },
    add: function add() {
      var _this10 = this;

      this.$refs['insertForm'].validate(function (valid) {
        if (valid) {
          if (_this10.form.dataType == 1) {
            if (_this10.form.streetId === '') {
              _this10.$message({
                message: '请将街道信息填充完整',
                type: 'error'
              });
            }
          } else {
            _this10.form.streetId = 0; // 后台转换空字符串报错
          }

          _this10.form.devicePic = _this10.devicePic;
          _this10.form.logoPic = _this10.logoPic;
          screenSettingService.add(_this10.form).then(function (res) {
            if (res.data.statusCode == 200) {
              _this10.dialogFormVisible = false;

              _this10.$message({
                message: '添加成功',
                type: 'success'
              });

              _this10.getList();
            } else if (res.data.statusCode == '300') {
              _this10.$message({
                message: '大屏设置数据不能重复添加',
                type: 'error'
              });
            }
          });
        }
      });
    },
    getProvinceEdit: function getProvinceEdit() {
      var _this11 = this;

      Service.getProvinceCityDistrict({
        "parentId": 100000
      }).then(function (res) {
        _this11.province = res.data.data;
      });
    },
    getCityEdit: function getCityEdit(provinceId) {
      var _this12 = this;

      this.forms.provinceId = provinceId;
      Service.getProvinceCityDistrict({
        "parentId": provinceId
      }).then(function (res) {
        _this12.city = res.data.data;
      });
    },
    getDistrictEdit: function getDistrictEdit(cityId) {
      var _this13 = this;

      this.forms.cityId = cityId;
      Service.getProvinceCityDistrict({
        "parentId": cityId
      }).then(function (res) {
        _this13.district = res.data.data;
      });
    },
    changeDistrictEdit: function changeDistrictEdit(districtId) {
      this.forms.districtId = districtId;
    },
    edit: function edit(rows) {
      // 编辑-加载
      this.FormVisible = true;
      this.forms = rows;
      this.devicePic = rows.devicePic;
      this.logoPic = rows.logoPic;
      this.getCityEdit(rows.provinceId);
      this.getDistrictEdit(rows.cityId);
    },
    updateScreenSetting: function updateScreenSetting() {
      var _this14 = this;

      // 修改
      this.forms.devicePic = this.devicePic;
      this.forms.logoPic = this.logoPic;
      this.$refs['updateForm'].validate(function (valid) {
        if (valid) {
          if (_this14.forms.dataType == 1) {
            if (_this14.forms.streetId === '') {
              _this14.$message({
                message: '请将街道信息填充完整',
                type: 'error'
              });
            }
          } else {
            _this14.forms.streetId = 0; // 后台转换空字符串报错
          }

          _this14.FormVisible = false;
          screenSettingService.update(_this14.forms).then(function (res) {
            if (res.status == 200) {
              _this14.$message({
                message: '修改成功',
                type: 'success'
              });

              _this14.getList();
            }
          });
        }
      });
    },
    handleActivityRemove: function handleActivityRemove(item) {
      var _this15 = this;

      var index = this.activityPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this15.activityPic.splice(index, 1);
      }).catch(function () {
        _this15.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    uploadActivityImg: function uploadActivityImg(response, file, fileList) {
      fileList = [];

      if (this.activityPic.indexOf(response.key) == -1) {
        this.activityPic.push(response.key);
      }

      this.$refs.uploadActive.clearFiles();
    },
    updateScreenTemplateData: function updateScreenTemplateData() {
      var _this16 = this;

      // 修改临时数据
      this.$refs['updateTemplateForm'].validate(function (valid) {
        if (valid) {
          _this16.templateForm.activityPic = ''; // 清空

          if (_this16.activityPic.length != 0) {
            for (var i = 0; i < _this16.activityPic.length; i++) {
              if (i == _this16.activityPic.length - 1) {
                _this16.templateForm.activityPic += _this16.activityPic[i];
              } else {
                _this16.templateForm.activityPic += _this16.activityPic[i] + ",";
              }
            }
          }

          _this16.FormTemplateVisible = false;
          screenSettingService.addAndUpdate(_this16.templateForm).then(function (res) {
            if (res.status == 200) {
              _this16.$message({
                message: '修改成功',
                type: 'success'
              });

              _this16.getList();
            }
          });
        }
      });
    },
    UpNumber: function UpNumber(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, '');
    }
  }
};