//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import noel from '@/components/street/noeladdstreet';
import addrselect from '@/components/street/addrselect';
import { parseTime } from "@/utils";
export default {
  name: 'ComplexTable',
  components: {
    Pagination: Pagination,
    addrselect: addrselect,
    noel: noel
  },
  directives: {
    waves: waves
  },
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: "",
        community: "",
        region: "",
        streetId: '',
        communityId: '',
        regionId: '',
        currentPage: 1,
        numOfPage: 20,
        sort: 0,
        // 升序，降序
        count: "50" // 导出多少条,默认100

      },
      streetId: '',
      communityId: '',
      regionId: '',
      options: [{
        label: "降序",
        value: 0
      }, {
        label: "升序",
        value: 1
      }],
      queryFlag: 0 // 查询标识

    };
  },
  watch: {
    time: function time(val) {
      console.log(val);

      if (val == null) {
        this.listQuery.startTime = "";
        this.listQuery.endTime = "";
      } else {
        this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      }
    }
  },
  created: function created() {
    this.listLoading = false;
  },
  methods: {
    getList1: function getList1() {
      var _this = this;

      this.queryFlag = 1;

      if (this.listQuery.street == '' || this.listQuery.street == null) {
        this.$message.error('请选择街道');
        return;
      }

      this.listLoading = true;

      if (this.listQuery.count == '' && this.queryFlag == 1) {
        this.listQuery.count = 1000; // 默认最大1000
      }

      if (this.listQuery.count > 1000 && this.queryFlag == 1) {
        this.listQuery.count = 1000; // 默认最大1000
      }

      Service.cumulativeQuery(this.listQuery).then(function (response) {
        _this.list = response.data.data.list;
        _this.total = response.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    getList2: function getList2() {
      var _this2 = this;

      this.queryFlag = 2;

      if (this.listQuery.street == '' || this.listQuery.street == null) {
        this.$message.error('请选择街道');
        return;
      }

      this.listLoading = true;
      Service.surplusQuery(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.totalRows;
        _this2.listLoading = false;
      });
    },
    noelchange: function noelchange(data) {
      // 查询条件
      //console.log(data);
      //this.listQuery.streetId = data.streetId
      //this.listQuery.communityId = data.communityId
      //this.listQuery.regionId = data.regionId
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    exportExcel1: function exportExcel1() {
      // 数据统计-积分查询-累计积分排名导出
      location.href = '/operation/coin/cumulative/excel?' + qs.stringify(this.listQuery);
    },
    exportExcel2: function exportExcel2() {
      // 数据统计-积分查询-剩余积分排名导出
      location.href = '/operation/coin/surplus/excel?' + qs.stringify(this.listQuery);
    }
  }
};