import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 10
      },
      total: 0,
      tableKey: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      FormVisible: false,
      form: {
        name: '',
        password: '',
        accountName: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        streetId: '',
        permissionStatus: '1',
        status: '1',
        permissionCustomStatus: '1',
        customData: ''
      },
      forms: {
        name: '',
        password: '',
        accountName: '',
        provinceId: '',
        cityId: '',
        districtId: '',
        streetId: '',
        permissionStatus: '',
        status: '',
        permissionCustomStatus: '',
        customData: ''
      },
      formLabelWidth: '120px',
      listLoading: true,
      list: [],
      stree: '',
      puts: {
        pass: '',
        id: '',
        Jid: '',
        status: status
      },
      po: '',
      Xiupass: {
        pass: '',
        id: '',
        Jid: '',
        status: ''
      },
      province: '',
      // 省
      city: '',
      // 市
      district: '',
      // 区
      Visible: false,
      screenCustoms: [],
      // 定制类
      customDatas: [] // checkbox赋的值,修改用

    };
  },
  created: function created() {
    this.getList(), this.getJiedao();
    this.getProvince();
  },
  watch: {
    /*"Xiupass.status"(e){
      console.log(e)
    },
    "form.status"(e){
      console.log(e)
    } ,
    "form.permissionStatus"(e){
      console.log(e)
    } ,
    "form.permissionCustomStatus"(e){
      console.log(e)
    } */
    customDatas: function customDatas(e) {
      //this.listQuery.roleIds = e.join(",")
      //this.customDatas = e;// 选中的值
      this.form.customData = e.join(",");
      this.forms.customData = e.join(",");
    }
  },
  methods: {
    Begin: function Begin(Jid) {
      // 街道列表改变赋值
      console.log(Jid);
      this.form.streetId = Jid;
    },
    getList: function getList() {
      var _this = this;

      Service.getArtlist(this.listQuery).then(function (res) {
        console.log(res);
        _this.list = res.data.list; //this.total = res.data.length

        _this.total = res.data.totalRows;
        _this.listLoading = false;
      });
    },
    getProvince: function getProvince() {
      var _this2 = this;

      Service.getProvinceCityDistrict({
        "parentId": 100000
      }).then(function (res) {
        _this2.province = res.data.data;
      });
    },
    getCity: function getCity(provinceId) {
      var _this3 = this;

      this.form.provinceId = provinceId;
      Service.getProvinceCityDistrict({
        "parentId": provinceId
      }).then(function (res) {
        _this3.city = res.data.data;
      });
    },
    getDistrict: function getDistrict(cityId) {
      var _this4 = this;

      this.form.cityId = cityId;
      Service.getProvinceCityDistrict({
        "parentId": cityId
      }).then(function (res) {
        _this4.district = res.data.data;
      });
    },
    changeDistrict: function changeDistrict(districtId) {
      this.form.districtId = districtId;
    },
    getJiedao: function getJiedao() {
      var _this5 = this;

      Service.getArtjied().then(function (res) {
        _this5.stree = res.data;
      });
    },
    Street: function Street(Jid) {
      var _this6 = this;

      // 查询页面修改
      this.puts.Jid = Jid;
      Service.updateJied(this.puts).then(function (res) {
        if (res.status == 200) {
          _this6.$message({
            message: '修改成功',
            type: 'success'
          });

          _this6.FormVisible = false;

          _this6.getList();
        }
      });
    },
    Str: function Str(id, pass, status) {
      this.puts.id = id;
      this.puts.pass = pass;
      this.puts.status = status;
    },
    del: function del(rows) {
      var _this7 = this;

      Service.delJied(rows.id).then(function (res) {
        if (res.status == 200) {
          _this7.$message({
            message: '成功删除',
            type: 'success'
          });

          _this7.getList();
        }
      });
    },
    Tzhang: function Tzhang() {
      var _this8 = this;

      //添加数据加载
      this.dialogFormVisible = true;
      var Num = '';

      for (var i = 0; i < 5; i++) {
        Num += Math.floor(Math.random() * 10);
      }

      this.form.password = Num;
      this.form.name = '';
      this.form.accountName = '';
      this.form.provinceId = '';
      this.form.cityId = '';
      this.form.districtId = '';
      this.form.streetId = '';
      this.form.permissionStatus = '';
      this.form.status = '';
      this.form.permissionCustomStatus = '';
      this.form.customData = '';
      this.customDatas = []; // 加载定制类

      Service.getScreenCustom().then(function (res) {
        console.log(res.data.data);
        _this8.screenCustoms = res.data.data;
      });
    },
    Addz: function Addz() {
      var _this9 = this;

      // console.log(this.form);
      if (this.form.name == '' || this.form.password == '' || this.form.accountName == '' || this.form.provinceId == '' || this.form.cityId == '' || this.form.districtId == '' || this.form.streetId == '' || this.form.permissionStatus === '' || this.form.status === '' || this.form.permissionCustomStatus === '') {
        this.$message({
          message: '请将信息填充完整',
          type: 'error'
        });
      } else {
        this.dialogFormVisible = false; // this.form.status?this.form.status = 1:this.form.status = 0
        // this.form.permissionStatus?this.form.permissionStatus = 1:this.form.permissionStatus = 0
        // this.form.permissionCustomStatus?this.form.permissionCustomStatus = 1:this.form.permissionCustomStatus = 0

        Service.TianJied(this.form).then(function (res) {
          if (res.status == 200) {
            _this9.$message({
              message: '添加成功',
              type: 'success'
            });

            _this9.getList();
          }
        });
      }
    },
    getProvinceEdit: function getProvinceEdit() {
      var _this10 = this;

      Service.getProvinceCityDistrict({
        "parentId": 100000
      }).then(function (res) {
        _this10.province = res.data.data;
      });
    },
    getCityEdit: function getCityEdit(provinceId) {
      var _this11 = this;

      this.forms.provinceId = provinceId;
      Service.getProvinceCityDistrict({
        "parentId": provinceId
      }).then(function (res) {
        _this11.city = res.data.data;
      });
    },
    getDistrictEdit: function getDistrictEdit(cityId) {
      var _this12 = this;

      this.forms.cityId = cityId;
      Service.getProvinceCityDistrict({
        "parentId": cityId
      }).then(function (res) {
        _this12.district = res.data.data;
      });
    },
    changeDistrictEdit: function changeDistrictEdit(districtId) {
      this.forms.districtId = districtId;
    },
    edit: function edit(rows) {
      var _this13 = this;

      this.customDatas = [];
      /*this.FormVisible = true
      this.Xiupass.id = rows.id
      this.Xiupass.Jid = rows.streetId
      this.forms.pass = rows.password
      // this.Xiupass.status = rows.status
      if(!rows.status){
        this.Xiupass.status = false
      }else{
        this.Xiupass.status = true
      }
      console.log(this.Xiupass.status)*/

      this.FormVisible = true;
      this.forms = rows;
      this.getCityEdit(rows.provinceId);
      this.getDistrictEdit(rows.cityId);
      Service.getScreenCustom().then(function (res) {
        // console.log(res.data.data);
        _this13.screenCustoms = res.data.data;
      }); //console.log(rows.statsPlatScreenCustomDatas);

      rows.statsPlatScreenCustomDatas.forEach(function (item, index) {
        _this13.customDatas.push(item.cid); // 加载
        //console.log(item.cid);

      });
    },
    Streets: function Streets() {
      var _this14 = this;

      // 修改
      console.log(this.forms.password == '');

      if (this.forms.name == '' || this.forms.password == '' || this.forms.accountName == '' || this.forms.provinceId == '' || this.forms.cityId == '' || this.forms.districtId == '' || this.forms.streetId == '' || this.forms.permissionStatus === '' || this.forms.status === '' || this.forms.permissionCustomStatus === '') {
        this.$message({
          message: '请将信息填充完整',
          type: 'error'
        });
      } else {
        Service.updateJied(this.forms).then(function (res) {
          if (res.status == 200) {
            _this14.$message({
              message: '修改成功',
              type: 'success'
            });

            _this14.FormVisible = false;

            _this14.getList();
          }
        });
      }
    }
  }
};