var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group"),
      _vm._v(" "),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px",
            "margin-bottom": "32px",
          },
        },
        [
          _c(
            "el-col",
            {
              attrs: {
                xs: { span: 24 },
                sm: { span: 24 },
                md: { span: 24 },
                lg: { span: 12 },
                xl: { span: 12 },
              },
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "审核",
                  "inactive-text": "投对数据云平台小程序",
                },
                on: {
                  change: function ($event) {
                    return _vm.swicth("wx64192eca6b805a56", _vm.touduiData)
                  },
                },
                model: {
                  value: _vm.touduiData,
                  callback: function ($$v) {
                    _vm.touduiData = $$v
                  },
                  expression: "touduiData",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            {
              attrs: {
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 12 },
                lg: { span: 6 },
                xl: { span: 6 },
              },
            },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "审核",
                  "inactive-text": "投对管理小程序",
                },
                on: {
                  change: function ($event) {
                    return _vm.swicth("wx260bdf8d96d07370", _vm.touduiGuanli)
                  },
                },
                model: {
                  value: _vm.touduiGuanli,
                  callback: function ($$v) {
                    _vm.touduiGuanli = $$v
                  },
                  expression: "touduiGuanli",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.lineChartData
        ? _c(
            "el-row",
            {
              staticStyle: {
                background: "#fff",
                padding: "16px 16px 0",
                "margin-bottom": "32px",
              },
            },
            [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }