var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        { staticStyle: { width: "300px" }, attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "1-是否开启泰达投对首页新闻", prop: "k1" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.k1,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "k1", $$v)
                    },
                    expression: "form.k1",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "2-是否开启督导员小程序上报位置", prop: "k2" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.k2,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "k2", $$v)
                    },
                    expression: "form.k2",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "2.1-督导员小程序上报位置间隔时间", prop: "k3" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { autocomplete: "off" },
                model: {
                  value: _vm.form.k3,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "k3", $$v)
                  },
                  expression: "form.k3",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveHistoryData } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }