//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import waves from '@/directive/waves'; // Waves directive

import { parseTime } from '@/utils';
export default {
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      images: '',
      datas: '',
      total: 0,
      listQuery: {
        startTime: '',
        endTime: '',
        currentPage: 1,
        numOfPage: 20,
        locationId: ''
      },
      listLoading: false,
      lists: this.$route.query.data,
      list: [],
      time: '',
      dialog: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    console.log(this.lists);
    this.listQuery.locationId = this.lists;
    this.getList();
  },
  methods: {
    imgs: function imgs(e, index) {
      var _this = this;

      this.dialog = true;
      setTimeout(function () {
        _this.images = e;

        _this.$refs.carousel.setActiveItem(index);
      }, 200);
      console.log(e);
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      Service.gettaizhanglist(this.listQuery).then(function (res) {
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
        _this2.datas = res.data.data.location;
      });
    }
  }
};