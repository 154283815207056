import request from '@/utils/request';
/**
 * 回收员管理-督导员工作轨迹
 */

var Service = {
  // 加载页面
  getList: function getList(params) {
    return request({
      url: '/tracks/getListPage',
      method: 'get',
      params: params
    });
  },
  // 通过街道和日期查询最新的督导员轨迹
  getTracks: function getTracks(params) {
    return request({
      url: '/tracks/getTracks?street=' + params.street + "&nowTime=" + params.nowTime,
      method: 'get'
    });
  },
  // 督导员个人轨迹
  getSelfTracks: function getSelfTracks(params) {
    return request({
      url: '/tracks/getRecyclerTracks?weixinId=' + params.weixinId + "&nowTime=" + params.nowTime,
      method: 'get'
    });
  }
};
export default Service;