import _objectSpread from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import SidebarItem from "./SidebarItem";
export default {
  components: {
    SidebarItem: SidebarItem
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routers', 'sidebar'])), {}, {
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};