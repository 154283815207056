import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  name: 'Lingtoudi',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _listQuery;

    return {
      mapVisible: false,
      editVisible: false,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      Options: [{
        name: '正常用户',
        value: 0
      }, {
        name: '非法用户',
        value: -1
      }, {
        name: '线下用户',
        value: 2
      }],
      form: {
        'address': '',
        'city': '滨海新区',
        'lat': 0,
        'locationId': 0,
        'lon': 0,
        'name': '',
        'org': '',
        'phone': 0,
        'province': '天津市',
        'userId': 0
      },
      listQuery: (_listQuery = {
        title: '',
        // 搜索 搜索用户名字
        key: '',
        // 搜索名字 v2
        role: 0,
        // :0=货主,1=居民版,-1=所有
        type: 0,
        // 0:正常用户;-1:非法用户2：无微信用户
        currentPage: 1,
        numOfPage: 20
      }, _defineProperty(_listQuery, "role", 1), _defineProperty(_listQuery, "status", 1), _listQuery)
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 改变用户限制
    changeUserLocation: function changeUserLocation(row) {
      var self = this;
      var posLimited = row.posLimited === 0 ? 1 : 0; // 开关取反

      Service.updateUserLocation({
        posLimited: posLimited,
        userId: row.id
      }).then(function (res) {
        self.getList();
      });
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this.initMap(row.lon, row.lat);
      }, 500);
    },
    editUser: function editUser(row) {
      this.editVisible = true;
      this.form = row;
      console.log(row);
    },
    saveAddr: function saveAddr() {
      var _this2 = this;

      this.form.locationId = this.form.id;
      this.form.userId = this.form.ownerId;
      Service.updateLocation(this.form).then(function (res) {
        _this2.editVisible = false;

        _this2.$message({
          message: '更新成功',
          type: 'success'
        });

        _this2.getList();
      });
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.lingtoudiList(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this3.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作成功',
        type: 'success'
      });
      row.status = status;
    },
    handleDelete: function handleDelete(row) {
      this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success',
        duration: 2000
      });
      var index = this.list.indexOf(row);
      this.list.splice(index, 1);
    },
    exportExcel: function exportExcel() {
      location.href = '/operation/owner/exportOwner?' + qs.stringify(this.listQuery);
    },
    exportLingtoudi: function exportLingtoudi() {
      location.href = '/operation/owner/exportZeroKitchenOwner';
    }
  }
};