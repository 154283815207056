"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _defineProperty2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

require("@/components/Input/Inputnum.js");

var _qs = _interopRequireDefault(require("qs"));

var _service = _interopRequireDefault(require("@/api/service"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _utils = require("@/utils");

var _noeladdstreet = _interopRequireDefault(require("@/components/street/noeladdstreet"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
// Secondary package based on el-pagination
var _default = {
  name: 'Order',
  components: {
    Pagination: _Pagination.default,
    noel: _noeladdstreet.default
  },
  directives: {
    waves: _waves.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, (0, _defineProperty2.default)(_statusMap, "10", 'primary'), (0, _defineProperty2.default)(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '-1': '删除订单',
        '0': '取消订单',
        '1': '回收中',
        '2': '已完成'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      lists: {
        printNum: '',
        sn: ""
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: (0, _defineProperty2.default)({
        street: '',
        community: '',
        region: '',
        startTime: (0, _utils.parseTime)(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: (0, _utils.parseTime)(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        // DELETE_ENUM(-1,"删除订单"),
        // CANCEL_ENUM(0,"取消订单"),
        // STATE_ENUM(1,"回收中"),
        // END_ENUM(2,"已完成");
        status: '',
        reserveType: 0,
        // statisticalMethod:1,
        orderType: 0,
        // streetMethod:1,
        type: ""
      }, "status", ""),
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '已完成',
        value: 2
      }, {
        name: '回收中',
        value: 1
      } //, {
      // 	name: '取消订单',
      // 	value: 0
      // },
      , {
        name: '已删除',
        value: -1
      }],
      Option: [{
        name: '全部',
        value: 0
      }, {
        name: '预约订单',
        value: 1
      }, {
        name: '自助打印订单',
        value: 2
      }, {
        name: '自助贴纸订单',
        value: 3
      }, {
        name: '行动站订单-称重',
        value: 4
      }, {
        name: '行动站订单-未称重',
        value: 5
      }, {
        name: '行动站标牌',
        value: 6
      }, {
        name: '快速订单',
        value: 7
      }],
      methods: [{
        name: "按下单日期统计",
        value: 1
      }, {
        name: "按回收日期统计",
        value: 2
      }],
      typesOptions: [{
        typeInfo: '全部',
        type: 0
      }, {
        // 厨余类型字典
        typeInfo: '可回收',
        type: 2
      }, {
        typeInfo: '低值可回收',
        type: 4
      }, {
        typeInfo: '有害垃圾',
        type: 5
      }],
      streetMethodOptions: [{
        typeInfo: '按用户街道查找',
        type: 1
      }, {
        // 厨余类型字典
        typeInfo: '按督导员街道查找',
        type: 2
      }],
      alertlist: [],
      goodsType: []
    };
  },
  watch: {
    time: function time(val) {
      if (val == null || val == '') {
        this.listQuery.startTime = '';
        this.listQuery.endTime = '';
      } else {
        this.listQuery.startTime = (0, _utils.parseTime)(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = (0, _utils.parseTime)(val[1], '{y}-{m}-{d}');
      }
    }
  },
  mounted: function mounted() {
    // 获取回收物类型
    // Service.getGoodsType().then((res)=>{
    // 	this.goodsType = res.data.data;
    // 	console.log(res.data.data);
    // })
    if (this.$route.params.data) {
      this.listQuery.key = this.$route.params.data.location.name;
      this.getList();
    } else {
      this.getList();
    }
  },
  methods: {
    noelchange: function noelchange(data) {
      console.log(data);
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    // exportExcel() {// 回收订单管理-个人回收订单导出
    // 	Service.exportOrderList(this.listQuery).then((res) => {
    // 		const content = res.data
    // 		const blob = new Blob([content])
    // 		const fileName = '订单记录.xls'
    // 		if ('download' in document.createElement('a')) {
    // 			// 非IE下载
    // 			const elink = document.createElement('a')
    // 			elink.download = fileName
    // 			elink.style.display = 'none'
    // 			elink.href = URL.createObjectURL(blob)
    // 			document.body.appendChild(elink)
    // 			elink.click()
    // 			URL.revokeObjectURL(elink.href) // 释放URL 对象
    // 			document.body.removeChild(elink)
    // 		} else {
    // 			// IE10+下载
    // 			navigator.msSaveBlob(blob, fileName)
    // 		}
    // 	})
    // },
    // initMap(lon, lat) {
    // 	var map = new AMap.Map('amapContainer', {
    // 		resizeEnable: true,
    // 		center: [lon, lat],
    // 		zoom: 9
    // 	})
    // 	var marker = new AMap.Marker({
    // 		icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
    // 		position: [lon, lat],
    // 		offset: new AMap.Pixel(-13, -30)
    // 	})
    // 	marker.setMap(map)
    // 	map.setFitView()
    // },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      _service.default.startOrderListNew(this.listQuery).then(function (response) {
        console.log(response.data);
        _this.list = response.data.data.list;
        _this.total = response.data.data.totalRows;

        _this.list.forEach(function (item, index) {
          if (item.orderInfo) {
            item.orderInfo.forEach(function (item2, index2) {
              if (item2.userPic) {
                item2.userPic = item2.userPic.replace("http://pic.touduila.com/", "");
              }
            });
          }
        });

        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },

    /*chongpai(row) {
    	Service.orderRecover(row.id).then(res => {
    		if (res.data.statusCode) {
    			this.$notify({
    				title: '成功',
    				message: '重派成功',
    				type: 'success',
    				duration: 2000
    			})
    				this.getList()
    		}
    	})
    },*/
    deleteOrder: function deleteOrder(row) {
      var _this2 = this;

      this.$confirm('此操作将删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _service.default.orderDelNew(row.id).then(function (res) {
          _this2.$notify({
            title: '',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });

          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    backOrder: function backOrder(row) {
      var _this3 = this;

      this.$confirm('此操作驳回该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _service.default.orderBackNew(row.id).then(function (res) {
          console.log(res.data);

          _this3.$notify({
            title: '',
            message: res.data.data,
            type: 'success',
            duration: 2000
          });

          _this3.getList();
        });
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消驳回'
        });
      });
    }
  }
};
exports.default = _default;