import request from '@/utils/request';
var Service = {
  getzhanghao: function getzhanghao(params) {
    // 查询用户列表
    return request({
      url: '/fht/api/fhtSys/user',
      method: 'get',
      params: params
    });
  },
  editState: function editState(params) {
    // 正常、拒绝
    return request({
      url: '/fht/api/fhtSys/updateUserState',
      method: 'post',
      params: params
    });
  },
  addRoles: function addRoles(params) {
    return request({
      url: '/fht/api/fhtSys/addUser',
      method: 'post',
      params: params
    });
  },
  yanzheng: function yanzheng(params) {
    return request({
      url: '/fht/api/fhtSys/validateUser?userName=' + params,
      method: 'post'
    });
  },
  xiugai: function xiugai(params) {
    // 修改账号
    return request({
      url: '/fht/api/fhtSys/modifyUser',
      method: 'post',
      params: params
    });
  },
  getList: function getList(params) {
    return request({
      url: '/fht/api/fhtSys/role?roleName=' + params,
      method: 'post'
    });
  },
  // 角色查询列表
  getDictData: function getDictData() {
    return request({
      url: '/fht/api/fhtSys/getDictData',
      method: 'get'
    });
  },
  getUserRoleInfo: function getUserRoleInfo() {
    return request({
      url: '/fht/api/fhtSys/getUserRoleInfo',
      method: 'get'
    });
  },
  getAuthoritys: function getAuthoritys(params) {
    // 权限树列表
    return request({
      url: '/fht/api/fhtAccessAuthorityController/getAuthoritys',
      method: 'get',
      params: params
    });
  },
  saveRole: function saveRole(params) {
    return request({
      url: '/fht/api/fhtAccessAuthorityController/insertRole',
      method: 'post',
      params: params
    });
  },
  updateRole: function updateRole(params) {
    return request({
      url: '/fht/api/fhtAccessAuthorityController/updateRole',
      method: 'post',
      params: params
    });
  },
  getAccessRoleAuthorityByRoleId: function getAccessRoleAuthorityByRoleId(params) {
    return request({
      url: '/fht/api/fhtAccessAuthorityController/getAccessRoleAuthorityByRoleId',
      method: 'get',
      params: params
    });
  },
  logFhtInlists: function logFhtInlists(params) {
    return request({
      url: '/fht/api/fhtSys/loginHistory',
      method: 'get',
      params: params
    });
  }
};
export default Service;