var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app-container", attrs: { div: "" } },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.listQuery.checkStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "checkStatus", $$v)
                    },
                    expression: "listQuery.checkStatus",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.hasPerm("checkpic:select")
                ? _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("\n\t\t\t\t\t查询\n\t\t\t\t")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图像" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src:
                              "http://pic.touduila.com/" + scope.row.qiniuPic,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "识别结果" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(row.bussinessData) +
                              " -" +
                              _vm._s((row.sim * 100).toFixed(2)) +
                              "%\n\t\t\t\t\t\t"
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "审核状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        !row.checkStatus
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                              },
                              [_vm._v("待审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        row.checkStatus
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "#67C23A" },
                                attrs: { type: "text" },
                              },
                              [_vm._v("已审核")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.hasPerm("checkpic:check") && row.checkStatus != 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addAiPicCheckView(row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("checkpic:delete")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAiPicCheck(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.currentPage,
              limit: _vm.listQuery.numOfPage,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "numOfPage", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "图像审核", visible: _vm.dialogCheckFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCheckFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c("el-form-item", { attrs: { label: "图像" } }, [
                _c("img", {
                  staticStyle: { width: "200px", height: "200px" },
                  attrs: {
                    src: "http://pic.touduila.com/" + _vm.form.qiniuPic,
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "数据集" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择数据集" },
                      on: { change: _vm.datasetChange },
                      model: {
                        value: _vm.form.datasetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "datasetId", $$v)
                        },
                        expression: "form.datasetId",
                      },
                    },
                    _vm._l(_vm.datasetList, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.label, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签集" } },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "span",
                    {
                      key: item.id,
                      staticStyle: { "padding-left": "10px" },
                      attrs: { type: item.label },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.tagChange(item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(item.label))]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogCheckFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.check } },
                [_vm._v("完 成")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }