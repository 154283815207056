import request from '@/utils/request';
var dizhiService = {
  getHomeData: function getHomeData() {
    return request({
      url: '/operation/stats/getStatisticAllData',
      method: 'get'
    });
  },
  getCommunityData: function getCommunityData(params) {
    return request({
      url: '/operation/stats/getStatisticCommunity',
      method: 'get',
      params: params
    });
  },
  getRegionData: function getRegionData(params) {
    return request({
      url: '/operation/stats/getStatisticRegion',
      method: 'get',
      params: params
    });
  },
  getStreetData: function getStreetData(params) {
    return request({
      url: '/operation/stats/getStatisticStreet',
      method: 'get',
      params: params
    });
  },
  getJiedaoList: function getJiedaoList(params) {
    return request({
      url: '/operation/supervisorRecord/getKitchenRegionTree',
      method: 'get',
      params: params
    });
  }
};
export default dizhiService;