//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      id: this.$route.params.datas.id,
      list: [],
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 10
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      Service.logInlists(this.listQuery).then(function (res) {
        console.log(res);
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
      });
    }
  }
};