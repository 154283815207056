import request from '@/utils/request'; // 基础设置-积分调整api

var Service = {
  getList: function getList(params) {
    // 查询任务
    return request({
      url: '/operation/coin/adjust/list',
      method: 'post',
      params: params
    });
  },
  adjustStreetAdd: function adjustStreetAdd(params) {
    // 添加任务
    return request({
      url: '/operation/coin/street/add',
      method: 'post',
      params: params
    });
  },
  adjustStreetEdit: function adjustStreetEdit(params) {
    // 编辑任务
    return request({
      url: '/operation/coin/street/edit',
      method: 'post',
      params: params
    });
  },
  adjustStreetDelete: function adjustStreetDelete(params) {
    // 删除任务
    return request({
      url: '/operation/coin/street/delete?id=' + params,
      method: 'post'
    });
  },
  userSearch: function userSearch(params) {
    // 单用户搜索
    return request({
      url: '/operation/coin/user/search/?key=' + params,
      method: 'get'
    });
  },
  adjustOneAdd: function adjustOneAdd(params) {
    // 单用户调整
    return request({
      url: '/operation/coin/one/add',
      method: 'post',
      params: params
    });
  },
  coinAdjustDetail: function coinAdjustDetail(params) {
    // 积分发放明细
    return request({
      url: "/operation/coin/adjustdetial/list",
      method: 'post',
      params: params
    });
  }
};
export default Service;