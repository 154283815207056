import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import waves from '@/directive/waves'; // Waves directive

import dizhiService from '@/api/dizhi';
import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import Service from '@/api/service';
import ServiceProject from '@/api/project'; // 项目api

export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    var _listQuery;

    return {
      exChangelistsd: {
        currentPage: 1,
        numOfPage: 20,
        shopId: '',
        cdk: "" // 兑换码

      },
      exChangetotals: 0,
      exChangelist: [],
      exChangeform: {
        cdks: '',
        time: '',
        shopId: ''
      },
      Exchangedata: '',
      exChangedialogVisible: false,
      Exchangestatus: '',
      types: [{
        info: '全部',
        id: 1
      }, {
        info: '自助',
        id: 2
      }, {
        info: '现场督导员',
        id: 3
      }],
      value1: false,
      offs: 1,
      cardlist: [],
      kucuntotle: 0,
      cardNum: '',
      dialogVisibles: false,
      streetList: [],
      searchStreet: null,
      street: '',
      postStatuslist: [{
        type: 0,
        info: '已上架'
      }, {
        type: 1,
        info: '已下架'
      }],
      total: '',
      integral_price: '',
      shop_name: '',
      form: {
        shop_name: '',
        integral_price: '',
        img: [],
        note: '',
        type: '实体商品',
        cdks: '',
        state: '',
        currentPage: 1,
        numOfPage: 20,
        total: '',
        street: '',
        shopDesc: '',
        projectId: null,
        // 默认为null，不要改动
        pay_type: "0" //使用厨余积分支付 0:否，1：是

      },
      forms: {
        shop_name: '',
        integral_price: '',
        img: [],
        note: '',
        state: '',
        currentPage: 1,
        numOfPage: 20,
        id: '',
        street: '',
        total: '',
        type: '',
        shopDesc: '',
        projectName: '',
        pay_type: "" //使用厨余积分支付 0:否，1：是

      },
      totals: 0,
      state: [],
      rule: {
        type: [{
          required: true,
          message: "商品类型是必填项"
        }],
        img: [{
          required: true,
          message: "图片是必填项"
        }],
        state: [{
          required: true,
          message: "发布状态是必填项"
        }],
        shop_name: [{
          required: true,
          message: "商品名称是必填项"
        }],
        integral_price: [{
          required: true,
          message: "积分价格是必填项"
        }],
        total: [{
          required: true,
          message: "库存数量是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必填项"
        }]
        /*projectId:[{
        	required: true,
        	message: "项目是必填项"
        }]*/

      },
      exChangerule: {
        cdks: [{
          required: true,
          message: "兑换码是必填项"
        }],
        time: [{
          required: true,
          message: "兑换码有效期是必填项"
        }]
      },
      rules: {
        shopDesc: [{
          required: true,
          message: "商品说明是必填项"
        }],
        note: [{
          required: true,
          message: "备注是必填项"
        }],
        cdks: [{
          required: true,
          message: "卡卷密码是必填项"
        }],
        type: [{
          required: true,
          message: "商品类型是必填项"
        }],
        img: [{
          required: true,
          message: "图片是必填项"
        }],
        state: [{
          required: true,
          message: "发布状态是必填项"
        }],
        shop_name: [{
          required: true,
          message: "商品名称是必填项"
        }],
        integral_price: [{
          required: true,
          message: "积分价格是必填项"
        }],
        total: [{
          required: true,
          message: "库存数量是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必填项"
        }]
      },
      states: [{
        name: '已上架',
        value: '已上架'
      }, {
        name: '已下架',
        value: '已下架'
      }],
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '已上架',
        value: '已上架'
      }, {
        name: '已下架',
        value: '已下架'
      }],
      statusList: [
      /*{
      name: '虚拟商品',
      value: '虚拟商品'
      },*/
      {
        name: '实体商品',
        value: '实体商品'
      }],
      statusLists: [{
        name: '全部',
        value: ''
      },
      /*{
      	name: '虚拟商品',
      	value: '虚拟商品'
      },*/
      {
        name: '实体商品',
        value: '实体商品'
      }],
      uploadPic: '',
      dialogForm: false,
      dialogFormVisible: false,
      listLoading: true,
      listQuery: (_listQuery = {
        type: '',
        shop_name: '',
        state: ''
      }, _defineProperty(_listQuery, "type", ''), _defineProperty(_listQuery, "currentPage", 1), _defineProperty(_listQuery, "numOfPage", 20), _defineProperty(_listQuery, "street", ''), _defineProperty(_listQuery, "projectId", ""), _listQuery),
      list: [],
      daochu: [],
      xiugai: '',
      titlestreet: '',
      dialogImageUrl: '',
      dialogVisible: false,
      shopid: '',
      cdktime: '',
      projectList: [],
      // 项目列表
      projectFieldVisible: false,
      // 判断项目列是否显示 add or update 页面使用
      projectFieldVisibleIndex: false,
      // 判断项目列是否显示 主页显示
      cdks: [] // 批量删除兑换码集合

    };
  },
  watch: {
    cardNum: function cardNum(val) {
      if (val) {
        var num = 0;

        for (var i = 0, len = val.length; i < len; i++) {
          if (val[i] == ',' || val[i] == '，') {
            num++;
          }
        }

        this.kucuntotle = num;
      }
    },
    searchStreet: function searchStreet(val) {
      var _this = this;

      this.listQuery.street = val.street; // console.log(this.listQuery.street);

      ServiceProject.getProjectsByStreet(this.listQuery.street).then(function (res) {
        _this.projectList = res.data.list;

        if (_this.projectList.length == 0) {
          _this.projectFieldVisibleIndex = false;
          _this.listQuery.projectId = "";
        } else {
          _this.projectFieldVisibleIndex = true;
        }
      });
      this.getList();
    },
    "form.integral_price": function formIntegral_price(val) {
      if (val) {
        this.form.integral_price = isNaN(parseInt(val)) ? '' : parseInt(val);
      }
    },
    "forms.integral_price": function formsIntegral_price(val) {
      if (val) {
        this.forms.integral_price = isNaN(parseInt(val)) ? '' : parseInt(val);
      }
    },
    "form.total": function formTotal(val) {
      if (val) {
        this.form.total = isNaN(parseInt(val)) ? '' : parseInt(val);
      }
    },
    "forms.total": function formsTotal(val) {
      if (val) {
        this.forms.total = isNaN(parseInt(val)) ? '' : parseInt(val);
      }
    }
  },
  created: function created() {
    this.getList();
    this.getJiedao();
  },
  methods: {
    deletex: function deletex(e) {
      var _this2 = this;

      // 删除单个兑换码
      this.$confirm('删除此项cdk, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.deleteshopoff({
          cdkId: e.id
        }).then(function (res) {
          _this2.getexChangelist();

          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    selectTable: function selectTable(val) {
      var _this3 = this;

      this.cdks = [];
      val.forEach(function (item, index) {
        _this3.cdks.push(item);
      });
      console.log(this.cdks);
    },
    deleteAllx: function deleteAllx() {
      var _this4 = this;

      // 删除多个兑换码
      // console.log(this.cdks.join(","));
      if (this.cdks.length == 0) {
        this.$message({
          type: 'error',
          message: '请选择要删除的兑换码'
        });
        return;
      }

      var deleteFlag = 0;
      this.cdks.forEach(function (item, index) {
        if (item.status == '1') {
          deleteFlag = 1;
        }
      }); //console.log(this.cdks);

      if (deleteFlag == 1) {
        this.$message({
          type: 'error',
          message: '已兑换的兑换码不能删除'
        });
        return;
      }

      var cdkIds = [];
      this.cdks.forEach(function (item, index) {
        cdkIds.push(item.id);
      }); //console.log(cdkIds.join(","));
      //return;

      this.$confirm('删除选择的cdk, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.batchDeleteShopoff({
          ids: cdkIds.join(",")
        }).then(function (res) {
          _this4.getexChangelist();

          _this4.getList();
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    exchangeswitch: function exchangeswitch(e, data) {
      var _this5 = this;

      console.log(e, data);
      Service.shopingonff({
        onOff: e ? 1 : 0,
        shopId: data.id
      }).then(function (res) {
        _this5.getList();

        _this5.$message({
          type: 'success',
          message: '修改成功'
        });
      });
    },
    getexChangelist: function getexChangelist() {
      var _this6 = this;

      // console.log(this.exChangelistsd);
      Service.shoptables(this.exChangelistsd).then(function (res) {
        _this6.exChangelist = res.data.list;
        _this6.exChangetotals = res.data.totalRows;
      });
    },
    Exchange: function Exchange(e, name) {
      // 导入兑换码，查看兑换码
      this.cdks = [];
      this.Exchangestatus = name;
      this.Exchangedata = e;
      this.exChangeform.shopId = e.id;
      this.exChangelistsd.shopId = e.id;

      if (name == 'see') {
        this.getexChangelist();
      }

      this.exChangedialogVisible = true;
    },
    exChangesure: function exChangesure() {
      var _this7 = this;

      if (this.Exchangestatus == 'see') {
        this.exChangedialogVisible = false;
      } else {
        this.$refs['exChangeform'].validate(function (valid) {
          if (valid) {
            Service.allinshop(_this7.exChangeform).then(function (res) {
              _this7.$notify(_defineProperty({
                title: '提示',
                message: res.data,
                duration: 0,
                type: 'success'
              }, "duration", 2000));

              _this7.getList();

              _this7.exChangedialogVisible = false;
            });
          }
        });
      }
    },
    doselect: function doselect(item, _type, evt) {
      var regionId = item.id;
      var type = _type.id;

      if (evt) {
        console.log(regionId, type);
      } else {
        //取消选中
        console.log(regionId, type);
      }
    },
    Cardcoupon: function Cardcoupon(e) {
      var _this8 = this;

      this.offs = 3;
      this.shopid = e.id;
      Service.shoplistxuni(e.id).then(function (res) {
        res.data.list.forEach(function (item, index) {
          _this8.cardlist.push();
        });
        var result = [];
        var obj = {};

        for (var i = 0; i < res.data.list.length; i++) {
          if (!obj[res.data.list[i].cdk]) {
            result.push({
              cdks: res.data.list[i].cdk,
              id: res.data.list[i].id,
              status: true,
              checked: true,
              cdktime: res.data.list[i].time
            });
            obj[res.data.list[i].cdk] = true;
          }
        }

        _this8.cardlist = result;
      });
      this.dialogVisibles = true;
      console.log(e);
    },
    validateform: function validateform(e) {
      var _this9 = this;

      this.cardlist.forEach(function (item, index) {
        if (e == index) {
          item.checked = 2;
          Service.surecdks(_this9.cardlist[e].cdks).then(function (res) {
            item.checked = res.data.data;
          });
        }
      });
      this.cardlist = this.cardlist;
    },
    dangercard: function dangercard(e) {
      var _this10 = this;

      console.log(e, this.cardlist);
      this.$confirm('删除这一项卡卷密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (_this10.cardlist[e].id) {
          Service.deletecdk(_this10.cardlist[e].id).then(function (res) {
            _this10.$message({
              type: 'success',
              message: '已删除'
            });
          });

          _this10.cardlist.splice(e, 1);

          return false;
        }

        _this10.cardlist.splice(e, 1);

        _this10.$message({
          type: 'success',
          message: '已删除'
        });
      }).catch(function () {
        _this10.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    adds: function adds() {
      this.form.this.cardlist.push({
        cdks: '',
        checked: true,
        status: false,
        cdktime: ''
      });
    },
    closeDialog: function closeDialog() {
      var _this11 = this;

      if (this.offs == 2) {
        this.dialogVisibles = false;
        return false;
      }

      if (!this.isRepeat(this.cardlist, 'cdks')) {
        this.$message({
          type: 'error',
          message: '卷码录入重复，请确认后修改'
        });
        return false;
      }

      this.cardlist.some(function (item, index) {
        console.log(item);

        if (item.cdks == '') {
          _this11.$message({
            type: 'error',
            message: '卷码录入错误'
          });

          _this11.dialogVisibles = true;
          return true;
        } else if (!item.checked) {
          _this11.$message({
            type: 'error',
            message: '卷码已被录入'
          });

          return false;
        }
      });
    },
    isRepeat: function isRepeat(arr, key) {
      var obj = {};
      console.log(arr, key);

      for (var i = 0; i < arr.length; i++) {
        if (obj[arr[i][key]]) {
          return false;
        } else {
          obj[arr[i][key]] = arr[i];
        }
      }

      return obj;
    },
    Surecard: function Surecard() {
      var _this12 = this;

      if (this.offs == 2) {
        this.dialogVisibles = false;
        return false;
      }

      if (!this.isRepeat(this.cardlist, 'cdks')) {
        this.$message({
          type: 'error',
          message: '卷码录入重复，请确认后修改'
        });
        return false;
      }

      this.cardlist.some(function (item, index) {
        if (item.cdks == '') {
          _this12.$message({
            type: 'error',
            message: '卷码录入错误'
          });

          return true;
        } else if (!item.checked) {
          _this12.$message({
            type: 'error',
            message: '卷码已被录入'
          });

          return true;
        } else {
          if (_this12.offs == 3) {
            if (!item.cdktime) {
              _this12.$message({
                type: 'error',
                message: '请选择卡卷到期时间'
              });

              return false;
            }

            Service.editshopcdks({
              cdk: item.cdks,
              id: item.id,
              shopId: _this12.shopid,
              time: item.cdktime
            }).then(function (res) {
              _this12.dialogVisibles = false;

              _this12.$message({
                type: 'success',
                message: '卡卷密码录入成功'
              });

              _this12.getList();

              return false;
            });
          } else {
            _this12.dialogVisibles = false;
          }
        }
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.dialogVisible = true;
    },
    getJiedao: function getJiedao() {
      var _this13 = this;

      dizhiService.streetList().then(function (res) {
        _this13.streetList = res.data;
      });
    },
    UpNumber: function UpNumber(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, '');
    },
    getList: function getList() {
      var _this14 = this;

      Service.getshoplist(this.listQuery).then(function (res) {
        // console.log(res)
        _this14.listLoading = false;
        _this14.list = res.data.list;
        _this14.totals = res.data.totalRows;
      });
    },
    handleSelectionChange: function handleSelectionChange(row) {
      var _this15 = this;

      this.daochu = [];
      row.forEach(function (item, index) {
        _this15.daochu.push(item.id);
      });
    },
    handleFilters: function handleFilters() {
      this.getList();
    },
    add: function add() {
      this.uploadPic = '';
      this.dialogFormVisible = true;
      this.xiugai = false;
      this.cardlist = [];
      this.offs = 1;
      this.form.shop_name = "";
      this.form.street = "";
      this.form.integral_price = "";
      this.form.total = "";
      this.form.projectId = null;
      this.form.shopDesc = "";
      this.form.note = "";
      this.form.state = "";
      this.form.pay_type = 0; // 默认不是厨余积分
    },
    edit: function edit(row) {
      var _this16 = this;

      // 打开编辑页面
      // let pic = [];
      // if (typeof row.img === "string") {
      //   pic = row.img.split(",");
      // }
      this.offs = 2;
      this.uploadPic = row.img; // console.log(row)

      this.forms.shop_name = row.shopName;
      this.forms.integral_price = row.integralPrice;
      this.forms.img = row.img;
      this.forms.type = row.type;
      this.forms.note = row.note;
      this.forms.state = row.state;
      this.forms.id = row.id;
      this.forms.total = row.total;
      this.forms.shopDesc = row.shopDesc;
      this.forms.street = row.street;
      this.forms.projectName = row.projectName;
      this.forms.pay_type = row.payType;
      this.dialogForm = true;
      this.xiugai = true;
      Service.shoplistxuni(row.id).then(function (res) {
        res.data.list.forEach(function (item, index) {
          _this16.cardlist.push();
        });
        var result = [];
        var obj = {};

        for (var i = 0; i < res.data.list.length; i++) {
          if (!obj[res.data.list[i].cdk]) {
            result.push({
              cdks: res.data.list[i].cdk,
              id: res.data.list[i].id,
              status: true,
              checked: true
            });
            obj[res.data.list[i].cdk] = true;
          }
        }

        _this16.cardlist = result;
        console.log(_this16.cardlist);
      });
    },
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];

      if (!this.form.img.includes(response.key)) {
        this.form.img = response.key;
        this.forms.img = response.key;
      }

      console.log(this.form.img);
      this.uploadPic = this.form.img;
      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var _this17 = this;

      // var index = this.uploadPic.indexOf(item);
      // if (index > -1) {
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this17.uploadPic = '';
      }).catch(function () {
        _this17.$message({
          type: 'info',
          message: '已取消删除'
        });
      }); // }
    },
    Sure: function Sure(row, num) {
      var _this18 = this;

      var lists = {};
      lists.id = row.id;
      console.log(num);

      if (num) {
        this.$confirm('确认下架商品吗?下架后用户将无法兑换此商品', '下架商品', {
          confirmButtonText: '下架',
          cancelButtonText: '取消'
        }).then(function () {
          lists.state = '已下架';
          Service.xiugaishoplist(lists).then(function (res) {
            console.log(res);

            if (res.data.data == 'ok') {
              _this18.$notify({
                title: '成功',
                message: '下架成功',
                type: 'success',
                duration: 2000
              });

              _this18.getList();
            } else {
              _this18.$message({
                type: 'error',
                message: '下架失败'
              });

              _this18.getList();
            }
          });
        }).catch(function () {
          _this18.$message({
            type: 'info',
            message: '已取消下架'
          });
        });
      } else {
        this.$confirm('确认上架商品吗?上架后用户将可以兑换此商品', '上架商品', {
          confirmButtonText: '上架',
          cancelButtonText: '取消'
        }).then(function () {
          lists.state = '已上架';
          Service.xiugaishoplist(lists).then(function (res) {
            if (res.data.data == 'ok') {
              _this18.$notify({
                title: '成功',
                message: '上架成功',
                type: 'success',
                duration: 2000
              });

              _this18.getList();
            } else {
              _this18.$message({
                type: 'error',
                message: '上架失败'
              });

              _this18.getList();
            }
          });
        }).catch(function () {
          _this18.$message({
            type: 'info',
            message: '已取消上架'
          });
        });
      }
    },
    danger: function danger(row) {
      var _this19 = this;

      var lists = {};
      lists.idList = row.id;
      this.$confirm('确认删除商品吗?删除后将无法恢复', '删除商品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.dangershoplist(lists).then(function (res) {
          console.log(res);

          _this19.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this19.getList();
        });
      }).catch(function () {
        _this19.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    xiugaiSure: function xiugaiSure() {
      var _this20 = this;

      this.forms.total ? this.forms.total = this.forms.total : this.forms.total = 1;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this20.$confirm('确认修改商品信息吗?', '修改商品信息', {
            confirmButtonText: '修改',
            cancelButtonText: '取消'
          }).then(function () {
            Service.xiugaishoplist(_this20.forms).then(function (res) {
              console.log(res);

              if (res.data.data == 'ok') {
                _this20.$notify({
                  title: '成功',
                  message: '商品修改成功',
                  type: 'success',
                  duration: 2000
                });

                _this20.getList();

                _this20.dialogForm = false;
              } else {
                _this20.$message({
                  type: 'error',
                  message: '修改失败'
                });

                _this20.getList();
              }
            });
          }).catch(function () {
            _this20.$message({
              type: 'info',
              message: '已取消修改'
            });
          });
        }
      });
    },
    getSetArr: function getSetArr(arr) {
      return _toConsumableArray(new Set(arr));
    },
    Relieved: function Relieved() {
      var _this21 = this;

      var arr = [];

      if (this.form.type == '虚拟商品') {
        if (this.cardlist.length == 0) {
          this.$message({
            type: 'error',
            message: '请添加卡卷密码'
          });
          return false;
        } else {
          this.cardlist.forEach(function (item, index) {
            arr.push(item.cdks);
          });
        }
      }

      this.form.cdks = this.getSetArr(arr).join(',');
      this.form.total ? this.form.total = this.form.total : this.form.total = 1;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          Service.addshoplist(_this21.form).then(function (res) {
            console.log(res);

            if (res.data.data == 'ok') {
              _this21.$notify({
                title: '成功',
                message: '商品添加成功',
                type: 'success',
                duration: 2000
              });

              _this21.getList();

              _this21.dialogFormVisible = false;
            } else {
              _this21.$message({
                type: 'error',
                message: '添加失败'
              });

              _this21.getList();
            }
          });
        }
      });
    },
    dangerlist: function dangerlist() {
      var _this22 = this;

      var lists = {};
      lists.idList = this.daochu.join(',');
      this.$confirm('确认删除商品吗?删除后将无法恢复', '删除商品', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.dangershoplist(lists).then(function (res) {
          console.log(res);

          _this22.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this22.getList();
        });
      }).catch(function () {
        _this22.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    streetChange: function streetChange(street) {
      var _this23 = this;

      //console.log(street);
      ServiceProject.getProjectsByStreet(street).then(function (res) {
        _this23.projectList = res.data.list; // console.log(this.projectList );

        if (_this23.projectList.length == 0) {
          _this23.projectFieldVisible = false;
        } else {
          _this23.projectFieldVisible = true;
        }
      });
    }
  }
};