import request from '@/utils/request';
var Service = {
  getSource: function getSource(params) {
    return request({
      url: '/operation/stats/source',
      method: 'get',
      params: params
    });
  },
  getStatus: function getStatus(params) {
    return request({
      url: '/operation/stats/postStatus',
      method: 'get',
      params: params
    });
  },
  addAtivity: function addAtivity(data) {
    return request({
      url: '/operation/stats/addAtivity',
      method: 'post',
      data: data
    });
  },
  getList: function getList(params) {
    return request({
      url: '/operation/stats/updateAtivity',
      method: 'get',
      params: params
    });
  },
  updateAtivity: function updateAtivity(params) {
    return request({
      url: '/operation/stats/updateAtivity',
      method: 'post',
      params: params
    });
  },
  delAtivity: function delAtivity(id) {
    return request({
      url: '/operation/stats/deleteAtivity?id=' + id,
      method: 'get'
    });
  }
};
export default Service;