import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _objectSpread from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { Notification } from 'element-ui';
import Service from '@/api/service';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import ErrorLog from '@/components/ErrorLog';
import Screenfull from '@/components/Screenfull';
import SizeSelect from '@/components/SizeSelect';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    ErrorLog: ErrorLog,
    Screenfull: Screenfull,
    SizeSelect: SizeSelect
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'name', 'avatar', 'device'])),
  created: function created() {
    this.goNext();
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    goNext: function goNext() {// setTimeout(() => {
      //   this.getOrder()
      // }, 30000)
    },
    getOrder: function getOrder() {
      var self = this;
      Service.newOrder().then(function (res) {
        if (res.status == 200) {
          if (res.data.length) {
            res.data.forEach(function (item, index) {
              setTimeout(function () {
                self.$notify({
                  title: item.location.name + '单号' + item.id,
                  message: item.location.address,
                  position: 'bottom-right'
                });
              }, 500 * index);
            });
            self.goNext();
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    logout: function logout() {
      this.$store.dispatch('LogOut').then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    }
  }
};