import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addrselect from '@/components/street/noeladdstreet';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import noel from '@/components/street/street';
import actionService from '@/api/actionstation';
import waves from '@/directive/waves'; // waves directive

var map = null;
export default {
  components: {
    addrselect: addrselect,
    Pagination: Pagination,
    noel: noel
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      standbyPics: [],
      // 待机图片
      bannerPics: [],
      // banner图片
      marker: '',
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: '',
        community: '',
        region: '',
        streetId: '',
        siteId: this.$route.query.siteId,
        // 行动站
        gpCode: "" // 标牌编码

      },
      total: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      form: {
        id: "",
        mbId: '',
        deviceName: '',
        streetId: '',
        communityId: '',
        regionId: '',
        siteId: '',
        phone: '',
        homepage: '',
        subpage: '',
        labelBegin: '',
        qrCode: '',
        nameText: '',
        phoneText: '',
        stationNameText: '',
        createTimeText: '',
        actionPrint: {
          // 修改提交封装数据
          labelBegin: '',
          qrCode: '',
          nameText: '',
          phoneText: '',
          stationNameText: '',
          createTimeText: ''
        },
        equipmentPic: '',
        standbyPic: '',
        bannerPic: '',
        rank: '',
        bind: '',
        print: '',
        state: ''
      },
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      stree: '',
      puts: {
        pass: '',
        id: '',
        Jid: ''
      },
      po: '',
      Xiupass: {
        pass: '',
        id: '',
        Jid: ''
      },
      Visible: false,
      mpas: '',
      lng: '',
      lat: '',
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      kong: '',
      uploadPic: [],
      sites: [] //行动站列表

    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    viewGpRecord: function viewGpRecord(rows) {
      this.$router.push({
        path: "RecyclableGpRecord",
        query: {
          gpCode: rows.gpCode,
          siteId: rows.siteId
        }
      });
    },
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      actionService.xdzManageGpList(this.listQuery).then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.rowsCount;
        _this.listLoading = false;
      });
    },
    del: function del(row) {
      var _this2 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        actionService.xdzManageGpDelete(row.gpCode).then(function (res) {
          if (res.data.error) {
            _this2.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.getList();
        });
      }).catch(function () {});
    },
    unbind: function unbind(row) {
      var _this3 = this;

      //console.log(row)
      this.$confirm('是否确认解绑, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        actionService.xdzManageGpUnbound(row.gpCode).then(function (res) {
          if (res.data.error) {
            _this3.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this3.$message({
            type: 'success',
            message: '解绑成功!'
          });

          _this3.getList();
        });
      }).catch(function () {});
    },
    uploadImg: function uploadImg(response, file, fileList) {
      this.uploadPic = [];
      fileList = [];

      if (!this.uploadPic.includes(response.key)) {
        this.uploadPic.push(response.key);
      }

      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var index = this.uploadPic.indexOf(item);

      if (index > -1) {
        this.uploadPic.splice(index, 1);
      }
    },
    addrChange: function addrChange(data) {
      //console.log(data)
      this.addr.street = data.street;
      this.form.street = data.street;
      this.form.streetId = data.streetId;

      if (data.community) {
        this.addr.community = data.community;
        this.form.community = data.community;
        this.form.communityId = data.communityId;
      } else {
        this.addr.community = '';
        this.form.community = "";
        this.form.communityId = '';
      }

      if (data.region) {
        this.addr.region = data.region;
        this.form.region = data.region;
        this.form.regionId = data.regionId;
      } else {
        this.addr.region = '';
        this.form.region = '';
        this.form.regionId = '';
      }
    },
    Begin: function Begin(Jid) {
      //console.log(Jid)
      this.form.streetId = Jid;
    },
    Addz: function Addz() {
      var _this4 = this;

      if (this.standbyPics.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择待机图片',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (this.bannerPics.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择banner图片',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // updateTime: 2021-01-08 15:53:56
          // createTime:
          //console.log(this.form);
          if (_this4.uploadPic.length != 0) {
            _this4.form.equipmentPic = _this4.uploadPic[0];
          } else {
            _this4.form.equipmentPic = "";
          }

          if (_this4.standbyPics.length != 0) {
            _this4.form.standbyPic = _this4.standbyPics.join(";");
          } else {
            _this4.form.standbyPic = "";
          }

          if (_this4.bannerPics.length != 0) {
            _this4.form.bannerPic = _this4.standbyPics[0];
          } else {
            _this4.form.bannerPic = "";
          }

          _this4.form.actionPrint.labelBegin = _this4.form.labelBegin;
          _this4.form.actionPrint.qrCode = _this4.form.qrCode;
          _this4.form.actionPrint.nameText = _this4.form.nameText;
          _this4.form.actionPrint.phoneText = _this4.form.phoneText;
          _this4.form.actionPrint.stationNameText = _this4.form.stationNameText;
          _this4.form.actionPrint.createTimeText = _this4.form.createTimeText;
          console.log(_this4.form);
          actionService.gpManageUpdate(_this4.form).then(function (res) {
            if (res.data.error) {
              _this4.$message({
                message: res.data.error,
                type: 'error'
              });
            } else {
              _this4.$message({
                message: '操作成功',
                type: 'success'
              });

              _this4.dialogFormVisible = false;

              _this4.getList();
            }
          });
        }
      });
    },
    getJiedao: function getJiedao() {
      var _this5 = this;

      Service.getArtjied().then(function (res) {
        _this5.stree = res.data;
      });
    },
    Street: function Street(Jid) {
      var _this6 = this;

      this.puts.Jid = Jid; //console.log(this.puts)

      Service.updateJied(this.puts).then(function (res) {
        if (res.status == 200) {
          _this6.$message({
            message: '修改成功',
            type: 'success'
          });

          _this6.getList();
        }
      });
    },
    Streets: function Streets() {
      console.log(this.form);
    },
    Str: function Str(id, pass) {
      this.puts.id = id;
      this.puts.pass = pass;
    },
    edit: function edit(rows) {
      var _this7 = this;

      // 行动站列表-不分页
      actionService.xdzManageInformation().then(function (res) {
        _this7.sites = res.data.data;
      });
      this.uploadPic = [];
      this.standbyPics = [];
      this.bannerPics = []; //console.log(rows)
      //if (rows.kitchenRegion) {

      this.addr.street = rows.street, this.addr.community = rows.community;
      this.addr.region = rows.region;
      this.addr.streetId = rows.streetId;
      this.form.streetId = rows.streetId;
      /*} else {
      	this.addr.street = rows.street
      	this.addr.streetId = rows.streetId
      	this.form.streetId = rows.streetId
      }*/
      //this.form = rows;

      if (rows.streetId) {
        this.streetId = rows.streetId;
      }

      if (rows.communityId) {
        this.communityId = rows.communityId;
      }

      if (rows.regionId) {
        this.regionId = rows.regionId;
      }

      this.form.id = rows.id;
      this.form.mbId = rows.mbId;
      this.form.deviceName = rows.deviceName;
      this.form.siteId = rows.siteId;
      this.form.phone = rows.phone;
      this.form.homepage = rows.homepage;
      this.form.subpage = rows.subpage;
      this.form.rank = rows.rank;
      this.form.bind = rows.bind;
      this.form.print = rows.print;
      this.form.state = rows.state;
      this.form.labelBegin = rows.actionPrint.labelBegin;
      this.form.qrCode = rows.actionPrint.qrCode;
      this.form.nameText = rows.actionPrint.nameText;
      this.form.phoneText = rows.actionPrint.phoneText;
      this.form.stationNameText = rows.actionPrint.stationNameText;
      this.form.createTimeText = rows.actionPrint.createTimeText;
      this.form.equipmentPic = rows.equipmentPic;
      this.form.standbyPic = rows.standbyPic;
      this.form.bannerPic = rows.bannerPic;

      if (this.form.equipmentPic != null && this.form.equipmentPic != '') {
        this.uploadPic.push(this.form.equipmentPic);
      }

      if (this.form.standbyPic != null && this.form.standbyPic != '') {
        for (var i = 0; i < this.form.standbyPic.split(";").length; i++) {
          this.standbyPics.push(this.form.standbyPic.split(";")[i]);
        }

        console.log(this.standbyPics);
      } //standbyPics:[],// 待机图片
      //bannerPics:[],// banner图片


      if (this.form.bannerPic != null && this.form.bannerPic != '') {
        for (var i = 0; i < this.form.bannerPic.split(";").length; i++) {
          this.bannerPics.push(this.form.bannerPic.split(";")[i]);
        }
      }

      this.dialogFormVisible = true; //console.log(this.form);
    }
  },
  update: function update() {
    this.Street(Jid);
    this.del(rows);
    this.getJiedao();
  }
};