var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }),
      _vm._v(" "),
      _c(
        "el-table",
        {
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递类型", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_c("span", [_vm._v(_vm._s(row.typeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: { autocomplete: "off", type: "number" },
                          model: {
                            value: row.weight,
                            callback: function ($$v) {
                              _vm.$set(row, "weight", $$v)
                            },
                            expression: "row.weight",
                          },
                        }),
                        _vm._v(" "),
                        $index + 1 === 1
                          ? _c("span", [
                              _vm._v(
                                "投递重量限制（单位：克）。填写至少多少克记为有效投递，0表示开门就算有效投递"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        $index + 1 === 2
                          ? _c("span", [
                              _vm._v(
                                "投递重量限制（单位：克）。填写至少多少克记为有效投递，0表示开门就算有效投递"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        $index + 1 === 3
                          ? _c("span", [
                              _vm._v(
                                "投递重量（单位：克）。1000 相当于计量单位"
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        $index + 1 === 4
                          ? _c("span", [
                              _vm._v(
                                "投递重量（单位：克）。1000 相当于计量单位"
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: { autocomplete: "off", type: "number" },
                          model: {
                            value: row.score,
                            callback: function ($$v) {
                              _vm.$set(row, "score", $$v)
                            },
                            expression: "row.score",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    $index + 1 === 1
                      ? _c("span", [_vm._v("单次奖励积分设置。填写0或正整数")])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 2
                      ? _c("span", [_vm._v("单次奖励积分设置。填写0或正整数")])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 3
                      ? _c("span", [
                          _vm._v(
                            "奖励积分设置（分）。投递1000克，获得多少积分"
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 4
                      ? _c("span", [
                          _vm._v(
                            "奖励积分设置（分）。投递1000克，获得多少积分"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "每日获得积分限制" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [
                    _c(
                      "span",
                      [
                        _c("el-input", {
                          attrs: { autocomplete: "off", type: "number" },
                          model: {
                            value: row.num,
                            callback: function ($$v) {
                              _vm.$set(row, "num", $$v)
                            },
                            expression: "row.num",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    $index + 1 === 1
                      ? _c("span", [
                          _vm._v("0表示不限制，1表示每日只获得1次奖励"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 2
                      ? _c("span", [
                          _vm._v("0表示不限制，1表示每日只获得1次奖励"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 3
                      ? _c("span", [_vm._v("每日最多能获取积分的最大值。")])
                      : _vm._e(),
                    _vm._v(" "),
                    $index + 1 === 4
                      ? _c("span", [_vm._v("每日最多能获取积分的最大值。")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer_jifen",
          staticStyle: { "margin-right": "10px" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.hasPerm("jifenguize:cancle")
            ? _c("el-button", { on: { click: _vm.cancle } }, [_vm._v("取 消")])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("jifenguize:confirm")
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sure } },
                [_vm._v("确 定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }