import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      names: this.$route.query.names,
      name: this.$route.query.name,
      total: 0,
      list: '',
      listLoading: false,
      listQuery: {
        mbId: this.$route.query.mbId,
        currentPage: 1,
        numOfPage: 20,
        ways: this.$route.query.ways
      },
      dialogFormVisible: false,
      formLabelWidth: '120px'
    };
  },
  created: function created() {
    if (this.listQuery.mbId) {
      this.getList();
    }
  },
  methods: {
    edit: function edit(e) {
      console.log(e);
      this.forms.mbId = e.mbId;
      this.forms.ways = e.ways;
      this.forms.goodsName = e.goodsName;
      this.forms.coin = e.coin;
      this.dialogFormVisible = true;
    },
    getList: function getList() {
      var _this = this;

      Service.rukuduihuanji(this.listQuery).then(function (res) {
        _this.list = res.data.data;
        _this.total = res.data.data.totalRows;
      });
    }
  }
};