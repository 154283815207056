//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import DataServer from '@/api/data';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      listLoading: true,
      list: [],
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      total: 0,
      dialogTableVisible: false,
      currStreet: null,
      street: []
    };
  },
  created: function created() {
    this.getList();
    this.getDizhi();
  },
  methods: {
    goBack: function goBack() {
      this.$router.back(-1);
    },
    go: function go(data) {
      this.$router.push({
        path: '/shujutongji/street',
        query: {
          name: data
        }
      });
    },
    getList: function getList() {
      var _this = this;

      DataServer.getHomeData().then(function (res) {
        _this.list = res.data;
        _this.total = res.data.length;
        _this.listLoading = false;
      });
    },
    getDizhi: function getDizhi(val) {
      var _this2 = this;

      DataServer.getJiedaoList({
        param: val
      }).then(function (res) {
        _this2.street = res.data.data;
      });
    },
    Sxiaoqu: function Sxiaoqu() {
      location.href = '/operation/supervisorRecord/exportLocation';
    },
    jiedao: function jiedao() {
      location.href = '/operation/supervisorRecord/exportStreetRecord';
    },
    xiaoqu: function xiaoqu() {
      location.href = '/operation/supervisorRecord/exportStreetRegion';
    }
  }
};