var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              attrs: { placeholder: "请选择街道" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.street,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "street", $$v)
                },
                expression: "listQuery.street",
              },
            },
            _vm._l(_vm.streets, function (i) {
              return _c("el-option", {
                key: i.street,
                attrs: { label: i.street, value: i.street },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.hasPerm("project:select")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("\n\t\t\t查询\n\t\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("project:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.add },
                },
                [_vm._v("\n\t\t\t添加\n\t\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "项目名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.projectName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.street)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "管理小区" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.regions)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "管理期限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(scope.row.begTime) +
                          "~" +
                          _vm._s(scope.row.endTime)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color: scope.row.state == 1 ? "#67C23A" : "#C03639",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.state == 0 ? "关闭" : "开启"))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("project:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加/编辑",
            visible: _vm.dialogAddOrUpdateFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrUpdateFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "项目名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "projectName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "projectName", $$v)
                      },
                      expression: "form.projectName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "街道",
                    "label-width": _vm.formLabelWidth,
                    prop: "street",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道", clearable: true },
                      on: {
                        change: function ($event) {
                          return _vm.streetTest($event)
                        },
                      },
                      model: {
                        value: _vm.form.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "street", $$v)
                        },
                        expression: "form.street",
                      },
                    },
                    _vm._l(_vm.streetList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.street, value: obj },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "社区",
                    "label-width": _vm.formLabelWidth,
                    prop: "community",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择社区", clearable: true },
                      on: {
                        change: function ($event) {
                          return _vm.communityTest($event)
                        },
                      },
                      model: {
                        value: _vm.form.community,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "community", $$v)
                        },
                        expression: "form.community",
                      },
                    },
                    _vm._l(_vm.shequList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.name, value: obj },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "小区",
                    "label-width": _vm.formLabelWidth,
                    prop: "region",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "600px" },
                      attrs: {
                        multiple: "",
                        filterable: "",
                        "value-key": "id",
                        placeholder: "请选择小区",
                      },
                      on: {
                        change: _vm.regionTest,
                        "remove-tag": _vm.removeTag,
                      },
                      model: {
                        value: _vm.regionObjs,
                        callback: function ($$v) {
                          _vm.regionObjs = $$v
                        },
                        expression: "regionObjs",
                      },
                    },
                    _vm._l(_vm.communityList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.region, value: obj },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleCheckAllChange },
                          model: {
                            value: _vm.allchecked,
                            callback: function ($$v) {
                              _vm.allchecked = $$v
                            },
                            expression: "allchecked",
                          },
                        },
                        [_vm._v("全选")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.regionObjsCopy.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": _vm.formLabelWidth } },
                    _vm._l(_vm.regionObjsCopy, function (element) {
                      return _c(
                        "span",
                        {
                          key: element.id,
                          staticStyle: {
                            position: "relative",
                            display: "inline-block",
                            width: "160px",
                          },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t" +
                              _vm._s(element.region) +
                              "\n\t\t\t\t\t\t"
                          ),
                          _c("img", {
                            staticStyle: {
                              width: "17px",
                              height: "17px",
                              position: "absolute",
                            },
                            attrs: {
                              src: "http://pic.touduila.com/FuDo4xUomaAsC9odswXnB5t2IBIp",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.pushEle(element)
                              },
                            },
                          }),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "管理期限",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "开始日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.begTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "begTime", $$v)
                      },
                      expression: "form.begTime",
                    },
                  }),
                  _vm._v("~\n\t\t\t\t\t"),
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "结束日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "endTime", $$v)
                      },
                      expression: "form.endTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "state",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddOrUpdateFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }