var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("Printermanagement:insert")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { margin: "30px" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.Tzhang },
                },
                [_vm._v("添加打印机")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加打印机", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "dataForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "SN码",
                    "label-width": _vm.formLabelWidth,
                    prop: "sn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.sn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sn", $$v)
                      },
                      expression: "form.sn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机地址",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("addrselect", {
                    attrs: {
                      street: _vm.addr.street,
                      community: _vm.addr.community,
                      region: _vm.addr.region,
                      floor: _vm.addr.floor,
                      streetId: _vm.addr.streetId,
                    },
                    on: { addrChange: _vm.addrChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打印机具体地址",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", placeholder: "监控具体地址" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "status",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.streetsc, function (i) {
                      return _c("el-option", {
                        key: i.name,
                        attrs: { label: i.name, value: i.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "经纬度", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "监控具体地址",
                      disabled: "",
                    },
                    model: {
                      value: _vm.form.lon + "," + _vm.form.lat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.lon + "," + _vm.form, "lat", $$v)
                      },
                      expression: "form.lon + ',' + form.lat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "amap-wrap" }, [
                _c("div", { attrs: { id: "pickerBox" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kong,
                        expression: "kong",
                      },
                    ],
                    staticClass: "el-input__inner",
                    attrs: {
                      id: "pickerInput",
                      placeholder: "输入地名进行选点",
                    },
                    domProps: { value: _vm.kong },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.kong = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "poiInfo" } }),
                ]),
                _vm._v(" "),
                _c("div", {
                  staticStyle: { height: "500px", width: "100%" },
                  attrs: { id: "amapContainer" },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Addz } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "90%", margin: "auto" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "设备名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "SN码" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.sn)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _c("span", [_vm._v(_vm._s(scope.row.street) + " -")]),
                      scope.row.kitchenRegion
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(scope.row.kitchenRegion.community) +
                                " -"
                            ),
                          ])
                        : _vm._e(),
                      scope.row.kitchenRegion
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.kitchenRegion.region) +
                                " -"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(scope.row.address)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "坐标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(scope.row.lat) + " , " + _vm._s(scope.row.lon)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "seagreen" },
                          },
                          [_vm._v(" 已开启 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !scope.row.status
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "firebrick" },
                          },
                          [_vm._v(" 已关闭 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "昨日", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.yesterday)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "本月", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.thisMonth)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上月", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.lastMonth)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "累计", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.total)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("Printermanagement:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status && _vm.hasPerm("Printermanagement:start")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(0, scope.row)
                              },
                            },
                          },
                          [_vm._v("停用")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !scope.row.status && _vm.hasPerm("Printermanagement:start")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(1, scope.row)
                              },
                            },
                          },
                          [_vm._v("启用")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }