"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("core-js/modules/es6.regexp.replace");

var _vue = _interopRequireDefault(require("vue"));

// 针对 el-input做的限制，只能输入正整数
_vue.default.directive('Int', {
  bind: function bind(el) {
    var input = el.getElementsByTagName('input')[0];

    input.onkeyup = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }

      trigger(input, 'input');
    };

    input.onblur = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }

      trigger(input, 'input');
    };
  }
});

var trigger = function trigger(el, type) {
  var e = document.createEvent('HTMLEvents');
  e.initEvent(type, true, true);
  el.dispatchEvent(e);
};