import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    return request({
      url: '/operation/style/getAllPicForOp',
      method: 'get',
      params: params
    });
  },
  del: function del(params) {
    return request({
      url: '/operation/style/deleteBanner',
      method: 'delete',
      params: params
    });
  },
  add: function add(params) {
    return request({
      url: '/operation/style/insertBanner',
      method: 'post',
      params: params
    });
  },
  update: function update(params) {
    return request({
      url: '/operation/style/modifyBanner',
      method: 'PUT',
      params: params
    });
  },
  getAllstreet: function getAllstreet() {
    return request({
      url: '/operation/style/allStreet',
      method: 'get'
    });
  }
};
export default Service;