var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _vm.device !== "mobile"
          ? [
              _c("error-log", {
                staticClass: "errLog-container right-menu-item",
              }),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.$t("navbar.screenfull"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("screenfull", {
                    staticClass: "screenfull right-menu-item",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    content: _vm.$t("navbar.size"),
                    effect: "dark",
                    placement: "bottom",
                  },
                },
                [
                  _c("size-select", {
                    staticClass: "international right-menu-item",
                  }),
                ],
                1
              ),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item",
            attrs: { trigger: "click" },
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("img", {
                staticClass: "user-avatar",
                attrs: { src: _vm.avatar + "?imageView2/1/w/80/h/80" },
              }),
              _vm._v(" "),
              _c("i", { staticClass: "el-icon-caret-bottom" }),
            ]),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", [
                  _c(
                    "span",
                    {
                      staticStyle: { display: "block" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v("退出登陆")]
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }