import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/sys';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

/**这代码谁写的，哪复制的，乱七八糟2022-07-14 */

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _ref;

    return _ref = {
      passWord: '',
      // jiedao:'',
      jiedaohao: [],
      rules: {
        name: [{
          required: true,
          message: '账号不能为空'
        }],
        passWord: [{
          required: true,
          message: "密码不能为空"
        }],
        state: [{
          required: true,
          message: '状态不能为空'
        }],
        roleId: [{
          required: true,
          message: '账号不能为空'
        }]
      },
      checkAll: false,
      isIndeterminate: true,
      juese: [],
      // 角色字典
      jiedao: [],
      // 街道字典
      states: [{
        "id": 0,
        "name": "正常"
      }, {
        "id": 1,
        "name": "拒绝"
      }],
      // 状态
      //juesehao: [],// 角色多选改为单选
      tableKey: 0,
      list: null,
      total: 0
    }, _defineProperty(_ref, "checkAll", false), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      passWord: '',
      userName: '',
      //roleIds: [],
      streetIds: [],
      name: '',
      state: '',
      roleId: ""
    }), _defineProperty(_ref, "temp", {
      id: undefined,
      importance: 1,
      remark: '',
      timestamp: new Date(),
      title: '',
      type: '',
      status: 'published'
    }), _defineProperty(_ref, "dialogFormVisible", false), _defineProperty(_ref, "userSelFormVisible", false), _defineProperty(_ref, "authUserQuery", {
      username: '',
      page: 1,
      limit: 10,
      roleid: ''
    }), _defineProperty(_ref, "authUserList", []), _defineProperty(_ref, "selUserQuery", {
      username: '',
      roleId: ''
    }), _defineProperty(_ref, "selUserList", []), _defineProperty(_ref, "addSelUserList", []), _defineProperty(_ref, "isRoleEdit", false), _defineProperty(_ref, "roleFormVisible", false), _defineProperty(_ref, "roleForm", {
      roleId: '',
      remark: '',
      roleName: ''
    }), _defineProperty(_ref, "roleFormTemp", {
      roleId: '',
      remark: '',
      roleName: ''
    }), _defineProperty(_ref, "authFormVisible", false), _defineProperty(_ref, "currentAuthData", null), _defineProperty(_ref, "treelist", null), _defineProperty(_ref, "treeChecked", []), _defineProperty(_ref, "defaultProps", {
      children: 'children',
      label: 'name'
    }), _defineProperty(_ref, "userName", ''), _defineProperty(_ref, "state", ""), _defineProperty(_ref, "ids", ''), _ref;
  },
  watch: {
    /* juesehao: function(e) {
     
    	  this.listQuery.roleIds = e.join(",")
     },*/
    jiedaohao: function jiedaohao(e) {
      // console.log(e)
      console.log(e.join(","));
      this.listQuery.streetIds = e.join(",");
    }
  },
  created: function created() {
    var _this = this;

    this.getList(); // 角色列表和街道列表

    Service.getDictData().then(function (res) {
      res.data.data.roles.forEach(function (item) {
        // 角色字典
        _this.juese.push({
          "id": item.id,
          "juese": item.roleName
        });
      });
      res.data.data.streets.forEach(function (item) {
        // 街道字典
        _this.jiedao.push({
          "id": item.id,
          "juese": item.street
        });
      });
    });
  },
  methods: {
    Loginlist: function Loginlist(e) {
      this.$router.push({
        name: '登录log',
        params: {
          datas: e
        }
      });
    },
    unique: function unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }

      return arr;
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this2 = this;

      console.log(val); // this.jiedaohao = val ? this.jiedao : [];

      this.isIndeterminate = false;

      if (val == true) {
        this.jiedao.forEach(function (item, index) {
          // row.streets.forEach((i,index)=>{
          // console.log(i)
          // if(item.juese == i){
          _this2.jiedaohao.push(item.id); // console.log(this.listQuery.roleIds)
          // 	}
          // })

        });
        this.listQuery.streetIds = this.unique(this.jiedaohao);
        console.log(this.listQuery.streetIds);
      } else {
        this.jiedaohao = [];
        this.listQuery.streetIds = [];
      }
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.jiedaohao.length; // console.log(this.checkAll)
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.jiedaohao.length;
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.getzhanghao({
        "userName": this.userName,
        "state": this.state
      }).then(function (res) {
        console.log(res);
        _this3.list = res.data.data;
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addRole: function addRole() {
      this.ids = "";
      this.jiedaohao = []; //this.juesehao = []
      //this.listQuery.roleIds = []

      this.listQuery.streetIds = [];
      this.dialogFormVisible = true;
    },
    // 点击添加
    Sure: function Sure() {
      var _this4 = this;

      var self = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          /*if(this.listQuery.roleIds ==[]){
            this.$notify({
              title: '失败',
              message: '请选择角色',
              type: 'error',
              duration: 2000,
            })
          }else */
          if (_this4.listQuery.streetIds == []) {
            _this4.$notify({
              title: '失败',
              message: '请选择数据权限',
              type: 'error',
              duration: 2000
            });
          } else {
            if (_this4.ids) {
              _this4.listQuery.userId = _this4.ids;
              Service.xiugai(_this4.listQuery).then(function (res) {
                console.log(res);

                if (res.data.error) {
                  _this4.$notify({
                    title: '失败',
                    message: res.data.error,
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  _this4.$notify({
                    title: '成功',
                    message: '修改成功',
                    type: 'success',
                    duration: 1500,
                    onClose: function onClose() {
                      self.getList();
                      self.dialogFormVisible = false;
                      location.reload();
                    }
                  });
                }
              }).catch(function (res) {
                _this4.$notify({
                  title: '失败',
                  message: '请为用户分配数据权限',
                  type: 'error',
                  duration: 2000
                });
              });
            } else {
              Service.yanzheng(_this4.listQuery.name).then(function (res) {
                console.log(res);

                if (res.data.data == false) {
                  _this4.$notify({
                    title: '失败',
                    message: '账号已存在',
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  Service.addRoles(_this4.listQuery).then(function (res) {
                    console.log(res);

                    if (res.data.error) {
                      _this4.$notify({
                        title: '失败',
                        message: res.data.error,
                        type: 'error',
                        duration: 2000
                      });
                    } else {
                      _this4.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success',
                        duration: 1500,
                        onClose: function onClose() {
                          self.getList();
                          self.dialogFormVisible = false;
                        }
                      });
                    }
                  }).catch(function (res) {
                    _this4.$notify({
                      title: '失败',
                      message: '请为用户分配数据权限',
                      type: 'error',
                      duration: 2000
                    });
                  });
                }
              });
            }
          }
        }
      });
    },
    editRole: function editRole(row) {
      var _this5 = this;

      console.log(row);
      this.dialogFormVisible = true;
      this.ids = row.id;
      this.jiedaohao = []; //this.juesehao = []
      //this.listQuery.roleIds = []

      this.listQuery.streetIds = [];
      this.listQuery.passWord = row.psd;
      this.listQuery.userName = row.userName;
      this.listQuery.name = row.name;
      this.listQuery.state = row.state;
      this.juese.forEach(function (item, index) {
        // console.log(item)
        row.userRoles.forEach(function (i, index) {
          //console.log(item.juese)
          if (item.id == i.id) {
            _this5.listQuery.roleId = item.id; //this.juesehao.push(item.id)
            //this.listQuery.roleIds.push(item.id);
            // console.log(this.juesehao)
          }
        });
      });
      this.jiedao.forEach(function (item, index) {
        row.userStreets.forEach(function (i, index) {
          // console.log(i)
          if (item.id == i.id) {
            _this5.jiedaohao.push(item.id);

            _this5.listQuery.streetIds.push(item.id); // console.log(this.listQuery.roleIds)

          }
        });
      });
    },
    editState: function editState(id, state) {
      // 正常、拒绝
      var self = this;
      Service.editState({
        "id": id,
        "state": state
      }).then(function (res) {
        console.log(res);
        self.$message({
          type: 'success',
          message: '修改成功!',
          onClose: function onClose() {
            self.getList();
          }
        });
      });
    },
    authRole: function authRole(row) {
      var _this6 = this;

      this.authFormVisible = true;
      this.currentAuthData = row;
      server.getRoleTree(row.roleId).then(function (res) {
        _this6.treelist = res;
        _this6.treeChecked = _this6.getChild(res);
      });
    },
    getChild: function getChild(arr) {
      var self = this;
      var tmp = [];
      arr.forEach(function (item) {
        if (item.checked) {
          tmp.push(item.id);
        }

        if (item.children) {
          tmp = tmp.concat(self.getChild(item.children));
        }
      });
      return tmp;
    },
    setMenuRole: function setMenuRole() {
      var _this7 = this;

      var roleId = this.currentAuthData.roleId;
      var menuids = [];
      var tree = this.$refs.tree.getCheckedNodes();
      console.log(tree);
      tree.forEach(function (item) {
        menuids.push(item.id);
      });
      server.setMenuRoles({
        roleId: roleId,
        menuids: menuids
      }).then(function (res) {
        _this7.authFormVisible = false;

        _this7.$message({
          type: 'success',
          message: '权限配置成功'
        });
      });
    },
    userRole: function userRole(row) {
      console.log(row);
      this.authUserQuery.roleid = row.roleId;
      this.getRoleUserList();
    },
    getRoleUserList: function getRoleUserList() {
      var _this8 = this;

      server.getRoleUserList(this.authUserQuery).then(function (res) {
        if (!res.code) {
          _this8.authUserList = res.page.list;
        }
      });
    },
    showSelUser: function showSelUser() {
      this.userSelFormVisible = true;
      this.selUserQuery.roleId = this.authUserQuery.roleid;
      this.getSelUserList();
    },
    getSelUserList: function getSelUserList() {
      var _this9 = this;

      server.getSelRoleUserList(this.selUserQuery).then(function (res) {
        if (!res.code) {
          _this9.selUserList = res.list;
        }
      });
    },
    // handleSelectionChange(val) {
    //   this.addSelUserList = val
    // },
    delRoleUser: function delRoleUser(row) {
      var roleId = this.authUserQuery.roleid;
      var userId = row.userId;
      var self = this;
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        server.delRoleUser({
          roleId: roleId,
          userId: userId
        }).then(function (res) {
          self.$message({
            type: 'success',
            message: '删除成功!',
            onClose: function onClose() {
              self.getRoleUserList();
            }
          });
        });
      }).catch(function () {// this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    addSelUser: function addSelUser() {
      var self = this;
      var roleId = this.selUserQuery.roleId;
      var userIds = [];
      this.addSelUserList.forEach(function (item) {
        userIds.push({
          roleId: roleId,
          userId: item.userId
        });
      });
      server.setSelRoleUserList(userIds).then(function (res) {
        self.userSelFormVisible = false;
        self.$message({
          type: 'success',
          message: '添加成功!',
          onClose: function onClose() {
            self.getRoleUserList();
          }
        });
      });
    }
  }
};