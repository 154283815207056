//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import { parseTime } from '@/utils';
export default {
  name: 'Wuziguanli',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  data: function data() {
    return {
      listLoading: false,
      time: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      form: {
        tel: ''
      },
      list: null,
      total: 0,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        num: '',
        street: '',
        type: '厨余袋'
      },
      searchStreet: '',
      listsd: [{
        info: '厨余袋',
        type: '厨余袋'
      }, {
        info: '厨余桶',
        type: '厨余桶'
      }]
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    exportExcel: function exportExcel() {
      location.href = '/operator/kitchenbin/exportOutStock?' + qs.stringify(this.listQuery);
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.wuzichuku(this.listQuery).then(function (res) {
        setTimeout(function () {
          _this.list = res.data.list;
          _this.total = res.data.totalRows; //						this.listQuery.currentPage = res.data.totalPages

          _this.listLoading = false;
        }, 1000);
      });
    }
  }
};