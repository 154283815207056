var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: { "value-key": "id", filterable: "", placeholder: "请选择小区" },
      model: {
        value: _vm.selectVal,
        callback: function ($$v) {
          _vm.selectVal = $$v
        },
        expression: "selectVal",
      },
    },
    _vm._l(_vm.list, function (obj) {
      return _c("el-option", {
        key: obj.id,
        attrs: { label: obj.region, value: obj },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }