//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import jiedao from '@/components/street/jiedao';
import { parseTime } from "@/utils";
export default {
  name: 'Dati',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      searchStreet: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: "",
        // 街道
        currentPage: 1,
        numOfPage: 20
      },
      dialogVisible: false
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      } else {
        this.listQuery.startTime = '';
        this.listQuery.endTime = '';
      }
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getDatiResult(this.listQuery).then(function (response) {
        _this.list = response.data.list;
        _this.total = response.data.totalRows;
        _this.listLoading = false;
      });
    },
    exportList: function exportList() {
      Service.exportDatiResult(this.listQuery).then(function (res) {
        var content = res.data;
        var blob = new Blob([content]);
        var fileName = '答题记录.xls';

        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象

          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    }
  }
};