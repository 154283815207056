//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Street from '@/components/street/street'
// import danweiStreet from '@/components/street/danweiStreet'
import Service from '@/api/service';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

export default {
  components: {
    Pagination: Pagination // Street,
    // danweiStreet

  },
  data: function data() {
    return {
      streetList: [{
        type: 0,
        text: '全部'
      }, {
        type: 2,
        text: '单位物业'
      }, {
        type: 3,
        text: '小区物业'
      }, {
        type: 5,
        text: '社区服务中心'
      }, {
        type: 6,
        text: '公共机构'
      }, {
        type: 7,
        text: '学校'
      }, {
        type: 8,
        text: '业主委员会'
      }, {
        type: 10,
        text: '企业'
      }, {
        type: 13,
        text: '商户'
      }, {
        type: 100,
        text: '其他'
      }],
      listQuery: {
        street: '',
        community: '',
        region: '',
        company: '',
        currentPage: 1,
        numOfPage: 20,
        companyType: 0
      },
      total: 0,
      list: [],
      xiugais: {
        locationId: '',
        remarks: ''
      },
      form: {
        chanames: ''
      },
      dialogFormVisible: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    taizhang: function taizhang(e) {
      console.log(e);
      this.$router.push({
        path: '/front/taizhang',
        query: {
          data: e.id
        }
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addrChanges: function addrChanges(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
      this.getList();
    },
    beizhuuser: function beizhuuser(e) {
      this.dialogFormVisible = true;
      console.log(e);
      this.xiugais.locationId = e.id;
      this.xiugais.remarks = e.remarks;
    },
    Sures: function Sures() {
      var _this = this;

      Service.xiugaibeizhu(this.xiugais).then(function (res) {
        _this.$message({
          type: 'success',
          message: '成功'
        });

        _this.dialogFormVisible = false;

        _this.getList();
      });
    },
    getList: function getList() {
      var _this2 = this;

      console.log(this.listQuery);
      Service.danweilist(this.listQuery).then(function (res) {
        console.log(res);
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
      });
    }
  }
};