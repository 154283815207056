var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app-container", attrs: { div: "" } },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _vm.hasPerm("tag:insert")
                ? _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.addParentAiPicTag },
                    },
                    [_vm._v("创建标签")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.list,
                "row-key": "id",
                border: "",
                "default-expand-all": "",
                "tree-props": { children: "children" },
              },
            },
            [
              _c("el-table-column", { attrs: { prop: "id", label: "序号" } }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "label", label: "标签名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "level", label: "层级" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.level == 1
                          ? _c("span", [_vm._v("一级")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.level == 2
                          ? _c("span", [_vm._v("二级")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.level == 3
                          ? _c("span", [_vm._v("三级")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.hasPerm("tag:edit")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAiPicTag(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("tag:delete")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danger", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delAiPicTag(row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("tag:subinsert") && row.level < 3
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addSonAiPicTag(row)
                                  },
                                },
                              },
                              [_vm._v("添加子标签")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加/编辑标签",
            visible: _vm.dialogAddOrUpdateFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrUpdateFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "标签名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "label",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "label", $$v)
                      },
                      expression: "form.label",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddOrUpdateFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }