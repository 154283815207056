"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.regexp.replace");

var _defineProperty2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _service = _interopRequireDefault(require("@/api/service"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _pointselect = _interopRequireDefault(require("@/components/street/pointselect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Zhongduanguanli',
  components: {
    Pagination: _Pagination.default,
    pointSelect: _pointselect.default
  },
  data: function data() {
    var _forms;

    return {
      locations: [],
      total: 0,
      list: null,
      listLoading: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      formLabelMinWidth: '75px',
      form: {
        deviceName: '',
        deviceRegion: '',
        id: '',
        purchaseTime: ''
      },
      time: '',
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        key: ''
      },
      bins: '',
      times: '',
      op: '',
      addr: {
        community: '',
        region: '',
        street: '',
        floor: '',
        mark: '',
        pointName: ''
      },
      louhao: '',
      forms: (_forms = {
        id: '',
        deviceName: '',
        mbId: "",
        deviceAddress: '',
        deviceRegion: '',
        street: '',
        mark: "",
        //点位ID
        vender: "",
        state: "",
        devicePic: ""
      }, (0, _defineProperty2.default)(_forms, "mark", ''), (0, _defineProperty2.default)(_forms, "pointName", ''), (0, _defineProperty2.default)(_forms, "community", ""), _forms),
      stree: '',
      // pointList:[],// 点位列表
      // venderOptions: [{// 厨余类型字典
      //   typeInfo: 'yidian',
      //   type: 'yidian'
      // },
      // {
      //   typeInfo: 'dls',
      //   type: 'dls'
      // },
      // {
      //   typeInfo: 'smart',
      //   type: 'smart'
      // }],
      images: "",
      // 弹框图
      dialog: false
    };
  },
  watch: {
    'forms.purchaseTime': function formsPurchaseTime(date) {
      var nowdate = new Date(date).toLocaleDateString().replace(/\//g, '-');
      this.forms.purchaseTime = nowdate;
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    Sure: function Sure() {
      var _this = this;

      this.forms.deviceAddress = this.addr.street + ',' + this.addr.community + ',' + this.addr.region + ',' + this.addr.floor; // console.log(this.forms.deviceName, this.addr.street + ' , ' + this.addr.community + ' , ' + this.addr.region +
      // 	' , ' + this.addr.floor)

      this.forms.street = this.addr.street;

      if (!this.forms.deviceName) {
        this.$message({
          message: '设备名称不能为空',
          type: 'error'
        });
      } else if (!this.addr.street || !this.addr.community || !this.addr.region) {
        this.$message({
          message: '设备地址不能为空',
          type: 'error'
        }); // this.forms.mark  = e.mark
        // this.forms.vender = e.vender
        // this.forms.state = e.state
        // this.froms.devicePic = e.devicePic
      } // else if(!this.forms.mark){
      // 	this.$message({
      // 		message: '点位不能为空',
      // 		type: 'error'
      // 	})
      // } 
      // else if(!this.forms.vender){
      // 	this.$message({
      // 		message: '设备厂家不能为空',
      // 		type: 'error'
      // 	})
      // } 
      else if (!this.forms.state) {
        this.$message({
          message: '设备状态不能为空',
          type: 'error'
        });
      } else {
        this.forms.deviceRegion = this.addr.region;
        setTimeout(function (res) {
          _service.default.mergeBinById(_this.forms).then(function (res) {
            _this.dialogFormVisible = false; //	console.log(res)

            _this.$message({
              message: '修改成功',
              type: 'success'
            });

            _this.getList();
          });
        }, 500);
      }
    },
    toogleExpand: function toogleExpand(row) {
      this.getNList(row.id);
      var $table = this.$refs.table;
      this.list.map(function (item) {
        if (row.mbId != item.mbId) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },
    loadImage: function loadImage(e) {
      var _this2 = this;

      this.dialog = true;
      setTimeout(function () {
        _this2.images = e;
      }, 200);
    },
    handleFilter: function handleFilter() {
      // this.community = ''
      // this.region = ''
      // this.street = ''
      // this.louhao = ''
      this.dialogFormVisible = true;
    },
    view: function view(row) {
      this.$router.push({
        name: 'chaoshishangbao',
        query: {
          mbId: row.mbId,
          name: row.deviceName
        }
      });
    },
    edit: function edit(e) {
      //	console.log(e)
      this.dialogFormVisible = true;
      this.forms.id = e.id;
      this.forms.deviceName = e.deviceName; // this.forms.deviceRegion = e.deviceRegion

      this.forms.purchaseTime = e.purchaseTime; // 增加点位时，增加的5个字段

      this.forms.mbId = e.mbId;
      this.forms.vender = e.vender;
      this.forms.state = e.state;
      this.forms.devicePic = e.devicePic;
      this.addr.pointName = e.pointName;
      this.addr.mark = e.mark;
      this.forms.pointName = e.pointName;
      this.forms.mark = e.mark;

      if (e['deviceAddress']) {
        var addr = e['deviceAddress'].split(",");

        if (addr.length == 4) {
          this.addr.street = addr[0].trim();
          this.addr.community = addr[1].trim();
          this.addr.region = addr[2].trim();
          this.addr.floor = addr[3].trim();
          this.forms.street = addr[0].trim();
          this.forms.community = addr[1].trim();
          this.forms.deviceRegion = addr[2].trim();
        }
      } else {
        this.addr.street = "";
        this.addr.community = "";
        this.addr.region = "";
        this.addr.floor = "";
        this.forms.street = "";
        this.forms.community = "";
        this.forms.deviceRegion = "";
      }
    },
    addrChange: function addrChange(data) {
      // this.addr.community = data.community
      // this.addr.floor = data.floor
      // this.addr.region = data.region
      // this.addr.street = data.street
      // this.addr.mark = data.mark
      // this.addr.pointName = data.pointName
      this.forms.streetId = data.streetId;
      this.forms.communityId = data.communityId;
      this.forms.regionId = data.regionId;
      this.forms.street = data.street;
      this.forms.community = data.community;
      this.forms.deviceRegion = data.region;
      this.forms.mark = data.mark;
      this.forms.pointName = data.pointName;
      this.addr.streetId = data.streetId;
      this.addr.communityId = data.communityId;
      this.addr.regionId = data.regionId;
      this.addr.community = data.community;
      this.addr.region = data.region;
      this.addr.street = data.street;
      this.addr.mark = data.mark;
      this.addr.pointName = data.pointName;
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;

      _service.default.getbinsList(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this3.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    getNList: function getNList(id) {
      var _this4 = this;

      _service.default.getNbinsList(id).then(function (res) {
        //console.log(res)
        _this4.locations = res.data;
      });
    },
    // xuanze() {
    // 	console.log(1)
    // },
    routr: function routr(row) {
      var deviceGpsLng = row.deviceGpsLng;
      var deviceGpsLat = row.deviceGpsLat;
      this.$router.push({
        path: '/device/map',
        query: {
          deviceGpsLng: deviceGpsLng,
          deviceGpsLat: deviceGpsLat
        }
      });
    },
    Search: function Search() {
      this.getList();
    },
    uploadImg: function uploadImg(data) {
      this.forms.devicePic = data.key;
    }
  }
};
exports.default = _default;