import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/coinadjust';
import SysService from '@/api/sys';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      addOrEditForm: {
        id: "",
        taskName: '',
        //活动名称
        grantMode: '',
        // 发放方式
        coinValue: "",
        // 发放值
        coinValue1: "",
        coinValue2: "",
        street: '',
        // 街道号
        grantCondition: '',
        //发放条件
        period: "",
        //统计周期
        grantNumber: "",
        // 发放人数
        grantDateRadio: "",
        // 发放日期radio
        grantDate: "",
        day: "",
        // 发放天数
        weekStr: "",
        // 固定星期发放str
        action: '',
        // 发放原因
        state: ''
      },
      week: [],
      // 固定星期发放+load+model
      grantModes: [{
        "id": '随机发放',
        "name": "随机发放"
      }, {
        "id": '固定值发放',
        "name": "固定值发放"
      }],
      // 发放方式字典
      jiedaos: [],
      // 街道字典
      grantConditions: [{
        "id": '街道获得积分排名',
        "name": "街道获得积分排名"
      }, {
        "id": '街道投递厨余次数排名',
        "name": "街道投递厨余次数排名"
      }, {
        "id": '街道参与可回收次数排名',
        "name": "街道参与可回收次数排名"
      }, {
        "id": '随机用户',
        "name": "随机用户(随机用户发放时统计周期无效)"
      }],
      // 发放条件
      periods: [
      /*{"id":"本月",name:"本月"},{"id":"本周",name:"本周"},*/
      {
        "id": "上月",
        name: "上月"
      }, {
        "id": "上周",
        name: "上周"
      }],
      // 统计周期
      weeks: [{
        "id": "MONDAY",
        name: "星期一"
      }, {
        "id": "TUESDAY",
        name: "星期二"
      }, {
        "id": "WEDNESDAY",
        name: "星期三"
      }, {
        "id": "THURSDAY",
        name: "星期四"
      }, {
        "id": "FRIDAY",
        name: "星期五"
      }, {
        "id": "SATURDAY",
        name: "星期六"
      }, {
        "id": "SUNDAY",
        name: "星期日"
      }],
      actions: [{
        "id": 402,
        name: "系统发放"
      }, {
        "id": 404,
        name: "参与奖励"
      }, {
        "id": 405,
        name: "活动奖励"
      }, {
        "id": 406,
        name: "其他"
      }],
      actionss: [{
        "id": 402,
        name: "系统发放"
      }, {
        "id": 403,
        name: "系统扣减"
      }, {
        "id": 404,
        name: "参与奖励"
      }, {
        "id": 405,
        name: "活动奖励"
      }, {
        "id": 406,
        name: "其他"
      }],
      // 单用户扣减
      rules: {
        taskName: [{
          required: true,
          message: '活动名称不能为空'
        }],
        grantMode: [{
          required: true,
          message: "发放方式不能为空"
        }],

        /*coinValue: [{ required: true, message: '发放数值不能为空'}],
        coinValue1: [{ required: true, message: '发放数值不能为空'}],
        coinValue2: [{ required: true, message: '发放数值不能为空'}],*/
        street: [{
          required: true,
          message: '请选择街道'
        }],
        grantCondition: [{
          required: true,
          message: '请选择发放条件'
        }],
        period: [{
          required: true,
          message: '请选择统计周期'
        }]
      },
      disabledDay: true,
      // 发放日期 固定日期发放默认禁用 
      disabledWeek: true,
      // 固定星期发放，默认禁用
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        key: ""
      },
      dialogFormVisible: false,
      // 添加/编辑
      dialogGrantModes: false,
      // 随机发放
      //单用户调整
      dialogOnlyFormVisible: false,
      only: {
        key: ""
      },
      onlyTableKey: 0,
      onlyList: null,
      onlyListLoading: false,
      editForm: {
        coin: "",
        action: "",
        userId: ""
      },
      onlyRules: {
        coin: [{
          required: true,
          message: '积分不能为空'
        }],
        action: [{
          required: true,
          message: '请选择调整原因'
        }]
      },
      radio: '',
      // 单用户选择
      cardNumber: "" // 卡号，用于跳转传参

    };
  },
  watch: {
    "addOrEditForm.grantMode": function addOrEditFormGrantMode(newValue) {
      if (newValue == "随机发放") {
        this.dialogGrantModes = true;
      } else {
        this.dialogGrantModes = false;
      }
    },
    "addOrEditForm.grantDateRadio": function addOrEditFormGrantDateRadio(newValue) {
      if (newValue == '0') {
        // 固定日期发放
        this.disabledDay = false;
        this.disabledWeek = true;
      } else {
        // 固定星期发放
        this.disabledDay = true;
        this.disabledWeek = false;
      }
    },
    "week": function week(newValue) {
      //console.log(newValue.join(","));
      this.addOrEditForm.weekStr = newValue.join(",");
    }
  },
  created: function created() {
    var _this = this;

    this.getList(); // 街道列表

    SysService.getDictData().then(function (res) {
      res.data.data.streets.forEach(function (item) {
        // 街道字典
        _this.jiedaos.push({
          "id": item.street,
          "name": item.street
        });
      });
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true; // console.log(this.search.key);

      Service.getList(this.listQuery).then(function (res) {
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
        _this2.listLoading = false;
      });
    },
    add: function add() {
      this.dialogFormVisible = true;
      this.addOrEditForm.id = "";
      this.addOrEditForm.taskName = "";
      this.addOrEditForm.grantMode = '随机发放';
      this.addOrEditForm.coinValue1 = '';
      this.addOrEditForm.coinValue2 = "";
      this.addOrEditForm.coinValue = "";
      this.addOrEditForm.street = ""; // 街道

      this.addOrEditForm.grantCondition = "街道获得积分排名";
      this.addOrEditForm.period = "上月";
      this.addOrEditForm.grantNumber = ""; // // 发放人数

      this.week = []; // 固定发放星期，数组

      this.addOrEditForm.weekStr = ""; // 固定发放星期，字符串

      this.addOrEditForm.action = "";
      this.addOrEditForm.state = 0; // 开启

      this.addOrEditForm.action = '';
    },
    edit: function edit(row) {
      console.log(row);
      this.dialogFormVisible = true;
      this.addOrEditForm.id = row.id;
      this.addOrEditForm.taskName = row.taskName;
      this.addOrEditForm.grantMode = row.grantMode;

      if (row.grantMode == '随机发放') {
        this.addOrEditForm.coinValue1 = row.coinValue.split("-")[0];
        this.addOrEditForm.coinValue2 = row.coinValue.split("-")[1];
      } else if (row.grantMode == '固定值发放') {
        this.addOrEditForm.coinValue = row.coinValue;
      }

      this.addOrEditForm.street = row.street;
      this.addOrEditForm.grantCondition = row.grantCondition.split("/")[0];
      this.addOrEditForm.period = row.grantCondition.split("/")[1];
      this.addOrEditForm.grantNumber = row.grantNumber; // 发放人数

      this.addOrEditForm.grantDate = row.grantDate.split("/")[0];

      if (this.addOrEditForm.grantDate == '固定日期发放') {
        this.addOrEditForm.grantDateRadio = 0;
        this.addOrEditForm.day = row.grantDate.split("/")[1];
      } else {
        this.addOrEditForm.grantDateRadio = 1;
        this.week = row.grantDate.split("/")[1].split(",");
      }

      this.addOrEditForm.action = row.action; //发放原因
      //console.log(this.addOrEditForm.action);

      this.addOrEditForm.state = row.state;
    },
    del: function del(row) {
      var _this3 = this;

      console.log(row);
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.adjustStreetDelete(row.id).then(function (res) {
          if (res.data.error) {
            _this3.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this3.getList();
        });
      }).catch(function () {});
    },
    Loginlist: function Loginlist(e) {
      this.$router.push({
        name: '发放明细',
        params: {
          datas: e
        }
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    // 点击添加
    Sure: function Sure() {
      var _this4 = this;

      var self = this;
      this.$refs['addOrEditForm'].validate(function (valid) {
        if (valid) {
          // console.log(this.addOrEditForm.coinValue1+":"+this.addOrEditForm.coinValue2)
          if (_this4.addOrEditForm.grantMode == '随机发放' && (_this4.addOrEditForm.coinValue1 == '' || _this4.addOrEditForm.coinValue2 == '')) {
            _this4.$notify({
              title: '失败',
              message: '发放数值必填',
              type: 'error',
              duration: 2000
            });
          } else if (_this4.addOrEditForm.grantMode == '固定值发放' && _this4.addOrEditForm.coinValue == '') {
            _this4.$notify({
              title: '失败',
              message: '发放数值必填',
              type: 'error',
              duration: 2000
            });
          } else if (_this4.addOrEditForm.grantMode == '固定值发放' && _this4.addOrEditForm.coinValue.indexOf('-') > 0 && _this4.addOrEditForm.coinValue.split("-").length != _this4.addOrEditForm.grantNumber) {
            _this4.$notify({
              title: '失败',
              message: '发放人数和发放数值设置有误',
              type: 'error',
              duration: 2000
            });
          } else if (_this4.addOrEditForm.action == '') {
            _this4.$notify({
              title: '失败',
              message: '发放原因必填',
              type: 'error',
              duration: 2000
            });
          } else {
            if (_this4.addOrEditForm.grantMode == "随机发放") {
              _this4.addOrEditForm.coinValue = _this4.addOrEditForm.coinValue1 + "-" + _this4.addOrEditForm.coinValue2; // -
            }

            if (_this4.addOrEditForm.grantDateRadio == '0') {
              _this4.addOrEditForm.grantDate = "固定日期发放/" + _this4.addOrEditForm.day;
            } else {
              _this4.addOrEditForm.grantDate = "固定星期发放/" + _this4.addOrEditForm.weekStr;
            }

            _this4.addOrEditForm.grantCondition = _this4.addOrEditForm.grantCondition + "/" + _this4.addOrEditForm.period; // 发放条件和统计周期合并

            if (_this4.addOrEditForm.id != "" && _this4.addOrEditForm.id != null) {
              // 修改
              console.log(_this4.addOrEditForm);
              Service.adjustStreetEdit(_this4.addOrEditForm).then(function (res) {
                if (res.data.error) {
                  _this4.$notify({
                    title: '失败',
                    message: res.data.error,
                    type: 'error',
                    duration: 2000
                  });

                  return false;
                }

                _this4.dialogFormVisible = false;

                _this4.$notify({
                  title: '成功',
                  message: '修改成功',
                  type: 'success',
                  duration: 2000
                });

                _this4.getList();
              });
            } else {
              // 添加
              console.log(_this4.addOrEditForm);
              Service.adjustStreetAdd(_this4.addOrEditForm).then(function (res) {
                if (res.data.error) {
                  _this4.$notify({
                    title: '失败',
                    message: res.data.error,
                    type: 'error',
                    duration: 2000
                  });

                  return false;
                }

                _this4.dialogFormVisible = false;

                _this4.$notify({
                  title: '成功',
                  message: '新增成功',
                  type: 'success',
                  duration: 2000
                });

                _this4.getList();
              });
            }
          }
        }
      });
    },
    viewDtail: function viewDtail(row) {
      this.$router.push({
        path: '/basicsetting/coinadjustdetail',
        query: {
          taskId: row.id
        }
      });
    },
    // 单用户调整
    addOnly: function addOnly() {
      this.dialogOnlyFormVisible = true;
      this.only.key = "";
      this.onlyList = [];
      this.radio = ''; // 选择的用户id

      this.editForm.coin = ""; // 积分

      this.editForm.action = ""; // 发放原因
    },
    onlyGetList: function onlyGetList() {
      var _this5 = this;

      this.onlyListLoading = true;
      console.log(this.only.key);
      Service.userSearch(this.only.key).then(function (res) {
        _this5.onlyList = res.data.data;
        _this5.onlyListLoading = false;
      });
    },
    clickChange: function clickChange(row) {
      //console.log(row);
      this.radio = row.userId;
      this.cardNumber = row.cardNumber;
    },
    showRow: function showRow(row) {// this.radio = this.onlyList.indexOf(row);
      //console.log(this.radio);
    },
    onlySure: function onlySure() {
      var _this6 = this;

      var self = this;
      this.$refs['editForm'].validate(function (valid) {
        if (valid) {
          // console.log(this.addOrEditForm.coinValue1+":"+this.addOrEditForm.coinValue2)
          if (_this6.radio == '') {
            _this6.$notify({
              title: '失败',
              message: '请选择用户',
              type: 'error',
              duration: 2000
            });
          } else {
            _this6.editForm.userId = _this6.radio;
            Service.adjustOneAdd(_this6.editForm).then(function (res) {
              if (res.data.error) {
                _this6.$notify({
                  title: '失败',
                  message: res.data.error,
                  type: 'error',
                  duration: 2000
                });

                return false;
              }

              _this6.$confirm('添加成功', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '查看明细'
              }).then(function () {
                _this6.dialogOnlyFormVisible = false;

                _this6.getList();
              }).catch(function () {
                // 查看明细-居民用户列表
                _this6.$router.push({
                  path: '/front/users',
                  query: {
                    cardNumber: _this6.cardNumber
                  }
                });

                _this6.dialogOnlyFormVisible = false;
              });
              /*
              this.dialogOnlyFormVisible = false
              this.$notify({
                title: '成功',
                message: '修改成功',
                type: 'success',
                 duration: 2000,
               
              })
              this.getList()
              */

            });
          }
        }
      });
    }
  }
};