"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vue = _interopRequireDefault(require("vue"));

var _vueI18n = _interopRequireDefault(require("vue-i18n"));

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _zh = _interopRequireDefault(require("./zh"));

// element-ui lang
_vue.default.use(_vueI18n.default);

var messages = {
  zh: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _zh.default), _zhCN.default)
};
var i18n = new _vueI18n.default({
  // set locale
  // options: en | zh | es
  locale: 'zh',
  // set locale messages
  messages: messages
});
var _default = i18n;
exports.default = _default;