"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

require("core-js/modules/web.dom.iterable");

var _objectSpread2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _elementUi = require("element-ui");

var _service = _interopRequireDefault(require("@/api/service"));

var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

var _ErrorLog = _interopRequireDefault(require("@/components/ErrorLog"));

var _Screenfull = _interopRequireDefault(require("@/components/Screenfull"));

var _SizeSelect = _interopRequireDefault(require("@/components/SizeSelect"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default,
    ErrorLog: _ErrorLog.default,
    Screenfull: _Screenfull.default,
    SizeSelect: _SizeSelect.default
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'name', 'avatar', 'device'])),
  created: function created() {
    this.goNext();
  },
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    goNext: function goNext() {// setTimeout(() => {
      //   this.getOrder()
      // }, 30000)
    },
    getOrder: function getOrder() {
      var self = this;

      _service.default.newOrder().then(function (res) {
        if (res.status == 200) {
          if (res.data.length) {
            res.data.forEach(function (item, index) {
              setTimeout(function () {
                self.$notify({
                  title: item.location.name + '单号' + item.id,
                  message: item.location.address,
                  position: 'bottom-right'
                });
              }, 500 * index);
            });
            self.goNext();
          }
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    logout: function logout() {
      var _this = this;

      _service.default.Weberror().then(function (res) {
        _this.$message({
          message: '退出成功',
          type: 'success'
        });

        _this.$router.push({
          path: '/jiedao/jiedaologin'
        });
      });
    }
  }
};
exports.default = _default;