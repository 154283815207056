import request from '@/utils/request';
var Service = {
  getzhanghao: function getzhanghao(params) {
    // 查询用户列表
    return request({
      url: '/sys/user',
      method: 'get',
      params: params
    });
  },
  editState: function editState(params) {
    // 正常、拒绝
    return request({
      url: '/sys/updateUserState',
      method: 'post',
      params: params
    });
  },
  addRoles: function addRoles(params) {
    return request({
      url: '/sys/addUser',
      method: 'post',
      params: params
    });
  },
  yanzheng: function yanzheng(params) {
    return request({
      url: '/sys/validateUser?userName=' + params,
      method: 'post'
    });
  },
  xiugai: function xiugai(params) {
    // 修改账号
    return request({
      url: '/sys/modifyUser',
      method: 'post',
      params: params
    });
  },
  getList: function getList(params) {
    return request({
      url: '/sys/role?roleName=' + params,
      method: 'post'
    });
  },
  // 角色查询列表
  getAuthoritys: function getAuthoritys(params) {
    // 权限树列表
    return request({
      url: '/accessAuthorityController/getAuthoritys',
      method: 'get',
      params: params
    });
  },
  saveRole: function saveRole(params) {
    return request({
      url: '/accessAuthorityController/insertRole',
      method: 'post',
      params: params
    });
  },
  updateRole: function updateRole(params) {
    return request({
      url: '/accessAuthorityController/updateRole',
      method: 'post',
      params: params
    });
  },
  getAccessRoleAuthorityByRoleId: function getAccessRoleAuthorityByRoleId(params) {
    return request({
      url: '/accessAuthorityController/getAccessRoleAuthorityByRoleId',
      method: 'get',
      params: params
    });
  },
  getDictData: function getDictData() {
    return request({
      url: '/sys/getDictData',
      method: 'get'
    });
  },
  getUserRoleInfo: function getUserRoleInfo() {
    return request({
      url: '/sys/getUserRoleInfo',
      method: 'get'
    });
  }
};
export default Service;