import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      downlist: ['上午上班打卡', '上午下班打卡', '下午上班补卡', '下午下班补卡'],
      formLabelWidth: '120px',
      dialogs: false,
      dialog: false,
      total: 0,
      tableData: [],
      data: '',
      listQuery: {
        weixinId: this.$route.query.data,
        currentPage: 1,
        numOfPage: 20,
        workDate: this.getNowFormatDate()
      },
      listQuerys: {
        recyclerId: this.$route.query.data,
        currentPage: 1,
        numOfPage: 20,
        startTime: new Date().getFullYear() + '-' + Number(new Date().getMonth() + 1) + '-' + new Date().getDay()
      },
      zhanshiimg: '',
      radio1: '1',
      shows: 2,
      value: new Date(),
      tabledata: [],
      beizhudata: {
        id: '',
        remark: '',
        fangshi: '上午上班打卡',
        workDate: '',
        time: new Date(2016, 9, 1, 0, 0)
      },
      datae: ''
    };
  },
  watch: {
    "listQuery.workDate": function listQueryWorkDate(e) {
      if (e) {
        this.gettablelist();
      } else {
        this.listQuery.workDate = new Date().getFullYear() + '-' + Number(new Date().getMonth() + 1);
      }
    },
    value: function value(e) {
      var d = new Date(e);
      this.datae = d;
      this.gettablelist(e);
    },
    radio1: function radio1(e) {
      console.log(this.datae);

      if (e == 2) {
        this.getList();
      } else {
        this.gettablelist(new Date());
      }
    }
  },
  created: function created() {
    this.gettablelist(new Date());
    this.getNames();
  },
  methods: {
    getNowFormatDate: function getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();

      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }

      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }

      var currentdate = year + seperator1 + month;
      return currentdate;
    },
    Sures: function Sures() {
      var _this = this;

      delete this.beizhudata.workDate;
      var self = this;
      Service.charubeizhu(this.beizhudata).then(function (res) {
        _this.$notify({
          title: '成功',
          message: '插入成功',
          type: 'success',
          duration: 2000,
          onClose: function onClose() {
            self.gettablelist();
          }
        });
      });
      this.dialog = false;
    },
    beizhus: function beizhus(e, data) {
      this.beizhudata.remark = '';
      this.beizhudata.id = data.id;
      this.beizhudata.workDate = e;
      this.dialog = true;
    },
    getList: function getList(e) {
      var _this2 = this;

      delete this.listQuery.month;
      Service.dakaxiangqingjilu(this.listQuery).then(function (res) {
        _this2.tableData = res.data.data.list;
        _this2.total = res.data.data.totalRows;
      });
    },
    getNames: function getNames() {
      var _this3 = this;

      Service.dakaxiangq(this.listQuerys).then(function (res) {
        _this3.data = res.data.data.location;
      });
    },
    gettablelist: function gettablelist(e) {
      var _this4 = this;

      Service.tabledak(this.listQuery).then(function (res) {
        res.data.forEach(function (item, index) {
          item.address = item.address.split('/n');
          item.deviceName = item.deviceName.split('/n');
          item.mbId = item.mbId.split('/n');
          item.pic = item.pic.split(',');
          item.workTime = item.workTime.split('/n');
          item.remark = item.remark ? item.remark.split('/n') : '';
        });
        _this4.tableData = res.data;
      });
    },
    imgs: function imgs(data) {
      this.dialogs = true;
      this.zhanshiimg = data;
    }
  }
};