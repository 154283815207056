var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("type:insert")
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addType },
                },
                [_vm._v("添加类型")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "ID",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "banner图片", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pic
                      ? _c("img", {
                          staticStyle: { width: "100%", height: "170px" },
                          attrs: {
                            src: "http://pic.touduila.com/" + scope.row.pic,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打卡类型", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.typeInfo)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖励积分", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.coin)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动说明", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.des)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "活动图片上传要求" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.ruler)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打卡样例图", width: "280" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.clockPic
                      ? _c("img", {
                          staticStyle: { width: "100%", height: "170px" },
                          attrs: {
                            src:
                              "http://pic.touduila.com/" + scope.row.clockPic,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color: scope.row.status == 1 ? "#67C23A" : "#C03639",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status == 0 ? "关闭" : "开启"))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "针对客户端", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(_vm.datas, function (item, index) {
                    return item.value == scope.row.role
                      ? _c("span", { staticClass: "link-type" }, [
                          _vm._v(_vm._s(item.name)),
                        ])
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "针对街道", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.street)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核方式", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.auditMethod == 0
                            ? "自动审核通过"
                            : "人工审核"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            staticStyle: { width: "300px" },
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("type:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 编辑 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.record(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 操作记录 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialog, title: _vm.addstatus },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "forms",
              attrs: {
                model: _vm.forms,
                "label-width": "120px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打卡类型", prop: "typeInfo" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.forms.typeInfo,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "typeInfo", $$v)
                        },
                        expression: "forms.typeInfo",
                      },
                    },
                    _vm._l(_vm.typeInfos, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "针对客户端", prop: "role" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.forms.role,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "role", $$v)
                        },
                        expression: "forms.role",
                      },
                    },
                    _vm._l(_vm.datas, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "针对街道", prop: "street" } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.searchStreet,
                      callback: function ($$v) {
                        _vm.searchStreet = $$v
                      },
                      expression: "searchStreet",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖励积分", prop: "coin" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "coin", $$v)
                      },
                      expression: "forms.coin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动图片上传要求" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.ruler,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "ruler", $$v)
                      },
                      expression: "forms.ruler",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "banner图" } }, [
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "https://pic.touduila.com/DK-chuyudak_1.jpg",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.pic,
                        expression: "forms.pic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: { type: "radio", value: "DK-chuyudak_1.jpg" },
                    domProps: {
                      checked: _vm._q(_vm.forms.pic, "DK-chuyudak_1.jpg"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(_vm.forms, "pic", "DK-chuyudak_1.jpg")
                        },
                        _vm.getBannerVal,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "https://pic.touduila.com/DK-%E5%87%8F%E7%A2%B3%E5%9B%9E%E6%94%B61.jpg",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.pic,
                        expression: "forms.pic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: {
                      type: "radio",
                      value: "DK-%E5%87%8F%E7%A2%B3%E5%9B%9E%E6%94%B61.jpg",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.forms.pic,
                        "DK-%E5%87%8F%E7%A2%B3%E5%9B%9E%E6%94%B61.jpg"
                      ),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(
                            _vm.forms,
                            "pic",
                            "DK-%E5%87%8F%E7%A2%B3%E5%9B%9E%E6%94%B61.jpg"
                          )
                        },
                        _vm.getBannerVal,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "https://pic.touduila.com/DK-%E5%85%89%E7%9B%98%E8%A1%8C%E5%8A%A8.jpg",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.pic,
                        expression: "forms.pic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: {
                      type: "radio",
                      value: "DK-%E5%85%89%E7%9B%98%E8%A1%8C%E5%8A%A8.jpg",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.forms.pic,
                        "DK-%E5%85%89%E7%9B%98%E8%A1%8C%E5%8A%A8.jpg"
                      ),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(
                            _vm.forms,
                            "pic",
                            "DK-%E5%85%89%E7%9B%98%E8%A1%8C%E5%8A%A8.jpg"
                          )
                        },
                        _vm.getBannerVal,
                      ],
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.forms.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "status", $$v)
                        },
                        expression: "forms.status",
                      },
                    },
                    _vm._l(_vm.communityList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.region, value: obj.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticClass: "filter-item",
                  attrs: { label: "审核方式", prop: "auditMethod" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "审核方式" },
                      model: {
                        value: _vm.forms.auditMethod,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "auditMethod", $$v)
                        },
                        expression: "forms.auditMethod",
                      },
                    },
                    _vm._l(_vm.auditMethods, function (obj) {
                      return _c("el-option", {
                        key: obj.value,
                        attrs: { label: obj.name, value: obj.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "打卡样例图" } }, [
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "https://pic.touduila.com/chuyudaka_640.jpg ",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.clockPic,
                        expression: "forms.clockPic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: { type: "radio", value: "chuyudaka_640.jpg" },
                    domProps: {
                      checked: _vm._q(_vm.forms.clockPic, "chuyudaka_640.jpg"),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(
                            _vm.forms,
                            "clockPic",
                            "chuyudaka_640.jpg"
                          )
                        },
                        _vm.getClockVal,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "https://pic.touduila.com/db01f607a249bfb931dea2d01401eff(1).jpg",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.clockPic,
                        expression: "forms.clockPic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: {
                      type: "radio",
                      value: "db01f607a249bfb931dea2d01401eff(1).jpg",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.forms.clockPic,
                        "db01f607a249bfb931dea2d01401eff(1).jpg"
                      ),
                    },
                    on: {
                      change: [
                        function ($event) {
                          _vm.$set(
                            _vm.forms,
                            "clockPic",
                            "db01f607a249bfb931dea2d01401eff(1).jpg"
                          )
                        },
                        _vm.getClockVal,
                      ],
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [
                  _c("img", {
                    staticStyle: { width: "100%", height: "170px" },
                    attrs: {
                      src: "http://pic.touduila.com/FgtNo7PQRjN-jCfWUg2ZIZFhz01h",
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.forms.clockPic,
                        expression: "forms.clockPic",
                      },
                    ],
                    staticClass: "checkbox",
                    attrs: {
                      type: "radio",
                      value: "FgtNo7PQRjN-jCfWUg2ZIZFhz01h",
                    },
                    domProps: {
                      checked: _vm._q(
                        _vm.forms.clockPic,
                        "FgtNo7PQRjN-jCfWUg2ZIZFhz01h"
                      ),
                    },
                    on: {
                      change: [
                        function ($event) {
                          return _vm.$set(
                            _vm.forms,
                            "clockPic",
                            "FgtNo7PQRjN-jCfWUg2ZIZFhz01h"
                          )
                        },
                        _vm.getClockVal,
                      ],
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动说明", prop: "des" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.forms.des,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "des", $$v)
                      },
                      expression: "forms.des",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saves } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }