import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "D:\\workspace\\toudui-admin\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\workspace\\toudui-admin\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\workspace\\toudui-admin\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\workspace\\toudui-admin\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/index.scss'; // global css

import App from "./App";
import router from "./router";
import store from "./store";
import _i18n from "./lang"; // Internationalization

import "./icons"; // icon

import "./errorLog"; // error log

import "./permission"; // permission control

import VueQuillEditor from 'vue-quill-editor'; // require styles 引入样式

import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as filters from "./filters"; // global filters

import { hasBtnPermission } from "./utils/permission";
import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
Vue.use(Element, {
  size: 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
}); // register global utility filters.

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.hasPerm = hasBtnPermission;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});