import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import waves from '@/directive/waves'; // Waves directive

import service from '@/api/service';
import aiService from '@/api/aipic';
export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      formLabelWidth: '160px',
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      total: 0,
      list: [],
      streets: [],
      // 街道列表
      yongtu: [{
        label: "乱丢包",
        val: 1
      }, {
        label: "混乱投放",
        val: 2
      }],
      name: null,
      //临时存放
      names: [],
      //管理员
      tagList: [],
      // 图像标签
      dialogAddOrUpdateFormVisible: false,
      rules: {
        fileDir: [{
          required: true,
          message: "FTP目录是必填项"
        }],
        position: [{
          required: true,
          message: "场景位置是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必填项"
        }]
      },
      form: {
        id: "",
        fileDir: "",
        name: '',
        cameraUsage: '',
        position: "",
        street: "",
        ruleLabel: "" // 标签 42,厨余袋,0.935;43,厨余垃圾,0.835;

      },
      tableKey: 0,
      tableTags: [] // 分成一张tags标签

    };
  },
  watch: {
    "form.street": function formStreet(val) {
      console.log(val);

      if (val) {
        this.getRecyclerByStreet(val);
      }
    },
    name: function name(val) {
      console.log(val);

      if (val) {
        this.form.name = val.join(",");
      } else {
        this.form.name = '';
      }
    }
  },
  created: function created() {
    this.getList();
    this.getJiedao();
    this.getTags();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      service.ruleSetList(this.listQuery).then(function (res) {
        console.log(res.data);
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    add: function add() {
      var _this2 = this;

      Object.keys(this.form).forEach(function (key) {
        _this2.form[key] = '';
      });
      this.tableTags = [];
      this.dialogAddOrUpdateFormVisible = true;
    },
    getRecyclerByStreet: function getRecyclerByStreet(street) {
      var _this3 = this;

      aiService.getRecyclerByStreet({
        street: street
      }).then(function (res) {
        _this3.names = res.data.data;
      });
    },
    edit: function edit(row) {
      var _this4 = this;

      Object.keys(this.form).forEach(function (key) {
        _this4.form[key] = row[key];
      });
      this.name = [];
      this.getRecyclerByStreet(row.street);
      this.tableTags = [];
      this.form.ruleLabel.split(";").forEach(function (item, index) {
        if (item != "") {
          var insertRow = {};
          insertRow.id = parseInt(item.split(",")[0]); // 此id类型必须和下拉列表类型保持一致，否则只显示value

          insertRow.label = item.split(",")[1];
          insertRow.sim = item.split(",")[2];

          _this4.tableTags.push(insertRow);
        }
      });

      if (this.form.name) {
        this.name = this.form.name.split(",");
      } //console.log(this.tagList);
      //console.log(this.tableTags);


      this.dialogAddOrUpdateFormVisible = true;
    },
    del: function del(row) {
      var _this5 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.deleteRule(row.id).then(function (res) {
          if (res.data.error) {
            _this5.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this5.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this5.getList();
        });
      }).catch(function () {});
    },
    sure: function sure() {
      var _this6 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this6.tableTags.length == 0) {
            _this6.$message({
              message: "识别类型标签是必填项",
              type: 'error'
            });

            return;
          }

          var flagTag = false;
          var flagSim = false;

          _this6.tableTags.forEach(function (item, index) {
            if (item.id == "" || item.id == null) {
              flagTag = true;
            }

            if (item.sim <= 0) {
              flagSim = true;
            }
          });

          if (flagTag) {
            _this6.$message({
              message: "识别类型标签是必填项",
              type: 'error'
            });

            return;
          }

          if (flagSim) {
            _this6.$message({
              message: "识别类型相似度填写有误",
              type: 'error'
            });

            return;
          }

          _this6.form.ruleLabel = "";

          _this6.tableTags.forEach(function (item, index) {
            _this6.form.ruleLabel = _this6.form.ruleLabel + item.id + ',' + item.label + ',' + item.sim + ";";
          });

          console.log(_this6.form);
          service.addOrUpdateRule(_this6.form).then(function (res) {
            if (res.data.msg == '同名场景位置已存在') {
              _this6.$message({
                message: res.data.msg,
                type: 'error'
              });
            } else {
              _this6.$message({
                message: '操作成功',
                type: 'success'
              });

              _this6.getList();

              _this6.dialogAddOrUpdateFormVisible = false;
            }
          });
        }
      });
    },
    getJiedao: function getJiedao() {
      var _this7 = this;

      service.getArtjied().then(function (res) {
        _this7.streets = res.data;
      });
    },
    getTags: function getTags() {
      var _this8 = this;

      // 获得标签
      service.getTags().then(function (res) {
        // console.log(res.data.data);
        _this8.tagList = res.data.data;
      });
    },
    addTags: function addTags() {
      // 添加标签
      //  复制基本信息
      var insertRow = {};
      insertRow.id = "";
      insertRow.label = "";
      insertRow.sim = 0;
      this.tableTags.push(insertRow);
    },
    deleteTags: function deleteTags(index, row) {
      // 删除行标签
      this.tableTags.splice(index, 1);
    },
    tagChange: function tagChange(row) {
      this.tagList.forEach(function (item, index) {
        if (row.id == item.id) {
          row.label = item.label;
        }
      });
    }
  }
};