var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                "allow-create": "",
                placeholder: "请选择活动发布终端",
              },
              model: {
                value: _vm.listQuery.source,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "source", $$v)
                },
                expression: "listQuery.source",
              },
            },
            _vm._l(_vm.listsd, function (obj) {
              return _c("el-option", {
                key: obj.type,
                attrs: { label: obj.info, value: obj.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.listQuery.source == 2,
                  expression: "listQuery.source==2",
                },
              ],
              staticClass: "filter-item",
              attrs: { placeholder: "请选择活动类型", clearable: true },
              model: {
                value: _vm.listQuery.activityType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "activityType", $$v)
                },
                expression: "listQuery.activityType",
              },
            },
            _vm._l(_vm.activityType, function (item) {
              return _c("el-option", {
                key: item.type,
                attrs: { label: item.info, value: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v("查 询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("huodong:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.showAdd },
                },
                [_vm._v("添加活动")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huodong:create")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.batchCreate },
                },
                [_vm._v("批量生成")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huodong:download")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadPdfAll },
                },
                [_vm._v("批量pdf下载")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huodong:download")
            ? _c(
                "el-button",
                {
                  staticClass: "el-button filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.downloadWordAll },
                },
                [_vm._v("批量word下载")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "活动", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发布终端", prop: "source" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        placeholder: "请选择活动发布终端",
                      },
                      model: {
                        value: _vm.form.source,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "source", $$v)
                        },
                        expression: "form.source",
                      },
                    },
                    _vm._l(_vm.listsd, function (obj) {
                      return _c("el-option", {
                        key: obj.type,
                        attrs: { label: obj.info, value: obj.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source == 2,
                      expression: "form.source == 2",
                    },
                  ],
                  attrs: { label: "活动标题" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.activityTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "activityTitle", $$v)
                      },
                      expression: "form.activityTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.source != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "活动标题" } },
                    [
                      _c("el-input", {
                        attrs: { type: "input" },
                        model: {
                          value: _vm.form.activityTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "activityTitle", $$v)
                          },
                          expression: "form.activityTitle",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.source != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "活动方式" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radio1,
                            callback: function ($$v) {
                              _vm.radio1 = $$v
                            },
                            expression: "radio1",
                          },
                        },
                        [_vm._v("签到/签退")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.radio1,
                            callback: function ($$v) {
                              _vm.radio1 = $$v
                            },
                            expression: "radio1",
                          },
                        },
                        [_vm._v("仅签到")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动时间", prop: "activityTime" } },
                [
                  _vm.form.source != 2 && _vm.radio1 == 1
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.daterange,
                          callback: function ($$v) {
                            _vm.daterange = $$v
                          },
                          expression: "daterange",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.form.source != 2 && _vm.radio1 == 2
                    ? _c("el-date-picker", {
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.dateranges,
                          callback: function ($$v) {
                            _vm.dateranges = $$v
                          },
                          expression: "dateranges",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-date-picker", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.source == 2,
                        expression: "form.source == 2",
                      },
                    ],
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.form.activityTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "activityTime", $$v)
                      },
                      expression: "form.activityTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("addrselect", {
                attrs: {
                  street: _vm.form.street,
                  community: _vm.form.community,
                  region: _vm.form.region,
                  showFloor: false,
                },
                on: { addrChange: _vm.addrChange },
              }),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source == 2,
                      expression: "form.source == 2",
                    },
                  ],
                  attrs: { label: "活动类型(可多选)" },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.activityType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "activityType", $$v)
                        },
                        expression: "form.activityType",
                      },
                    },
                    _vm._l(_vm.activityType, function (item) {
                      return _c(
                        "el-checkbox",
                        { key: item.type, attrs: { label: item.type } },
                        [_vm._v(_vm._s(item.info) + "\n\t\t\t\t\t\t")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source != 2,
                      expression: "form.source != 2",
                    },
                  ],
                  attrs: { label: "活动人数" },
                },
                [
                  _c("el-input", {
                    directives: [{ name: "Int", rawName: "v-Int" }],
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.participants,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "participants", $$v)
                      },
                      expression: "form.participants",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source == 2,
                      expression: "form.source == 2",
                    },
                  ],
                  attrs: { label: "活动人数" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择活动人数" },
                      model: {
                        value: _vm.form.participants,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "participants", $$v)
                        },
                        expression: "form.participants",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "1-30人", value: "1-30" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "20-50人", value: "20-50" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "50-70人", value: "50-70" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "70-100人", value: "70-100" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "100-150人", value: "100-150" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "150-200人", value: "150-200" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "大于200人", value: "200" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source != 2,
                      expression: "form.source != 2",
                    },
                  ],
                  attrs: { label: "活动地点", prop: "address" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.source == 2,
                      expression: "form.source == 2",
                    },
                  ],
                  attrs: { label: "活动地点" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "活动内容", prop: "activityContent" } },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.activityContent,
                        expression: "form.activityContent",
                      },
                    ],
                    staticStyle: {
                      width: "100%",
                      height: "150px",
                      padding: "10px",
                    },
                    attrs: { type: "input" },
                    domProps: { value: _vm.form.activityContent },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "activityContent",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.form.source != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "奖励积分", prop: "coin" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "Int", rawName: "v-Int" }],
                        attrs: { type: "input" },
                        model: {
                          value: _vm.form.coin,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "coin", $$v)
                          },
                          expression: "form.coin",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.source == 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "联系人/组织人员", prop: "contacts" } },
                    [
                      _c("el-input", {
                        attrs: { type: "input" },
                        model: {
                          value: _vm.form.contacts,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contacts", $$v)
                          },
                          expression: "form.contacts",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.form.source != 2
                ? _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "phone" } },
                    [
                      _c("el-input", {
                        directives: [{ name: "Int", rawName: "v-Int" }],
                        attrs: { type: "input" },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.form.source == 2 ? "活动照片" : "活动海报",
                    prop: "pic",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      style: {
                        display:
                          _vm.form.source == 2
                            ? "block"
                            : _vm.uploadPic.length >= 1
                            ? "none"
                            : "block",
                      },
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "auto-upload": false,
                        "on-change": _vm.handleChange,
                        multiple: "",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "http://pic.touduila.com/" + item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "发布状态", prop: "postStatus" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        placeholder: "请选择活动状态",
                      },
                      model: {
                        value: _vm.form.postStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "postStatus", $$v)
                        },
                        expression: "form.postStatus",
                      },
                    },
                    _vm._l(_vm.postStatuslist, function (obj) {
                      return _c("el-option", {
                        key: obj.type,
                        attrs: { label: obj.info, value: obj.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.listQuery.source == 2
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号", width: "60" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.id)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3504193272
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标题" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.activityTitle))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  369853099
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  width: "100",
                  label: "活动时间",
                  prop: "activityTime",
                  sortable: "",
                  "sort-method": function (a, b) {
                    return (
                      Date.parse(a.activityTime) - Date.parse(b.activityTime)
                    )
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("p", [
                            _vm._v("\n\t\t\t\t\t\t活动时间："),
                            _c("br"),
                            _vm._v(
                              _vm._s(scope.row.activityTime) + "\n\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("\n\t\t\t\t\t\t创建时间："),
                            _c("br"),
                            _vm._v(
                              _vm._s(scope.row.createTime) + "\n\t\t\t\t\t"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v("\n\t\t\t\t\t\t更新时间："),
                            _c("br"),
                            _vm._v(
                              _vm._s(scope.row.updateTime) + "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  370778943
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "举办地点", width: "100" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.street) +
                                "-" +
                                _vm._s(scope.row.community) +
                                "-" +
                                _vm._s(scope.row.region) +
                                "-" +
                                _vm._s(scope.row.address)
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  368508250
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系人/组织人员", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.contacts)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3579889478
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动类型" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.activityTypeInfo)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1463403918
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动人数", width: "70" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.participants)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3731289593
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "签到/签退二维码" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src:
                                "/operation/stats/getActivitySignQrcode?activityId=" +
                                scope.row.id,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2428426047
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "海报", width: "355" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.pic.split(","),
                          function (i, index) {
                            return scope.row.pic
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    margin: "5px",
                                  },
                                  attrs: {
                                    src: "http://pic.touduila.com/" + i,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgs(scope.row.pic, index)
                                    },
                                  },
                                })
                              : _vm._e()
                          }
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  537171296
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动内容", width: "310" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.activityContent)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2034719234
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发布状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.postStatusInfo)),
                          ]),
                          _vm._v(" "),
                          scope.row.postStatusInfo == "待发布"
                            ? _c(
                                "p",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editstatus(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("发布")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  291300078
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作人", width: "140" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.adminName)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4131623197
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "生成文件状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "link-type",
                              style: {
                                color:
                                  scope.row.fileStatus == 1
                                    ? "#67C23A"
                                    : "#C03639",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.fileStatus == 1
                                    ? "已生成"
                                    : "未生成"
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2606106195
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.printing(scope.row)
                                },
                              },
                            },
                            [_vm._v("导出word")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.printingPdf(scope.row)
                                },
                              },
                            },
                            [_vm._v("导出Pdf")]
                          ),
                          _vm._v(" "),
                          _vm.listQuery.source != 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看报名人数")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPerm("huodong:edit")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPerm("huodong:delete")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4076039371
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.listQuery.source != 2
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "编号" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.id)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3504193272
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动标题" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.activityTitle))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  369853099
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动方式" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.checkInType == 1
                            ? _c("span", [_vm._v("签到/签退")])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.checkInType == 2
                            ? _c("span", [_vm._v("仅签到")])
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3008713319
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动时间" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(scope.row.activityStartTime) +
                                "\n\t\t\t\t\t\t"
                            ),
                            _c("br"),
                            _vm._v("至\n\t\t\t\t\t\t"),
                            _c("br"),
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(scope.row.activityEndTime) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  33249965
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动小区" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(scope.row.street) +
                                "-" +
                                _vm._s(scope.row.community) +
                                "-" +
                                _vm._s(scope.row.region)
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  880140556
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动地点" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                      },
                    },
                  ],
                  null,
                  false,
                  1569047600
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "海报" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return _vm._l(
                          scope.row.pic.split(","),
                          function (i, index) {
                            return scope.row.pic
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100px",
                                    height: "100px",
                                    "margin-right": "20px",
                                  },
                                  attrs: {
                                    src: "http://pic.touduila.com/" + i,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.imgs(scope.row.pic, index)
                                    },
                                  },
                                })
                              : _vm._e()
                          }
                        )
                      },
                    },
                  ],
                  null,
                  false,
                  4047491450
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系人/组织人员" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.contacts)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3579889478
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "联系电话" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.phone)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2826390633
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "活动人数" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", { staticClass: "link-type" }, [
                            _vm._v(_vm._s(scope.row.participants)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3731289593
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "奖励积分" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.coin))])]
                      },
                    },
                  ],
                  null,
                  false,
                  713954093
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "发布状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.postStatusInfo)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2183230084
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "签到/签退二维码" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src:
                                "/operation/stats/getActivitySignQrcode?activityId=" +
                                scope.row.id,
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2428426047
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "生成文件状态" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "link-type",
                              style: {
                                color:
                                  scope.row.fileStatus == 1
                                    ? "#67C23A"
                                    : "#C03639",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  scope.row.fileStatus == 1
                                    ? "已生成"
                                    : "未生成"
                                )
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2606106195
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.listQuery.source != 2
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.view(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看报名人数")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPerm("huodong:edit")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPerm("huodong:delete")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "danger", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.del(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2791101797
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "海报", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }