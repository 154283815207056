import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    // 加载页面
    return request({
      url: '/recyclingBinsAdv/getList',
      method: 'get',
      params: params
    });
  },
  del: function del(params) {
    // 删除
    return request({
      url: '/recyclingBinsAdv/delete?unionId=' + params,
      method: 'post'
    });
  },
  queryAllRecyclingBins: function queryAllRecyclingBins() {
    // 查询设备列表
    return request({
      url: '/operation/recycling/bins/queryAllRecyclingBins',
      method: 'get'
    });
  },
  add: function add(params) {
    // 添加
    return request({
      url: '/recyclingBinsAdv/insert',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  update: function update(params) {
    return request({
      url: '/recyclingBinsAdv/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  }
};
export default Service;