import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Editor from "@/components/Editor";
import Service from '@/api/service';
import ServiceSys from '@/api/sys';
import Pagination from '@/components/Pagination';
export default {
  components: {
    Pagination: Pagination,
    Editor: Editor
  },
  data: function data() {
    return {
      datas: '',
      listLoading: false,
      list: [],
      //查询条件
      listQuery: {
        key: '',
        currentPage: 1,
        numOfPage: 20
      },
      total: 0,
      dialogVisible: false,
      isIndeterminate: true,
      jiedaohao: [],
      checkAll: false,
      jiedao: [],
      // 街道字典
      //添加公告字段
      form: {
        id: '',
        title: '',
        //标题
        street: "",
        displayStart: "",
        displayEnd: "",
        state: 0,
        //发布状态
        content: '' //文本内容

      },
      juese: [],
      formLabelWidth: '120px'
    };
  },
  watch: {
    jiedaohao: function jiedaohao(e) {
      console.log(e.join(","));
      this.form.street = e.join(",");
    }
  },
  created: function created() {
    var _this2 = this;

    this.getList(); // 街道列表

    ServiceSys.getDictData().then(function (res) {
      res.data.data.streets.forEach(function (item) {
        // 街道字典
        _this2.jiedao.push({
          "id": item.id,
          "juese": item.street
        });
      });
    });
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this3 = this;

      this.jiedaohao = [];
      this.isIndeterminate = false;

      if (val == true) {
        this.jiedao.forEach(function (item, index) {
          _this3.jiedaohao.push(item.juese);
        }); // console.log(this.unique(this.jiedaohao))
      } else {
        this.jiedaohao = [];
        this.form.street = "";
      }
    },
    // 不重复唯一数组
    unique: function unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }

      return arr;
    },
    //查询按钮
    handleFilter: function handleFilter() {
      this.getList();
    },
    //获取公告列表
    getList: function getList() {
      var _this4 = this;

      Service.getNotice(this.listQuery).then(function (res) {
        res.data.data.list.forEach(function (item, index) {
          item.content = item.content.replace(/\<img/gi, '<img style="width:100%;height:auto" ');
        });
        _this4.list = res.data.data.list;
        _this4.total = res.data.data.totalRows;
      });
    },
    //添加公告
    addNotice: function addNotice() {
      var _this5 = this;

      delete this.form.id;
      this.jiedaohao = [];
      Object.keys(this.form).forEach(function (key) {
        _this5.form[key] = '';
      });
      this.form.state = 0;
      this.dialogVisible = true;
    },
    //修改公告
    editNotice: function editNotice(row) {
      var _this6 = this;

      this.jiedaohao = [];
      Object.keys(this.form).forEach(function (key) {
        _this6.form[key] = row[key];
      });
      this.form.id = row.id;
      this.form.state = parseInt(row.state);
      var street = row.street;

      if (street != "" && street != null) {
        street.split(",").forEach(function (item, index) {
          _this6.jiedaohao.push(item);
        });
      }

      this.dialogVisible = true;
    },
    //删除公告
    delNotice: function delNotice(rows) {
      var _this7 = this;

      this.$confirm('删除公告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.deleteNotice({
          id: rows.id
        }).then(function (res) {
          _this7.$message({
            type: 'success',
            message: '已删除'
          });

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //确定添加/修改公告
    Saves: function Saves() {
      var _this8 = this;

      // console.log(this.form);
      if (this.form.title == '' || this.form.title == null) {
        this.$message({
          message: "标题是必填项",
          type: 'error'
        });
        return;
      }

      if (this.form.displayStart == '' || this.form.displayStart == null) {
        this.$message({
          message: "开始时间是必填项",
          type: 'error'
        });
        return;
      }

      if (this.form.displayEnd == '' || this.form.displayEnd == null) {
        this.$message({
          message: "结束时间是必填项",
          type: 'error'
        });
        return;
      }

      if (this.form.street == '' || this.form.street == null) {
        this.$message({
          message: "面向街道是必填项",
          type: 'error'
        });
        return;
      }

      if (this.form.content == '' || this.form.content == null) {
        this.$message({
          message: "内容是必填项",
          type: 'error'
        });
        return;
      }

      Service.addOrUpdateNotice(this.form).then(function (res) {
        _this8.$message({
          type: 'success',
          message: res.data.data
        });

        _this8.getList();

        _this8.dialogVisible = false;
      });
    },
    //限制图片大小
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this9 = this;

      var isSize = new Promise(function (resolve, reject) {
        var width = 760;
        var height = 530;

        var _URL = window.URL || window.webkitURL;

        var img = new Image();

        img.onload = function () {
          var valid = img.width <= width && img.height <= height;
          valid ? resolve() : reject();
        };

        img.src = _URL.createObjectURL(file);
      }).then(function () {
        return file;
      }, function () {
        _this9.$message.error('上传图片像素建议小于等于760*530!');

        return promise.reject();
      });
      return isSize;
    },
    //获取当前时间
    getCurrentTime: function getCurrentTime() {
      var _this = this;

      var yy = new Date().getFullYear();
      var mm = new Date().getMonth() + 1;
      var dd = new Date().getDate();
      var hh = new Date().getHours();
      var mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      var ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
      return _this.gettime;
    }
  }
};