var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.streetMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "streetMethod", $$v)
                },
                expression: "listQuery.streetMethod",
              },
            },
            _vm._l(_vm.streetMethodOptions, function (item) {
              return _c("el-option", {
                key: item.typeInfo,
                attrs: { label: item.typeInfo, value: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("noel", {
            attrs: { showFloor: false },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入手机号/姓名/订单号" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: {
                placeholder: "",
                placeholder: "请选择回收物类型",
                clearable: true,
              },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            _vm._l(_vm.goodsType, function (item) {
              return _c("el-option", {
                key: item.typeInfo,
                attrs: { label: item.typeInfo, value: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "160px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.reserveType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "reserveType", $$v)
                },
                expression: "listQuery.reserveType",
              },
            },
            _vm._l(_vm.Option, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "200px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.statisticalMethod,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "statisticalMethod", $$v)
                },
                expression: "listQuery.statisticalMethod",
              },
            },
            _vm._l(_vm.methods, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-right": "500rpx" },
            },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                  clearable: false,
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasPerm("neworderv2:select")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.getList },
                },
                [_vm._v("查询")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("neworderv2:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("neworderv2:recover")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.zizhu },
                },
                [_vm._v("自助回收订单码")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "", type: "expand", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.userAddress
                      ? _c(
                          "span",
                          {
                            staticClass: "el-tag",
                            staticStyle: {
                              "margin-left": "100px",
                              "margin-right": "200px",
                            },
                          },
                          [
                            _vm._v(
                              "下单人信息： " +
                                _vm._s(scope.row.userId) +
                                ", " +
                                _vm._s(scope.row.userName) +
                                ", " +
                                _vm._s(scope.row.userPhone) +
                                ", " +
                                _vm._s(scope.row.userAddress) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.recyclerId
                      ? _c("span", { staticClass: "el-tag" }, [
                          _vm._v(
                            "回收员信息： " +
                              _vm._s(scope.row.recyclerId) +
                              "," +
                              _vm._s(scope.row.recyclerName) +
                              "," +
                              _vm._s(scope.row.recyclerPhone) +
                              "," +
                              _vm._s(scope.row.recyclerStreet)
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "订单号",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "垃圾类型",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收重量", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weight) + "Kg")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "下单方式", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderTypeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "下单时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "回收状态",
              "class-name": "status-col",
              width: "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(scope.row.orderStateInfo) +
                        "\n\t\t\t"
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "用户照片",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.userPic
                      ? _vm._l(
                          scope.row.userPic.split(","),
                          function (i, index) {
                            return _c("img", {
                              staticStyle: { width: "160px", height: "160px" },
                              attrs: { src: "http://pic.touduila.com/" + i },
                            })
                          }
                        )
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "督导员照片",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.recyclerPic
                      ? _vm._l(
                          scope.row.recyclerPic.split(","),
                          function (i, index) {
                            return _c("img", {
                              staticStyle: { width: "160px", height: "160px" },
                              attrs: { src: "http://pic.touduila.com/" + i },
                            })
                          }
                        )
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "支付时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.completeTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.payType == 0 && scope.row.price
                      ? _c("span", [
                          _vm._v(
                            "￥" + _vm._s((scope.row.price / 100).toFixed(2))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payType == 1 && scope.row.price
                      ? _c("span", [_vm._v(_vm._s(scope.row.price) + "积分")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "额外支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.payType == 0 && scope.row.addPrice
                      ? _c("span", [
                          _vm._v(
                            "￥" + _vm._s((scope.row.addPrice / 100).toFixed(2))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payType == 1 && scope.row.addPrice
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.addPrice) + "积分"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "应支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.payType == 0 && !scope.row.addPrice
                      ? _c("span", [
                          _vm._v(
                            "￥" + _vm._s((scope.row.price / 100).toFixed(2))
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payType == 0 && scope.row.addPrice
                      ? _c("span", [
                          _vm._v(
                            "￥" +
                              _vm._s(
                                (
                                  (scope.row.price + scope.row.addPrice) /
                                  100
                                ).toFixed(2)
                              )
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payType == 1 && !scope.row.addPrice
                      ? _c("span", [
                          _vm._v(_vm._s(Number(scope.row.price)) + "积分"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.payType == 1 && scope.row.addPrice
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              Number(scope.row.price + scope.row.addPrice)
                            ) + "积分"
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "用户备注",
              "class-name": "status-col",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.comment))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "运营备注", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "备注" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.beizhuTemp, "label-position": "left" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "备注信息",
                    },
                    model: {
                      value: _vm.beizhuTemp.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.beizhuTemp, "remark", $$v)
                      },
                      expression: "beizhuTemp.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doRemark } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mapVisible, title: "地图" },
          on: {
            "update:visible": function ($event) {
              _vm.mapVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "amapContainer" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.mapVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogs, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogs = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "70%", margin: "0 auto" },
              attrs: { data: _vm.alertlist, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "垃圾类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "重量", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.weigh) + " Kg")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pic
                          ? _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: "http://pic.touduila.com/" + scope.row.pic,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "打印自助回收订单码", visible: _vm.dialoges },
          on: {
            "update:visible": function ($event) {
              _vm.dialoges = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.lists, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "打印机sn码" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.lists.sn,
                      callback: function ($$v) {
                        _vm.$set(_vm.lists, "sn", $$v)
                      },
                      expression: "lists.sn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设置打印数量" } },
                [
                  _c("el-input", {
                    directives: [{ name: "Int", rawName: "v-Int" }],
                    attrs: { type: "input" },
                    model: {
                      value: _vm.lists.printNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.lists, "printNum", $$v)
                      },
                      expression: "lists.printNum",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.shezhiprint } },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }