var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.orderstatus,
                callback: function ($$v) {
                  _vm.orderstatus = $$v
                },
                expression: "orderstatus",
              },
            },
            _vm._l(_vm.orderstatuslist, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.listQuery.payTypeInfo,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "payTypeInfo", $$v)
                },
                expression: "listQuery.payTypeInfo",
              },
            },
            _vm._l(_vm.payTypeInfo, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              model: {
                value: _vm.listQuery.payStatus,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "payStatus", $$v)
                },
                expression: "listQuery.payStatus",
              },
            },
            _vm._l(_vm.payStatus, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入订单号或用户名" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getListstatus },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("danweiorder:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "订单编号",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusFilter")(scope.row.status),
                        },
                      },
                      [_vm._v(_vm._s(scope.row.statusInfo))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收员", type: "expand" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.recycler
                      ? [
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "微信： " +
                                _vm._s(scope.row.recycler.weixinId) +
                                " "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "回收员： " + _vm._s(scope.row.recycler.name)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "电话： " + _vm._s(scope.row.recycler.phone)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "住址： " + _vm._s(scope.row.recycler.apartment)
                            ),
                          ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "垃圾类型",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.orderType))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户拍照", align: "center", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "touxiang" },
                      _vm._l(scope.row.orderPic, function (item) {
                        return _c("img", {
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src:
                              "https://wx.touduila.com/open/order/photo/" +
                              item,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dakai(scope.row.orderPic)
                            },
                          },
                        })
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收物总重", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      [
                        _vm._v(_vm._s(scope.row.weight) + "Kg "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.toogleExpand(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "总支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pay
                      ? _c("span", [_vm._v(_vm._s(scope.row.pay.fee / 100))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "额外支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pay
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.pay.otherFee / 100)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "应支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pay
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              (
                                scope.row.pay.fee / 100 -
                                scope.row.pay.otherFee / 100
                              ).toFixed(2)
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付方式", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pay
                      ? _c("span", [_vm._v(_vm._s(scope.row.pay.payTypeInfo))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "支付情况", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.payTime
                      ? _c("span", [_vm._v("已支付")])
                      : _vm._e(),
                    _vm._v(" "),
                    !scope.row.payTime
                      ? _c("span", [_vm._v("未支付")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "单位名称", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.company))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系人", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "联系电话", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "地址信息",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "用户备注",
              "class-name": "status-col",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.comment))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "运营备注",
              "class-name": "status-col",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "订单类型",
              "class-name": "status-col",
              width: "150",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.reserveTypeInfo))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "下单时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "完成时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? _c("span", [_vm._v(_vm._s(scope.row.updateTime))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("danweiorder:remark")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.beizhu(scope.row)
                              },
                            },
                          },
                          [_vm._v("备注")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1 && _vm.hasPerm("danweiorder:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 10 &&
                    !scope.row.payTime &&
                    _vm.hasPerm("danweiorder:confirm")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.Completepayment(scope.row)
                              },
                            },
                          },
                          [_vm._v("完成支付")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "备注" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.beizhuTemp, "label-position": "left" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "备注信息",
                    },
                    model: {
                      value: _vm.beizhuTemp.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.beizhuTemp, "remark", $$v)
                      },
                      expression: "beizhuTemp.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doRemark } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mapVisible, title: "地图" },
          on: {
            "update:visible": function ($event) {
              _vm.mapVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "amapContainer" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.mapVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _vm._l(_vm.imgs, function (item) {
            return _c("img", {
              attrs: {
                src: "https://wx.touduila.com/open/order/photo/" + item,
              },
            })
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogs, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogs = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "70%", margin: "0 auto" },
              attrs: { data: _vm.alertlist, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "垃圾类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "重量", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.weigh) + " Kg")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pic
                          ? _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: "http://pic.touduila.com/" + scope.row.pic,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }