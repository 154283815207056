import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/dizhi';
import tongjiService from '@/api/tongji';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import service from "../../utils/request";
export default {
  name: 'ComplexTable',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      jiedao: [{
        juese: '厨余垃圾',
        id: 100
      }, {
        juese: '其他垃圾',
        id: 101
      }, {
        juese: '可回收垃圾',
        id: 102
      }, {
        juese: '有害垃圾',
        id: 103
      }],
      formes: {
        community: '',
        region: '',
        street: ''
      },
      communityList: [],
      shequList: [],
      street: '',
      uploadPic: '',
      dialogVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      lists: {
        screenName: '',
        pic: ''
      },
      form: {
        'lan': 0,
        'lon': 0,
        'street': '',
        'city': ''
      },
      temp: {
        'lan': 0,
        'lon': 0,
        'street': ''
      },
      dialog: false,
      dialogs: false,
      jiedaohao: '',
      forms: '',
      zhanshiimg: '',
      dialoges: false
    };
  },
  created: function created() {
    this.getList();
  },
  watch: {
    jiedaohao: function jiedaohao(val) {
      if (val) {
        console.log(val);
      }
    },
    'formes.community': function formesCommunity(val) {
      var _this = this;

      if (val) {
        var streetName = this.formes.street;
        this.communityList = [];
        Service.xiaoquList({
          currentPage: 1,
          numOfPage: 10000,
          street: streetName,
          community: val
        }).then(function (res) {
          _this.communityList = res.data.list;
        });
      } else {
        var _streetName = this.formes.street;
        this.communityList = [];
        Service.xiaoquList({
          currentPage: 1,
          numOfPage: 10000,
          street: _streetName,
          community: ''
        }).then(function (res) {
          _this.communityList = res.data.list;
        });
      }
    },
    'formes.region': function formesRegion(val) {
      var _this2 = this;

      if (val) {
        var streetName = this.formes.street;
        this.communityList = [];
        Service.xiaoquList({
          currentPage: 1,
          numOfPage: 10000,
          street: streetName,
          community: val
        }).then(function (res) {
          _this2.communityList = res.data.list;
        });
      } else {}
    }
  },
  methods: {
    imgs: function imgs(data) {
      this.dialoges = true;
      this.zhanshiimg = data;
    },
    saves: function saves() {},
    communityTest: function communityTest(e) {
      console.log(e);
    },
    regionTest: function regionTest(e) {
      console.log(e);
    },
    handleRemove: function handleRemove(item) {
      var _this3 = this;

      var index = this.uploadPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        _this3.lists.pic = '';
        _this3.uploadPic = '';
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    shezhi: function shezhi(e) {
      this.$router.push({
        path: '/tongji/editstreet',
        query: {
          data: e.street
        }
      });
    },
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];

      if (!this.lists.pic.includes(response.key)) {
        this.lists.pic = response.key;
      }

      this.uploadPic = this.lists.pic;
      this.$refs.upload.clearFiles();
    },
    edit: function edit(row, e) {
      this.forms = row;
      this.street = e;
      this.dialog = true;
      console.log(this.forms);

      if (row.pic) {
        this.lists.pic = row.pic;
        this.uploadPic = row.pic;
      } else {
        this.lists.pic = '';
        this.uploadPic = '';
      }

      if (row.screenName) {
        this.lists.screenName = row.screenName;
      } else {
        this.lists.screenName = '';
      }
    },
    getList: function getList() {
      var self = this;
      self.listLoading = true;
      tongjiService.streetList(self.listQuery).then(function (response) {
        self.list = response.data.data.list;
        self.total = response.data.data.totalRows; // Just to simulate the time of the request

        self.listLoading = false;
      });
    },
    save: function save() {
      var _this4 = this;

      tongjiService.addStreet(this.form).then(function (res) {
        console.log(res);

        _this4.$message({
          message: '添加成功',
          type: 'success'
        });

        _this4.dialogVisible = false;
        _this4.form = Object.assign(_this4.temp, {});

        _this4.getList();
      });
    },
    del: function del(row) {
      var _this5 = this;

      tongjiService.deletePhoto(row.id).then(function (res) {
        _this5.$notify({
          title: '成功',
          message: '通过',
          type: 'success',
          duration: 2000
        });

        _this5.getList();
      });
    },
    Sures: function Sures() {
      var _this6 = this;

      this.forms.pic = this.lists.pic;
      this.forms.screenName = this.lists.screenName;
      tongjiService.updateScreenNameSps(this.forms).then(function (res) {
        console.log(res);

        _this6.$message({
          message: '修改成功',
          type: 'success'
        });

        _this6.dialog = false;

        _this6.getList();
      });
    }
  }
};