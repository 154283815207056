import "core-js/modules/es7.array.includes";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/dizhi';
export default {
  name: 'menpaiSelect',
  props: {
    region: String,
    louhao: String,
    danyuan: String,
    menpai: String
  },
  data: function data() {
    return {
      hasData: false,
      form: {
        louhao: '',
        danyuan: '',
        menpai: ''
      },
      list: [],
      louhaoList: [],
      danyuanList: [],
      menpaiList: []
    };
  },
  watch: {
    region: function region(val) {
      console.log(val);
      this.getXiaoqu();
    },
    louhao: function louhao(val) {
      this.form.louhao = val;
    },
    danyuan: function danyuan(val) {
      this.form.danyuan = val;
    },
    menpai: function menpai(val) {
      this.form.menpai = val;
    },
    'form.louhao': function formLouhao(val) {
      console.log(val);
      this.form.louhao = val;
      this.getDanyuanList();
      this.emit();
    },
    'form.danyuan': function formDanyuan(val) {
      console.log(val);
      this.form.danyuan = val;
      this.getDanyuanList();
      this.emit();
    },
    'form.menpai': function formMenpai(val) {
      console.log(val);
      this.form.menpai = val;
      this.emit();
    }
  },
  created: function created() {
    this.getXiaoqu();
    this.form.louhao = this.louhao;
    this.form.danyuan = this.danyuan;
    this.form.menpai = this.menpai;
  },
  methods: {
    getLoudongList: function getLoudongList() {
      var list = this.list;
      var loudongList = [];
      list.forEach(function (item) {
        if (!loudongList.includes(item.buildingNumber)) {
          loudongList.push(item.buildingNumber);
        }
      });
      this.louhaoList = loudongList;
      this.getDanyuanList();
    },
    getDanyuanList: function getDanyuanList() {
      var list = this.list;
      var louhao = this.form.louhao;
      var danyuanList = [];
      list.forEach(function (item) {
        if (!danyuanList.includes(item.unitNumber) && item.buildingNumber == louhao) {
          danyuanList.push(item.unitNumber);
        }
      });
      this.danyuanList = danyuanList;
      this.getMenpaiList();
    },
    getMenpaiList: function getMenpaiList() {
      var list = this.list;
      var louhao = this.form.louhao;
      var danyuan = this.form.danyuan;
      var menpaiList = [];
      list.forEach(function (item) {
        if (!menpaiList.includes(item.homeNumber) && item.buildingNumber == louhao && item.unitNumber == danyuan) {
          menpaiList.push(item.homeNumber);
        }
      });
      this.menpaiList = menpaiList;
    },
    emit: function emit() {
      this.$emit('menpaiChange', this.form);
    },
    // 获取街道
    getXiaoqu: function getXiaoqu() {
      var _this = this;

      var region = this.region;
      Service.getDefaultXiaoqu({
        region: region
      }).then(function (res) {
        if (res.data.success) {
          _this.list = res.data.data;

          _this.getLoudongList();
        }
      });
    }
  }
};