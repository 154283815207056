"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));

var pages = {
  "/views/huishou/geti": function viewsHuishouGeti() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/geti"));
    });
  },
  "/views/huishou/xiaoquhuishouyuan": function viewsHuishouXiaoquhuishouyuan() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/xiaoquhuishouyuan"));
    });
  },
  "/views/device/map": function viewsDeviceMap() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/map"));
    });
  },
  "/views/device/index": function viewsDeviceIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/index"));
    });
  },
  "/views/device/zhineng": function viewsDeviceZhineng() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/zhineng"));
    });
  },
  "/views/device/duihuanji": function viewsDeviceDuihuanji() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/duihuanji"));
    });
  },
  "/views/device/deviceshangbao": function viewsDeviceDeviceshangbao() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/deviceshangbao"));
    });
  },
  "/views/device/jiankong": function viewsDeviceJiankong() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/jiankong"));
    });
  },
  "/views/device/Printer": function viewsDevicePrinter() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/Printer"));
    });
  },
  "/views/device/shoplists": function viewsDeviceShoplists() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/shoplists"));
    });
  },
  "/views/device/rukulists": function viewsDeviceRukulists() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/rukulists"));
    });
  },
  "/views/device/chaoshishangbao": function viewsDeviceChaoshishangbao() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/chaoshishangbao"));
    });
  },
  // "/views/order/index":  () => import("@/views/order/index"),
  "/views/order/danweiindex": function viewsOrderDanweiindex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/order/danweiindex"));
    });
  },
  "/views/order/studentindex": function viewsOrderStudentindex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/order/studentindex"));
    });
  },
  "/views/front/price": function viewsFrontPrice() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/price"));
    });
  },
  "/views/front/users": function viewsFrontUsers() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/users"));
    });
  },
  "/views/front/danweiusers": function viewsFrontDanweiusers() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/danweiusers"));
    });
  },
  "/views/front/student": function viewsFrontStudent() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/student"));
    });
  },
  "/views/front/shanghuusers": function viewsFrontShanghuusers() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/shanghuusers"));
    });
  },
  "/views/front/lingtoudi": function viewsFrontLingtoudi() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/lingtoudi"));
    });
  },
  "/views/front/seconduser": function viewsFrontSeconduser() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/seconduser"));
    });
  },
  "/views/front/jifenmingxi": function viewsFrontJifenmingxi() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/jifenmingxi"));
    });
  },
  "/views/huishou/getikao": function viewsHuishouGetikao() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/getikao"));
    });
  },
  "/views/huishou/daka": function viewsHuishouDaka() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/daka"));
    });
  },
  "/views/front/taizhang": function viewsFrontTaizhang() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/front/taizhang"));
    });
  },
  "/views/dashboard/index": function viewsDashboardIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dashboard/index"));
    });
  },
  "/views/data/index": function viewsDataIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/data/index"));
    });
  },
  "/views/userdata/users": function viewsUserdataUsers() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/userdata/users"));
    });
  },
  "/views/userdata/homeVisit": function viewsUserdataHomeVisit() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/userdata/homeVisit"));
    });
  },
  "/views/userdata/ownerCash": function viewsUserdataOwnerCash() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/userdata/ownerCash"));
    });
  },
  "/views/dizhi/jiedao": function viewsDizhiJiedao() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dizhi/jiedao"));
    });
  },
  "/views/dizhi/shequ": function viewsDizhiShequ() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dizhi/shequ"));
    });
  },
  "/views/dizhi/xiaoqu": function viewsDizhiXiaoqu() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dizhi/xiaoqu"));
    });
  },
  "/views/huodong/huodong": function viewsHuodongHuodong() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huodong/huodong"));
    });
  },
  "/views/dati/index": function viewsDatiIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dati/index"));
    });
  },
  "/views/dati/baoming": function viewsDatiBaoming() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dati/baoming"));
    });
  },
  "/views/dati/shuoming": function viewsDatiShuoming() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dati/shuoming"));
    });
  },
  "/views/chuyu/chuyulist": function viewsChuyuChuyulist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/chuyu/chuyulist"));
    });
  },
  "/views/chuyu/chuyucepinglist": function viewsChuyuChuyucepinglist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/chuyu/chuyucepinglist"));
    });
  },
  "/views/chuyu/zhongduanchuyulist": function viewsChuyuZhongduanchuyulist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/chuyu/zhongduanchuyulist"));
    });
  },
  "/views/chuyu/jifenka": function viewsChuyuJifenka() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/chuyu/jifenka"));
    });
  },
  "/views/chuyu/jifenduihuan": function viewsChuyuJifenduihuan() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/chuyu/jifenduihuan"));
    });
  },
  "/views/shangchen/shoplist": function viewsShangchenShoplist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/shangchen/shoplist"));
    });
  },
  "/views/shangchen/duihuanlist": function viewsShangchenDuihuanlist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/shangchen/duihuanlist"));
    });
  },
  "/views/shangchen/duihuanjilist": function viewsShangchenDuihuanjilist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/shangchen/duihuanjilist"));
    });
  },
  "/views/luckdraw/index": function viewsLuckdrawIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/luckdraw/index"));
    });
  },
  "/views/luckdraw/list": function viewsLuckdrawList() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/luckdraw/list"));
    });
  },
  "/views/luckdraw/set": function viewsLuckdrawSet() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/luckdraw/set"));
    });
  },
  "/views/carbon/type": function viewsCarbonType() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/carbon/type"));
    });
  },
  "/views/carbon/list": function viewsCarbonList() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/carbon/list"));
    });
  },
  "/views/carbon/typerecord": function viewsCarbonTyperecord() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/carbon/typerecord"));
    });
  },
  "/views/tongji/zhouziliao": function viewsTongjiZhouziliao() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/zhouziliao"));
    });
  },
  "/views/management/record": function viewsManagementRecord() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/management/record"));
    });
  },
  "/views/management/ruku": function viewsManagementRuku() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/management/ruku"));
    });
  },
  "/views/management/fafang": function viewsManagementFafang() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/management/fafang"));
    });
  },
  "/views/Cloudaccount/account": function viewsCloudaccountAccount() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/Cloudaccount/account"));
    });
  },
  "/views/Cloudaccount/classification": function viewsCloudaccountClassification() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/Cloudaccount/classification"));
    });
  },
  "/views/Cloudaccount/workrecord": function viewsCloudaccountWorkrecord() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/Cloudaccount/workrecord"));
    });
  },
  "/views/tongji/shuju": function viewsTongjiShuju() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/shuju"));
    });
  },
  "/views/tongji/data": function viewsTongjiData() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/data"));
    });
  },
  "/views/tongji/zhanghaoguanli": function viewsTongjiZhanghaoguanli() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/zhanghaoguanli"));
    });
  },
  "/views/tongji/street": function viewsTongjiStreet() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/street"));
    });
  },
  "/views/tongji/editstreet": function viewsTongjiEditstreet() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/editstreet"));
    });
  },
  "/views/tongji/screensetting": function viewsTongjiScreensetting() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/screensetting"));
    });
  },
  "/views/tongji/baoming": function viewsTongjiBaoming() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/tongji/baoming"));
    });
  },
  "/views/website/userlist": function viewsWebsiteUserlist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/website/userlist"));
    });
  },
  "/views/website/newslist": function viewsWebsiteNewslist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/website/newslist"));
    });
  },
  "/views/resource/index": function viewsResourceIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/resource/index"));
    });
  },
  "/views/website/newsetting": function viewsWebsiteNewsetting() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/website/newsetting"));
    });
  },
  "/views/basicsetting/index": function viewsBasicsettingIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/basicsetting/index"));
    });
  },
  "/views/sys/sysLoginlist": function viewsSysSysLoginlist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/sysLoginlist"));
    });
  },
  "/views/banner/index": function viewsBannerIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/banner/index"));
    });
  },
  "/views/sys/sysuser_v2": function viewsSysSysuser_v2() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/sysuser_v2"));
    });
  },

  /**账号权限-账号管理 */
  "/views/sys/sysrole_v2": function viewsSysSysrole_v2() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/sysrole_v2"));
    });
  },

  /**账号权限-角色管理 */
  "/views/basicsetting/coinadjust": function viewsBasicsettingCoinadjust() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/basicsetting/coinadjust"));
    });
  },

  /**基础设置-积分调整 */
  "/views/basicsetting/coinadjustdetail": function viewsBasicsettingCoinadjustdetail() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/basicsetting/coinadjustdetail"));
    });
  },

  /**基础设置-积分调整-积分发放明细 */
  "/views/device/Actionstation": function viewsDeviceActionstation() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/Actionstation"));
    });
  },

  /**行动站列表 */
  "/views/device/Recyclableequipment": function viewsDeviceRecyclableequipment() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/Recyclableequipment"));
    });
  },

  /**安卓屏设备 */
  "/views/device/RecyclableGp": function viewsDeviceRecyclableGp() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/RecyclableGp"));
    });
  },

  /**标牌使用情况 */
  "/views/device/RecyclableGpRecord": function viewsDeviceRecyclableGpRecord() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/RecyclableGpRecord"));
    });
  },

  /**标牌使用记录 */
  "/views/jifenguize/jifenguize": function viewsJifenguizeJifenguize() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/jifenguize/jifenguize"));
    });
  },

  /**设备管理-积分规则 */
  "/views/device/deviceaccount": function viewsDeviceDeviceaccount() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/deviceaccount"));
    });
  },

  /**设备管理-硬件账号管理 */
  "/views/device/devicebanner": function viewsDeviceDevicebanner() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/devicebanner"));
    });
  },

  /**设备管理-banner设置*/
  "/views/device/deviceqr": function viewsDeviceDeviceqr() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/deviceqr"));
    });
  },

  /**设备管理-二维码设置 */
  "/views/device/deviceadv": function viewsDeviceDeviceadv() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/deviceadv"));
    });
  },

  /**设备管理-广告设置 */
  "/views/device/devicequestion": function viewsDeviceDevicequestion() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/devicequestion"));
    });
  },

  /**设备管理-维修电话设置 */
  "/views/dati/result": function viewsDatiResult() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/dati/result"));
    });
  },

  /**答题-答题记录 */
  "/views/project/index": function viewsProjectIndex() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/project/index"));
    });
  },

  /**项目数据管理-项目列表 */
  "/views/aipic/tag": function viewsAipicTag() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/tag"));
    });
  },

  /**图像识别管理-管理标签集 */
  "/views/aipic/managertag": function viewsAipicManagertag() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/managertag"));
    });
  },

  /**图像识别管理-图像管理标注 */
  "/views/aipic/verifypic": function viewsAipicVerifypic() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/verifypic"));
    });
  },

  /**图像识别管理-图像校验 */
  "/views/aipic/checkpic": function viewsAipicCheckpic() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/checkpic"));
    });
  },

  /**图像识别管理-图像复核列表 */
  "/views/device/devicedtu": function viewsDeviceDevicedtu() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/devicedtu"));
    });
  },

  /**设备管理-DTU厨余投递套件*/
  "/views/userdata/scorereport": function viewsUserdataScorereport() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/userdata/scorereport"));
    });
  },

  /**数据统计-积分查询 */
  "/views/notice/notice": function viewsNoticeNotice() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/notice/notice"));
    });
  },

  /**系统设置-公告 */
  "/views/aipic/picrecord": function viewsAipicPicrecord() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/picrecord"));
    });
  },

  /**图像识别管理-投递规范识别 */
  "/views/aipic/ruleset": function viewsAipicRuleset() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/aipic/ruleset"));
    });
  },

  /**图像识别管理-应用场景 */
  // "/views/order/neworder":  () => import("@/views/order/neworder"),/**回收订单管理-个人回收订单(新) */
  "/views/huishou/huishoudata": function viewsHuishouHuishoudata() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/huishoudata"));
    });
  },

  /**回收员管理-督导员工作数据 */
  "/views/device/deviceconfig": function viewsDeviceDeviceconfig() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/deviceconfig"));
    });
  },

  /**设备管理-智能厢房配置 */
  "/views/device/devicelog": function viewsDeviceDevicelog() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/devicelog"));
    });
  },

  /**设备管理-回收箱列表操作日志 */
  "/views/huishou/recyclertracks": function viewsHuishouRecyclertracks() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/recyclertracks"));
    });
  },

  /**回收员管理-督导员工作轨迹 */
  "/views/huishou/selftracks": function viewsHuishouSelftracks() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/huishou/selftracks"));
    });
  },

  /**回收员管理-督导员工作个人轨迹 */
  "/views/kitchensorting/place": function viewsKitchensortingPlace() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/kitchensorting/place"));
    });
  },

  /**厨余分拣场-分拣场管理 */
  "/views/kitchensorting/rfid": function viewsKitchensortingRfid() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/kitchensorting/rfid"));
    });
  },

  /**厨余分拣场-RFID管理 */
  "/views/kitchensorting/ksmain": function viewsKitchensortingKsmain() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/kitchensorting/ksmain"));
    });
  },

  /**厨余分拣场-分拣场数据统计 */
  "/views/kitchensorting/ksdetail": function viewsKitchensortingKsdetail() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/kitchensorting/ksdetail"));
    });
  },

  /**厨余分拣场-今日分拣详情 */
  "/views/kitchensorting/ksdata": function viewsKitchensortingKsdata() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/kitchensorting/ksdata"));
    });
  },

  /**厨余分拣场-分拣场管理统计数据 */
  "/views/order/neworderv2": function viewsOrderNeworderv2() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/order/neworderv2"));
    });
  },

  /**回收订单管理-个人回收订单(新第二个版本) */
  "/views/sys/fhtsysLoginlist": function viewsSysFhtsysLoginlist() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/fhtsysLoginlist"));
    });
  },

  /**账号登录日志 */
  "/views/sys/fhtsysuser_v2": function viewsSysFhtsysuser_v2() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/fhtsysuser_v2"));
    });
  },

  /**账号权限-账号管理 */
  "/views/sys/fhtsysrole_v2": function viewsSysFhtsysrole_v2() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/sys/fhtsysrole_v2"));
    });
  },

  /**账号权限-角色管理 */
  "/views/order/recoveryorder": function viewsOrderRecoveryorder() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/order/recoveryorder"));
    });
  },

  /**回收订单管理-待回收订单 */
  "/views/device/pointtype": function viewsDevicePointtype() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/pointtype"));
    });
  },

  /**设备管理-点位类型 */
  "/views/device/point": function viewsDevicePoint() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/device/point"));
    });
  },

  /**设备管理-点位管理 */
  "/views/voluntary/voluntarysetting": function viewsVoluntaryVoluntarysetting() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/voluntary/voluntarysetting"));
    });
  },

  /**志愿督导管理-设置 */
  "/views/voluntary/voluntaryok": function viewsVoluntaryVoluntaryok() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/voluntary/voluntaryok"));
    });
  }
  /**志愿督导管理-报名列表 */

};
var _default = pages;
exports.default = _default;