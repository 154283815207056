//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/deviceaccount';
import waves from '@/directive/waves'; // Waves directive

export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        accountName: "" // 账号

      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      dialogAddOrUpdateFormVisible: false,
      form: {
        id: "",
        accountName: "",
        password: ""
      },
      rules: {
        accountName: [{
          required: true,
          message: "账号是必填项"
        }],
        password: [{
          required: true,
          message: "密码是必选项"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getList(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    add: function add() {
      this.form.accountName = '', this.form.password = '', this.dialogAddOrUpdateFormVisible = true;
    },
    edit: function edit(rows) {
      this.form.id = rows.id;
      this.form.accountName = rows.accountName;
      this.form.password = rows.password;
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this2.form.id == null || _this2.form.id == '') {
            // 添加
            Service.add(_this2.form).then(function (res) {
              if (res.data.error) {
                _this2.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          } else {
            // 修改
            Service.update(_this2.form).then(function (res) {
              if (res.data.error) {
                _this2.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          }
        }
      });
    },
    del: function del(row) {
      var _this3 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.del(row.id).then(function (res) {
          if (res.data.error) {
            _this3.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this3.getList();
        });
      }).catch(function () {});
    }
  }
};