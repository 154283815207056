import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.set";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _methods;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dizhiService from '@/api/dizhi';
import qs from 'qs';
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  props: {
    dataHeight: {
      type: String,
      default: '750px'
    }
  },
  data: function data() {
    var _ref;

    return _ref = {
      total: 0,
      statuslist: [{
        name: '全部',
        value: null
      }, {
        name: '待审核',
        value: 0
      }, {
        name: '审核通过',
        value: 1
      }, {
        name: '审核不通过',
        value: 2
      }],
      datas: [{
        name: '全部',
        value: null
      }, {
        name: '居民小程序',
        value: 1
      }, {
        name: '生态城小程序',
        value: 40
      }, {
        name: '泰达投对小程序',
        value: 80
      }, {
        name: '什邡智慧分类',
        value: 70
      }],
      typeIns: [{
        'name': '全部',
        'value': ''
      }],
      communityList: [{
        region: '通过',
        id: 1
      }, {
        region: '不通过',
        id: 2
      }],
      uploadPic: [],
      formLabelWidth: '120px',
      searchStreet: null,
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: []
    }, _defineProperty(_ref, "total", 0), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      currentPage: 1,
      numOfPage: 20,
      role: null,
      status: null,
      street: '',
      typeInfo: '',
      userName: ''
    }), _defineProperty(_ref, "beizhuTemp", {
      orderId: '',
      remark: ''
    }), _defineProperty(_ref, "Options", []), _defineProperty(_ref, "Option", [{
      name: '全部',
      value: null
    }, {
      name: '待审核',
      value: 0
    }, {
      name: '已审核',
      value: 1
    }]), _defineProperty(_ref, "dialogs", false), _defineProperty(_ref, "alertlist", ''), _defineProperty(_ref, "datalist", ''), _defineProperty(_ref, "form", {
      userName: '',
      remark: '',
      coin: '',
      id: '',
      status: '',
      typeInfo: '',
      userId: ''
    }), _defineProperty(_ref, "imgsp", []), _defineProperty(_ref, "dialogVisible", false), _defineProperty(_ref, "dialogImageUrl", ''), _defineProperty(_ref, "images", ''), _defineProperty(_ref, "dialog", false), _ref;
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getLists();
  },
  methods: (_methods = {
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.dialogVisible = true;
    },
    exportlist: function exportlist() {
      this.beizhuVisibles = true;
    },
    deletes: function deletes(row) {
      var _this = this;

      this.$confirm('确定删除此条数据？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.delshenheuser({
          id: row.id
        }).then(function (res) {
          _this.$message({
            type: 'success',
            message: '已删除'
          });

          _this.getList();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }, _defineProperty(_methods, "handlePictureCardPreview", function handlePictureCardPreview(files) {
    this.dialogImageUrl = files;
    this.dialogVisible = true;
  }), _defineProperty(_methods, "inputchange", function inputchange(e) {
    console.log(e);
    var totals = 0;
    this.form.total = 0;
    e.forEach(function (item, index) {
      totals += Number(item.kitchenBins);
    });
    this.form.total = Number(totals).toFixed(1);
  }), _defineProperty(_methods, "Sures", function Sures() {
    var _this2 = this;

    console.log(this.form);
    Service.shenhemanuser(this.form).then(function (res) {
      _this2.beizhuVisible = false;

      _this2.getList();

      _this2.$notify({
        title: '成功',
        message: '审核成功',
        type: 'success',
        duration: 2000
      });
    }); // this.$notify({
    // 	title: '失败',
    // 	message: '审核',
    // 	type: 'error',
    // 	duration: 2000
    // })
  }), _defineProperty(_methods, "handleSelectionChange", function handleSelectionChange(e) {
    var _this3 = this;

    console.log(e);
    this.imgsp = [];
    e.forEach(function (item, index) {
      _this3.imgsp.push(item.id);
    });
  }), _defineProperty(_methods, "exportimg", function exportimg() {
    var _this4 = this;

    console.log(this.imgsp);

    if (this.imgsp.length == 0) {
      this.$notify({
        title: '失败',
        message: '请选择要审核的数据',
        type: 'error',
        duration: 2000
      });
    } else {
      Service.yijianshenheuser({
        ids: this.imgsp.join(','),
        status: 1
      }).then(function (res) {
        _this4.getList();

        _this4.$notify({
          title: '成功',
          message: '审核成功',
          type: 'success',
          duration: 2000
        });
      });
    }
  }), _defineProperty(_methods, "exportnotimg", function exportnotimg() {
    var _this5 = this;

    if (this.imgsp.length == 0) {
      this.$notify({
        title: '失败',
        message: '请选择要审核的数据',
        type: 'error',
        duration: 2000
      });
    } else {
      Service.yijianshenheuser({
        ids: this.imgsp.join(','),
        status: 2
      }).then(function (res) {
        _this5.getList();

        _this5.$notify({
          title: '成功',
          message: '审核成功',
          type: 'success',
          duration: 2000
        });
      });
    }
  }), _defineProperty(_methods, "getList", function getList() {
    var _this6 = this;

    // this.typeIns
    var arr = [];
    Service.getmanageuser(this.listQuery).then(function (response) {
      console.log(response);
      _this6.list = response.data.data.list;
      _this6.total = response.data.data.totalRows;
      console.log(_this6.list);
      setTimeout(function () {
        _this6.listLoading = false;
      }, 1.5 * 1000);
    });
  }), _defineProperty(_methods, "getLists", function getLists() {
    var _this7 = this;

    // this.typeIns
    var arr = [];
    Service.getmanageuser(this.listQuery).then(function (response) {
      console.log(response);
      _this7.list = response.data.data.list;
      _this7.total = response.data.data.totalRows;
      console.log(_this7.list);
      setTimeout(function () {
        _this7.listLoading = false;
      }, 1.5 * 1000);
    });
    Service.getmanageActivity(this.listQuery).then(function (response) {
      var list = response.data.data.list;
      list.forEach(function (item, index) {
        arr.push(item.typeInfo);
      });
      console.log(_this7.unique(arr));

      var newarr = _this7.unique(arr);

      newarr.forEach(function (item, index) {
        _this7.typeIns.push({
          name: item,
          value: item
        });
      });
    });
  }), _defineProperty(_methods, "unique", function unique(arr) {
    return Array.from(new Set(arr));
  }), _defineProperty(_methods, "handleFilter", function handleFilter() {
    this.getList();
  }), _defineProperty(_methods, "beizhu", function beizhu(row) {
    var _this8 = this;

    this.beizhuVisible = true;
    Object.keys(this.form).forEach(function (keys) {
      _this8.form[keys] = row[keys];
    });
    this.form.status == 0 ? this.form.status = 1 : this.form.status == 2;
    this.uploadPic = row.pic.split(',');
  }), _defineProperty(_methods, "imgs", function imgs(e, index) {
    var _this9 = this;

    this.dialog = true;
    setTimeout(function () {
      _this9.images = e.split(',');

      _this9.$refs.carousel.setActiveItem(index);
    }, 200);
  }), _methods)
};