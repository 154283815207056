import "core-js/modules/es6.regexp.split";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/service';
import noel from '@/components/street/street';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import addrselect from '@/components/street/addrselect';
import jiedao from '@/components/street/jiedao';
import { parseTime } from '@/utils';
export default {
  name: 'Users',
  components: {
    Pagination: Pagination,
    addrselect: addrselect,
    jiedao: jiedao,
    noel: noel
  },
  directives: {
    waves: waves
  },
  data: function data() {
    var _listQuery;

    return {
      juese: [{
        juese: '厨余投递',
        id: 302 + ',' + 602
      }, {
        juese: '可回收物回收',
        id: 701
      }, {
        juese: '参加活动',
        id: 905
      }],
      juesehao: [],
      exports: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: '',
        action: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      searchStreet: null,
      beizhuVisible: false,
      shows: false,
      datalist: {
        nickName: '',
        phone: '',
        address: ''
      },
      dialogFormVisible: false,
      mapVisible: false,
      editVisible: false,
      tableKey: 0,
      list: null,
      total: 0,
      increasedUser: '',
      listLoading: true,
      Options: [{
        name: '正常用户',
        value: 0
      }, {
        name: '非法用户',
        value: -1
      }, {
        name: '线下用户',
        value: 2
      }],
      datas: [{
        name: '居民小程序',
        value: 1
      }, {
        name: '生态城小程序',
        value: 40
      }, {
        name: '泰达投对小程序',
        value: 80
      }, {
        name: '什邡智慧分类',
        value: 70
      }, {
        name: '所有',
        value: -1
      }],
      form: {
        "floor": "",
        'address': '',
        'city': '滨海新区',
        'lat': 0,
        'locationId': 0,
        'lon': 0,
        'name': '',
        'org': '',
        'phone': 0,
        'province': '天津市',
        'userId': 0,
        'homeinfo': ''
      },
      addrForm: {
        'louhao': '',
        'danyuan': '',
        'menpai': ''
      },
      listQuery: (_listQuery = {
        title: '',
        // 搜索 搜索用户名字
        key: '',
        // 搜索名字 v2
        role: '-1',
        // :0=货主,1=居民版,-1=所有
        type: 0,
        // 0:正常用户;-1:非法用户2：无微信用户
        currentPage: 1,
        numOfPage: 20,
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: '',
        community: '',
        region: ''
      }, _defineProperty(_listQuery, "role", 1), _defineProperty(_listQuery, "status", 1), _listQuery),
      Relieve: {
        qrcodeGroup: ''
      }
    };
  },
  watch: {
    // time(val) {
    //   this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}')
    //   this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}')
    // },
    dialogFormVisible: function dialogFormVisible(val) {
      if (val == false) {
        this.shows = false;
        this.Relieve.zuhao = '', this.datalist = null;
      }
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.exports.street = val.street;
      }
    },
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      this.exports.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.exports.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    juesehao: function juesehao(e) {
      // console.log(e)
      this.exports.action = e.join(",");
    }
  },
  created: function created() {
    this.getList();
    this.getIncreasedUser();
  },
  methods: {
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    Sures: function Sures() {
      var _this = this;

      Service.ExportCoins(this.exports).then(function (res) {
        var content = res.data;
        var blob = new Blob([content]);
        var fileName = _this.exports.street + '积分排行' + _this.exports.startTime + '-' + _this.exports.endTime + '.xls';

        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象

          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    exportCoin: function exportCoin() {
      this.beizhuVisible = true;
      this.juesehao = [];
      this.exports.street = '';
      this.exports.action = '';
      this.searchStreet = '';
    },
    // 改变用户限制
    changeUserLocation: function changeUserLocation(row) {
      var self = this;
      var posLimited = row.posLimited === 0 ? 1 : 0; // 开关取反

      Service.updateUserLocation({
        posLimited: posLimited,
        userId: row.id
      }).then(function (res) {
        self.getList();
      });
    },
    jiefeng: function jiefeng(row) {
      var self = this;
      Service.jifengUser({
        delFlag: 0,
        userId: row.id
      }).then(function (res) {
        console.log(res);
        self.$message({
          message: '更新成功',
          type: 'success'
        });
        self.getList();
      });
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this2 = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this2.initMap(row.lon, row.lat);
      }, 500);
    },
    editUser: function editUser(row) {
      this.editVisible = true;
      this.form = row;
      var addr = row.address.split(" , ");

      if (addr.length >= 4) {
        var floor = addr[3];
        console.log(floor);

        if (floor) {
          var addr2 = floor.split("-");
          console.log(addr2);
          this.addrForm.louhao = addr2[0] || '';
          this.addrForm.danyuan = addr2[1] || '';
          this.addrForm.menpai = addr2[2] || '';
        }
      }
    },
    addrChange: function addrChange(data) {
      this.form.community = data.community;
      this.form.region = data.region;
      this.form.street = data.street;
    },
    saveAddr: function saveAddr() {
      var _this3 = this;

      this.form.locationId = this.form.id;
      this.form.userId = this.form.ownerId;
      var floor = this.addrForm.louhao + "-" + this.addrForm.danyuan + "-" + this.addrForm.menpai;
      this.form.address = this.form.street + " , " + this.form.community + " , " + this.form.region + " , " + floor;
      this.form.homeinfo = floor;
      var param = Object.assign({}, this.form);
      Service.updateLocation(param).then(function (res) {
        _this3.editVisible = false;

        _this3.$message({
          message: '更新成功',
          type: 'success'
        });

        _this3.getList();
      });
    },
    getIncreasedUser: function getIncreasedUser() {
      var _this4 = this;

      this.listLoading = true;
      Service.getIncreasedUser(this.listQuery).then(function (response) {
        _this4.increasedUser = response.data.data; // Just to simulate the time of the request

        setTimeout(function () {
          _this4.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    getList: function getList() {
      var _this5 = this;

      this.listLoading = true;
      Service.owerList(this.listQuery).then(function (response) {
        _this5.list = response.data.list;
        _this5.total = response.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this5.listLoading = false;
        }, 1.5 * 1000);
      });
      Service.getIncreasedUser(this.listQuery).then(function (response) {
        _this5.increasedUser = response.data.data;
        setTimeout(function () {
          _this5.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    handleF: function handleF() {
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作成功',
        type: 'success'
      });
      row.status = status;
    },
    handleDelete: function handleDelete(row) {
      this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success',
        duration: 2000
      });
      var index = this.list.indexOf(row);
      this.list.splice(index, 1);
    },
    exportExcel: function exportExcel() {
      // location.href = '/operation/owner/exportOwner?' + qs.stringify(this.listQuery)\
      this.$router.push({
        path: '/exports/dashboard'
      });
      Service.exportusers(this.listQuery).then(function (res) {});
    },
    exportLingtoudi: function exportLingtoudi() {
      location.href = '/operation/owner/exportZeroKitchenOwner';
    },
    // 点击确定确认用户信息
    Relievedai: function Relievedai() {
      var _this6 = this;

      Service.getUserByqrcodeQroup(this.Relieve.zuhao).then(function (res) {
        console.log(res);

        if (res.data.success == false) {
          _this6.$message.error('没有搜索到用户');

          _this6.shows = false;
        } else {
          _this6.datalist = res.data.data;
          _this6.Relieve.qrcodeGroup = res.data.data.qrcodeGroup;
          _this6.shows = true;
        }
      });
    },
    // 点击解绑
    Relieved: function Relieved() {
      var _this7 = this;

      Service.UnbindKitchenRecord(this.Relieve.qrcodeGroup).then(function (res) {
        console.log(res);

        if (res.data.error == '该厨余组号有投递记录，解绑失败') {
          _this7.$message.error('已有厨余投递，不可解绑');
        } else {
          _this7.$message({
            message: '用户解绑成功',
            type: 'success'
          });

          _this7.dialogFormVisible = false;

          _this7.getList();
        }
      });
    },
    jieChu: function jieChu() {
      this.dialogFormVisible = true;
    },
    toogleExpand: function toogleExpand(e) {
      var _this8 = this;

      console.log(e.id);
      Service.getUserTotleCoin(e.id).then(function (res) {
        _this8.$router.push({
          path: '/front/jifenmingxi',
          query: {
            data: JSON.stringify(res.data.data)
          }
        });
      });
    },
    // 督导员通过
    dudaoguo: function dudaoguo(e) {
      var _this9 = this;

      console.log(e);
      var data = {};
      data.recyclerId = e.id;
      data.status = 2;
      Service.dudaoshenhe(data).then(function (res) {
        _this9.$message({
          message: '通过成功',
          type: 'success'
        });

        _this9.getList();
      });
    },
    dudaobohui: function dudaobohui(e) {
      var _this10 = this;

      console.log(e);
      var data = {};
      data.recyclerId = e.id;
      data.status = 0;
      Service.dudaoshenhe(data).then(function (res) {
        _this10.$message({
          message: '驳回成功',
          type: 'success'
        });

        _this10.getList();
      });
    },
    dudaojin: function dudaojin(e) {
      var _this11 = this;

      var data = {};
      data.recyclerId = e.id;
      data.status = 4;
      Service.dudaoshenhe(data).then(function (res) {
        _this11.$message({
          message: '禁用成功',
          type: 'success'
        });

        _this11.getList();
      });
      console.log(e);
    },
    dudaojie: function dudaojie(e) {
      var _this12 = this;

      console.log(e);
      var data = {};
      data.recyclerId = e.id;
      data.status = 2;
      Service.dudaoshenhe(data).then(function (res) {
        _this12.$message({
          message: '解除成功',
          type: 'success'
        });

        _this12.getList();
      });
      console.log(e);
    },
    open: function open(e, row) {
      var _this13 = this;

      if (e == 1) {
        this.$confirm('此操作将通过用户申请,通过后用户可使用督导评价功能,是否通过?', '提示', {
          confirmButtonText: '通过',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this13.dudaoguo(row);
        }).catch(function () {
          _this13.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else if (e == 2) {
        this.$confirm('此操作将驳回用户申请, 是否继续?', '提示', {
          confirmButtonText: '驳回',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this13.dudaobohui(row);
        }).catch(function () {
          _this13.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else if (e == 3) {
        this.$confirm('用户将无法使用督导评价功能，是否继续禁用？', '提示', {
          confirmButtonText: '禁用',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this13.dudaojin(row);
        }).catch(function () {
          _this13.$message({
            type: 'info',
            message: '已取消'
          });
        });
      } else {
        this.$confirm('用户将重新开通并使用督导评价功能，是否解除禁用？', '提示', {
          confirmButtonText: '解除禁用',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this13.dudaojie(row);
        }).catch(function () {
          _this13.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    }
  }
};