//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import CountTo from 'vue-count-to';
export default {
  components: {
    CountTo: CountTo
  },
  data: function data() {
    return {
      detail: {
        orders: 0,
        recyclers: 0,
        registers: 0
      }
    };
  },
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      Service.homeTotal().then(function (res) {
        _this.detail = res.data;
      });
    }
  }
};