import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import ServiceSys from '@/api/sys';
import jiedao from '@/components/street/jiedao';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import addrselect from '@/components/street/addrselect';
import { parseTime } from '@/utils';
export default {
  name: 'Getihuishou',
  components: {
    Pagination: Pagination,
    addrselect: addrselect,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        '10': 'success',
        '5': 'info',
        '-1': 'danger'
      };
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '10': '合格',
        '5': '待审核',
        '-1': '拒绝'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      formLabelWidth: '',
      radio1: '',
      isIndeterminate: true,
      dialogFormVisible: false,
      // 权限设置
      jiedaohao: [],
      manageRole: 0,
      jiedaos: [],
      // 街道字典
      streetNames: '',
      // 街道拼接
      weixinId: 0,
      jiedao: [{
        juese: '管理员',
        id: 100
      }, {
        juese: '督导员/回收员',
        id: 101
      }, {
        juese: '商户',
        id: 102
      }],
      radioTreaty: null,
      searchStreet: null,
      listStreet: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      beizhuVisible: false,
      form: {
        recyclerId: '',
        comment: ''
      },
      streetObj: {
        recyclerId: '',
        street: ''
      },
      radio: '1',
      tableKey: 0,
      list: null,
      total: 0,
      loading: false,
      listLoading: true,
      listQuery: {
        key: '',
        // 搜索
        currentPage: 1,
        numOfPage: 10,
        role: -1,
        // 角色:10=商户回收员,20=小区回收员,-1=所有
        status: 0,
        street: ''
      },
      Options: [{
        name: '查询全部',
        value: 0
      }, {
        name: '待审核',
        value: 5
      }, {
        name: '通过',
        value: 10
      }, {
        name: '不通过',
        value: -1
      }],
      temp: {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        type: '',
        status: 'published'
      },
      dialogPvVisible: false,
      detailData: {},
      checkAll: false,
      rules: {
        type: [{
          required: true,
          message: 'type is required',
          trigger: 'change'
        }],
        timestamp: [{
          type: 'date',
          required: true,
          message: 'timestamp is required',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      },
      downloadLoading: false,
      editVisible: false,
      streetForm: {},
      exports: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: ''
      },
      exportsd: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}')
      },
      times: "",
      // 智能厢房设备设置开始
      dialogDeviceFormVisible: false,
      deviceManageRole: 0,
      deviceWeixinId: 0,
      deviceJiedaohao: [],
      deviceStreetNames: '',
      // 街道拼接
      isDeviceIndeterminate: true,
      deviceCheckAll: false // 智能厢房设备设置结束

    };
  },
  created: function created() {
    var _this = this;

    this.getList(); // 角色列表和街道列表

    ServiceSys.getDictData().then(function (res) {
      res.data.data.streets.forEach(function (item) {
        // 街道字典
        _this.jiedaos.push({
          "id": item.street,
          "juese": item.street
        }); //console.log(jiedaos);

      });
    });
  },
  watch: {
    time: function time(val) {
      this.exports.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.exports.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    times: function times(val) {
      var date = new Date(val);
      this.exportsd.startTime = date.getFullYear() + '-' + parseTime(date, '{mm}');
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.exports.street = val.street;
      }
    },
    listStreet: function listStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      }
    },
    jiedaohao: function jiedaohao(e) {
      this.streetNames = e.join(",");
    },
    deviceJiedaohao: function deviceJiedaohao(e) {
      // 智能厢房街道
      this.deviceStreetNames = e.join(",");
    }
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this2 = this;

      //  督导员管理设置
      this.isIndeterminate = false;

      if (val == true) {
        this.jiedaos.forEach(function (item, index) {
          _this2.jiedaohao.push(item.id);
        });
        this.streetNames = this.unique(this.jiedaohao);
      } else {
        this.jiedaohao = [];
        this.streetNames = '';
      }
    },
    handleDeviceCheckAllChange: function handleDeviceCheckAllChange(val) {
      var _this3 = this;

      //  投对智能箱房管理设置
      this.isDeviceIndeterminate = false;

      if (val == true) {
        this.jiedaos.forEach(function (item, index) {
          _this3.deviceJiedaohao.push(item.id);
        });
        this.deviceStreetNames = this.unique(this.deviceJiedaohao);
      } else {
        this.deviceJiedaohao = [];
        this.deviceStreetNames = '';
      }
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.jiedaohao.length;
    },
    agreeChange: function agreeChange(e) {
      console.log(e);

      if (e == 1) {
        this.exports.startTime = parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}');
        this.exports.endTime = parseTime(new Date(), '{y}-{m}-{d}');
      }
    },
    shngbaos: function shngbaos(add, e) {
      var _this4 = this;

      console.log(e);

      if (add) {
        this.$confirm('是否关闭回收员派单权限?', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.huishoupaidan(e).then(function (res) {
            _this4.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this4.getList();
          });
        });
      } else {
        this.$confirm('是否开启回收员派单权限?', '提示', {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.huishoupaidan(e).then(function (res) {
            _this4.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this4.getList();
          });
        });
      }
    },
    bangdai: function bangdai(add, e) {
      var _this5 = this;

      console.log(e);

      if (add) {
        this.$confirm('是否关闭回收员额外绑袋权限?', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.KitchenBag(e).then(function (res) {
            _this5.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this5.getList();
          });
        });
      } else {
        this.$confirm('是否开启回收员额外绑袋权限?', '提示', {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.KitchenBag(e).then(function (res) {
            _this5.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this5.getList();
          });
        });
      }
    },
    chenguo: function chenguo(add, e) {
      var _this6 = this;

      if (add) {
        this.$confirm('是否关闭回收员成果上传权限?', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.updatechengguo({
            resultsUpload: 0,
            weixinId: e
          }).then(function (res) {
            _this6.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this6.getList();
          });
        });
      } else {
        this.$confirm('是否开启回收员成果上传权限?', '提示', {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.updatechengguo({
            resultsUpload: 1,
            weixinId: e
          }).then(function (res) {
            _this6.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this6.getList();
          });
        });
      }
    },
    shebei: function shebei(add, e) {
      var _this7 = this;

      console.log(e);

      if (add) {
        this.$confirm('是否关闭回收员设备维护权限?', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.shebeisheshi({
            protoRole: 0,
            weixin_id: e
          }).then(function (res) {
            _this7.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this7.getList();
          });
        });
      } else {
        this.$confirm('是否开启回收员设备维护权限?', '提示', {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.shebeisheshi({
            protoRole: 1,
            weixin_id: e
          }).then(function (res) {
            _this7.$notify({
              title: '成功',
              message: res.data.data,
              type: 'success',
              duration: 2000
            });

            _this7.getList();
          });
        });
      }
    },
    Sures: function Sures() {
      var _this8 = this;

      if (this.radioTreaty == 2) {
        delete this.exports.endTime;
        this.exports.startTime = this.exportsd.startTime;
      }

      Service.exportdaka(this.exports).then(function (res) {
        var content = res.data;
        var blob = new Blob([content]);
        var fileName = _this8.exports.street + '打卡记录.xls';

        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象

          document.body.removeChild(elink);
          _this8.beizhuVisible = false;
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    },
    exportlist: function exportlist() {
      this.beizhuVisible = true;
    },
    addrChange: function addrChange(data) {
      console.log(data);
      this.streetForm.communit = data.community;
      this.streetForm.floor = data.floor;
      this.streetForm.region = data.region;
      this.streetForm.street = data.street;
    },
    getList: function getList() {
      var _this9 = this;

      this.listLoading = true;
      Service.recyclerList(this.listQuery).then(function (response) {
        var list = response.data.list;
        list.forEach(function (item) {
          item.communityList = [];
          item.regionList = [];
        });
        _this9.list = list;
        _this9.total = response.data.totalRows; // Just to simulate the time of the request

        _this9.listLoading = false;
      });
    },
    showEdit: function showEdit(row) {
      this.streetForm = row;
      console.log(row);
      this.editVisible = true;
      this.radio1 = String(row.recycleRole);
    },
    updateAddr: function updateAddr() {
      console.log(this.radio1);
      this.updateStreet(this.streetForm);
    },
    updateStreet: function updateStreet(row) {
      var _this10 = this;

      console.log(row);

      if (!row.street) {
        this.$notify({
          title: '失败',
          message: '街道不能为空',
          type: 'error',
          duration: 2000
        });
      } else {
        this.editVisible = false;
        Service.updateRecyclerStreet({
          recyclerId: row.weixinId,
          street: row.street
        }).then(function (res) {
          _this10.$notify({
            title: '成功',
            message: '街道更新成功',
            type: 'success',
            duration: 2000
          });

          _this10.getList();
        });
        Service.modifyCommunity({
          recyclerId: row.weixinId,
          community: row.communit
        }).then(function (res) {
          _this10.$notify({
            title: '成功',
            message: '社区更新成功',
            type: 'success',
            duration: 2000
          });
        });
        Service.updateRegion({
          recyclerId: row.weixinId,
          region: row.region
        }).then(function (res) {
          _this10.$notify({
            title: '成功',
            message: '小区更新成功',
            type: 'success',
            duration: 2000
          });
        });
        Service.updatedudaoyuan({
          recycleId: row.weixinId,
          recycleRole: this.radio1
        }).then(function (res) {
          _this10.$notify({
            title: '成功',
            message: '权限更新成功',
            type: 'success',
            duration: 2000
          });
        });
      }
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        importance: 1,
        remark: '',
        timestamp: new Date(),
        title: '',
        status: 'published',
        type: ''
      };
    },
    updateComment: function updateComment() {
      this.dialogPvVisible = false;
      var comment = this.form.comment;
      Service.recyclerComment(this.form).then(function (res) {
        location.reload();
      });
    },
    handleDetail: function handleDetail(row) {
      this.dialogPvVisible = true;
      this.detailData = row;
      this.form.recyclerId = row.weixinId;
      this.form.comment = row.auditComment;
    },
    tongguo: function tongguo(row) {
      var _this11 = this;

      Service.recyclerAudit({
        recyclerId: row.weixinId,
        status: 10
      }).then(function (res) {
        _this11.$notify({
          title: '成功',
          message: '通过',
          type: 'success',
          duration: 2000
        });

        _this11.getList();
      }).catch(function (err) {
        _this11.$notify({
          title: '失败',
          message: '请先为该用户选择对应的角色',
          type: 'error',
          duration: 2000
        });
      });
    },
    jujue: function jujue(row) {
      var _this12 = this;

      Service.recyclerAudit({
        recyclerId: row.weixinId,
        status: -1
      }).then(function (res) {
        _this12.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        _this12.getList();
      }).catch(function (err) {
        console.log(err);

        _this12.$notify({
          title: '失败',
          message: '请先为该用户选择对应的角色',
          type: 'error',
          duration: 2000
        });
      });
    },
    uploadImg: function uploadImg(data) {
      var self = this;
      return function (res) {
        console.log(data);
        console.log(res);
        Service.modifyPic({
          pic: res.key,
          recyclerId: data.weixinId
        }).then(function (res) {
          self.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });
          self.getList();
        });
      };
    },
    shngbao: function shngbao(e, ids) {
      var _this13 = this;

      if (!e) {
        this.$confirm('是否开启权限?', '提示', {
          confirmButtonText: '开启',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.xiugaishuju({
            binsRole: 1,
            weixin_id: ids
          }).then(function (response) {
            console.log(response);

            _this13.$message({
              type: 'success',
              message: '修改成功'
            });

            _this13.getList();
          });
        });
      } else {
        this.$confirm('是否关闭权限', '提示', {
          confirmButtonText: '关闭',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          Service.xiugaishuju({
            binsRole: 0,
            weixin_id: ids
          }).then(function (response) {
            _this13.$message({
              type: 'success',
              message: '修改成功'
            });

            _this13.getList();
          });
        });
      }
    },
    delImg: function delImg(row) {
      var _this14 = this;

      var self = this;
      this.$confirm('是否确认删除当前在照片?', '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.modifyPic({
          pic: '',
          recyclerId: row.weixinId
        }).then(function (res) {
          self.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });
          self.getList();
        });
      }).catch(function () {
        _this14.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // kaoqing(e) {
    //   console.log(e)
    //   this.$router.push({
    // path: '/huishou/kaoqing',
    // query: {
    //   id: e.weixinId,
    //       jiedao: e.street,
    //       shequ: e.communit,
    //       xiaoqu: e.region,
    //       name: e.name
    // }
    //   })
    //   // this.$router.push({name: '/huishou/kaoqing',params:{ id:e.weixinId}});
    // },
    dudaoxq: function dudaoxq(e) {
      console.log(e.weixinId);
      this.$router.push({
        path: '/front/kaoqing',
        query: {
          data: e.weixinId
        }
      });
    },
    dakaxq: function dakaxq(e) {
      console.log(e);
      this.$router.push({
        path: '/front/daka',
        query: {
          data: e.weixinId
        }
      });
    },
    setting: function setting(row) {
      var _this15 = this;

      this.dialogFormVisible = true;
      this.manageRole = row.manageRole;
      this.weixinId = row.weixinId;
      this.jiedaohao = [];

      if (row.streets == undefined || row.streets == "" || row.streets == null) {
        this.streetNames = '';
      } else {
        this.jiedaos.forEach(function (item, index) {
          row.streets.split(",").forEach(function (i, index) {
            if (item.id == i) {
              _this15.jiedaohao.push(item.id);
            }
          });
        });
      }
    },
    deviceSetting: function deviceSetting(row) {
      var _this16 = this;

      // 智能厢房街道及开关
      this.dialogDeviceFormVisible = true;
      this.deviceManageRole = row.deviceManageRole;
      this.deviceWeixinId = row.weixinId;
      this.deviceJiedaohao = [];

      if (row.deviceStreets == undefined || row.deviceStreets == "" || row.deviceStreets == null) {
        this.deviceStreetNames = '';
      } else {
        this.jiedaos.forEach(function (item, index) {
          row.deviceStreets.split(",").forEach(function (i, index) {
            if (item.id == i) {
              _this16.deviceJiedaohao.push(item.id);
            }
          });
        });
      }

      console.log(this.deviceManageRole + "，" + this.deviceWeixinId + "," + this.deviceStreetNames);
    },
    save: function save() {
      var self = this;
      Service.manageRole({
        "weixinId": this.weixinId,
        "manageRole": this.manageRole,
        "streets": this.streetNames
      }).then(function (res) {
        console.log(res);
        self.$message({
          type: 'success',
          message: '修改成功!',
          onClose: function onClose() {
            self.getList();
            self.dialogFormVisible = false;
          }
        });
      });
    },
    saveDevice: function saveDevice() {
      var self = this; // console.log({"weixinId":this.deviceWeixinId,"deviceManageRole":this.deviceManageRole,"deviceStreets":this.deviceStreetNames});

      Service.deviceManageRole({
        "weixinId": this.deviceWeixinId,
        "deviceManageRole": this.deviceManageRole,
        "deviceStreets": this.deviceStreetNames
      }).then(function (res) {
        console.log(res);
        self.$message({
          type: 'success',
          message: '修改成功!',
          onClose: function onClose() {
            self.getList();
            self.dialogDeviceFormVisible = false;
          }
        });
      });
    }
  }
};