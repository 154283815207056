import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addrselect from '@/components/street/noeladdstreet';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import noel from '@/components/street/street';
import actionService from '@/api/actionstation';
import waves from '@/directive/waves'; // waves directive

var map = null;
export default {
  components: {
    addrselect: addrselect,
    Pagination: Pagination,
    noel: noel
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      standbyPics: [],
      // 待机图片
      bannerPics: [],
      // banner图片
      marker: '',
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: '',
        community: '',
        region: '',
        streetId: '',
        key: ''
      },
      total: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      form: {
        id: "",
        mbId: '',
        deviceName: '',
        street: "",
        streetId: '',
        communityId: '',
        community: "",
        regionId: '',
        region: "",
        siteId: '',
        phone: '',
        homepage: '',
        subpage: '',
        resourcepage: '',
        labelBegin: '',
        qrCode: '',
        nameText: '',
        phoneText: '',
        stationNameText: '',
        createTimeText: '',
        weightText: '',
        estimateText: "",
        // 打印机参数-预估金额
        actionPrint: {
          // 修改提交封装数据
          labelBegin: '',
          qrCode: '',
          nameText: '',
          phoneText: '',
          stationNameText: '',
          createTimeText: '',
          weightText: '',
          estimateText: ""
        },
        equipmentPic: '',
        standbyPic: '',
        bannerPic: '',
        rank: '',
        bind: '',
        print: '',
        state: '',
        weightPic: "",
        // 是否称重拍照
        stickerPoints: 0,
        ruleDisplay: ""
      },
      formLabelWidth: '175px',
      listLoading: true,
      list: [],
      stree: '',
      puts: {
        pass: '',
        id: '',
        Jid: ''
      },
      po: '',
      Xiupass: {
        pass: '',
        id: '',
        Jid: ''
      },
      Visible: false,
      mpas: '',
      lng: '',
      lat: '',
      rules: {
        mbId: [{
          required: true,
          message: "设备编码是必填项"
        }],
        deviceName: [{
          required: true,
          message: "设备名称是必选项"
        }],
        siteId: [{
          required: true,
          message: "关联行动站是必选项"
        }],
        phone: [{
          required: true,
          message: "联系电话是必填项"
        }],
        homepage: [{
          required: true,
          message: "账户退出系统时间是必选项"
        }],
        subpage: [{
          required: true,
          message: "其他页面返回时间是必选项"
        }],
        resourcepage: [{
          required: true,
          message: "资源回收页面返回时间"
        }],
        labelBegin: [{
          required: true,
          message: "打印机参数-纸张大小是必填项"
        }],
        qrCode: [{
          required: true,
          message: "打印机参数-二维码位置是必选项"
        }],
        nameText: [{
          required: true,
          message: "打印机参数-姓名是必选项"
        }],
        phoneText: [{
          required: true,
          message: "打印机参数-电话是必填项"
        }],
        stationNameText: [{
          required: true,
          message: "打印机参数-行动站是必选项"
        }],
        createTimeText: [{
          required: true,
          message: "打印机参数-下单时间是必选项"
        }],
        weightText: [{
          required: true,
          message: "打印机参数-称重拍照是必选项"
        }],
        estimateText: [{
          required: true,
          message: "打印机参数-预估金额是必选项"
        }],
        rank: [{
          required: true,
          message: "请选择排行榜"
        }],
        bind: [{
          required: true,
          message: "请选择回收标牌"
        }],
        print: [{
          required: true,
          message: "请选择回收打印机"
        }],
        state: [{
          required: true,
          message: "请选择状态"
        }],
        weightPic: [{
          required: true,
          message: "请选择是否称重拍照"
        }],
        stickerPoints: [{
          required: true,
          message: "请输入打印扣积分"
        }]
      },
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      kong: '',
      uploadPic: [],
      sites: [],
      //行动站列表
      waitPics: ['https://pic.touduila.com/huishou_banner3.jpg', 'https://pic.touduila.com/xianxuanxing.jpg', 'https://pic.touduila.com/huishou_banner1%20.jpg', 'https://pic.touduila.com/xd_banner.jpg', 'https://pic.touduila.com/taida_banner_2.jpg', 'https://pic.touduila.com/taida_banner_1.jpg']
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      actionService.gpManageList(this.listQuery).then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.rowsCount;
        _this.listLoading = false;
      });
    },
    getChangeSite: function getChangeSite(e) {
      console.log(e);

      for (var item in this.sites) {
        if (this.sites[item].uuid == e) {
          //console.log(this.sites[item])
          this.form.street = this.sites[item].street;
          this.form.community = this.sites[item].community;
          this.form.region = this.sites[item].region; //console.log(e.street);
        }
      }
    },
    del: function del(row) {
      var _this2 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        actionService.gpManageDelete(row.mbId).then(function (res) {
          if (res.data.error) {
            _this2.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.getList();
        });
      }).catch(function () {});
    },
    uploadImg: function uploadImg(response, file, fileList) {
      this.uploadPic = [];
      fileList = [];

      if (!this.uploadPic.includes(response.key)) {
        this.uploadPic.push(response.key);
      }

      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var index = this.uploadPic.indexOf(item);

      if (index > -1) {
        this.uploadPic.splice(index, 1);
      }
    },
    addrChange: function addrChange(data) {
      //console.log(data)
      this.addr.street = data.street; //this.form.street = data.street
      //this.form.streetId = data.streetId

      if (data.community) {
        this.addr.community = data.community; //this.form.community = data.community;
        //this.form.communityId = data.communityId
      } else {
        this.addr.community = ''; //this.form.community = ""
        //this.form.communityId = ''
      }

      if (data.region) {
        this.addr.region = data.region; //this.form.region = data.region
        //this.form.regionId = data.regionId
      } else {
        this.addr.region = ''; //this.form.region = ''
        //this.form.regionId = ''
      }
    },
    Begin: function Begin(Jid) {
      //console.log(Jid)
      this.form.streetId = Jid;
    },
    Addz: function Addz() {
      var _this3 = this;

      if (this.standbyPics.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择待机图片',
          type: 'error',
          duration: 2000
        });
        return false;
      } //console.log(this.bannerPics);


      if (this.bannerPics.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择banner图片',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // updateTime: 2021-01-08 15:53:56
          // createTime:
          //console.log(this.form);
          if (_this3.uploadPic.length != 0) {
            _this3.form.equipmentPic = _this3.uploadPic[0];
          } else {
            _this3.form.equipmentPic = "";
          }

          console.log(_this3.standbyPics);

          if (_this3.standbyPics.length != 0) {
            _this3.form.standbyPic = _this3.standbyPics.join(";");
          } else {
            _this3.form.standbyPic = "";
          }

          console.log(_this3.bannerPics);

          if (_this3.bannerPics.length != 0) {
            _this3.form.bannerPic = _this3.bannerPics[0];
          } else {
            _this3.form.bannerPic = "";
          }

          _this3.form.actionPrint.labelBegin = _this3.form.labelBegin;
          _this3.form.actionPrint.qrCode = _this3.form.qrCode;
          _this3.form.actionPrint.nameText = _this3.form.nameText;
          _this3.form.actionPrint.phoneText = _this3.form.phoneText;
          _this3.form.actionPrint.stationNameText = _this3.form.stationNameText;
          _this3.form.actionPrint.createTimeText = _this3.form.createTimeText;
          _this3.form.actionPrint.weightText = _this3.form.weightText;
          _this3.form.actionPrint.estimateText = _this3.form.estimateText;
          console.log(_this3.form);
          actionService.gpManageUpdate(_this3.form).then(function (res) {
            if (res.data.error) {
              _this3.$message({
                message: res.data.error,
                type: 'error'
              });
            } else {
              _this3.$message({
                message: '操作成功',
                type: 'success'
              });

              _this3.dialogFormVisible = false;

              _this3.getList();
            }
          });
        }
      });
    },
    getJiedao: function getJiedao() {
      var _this4 = this;

      Service.getArtjied().then(function (res) {
        _this4.stree = res.data;
      });
    },
    Street: function Street(Jid) {
      var _this5 = this;

      this.puts.Jid = Jid; //console.log(this.puts)

      Service.updateJied(this.puts).then(function (res) {
        if (res.status == 200) {
          _this5.$message({
            message: '修改成功',
            type: 'success'
          });

          _this5.getList();
        }
      });
    },
    Streets: function Streets() {
      console.log(this.form);
    },
    Str: function Str(id, pass) {
      this.puts.id = id;
      this.puts.pass = pass;
    },
    edit: function edit(rows) {
      var _this6 = this;

      // 行动站列表-不分页
      actionService.xdzManageInformation().then(function (res) {
        _this6.sites = res.data.data;
      });
      this.uploadPic = [];
      this.standbyPics = [];
      this.bannerPics = [];
      console.log(rows);
      this.form.id = rows.id;
      this.form.mbId = rows.mbId;
      this.form.deviceName = rows.deviceName;
      this.form.siteId = rows.siteId;
      this.form.street = rows.street;
      this.form.community = rows.community;
      this.form.region = rows.region;
      this.form.phone = rows.phone;
      this.form.homepage = rows.homepage;
      this.form.subpage = rows.subpage;
      this.form.resourcepage = rows.resourcepage;
      this.form.rank = rows.rank;
      this.form.bind = rows.bind;
      this.form.print = rows.print;
      this.form.state = rows.state;
      this.form.weightPic = rows.weightPic;

      if (rows.actionPrint != null && rows.actionPrint != '') {
        this.form.labelBegin = rows.actionPrint.labelBegin;
        this.form.qrCode = rows.actionPrint.qrCode;
        this.form.nameText = rows.actionPrint.nameText;
        this.form.phoneText = rows.actionPrint.phoneText;
        this.form.stationNameText = rows.actionPrint.stationNameText;
        this.form.createTimeText = rows.actionPrint.createTimeText;
        this.form.weightText = rows.actionPrint.weightText;
        this.form.estimateText = rows.actionPrint.estimateText;
      } else {
        this.form.labelBegin = "400,300";
        this.form.qrCode = "36,95,3";
        this.form.nameText = "150,95,1,0"; // 订单id

        this.form.phoneText = "20,50,1,0";
        this.form.stationNameText = "15,225,1,0";
        this.form.createTimeText = "15,260,1,0";
        this.form.weightText = "150,140,1,0";
        this.form.estimateText = "150,185,1,0";
      }

      this.form.equipmentPic = rows.equipmentPic;
      this.form.standbyPic = rows.standbyPic;
      this.form.bannerPic = rows.bannerPic;

      if (this.form.equipmentPic != null && this.form.equipmentPic != '') {
        this.uploadPic.push(this.form.equipmentPic);
      }

      if (this.form.standbyPic != null && this.form.standbyPic != '') {
        for (var i = 0; i < this.form.standbyPic.split(";").length; i++) {
          this.waitPics.forEach(function (element) {
            // 此代码防止 图片从前台删除，但数据库还保存着此图片。过滤掉数据中存在的图片
            if (element == _this6.form.standbyPic.split(";")[i]) {
              _this6.standbyPics.push(_this6.form.standbyPic.split(";")[i]);
            }
          });
        }

        console.log(this.standbyPics);
      } //standbyPics:[],// 待机图片
      //bannerPics:[],// banner图片


      if (this.form.bannerPic != null && this.form.bannerPic != '') {
        for (var i = 0; i < this.form.bannerPic.split(";").length; i++) {
          this.bannerPics.push(this.form.bannerPic.split(";")[i]);
        }
      }

      this.form.stickerPoints = rows.stickerPoints;
      this.form.ruleDisplay = rows.ruleDisplay; //console.log(this.bannerPics);

      this.dialogFormVisible = true; //console.log(this.form);
    }
  },
  update: function update() {
    this.Street(Jid);
    this.del(rows);
    this.getJiedao();
  }
};