var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "panel-group", attrs: { gutter: 40 } },
    [
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 8 } },
        [
          _c("div", { staticClass: "card-panel" }, [
            _c(
              "div",
              { staticClass: "card-panel-icon-wrapper icon-people" },
              [
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "peoples",
                    "class-name": "card-panel-icon",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-panel-description" },
              [
                _c("div", { staticClass: "card-panel-text" }, [
                  _vm._v("注册人数"),
                ]),
                _vm._v(" "),
                _c("count-to", {
                  staticClass: "card-panel-num",
                  attrs: {
                    "start-val": 0,
                    "end-val": _vm.detail.registers,
                    duration: 2600,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 8 } },
        [
          _c("div", { staticClass: "card-panel" }, [
            _c(
              "div",
              { staticClass: "card-panel-icon-wrapper  icon-people" },
              [
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "peoples",
                    "class-name": "card-panel-icon",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-panel-description" },
              [
                _c("div", { staticClass: "card-panel-text" }, [
                  _vm._v("回收员人数"),
                ]),
                _vm._v(" "),
                _c("count-to", {
                  staticClass: "card-panel-num",
                  attrs: {
                    "start-val": 0,
                    "end-val": _vm.detail.recyclers,
                    duration: 3200,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-col",
        { staticClass: "card-panel-col", attrs: { xs: 12, sm: 12, lg: 8 } },
        [
          _c("div", { staticClass: "card-panel" }, [
            _c(
              "div",
              { staticClass: "card-panel-icon-wrapper icon-shopping" },
              [
                _c("svg-icon", {
                  attrs: {
                    "icon-class": "shopping",
                    "class-name": "card-panel-icon",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "card-panel-description" },
              [
                _c("div", { staticClass: "card-panel-text" }, [
                  _vm._v("订单量"),
                ]),
                _vm._v(" "),
                _c("count-to", {
                  staticClass: "card-panel-num",
                  attrs: {
                    "start-val": 0,
                    "end-val": _vm.detail.orders,
                    duration: 3600,
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }