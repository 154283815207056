var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("span", { staticStyle: { "margin-right": "40rpx" } }, [
                _vm._v("回收时间"),
              ]),
              _vm._v(" "),
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("街道")]),
          _vm._v(" "),
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.searchStreet,
              callback: function ($$v) {
                _vm.searchStreet = $$v
              },
              expression: "searchStreet",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("状态")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.Option, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getList },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("shuju:exportexcel")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportlist()
                    },
                  },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("shuju:exportpic")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportimg },
                },
                [_vm._v("导出图片")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("shuju:pass")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.successlist },
                },
                [_vm._v("一键通过")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收时间", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(scope.row.submitTime))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上报时间", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上报人", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.phone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属街道", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "合计回收", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.total))]),
                    _vm._v(" "),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("(桶)"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.status
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("待审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("已审核")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("shuju:check")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.beizhu(scope.row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.beizhuVisible,
            title: _vm.form.street + "厨余垃圾回收数据审核",
          },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上报时间",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.createTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "createTime", $$v)
                      },
                      expression: "form.createTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "上报人", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电话", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "回收时间",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: { type: "datetime", placeholder: "选择日期时间" },
                    model: {
                      value: _vm.form.submitTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "submitTime", $$v)
                      },
                      expression: "form.submitTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据填写",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.form.street) +
                        " " +
                        _vm._s(_vm.form.community) +
                        " "
                    ),
                    _vm.form.kitchenBinsInfo.length == 1
                      ? _c("span", [
                          _vm._v(_vm._s(_vm.form.kitchenBinsInfo[0].region)),
                        ])
                      : _vm._e(),
                    _vm.form.kitchenBinsInfo.length > 1
                      ? _c("span", [_vm._v("全部小区")])
                      : _vm._e(),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据填写",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                _vm._l(_vm.arrs, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "margin-bottom": "10px" },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "text-align": "center",
                            display: "inline-block",
                            margin: "0 10px",
                          },
                        },
                        [
                          _vm.commid
                            ? _c("span", [_vm._v(_vm._s(item.community))])
                            : _vm._e(),
                          !_vm.commid
                            ? _c("span", [_vm._v(_vm._s(_vm.form.community))])
                            : _vm._e(),
                          _vm._v("-" + _vm._s(item.region)),
                        ]
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticStyle: { flex: "0.5", "text-align": "center" },
                        attrs: { autocomplete: "off" },
                        on: {
                          input: function ($event) {
                            return _vm.inputchange(_vm.form.kitchenBinsInfo)
                          },
                        },
                        model: {
                          value: item.kitchenBins,
                          callback: function ($$v) {
                            _vm.$set(item, "kitchenBins", $$v)
                          },
                          expression: "item.kitchenBins",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: { flex: "0.1", "text-align": "center" },
                        },
                        [_vm._v("桶")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "合计", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "40%" },
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.form.total,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "total", $$v)
                          },
                          expression: "form.total",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticStyle: { flex: "0.5", margin: "0 10px" } },
                        [_vm._v("桶")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上报图片",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src:
                                "https://wx.touduila.com/open/order/photo/" +
                                item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.beizhuVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src:
                "https://wx.touduila.com/open/order/photo/" +
                _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisibles, title: "备注" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisibles = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c("el-form-item", { attrs: { label: "请选择统计时间" } }, [
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: { type: "month", placeholder: "选择月" },
                      model: {
                        value: _vm.times,
                        callback: function ($$v) {
                          _vm.times = $$v
                        },
                        expression: "times",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.beizhuVisibles = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exports } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }