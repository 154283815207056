var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { placeholder: "请选择街道" },
              on: { change: _vm.streetChange },
              model: {
                value: _vm.listQuery.street,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "street", $$v)
                },
                expression: "listQuery.street",
              },
            },
            _vm._l(_vm.streets, function (i) {
              return _c("el-option", {
                key: i.street,
                attrs: { label: i.street, value: i.street },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入手机号/姓名" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.year,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "year", $$v)
                },
                expression: "listQuery.year",
              },
            },
            _vm._l(_vm.yearOptions, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.month,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "month", $$v)
                },
                expression: "listQuery.month",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm.hasPerm("huishoudata:select")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.getList()
                    },
                  },
                },
                [_vm._v(" 查 询")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huishoudata:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportlist()
                    },
                  },
                },
                [_vm._v(" 导出")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huishoudata:lotsend")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.lotsend()
                    },
                  },
                },
                [_vm._v(" 一键发放")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("huishoudata:setting")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.settingBaseWork()
                    },
                  },
                },
                [_vm._v(" 设置基础工作量")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "ID", align: "center", width: "45" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.name))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.phone))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "入职时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.entryTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "出勤天数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.workNumber) + "天")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "正常打卡-(2)", "render-header": _vm.renderheader },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.num) + "次")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "注册人数-(3)", "render-header": _vm.renderheader },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.registerNum == null
                            ? 0
                            : scope.row.registerNum
                        ) + "人"
                      ),
                    ]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("(" + _vm._s(_vm.settingform.registerNum) + "人)"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "厨余分类袋数-(2)",
              "render-header": _vm.renderheader,
              width: "110",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.kitchenNum) + "袋")]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("(" + _vm._s(_vm.settingform.kitchenNum) + "袋)"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上门拜访-(1)", "render-header": _vm.renderheader },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.visitNum) + "次")]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("(" + _vm._s(_vm.settingform.visitNum) + "次)"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分核销-(2)", "render-header": _vm.renderheader },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.exchangeNum) + "次")]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("(" + _vm._s(_vm.settingform.exchangeNum) + "次)"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "可回收物回收-(单数3,重量2)",
              "render-header": _vm.renderheader,
              width: "125",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.recyclerNum) +
                          "单(" +
                          _vm._s(scope.row.recyclerWeight) +
                          "kg)"
                      ),
                    ]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "(" + _vm._s(_vm.settingform.recyclerWeight) + "kg)"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "有害垃圾回收-(单数3,重量2)",
              "render-header": _vm.renderheader,
              width: "125",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.harmfulNum) +
                          "单(" +
                          _vm._s(scope.row.harmfulWeight) +
                          "kg)"
                      ),
                    ]),
                    _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "(" + _vm._s(_vm.settingform.harmfulWeight) + "kg)"
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.comment))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "绩效评分" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.systemScore) + "分")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核评分" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.auditScore != -1
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.auditScore) + "分"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发放状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: scope.row.status == 0 ? "#C03639" : "#67C23A",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(scope.row.status == 0 ? "待发放" : "已发放")
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              width: "150",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("huishoudata:check") && scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.check(scope.row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("huishoudata:check") && scope.row.status == 1
                      ? _c(
                          "el-button",
                          { attrs: { size: "mini", type: "info" } },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("huishoudata:send") && scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.send(scope.row)
                              },
                            },
                          },
                          [_vm._v("发放")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("huishoudata:send") && scope.row.status == 1
                      ? _c(
                          "el-button",
                          { attrs: { size: "mini", type: "info" } },
                          [_vm._v("发放")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "设置基础工作量",
            visible: _vm.dialogSettingFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSettingFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "settingform",
              attrs: {
                model: _vm.settingform,
                rules: _vm.rules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "注册人数",
                    "label-width": _vm.formLabelWidth,
                    prop: "registerNum",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.registerNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "registerNum", $$v)
                      },
                      expression: "settingform.registerNum",
                    },
                  }),
                  _vm._v("单位：人\n\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "厨余分类袋数",
                    "label-width": _vm.formLabelWidth,
                    prop: "kitchenNum",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.kitchenNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "kitchenNum", $$v)
                      },
                      expression: "settingform.kitchenNum",
                    },
                  }),
                  _vm._v("单位：袋\n\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上门拜访",
                    "label-width": _vm.formLabelWidth,
                    prop: "visitNum",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.visitNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "visitNum", $$v)
                      },
                      expression: "settingform.visitNum",
                    },
                  }),
                  _vm._v("单位：次\n\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "积分核销",
                    "label-width": _vm.formLabelWidth,
                    prop: "exchangeNum",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.exchangeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "exchangeNum", $$v)
                      },
                      expression: "settingform.exchangeNum",
                    },
                  }),
                  _vm._v("单位：次\n\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可回收物回收重量",
                    "label-width": _vm.formLabelWidth,
                    prop: "recyclerWeight",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.recyclerWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "recyclerWeight", $$v)
                      },
                      expression: "settingform.recyclerWeight",
                    },
                  }),
                  _vm._v("单位：kg\n\t\t\t"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "有害垃圾回收重量",
                    "label-width": _vm.formLabelWidth,
                    prop: "harmfulWeight",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.settingform.harmfulWeight,
                      callback: function ($$v) {
                        _vm.$set(_vm.settingform, "harmfulWeight", $$v)
                      },
                      expression: "settingform.harmfulWeight",
                    },
                  }),
                  _vm._v("单位：kg\n\t\t\t"),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogSettingFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.settingSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "评审绩效", visible: _vm.dialogCheckFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCheckFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "checkform",
              attrs: {
                model: _vm.checkform,
                rules: _vm.checkRules,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "绩效评分",
                    "label-width": _vm.formLabelWidth,
                    prop: "auditScore",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off", type: "number" },
                    model: {
                      value: _vm.checkform.auditScore,
                      callback: function ($$v) {
                        _vm.$set(_vm.checkform, "auditScore", $$v)
                      },
                      expression: "checkform.auditScore",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.checkform.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.checkform, "comment", $$v)
                      },
                      expression: "checkform.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogCheckFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.checkSave } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }