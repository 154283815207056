import request from '@/utils/request';
/**
 * 设备投口参数
 */

var Service = {
  getByMbId: function getByMbId(params) {
    // 根据设备查询投口参数
    return request({
      url: '/recyclingBinsMouthpiece/getByMbId?mbId=' + params,
      method: 'get'
    });
  },
  batch: function batch(params) {
    // 设备投口规则维护
    return request({
      url: '/recyclingBinsMouthpiece/batch',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updateRecyclingBinsMouthpiece: function updateRecyclingBinsMouthpiece(params) {
    // 设备投口删除
    return request({
      url: '/recyclingBinsMouthpiece/updateRecyclingBinsMouthpiece?mbId=' + params
    });
  }
};
export default Service;