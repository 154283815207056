var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container", attrs: { div: "" } }, [
    _c("div", { staticStyle: { display: "flex" } }, [
      _c(
        "div",
        { staticStyle: { flex: "0.5" } },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "数据集" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择数据集" },
                      on: { change: _vm.datasetChange },
                      model: {
                        value: _vm.form.datasetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "datasetId", $$v)
                        },
                        expression: "form.datasetId",
                      },
                    },
                    _vm._l(_vm.datasetList, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.label, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "pictureUpload",
                      attrs: {
                        multiple: false,
                        action: _vm.uploadFileUrl,
                        accept: ".jpg,.png,.jpeg,bmp,.JPG,.PNG,.JPEG,BMP",
                        "list-type": "picture-card",
                        disabled: _vm.check,
                        data: _vm.form,
                        limit: 1,
                        "auto-upload": false,
                        "on-change": _vm.handleOnChange,
                        "on-success": _vm.handleUploadSuccess,
                        "before-upload": _vm.beforUpload,
                        "file-list": _vm.showFile,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function (ref) {
                            var file = ref.file
                            return _c("div", {}, [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                staticStyle: { width: "80%", height: "80%" },
                                attrs: { src: file.url, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  !_vm.check
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemoveThis(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          },
                        },
                      ]),
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: {
                          "margin-left": "100px",
                          "margin-bottom": "10px",
                        },
                        attrs: { type: "primary" },
                        on: { click: _vm.submitUpload },
                      },
                      [_vm._v("校验")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.result.msg
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "500px",
                          height: "300px",
                          border: "1px solid",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-top": "10px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("校验结果")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "30px",
                              "margin-top": "30px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("识别类型：" + _vm._s(_vm.result.msg))]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "30px",
                              "margin-top": "30px",
                              "font-weight": "bold",
                            },
                          },
                          [
                            _vm._v(
                              "识别相似度： " + _vm._s(_vm.result.sim) + "%"
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.result.msg
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          width: "500px",
                          height: "300px",
                          border: "1px solid",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "20px",
                              "margin-top": "30px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.result.nomsg))]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { border: "1px solid" } },
                  [
                    _c(
                      "el-table",
                      {
                        staticStyle: { margin: "auto" },
                        attrs: {
                          data: _vm.results,
                          border: "",
                          fit: "",
                          "highlight-current-row": "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "ID", width: "100" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (ref) {
                                var row = ref.row
                                var $index = ref.$index
                                return [
                                  _c("span", [_vm._v(_vm._s($index + 1))]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "相似图片" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _c("img", {
                                      attrs: {
                                        src: scope.row.path,
                                        width: "150",
                                        height: "150",
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "识别类型" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(scope.row.tags.json)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "相似度" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.sim))]),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }