import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
export default {
  data: function data() {
    return {
      listLoading: false,
      form: {
        k1: "",
        k2: "",
        k3: ""
      },
      list: [],
      params: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    saveHistoryData: function saveHistoryData() {
      var _this = this;

      this.params = [];
      this.list.forEach(function (item) {
        var status = "";
        var setValue = "";

        if (item.id == 1) {
          status = _this.form.k1 == '1' ? '开启' : '关闭';
          setValue = "";
        } else if (item.id == 2) {
          status = _this.form.k2 == '1' ? '开启' : '关闭';
          setValue = "";
        } else if (item.id == 3) {
          status = "";
          setValue = _this.form.k3;
        }

        _this.params.push({
          id: item.id,
          status: status,
          setValue: setValue
        });
      }); //console.log(this.params);

      Service.WebsitesetUp(this.params).then(function (res) {
        _this.$message({
          type: 'success',
          message: '修改成功'
        });

        _this.getList();
      });
    },
    getList: function getList() {
      var _this2 = this;

      Service.WebsitenewsSetUp().then(function (res) {
        // console.log(res);
        _this2.list = res.data.data;
        res.data.data.forEach(function (item) {
          if (item.id == 1) {
            _this2.form.k1 = item.status == '开启' ? 1 : 0;
          } else if (item.id == 2) {
            _this2.form.k2 = item.status == '开启' ? 1 : 0;
          } else if (item.id == 3) {
            _this2.form.k3 = item.setValue;
          }
        });
      });
    }
    /*editnews(row){
    	this.$confirm('修改新闻状态, 是否继续?', '提示', {
    		confirmButtonText: '确定',
    		cancelButtonText: '取消',
    	}).then(() => {
    		Service.WebsitesetUp({
    			id:row.id,
    			status:row.status == '关闭'?'开启':'关闭'
    		}).then((res)=>{
    			this.$message({
    				type: 'success',
    				message: '修改成功'
    			});
    			this.getList()
    		})
    	
    	}).catch(() => {
    		this.$message({
    			type: 'info',
    			message: '已取消删除'
    		});
    	});
    	
    }*/

  }
};