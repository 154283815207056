//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import noel from '@/components/street/noeladdstreet';
import Street from '@/components/street/street';
var map = null;
export default {
  name: 'ComplexTable',
  components: {
    Pagination: Pagination,
    Street: Street,
    noel: noel
  },
  directives: {
    waves: waves
  },
  filters: {},
  watch: {
    "listQuery.street": function listQueryStreet(e) {
      console.log(e);
    }
  },
  data: function data() {
    return {
      zhanshiimg: '',
      streetList: [{
        type: '',
        text: '全部'
      }, {
        type: '单位物业',
        text: '单位物业'
      }, {
        type: '小区物业',
        text: '小区物业'
      }, {
        type: '社区服务中心',
        text: '社区服务中心'
      }, {
        type: '公共机构',
        text: '公共机构'
      }, {
        type: '学校',
        text: '学校'
      }, {
        type: '业主委员会',
        text: '业主委员会'
      }, {
        type: '企业',
        text: '企业'
      }, {
        type: '商户',
        text: '商户'
      }, {
        type: '其他',
        text: '其他'
      }],
      Optiones: [{
        type: 3,
        info: '全部'
      }, {
        type: 1,
        info: '使用中'
      }, {
        type: -2,
        info: '已禁用'
      }, {
        type: 0,
        info: '待审核'
      }, {
        type: -1,
        info: '已驳回'
      }],
      formLabelWidth: '120px',
      maps: false,
      xiugaistatus: '',
      statuadds: '',
      listsd: [{
        type: 0,
        info: '禁用中'
      }, {
        type: 1,
        info: '已启用'
      }],
      lists: [],
      xinxi: '',
      dialog: false,
      forms: {
        pass: '',
        name: '',
        lat: '',
        lon: '',
        mark: '',
        streetId: '',
        communityId: '',
        regionId: '',
        status: '',
        comment: '',
        number: '',
        address: '',
        type: ''
      },
      dialogFormVisible: false,
      form: {
        id: '',
        property: '',
        // 物业
        housing: '' // 小区

      },
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        queryInfo: '',
        status: 3,
        companyType: '',
        currentPage: 1,
        numOfPage: 20,
        community: '',
        region: '',
        street: ''
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    userstatus: function userstatus(e, ros) {
      var _this = this;

      var arr = {};
      arr.id = e.id;

      if (ros == 'jin') {
        arr.status = -2;
      } else if (ros == 'qi') {
        arr.status = 1;
      } else if (ros == 'tong') {
        arr.status = 1;
      } else {
        arr.status = -1;
      }

      Service.editStatususer(arr).then(function (res) {
        _this.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        _this.getList();
      });
    },
    chongzhi: function chongzhi(e) {
      var _this2 = this;

      var arr = {};
      arr.id = e.id;
      Service.edituserzhuang(arr).then(function (res) {
        _this2.$notify({
          title: '成功',
          message: '重置成功',
          type: 'success',
          duration: 2000
        });

        _this2.getList();
      });
    },
    listadd: function listadd() {
      this.getList();
    },
    addrChange: function addrChange(data) {
      console.log(data);
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
      this.listQuery.street = data.street;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.cloudaccount(this.listQuery).then(function (response) {
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows;
        _this3.listLoading = false;
      });
    }
  }
};