import { render, staticRenderFns } from "./tag.vue?vue&type=template&id=06f20943&"
import script from "./tag.vue?vue&type=script&lang=js&"
export * from "./tag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\toudui-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06f20943')) {
      api.createRecord('06f20943', component.options)
    } else {
      api.reload('06f20943', component.options)
    }
    module.hot.accept("./tag.vue?vue&type=template&id=06f20943&", function () {
      api.rerender('06f20943', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aipic/tag.vue"
export default component.exports