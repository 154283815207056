var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("商品类型")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                placeholder: "请选择商品类型",
                clearable: true,
              },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            _vm._l(_vm.statusLists, function (obj) {
              return _c("el-option", {
                key: obj.id,
                attrs: { label: obj.name, value: obj.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("街道")]
          ),
          _vm._v(" "),
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.searchStreet,
              callback: function ($$v) {
                _vm.searchStreet = $$v
              },
              expression: "searchStreet",
            },
          }),
          _vm._v(" "),
          _vm.projectFieldVisibleIndex
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "value-key": "id_project_index",
                    filterable: "",
                    placeholder: "请选择项目",
                    clearable: true,
                  },
                  model: {
                    value: _vm.listQuery.projectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "projectId", $$v)
                    },
                    expression: "listQuery.projectId",
                  },
                },
                _vm._l(_vm.projectList, function (obj) {
                  return _c("el-option", {
                    key: obj.id,
                    attrs: { label: obj.projectName, value: obj.id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-right": "0.5%" },
            },
            [_vm._v("商品名称")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入商品名称查询" },
            model: {
              value: _vm.listQuery.shop_name,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "shop_name", $$v)
              },
              expression: "listQuery.shop_name",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("状态")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              model: {
                value: _vm.listQuery.state,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilters },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("shop:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("新增商品")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "商品编号",
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.shopName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品图片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _c("img", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: {
                          src: "http://pic.touduila.com/" + scope.row.img,
                        },
                      }),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品说明" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.shopDesc)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分价格" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.integralPrice)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.street)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属街道项目" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.projectName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "库存数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.total)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已核销" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.destruction)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "当前状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.state == "已上架"
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "green" },
                          },
                          [_vm._v(_vm._s(scope.row.state))]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == "已下架"
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "red" },
                          },
                          [_vm._v(_vm._s(scope.row.state))]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上架时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.upTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "下架时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.downTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "修改人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.mixPerson)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.type)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "开启兑换机", width: "150" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.type == "实体商品"
                      ? [
                          _c(
                            "span",
                            { staticClass: "link-type" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-color": "#13ce66",
                                  "inactive-color": "#ff4949",
                                  "active-text": "开启",
                                  "inactive-text": "关闭",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.exchangeswitch($event, scope.row)
                                  },
                                },
                                model: {
                                  value: scope.row.onOff,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "onOff", $$v)
                                  },
                                  expression: "scope.row.onOff",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换码", align: "center", width: "160" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.type == "实体商品"
                      ? [
                          _c("p", { staticClass: "link-type" }, [
                            _vm._v(
                              "码库存【" + _vm._s(scope.row.cdkTotal) + "】"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "link-type" },
                            [
                              _vm.hasPerm("shop:export")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Exchange(
                                            scope.row,
                                            "export"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("导入")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.hasPerm("shop:view")
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Exchange(scope.row, "see")
                                        },
                                      },
                                    },
                                    [_vm._v("查看")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("shop:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type == "虚拟商品"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.Cardcoupon(scope.row)
                              },
                            },
                          },
                          [_vm._v("卡券")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == "已下架" && _vm.hasPerm("shop:updown")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.Sure(scope.row, 0)
                              },
                            },
                          },
                          [_vm._v("上架\n\t\t\t\t")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == "已上架" && _vm.hasPerm("shop:updown")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.Sure(scope.row, 1)
                              },
                            },
                          },
                          [_vm._v("下架\n\t\t\t\t")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.totals > 0,
            expression: "totals>0",
          },
        ],
        attrs: {
          total: _vm.totals,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增商品", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.form.type == "虚拟商品" ? _vm.rules : _vm.rule,
                model: _vm.form,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择商品类型",
                        clearable: true,
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.statusList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.name, value: obj.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "shop_name" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.shop_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shop_name", $$v)
                      },
                      expression: "form.shop_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属街道", prop: "street" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "value-key": "id",
                        filterable: "",
                        placeholder: "请选择街道",
                        clearable: true,
                      },
                      on: { change: _vm.streetChange },
                      model: {
                        value: _vm.form.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "street", $$v)
                        },
                        expression: "form.street",
                      },
                    },
                    _vm._l(_vm.streetList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.street, value: obj.street },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.projectFieldVisible
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属街道项目" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            "value-key": "id_project",
                            filterable: "",
                            placeholder: "请选择项目",
                            clearable: true,
                          },
                          model: {
                            value: _vm.form.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "projectId", $$v)
                            },
                            expression: "form.projectId",
                          },
                        },
                        _vm._l(_vm.projectList, function (obj) {
                          return _c("el-option", {
                            key: obj.id,
                            attrs: { label: obj.projectName, value: obj.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分价格", prop: "integral_price" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "input",
                      onkeyup:
                        "if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                      onafterpaste:
                        "if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\\D/g,'')}",
                    },
                    model: {
                      value: _vm.form.integral_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "integral_price", $$v)
                      },
                      expression: "form.integral_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.type != "实体商品"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "卡券密码" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisibles = true
                            },
                          },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "实体商品",
                      expression: "form.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "库存数量", prop: "total" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.form.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "total", $$v)
                      },
                      expression: "form.total",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type != "实体商品",
                      expression: "form.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "库存数量" },
                },
                [
                  _c("el-button", { attrs: { type: "text" } }, [
                    _vm._v("现库存" + _vm._s(_vm.cardlist.length)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片(比例1:1)", prop: "img" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        limit: 1,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.uploadPic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.uploadPic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemove(_vm.uploadPic)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type != "实体商品",
                      expression: "form.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "商品说明", prop: "shopDesc" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.shopDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shopDesc", $$v)
                      },
                      expression: "form.shopDesc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "实体商品",
                      expression: "form.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "商品说明" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.shopDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "shopDesc", $$v)
                      },
                      expression: "form.shopDesc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type != "实体商品",
                      expression: "form.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "备注", prop: "note" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "实体商品",
                      expression: "form.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "备注" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "note", $$v)
                      },
                      expression: "form.note",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品状态", prop: "state" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    _vm._l(_vm.states, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.street == "泰达街道",
                      expression: "form.street=='泰达街道'",
                    },
                  ],
                  attrs: { label: "使用厨余积分支付", prop: "pay_type" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pay_type", $$v)
                        },
                        expression: "form.pay_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Relieved } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "修改商品", visible: _vm.dialogForm },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForm = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.forms.type == "虚拟商品" ? _vm.rules : _vm.rule,
                model: _vm.forms,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择商品类型",
                        clearable: true,
                        disabled: "",
                      },
                      model: {
                        value: _vm.forms.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "type", $$v)
                        },
                        expression: "forms.type",
                      },
                    },
                    _vm._l(_vm.statusList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.name, value: obj.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.shop_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "shop_name", $$v)
                      },
                      expression: "forms.shop_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属街道" } },
                [
                  _c("el-input", {
                    attrs: { type: "input", disabled: "" },
                    model: {
                      value: _vm.forms.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "street", $$v)
                      },
                      expression: "forms.street",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.forms.projectName != "" && _vm.forms.projectName != null
                ? _c(
                    "el-form-item",
                    { attrs: { label: "所属街道项目" } },
                    [
                      _c("el-input", {
                        attrs: { type: "input", disabled: "" },
                        model: {
                          value: _vm.forms.projectName,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "projectName", $$v)
                          },
                          expression: "forms.projectName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "积分价格", prop: "integral_price" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.forms.integral_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "integral_price", $$v)
                      },
                      expression: "forms.integral_price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.forms.type != "实体商品"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "卡券密码" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _vm.dialogVisibles = true
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type == "实体商品",
                      expression: "forms.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "库存数量", prop: "total" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.UpNumber($event)
                      },
                      keydown: function ($event) {
                        return _vm.UpNumber($event)
                      },
                    },
                    model: {
                      value: _vm.forms.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "total", $$v)
                      },
                      expression: "forms.total",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type != "实体商品",
                      expression: "forms.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "库存数量" },
                },
                [
                  _vm.forms.type != "实体商品"
                    ? _c("el-button", { attrs: { type: "text" } }, [
                        _vm._v("现库存" + _vm._s(_vm.cardlist.length)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品图片(比例1:1)", prop: "img" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "http://pic.touduila.com/" + _vm.uploadPic,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(_vm.uploadPic)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(
                                        _vm.uploadPic
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type != "实体商品",
                      expression: "forms.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "商品说明", prop: "shopDesc" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.shopDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "shopDesc", $$v)
                      },
                      expression: "forms.shopDesc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type == "实体商品",
                      expression: "forms.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "商品说明" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.forms.shopDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "shopDesc", $$v)
                      },
                      expression: "forms.shopDesc",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type != "实体商品",
                      expression: "forms.type != '实体商品'",
                    },
                  ],
                  attrs: { label: "备注", prop: "note" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.forms.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "note", $$v)
                      },
                      expression: "forms.note",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.type == "实体商品",
                      expression: "forms.type == '实体商品'",
                    },
                  ],
                  attrs: { label: "备注" },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.forms.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "note", $$v)
                      },
                      expression: "forms.note",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商品状态", prop: "state" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "120px" },
                      model: {
                        value: _vm.forms.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "state", $$v)
                        },
                        expression: "forms.state",
                      },
                    },
                    _vm._l(_vm.states, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.forms.street == "泰达街道",
                      expression: "forms.street=='泰达街道'",
                    },
                  ],
                  attrs: { label: "使用厨余积分支付", prop: "pay_type" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.forms.pay_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "pay_type", $$v)
                        },
                        expression: "forms.pay_type",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogForm = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.xiugaiSure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "", visible: _vm.dialogVisibles, width: "50%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisibles = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _vm._l(_vm.cardlist, function (item, index) {
            return _c(
              "el-row",
              {
                staticStyle: { margin: "0 auto", width: "100%" },
                attrs: { gutter: 24 },
              },
              [
                _c(
                  "el-col",
                  {
                    staticStyle: { "line-height": "50px", "font-size": "20px" },
                    attrs: { span: 1 },
                  },
                  [_vm._v(_vm._s(index + 1) + ".")]
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c(
                      "el-input",
                      {
                        attrs: {
                          disabled: _vm.offs == 2 ? true : false,
                          placeholder: "请输入卡密",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.validateform(index)
                          },
                        },
                        model: {
                          value: item.cdks,
                          callback: function ($$v) {
                            _vm.$set(item, "cdks", $$v)
                          },
                          expression: "item.cdks",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-input__icon",
                          class: [
                            item.checked === 2
                              ? "el-icon-loading"
                              : item.status
                              ? "el-icon-check"
                              : item.checked
                              ? "el-icon-check"
                              : "el-icon-close",
                          ],
                          attrs: { slot: "suffix" },
                          slot: "suffix",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-col",
                  { attrs: { span: 6 } },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        align: "right",
                        type: "datetime",
                        placeholder: "选择日期",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                      },
                      model: {
                        value: item.cdktime,
                        callback: function ($$v) {
                          _vm.$set(item, "cdktime", $$v)
                        },
                        expression: "item.cdktime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.offs != 2
                  ? _c(
                      "el-col",
                      { attrs: { span: 6 } },
                      [
                        _c("el-button", {
                          attrs: {
                            type: "danger",
                            icon: "el-icon-delete",
                            circle: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.dangercard(index)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.offs != 2
            ? _c("el-button", { on: { click: _vm.adds } }, [_vm._v("添加")])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Surecard } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.exChangedialogVisible,
            width: _vm.Exchangestatus == "see" ? "80%" : "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.exChangedialogVisible = $event
            },
          },
        },
        [
          _vm.Exchangestatus == "export"
            ? _c(
                "el-form",
                {
                  ref: "exChangeform",
                  attrs: {
                    rules: _vm.exChangerule,
                    model: _vm.exChangeform,
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑换码", prop: "cdks" } },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.exChangeform.cdks,
                          callback: function ($$v) {
                            _vm.$set(_vm.exChangeform, "cdks", $$v)
                          },
                          expression: "exChangeform.cdks",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticStyle: { margin: "0", color: "#a1a1a1" } },
                        [_vm._v('操作指南：兑换码用英文逗号 " , " 分隔')]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑换码有效期", prop: "time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择日期",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                        },
                        model: {
                          value: _vm.exChangeform.time,
                          callback: function ($$v) {
                            _vm.$set(_vm.exChangeform, "time", $$v)
                          },
                          expression: "exChangeform.time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.Exchangestatus != "see"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.exChangesure },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.Exchangestatus == "see"
            ? _c(
                "div",
                [
                  _c(
                    "div",
                    { staticStyle: { "padding-bottom": "10px" } },
                    [
                      _c("el-input", {
                        staticClass: "filter-item",
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入兑换码" },
                        model: {
                          value: _vm.exChangelistsd.cdk,
                          callback: function ($$v) {
                            _vm.$set(_vm.exChangelistsd, "cdk", $$v)
                          },
                          expression: "exChangelistsd.cdk",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "primary", icon: "el-icon-search" },
                          on: { click: _vm.getexChangelist },
                        },
                        [_vm._v("查询")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [{ name: "waves", rawName: "v-waves" }],
                          staticClass: "filter-item",
                          attrs: { type: "danger" },
                          on: { click: _vm.deleteAllx },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.exChangelist,
                        border: "",
                        fit: "",
                        "highlight-current-row": "",
                      },
                      on: { "selection-change": _vm.selectTable },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          label: "编号",
                          prop: "id",
                          sortable: "custom",
                          align: "center",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [_vm._v(_vm._s(scope.row.id))]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          773642443
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "兑换码", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.cdk)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3826261945
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "有效期", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.time)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3981107104
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "兑换人", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.name)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2546910194
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "兑换人电话", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.phone)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2826390633
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "兑换日期", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.exchangeTime)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          89418395
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "状态", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "link-type",
                                      style: {
                                        color: scope.row.status
                                          ? "#67C23A"
                                          : "#F56C6C",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.status ? "已兑换" : "未兑换"
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1536342428
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "导入日期", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.createTime)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1199218436
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "入库人", align: "center" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", { staticClass: "link-type" }, [
                                    _vm._v(_vm._s(scope.row.executor)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3862052690
                        ),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "200" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return !scope.row.status
                                  ? [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "danger",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletex(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  : undefined
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.exChangetotals > 0,
                        expression: "exChangetotals>0",
                      },
                    ],
                    attrs: {
                      total: _vm.exChangetotals,
                      page: _vm.exChangelistsd.currentPage,
                      limit: _vm.exChangelistsd.numOfPage,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.exChangelistsd,
                          "currentPage",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.exChangelistsd, "numOfPage", $event)
                      },
                      pagination: _vm.getexChangelist,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }