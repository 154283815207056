import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, _defineProperty(_statusMap, "10", 'primary'), _defineProperty(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '1': '派单中',
        '5': '回收中',
        '10': '已完成',
        '-1': '已删除'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      orderstatus: 0,
      orderstatuslist: [{
        name: '单位订单',
        value: 0
      }, {
        name: '云台账订单',
        value: 1
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      dialog: false,
      dialogs: false,
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        payStatus: 1,
        payTypeInfo: '在线支付',
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        status: 1,
        // 1:派单中,5:回收中,10:已完成,-1:已删除
        lable: 2,
        key: '',
        reserveType: 0
      },
      imgs: '',
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      alertlist: [],
      Options: [{
        name: '查询全部',
        value: 0
      }, {
        name: '派单中',
        value: 1
      }, {
        name: '回收中',
        value: 5
      }, {
        name: '已完成',
        value: 10
      }, {
        name: '已删除',
        value: -1
      }],
      payTypeInfo: [{
        name: '全部',
        value: '全部'
      }, {
        name: '在线支付',
        value: '在线支付'
      }, {
        nme: '线下支付',
        value: '线下支付'
      }],
      payStatus: [{
        name: '全部',
        value: 3
      }, {
        name: '已支付',
        value: 1
      }, {
        name: '未支付',
        value: 0
      }]
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    } // orderstatus(val){
    // 	console.log(val)
    // 	if(val){
    // 		if(val ==0){
    // 			this.getList()
    // 		}else{
    // 			this.getyunList()
    // 		}
    // 	}
    // }

  },
  created: function created() {
    this.getList();
  },
  methods: {
    getListstatus: function getListstatus() {
      if (this.orderstatus == 0) {
        this.getList();
      } else {
        this.getyunList();
      }
    },
    dakai: function dakai(e) {
      console.log(e);
      this.dialog = true;
      this.imgs = e;
    },
    exportExcel: function exportExcel() {
      location.href = '/operation/order/export?' + qs.stringify(this.listQuery);
    },
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this.initMap(row.lon, row.lat);
      }, 500);
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.orderList(this.listQuery).then(function (response) {
        console.log(response.data.list);
        _this2.list = response.data.list;
        _this2.total = response.data.totalRows;
        setTimeout(function () {
          _this2.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    getyunList: function getyunList() {
      var _this3 = this;

      this.listLoading = true;
      Service.orderyunLists({
        currentPage: this.listQuery.currentPage,
        endTime: this.listQuery.endTime,
        key: this.listQuery.key,
        numOfPage: this.listQuery.numOfPage,
        payStatus: this.listQuery.payStatus,
        sequence: 1,
        startTime: this.listQuery.startTime,
        status: this.listQuery.status,
        payTypeInfo: this.listQuery.payTypeInfo
      }).then(function (response) {
        console.log(response.data.list);
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows;
        setTimeout(function () {
          _this3.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = e.goods;
    },
    Completepayment: function Completepayment(row) {
      var _this4 = this;

      this.$confirm('确定该笔订单已在线下支付回收金额给用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.Completepayment({
          orderId: row.id
        }).then(function (res) {
          _this4.$notify({
            title: '成功',
            message: '订单已完成，请线下支付回收金额给用户',
            type: 'success',
            duration: 2000
          });

          _this4.getList();
        });
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    beizhu: function beizhu(row) {
      this.beizhuTemp.remark = row.remark || '';
      this.beizhuTemp.orderId = row.id;
      this.beizhuVisible = true;
    },
    doRemark: function doRemark() {
      var _this5 = this;

      Service.orderRemark(this.beizhuTemp).then(function (res) {
        _this5.beizhuVisible = false;

        if (res.data.statusCode == 200) {
          _this5.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });

          _this5.getList();
        }
      });
    },
    chongpai: function chongpai(row) {
      var _this6 = this;

      Service.orderRecover(row.id).then(function (res) {
        if (res.data.statusCode) {
          _this6.$notify({
            title: '成功',
            message: '重派成功',
            type: 'success',
            duration: 2000
          });

          _this6.getList();
        }
      });
    },
    deleteOrder: function deleteOrder(row) {
      var _this7 = this;

      this.$confirm('此操作将永久删除该记录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.orderDel(row.id).then(function (res) {
          _this7.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
};