var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入查询条件" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              on: { change: _vm.handleF },
              model: {
                value: _vm.listQuery.role,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "role", $$v)
                },
                expression: "listQuery.role",
              },
            },
            _vm._l(_vm.datas, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v(_vm._s(_vm.$t("table.search")))]
          ),
          _vm._v(" "),
          _vm.hasPerm("users:export")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("users:coinsort")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportCoin },
                },
                [_vm._v("导出积分排名")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("users:zeroexport")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.exportLingtoudi },
                },
                [_vm._v("零投递导出")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("users:remove")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "danger" },
                  on: { click: _vm.jieChu },
                },
                [_vm._v("解除厨余袋")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "昵称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.nickName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分卡号", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.cardNumber))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "积分", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.location[0].street != "泰达街道"
                      ? _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toogleExpand(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(scope.row.coin) + " "),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.location[0].street == "泰达街道"
                      ? _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toogleExpand(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v("其他积分:" + _vm._s(scope.row.coin) + " "),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.location[0].street == "泰达街道"
                      ? _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.toogleExpand(scope.row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "厨余积分:" + _vm._s(scope.row.kitchenCoin) + " "
                            ),
                            _c("i", { staticClass: "el-icon-arrow-right" }),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "余额提现", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.alreadyFee / 100) + "元"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "余额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.canFee / 100) + "元")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "地址列表", width: "120", type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: props.row.location.slice(0, 1),
                          border: "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { label: "收货人" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope2) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope2.row.name)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "电话" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope2) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope2.row.phone)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: { label: "地址" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope2) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope2.row.address)),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            align: "center",
                            "class-name": "small-padding fixed-width",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope2) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editUser(scope2.row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editdizhihis(scope2.row)
                                          },
                                        },
                                      },
                                      [_vm._v("地址修改历史")]
                                    ),
                                    _vm._v(" "),
                                    scope2.row.lat && scope2.row.lon
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "success" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewMap(scope2.row)
                                              },
                                            },
                                          },
                                          [_vm._v("地图")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "区域限制", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.posLimited === 0
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("放开"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.posLimited === 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("限制"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "注册时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "自宣书签名", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.autographPic
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.autopic(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "查看", align: "center", width: "330" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "secondusers",
                            params: { data: scope.row, name: "chuyu" },
                          },
                        },
                      },
                      [
                        _vm.hasPerm("users:viewkitchen")
                          ? _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v("厨余袋物资")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "secondusers",
                            params: { data: scope.row, name: "toudi" },
                          },
                        },
                      },
                      [
                        _vm.hasPerm("users:viewdeliver")
                          ? _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v("投递记录")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: { name: "order", params: { data: scope.row } },
                        },
                      },
                      [
                        _vm.hasPerm("users:vieworder")
                          ? _c(
                              "el-button",
                              { attrs: { type: "primary", size: "mini" } },
                              [_vm._v("可回收订单")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.changeUserLocation(scope.row)
                          },
                        },
                      },
                      [_vm._v("限制开关")]
                    ),
                    _vm._v(" "),
                    scope.row.delFlag
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.jiefeng(scope.row)
                              },
                            },
                          },
                          [_vm._v("解封")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mapVisible, title: "地图" },
          on: {
            "update:visible": function ($event) {
              _vm.mapVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "amapContainer" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.mapVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editVisible, title: "编辑" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.form, "label-position": "left" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "电话" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("addrselect", {
                attrs: {
                  street: _vm.form.street,
                  community: _vm.form.community,
                  region: _vm.form.region,
                  showFloor: false,
                },
                on: { addrChange: _vm.addrChange },
              }),
              _vm._v(" "),
              _c("menpai", {
                attrs: {
                  region: _vm.form.region,
                  louhao: _vm.addrForm.louhao,
                  danyuan: _vm.addrForm.danyuan,
                  menpai: _vm.addrForm.menpai,
                },
                on: { menpaiChange: _vm.menpaiChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveAddr } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "解绑厨余袋", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.Relieve } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "厨余袋组号" } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "500px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.Relieve.zuhao,
                      callback: function ($$v) {
                        _vm.$set(_vm.Relieve, "zuhao", $$v)
                      },
                      expression: "Relieve.zuhao",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.Relievedai },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.shows
            ? _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户姓名" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.datalist.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.datalist, "nickName", $$v)
                          },
                          expression: "datalist.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户电话" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.datalist.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.datalist, "phone", $$v)
                          },
                          expression: "datalist.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户地址" } },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.datalist.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.datalist, "address", $$v)
                          },
                          expression: "datalist.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Relieved } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "导出" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c("el-form-item", { attrs: { label: "请选择统计时间" } }, [
                _c(
                  "div",
                  { staticClass: "filter-item" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "picker-options": _vm.pickerOptions,
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                        align: "right",
                      },
                      model: {
                        value: _vm.time,
                        callback: function ($$v) {
                          _vm.time = $$v
                        },
                        expression: "time",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "请选择督导员所属街道:" } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.searchStreet,
                      callback: function ($$v) {
                        _vm.searchStreet = $$v
                      },
                      expression: "searchStreet",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "请选择积分来源" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.juesehao,
                        callback: function ($$v) {
                          _vm.juesehao = $$v
                        },
                        expression: "juesehao",
                      },
                    },
                    _vm._l(_vm.juese, function (item) {
                      return _c("el-checkbox", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.juese)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.beizhuVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editdizhiVisible, title: "" },
          on: {
            "update:visible": function ($event) {
              _vm.editdizhiVisible = $event
            },
          },
        },
        [
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.usertable },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "address", label: "具体地址", width: "180" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "changeName",
                    label: "修改人姓名",
                    width: "180",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "createTime", label: "修改成功时间" },
                }),
              ],
              1
            ),
          ],
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.editdizhiVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.autodizhiVisible, title: "" },
          on: {
            "update:visible": function ($event) {
              _vm.autodizhiVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { margin: "0 auto" },
            attrs: { src: "http://pic.touduila.com/" + _vm.autopics },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.autodizhiVisible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }