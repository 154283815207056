import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import jiedao from '@/components/street/jiedao';
import Service from '@/api/banner';
import waves from '@/directive/waves'; // Waves directive

export default {
  name: 'Banner',
  components: {
    jiedao: jiedao,
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      communityList: [{
        region: '上架',
        id: 0
      }, {
        region: '下架',
        id: 1
      }],
      dialog: false,
      searchs: null,
      total: 0,
      isAdd: true,
      forms: {
        remark: '',
        pic: '',
        street: '',
        status: '',
        otherPic: '',
        sort: '',
        bannerTitle: ''
      },
      form: {
        remark: '',
        pic: '',
        street: '',
        status: '',
        otherPic: '',
        sort: '',
        bannerTitle: ''
      },
      status: '',
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        bannerTitle: '',
        status: ''
      },
      tableKey: 0,
      list: null,
      listLoading: true,
      uploadPic: [],
      uploadPics: [],
      dialogFormVisible: false,
      searchStreet: null,
      checked: false,
      Option: [{
        name: '全部',
        value: 2
      }, {
        name: '上架',
        value: 3
      }, {
        name: '下架',
        value: 1
      }],
      streetlist: [],
      allchecked: false
    };
  },
  watch: {
    status: function status(val) {
      if (val) {
        if (val == 3) {
          this.listQuery.status = 0;
        } else if (val == 2) {
          delete this.listQuery.status;
        } else {
          this.listQuery.status = val;
        }
      }
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        console.log(val);
        this.form.street = val.street;
        this.forms.street = val.street;
      } else {
        this.form.street = '';
        this.forms.street = '';
      }
    },
    searchs: function searchs(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        delete this.listQuery.street;
      }
    },
    allchecked: function allchecked(val) {
      var _this = this;

      this.streetlist.forEach(function (item, index) {
        _this.allchecked ? item.checked = true : item.checked = false;
      });
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    deletes: function deletes(e) {
      var _this2 = this;

      this.$confirm('此操作将删除此条banner图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.del({
          id: e
        }).then(function (res) {
          _this2.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });

          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    close: function close() {
      this.dialogFormVisible = false;
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.getList(this.listQuery).then(function (response) {
        console.log(response);
        var list = response.data.data.list;
        _this3.total = response.data.data.totalRows;
        _this3.list = list; // Just to simulate the time of the request

        _this3.listLoading = false;
      });
      Service.getAllstreet().then(function (res) {
        res.data.data.forEach(function (item, index) {
          _this3.streetlist.push({
            name: item,
            checked: false
          });
        });
      });
    },
    handleRemove: function handleRemove(item) {
      this.form.pic = '';
    },
    logohandleRemove: function logohandleRemove(item) {
      this.form.otherPic = '';
    },
    uploadImg: function uploadImg(data) {
      console.log(data);
      this.form.pic = data.key;
    },
    logouploadImg: function logouploadImg(data) {
      console.log(data);
      this.form.otherPic = data.key;
    },
    uploadImgs: function uploadImgs(data) {
      console.log(data);
      this.forms.pic = data.key;
    },
    logouploadImgs: function logouploadImgs(data) {
      console.log(data);
      this.forms.otherPic = data.key;
    },
    save: function save() {
      if (this.isAdd) {
        this.add();
      } else {
        this.update();
      }
    },
    saves: function saves() {
      var _this4 = this;

      var arr = [];

      if (this.checked) {
        this.forms.street = '默认街道';
      } else {
        if (this.allchecked) {
          this.streetlist.forEach(function (item, index) {
            arr.push(item.name);
          });
        } else {
          this.streetlist.forEach(function (item, index) {
            if (item.checked) {
              arr.push(item.name);
            }
          });
        }

        this.forms.street = arr.join(',');
      }

      Service.add(this.forms).then(function (res) {
        _this4.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        location.reload();

        _this4.close();

        _this4.getList();

        _this4.dialog = false;
      });
    },
    add: function add() {
      this.streetlist.forEach(function (cell, indexs) {
        cell.checked = false;
      });
      this.dialog = true;
      this.searchStreet = '';
      this.checked = false;
      this.forms.remark = '';
      this.forms.pic = '';
      this.forms.street = '';
      this.forms.otherPic = '';
      this.forms.bannerTitle = '';
    },
    update: function update() {
      var self = this;

      if (self.checked) {
        self.form.role = 0;
        delete self.form.street;
        delete self.form.updateTime;
        delete self.form.createTime;
        self.gaishuju();
      } else {
        delete self.form.updateTime;
        delete self.form.createTime;
        self.gaishuju();
      }
    },
    gaishuju: function gaishuju() {
      var _this5 = this;

      var arr = [];

      if (this.checked) {
        this.form.street = '默认街道';
      } else {
        if (this.allchecked) {
          this.streetlist.forEach(function (item, index) {
            arr.push(item.name);
          });
        } else {
          this.streetlist.forEach(function (item, index) {
            if (item.checked) {
              arr.push(item.name);
            }
          });
        }

        this.form.street = arr.join(',');
      }

      Service.update(this.form).then(function (res) {
        _this5.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });

        _this5.close();

        _this5.getList();

        location.reload();
      });
    },
    edit: function edit(row) {
      var _this6 = this;

      console.log(row.street);
      this.streetlist.forEach(function (cell, indexs) {
        cell.checked = false;
      });

      if (row.street == '默认街道') {
        this.checked = true;
      } else {
        this.checked = false;
      }

      row.street.split(',').forEach(function (item, index) {
        _this6.streetlist.forEach(function (cell, indexs) {
          if (item == cell.name) {
            cell.checked = true;
          }
        });
      });
      this.dialogFormVisible = true;
      this.isAdd = false;
      console.log(row);
      this.form = row;
    },
    del: function del(row) {
      delete row.createTime;
      delete row.updateTime;
      row.status = !row.status;
      row.status = !row.status ? 0 : 1;
      var self = this;
      Service.update(row).then(function (res) {
        self.$notify({
          title: '成功',
          message: '操作成功',
          type: 'success',
          duration: 2000
        });
        self.getList();
      });
    }
  }
};