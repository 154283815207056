"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _dizhi = _interopRequireDefault(require("@/api/dizhi"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'addrselect',
  props: {
    showFloor: {
      type: Boolean,
      default: true
    },
    street: [String, Number, Object],
    community: [String, Number, Object],
    region: [String, Number, Object],
    floor: [String, Number, Object]
  },
  data: function data() {
    return {
      form: {
        community: '',
        region: '',
        street: '',
        floor: ''
      },
      // 社区列表
      streetList: [],
      shequList: [],
      communityList: []
    };
  },
  watch: {
    street: function street(val) {
      this.form.street = val;
      this.form.community = '';
      this.form.region = '';
      this.form.floor = '';
    },
    community: function community(val) {
      this.form.community = val;
      this.form.region = '';
      this.form.floor = '';
    },
    region: function region(val) {
      this.form.region = val;
      this.form.floor = '';
    },
    floor: function floor(val) {
      this.form.floor = val;
    },
    // 监听选择的社区
    'form.street': function formStreet(val) {
      var _this = this;

      if (val) {
        this.shequList = [];
        this.communityList = [];

        if (val) {
          _dizhi.default.shequList({
            currentPage: 1,
            numOfPage: 10000,
            streetName: val
          }).then(function (res) {
            _this.shequList = res.data;

            _this.change();
          });
        }
      }
    },
    'form.community': function formCommunity(val) {
      var _this2 = this;

      if (val) {
        var streetName = this.form.street;
        this.communityList = [];

        if (val) {
          _dizhi.default.xiaoquList({
            currentPage: 1,
            numOfPage: 10000,
            street: streetName,
            community: val
          }).then(function (res) {
            _this2.communityList = res.data.list;

            _this2.change();
          });
        }
      }
    },
    // 监听选择的小区
    'form.region': function formRegion(val) {
      this.change();
    },
    // 监听选择的小区
    'form.floor': function formFloor(val) {
      this.change();
    }
  },
  created: function created() {
    this.getJiedao();
    this.init();
  },
  methods: {
    streetTest: function streetTest(name) {
      console.log(name);

      if (!name) {
        this.form.street = '';
        this.change();
      } else {
        this.form.street = name;
        this.change();
      } // this.change()

    },
    change: function change() {
      this.$emit('addrChange', this.form);
    },
    init: function init() {
      this.form.street = this.street;
      this.form.region = this.region;
      this.form.community = this.community;
      this.form.floor = this.floor;
    },
    // 获取街道
    getJiedao: function getJiedao() {
      var _this3 = this;

      _dizhi.default.streetList().then(function (res) {
        _this3.streetList = res.data;
      });
    }
  }
};
exports.default = _default;