import request from '@/utils/request';
var Service = {
  getProjectList: function getProjectList(params) {
    // 加载页面
    return request({
      url: '/project/getProjectList',
      method: 'get',
      params: params
    });
  },
  insertProject: function insertProject(params) {
    // 添加
    return request({
      url: '/project/insertProject',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updateProject: function updateProject(params) {
    return request({
      url: '/project/updateProject',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  getKitchenRegionByProjectId: function getKitchenRegionByProjectId(params) {
    // 根据项目编号查询小区集合
    return request({
      url: '/project/getKitchenRegionByProjectId?projectId=' + params,
      method: 'get'
    });
  },
  getKitchenRegionsByTown: function getKitchenRegionsByTown(params) {
    // 根据社区名称查询小区集合
    return request({
      url: '/project/getKitchenRegionsByTown?community=' + params,
      method: 'get'
    });
  },
  getProjectsByStreet: function getProjectsByStreet(params) {
    // 根据街道查询项目列表
    return request({
      url: '/project/getProjectsByStreet?street=' + params,
      method: 'get'
    });
  }
};
export default Service;