//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Street from '@/components/street/street'
// import danweiStreet from '@/components/street/danweiStreet'
import qs from 'qs';
import Service from '@/api/service';
import noel from '@/components/street/street';
import addrselect from '@/components/street/addrselect';
import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
export default {
  components: {
    Pagination: Pagination,
    addrselect: addrselect,
    jiedao: jiedao,
    noel: noel // Street,
    // danweiStreet

  },
  data: function data() {
    return {
      Options: [{
        name: '参与用户',
        value: 0
      }, {
        name: '潜在用户',
        value: 1
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      form: {
        "floor": "",
        'address': '',
        'city': '滨海新区',
        'lat': 0,
        'locationId': 0,
        'lon': 0,
        'name': '',
        'org': '',
        'phone': 0,
        'province': '天津市',
        'userId': 0,
        'homeinfo': ''
      },
      addrForm: {
        'louhao': '',
        'danyuan': '',
        'menpai': ''
      },
      beizhuVisible: false,
      searchStreet: null,
      // 存放查询街道数据
      exportStreet: null,
      // 存放导出街道数据
      exports: {
        paymentTimeStart: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        paymentTimeEnd: parseTime(new Date(), '{y}-{m}-{d}'),
        street: ''
      },
      listQuery: {
        street: null,
        currentPage: 1,
        numOfPage: 20
      },
      total: 0,
      list: [],
      xiugais: {
        locationId: '',
        remarks: ''
      },
      dialogFormVisible: false
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      }
    },
    exportStreet: function exportStreet(val) {
      if (val) {
        this.exports.street = val.street;
      }
    },
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      this.exports.paymentTimeStart = parseTime(val[0], '{y}-{m}-{d}');
      this.exports.paymentTimeEnd = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    addrChange: function addrChange(data) {
      this.form.community = data.community;
      this.form.region = data.region;
      this.form.street = data.street;
    },
    saveAddr: function saveAddr() {
      var _this = this;

      this.form.locationId = this.form.id;
      this.form.userId = this.form.ownerId;
      var floor = this.addrForm.louhao + "-" + this.addrForm.danyuan + "-" + this.addrForm.menpai;
      this.form.address = this.form.street + " , " + this.form.community + " , " + this.form.region + " , " + floor;
      this.form.homeinfo = floor;
      var param = Object.assign({}, this.form);
      Service.updateLocation(param).then(function (res) {
        _this.editVisible = false;

        _this.$message({
          message: '更新成功',
          type: 'success'
        });

        _this.getList();
      });
    },
    taizhang: function taizhang(e) {
      console.log(e);
      this.$router.push({
        path: '/front/taizhang',
        query: {
          data: e.id
        }
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addrChanges: function addrChanges(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
      this.getList();
    },
    beizhuuser: function beizhuuser(e) {
      this.dialogFormVisible = true;
      console.log(e);
      this.xiugais.locationId = e.locationList[0].id;
      this.xiugais.remarks = e.locationList[0].remarks;
    },
    exportCash: function exportCash() {
      this.beizhuVisible = true;
      this.juesehao = [];
      this.exports.street = '';
      this.exports.action = '';
      this.searchStreet = '';
    },
    getList: function getList() {
      var _this2 = this;

      console.log(this.listQuery);
      Service.ownerCashlist(this.listQuery).then(function (res) {
        console.log(res);
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
      });
    },
    Sures: function Sures() {
      Service.exportOwnerCash(this.exports).then(function (res) {
        var content = res.data;
        var blob = new Blob([content]);
        var fileName = '提现明细.xls';

        if ('download' in document.createElement('a')) {
          // 非IE下载
          var elink = document.createElement('a');
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象

          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      });
    }
  }
};