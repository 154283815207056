import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tongjiService from '@/api/tongji';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import Street from '@/components/street/street';
import { parseTime } from '@/utils';
var map = null;
export default {
  name: 'TongjiAdd',
  components: {
    Pagination: Pagination,
    Street: Street
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      regions: '',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      time: '',
      allTime: [],
      supervisorRecordPic: [],
      region: '',
      form: {
        registerUsers: 0,
        joinUsers: 0,
        regionId: '',
        // 小区id
        harmful: '',
        // 有害垃圾(吨)
        kitchenBins: '',
        // 厨余(桶)
        recycled: '',
        // 可回收物(吨)
        pic: [],
        // 厨余(桶)
        time: '',
        remark: ''
      },
      temp: {
        regionId: '',
        // 小区id
        time: ''
      },
      dialogVisible: false
    };
  },
  watch: {
    'time': function time(val) {
      this.temp.time = parseTime(val, '{y}-{m}-{d}');
      console.log(this.temp.time);
      this.getDetail();
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    addrChange: function addrChange(data) {
      console.log(data);
      this.listQuery.community = data.community;
      this.listQuery.floor = data.floor;
      this.listQuery.region = data.region;
      this.listQuery.street = data.street;
      this.getList();
    },
    isDataSelected: function isDataSelected(data) {
      if (this.allTime.includes(data.day)) {
        return true;
      } else {
        return false;
      }
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      tongjiService.getXiaoquList(this.listQuery).then(function (response) {
        // this.list = response.data
        _this.list = response.data.data.list;
        _this.total = response.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    add: function add(row) {
      this.dialogVisible = true;
      this.temp.regionId = row.id;
      this.region = row.region;
      this.temp.time = parseTime(new Date(), '{y}-{m}-{d}');
      this.getDetail();
    },
    getDetail: function getDetail() {
      var _this2 = this;

      var self = this;
      tongjiService.getSupervisorRecord(this.temp).then(function (res) {
        if (res.data.success) {
          if (res.data.data.id) {
            _this2.form.id = res.data.data.id;
          }

          _this2.form.regionId = _this2.temp.regionId;
          _this2.form.registerUsers = res.data.data.registerUsers;
          _this2.form.joinUsers = res.data.data.joinUsers;
          _this2.form.harmful = res.data.data.harmful;
          _this2.form.kitchenBins = res.data.data.kitchenBins;
          _this2.form.recycled = res.data.data.recycled;
          _this2.form.time = res.data.data.time || '';
          _this2.form.remark = res.data.data.remark || '';
          _this2.allTime = res.data.data.allTime || [];
          var _pic = res.data.data.supervisorRecordPic;
          self.supervisorRecordPic = [];

          if (_pic && _pic.length) {
            console.log(_pic);

            _pic.forEach(function (item) {
              self.supervisorRecordPic.push(item.pic);
            });
          }
        }
      });
    },
    handleRemove: function handleRemove(item) {
      var index = this.supervisorRecordPic.indexOf(item);

      if (index > -1) {
        this.supervisorRecordPic.splice(index, 1);
      }
    },
    uploadImg: function uploadImg(response, file, fileList) {
      console.log(response);
      console.log(file);
      console.log(fileList);
      fileList = [];

      if (!this.form.pic.includes(response.key)) {
        this.form.pic.push(response.key);
      }

      this.supervisorRecordPic = this.form.pic;
      this.$refs.upload.clearFiles();
    },
    save: function save() {
      var _this3 = this;

      this.form.harmful = this.form.harmful * 1;
      this.form.kitchenBins = this.form.kitchenBins * 1;
      this.form.recycled = this.form.recycled * 1;
      tongjiService.updateSupervisorRecord(this.form).then(function (res) {
        if (res.status == 200) {
          _this3.$notify({
            title: '操作成功',
            message: '添加更新成功',
            type: 'success',
            duration: 2000
          });

          _this3.getDetail();
        }
      });
    }
  }
};