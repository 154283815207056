import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _dizhi;

import request from '@/utils/request';
var dizhi = (_dizhi = {
  // 查询街道
  streetList: function streetList(params) {
    return request({
      url: '/operation/userAddress/getAllStatusStreet',
      method: 'get',
      params: params
    });
  },
  addStreet: function addStreet(data) {
    return request({
      url: '/operation/userAddress/increaseStreet',
      method: 'post',
      data: data
    });
  },
  updateStreet: function updateStreet(data) {
    return request({
      url: '/operation/userAddress/modifyStreet',
      method: 'put',
      data: data
    });
  },
  delStreet: function delStreet(id) {
    return request({
      url: '/operation/userAddress/deleteStreet/' + id,
      method: 'delete'
    });
  },
  // 查询社区
  shequList: function shequList(params) {
    return request({
      url: '/operation/userAddress/getAllStatusTown',
      method: 'get',
      params: params
    });
  },
  addShequ: function addShequ(data) {
    return request({
      url: '/operation/userAddress/increaseTown',
      method: 'post',
      data: data
    });
  },
  updateShequ: function updateShequ(data) {
    return request({
      url: '/operation/userAddress/modifyTown',
      method: 'put',
      data: data
    });
  },
  delShequ: function delShequ(id) {
    return request({
      url: '/operation/userAddress/deleteTown/' + id,
      method: 'delete'
    });
  },
  // 小区查询
  xiaoquList: function xiaoquList(params) {
    return request({
      url: '/operation/userAddress/getAllStatusRegion',
      method: 'get',
      params: params
    });
  },
  addxiaoqu: function addxiaoqu(data) {
    return request({
      url: '/operation/userAddress/insertKinchenRegionsLocation',
      method: 'post',
      data: data
    });
  },
  updatexiaoqu: function updatexiaoqu(data) {
    return request({
      url: '/operation/userAddress/modifyRegion',
      method: 'put',
      data: data
    });
  },
  delxiaoqu: function delxiaoqu(id) {
    return request({
      url: '/operation/userAddress/delete/' + id,
      method: 'delete'
    });
  },
  getAllRegionType: function getAllRegionType() {
    return request({
      url: '/operation/userAddress/getAllRegionType',
      method: 'get'
    });
  },
  modifyRegionRecoveryType: function modifyRegionRecoveryType(data) {
    return request({
      url: '/operation/userAddress/modifyRegionRecoveryType',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  }
}, _defineProperty(_dizhi, "modifyRegionRecoveryType", function modifyRegionRecoveryType(data) {
  return request({
    url: '/operation/userAddress/modifyRegionRecoveryType',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(data)
  });
}), _defineProperty(_dizhi, "removeRegionRecoveryType", function removeRegionRecoveryType(params) {
  return request({
    url: '/operation/userAddress/removeRegionRecoveryType',
    method: 'DELETE',
    params: params
  });
}), _defineProperty(_dizhi, "getDefaultXiaoqu", function getDefaultXiaoqu(params) {
  return request({
    url: '/operation/owner/getWhiteList',
    params: params
  });
}), _defineProperty(_dizhi, "getSchoolList", function getSchoolList(params) {
  return request({
    url: '/operation/owner/getSchool',
    method: 'get',
    params: params
  });
}), _defineProperty(_dizhi, "getUserStreets", function getUserStreets() {
  // 获得用户街道
  return request({
    url: '/getUserStreets',
    method: 'get'
  });
}), _defineProperty(_dizhi, "whitePreview", function whitePreview(params) {
  // 小区门牌号白名单预览
  return request({
    url: '/operation/userAddress/white/preview',
    method: 'post',
    params: params
  });
}), _defineProperty(_dizhi, "getRegionHomeNumbers", function getRegionHomeNumbers(params) {
  // 小区白名单明细
  return request({
    url: '/operation/userAddress/white/getRegionHomeNumbers',
    method: 'get',
    params: params
  });
}), _defineProperty(_dizhi, "getBuildingNumbers", function getBuildingNumbers(params) {
  // 小区白名单单元号列表
  return request({
    url: '/operation/userAddress/white/getBuildingNumbers?region=' + params,
    method: 'get'
  });
}), _defineProperty(_dizhi, "deleteRegionBuildingUnitById", function deleteRegionBuildingUnitById(id) {
  // 删除小区楼号
  return request({
    url: '/operation/userAddress/white/deleteRegionBuildingUnitById?id=' + id,
    method: 'delete'
  });
}), _defineProperty(_dizhi, "whiteInsert", function whiteInsert(params) {
  // 地址白名单数据添加
  return request({
    url: '/operation/userAddress/white/list',
    method: 'post',
    params: params
  });
}), _defineProperty(_dizhi, "queryPointInformation", function queryPointInformation(params) {
  return request({
    url: '/operation/point/query/information',
    method: 'get',
    params: params
  });
}), _dizhi);
export default dizhi;