var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-item", staticStyle: { flex: "0.2" } },
        [
          _c("el-date-picker", {
            attrs: {
              "picker-options": _vm.pickerOptions,
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              align: "right",
            },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-item", staticStyle: { "text-align": "center" } },
        [
          _c("p", { staticStyle: { "font-size": "26px" } }, [
            _vm._v(_vm._s(_vm.datas.company)),
            _c("span", { staticStyle: { "font-size": "20px" } }, [
              _vm._v("（" + _vm._s(_vm.datas.companyTypeInfo) + "）"),
            ]),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { "font-size": "20px" } }, [
            _vm._v(
              _vm._s(_vm.datas.address) +
                "-" +
                _vm._s(_vm.datas.name) +
                "-" +
                _vm._s(_vm.datas.phone)
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "上报日期", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "厨余垃圾回收桶数", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.kitchenNum) + "桶")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "其他垃圾回收桶数", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.otherNum) + "桶")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "可回收垃圾预约回收", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.recyclable) + "kg")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "有害垃圾预约回收", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.harmfulWaste) + "kg")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "可回收垃圾自助回收", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.otherWayRecycle) + "kg"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "可回收垃圾图片", align: "center", width: "400px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.recyclePic, function (i, index) {
                    return scope.row.recyclePic
                      ? _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "20px",
                          },
                          attrs: { src: "http://pic.touduila.com/" + i },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(scope.row.recyclePic, index)
                            },
                          },
                        })
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "有害垃圾自助回收", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.otherWayHarmful) + "kg"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "有害垃圾图片", align: "center", width: "400px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.harmfulPic, function (i, index) {
                    return scope.row.harmfulPic
                      ? _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "20px",
                          },
                          attrs: { src: "http://pic.touduila.com/" + i },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(scope.row.harmfulPic, index)
                            },
                          },
                        })
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递厨余袋数", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.allCount ? scope.row.allCount : 0)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center", height: "100%" },
          attrs: { title: "图片", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }