var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入查询条件" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.$t("table.search")) + "\n\t\t")]
          ),
          _vm._v(" "),
          _vm.hasPerm("newslist:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.Addnews },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "新闻标题" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.title))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "图片", width: "155px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.pic.split(","), function (i, index) {
                    return scope.row.pic
                      ? _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            margin: "5px",
                          },
                          attrs: { src: "http://pic.touduila.com/" + i },
                        })
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "显示客户端" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.client.split(","), function (item) {
                    return _c("span", [_c("el-tag", [_vm._v(_vm._s(item))])], 1)
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "作者" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.author))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "简介", width: "650px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.summary))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.status)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排序" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.sort)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "浏览量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.number)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "发布时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.newsTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("newslist:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editnews(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("newslist:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delnews(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogVisible, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "dataForm", attrs: { rules: _vm.rules, model: _vm.form } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "标题",
                    "label-width": _vm.formLabelWidth,
                    prop: "title",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v("（必填  建议50字以内）"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "封面图",
                    "label-width": _vm.formLabelWidth,
                    prop: "pic",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      style: {
                        display: _vm.uploadPic.length >= 1 ? "none" : "block",
                      },
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "http://pic.touduila.com/" + item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v("（必传  1张 ，尺寸建议760*530）"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排序",
                    "label-width": _vm.formLabelWidth,
                    prop: "sort",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "number", autocomplete: "off" },
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v(
                      "（提示：  排序规则，100 为基准，数小靠前，自由设置）"
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("发布")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("待发布"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作者", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.author,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "author", $$v)
                      },
                      expression: "form.author",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "简介",
                    "label-width": _vm.formLabelWidth,
                    prop: "summary",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.summary,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "summary", $$v)
                      },
                      expression: "form.summary",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "文本内容",
                    "label-width": _vm.formLabelWidth,
                    prop: "newsJson",
                  },
                },
                [
                  _c("Editor", {
                    model: {
                      value: _vm.form.newsJson,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newsJson", $$v)
                      },
                      expression: "form.newsJson",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "发布时间",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetime",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.form.newsTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newsTime", $$v)
                      },
                      expression: "form.newsTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v("（提示：不填默认发布时间为当前时间）"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "阅读量", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v("（非必填）"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "适用客户端",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.juesehao,
                        callback: function ($$v) {
                          _vm.juesehao = $$v
                        },
                        expression: "juesehao",
                      },
                    },
                    _vm._l(_vm.juese, function (item) {
                      return _c("el-checkbox", { attrs: { label: item } }, [
                        _vm._v(_vm._s(item)),
                      ])
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "time-tips" }, [
                    _vm._v(
                      "（提示：所有文章都在官网显示，勾选适用客户端，是为了在中小程序显示）"
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Saves } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.imgdialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.imgdialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "ql-editor",
        domProps: { innerHTML: _vm._s(_vm.datas) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }