import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/recyclertracks';
import jiedao from '@/components/street/jiedao';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import ServiceStreet from '@/api/service';
var map = null;
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '',
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 10,
        street: '',
        workTime: new Date().toLocaleDateString().replace(/\//g, '-'),
        viewMethod: 0
      },
      streets: [],
      // 街道下拉列表
      defaultStreet: "泰达街道",
      // 默认街道
      viewdialog: false,
      tracks: [],
      // 督导员所有轨迹
      viewMethods: [{
        value: 0,
        label: '列表展示'
      }, {
        value: 1,
        label: '地图展示'
      }],
      timersd: null,
      isViewMap: 0 // 不显示地图

    };
  },
  created: function created() {
    this.getJiedao(); // 街道列表
  },
  mounted: function mounted() {
    this.getListMap(); // 放到tab

    this.initMap();
  },
  methods: {
    renderheader: function renderheader(h, _ref) {
      var column = _ref.column,
          $index = _ref.$index;
      return h('span', {}, [h('span', {}, column.label.split('-')[0]), h('br'), h('span', {}, column.label.split('-')[1])]);
    },
    getJiedao: function getJiedao() {
      var _this = this;

      ServiceStreet.getArtjied().then(function (res) {
        _this.streets = res.data;
        _this.listQuery.street = _this.streets[0].street; // 街道默认选择第一个

        _this.getViewMethod();
      });
    },
    streetChange: function streetChange(street) {
      // 街道列表改变赋值
      this.listQuery.street = street;
      this.getViewMethod();
    },
    selfTracks: function selfTracks(row) {
      // 查看轨迹
      this.$router.push({
        path: 'selftracks',
        query: {
          nowTime: row.workDate,
          weixinId: row.userId
        }
      });
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true; // console.log(this.listQuery);

      Service.getList(this.listQuery).then(function (response) {
        response.data.list.forEach(function (item) {
          item.deviceName = item.deviceName.split('/n');
        }); // console.log(response);

        _this2.list = response.data.list;
        _this2.total = response.data.rowsCount;
        _this2.listLoading = false;
      });
    },
    viewtracks: function viewtracks(row) {
      this.viewdialog = true;
      this.tracks = row.recyclerTracks;
    },
    getViewMethod: function getViewMethod() {
      if (this.listQuery.viewMethod == 0) {
        document.getElementById("amapContainer").style.display = "none";
        this.isViewMap = 0;
        this.getList();
      } else {
        this.isViewMap = 1;
        clearInterval(this.timersd); //map.clearMap(map.getAllOverlays('marker'))

        document.getElementById("amapContainer").style.display = "";
        this.getListMap(); // 放到tab

        this.initMap();
        this.fitMap();
      }
    },
    // 地图开始
    initMap: function initMap() {
      map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        zoom: 13,
        center: new AMap.LngLat(117.706870, 39.020960) // mapStyle: 'amap://styles/darkblue'

      });
    },
    addMarks: function addMarks(item) {
      var lon = item.newLon;
      var lat = item.newLat;
      var name = item.name;
      var street = item.street;
      var address = item.address;
      var arr = [];
      arr.push([lon, lat]);
      var infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -50)
      });
      var self = this;
      var icon = new AMap.Icon({});
      icon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(60, 101),
        // 图标的取图地址
        image: 'http://pic.touduila.com/Fp_XxfNfjBxyHWrmExLcB5nvMxE0',
        // 图标所用图片大小
        imageSize: new AMap.Size(25, 26)
      });
      var marker = new AMap.Marker({
        icon: icon,
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setLabel({
        offset: new AMap.Pixel(0, 0),
        //设置文本标注偏移量
        content: "<div class='info'>" + name + "</div>",
        //设置文本标注内容
        direction: 'top' //设置文本标注方位

      });

      var _html = '<div>姓名:' + name + '</div><div>街道:' + street + '</div><div>位置:' + address + '</div>';

      marker.content = _html;
      marker.on('mouseover', function (e) {
        infoWindow.setContent(e.target.content);
        infoWindow.open(map, marker.getPosition());
      });
      marker.on('mouseout', function (e) {
        infoWindow.close(map, e.target.getPosition());
      });
      marker.setMap(map);
    },
    fitMap: function fitMap() {
      // 自适应
      setTimeout(function () {
        map.setFitView();
      }, 0);
    },
    getListMap: function getListMap() {
      var _this3 = this;

      Service.getTracks({
        nowTime: this.listQuery.workTime,
        street: this.listQuery.street
      }).then(function (response) {
        map.clearMap(map.getAllOverlays('marker'));
        response.data.data.forEach(function (item) {
          _this3.addMarks(item);
        });

        _this3.fitMap();
      });
    },

    /*getListMap(){
    	if (this.timersd == null) {
    		this.timersd = setInterval(() => {
    			Service.getTracks({nowTime:this.listQuery.workTime,street:this.listQuery.street}).then(response => {
    				map.clearMap(map.getAllOverlays('marker'))
    				response.data.data.forEach(item=>{
    					this.addMarks(item);
    				})
    				this.fitMap()
    			})
    			
    		}, 10000);
    		
        } else {
    		this.timersd = null;
    		clearInterval(this.timersd);
    		this.kaiqi()
    	}
    },*/
    kaiqi: function kaiqi() {
      this.getListMap();
    },
    clears: function clears() {
      clearInterval(this.timersd);
      this.timersd = null;
    },
    // 地图结束
    // 导出
    exportlist: function exportlist() {
      location.href = '/tracks/excelRecyclerTracks?' + qs.stringify(this.listQuery);
    }
  }
};