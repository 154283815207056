var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("zhanghaoguanlion:insert")
            ? _c(
                "el-button",
                {
                  staticClass:
                    "el-button filter-item el-button--primary el-button--medium",
                  staticStyle: { margin: "30px" },
                  attrs: { size: "medium", type: "primary" },
                  on: { click: _vm.Tzhang },
                },
                [_vm._v("添加账号")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.id)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "账号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "密码" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.password)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.accountName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "区县" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.districtName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.street)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "区县权限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(
                        _vm._s(
                          scope.row.permissionStatus == 0 ? "关闭" : "开启"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "允许切换街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.status == 0 ? "关闭" : "开启")),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "添加时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("zhanghaoguanlion:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("zhanghaoguanlion:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加账号", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accountName", $$v)
                      },
                      expression: "form.accountName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "省", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省" },
                      on: { change: _vm.getCity },
                      model: {
                        value: _vm.form.provinceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "provinceId", $$v)
                        },
                        expression: "form.provinceId",
                      },
                    },
                    _vm._l(_vm.province, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "市", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择市" },
                      on: { change: _vm.getDistrict },
                      model: {
                        value: _vm.form.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cityId", $$v)
                        },
                        expression: "form.cityId",
                      },
                    },
                    _vm._l(_vm.city, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "区/县", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区/县" },
                      on: { change: _vm.changeDistrict },
                      model: {
                        value: _vm.form.districtId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "districtId", $$v)
                        },
                        expression: "form.districtId",
                      },
                    },
                    _vm._l(_vm.district, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      on: { change: _vm.Begin },
                      model: {
                        value: _vm.form.streetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "streetId", $$v)
                        },
                        expression: "form.streetId",
                      },
                    },
                    _vm._l(_vm.stree, function (i) {
                      return _c("el-option", {
                        key: i.street,
                        attrs: { label: i.street, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区县权限",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1", border: "" },
                      model: {
                        value: _vm.form.permissionStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "permissionStatus", $$v)
                        },
                        expression: "form.permissionStatus",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0", border: "" },
                      model: {
                        value: _vm.form.permissionStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "permissionStatus", $$v)
                        },
                        expression: "form.permissionStatus",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "允许切换街道",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1", border: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0", border: "" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "定制类", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1", border: "" },
                      model: {
                        value: _vm.form.permissionCustomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "permissionCustomStatus", $$v)
                        },
                        expression: "form.permissionCustomStatus",
                      },
                    },
                    [_vm._v("开")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0", border: "" },
                      model: {
                        value: _vm.form.permissionCustomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "permissionCustomStatus", $$v)
                        },
                        expression: "form.permissionCustomStatus",
                      },
                    },
                    [_vm._v("关")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.customDatas,
                        callback: function ($$v) {
                          _vm.customDatas = $$v
                        },
                        expression: "customDatas",
                      },
                    },
                    _vm._l(_vm.screenCustoms, function (item) {
                      return _c("el-checkbox", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.name)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Addz } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "编辑账号", visible: _vm.FormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.FormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.forms } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "账号", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.forms.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "name", $$v)
                      },
                      expression: "forms.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "密码", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "password", $$v)
                      },
                      expression: "forms.password",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "姓名", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.accountName,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "accountName", $$v)
                      },
                      expression: "forms.accountName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "省", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择省" },
                      on: { change: _vm.getCityEdit },
                      model: {
                        value: _vm.forms.provinceId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "provinceId", $$v)
                        },
                        expression: "forms.provinceId",
                      },
                    },
                    _vm._l(_vm.province, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "市", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择市" },
                      on: { change: _vm.getDistrictEdit },
                      model: {
                        value: _vm.forms.cityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "cityId", $$v)
                        },
                        expression: "forms.cityId",
                      },
                    },
                    _vm._l(_vm.city, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "区/县", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择区/县" },
                      on: { change: _vm.changeDistrictEdit },
                      model: {
                        value: _vm.forms.districtId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "districtId", $$v)
                        },
                        expression: "forms.districtId",
                      },
                    },
                    _vm._l(_vm.district, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.cityName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      on: { change: _vm.Begin },
                      model: {
                        value: _vm.forms.streetId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "streetId", $$v)
                        },
                        expression: "forms.streetId",
                      },
                    },
                    _vm._l(_vm.stree, function (i) {
                      return _c("el-option", {
                        key: i.street,
                        attrs: { label: i.street, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "区县权限",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.forms.permissionStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "permissionStatus", $$v)
                        },
                        expression: "forms.permissionStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "允许切换街道",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.forms.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "status", $$v)
                        },
                        expression: "forms.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "定制类", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.forms.permissionCustomStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "permissionCustomStatus", $$v)
                        },
                        expression: "forms.permissionCustomStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.customDatas,
                        callback: function ($$v) {
                          _vm.customDatas = $$v
                        },
                        expression: "customDatas",
                      },
                    },
                    _vm._l(_vm.screenCustoms, function (item) {
                      return _c("el-checkbox", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.name)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.FormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Streets } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }