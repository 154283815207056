import "core-js/modules/es6.number.constructor";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import addrselect from '@/components/street/noeladdstreet';
import Pagination from '@/components/Pagination';
import Service from '@/api/service';
import noel from '@/components/street/street';
import actionService from '@/api/actionstation';
import waves from '@/directive/waves'; // waves directive

var map = null;
var marker = null;
export default {
  components: {
    addrselect: addrselect,
    Pagination: Pagination,
    noel: noel
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      marker: '',
      state: {},
      stat: {},
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: '',
        community: '',
        region: '',
        streetId: '',
        key: ""
      },
      total: 0,
      dialogTableVisible: false,
      dialogFormVisible: false,
      TableVisible: false,
      FormVisible: false,
      form: {
        address: '',
        stationName: '',
        streetId: '',
        communityId: '',
        regionId: '',
        lon: '',
        lat: '',
        uuid: ""
      },
      forms: {
        pass: ''
      },
      formLabelWidth: '120px',
      listLoading: true,
      list: [],
      stree: '',
      puts: {
        pass: '',
        id: '',
        Jid: ''
      },
      po: '',
      Xiupass: {
        pass: '',
        id: '',
        Jid: ''
      },
      Visible: false,
      mpas: '',
      lng: '',
      lat: '',
      rules: {
        stationName: [{
          required: true,
          message: "行动站名称是必填项"
        }],
        street: [{
          required: true,
          message: "街道是必选项"
        }]
        /*address: [{
        	required: true,
        	message: "详细位置是必选项"
        }],*/

      },
      rulesGp: {
        gpCode: [{
          required: true,
          message: "标牌编码是必填项"
        }]
      },
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      streetId: '',
      communityId: '',
      regionId: '',
      kong: '',
      uploadPic: [],
      pic: [],
      // 标牌
      dialogGpFormVisible: false,
      formGp: {
        gpCode: "",
        siteId: "",
        street: "",
        community: "",
        region: "",
        state: "",
        stationName: ""
      },
      maps: true
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    addGp: function addGp(e) {
      this.dialogGpFormVisible = true;
      this.formGp.siteId = e.uuid; // 站定ID

      this.formGp.stationName = e.stationName;
      this.formGp.street = e.street;
      this.formGp.community = e.community;
      this.formGp.region = e.region;
      this.formGp.state = 0; // 标牌状态：0闲置/1已绑定
    },
    AddLabelSurl: function AddLabelSurl() {
      var _this = this;

      // 添加标牌
      this.$refs['dataGpForm'].validate(function (valid) {
        if (valid) {
          actionService.xdzManageGp(_this.formGp).then(function (res) {
            if (res.data.error) {
              _this.$notify({
                title: '失败',
                message: res.data.error,
                type: 'error',
                duration: 2000
              });

              return false;
            }

            _this.$message({
              type: 'success',
              message: '添加成功!'
            });

            _this.dialogGpFormVisible = false;

            _this.getList();
          });
        }
      });
    },
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;

      actionService.getList(this.listQuery).then(function (res) {
        //console.log(res.data.list);
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.rowsCount;
        _this2.listLoading = false;
      });
    },
    del: function del(row) {
      var _this3 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        actionService.xdxManageDelete(row.uuid).then(function (res) {
          if (res.data.error) {
            _this3.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this3.getList();
        });
      }).catch(function () {});
    },
    uploadImg: function uploadImg(response, file, fileList) {
      this.uploadPic = [];
      fileList = [];

      if (!this.uploadPic.includes(response.key)) {
        this.uploadPic.push(response.key);
      }

      console.log(this.uploadPic);
      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var index = this.uploadPic.indexOf(item);

      if (index > -1) {
        this.uploadPic.splice(index, 1);
      }
    },
    initMap: function initMap(lons, lats) {
      var newarrsd = [];
      map = null;
      marker = null;
      var self = this;
      marker, map = new AMap.Map('amapContainer', {
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        center: [lons, lats],
        resizeEnable: true,
        zoom: 14
      });
      newarrsd.push(lons, lats);
      marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: [lons, lats],
        offset: new AMap.Pixel(-20, -50)
      });
      marker.setMap(map);
      self.getmaps(newarrsd);
      map.on('click', function (e) {
        var arrs = [];
        arrs.push(e.lnglat.getLng(), e.lnglat.getLat());

        if (marker) {
          console.log(arrs);
          marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()]);
          self.getmaps(arrs);
        } else {
          console.log(arrs);
          marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: [e.lnglat.getLng(), e.lnglat.getLat()],
            offset: new AMap.Pixel(-20, -50)
          });
          marker.setMap(map);
          self.getmaps(arrs);
        }
      });
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: '天津市',
          suggest: self.form.pass,
          input: 'pickerInput',
          placeSearchOptions: {
            map: map,
            pageSize: 10
          },
          searchResultsContainer: 'searchResults'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$message({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.form.lat = positionResult.position.lat;
          self.form.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$message({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        }); // 选取了某个POI

        poiPicker.on('poiPicked', function (poiResult) {
          var arrs = [];
          map.setCenter(poiResult.item.location); // positionPicker.start(poiResult.item.location)

          arrs.push(poiResult.item.location.lng, poiResult.item.location.lat);
          self.getmaps(arrs);

          if (marker) {
            marker.setPosition([poiResult.item.location.lng, poiResult.item.location.lat]);
          } else {
            marker = new AMap.Marker({
              icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              position: [poiResult.item.location.lng, poiResult.item.location.lat]
            });
            marker.setMap(map);
          }
        });
      }
    },
    getmaps: function getmaps(arrs) {
      console.log(arrs);
      var self = this;
      self.form.lon = arrs[0];
      self.form.lat = arrs[1];
      console.log(arrs);
      map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '天津'
        });
        geocoder.getAddress(arrs, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            console.log(status, result);
            self.form.address = result.regeocode.formattedAddress;
          }
        });
      });
    },
    addrChange: function addrChange(data) {
      //console.log(data)
      this.addr.street = data.street;
      this.form.street = data.street;
      this.form.streetId = data.streetId;

      if (data.community) {
        this.addr.community = data.community;
        this.form.community = data.community;
        this.form.communityId = data.communityId;
      } else {
        this.addr.community = '';
        this.form.community = "";
        this.form.communityId = '';
      }

      if (data.region) {
        this.addr.region = data.region;
        this.form.region = data.region;
        this.form.regionId = data.regionId;
      } else {
        this.addr.region = '';
        this.form.region = '';
        this.form.regionId = '';
      }
    },
    Tzhang: function Tzhang() {
      var _this4 = this;

      this.addr.community = '';
      this.addr.region = '';
      this.addr.street = '';
      this.form.address = '', this.form.stationName = '', this.form.streetId = '', this.form.communityId = '', this.form.regionId = '', this.form.lon = '', this.form.lat = '';
      this.form.uuid = "";
      this.uploadPic = [];
      this.dialogFormVisible = true;
      setTimeout(function (res) {
        _this4.initMap(117.67974, 39.026702);
      }, 500);
    },
    // addpar() {
    // 	if (this.marker) {
    // 		this.marker.setMap(null);
    // 		this.marker = null;
    // 	}
    // 	this.marker = new AMap.Marker({
    // 		position: new AMap.LngLat(this.form.lon, this.form.lat)
    // 	});
    // 	let lnglat = {}
    // 	lnglat.lng = Number(this.form.log)
    // 	lnglat.lat = Number(this.form.lat)
    // 	this.$emit("giveLnglat", lnglat);
    // 	map.add(this.marker);
    // },
    Begin: function Begin(Jid) {
      //console.log(Jid)
      this.form.streetId = Jid;
    },
    Addz: function Addz() {
      var _this5 = this;

      if (this.form.stationName == '') {
        this.$notify({
          title: '失败',
          message: '行动站名称不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (this.form.streetId == '') {
        this.$notify({
          title: '失败',
          message: '街道不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      console.log(this.form);

      if (this.form.lon == '' || this.form.lat == '' || this.form.lon == null || this.form.lat == null) {
        this.$notify({
          title: '失败',
          message: '经度或维度不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.form.stationPic = "";
      /*if (this.form.lon == '' && this.form.lat == '') {
      	this.$notify({
      		title: '失败',
      		message: '地图坐标不能为空',
      		type: 'error',
      		duration: 2000
      	})
      	return false
      }*/

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // updateTime: 2021-01-08 15:53:56
          // createTime:
          //console.log(this.form);
          if (_this5.uploadPic.length != 0) {
            _this5.form.stationPic = _this5.uploadPic[0];
          } // delete this.form.pic
          //console.log(this.pic[]);


          if (_this5.form.uuid == null || _this5.form.uuid == '') {
            // 添加
            actionService.xdzManageAdd(_this5.form).then(function (res) {
              if (res.data.error) {
                _this5.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this5.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this5.dialogFormVisible = false;

                _this5.getList();
              }
            });
          } else {
            // 修改
            actionService.xdzManageUpdate(_this5.form).then(function (res) {
              if (res.data.error) {
                _this5.$message({
                  message: res.data.error,
                  type: 'error'
                });
              } else {
                _this5.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this5.dialogFormVisible = false;

                _this5.getList();
              }
            });
          }
        }
      });
    },
    viewGp: function viewGp(e) {
      //console.log(e);
      this.$router.push({
        path: 'RecyclableGp',
        query: {
          siteId: e.uuid
        }
      });
    },
    getJiedao: function getJiedao() {
      var _this6 = this;

      Service.getArtjied().then(function (res) {
        _this6.stree = res.data;
      });
    },
    Street: function Street(Jid) {
      var _this7 = this;

      this.puts.Jid = Jid; //console.log(this.puts)

      Service.updateJied(this.puts).then(function (res) {
        if (res.status == 200) {
          _this7.$message({
            message: '修改成功',
            type: 'success'
          });

          _this7.FormVisible = false;

          _this7.getList();
        }
      });
    },
    Streets: function Streets() {
      console.log(this.form);
    },
    Str: function Str(id, pass) {
      this.puts.id = id;
      this.puts.pass = pass;
    },
    edit: function edit(row) {
      var _this8 = this;

      //console.log(row)
      //if (row.kitchenRegion) {
      this.addr.street = row.street;
      this.addr.community = row.community;
      this.addr.region = row.region;
      this.addr.streetId = row.streetId;
      this.form.streetId = row.streetId;
      /*} else {
      	this.addr.street = row.street
      	this.addr.streetId = row.streetId
      	this.form.streetId = row.streetId
      }*/
      //this.form = row;

      console.log(row);

      if (row.lat == null) {
        this.form.lat = "39.026702";
      }

      if (row.lon == null) {
        this.form.lon = "117.67974";
      }

      if (row.streetId) {
        this.streetId = row.streetId;
      }

      if (row.communityId) {
        this.communityId = row.communityId;
      }

      if (row.regionId) {
        this.regionId = row.regionId;
      }

      this.dialogFormVisible = true;
      console.log(this.form);
      this.form.stationName = row.stationName;
      this.form.uuid = row.uuid;
      this.form.address = row.address;
      this.form.stationPic = row.stationPic;
      this.uploadPic = [];
      this.pic = [];

      if (this.form.stationPic != null && this.form.stationPic != '') {
        this.uploadPic.push(this.form.stationPic);
        this.pic.push(this.form.stationPic);
      }

      setTimeout(function (res) {
        _this8.initMap(row.lon, row.lat); // map.on('click', (e) => {
        // this.form.lon = e.lnglat.getLng()
        // this.form.lat = e.lnglat.getLat()
        // })
        // this.addMarks(row.lon, row.lat)

      }, 500);
      this.FormVisible = true;
    },
    addMarks: function addMarks(lon, lat) {
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(lon, lat)
      });
      var lnglat = {};
      lnglat.lng = Number(lon);
      lnglat.lat = Number(lat);
      this.$emit("giveLnglat", lnglat);
      map.add(this.marker);
    }
  },
  update: function update() {
    this.Street(Jid);
    this.del(rows);
    this.getJiedao();
  }
};