"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

var _objectSpread2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _router = _interopRequireDefault(require("./router"));

var _store = _interopRequireDefault(require("./store"));

var _elementUi = require("element-ui");

var _nprogress = _interopRequireDefault(require("nprogress"));

require("nprogress/nprogress.css");

var _auth = require("@/utils/auth");

// progress bar
// progress bar style
// getToken from cookie
_nprogress.default.configure({
  showSpinner: false
}); // NProgress Configuration
// permission judge function


function hasPermission(roles, permissionRoles) {
  if (roles.indexOf('admin') >= 0) return true; // admin permission passed directly

  if (!permissionRoles) return true;
  return roles.some(function (role) {
    return permissionRoles.indexOf(role) >= 0;
  });
}

var whiteList = ['/login', '/auth-redirect']; // no redirect whitelist

_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start(); // start progress bar


  if ((0, _auth.getToken)()) {
    // determine if there has token

    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });

      _nprogress.default.done(); // if current page is dashboard will not trigger	afterEach hook, so manually handle it

    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetUserInfo').then(function (res) {
          // 拉取user_info
          var roles = res.data.data.roles; // note: roles must be a array! such as: ['editor','develop']

          var accessAuthorityDtos = res.data.data.accessAuthorityDtos;

          _store.default.dispatch('GenerateRoutes', {
            roles: roles,
            accessAuthorityDtos: accessAuthorityDtos
          }).then(function () {
            // 根据roles权限生成可访问的路由表
            // console.log(store.getters.addRouters)
            _router.default.addRoutes(_store.default.getters.addRouters); // 动态添加可访问路由表


            next((0, _objectSpread2.default)((0, _objectSpread2.default)({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成 ,set the replace: true so the navigation will not leave a history record
          });
        }).catch(function (err) {
          _store.default.dispatch('FedLogOut').then(function () {
            _elementUi.Message.error(err || 'Verification failed, please login again');

            next({
              path: '/'
            });
          });
        });
      } else {
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        if (hasPermission(_store.default.getters.roles, to.meta.roles)) {
          next();
        } else {
          next({
            path: '/401',
            replace: true,
            query: {
              noGoBack: true
            }
          });
        } // 可删 ↑

      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      //next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      next("/login");

      _nprogress.default.done(); // if current page is login will not trigger afterEach hook, so manually handle it

    }
  }
});

_router.default.afterEach(function () {
  _nprogress.default.done(); // finish progress bar

});