//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/kitchensorting';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
import draggable from 'vuedraggable';
import { parseTime } from "@/utils";
import noel from '@/components/street/noeladdstreet';
export default {
  components: {
    Pagination: Pagination,
    draggable: draggable,
    noel: noel
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date(), new Date()],
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        sortDate: parseTime(new Date(), '{y}-{m}-{d}'),
        streetId: '',
        communityId: '',
        regionId: ''
      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      places: []
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getReport3(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    noelchange: function noelchange(data) {
      console.log(data);
      this.listQuery.streetId = data.streetId;
      this.listQuery.communityId = data.communityId;
      this.listQuery.regionId = data.regionId;
    }
  }
};