//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/tongji';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
export default {
  name: 'Huodongbaoming',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      datas: '',
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        activityId: '',
        nickName: '',
        // 姓名
        currentPage: 1,
        numOfPage: 20
      },
      dialogVisible: false
    };
  },
  created: function created() {
    this.listQuery.activityId = this.$route.query.id;
    this.getList();
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getBaomingList(this.listQuery).then(function (response) {
        console.log(response.data);
        _this.datas = response.data.data;
        _this.list = response.data.data.activityUserList;
        _this.total = response.data.data.totalRows;
        _this.listLoading = false;
      });
    }
  }
};