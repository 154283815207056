import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import jiedao from '@/components/street/jiedaolist';
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  data: function data() {
    return {
      dialog: false,
      searchStreet: null,
      listLoading: true,
      list: [],
      total: 0,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      forms: {
        typeInfo: '',
        //打卡类型
        coin: '',
        //奖励积分
        ruler: '',
        //活动图片上传要求
        status: '',
        // 开启/关闭状态
        pic: '',
        //图片
        street: '',
        //街道
        role: '',
        //小程序角色
        des: '',
        //活动说明
        auditMethod: '',
        // 审核方式
        clockPic: '' //打卡样例图

      },
      communityList: [{
        region: '开启',
        id: 1
      }, {
        region: '关闭',
        id: 0
      }],
      addstatus: '添加类型',
      datas: [{
        name: '居民小程序',
        value: 1
      }, {
        name: '生态城小程序',
        value: 40
      }, {
        name: '泰达投对小程序',
        value: 80
      }, {
        name: '什邡智慧分类',
        value: 70
      }],
      dialogTableVisible: false,
      typeInfos: [{
        name: "厨余打卡",
        value: "厨余打卡"
      }, {
        name: "减碳回收",
        value: "减碳回收"
      }, {
        name: "光盘行动",
        value: "光盘行动"
      }
      /* {name:"环保出行",value:"环保出行"}*/
      ],
      auditMethods: [{
        name: '自动审核通过',
        value: 0
      }, {
        name: '人工审核',
        value: 1
      }],
      rules: {
        typeInfo: [{
          required: true,
          message: '请选择打卡类型',
          trigger: 'blur'
        }],
        role: [{
          required: true,
          message: '请选择针对客户端',
          trigger: 'blur'
        }],
        street: [{
          required: true,
          message: '请填写街道',
          trigger: 'blur'
        }],
        coin: [{
          required: true,
          message: '请填写积分',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'blur'
        }],
        auditMethod: [{
          required: true,
          message: '请选择审核方式',
          trigger: 'blur'
        }],
        des: [{
          required: true,
          message: '请填写活动说明',
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.forms.street = val;
      } else {
        this.forms.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    addType: function addType() {
      var _this = this;

      this.forms.id ? delete this.forms.id : '';
      this.dialog = true;
      Object.keys(this.forms).forEach(function (key) {
        _this.forms[key] = '', _this.searchStreet = '';
      });
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.getmanageActivity(this.listQuery).then(function (response) {
        console.log(response);
        var list = response.data.data.list;
        _this2.total = response.data.data.totalRows;
        _this2.list = list;
        _this2.listLoading = false;
      });
    },

    /*uploadImgs(data) {
    	console.log(data)
    	this.forms.pic = data.key
    },*/
    edit: function edit(row) {
      var _this3 = this;

      console.log(row);
      this.dialog = true;
      this.forms.id = row.id;
      this.searchStreet = row.street;
      Object.keys(this.forms).forEach(function (key) {
        _this3.forms[key] = row[key];
      });
    },
    record: function record(row) {
      var _this4 = this;

      Service.seemanageslist({
        id: row.id
      }).then(function (res) {
        _this4.$router.push({
          path: '/carbon/recordtype',
          query: {
            data: JSON.stringify(res.data.data)
          }
        });
      });
    },
    saves: function saves() {
      var _this5 = this;

      this.$refs['forms'].validate(function (valid) {
        if (valid) {
          // console.log(this.forms)
          Service.addoreditmanages(_this5.forms).then(function (res) {
            _this5.$message({
              type: 'success',
              message: '操作成功'
            });

            _this5.getList();

            _this5.dialog = false;
          });
        }
      });
    },
    getBannerVal: function getBannerVal() {
      console.log(this.forms.pic);
    },
    getClockVal: function getClockVal() {
      console.log(this.forms.clockPic);
    }
  }
};