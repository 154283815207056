"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _addrselect = _interopRequireDefault(require("@/components/street/addrselect"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _service = _interopRequireDefault(require("@/api/service"));

var _street = _interopRequireDefault(require("@/components/street/street"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// waves directive
var map = null;
var marker = null;
var _default = {
  components: {
    addrselect: _addrselect.default,
    Pagination: _Pagination.default,
    noel: _street.default
  },
  props: {},
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      tableKey: "tableKey",
      marker: '',
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: '',
        community: '',
        region: '',
        key: "",
        state: ''
      },
      total: 0,
      states: [{
        type: -1,
        info: '禁用'
      }, {
        type: 0,
        info: '待审核'
      }, {
        type: 1,
        info: '待完善'
      }, {
        type: 2,
        info: '通过'
      }],
      dialogFormVisible: false,
      // 添加or编辑
      form: {
        // address: '',
        street: '',
        community: '',
        region: '',
        pointName: '',
        pointType: "",
        longitude: '',
        latitude: '',
        pointPic: '',
        state: '',
        remark: ''
      },
      formLabelWidth: '120px',
      listLoading: true,
      list: [],
      // lng: '',
      // latitude: '',
      rules: {
        street: [{
          required: true,
          message: "街道是必选项"
        }],
        pointName: [{
          required: true,
          message: "点位名称是必填项"
        }],
        pointType: [{
          required: true,
          message: "点位类型是必填项"
        }],
        state: [{
          required: true,
          message: "状态是必选项"
        }]
      },
      addr: {
        community: '',
        region: '',
        street: '',
        streetId: ''
      },
      kong: '',
      uploadPic: [],
      maps: true,
      pointTypeList: [],
      images: "",
      // 点位弹框图
      dialog: false,
      disdayin: false,
      // 打印码显示
      rowData: {},
      // 操作记录开始
      disOperLog: false,
      // 操作记录是否显示
      listQueryOperLog: {
        id: '' // 点位id

      },
      operLogList: [],
      // 操作记录结束
      disDialogDevice: false,
      // 查看点位关联的设备弹框
      pointDeviceList: [] // 点位设备

    };
  },
  created: function created() {
    this.getPointTypeList();
    this.getList();
  },
  methods: {
    chakan: function chakan(row) {
      var _this = this;

      this.disDialogDevice = true;

      _service.default.pointAndDevice(row.mark).then(function (res) {
        _this.pointDeviceList = res.data.data;
      });
    },
    getPointTypeList: function getPointTypeList() {
      var _this2 = this;

      _service.default.getTypeList().then(function (res) {
        _this2.pointTypeList = res.data.data;
      });
    },
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;

      _service.default.getPointList(this.listQuery).then(function (res) {
        _this3.list = res.data.data.list;
        _this3.total = res.data.data.totalRows;
        _this3.listLoading = false;
      });
    },
    delPoint: function delPoint(row) {
      var _this4 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _service.default.deletePoint(row.id).then(function (res) {
          if (res.data.error) {
            _this4.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this4.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this4.getList();
        });
      }).catch(function () {});
    },
    uploadImg: function uploadImg(response, file, fileList) {
      this.uploadPic = [];
      fileList = [];

      if (!this.uploadPic.includes(response.key)) {
        this.uploadPic.push(response.key);
      }

      console.log(this.uploadPic);
      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var index = this.uploadPic.indexOf(item);

      if (index > -1) {
        this.uploadPic.splice(index, 1);
      }
    },
    initMap: function initMap(lons, lats) {
      var newarrsd = [];
      map = null;
      marker = null;
      var self = this;
      marker, map = new AMap.Map('amapContainer', {
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        center: [lons, lats],
        resizeEnable: true,
        zoom: 14
      });
      newarrsd.push(lons, lats);
      marker = new AMap.Marker({
        icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
        position: [lons, lats],
        offset: new AMap.Pixel(-20, -50)
      });
      marker.setMap(map);
      self.getmaps(newarrsd);
      map.on('click', function (e) {
        var arrs = [];
        arrs.push(e.lnglat.getLng(), e.lnglat.getLat());

        if (marker) {
          console.log(arrs);
          marker.setPosition([e.lnglat.getLng(), e.lnglat.getLat()]);
          self.getmaps(arrs);
        } else {
          console.log(arrs);
          marker = new AMap.Marker({
            icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
            position: [e.lnglat.getLng(), e.lnglat.getLat()],
            offset: new AMap.Pixel(-20, -50)
          });
          marker.setMap(map);
          self.getmaps(arrs);
        }
      });
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: '天津市',
          suggest: self.form.pass,
          input: 'pickerInput',
          placeSearchOptions: {
            map: map,
            pageSize: 10
          },
          searchResultsContainer: 'searchResults'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$message({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.form.latitude = positionResult.position.latitude;
          self.form.longitude = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$message({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        }); // 选取了某个POI

        poiPicker.on('poiPicked', function (poiResult) {
          var arrs = [];
          map.setCenter(poiResult.item.location); // positionPicker.start(poiResult.item.location)

          arrs.push(poiResult.item.location.lng, poiResult.item.location.latitude);
          self.getmaps(arrs);

          if (marker) {
            marker.setPosition([poiResult.item.location.lng, poiResult.item.location.latitude]);
          } else {
            marker = new AMap.Marker({
              icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
              position: [poiResult.item.location.lng, poiResult.item.location.latitude]
            });
            marker.setMap(map);
          }
        });
      }
    },
    getmaps: function getmaps(arrs) {
      console.log(arrs);
      var self = this;
      self.form.longitude = arrs[0];
      self.form.latitude = arrs[1];
      console.log(arrs);
      map.plugin('AMap.Geocoder', function () {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '天津'
        });
        geocoder.getAddress(arrs, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result为对应的地理位置详细信息
            console.log(status, result);
            self.form.address = result.regeocode.formattedAddress;
          }
        });
      });
    },
    addrChange: function addrChange(data) {
      //console.log(data)
      this.form.street = data.street;
      this.form.community = data.community;
      this.form.region = data.region;
    },
    addPoint: function addPoint() {
      var _this5 = this;

      this.form.street = '';
      this.form.community = '';
      this.form.region = '';
      this.form.pointName = '';
      this.form.pointType = "";
      this.form.longitude = '';
      this.form.latitude = '';
      this.form.pointPic = '';
      this.form.state = 2;
      this.form.remark = '';
      this.form.id = "";
      this.uploadPic = [];
      this.dialogFormVisible = true;
      setTimeout(function (res) {
        _this5.initMap(117.67974, 39.026702);
      }, 500);
    },
    addOrUpdatePoint: function addOrUpdatePoint() {
      var _this6 = this;

      if (!this.form.street) {
        this.$notify({
          title: '失败',
          message: '街道不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (!this.form.pointName) {
        this.$notify({
          title: '失败',
          message: '点位名称不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (!this.form.pointType) {
        this.$notify({
          title: '失败',
          message: '点位类型不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      } // if(this.form.state== ''){
      // 	this.$notify({
      // 		title: '失败',
      // 		message: '状态不能为空',
      // 		type: 'error',
      // 		duration: 2000
      // 	})
      // 	return false
      // }


      if (this.form.longitude == '' || this.form.latitude == '' || this.form.longitude == null || this.form.latitude == null) {
        this.$notify({
          title: '失败',
          message: '经度或维度不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.form.pointPic = "";
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          // updateTime: 2021-01-08 15:53:56
          // createTime:
          //console.log(this.form);
          if (_this6.uploadPic.length != 0) {
            _this6.form.pointPic = _this6.uploadPic[0];
          } // delete this.form.pic
          //console.log(this.pic[]);


          if (_this6.form.id == null || _this6.form.id == '') {
            // 添加
            _service.default.addPoint(_this6.form).then(function (res) {
              console.log(res.data);

              if (res.data.statusCode == 301) {
                _this6.$message({
                  message: res.data.data,
                  type: 'error'
                });
              } else {
                _this6.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this6.dialogFormVisible = false;

                _this6.getList();
              }
            });
          } else {
            // 修改
            _service.default.updatePoint(_this6.form).then(function (res) {
              if (res.data.statusCode == 301) {
                _this6.$message({
                  message: res.data.data,
                  type: 'error'
                });
              } else {
                _this6.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this6.dialogFormVisible = false;

                _this6.getList();
              }
            });
          }
        }
      });
    },
    editPoint: function editPoint(row) {
      var _this7 = this;

      this.form.street = row.street;
      this.form.community = row.community;
      this.form.region = row.region;
      this.form.pointName = row.pointName;
      this.form.pointType = row.pointType;
      this.form.longitude = row.longitude;
      this.form.latitude = row.latitude;
      this.form.pointPic = row.pointPic;
      this.form.state = row.state;
      this.form.remark = row.remark;
      this.form.id = row.id;

      if (row.latitude) {
        this.form.latitude = "39.026702";
      }

      if (row.longitude) {
        this.form.longitude = "117.67974";
      }

      this.dialogFormVisible = true;
      this.uploadPic = [];

      if (this.form.pointPic) {
        this.uploadPic.push(this.form.pointPic);
      }

      setTimeout(function (res) {
        _this7.initMap(row.longitude, row.latitude);
      }, 500);
    },
    addMarks: function addMarks(longitude, latitude) {
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(longitude, latitude)
      });
      var lnglat = {};
      lnglat.lng = Number(longitude);
      lnglat.latitude = Number(latitude);
      this.$emit("giveLnglat", lnglat);
      map.add(this.marker);
    },
    // 打印码开始
    erweima: function erweima(row) {
      this.disdayin = true;
      this.rowData = row;
    },
    dayin: function dayin() {
      _service.default.pointPrintPic(this.rowData.mark).then(function (res) {
        console.log(res); // const blob = new Blob([res.data], {
        // 	type: 'image/png'
        // })
        // if (window.navigator.msSaveOrOpenBlob) {
        // 	navigator.msSaveBlob(blob)
        // } else {
        // 	const elink = document.createElement('a')
        // 	elink.download = ''
        // 	elink.style.display = 'none'
        // 	elink.href = URL.createObjectURL(blob)
        // 	document.body.appendChild(elink)
        // 	elink.click()
        // 	document.body.removeChild(elink)
        // }
        // console.log(res)
      });
    },
    xiazai: function xiazai() {
      //location.href = '/operation/point/qrcode/picture/download?content='+this.rowData.mark
      _service.default.pointDownPic(this.rowData.mark).then(function (res) {
        // console.log(res)
        var blob = new Blob([res.data], {
          type: 'image/png'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var elink = document.createElement('a');
          elink.download = '';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    // 查看操作日志
    viewLog: function viewLog(row) {
      this.disOperLog = true;
      this.listQueryOperLog.id = row.id;
      this.getOperLogList();
    },
    // 操作日志列表
    getOperLogList: function getOperLogList() {
      var _this8 = this;

      this.listLoading = true;

      _service.default.getPointLogList(this.listQueryOperLog).then(function (response) {
        _this8.operLogList = response.data.data;
        _this8.listLoading = false;
      });
    },
    loadImage: function loadImage(e) {
      var _this9 = this;

      this.dialog = true;
      setTimeout(function () {
        _this9.images = e;
      }, 200);
    }
  }
};
exports.default = _default;