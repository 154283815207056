var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("商品类型")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                placeholder: "请选择商品类型",
                clearable: true,
              },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            _vm._l(_vm.statusLists, function (obj) {
              return _c("el-option", {
                key: obj.id,
                attrs: { label: obj.name, value: obj.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("所属街道")]
          ),
          _vm._v(" "),
          _c("userjiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.searchStreet,
              callback: function ($$v) {
                _vm.searchStreet = $$v
              },
              expression: "searchStreet",
            },
          }),
          _vm._v(" "),
          _vm.projectFieldVisibleIndex
            ? _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: {
                    "value-key": "id_project_index",
                    filterable: "",
                    placeholder: "请选择项目",
                    clearable: true,
                  },
                  model: {
                    value: _vm.listQuery.projectId,
                    callback: function ($$v) {
                      _vm.$set(_vm.listQuery, "projectId", $$v)
                    },
                    expression: "listQuery.projectId",
                  },
                },
                _vm._l(_vm.projectList, function (obj) {
                  return _c("el-option", {
                    key: obj.id,
                    attrs: { label: obj.projectName, value: obj.id },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("兑换日期")]
          ),
          _vm._v(" "),
          _c("el-date-picker", {
            attrs: {
              "picker-options": _vm.pickerOptions,
              type: "daterange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
              align: "right",
            },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("用户类型")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              on: { change: _vm.handleFilter },
              model: {
                value: _vm.listQuery.userType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "userType", $$v)
                },
                expression: "listQuery.userType",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-right": "1%" },
            },
            [_vm._v("商品")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "300px" },
            attrs: { placeholder: "请输入商品名称或编号查询" },
            model: {
              value: _vm.listQuery.shopInfo,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "shopInfo", $$v)
              },
              expression: "listQuery.shopInfo",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("用户信息")]
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入用户名称或手机号" },
            model: {
              value: _vm.listQuery.userInfo,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "userInfo", $$v)
              },
              expression: "listQuery.userInfo",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("duihuan:excel")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exports },
                },
                [_vm._v("导出Excel")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", { attrs: { width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "商品编号",
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.shopId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.shopName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.nickName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户地址", width: "270%" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.address)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.shopNum)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "消耗积分" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.useCion)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导员" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.recyclerName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "兑换时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }