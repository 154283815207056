var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "app-container", attrs: { div: "" } },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c("el-input", {
                staticClass: "filter-item",
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入名称" },
                model: {
                  value: _vm.listQuery.label,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "label", $$v)
                  },
                  expression: "listQuery.label",
                },
              }),
              _vm._v(" "),
              _vm.hasPerm("managertag:select")
                ? _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("\n\t\t\t     查询\n\t\t\t")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPerm("managertag:insert")
                ? _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.addAiPicDataset },
                    },
                    [_vm._v("\n\t\t\t\t添加\n\t\t    ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%", "margin-bottom": "20px" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "label", label: "图像数据集名称" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "标注统计" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "green",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(row.alreadyCount))]
                        ),
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v(" / " + _vm._s(row.totalCount)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _vm.hasPerm("managertag:tag")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addAiPicTagView(row)
                                  },
                                },
                              },
                              [_vm._v("标注")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("managertag:importpic")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.importAiPicDataset(row)
                                  },
                                },
                              },
                              [_vm._v("导入图像素材")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasPerm("managertag:edit")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editAiPicDataset(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.listQuery.currentPage,
              limit: _vm.listQuery.numOfPage,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.listQuery, "currentPage", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "numOfPage", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加/编辑数据集",
            visible: _vm.dialogAddOrUpdateFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrUpdateFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据集名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "label",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "label", $$v)
                      },
                      expression: "form.label",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddOrUpdateFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入图像素材",
            visible: _vm.dialogImportFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImportFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.importPicForm, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数据集名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "label",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.importPicForm.label,
                      callback: function ($$v) {
                        _vm.$set(_vm.importPicForm, "label", $$v)
                      },
                      expression: "importPicForm.label",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "pictureUpload",
                      attrs: {
                        multiple: true,
                        action: _vm.uploadFileUrl,
                        accept: ".jpg,.png,.jpeg,bmp,.JPG,.PNG,.JPEG,BMP",
                        "list-type": "picture-card",
                        disabled: _vm.check,
                        data: _vm.importPicForm,
                        "on-change": _vm.handleOnChange,
                        "before-upload": _vm.beforUpload,
                        "on-success": _vm.handleUploadSuccess,
                        "auto-upload": false,
                        "file-list": _vm.showFile,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "file",
                          fn: function (ref) {
                            var file = ref.file
                            return _c("div", {}, [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: { src: file.url, alt: "" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                      attrs: { disabled: true },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handlePictureCardPreview(
                                            file
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.check
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleRemoveThis(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ])
                          },
                        },
                      ]),
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogImportFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              !_vm.check
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitUpload },
                    },
                    [_vm._v("上传")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "预览",
            width: "800",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              display: "block",
              "max-width": "100%",
              margin: "0 auto",
            },
            attrs: { src: _vm.dialogImageUrl },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { height: "1000px" },
          attrs: { title: "图像标注", visible: _vm.dialogTagFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTagFormVisible = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { display: "flex" } }, [
            _c("div", { staticStyle: { flex: "1" } }, [
              _c(
                "div",
                [
                  _c(
                    "el-carousel",
                    {
                      staticClass: "carousel",
                      attrs: {
                        "indicator-position": "outside",
                        arrow: "always",
                        autoplay: false,
                        loop: true,
                      },
                    },
                    _vm._l(
                      Math.ceil(_vm.imgUrlList.length / 8),
                      function (item) {
                        return _c("el-carousel-item", { key: item }, [
                          _c(
                            "div",
                            { staticClass: "content" },
                            _vm._l(
                              _vm.imgUrlList.slice((item - 1) * 8, item * 8),
                              function (item, index) {
                                return _c(
                                  "span",
                                  {
                                    key: index,
                                    staticClass: "content-item",
                                    staticStyle: { padding: "5px" },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "num-item",
                                        staticStyle: {
                                          "padding-left": "5px",
                                          "padding-bottom": "10px",
                                          position: "relative",
                                        },
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "110px",
                                            height: "110px",
                                          },
                                          attrs: { src: item.url },
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeImg(item, index)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              left: "10px",
                                              bottom: "15px",
                                              color: "red",
                                              "font-weight": "bold",
                                            },
                                          },
                                          [_vm._v(_vm._s(item.bussinessData))]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      }
                    ),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "left", staticStyle: { flex: "1" } }, [
                _c("img", {
                  ref: "imgDiv",
                  staticClass: "img",
                  attrs: { src: _vm.mainImgUrl },
                  on: {
                    mousedown: function ($event) {
                      $event.preventDefault()
                      return _vm.dropImage($event)
                    },
                    mousewheel: function ($event) {
                      $event.preventDefault()
                      return _vm.gunlun($event)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { flex: "1" } }, [
              _c("div", { staticClass: "tag-group" }, [
                _c(
                  "span",
                  {
                    staticClass: "tag-group__title",
                    staticStyle: {
                      "margin-bottom": "20px",
                      "margin-left": "170px",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v("标签集")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      color: "red",
                      "padding-top": "10px",
                      "text-align": "center",
                      "font-size": "12px",
                    },
                  },
                  [_vm._v("选择图像标签集即可完成图像标注")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "center",
                      "flex-wrap": "wrap",
                    },
                  },
                  _vm._l(_vm.items, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticStyle: {
                          "margin-top": "10px",
                          "margin-left": "10px",
                          "text-align": "center",
                          flex: "0.3",
                        },
                        attrs: { type: item.label },
                      },
                      [
                        item.id == _vm.tagForm.tagId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveTag(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.id != _vm.tagForm.tagId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "danager", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveTag(item)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.label))]
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }