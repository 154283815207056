import request from '@/utils/request';
var dizhiService = {
  jifenkaList: function jifenkaList(params) {
    return request({
      url: '/operator/kitchenWaste/userCoinQrcodeList',
      method: 'get',
      params: params
    });
  },
  jifenduihuanList: function jifenduihuanList(params) {
    return request({
      url: '/operator/kitchenWaste/recycleCoinRecordInfoList',
      method: 'get',
      params: params
    });
  },
  jifenkaQrcodeList: function jifenkaQrcodeList(params) {
    return request({
      url: '/operator/kitchenWaste/getUserCoinQrcodePhotoList',
      method: 'get',
      params: params
    });
  },
  dizhiList: function dizhiList(params) {
    return request({
      url: '/operator/kitchenWaste/getKinchenRegions',
      method: 'get',
      params: params
    });
  },
  addDizhi: function addDizhi(data) {
    return request({
      url: '/operator/kitchenWaste/insertKinchenRegionsLocation',
      method: 'post',
      data: data
    });
  },
  updateDizhi: function updateDizhi(data) {
    return request({
      url: '/operator/kitchenWaste/updateKinchenRegionsLocation',
      method: 'post',
      data: data
    });
  }
};
export default dizhiService;