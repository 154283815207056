import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dizhiService from '@/api/dizhi';
import qs from 'qs';
import tongService from '@/api/tongji';
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      times: '',
      beizhuVisibles: false,
      commid: '',
      uploadPic: [],
      formLabelWidth: '120px',
      searchStreet: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: null,
        status: null,
        currentPage: 1,
        numOfPage: 20
      },
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      Options: [],
      Option: [{
        name: '全部',
        value: null
      }, {
        name: '待审核',
        value: 0
      }, {
        name: '已审核',
        value: 1
      }],
      dialogs: false,
      alertlist: '',
      datalist: '',
      form: {
        createTime: '',
        submitTime: '',
        name: '',
        phone: '',
        street: '',
        community: '',
        kitchenBinsInfo: '',
        total: 0,
        idOnley: '',
        weixinId: ''
      },
      forms: {
        submitTime: '',
        street: '',
        community: '',
        kitchenBinsInfo: [],
        total: 0,
        status: '',
        id: '',
        weixinId: ''
      },
      dialogImageUrl: '',
      dialogVisible: false,
      listQuerys: {
        currentPage: 1,
        numOfPage: 20
      },
      lists: '',
      arrs: [],
      imgsp: []
    };
  },
  watch: {
    times: function times(val) {
      this.datatimes = parseTime(val, '{y}-{m}');
    },
    "form.submitTime": function formSubmitTime(val) {
      console.log();
      this.forms.submitTime = this.dateFormat(val);
    },
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    exports: function exports() {
      if (!this.datatimes) {
        this.$notify({
          title: '失败',
          message: '时间不能为空',
          type: 'error',
          duration: 2000
        });
      } else {
        location.href = '/recycle/downlocadExcel?dateTime=' + this.datatimes;
      }
    },
    exportlist: function exportlist() {
      this.beizhuVisibles = true;
    },
    dateFormat: function dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? '0' + m : m;
      var d = date.getDate();
      d = d < 10 ? '0' + d : d;
      var time = y + '-' + m + '-' + d;
      return time;
    },
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.dialogVisible = true;
    },
    inputchange: function inputchange(e) {
      console.log(e);
      var totals = 0;
      this.form.total = 0;
      e.forEach(function (item, index) {
        totals += Number(item.kitchenBins);
      });
      this.form.total = Number(totals).toFixed(1);
    },
    Sures: function Sures() {
      var _this = this;

      console.log(this.form);
      this.forms.kitchenBinsInfo = [];
      this.forms.community = this.form.community;
      this.form.kitchenBinsInfo.forEach(function (item, index) {
        _this.forms.kitchenBinsInfo.push({
          kitchenBins: item.kitchenBins,
          region: item.region,
          id: item.id
        });
      });
      this.forms.kitchenBinsInfo = this.forms.kitchenBinsInfo;
      this.forms.status = 1;
      this.forms.street = this.form.street;
      this.forms.total = this.form.total;
      this.forms.id = this.form.idOnley;
      this.forms.weixinId = this.form.weixinId;
      console.log(this.forms);
      Service.shujushenheup(this.forms).then(function (res) {
        console.log(res);

        if (res.data.data == 'ok') {
          _this.$notify({
            title: '成功',
            message: '审核成功',
            type: 'success',
            duration: 2000
          });

          _this.getList();

          _this.beizhuVisible = false;
        } else {
          _this.$notify({
            title: '失败',
            message: '审核',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = e.goods;
    },
    handleSelectionChange: function handleSelectionChange(e) {
      var _this2 = this;

      console.log(e);
      this.imgsp = [];
      e.forEach(function (item, index) {
        _this2.imgsp.push({
          id: item.idOnley,
          street: item.street,
          submitTime: item.submitTime
        });
      });
    },
    exportimg: function exportimg() {
      var _this3 = this;

      if (this.imgsp.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择导出项',
          type: 'error',
          duration: 2000
        });
      } else {
        this.imgsp.forEach(function (item) {
          setTimeout(function () {
            _this3.getimgs(item.id, item.street, item.submitTime);
          }, 1000);
        });
      }
    },
    successlist: function successlist() {
      var _this4 = this;

      var n = 1;

      if (this.imgsp.length == 0) {
        this.$notify({
          title: '失败',
          message: '请选择通过项',
          type: 'error',
          duration: 2000
        });
      } else {
        this.imgsp.forEach(function (item) {
          setTimeout(function () {
            _this4.getsuccess(item.id, item.street, item.submitTime);

            n++;
          }, 1000);

          if (n == _this4.imgsp.length) {
            _this4.getList();
          }
        });
      }
    },
    getsuccess: function getsuccess(id, street, submitTime) {
      var _this5 = this;

      tongService.exportrecycle({
        id: id
      }).then(function (res) {
        _this5.$notify({
          title: '成功',
          message: '审核成功',
          type: 'success',
          duration: 2000
        });

        _this5.getList();
      }); // location.href = '/recycle/domnloadimg?id=' + id
    },
    getimgs: function getimgs(id, street, submitTime) {
      Service.shenheimgs(id).then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res.data], {
          type: 'application/zip'
        }));
        var link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', street + '(' + submitTime + ')'); // 文件名

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // 下载完成移除元素

        window.URL.revokeObjectURL(url); // 释放掉blob对象
      }); // location.href = '/recycle/domnloadimg?id=' + id
    },
    getList: function getList() {
      var _this6 = this;

      Service.getshujushenhe(this.listQuery).then(function (response) {
        console.log(response);
        _this6.total = response.data.data.totalRows;
        _this6.list = response.data.data.list;
        console.log(_this6.list);
        setTimeout(function () {
          _this6.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    beizhu: function beizhu(row) {
      var _this7 = this;

      this.datalist = row;
      this.form = row;

      if (row.img != null) {
        this.uploadPic = row.img.split(',');
      }

      this.listQuerys.street = row.street;
      this.arrs = this.form.kitchenBinsInfo;
      console.log(this.form.community);

      if (row.community == '全部社区') {
        this.commid = 1;
        dizhiService.xiaoquList(this.listQuerys).then(function (res) {
          var response = res.data;
          _this7.lists = response.list;

          if (response.list) {
            response.list.forEach(function (list, indexs) {
              _this7.arrs.forEach(function (item, index) {
                if (item.region == list.region) {
                  item.community = list.community;
                }
              });
            });
            _this7.arrs = _this7.arrs;
            _this7.beizhuVisible = true;
          }
        });
      } else {
        this.beizhuVisible = true;
        this.commid = 0;
      }
    }
  }
};