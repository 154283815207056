var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.searchStreet,
              callback: function ($$v) {
                _vm.searchStreet = $$v
              },
              expression: "searchStreet",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v("搜索")]
          ),
          _vm._v(" "),
          _vm.hasPerm("luckindex:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加奖品")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", prop: "id", sortable: "", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.serialNumber))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品图片", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pic
                      ? _c("el-avatar", {
                          attrs: {
                            size: 60,
                            src: "http://pic.touduila.com/" + scope.row.pic,
                            shape: "square",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖品数量", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.number))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    !scope.row.status
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "red" },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status
                      ? _c(
                          "span",
                          {
                            staticClass: "link-type",
                            staticStyle: { color: "green" },
                          },
                          [_vm._v("上架")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "管理",
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("luckindex:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.showUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "90%",
            top: "20px",
            visible: _vm.dialogVisible,
            title: _vm.titles,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品数量", prop: "number" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "奖品图片", prop: "pic" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    [
                      _vm.form.pic
                        ? _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.form.pic,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择上下架状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.communityList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.region, value: obj.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道", prop: "street" } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.SureStreet,
                      callback: function ($$v) {
                        _vm.SureStreet = $$v
                      },
                      expression: "SureStreet",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }