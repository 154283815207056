var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.reserveType,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "reserveType", $$v)
                },
                expression: "listQuery.reserveType",
              },
            },
            _vm._l(_vm.Option, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("schoolName", {
            staticClass: "filter-item",
            model: {
              value: _vm.listQuery.school,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "school", $$v)
              },
              expression: "listQuery.school",
            },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入查询条件" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "filter-item" },
            [
              _c("el-date-picker", {
                attrs: {
                  "picker-options": _vm.pickerOptions,
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  align: "right",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getList },
            },
            [_vm._v("查询")]
          ),
          _vm._v(" "),
          _vm.hasPerm("studentindex:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出excel")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("studentindex:exportpic")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportimg },
                },
                [_vm._v("导出图片")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "订单号",
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "创建时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.studentName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.studentPhone))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "学校/年级/班级", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.school) +
                          "，" +
                          _vm._s(scope.row.grade) +
                          "，" +
                          _vm._s(scope.row.clazz)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "参与图片",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.studentPic, function (item) {
                    return _c("img", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: "http://pic.touduila.com/" + item },
                      on: {
                        click: function ($event) {
                          return _vm.dakai(scope.row.studentPic)
                        },
                      },
                    })
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "下单时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "完成时间",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 10
                      ? _c("span", [_vm._v(_vm._s(scope.row.updateTime))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "垃圾类型",
              "class-name": "status-col",
              width: "95",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.typeName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收物总重", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weight) + "Kg")])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "回收员拍照",
              "class-name": "status-col",
              width: "180",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pic
                      ? _c("img", {
                          staticStyle: { width: "160px", height: "160px" },
                          attrs: {
                            src: "http://pic.touduila.com/" + scope.row.pic,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "总支付金额", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.pay
                      ? _c("span", [_vm._v(_vm._s(scope.row.pay.fee / 100))])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "关联用户", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.chasee(scope.row.studentRelation)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收员", type: "expand" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.recycler
                      ? [
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "微信： " +
                                _vm._s(scope.row.recycler.weixinId) +
                                " "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "回收员： " + _vm._s(scope.row.recycler.name)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "电话： " + _vm._s(scope.row.recycler.phone)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "el-tag" }, [
                            _vm._v(
                              "住址： " + _vm._s(scope.row.recycler.apartment)
                            ),
                          ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusFilter")(scope.row.status),
                        },
                      },
                      [_vm._v(_vm._s(scope.row.statusInfo))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteOrder(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 10 && !scope.row.payTime
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.Completepayment(scope.row)
                              },
                            },
                          },
                          [_vm._v("完成支付")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.exportimgSolo(scope.row.id)
                          },
                        },
                      },
                      [_vm._v("导出")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "备注" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.beizhuTemp, "label-position": "left" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      autosize: { minRows: 2, maxRows: 4 },
                      type: "textarea",
                      placeholder: "备注信息",
                    },
                    model: {
                      value: _vm.beizhuTemp.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.beizhuTemp, "remark", $$v)
                      },
                      expression: "beizhuTemp.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doRemark } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.mapVisible, title: "地图" },
          on: {
            "update:visible": function ($event) {
              _vm.mapVisible = $event
            },
          },
        },
        [
          _c("div", { attrs: { id: "amapContainer" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.mapVisible = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogs, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogs = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "70%", margin: "0 auto" },
              attrs: { data: _vm.alertlist, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "垃圾类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "重量", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.weigh) + " Kg")]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pic
                          ? _c("img", {
                              staticStyle: { width: "100px", height: "100px" },
                              attrs: {
                                src: "http://pic.touduila.com/" + scope.row.pic,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogs = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "打印自助回收订单码", visible: _vm.dialoges },
          on: {
            "update:visible": function ($event) {
              _vm.dialoges = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { model: _vm.lists, "label-width": "120px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设置打印数量" } },
                [
                  _c("el-input", {
                    directives: [{ name: "Int", rawName: "v-Int" }],
                    attrs: { type: "input" },
                    model: {
                      value: _vm.lists.num,
                      callback: function ($$v) {
                        _vm.$set(_vm.lists, "num", $$v)
                      },
                      expression: "lists.num",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.shezhiprint } },
                [_vm._v(" 确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "关联用户", visible: _vm.seedialogs, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.seedialogs = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.seedata, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope2) {
                      return [_c("span", [_vm._v(_vm._s(scope2.row.name))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "电话" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope2) {
                      return [_c("span", [_vm._v(_vm._s(scope2.row.phone))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "地址" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope2) {
                      return [_c("span", [_vm._v(_vm._s(scope2.row.address))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.seedialogs = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.seedialogs = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "图片", visible: _vm.dialog, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _vm._l(_vm.imgs, function (item) {
            return _c("img", {
              staticStyle: {
                width: "300px",
                height: "300px",
                "margin-right": "50px",
              },
              attrs: { src: "https://pic.touduila.com/" + item },
            })
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }