import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    // dtu设备列表分页
    return request({
      url: '/operator/kitchenWaste/dtu/list',
      method: 'get',
      params: params
    });
  },
  addDtu: function addDtu(params) {
    // 添加dtu
    return request({
      url: '/operator/kitchenWaste/dtu/add',
      method: 'post',
      params: params
    });
  },
  updateDtu: function updateDtu(params) {
    // 添加or修改dtu
    return request({
      url: '/operator/kitchenWaste/dtu/edit',
      method: 'post',
      params: params
    });
  },
  del: function del(params) {
    // 删除设备
    return request({
      url: '/operator/kitchenWaste/dtu/delete?mbId=' + params.mbId + "&formId=" + params.formId,
      method: 'post'
    });
  },
  configuration: function configuration(params) {
    // dtu设备投口配置
    return request({
      url: '/operator/kitchenWaste/dtu/configuration',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  delConfig: function delConfig(params) {
    // 删除DTU投口
    return request({
      url: '/operator/kitchenWaste/dtu/config/delete?mbId=' + params.mbId + "&formId=" + params.formId,
      method: 'post'
    });
  },
  move: function move(params) {
    // dtu设备配置迁移
    return request({
      url: '/operator/kitchenWaste/dtu/move',
      method: 'post',
      params: params
    });
  },
  dtuDisplay: function dtuDisplay(params) {
    // dtu设备投口配置显示
    return request({
      url: '/operator/kitchenWaste/dtu/display?mbId=' + params.mbId + "&formId=" + params.formId,
      method: 'get'
    });
  }
};
export default Service;