import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dizhiService from "@/api/dizhi";
import Pagination from "@/components/Pagination";
import shequSearch from "@/components/street/shequSearch";
export default {
  name: "editStreet",
  components: {
    Pagination: Pagination,
    shequSearch: shequSearch
  },
  data: function data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: ""
      },
      id: null,
      types: []
    };
  },
  created: function created() {
    this.listQuery.street = this.$route.query.data;
    this.getList();
    this.getAllRegionType();
  },
  methods: {
    addrChange: function addrChange(data) {
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      dizhiService.xiaoquList(this.listQuery).then(function (res) {
        // console.log(res);
        var response = res.data;
        _this.list = response.list;
        _this.total = response.totalRows;
        _this.listLoading = false;
      });
    },
    getAllRegionType: function getAllRegionType() {
      var _this2 = this;

      dizhiService.getAllRegionType().then(function (res) {
        _this2.types = res.data.data;
      });
    },
    isCheck: function isCheck(type, list) {
      var flag = false;
      list.forEach(function (item) {
        if (item.type == type.type) {
          flag = true;
        }
      });
      return flag;
    },
    doselect: function doselect(item, _type, evt) {
      var regionId = item.id;
      var type = _type.type;
      console.log(regionId);
      console.log(type);

      if (evt) {
        //选中
        dizhiService.modifyRegionRecoveryType({
          "regionId": regionId,
          "type": type
        }).then(function (res) {
          console.log(res);
        });
      } else {
        //取消选中
        dizhiService.removeRegionRecoveryType({
          "regionId": regionId,
          "type": type
        }).then(function (res) {
          console.log(res);
        });
      }
    }
  }
};