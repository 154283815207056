import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import ServiceProject from '@/api/project';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
import Service from '@/api/dizhi'; //import regions from '@/components/street/regions'

export default {
  components: {
    Pagination: Pagination //regions

  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: "" // 查询条件

      },
      streets: [],
      // 街道列表
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      dialogAddOrUpdateFormVisible: false,
      form: {
        id: "",
        projectName: "",
        street: '',
        streetId: '',
        community: '',
        communityId: '',
        regionIds: '',
        state: "",
        begTime: "",
        endTime: ""
      },
      regionObjs: [],
      // 下拉集合用
      regionObjsCopy: [],
      // 编辑时，小区显示用
      streetList: [],
      shequList: [],
      communityList: [],
      rules: {
        projectName: [{
          required: true,
          message: "项目名称是必填项"
        }],
        state: [{
          required: true,
          message: "状态是必选项"
        }],
        street: [{
          required: true,
          message: "街道是必填项"
        }]
        /*community:[{
        	required: true,
        	message: "社区是必填项"						
        }],
        region:[{
        	required: true,
        	message: "小区是必填项"						
        }]*/

      },
      allchecked: false // 小区全选

    };
  },
  watch: {
    // 监听选择的社区
    'form.street': function formStreet(val) {
      var _this = this;

      if (val) {
        this.shequList = [];
        this.communityList = [];
        this.form.community = "";
        this.form.communityId = "";
        this.regionObjs = [];
        this.regionObjsCopy = []; //console.log("aa");

        this.allchecked = false;

        if (val) {
          Service.shequList({
            currentPage: 1,
            numOfPage: 10000,
            streetName: val
          }).then(function (res) {
            // console.log(res)
            _this.shequList = res.data;
          });
        }
      }
    },
    'form.community': function formCommunity(val) {
      var _this2 = this;

      if (val) {
        var streetName = this.form.street;
        this.communityList = [];
        this.allchecked = false; // 去掉小区全选

        if (val) {
          // console.log("aaa");
          Service.xiaoquList({
            currentPage: 1,
            numOfPage: 10000,
            street: streetName,
            community: val
          }).then(function (res) {
            _this2.communityList = res.data.list;
          });
        }
      }
    }
  },
  created: function created() {
    this.getJiedao(); // 街道列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;

      ServiceProject.getProjectList(this.listQuery).then(function (res) {
        _this3.list = res.data.list;
        _this3.total = res.data.rowsCount;
        _this3.listLoading = false;
      });
    },
    add: function add() {
      this.form.id = "", this.form.projectName = "";
      this.form.street = "";
      this.form.community = '';
      this.regionObjs = [];
      this.regionObjsCopy = [];
      this.form.state = "";
      this.form.begTime = "";
      this.form.endTime = "";
      this.dialogAddOrUpdateFormVisible = true;
      this.getJiedaoList();
    },
    edit: function edit(row) {
      var _this4 = this;

      //console.log(row);
      this.getJiedaoList(); //console.log("bbb");

      this.form.id = row.id;
      this.form.projectName = row.projectName;
      this.form.street = row.street;
      this.form.community = ''; //this.region= row.regionIds.split(",");
      //console.log("ccc");
      //console.log(this.region);

      this.form.state = row.state;
      this.form.begTime = row.begTime;
      this.form.endTime = row.endTime; // 根据项目id查询小区list

      ServiceProject.getKitchenRegionByProjectId(row.id).then(function (res) {
        _this4.regionObjsCopy = res.data.list;
        _this4.regionObjs = [];
      });
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this5 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this5.regionObjsCopy.length == 0) {
            _this5.$message({
              message: "小区必填项",
              type: 'error'
            });

            return;
          } else {
            _this5.form.regionIds = "";

            _this5.regionObjsCopy.forEach(function (item, index) {
              _this5.form.regionIds = _this5.form.regionIds + item.id + ",";
            });
          }

          if (_this5.form.begTime == "" || _this5.form.begTime == null || _this5.form.endTime == "" || _this5.form.endTime == null) {
            _this5.$message({
              message: "管理期限必填项",
              type: 'error'
            });

            return;
          } //console.log(this.form);
          //return;


          if (_this5.form.id == null || _this5.form.id == '') {
            // 添加
            ServiceProject.insertProject(_this5.form).then(function (res) {
              //console.log(res);
              if (res.data.status == 1) {
                _this5.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              } else {
                _this5.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this5.dialogAddOrUpdateFormVisible = false;

                _this5.getList();
              }
            });
          } else {
            // 修改
            ServiceProject.updateProject(_this5.form).then(function (res) {
              if (res.data.status == 1) {
                _this5.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              } else {
                _this5.$message({
                  message: '操作成功',
                  type: 'success'
                });

                _this5.dialogAddOrUpdateFormVisible = false;

                _this5.getList();
              }
            });
          }
        }
      });
    },
    getJiedao: function getJiedao() {
      var _this6 = this;

      // 主页查询
      ServiceStreet.getArtjied().then(function (res) {
        _this6.streets = res.data;
      });
    },
    // 获取街道 insert or update
    getJiedaoList: function getJiedaoList() {
      var _this7 = this;

      Service.streetList().then(function (res) {
        _this7.streetList = res.data;
      });
    },
    streetTest: function streetTest(name) {
      // 选择街道
      if (!name) {
        this.form.street = '';
        this.form.streetId = '';
      } else {
        this.form.street = name.street;
        this.form.streetId = name.id;
      }
    },
    communityTest: function communityTest(name) {
      // 选择社区
      if (!name) {
        this.form.community = '';
        this.form.communityId = '';
      } else {
        this.form.community = name.name;
        this.form.communityId = name.id;
      }
    },
    regionTest: function regionTest(region) {
      var _this8 = this;

      // 选择小区
      //console.log(region);
      this.regionObjs = [];
      region.forEach(function (item, index) {
        _this8.regionObjs.push(item); // 和value有关系


        if (!_this8.regionObjsCopy.some(function (o) {
          return o.id == item.id;
        })) {
          // 判断是否存在，如果不存在添加到copy
          _this8.regionObjsCopy.push(item);
        }
      });
      console.log(this.regionObjs);
      console.log(this.regionObjsCopy);
    },
    removeTag: function removeTag(tag) {
      var _this9 = this;

      // 移除tag时触发
      this.regionObjs.forEach(function (item, index) {
        if (item.id == tag.id) {
          _this9.regionObjs.splice(index, 1);
        }
      });
      this.regionObjsCopy.forEach(function (item, index) {
        if (item.id == tag.id) {
          _this9.regionObjsCopy.splice(index, 1);
        }
      }); //console.log(this.regionObjs);
      //console.log(this.regionObjsCopy);
    },
    pushEle: function pushEle(obj) {
      var _this10 = this;

      // 删除小区
      this.regionObjs.forEach(function (item, index) {
        if (item.id == obj.id) {
          _this10.regionObjs.splice(index, 1);
        }
      });
      this.regionObjsCopy.forEach(function (item, index) {
        if (item.id == obj.id) {
          _this10.regionObjsCopy.splice(index, 1);
        }
      });
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this11 = this;

      //console.log("ccc");
      //console.log(val);
      if (val) {
        // 根据社区查询所有小区，赋值给regionObjs和regionObjsCopy
        if (this.form.community != '' && this.form.community != null) {
          ServiceProject.getKitchenRegionsByTown(this.form.community).then(function (res) {
            var list = res.data.list;
            list.forEach(function (item, index) {
              if (!_this11.regionObjs.some(function (o) {
                return o.id == item.id;
              })) {
                // 判断是否存在，如果不存在添加到objs
                _this11.regionObjs.push(item);
              }

              if (!_this11.regionObjsCopy.some(function (o) {
                return o.id == item.id;
              })) {
                // 判断是否存在，如果不存在添加到copy
                _this11.regionObjsCopy.push(item);
              }
            });
          });
        } else {
          this.$message({
            message: '请选择社区',
            type: 'error'
          });
          this.allchecked = false;
        }
      }
    }
  }
};