import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
export default {
  name: 'Shanghuusers',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _listQuery;

    return {
      mapVisible: false,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: (_listQuery = {
        role: 0,
        currentPage: 1,
        numOfPage: 20
      }, _defineProperty(_listQuery, "role", 0), _defineProperty(_listQuery, "status", 1), _listQuery)
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    initMap: function initMap(lon, lat) {
      var map = new AMap.Map('amapContainer', {
        resizeEnable: true,
        center: [lon, lat],
        zoom: 9
      });
      var marker = new AMap.Marker({
        icon: 'https://a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
        position: [lon, lat],
        offset: new AMap.Pixel(-13, -30)
      });
      marker.setMap(map);
      map.setFitView();
    },
    viewMap: function viewMap(row) {
      var _this = this;

      this.mapVisible = true;
      setTimeout(function () {
        _this.initMap(row.lon, row.lat);
      }, 500);
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.owerList(this.listQuery).then(function (response) {
        _this2.list = response.data.list;
        _this2.total = response.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this2.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    // 改变用户限制
    changeUserLocation: function changeUserLocation(row) {
      var self = this;
      var posLimited = row.posLimited === 0 ? 1 : 0; // 开关取反

      Service.updateUserLocation({
        posLimited: posLimited,
        userId: row.id
      }).then(function (res) {
        self.getList();
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    handleModifyStatus: function handleModifyStatus(row, status) {
      this.$message({
        message: '操作成功',
        type: 'success'
      });
      row.status = status;
    },
    handleDelete: function handleDelete(row) {
      this.$notify({
        title: '成功',
        message: '删除成功',
        type: 'success',
        duration: 2000
      });
      var index = this.list.indexOf(row);
      this.list.splice(index, 1);
    }
  }
};