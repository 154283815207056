//
//
//
//
import echarts from 'echarts';

require('echarts/theme/macarons'); // echarts theme


import { debounce } from '@/utils';
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null,
      sidebarElm: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.initChart();

    if (this.autoResize) {
      this.__resizeHandler = debounce(function () {
        if (_this.chart) {
          _this.chart.resize();
        }
      }, 100);
      window.addEventListener('resize', this.__resizeHandler);
    } // 监听侧边栏的变化


    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0];
    this.sidebarElm && this.sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler);
    }

    this.sidebarElm && this.sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    sidebarResizeHandler: function sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler();
      }
    },
    setOptions: function setOptions() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          appointingCount = _ref.appointingCount,
          completed_count = _ref.completed_count,
          day = _ref.day,
          recycling_count = _ref.recycling_count,
          total_count = _ref.total_count;

      this.chart.setOption({
        xAxis: {
          data: day,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: ['回收中订单数', '完成订单数', 'recycling_count', 'total_count']
        },
        series: [{
          name: '回收中订单数',
          smooth: true,
          type: 'line',
          data: appointingCount,
          animationDuration: 2800,
          animationEasing: 'cubicInOut'
        }, {
          name: '完成订单数',
          smooth: true,
          type: 'line',
          data: completed_count,
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        }, {
          name: '回收中订单数',
          smooth: true,
          type: 'line',
          data: recycling_count,
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        }, {
          name: '当天总订单数',
          smooth: true,
          type: 'line',
          data: total_count,
          animationDuration: 2800,
          animationEasing: 'quadraticOut'
        }]
      });
    },
    initChart: function initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    }
  }
};