//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/kitchensorting';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
import draggable from 'vuedraggable';
import { parseTime } from "@/utils";
import addrselect from '@/components/street/addrselect';
import jiedao from '@/components/street/jiedao';
import qs from 'qs';
export default {
  components: {
    Pagination: Pagination,
    draggable: draggable,
    addrselect: addrselect,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      listQuery: {
        status: "",
        streetId: "",
        rfidNo: "",
        // 查询条件
        currentPage: 1,
        numOfPage: 20
      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      statusDatas: [{
        name: '开启',
        id: 1
      }, {
        name: '关闭',
        id: 0
      }],
      dialogAddOrUpdateFormVisible: false,
      form: {
        id: "",
        rfidNo: "",
        bucketNo: "",
        streetName: "",
        streetId: "",
        communityName: "",
        communityId: "",
        regionName: "",
        regionId: "",
        msg: "",
        status: ""
      },
      streets: [],
      rules: {
        rfidNo: [{
          required: true,
          message: "RFID编码是必填项"
        }],
        status: [{
          required: true,
          message: "状态是必填项"
        }]
      }
    };
  },
  watch: {},
  created: function created() {
    this.getJiedao(); // 街道列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getRfidList(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    getJiedao: function getJiedao() {
      var _this2 = this;

      ServiceStreet.getArtjied().then(function (res) {
        _this2.streets = res.data;
      });
    },
    streetChange: function streetChange(streetId) {
      // 街道列表改变赋值
      this.listQuery.streetId = streetId;
      this.getList();
    },
    addrChange: function addrChange(data) {
      console.log(data);
      this.form.communityName = data.community;
      this.form.regionName = data.region;
      this.form.streetName = data.street;
      this.form.streetId = data.streetId;
      this.form.communityId = data.communityId;
      this.form.regionId = data.regionId;
    },
    addRfid: function addRfid() {
      this.form.id = "";
      this.form.rfidNo = "";
      this.form.innerNo = "";
      this.form.bucketNo = "";
      this.form.communityName = "";
      this.form.regionName = "";
      this.form.streetName = "";
      this.form.streetId = "";
      this.form.communityId = "";
      this.form.regionId = "";
      this.form.status = 0;
      this.form.msg = "";
      this.dialogAddOrUpdateFormVisible = true;
    },
    editRfid: function editRfid(rows) {
      this.form.id = rows.id;
      this.form.rfidNo = rows.rfidNo;
      this.form.innerNo = rows.innerNo;
      this.form.bucketNo = rows.bucketNo;
      this.form.communityName = rows.communityName;
      this.form.regionName = rows.regionName;
      this.form.streetName = rows.streetName;
      this.form.streetId = rows.streetId;
      this.form.communityId = rows.communityId;
      this.form.regionId = rows.regionId;
      this.form.status = rows.status;
      this.form.msg = rows.msg;
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this3 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this3.form.streetId == "") {
            _this3.$message({
              message: "街道是必填项",
              type: 'error'
            });

            return;
          }

          if (_this3.form.id != "" && _this3.form.id != null) {
            Service.updateRfid(_this3.form).then(function (res) {
              if (res.data.status == 1) {
                _this3.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              } else {
                _this3.$message({
                  message: '修改成功',
                  type: 'success'
                });

                _this3.dialogAddOrUpdateFormVisible = false;

                _this3.getList();
              }
            });
          } else {
            Service.insertRfid(_this3.form).then(function (res) {
              if (res.data.status == 1) {
                _this3.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              } else {
                _this3.$message({
                  message: '添加成功',
                  type: 'success'
                });

                _this3.dialogAddOrUpdateFormVisible = false;

                _this3.getList();
              }
            });
          }
        }
      });
    },
    exportRfid: function exportRfid() {
      location.href = '/kSortingRfid/downloadExcel?' + qs.stringify(this.listQuery);
    }
  }
};