import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/sys';
import service from '@/api/service';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _ref;

    return _ref = {
      datas: {
        name: '',
        remark: '',
        status: '',
        time: '',
        adminName: '',
        adminPhone: ''
      },
      rules: {
        remark: [{
          required: true,
          message: "描述不能为空"
        }],
        name: [{
          required: true,
          message: "资源名称不能为空"
        }],
        status: [{
          required: true,
          message: "状态不能为空"
        }],
        time: [{
          required: true,
          message: "到期时间不能为空"
        }],
        adminName: [{
          required: true,
          message: "管理人员姓名不能为空"
        }],
        adminPhone: [{
          required: true,
          message: "管理人员手机号不能为空"
        }]
      },
      checkAll: false,
      isIndeterminate: true,
      listsd: [{
        type: 1,
        info: '已停用'
      }, {
        type: 0,
        info: '使用中'
      }],
      juesehao: [],
      tableKey: 0,
      list: [],
      total: 0
    }, _defineProperty(_ref, "checkAll", false), _defineProperty(_ref, "isIndeterminate", true), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      currentPage: 1,
      numOfPage: 20,
      sort: null
    }), _defineProperty(_ref, "dialogFormVisible", false), _defineProperty(_ref, "userSelFormVisible", false), _defineProperty(_ref, "statusd", true), _ref;
  },
  watch: {
    juesehao: function juesehao(e) {
      // console.log(e)
      this.listQuery.roleIds = e.join(",");
    },
    jiedaohao: function jiedaohao(e) {
      // console.log(e)
      this.listQuery.streetIds = e.join(",");
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    onSortChange: function onSortChange(_ref2) {
      var prop = _ref2.prop,
          order = _ref2.order;

      if (order == 'ascending') {
        this.listQuery.sort = 1;
        this.getList();
      } else {
        this.listQuery.sort = 0;
        this.getList();
      } // this.tableData.sort(this.compare(prop,order));

    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      service.getresource(this.listQuery).then(function (res) {
        console.log(res);
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addRole: function addRole() {
      delete this.datas.id;
      this.datas.remark = '';
      this.datas.status = '';
      this.datas.time = '';
      this.datas.name = '';
      this.datas.adminName = '';
      this.datas.adminPhone = '';
      this.dialogFormVisible = true;
      this.statusd = true;
    },
    editRole: function editRole(e) {
      this.statusd = false;
      this.datas = e;
      delete this.datas.updateTime;
      delete this.datas.createTime;
      this.dialogFormVisible = true;
    },
    editRoles: function editRoles(e) {
      var _this2 = this;

      this.roleForm = e;
      delete this.roleForm.updateTime;
      delete this.roleForm.createTime;

      if (e.status) {
        this.roleForm.status = 0;
        service.editresource(this.roleForm).then(function (res) {
          _this2.$message({
            message: '修改成功',
            type: 'success'
          });

          _this2.getList();
        });
      } else {
        this.roleForm.status = 1;
        service.editresource(this.roleForm).then(function (res) {
          _this2.$message({
            message: '修改成功',
            type: 'success'
          });

          _this2.getList();
        });
      }
    },
    Sure: function Sure() {
      var _this3 = this;

      if (!this.statusd) {
        this.$refs['dataForm'].validate(function (valid) {
          if (valid) {
            service.editresource(_this3.datas).then(function (res) {
              _this3.$message({
                message: '修改成功',
                type: 'success'
              });

              _this3.dialogFormVisible = false;

              _this3.getList();
            });
          }
        });
      } else {
        this.$refs['dataForm'].validate(function (valid) {
          if (valid) {
            service.editresource(_this3.datas).then(function (res) {
              _this3.$message({
                message: '添加成功',
                type: 'success'
              });

              _this3.dialogFormVisible = false;

              _this3.getList();
            });
          }
        });
      }

      console.log();
    } // 点击添加

  }
};