import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import service from '@/api/aipic';
import waves from '@/directive/waves'; // Waves directive

import { getToken } from "@/utils/auth";
import * as imageConversion from 'image-conversion';
export default {
  directives: {
    waves: waves
  },
  props: ['imageUrl', 'check'],
  data: function data() {
    return {
      form: {
        datasetId: "" // 数据集编码

      },
      formLabelWidth: '160px',
      datasetList: [],
      // 数据集列表
      // 批量上传图像开始
      AllLoading: [],
      oldImg: null,
      dialogImageUrl: '',
      checkUpload: false,
      fileList: [],
      // returnFileList: [],
      showFile: [],
      urlStr: [],
      editFile: [],
      dialogVisible: false,
      uploadFileUrl: "/aiPicDataset/imagesearch",
      // 上传的图像服务器地址
      headers: {
        Authorization: "Authorization" + getToken()
      },
      // 批量上传图像结束
      result: {
        sim: "",
        //相似度
        msg: "",
        //类型
        nomsg: "",
        // 无匹配消息类型
        simInit: "",
        msgInit: ""
      },
      results: [],
      viewDisplay: true,
      mainImgUrl: "",
      // 主标注图像
      boundingBox: {},
      // 边界框
      mainObjectBox: {} // 主体对象框

    };
  },
  mounted: function mounted() {
    // 在mounted时候赋值，子组件只更新一次，后面重新选择后展示此组件的数据，不再更新
    this.oldImg = this.imageUrl;
    this.stringToImage(this.oldImg);
  },
  watch: {
    imageUrl: function imageUrl(val) {
      // // console.log('val')
      // // console.log(val)
      this.oldImg = val;
      this.stringToImage(this.oldImg);
    }
  },
  created: function created() {
    this.getDatasetList();
  },
  methods: {
    getDatasetList: function getDatasetList() {
      var _this = this;

      service.getDatasetList().then(function (res) {
        //console.log(res.data);
        _this.datasetList = res.data.data;
      });
    },
    datasetChange: function datasetChange(id) {
      // 数据集列表改变赋值
      //this.form.type = id
      this.form.datasetId = id;
      this.fileList = [];
      this.showFile = [];
      this.urlStr = [];
      this.editFile = [];
      this.result.msg = "";
      this.result.nomsg = ""; // this.mainImgUrl = "";

      /*document.getElementById("draw").style.border ="0px";
      document.getElementById("draw").style.left = "0px";
      document.getElementById("draw").style.top = "0px";
      document.getElementById("draw").style.width = "0px";
      document.getElementById("draw").style.height ="0px";*/
    },
    stringToImage: function stringToImage(imageString) {
      var _this2 = this;

      this.showFile = [];
      this.editFile = [];

      if (imageString != null && imageString != '' && imageString != undefined) {
        this.urlStr = imageString.split(",");
        this.urlStr.forEach(function (item) {
          var obj = new Object();
          var obj1 = new Object();
          obj1.url = item;

          if (item.indexOf("http") < 0) {
            item = process.env.VUE_APP_BASE_MINIO_API + item;
          }

          obj.url = item;

          _this2.showFile.push(obj);

          _this2.editFile.push(obj1);
        });
      }
    },
    arryToString: function arryToString(arry) {
      var imgString = "";

      if (arry.length > 0) {
        for (var i = 0; i < arry.length; i++) {
          if (i < arry.length - 1) {
            imgString += arry[i].url + ",";
          } else {
            imgString += arry[i].url;
          }
        }
      }

      return imgString;
    },
    handleOnChange: function handleOnChange(file, fileList) {
      var _this3 = this;

      // 点击上传触发
      //console.log(file);
      this.mainImgUrl = file.url;

      if (file.name.indexOf(',') !== -1) {
        this.msgError("上传的文件名称中不允许出现英文逗号！");
        this.handleRemoveThis(file);
        return false;
      } else {
        var isLt2M = file.size / 1024 / 1024 < 1.5;

        if (!isLt2M) {
          console.log(file); // return new Promise((resolve) => {
          // 压缩到600KB,这里的600就是要压缩的大小,可自定义

          imageConversion.compressAccurately(file.raw, 600).then(function (res) {
            file.raw = new window.File([res], file.name, {
              type: file.type
            });
            file.size = file.raw.size;

            _this3.fileList.push(file);
          }); // })
        } else {
          this.fileList.push(file); // console.log(this.fileList);
        }

        this.mainObjectSubmitUpload();
      }
    },
    // 图像主体识别 -返回图像主体识别坐标
    mainObjectSubmitUpload: function mainObjectSubmitUpload() {
      var _this4 = this;

      var fileList = [];
      var _this$$refs$pictureUp = this.$refs.pictureUpload,
          uploadFiles = _this$$refs$pictureUp.uploadFiles,
          action = _this$$refs$pictureUp.action,
          data = _this$$refs$pictureUp.data;
      action = "/aiPicDataset/imagesearch/main/object"; //console.log(this.$refs.pictureUpload);

      if (this.form.datasetId == "" || this.form.datasetId == null) {
        this.msgError("请选择数据集！");
        return;
      }

      if (uploadFiles.length == 0) {
        this.msgError("请上传图片！");
        return;
      } // 全屏禁用开启


      this.AllLoading = this.$loading({
        lock: true,
        text: '图像上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.uploadFiles({
        uploadFiles: uploadFiles,
        data: data,
        action: action,
        success: function success(response) {
          var res = JSON.parse(response);

          _this4.AllLoading.close(); // 边界框\主体对象框

          /*if(res.data.boundingBox != null && res.data.boundingBox.length != 0){
                             this.boundingBox = res.data.boundingBox;
          	console.log(this.boundingBox.top_left_x);
          	document.getElementById("draw").style.left = this.boundingBox.top_left_x+"px";
          	document.getElementById("draw").style.top = this.boundingBox.top_left_y+"px";
          	document.getElementById("draw").style.width = this.boundingBox.width+"px";
          	document.getElementById("draw").style.height = this.boundingBox.height+"px";
          }
          
          if(res.data.mainObjectBox != null && res.data.mainObjectBox.length != 0){
                             this.mainObjectBox = res.data.mainObjectBox;
          }*/

        },
        error: function error(_error) {
          _this4.msgError("图像上传失败！");

          _this4.AllLoading.close();

          console.log('图像上传失败！', _error);
        }
      });
    },
    // 文件上传
    submitUpload: function submitUpload() {
      var _this5 = this;

      var fileList = [];
      var _this$$refs$pictureUp2 = this.$refs.pictureUpload,
          uploadFiles = _this$$refs$pictureUp2.uploadFiles,
          action = _this$$refs$pictureUp2.action,
          data = _this$$refs$pictureUp2.data; //console.log(this.$refs.pictureUpload);

      if (this.form.datasetId == "" || this.form.datasetId == null) {
        this.msgError("请选择数据集！");
        return;
      }

      if (uploadFiles.length == 0) {
        this.msgError("请上传图片！");
        return;
      } // 全屏禁用开启


      this.AllLoading = this.$loading({
        lock: true,
        text: '图像校验中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // console.log(uploadFiles)
      // uploadFiles.forEach(file => {
      //   imageConversion.compressAccurately(file.raw, 600).then(res => {
      //     file.raw = new window.File([res], file.name, { type: file.type })
      //     file.size = file.raw.size
      //   });
      // })
      //console.log(uploadFiles);
      //console.log(data);
      //data.push({"datasetId":this.importPicForm.datasetId});
      //console.log(data);
      //console.log(action);
      //return;

      this.uploadFiles({
        uploadFiles: uploadFiles,
        data: data,
        action: action,
        success: function success(response) {
          var res = JSON.parse(response); // this.$refs.uploadFile.clearFiles();
          // console.log(res)
          // this.msgSuccess("图像上传成功！")

          _this5.AllLoading.close();
          /*res.data.fileName.forEach(item => {
          	let obj = new Object();
          	let obj1 = new Object();
          	obj.url = process.env.VUE_APP_BASE_MINIO_API + item;
          	obj1.url = item;
          	this.editFile.push(obj1)
          	this.showFile.push(obj)
          	var imgString = this.arryToString(this.editFile)
          	this.$emit('returnUrl', imgString)
          })*/
          // console.log(res.data.searchPicInfo.data.result);
          // 为图片画主体
          //console.log(res.data);
          // 边界框、主体对象框

          /*if(res.data.box.data.boundingBox != null && res.data.box.data.boundingBox.length != 0){
                             this.boundingBox = res.data.box.data.boundingBox;
          	document.getElementById("draw").style.left = this.boundingBox.top_left_x+"px";
          	document.getElementById("draw").style.top = this.boundingBox.top_left_y+"px";
          	document.getElementById("draw").style.width = this.boundingBox.width+"px";
          	document.getElementById("draw").style.height = this.boundingBox.height+"px";
          }
          
          if(res.data.box.data.mainObjectBox != null && res.data.box.data.mainObjectBox.length != 0){
                             this.mainObjectBox = res.data.box.data.mainObjectBox;
          }*/
          // console.log(this.resultMainObject);
          // 返回结果


          _this5.results = [];

          if (res.data.searchPicInfo.data.result != null && res.data.searchPicInfo.data.result.length != 0) {
            _this5.result.msg = res.data.searchPicInfo.data.result[0].tags.json;
            _this5.result.sim = (res.data.searchPicInfo.data.result[0].sim * 100).toFixed(2); //this.result.path = res.data.searchPicInfo.data.result[0].path;
            // this.result.msgInit = res.data.searchPicInfoInit.data.result[0].tags.json;
            // this.result.simInit = (res.data.searchPicInfoInit.data.result[0].sim *100).toFixed(2);

            res.data.searchPicInfo.data.result.forEach(function (item, index) {
              item.sim = (item.sim * 100).toFixed(2);

              _this5.results.push(item);
            });
            console.log(_this5.results);
          } else {
            _this5.result.msg = "";
            _this5.result.sim = "";
            _this5.result.nomsg = "没有匹配的图片";
            _this5.results = [];
          }
        },
        error: function error(_error2) {
          _this5.msgError("图像上传失败！");

          _this5.AllLoading.close();

          console.log('图像上传失败！', _error2);
        }
      });
    },

    /**
     * 自定义上传文件
     * @param fileList 文件列表
     * @param data 上传时附带的额外参数
     * @param url 上传的URL地址
     * @param success 成功回调
     * @param error 失败回调
     */
    uploadFiles: function uploadFiles(_ref) {
      var _uploadFiles = _ref.uploadFiles,
          headers = _ref.headers,
          data = _ref.data,
          action = _ref.action,
          success = _ref.success,
          error = _ref.error;
      var form = new FormData(); // 文件对象

      _uploadFiles.map(function (file) {
        return form.append("filedatas", file.raw);
      }); // 附件参数


      for (var key in data) {
        form.append(key, data[key]);
      }

      var xhr = new XMLHttpRequest(); // 异步请求

      xhr.open("post", action, true); // 设置请求头

      xhr.setRequestHeader("Authorization", getToken());

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status >= 200 && xhr.status < 300 || xhr.status == 304) {
            success && success(xhr.responseText);
          } else {
            error && error(xhr.status);
          }
        }
      };

      xhr.send(form);
    },
    // 移除图像
    handleRemoveThis: function handleRemoveThis(file) {
      this.mainImgUrl = "";
      /*document.getElementById("draw").style.border ="0px";
      document.getElementById("draw").style.left = "0px";
      document.getElementById("draw").style.top = "0px";
      document.getElementById("draw").style.width = "0px";
      document.getElementById("draw").style.height ="0px";*/
      // console.log(file)
      // 在控件中移除图像

      this.$refs.pictureUpload.handleRemove(file); // 获取图像在数组中的位置

      var number = this.showFile.indexOf(file); // 获取返回的文件url中此文件的url
      //var file = process.env.VUE_APP_BASE_MINIO_API + this.editFile[number].url.toString()
      //var fileItemList = file.split('/')
      // 执行文件删除

      /*delImg(fileItemList[4]).then(response => {
      	console.log(response)
      })*/

      this.editFile.splice(number, 1);
      this.showFile.splice(number, 1);
      var imgString = this.arryToString(this.editFile); //console.log(this.showFile);

      if (this.showFile.length == 0) {
        this.viewDisplay = false;
      }

      this.$emit('returnUrl', imgString);
    },
    // 上传前
    beforUpload: function beforUpload(file) {
      console.log("上传之前");
      console.log(file);
      var suffix = file.substring(file.lastIndexOf(".") + 1).toUpperCase();
      console.log(suffix);

      if (suffix != "JPG" && suffix != "PNG" && suffix != "JPEG" && suffix != "BMP") {
        this.msgError("上传的文件格式不正确，请上传正确的图像格式(JPG,PNG,JPEG,BMP)！");
        return false;
      }

      if (file.name.indexOf(',') !== -1) {
        this.msgError("上传的文件名称中不允许出现英文逗号！");
        return false;
      } else {
        // this.checkUpload = true
        this.fileList.push(file);
        var isLt2M = file.size / 1024 / 1024 < 1.5;

        if (!isLt2M) {
          return new Promise(function (resolve) {
            // 压缩到100KB,这里的100就是要压缩的大小,可自定义
            imageConversion.compressAccurately(file.raw, 600).then(function (res) {
              res = new File([res], file.name, {
                type: res.type,
                lastModified: Date.now()
              });
              resolve(res);
            });
          });
        }
      }
    },
    // 上传成功
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      // this.checkUpload = false
      // console.log("上传成功")
      // console.log(res)
      // console.log(fileList)
      console.log(this.$refs.pictureUpload.uploadFiles);
      this.$message.success("上传成功"); // fileList.forEach(item => {
      //   let obj = new Object();
      //   let obj1 = new Object();
      //   if (item.response !== undefined) {
      //     obj.url = process.env.VUE_APP_BASE_MINIO_API + item.response.fileName;
      //     obj1.url = item.response.fileName;
      //     this.editFile.push(obj1)
      //     this.showFile.push(obj)
      //     var imgString = this.arryToString(this.editFile)
      //     this.$emit('returnUrl', imgString)
      //   }
      // })

      var obj = new Object();
      var obj1 = new Object();
      obj.url = process.env.VUE_APP_BASE_MINIO_API + res.fileName;
      obj1.url = res.fileName;
      this.editFile.push(obj1);
      this.showFile.push(obj);
      var imgString = this.arryToString(this.editFile);
      this.$emit('returnUrl', imgString);
    },
    handleUploadError: function handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败"
      });
      this.loading.close();
    },
    msgError: function msgError(msg) {
      this.$message({
        message: msg,
        type: 'error'
      });
    },
    msgSuccess: function msgSuccess(msg) {
      this.$message({
        message: msg,
        type: 'success'
      });
    }
  }
};