import request from '@/utils/request';
var Service = {
  add: function add(params) {
    return request({
      url: '/operation/screenSetting/add',
      method: 'post',
      params: params
    });
  },
  update: function update(params) {
    return request({
      url: '/operation/screenSetting/update',
      method: 'post',
      params: params
    });
  },
  updateScreenSettingStatusById: function updateScreenSettingStatusById(params) {
    return request({
      url: '/operation/screenSetting/updateScreenSettingStatusById',
      method: 'post',
      params: params
    });
  },
  getSettingTempDataById: function getSettingTempDataById(params) {
    return request({
      url: '/operation/screenSettingTempData/getSettingTempDataById',
      method: 'get',
      params: params
    });
  },
  addAndUpdate: function addAndUpdate(params) {
    return request({
      url: '/operation/screenSettingTempData/addAndUpdate',
      method: 'post',
      params: params
    });
  },
  getList: function getList(params) {
    return request({
      url: '/operation/screenSetting/list',
      method: 'get',
      params: params
    });
  }
};
export default Service;