"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _service = _interopRequireDefault(require("@/api/service"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _jiedao = _interopRequireDefault(require("@/components/street/jiedao"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var _default = {
  name: 'Shequ',
  components: {
    Pagination: _Pagination.default,
    jiedao: _jiedao.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      rules: {
        coin: [{
          required: true,
          message: "奖励积分是必填项"
        }],
        remark: [{
          required: true,
          message: "备注是必选项"
        }]
      },
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '未完成',
        value: 0
      }, {
        name: '已完成',
        value: 1
      }, {
        name: '已取消',
        value: -1
      }, {
        name: '异常',
        value: -2
      }],
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        street: '',
        phone: "",
        state: "",
        currentPage: 1,
        numOfPage: 20
      },
      id: null,
      street: null,
      // 存放街道数据
      searchStreet: null,
      // 存放查询街道数据
      form: {
        id: '',
        remark: '',
        coin: '',
        userId: ''
      },
      titles: ''
    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;

      _service.default.gettVoluntaryok(this.listQuery).then(function (res) {
        console.log(res);
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    showUpdate: function showUpdate(row) {
      var _this2 = this;

      Object.keys(this.form).forEach(function (key) {
        _this2.form[key] = row[key];
      });
      this.titles = '审核';
      this.dialogVisible = true;
    },
    save: function save() {
      var _this3 = this;

      var data = this.form;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _service.default.editVoluntaryok(data).then(function (res) {
            _this3.dialogVisible = false;

            _this3.$notify({
              title: '成功',
              message: '审核成功',
              type: 'success',
              duration: 2000
            });

            _this3.getList();
          });
        }
      });
    }
  }
};
exports.default = _default;