import request from '@/utils/request';
/**数据标签开始 */

var Service = {
  getAiPicTags: function getAiPicTags() {
    // 加载页面
    return request({
      url: '/aiPicTag/getAiPicTags',
      method: 'get'
    });
  },
  deleteAiPicTagById: function deleteAiPicTagById(params) {
    // 删除
    return request({
      url: '/aiPicTag/deleteAiPicTagById?id=' + params,
      method: 'post'
    });
  },
  insertAiPicTag: function insertAiPicTag(params) {
    // 添加
    return request({
      url: '/aiPicTag/insertAiPicTag',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updateAiPicTag: function updateAiPicTag(params) {
    return request({
      url: '/aiPicTag/updateAiPicTag',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },

  /**数据标签结束 */

  /**数据集开始 */
  getAiPicDatasetListPage: function getAiPicDatasetListPage(params) {
    // 加载页面
    return request({
      url: '/aiPicDataset/getAiPicDatasetListPage',
      method: 'get',
      params: params
    });
  },
  insertAiPicDataset: function insertAiPicDataset(params) {
    // 添加
    return request({
      url: '/aiPicDataset/insertAiPicDataset',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updateAiPicDataset: function updateAiPicDataset(params) {
    return request({
      url: '/aiPicDataset/updateAiPicDataset',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updatePicTag: function updatePicTag(params) {
    return request({
      url: '/aiPicDataset/updatePicTag',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },

  /**数据集结束 */

  /**
   * 数据集明细开始
   */
  getAiPicDatasetDetailList: function getAiPicDatasetDetailList(params) {
    // 加载页面
    return request({
      url: '/aiPicDatasetDetail/getAiPicDatasetDetailList?datasetId=' + params,
      method: 'get'
    });
  },

  /**
  * 数据集明细结束
  */

  /**
   * 数据集列表
   */
  getDatasetList: function getDatasetList() {
    // 加载页面
    return request({
      url: '/aiPicDataset/getAiPicDatasetList',
      method: 'get'
    });
  },

  /**
   * 图像复核列表
   */
  getAiPicCheckListPage: function getAiPicCheckListPage(params) {
    // 加载页面
    return request({
      url: '/aiPicCheck/getAiPicCheckListPage',
      method: 'get',
      params: params
    });
  },

  /**
   * 图像复核删除
   * @param {*} params 
   * @returns 
   */
  deleteAiPicCheckById: function deleteAiPicCheckById(params) {
    return request({
      url: '/aiPicCheck/deleteAiPicCheckById?id=' + params,
      method: 'post'
    });
  },
  updateAiPicCheck: function updateAiPicCheck(params) {
    return request({
      url: '/aiPicCheck/updateAiPicCheck',
      method: 'post',
      params: params
    });
  },

  /**
  * 根据街道获取回收员姓名列表
  */
  getRecyclerByStreet: function getRecyclerByStreet(params) {
    // 加载页面
    return request({
      url: '/aiPicDataset/recycler/name',
      method: 'get',
      params: params
    });
  }
};
export default Service;