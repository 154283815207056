//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import dizhiService from '@/api/dizhi';
import { parseTime } from '@/utils';
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import ServiceProject from '@/api/project'; // 项目api

export default {
  components: {
    jiedao: jiedao,
    Pagination: Pagination
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      total: 0,
      searchStreet: null,
      listLoading: false,
      listQuery: {
        numOfPage: 20,
        currentPage: 1,
        key: '',
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        street: '',
        projectId: ""
      },
      Options: [{
        name: '全部',
        value: ''
      }, {
        name: '线上用户',
        value: 0
      }, {
        name: '线下用户',
        value: 2
      }],
      list: [],
      projectFieldVisibleIndex: false // 判断街道下项目是否存在

    };
  },
  watch: {
    searchStreet: function searchStreet(val) {
      var _this = this;

      this.listQuery.street = val.street;
      ServiceProject.getProjectsByStreet(this.listQuery.street).then(function (res) {
        _this.projectList = res.data.list;

        if (_this.projectList.length == 0) {
          _this.projectFieldVisibleIndex = false;
          _this.listQuery.projectId = "";
        } else {
          _this.projectFieldVisibleIndex = true;
        }
      });
      this.getList();
    },
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;

      Service.duihuanjidetailsd(this.listQuery).then(function (res) {
        // console.log(res)
        _this2.list = res.data.data.list;
        _this2.total = res.data.data.totalRows;
      });
    },
    handleSelectionChange: function handleSelectionChange() {},
    handleFilter: function handleFilter() {
      this.getList();
    },
    add: function add() {},
    exports: function exports() {
      location.href = '/operation/shopintegra/exportOwnerData?' + qs.stringify(this.listQuery); // Service.exportjifenhuanlist().then((res)=>{
      // 	console.log(res)
      // })
    }
  },
  created: function created() {
    this.getList();
  }
};