//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
export default {
  data: function data() {
    return {
      id: this.$route.query.data,
      tableData: [],
      data: ''
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      Service.dudaodaka(this.id).then(function (res) {
        console.log(res);
        _this.tableData = res.data.data.list;
        _this.data = res.data.data;
        console.log(_this.data.street);
      });
    }
  }
};