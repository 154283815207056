import "core-js/modules/es6.regexp.split";
import _typeof from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/typeof.js";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
export default {
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  data: function data() {
    var _ref;

    return _ref = {
      street: null,
      dialogVisible: false,
      daterange: null,
      dateranges: null,
      cond: [],
      conds: [{
        val: 1,
        name: '厨余投递5次以上'
      }, {
        val: 2,
        name: '1次可回收物回收'
      }, {
        val: 3,
        name: '1次减碳打卡'
      }, {
        val: 4,
        name: '厨余投递4次以上'
      }],
      form: {
        status: 1,
        //1是开，0 是关
        startTime: '',
        endTime: '',
        prizeRule: '',
        street: '',
        id: '',
        astartTime: '',
        aendTime: '',
        cond: '' //统计条件

      },
      rules: {
        startTime: [{
          required: true,
          message: "抽奖时间是必填项"
        }],
        astartTime: [{
          required: true,
          message: "统计时间是必填项"
        }],
        prizeRule: [{
          required: true,
          message: "抽奖规则说明是必选项"
        }]
      }
    }, _defineProperty(_ref, "dialogVisible", false), _defineProperty(_ref, "list", null), _defineProperty(_ref, "total", 0), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      street: '',
      currentPage: 1,
      numOfPage: 20,
      key: ''
    }), _ref;
  },
  watch: {
    street: function street(val) {
      if (_typeof(val) == "object") {
        this.form.street = val['street'];
      } else if (typeof val == "string") {
        this.form.street = val;
      } else {
        this.form.street = '';
      }
    },
    daterange: function daterange(val) {
      if (val) {
        this.form.startTime = val[0];
        this.form.endTime = val[1];
      }
    },
    dateranges: function dateranges(val) {
      if (val) {
        this.form.astartTime = val[0];
        this.form.aendTime = val[1];
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    add: function add() {
      this.form['startTime'] = '';
      this.form['endTime'] = '';
      this.form['prizeRule'] = '';
      this.form['id'] = '';
      this.form['astartTime'] = '';
      this.form['aendTime'] = '';
      this.form['cond'] = '';
      this.form['status'] = '';
      this.daterange = null;
      this.dateranges = null;
      this.cond = [];
      this.street = null;
      this.dialogVisible = true;
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getluckyRule().then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
        _this.listLoading = false; // this.daterange = [res.data.data.startTime, res.data.data.endTime]
        // this.dateranges = [res.data.data.astartTime?res.data.data.astartTime:'', res.data.data.aendTime?res.data.data.aendTime:'']
      });
    },
    edit: function edit(item) {
      console.log(item);
      this.form['startTime'] = item['startTime'];
      this.form['endTime'] = item['endTime'];
      this.daterange = [item['startTime'], item['endTime']];
      this.form['prizeRule'] = item['prizeRule'];
      this.form['id'] = item['id'];
      this.street = item['street'];
      this.form['street'] = item['street'];
      this.form['astartTime'] = item['astartTime'];
      this.form['aendTime'] = item['aendTime'];
      this.dateranges = [item['astartTime'], item['aendTime']];
      this.cond = item['cond'].split(',').map(function (item) {
        return item * 1;
      });
      this.form['cond'] = item['cond'];
      this.form['status'] = !!item['status'];
      this.dialogVisible = true;
    },
    save: function save() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          _this2.form.cond = _this2.cond.join(',');
          _this2.form.status = _this2.form.status ? 1 : 0;

          if (_this2.form.id) {
            Service.updateluckyRule(_this2.form).then(function (res) {
              if (res.data.statusCode == 200) {
                _this2.$notify({
                  title: '成功',
                  message: '操作成功',
                  type: 'success',
                  duration: 2000
                });

                _this2.dialogVisible = false;

                _this2.getList();
              } else {
                _this2.$notify({
                  title: '失败',
                  message: res.data.error,
                  type: 'error',
                  duration: 2000
                });
              }
            });
          } else {
            Service.addluckyRule(_this2.form).then(function (res) {
              if (res.data.statusCode == 200) {
                _this2.$notify({
                  title: '成功',
                  message: '操作成功',
                  type: 'success',
                  duration: 2000
                });

                _this2.dialogVisible = false;

                _this2.getList();
              } else {
                _this2.$notify({
                  title: '失败',
                  message: res.data.error,
                  type: 'error',
                  duration: 2000
                });
              }
            });
          }
        }
      });
    }
  }
};