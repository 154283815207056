"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var errorLog = {
  state: {
    logs: []
  },
  mutations: {
    ADD_ERROR_LOG: function ADD_ERROR_LOG(state, log) {
      state.logs.push(log);
    }
  },
  actions: {
    addErrorLog: function addErrorLog(_ref, log) {
      var commit = _ref.commit;
      commit('ADD_ERROR_LOG', log);
    }
  }
};
var _default = errorLog;
exports.default = _default;