//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
export default {
  name: 'Dati',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    return {
      form: {
        id: '',
        title: '',
        // 标题
        type: 1,
        // 单项选择
        time: 10,
        // 答题时间
        answer: '',
        // 答题
        question: '' // question

      },
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        dept: '',
        // 小区，学校，单位
        name: '',
        // 姓名
        phone: '',
        // 电话号
        currentPage: 1,
        numOfPage: 20
      },
      temp: {
        id: '',
        title: '',
        // 标题
        type: 1,
        // 单项选择
        time: 10,
        // 答题时间
        answer: '',
        // 答题
        question: '' // question

      },
      dialogVisible: false,
      detailData: {},
      rules: {
        property: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }],
        housing: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    close: function close() {
      this.dialogVisible = false;
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getBaoming(this.listQuery).then(function (response) {
        _this.list = response.data.body.list;
        _this.total = response.data.body.totalRows; // Just to simulate the time of the request

        _this.listLoading = false;
      });
    },
    save: function save() {
      var _this2 = this;

      var data = this.form;

      if (data.id) {
        Service.updateDati(data).then(function (res) {
          _this2.dialogVisible = false;

          _this2.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            duration: 2000
          });

          _this2.getList();
        });
      } else {
        Service.addDati(data).then(function (res) {
          _this2.dialogVisible = false;

          _this2.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success',
            duration: 2000
          });

          _this2.getList();
        });
      }
    },
    add: function add() {
      this.dialogVisible = true;
      this.form = Object.assign({}, this.temp);
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        title: '',
        // 标题
        type: 1,
        // 单项选择
        time: 10,
        // 答题时间
        answer: '',
        // 答题
        question: '' // question

      };
    },
    del: function del(row) {
      var _this3 = this;

      Service.deleteDati(row.id).then(function (res) {
        _this3.$notify({
          title: '成功',
          message: '通过',
          type: 'success',
          duration: 2000
        });

        _this3.getList();
      });
    },
    showUpdate: function showUpdate(row) {
      console.log(row);
      this.dialogVisible = true;
      this.form = row;
    }
  }
};