import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/fhtsys';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

/**这代码谁写的，哪复制的，乱七八糟2022-07-14 */

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _ref;

    return _ref = {
      passWord: '',
      // projects:'',
      projecthao: [],
      rules: {
        name: [{
          required: true,
          message: '账号不能为空'
        }],
        passWord: [{
          required: true,
          message: "密码不能为空"
        }],
        state: [{
          required: true,
          message: '状态不能为空'
        }],
        roleId: [{
          required: true,
          message: '账号不能为空'
        }]
      },
      checkAll: false,
      isIndeterminate: true,
      juese: [],
      // 角色字典
      projects: [],
      // 街道字典
      states: [{
        "id": 0,
        "name": "通过"
      }, {
        "id": 1,
        "name": "拒绝"
      }],
      // 状态
      tableKey: 0,
      list: null,
      total: 0
    }, _defineProperty(_ref, "checkAll", false), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      passWord: '',
      userName: '',
      projectIds: [],
      name: '',
      state: '',
      roleId: ""
    }), _defineProperty(_ref, "dialogFormVisible", false), _defineProperty(_ref, "userName", ''), _defineProperty(_ref, "state", ""), _defineProperty(_ref, "ids", ''), _ref;
  },
  watch: {
    projecthao: function projecthao(e) {
      // console.log(e)
      console.log(e.join(","));
      this.listQuery.projectIds = e.join(",");
    }
  },
  created: function created() {
    var _this = this;

    this.getList(); // 角色列表和街道列表

    Service.getDictData().then(function (res) {
      res.data.data.roles.forEach(function (item) {
        // 角色字典
        _this.juese.push({
          "id": item.id,
          "juese": item.roleName
        });
      });
      res.data.data.projects.forEach(function (item) {
        // xian字典
        _this.projects.push({
          "id": item.id,
          "juese": item.projectName
        });
      });
    });
  },
  methods: {
    Loginlist: function Loginlist(e) {
      this.$router.push({
        name: '登录分后台log',
        params: {
          datas: e
        }
      });
    },
    unique: function unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }

      return arr;
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this2 = this;

      // console.log(val)
      this.isIndeterminate = false;

      if (val == true) {
        this.projects.forEach(function (item, index) {
          _this2.projecthao.push(item.id);
        });
        this.listQuery.projectIds = this.unique(this.projecthao); // console.log(this.listQuery.projectIds)
      } else {
        this.projecthao = [];
        this.listQuery.projectIds = [];
      }
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.projecthao.length;
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.getzhanghao({
        "userName": this.userName,
        "state": this.state
      }).then(function (res) {
        console.log(res);
        _this3.list = res.data.data;
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addRole: function addRole() {
      this.ids = "";
      this.projecthao = [];
      this.listQuery.projectIds = [];
      this.dialogFormVisible = true;
    },
    // 点击添加
    Sure: function Sure() {
      var _this4 = this;

      var self = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this4.listQuery.projectIds == []) {
            _this4.$notify({
              title: '失败',
              message: '请选择数据权限',
              type: 'error',
              duration: 2000
            });
          } else {
            if (_this4.ids) {
              _this4.listQuery.userId = _this4.ids;
              Service.xiugai(_this4.listQuery).then(function (res) {
                console.log(res);

                if (res.data.error) {
                  _this4.$notify({
                    title: '失败',
                    message: res.data.error,
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  _this4.$notify({
                    title: '成功',
                    message: '修改成功',
                    type: 'success',
                    duration: 1500,
                    onClose: function onClose() {
                      self.getList();
                      self.dialogFormVisible = false;
                      location.reload();
                    }
                  });
                }
              }).catch(function (res) {
                _this4.$notify({
                  title: '失败',
                  message: '请为用户分配数据权限',
                  type: 'error',
                  duration: 2000
                });
              });
            } else {
              Service.yanzheng(_this4.listQuery.name).then(function (res) {
                console.log(res);

                if (res.data.data == false) {
                  _this4.$notify({
                    title: '失败',
                    message: '账号已存在',
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  Service.addRoles(_this4.listQuery).then(function (res) {
                    console.log(res);

                    if (res.data.error) {
                      _this4.$notify({
                        title: '失败',
                        message: res.data.error,
                        type: 'error',
                        duration: 2000
                      });
                    } else {
                      _this4.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success',
                        duration: 1500,
                        onClose: function onClose() {
                          self.getList();
                          self.dialogFormVisible = false;
                        }
                      });
                    }
                  }).catch(function (res) {
                    _this4.$notify({
                      title: '失败',
                      message: '请为用户分配数据权限',
                      type: 'error',
                      duration: 2000
                    });
                  });
                }
              });
            }
          }
        }
      });
    },
    editRole: function editRole(row) {
      var _this5 = this;

      console.log(row);
      this.dialogFormVisible = true;
      this.ids = row.id;
      this.projecthao = [];
      this.listQuery.projectIds = [];
      this.listQuery.passWord = row.psd;
      this.listQuery.userName = row.userName;
      this.listQuery.name = row.name;
      this.listQuery.state = row.state;
      this.juese.forEach(function (item, index) {
        row.userRoles.forEach(function (i, index) {
          if (item.id == i.id) {
            _this5.listQuery.roleId = item.id;
          }
        });
      });
      console.log(this.projects);
      this.projects.forEach(function (item, index) {
        row.userProjects.forEach(function (i, index) {
          if (item.id == i.id) {
            _this5.projecthao.push(item.id);

            _this5.listQuery.projectIds.push(item.id);
          }
        });
      });
    },
    editState: function editState(id, state) {
      // 正常、拒绝
      var self = this;
      Service.editState({
        "id": id,
        "state": state
      }).then(function (res) {
        console.log(res);
        self.$message({
          type: 'success',
          message: '修改成功!',
          onClose: function onClose() {
            self.getList();
          }
        });
      });
    }
  }
};