var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "boxs" }, [
        _c("p", [
          _vm._v(_vm._s(_vm.name == "chuyu" ? "厨余袋物资" : "投递记录")),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "boxs" }, [
        _c("p", [
          _vm._v(
            _vm._s(
              _vm.name == "chuyu" ? "厨余袋绑定次数：" : "厨余投递记录："
            ) + _vm._s(_vm.nums)
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.name == "chuyu"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.list,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  sortable: "custom",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户姓名", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.nickName))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3462521422
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "绑袋类型", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.bindType))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  914133791
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "创建时间", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2774630583
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "二维码组号", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.qrcodeGroup))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3717441527
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.name == "toudi"
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.lists,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  sortable: "custom",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户姓名", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.nickName))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3462521422
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "用户卡号", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.cardNumber))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1810395569
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投递时间", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.updateTime))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2682808770
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "投递类型", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.deviceInTypeInfo)),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3034651183
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "重量", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.deliverWeight) + "g"),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2463311279
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "本次积分", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [_vm._v(_vm._s(scope.row.integral))]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1442715592
                ),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "设备ID", align: "center" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.mbId))])]
                      },
                    },
                  ],
                  null,
                  false,
                  2486140420
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }