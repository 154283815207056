import request from '@/utils/request';
var Service = {
  getWarnView: function getWarnView(params) {
    // 加载页面
    return request({
      url: '/recyclingBinsWarning/getWarnView?mbId=' + params,
      method: 'get'
    });
  }
};
export default Service;