var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("Street", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.addrChange },
          }),
          _vm._v(" "),
          _vm.hasPerm("xiaoquguanli:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { icon: "el-icon-plus", type: "primary" },
                  on: { click: _vm.addRegion },
                },
                [_vm._v("添加")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "",
              align: "center",
              width: "60",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "省/市", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.province))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "市", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.city))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "社区", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.community))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "小区", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.region))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "户数",
              sortable: "",
              prop: "households",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.households))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "中心点坐标", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.lon) + "，" + _vm._s(scope.row.lat)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "250",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("xiaoquguanli:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.showUpdate(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("xiaoquguanli:whitelist")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addRegionNoDialog(scope.row)
                              },
                            },
                          },
                          [_vm._v("添加户号白名单")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("xiaoquguanli:whitedetail")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.getWhiteRegionDetail(scope.row)
                              },
                            },
                          },
                          [_vm._v("白名单明细")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("xiaoquguanli:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "90%",
            top: "20px",
            visible: _vm.dialogVisible,
            title: _vm.titles,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                inline: true,
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "省" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.province,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "province", $$v)
                      },
                      expression: "form.province",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "市/县" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "街道" } },
                [
                  _c("jiedao", {
                    model: {
                      value: _vm.street,
                      callback: function ($$v) {
                        _vm.street = $$v
                      },
                      expression: "street",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "社区" } },
                [
                  _c("shequ", {
                    attrs: { jiedao: _vm.street },
                    model: {
                      value: _vm.town,
                      callback: function ($$v) {
                        _vm.town = $$v
                      },
                      expression: "town",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小区" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.region,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经纬度" } },
                [
                  _c("el-input", {
                    attrs: { disabled: true, type: "input" },
                    model: {
                      value: _vm.form.lon + "," + _vm.form.lat,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.lon + "," + _vm.form, "lat", $$v)
                      },
                      expression: "form.lon + ',' + form.lat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "户数" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.households,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "households", $$v)
                      },
                      expression: "form.households",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "amap-wrap" }, [
                _c("div", { attrs: { id: "amapContainer" } }),
                _vm._v(" "),
                _c("div", { attrs: { id: "pickerBox" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.kong,
                        expression: "kong",
                      },
                    ],
                    staticClass: "el-input__inner",
                    attrs: {
                      id: "pickerInput",
                      placeholder: "输入地名进行选点",
                    },
                    domProps: { value: _vm.kong },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.kong = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "poiInfo" } }),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogRegionNoVisible,
            title: _vm.regionNoTitle,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogRegionNoVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "regionNoForm",
              attrs: {
                "label-width": "50px",
                rules: _vm.regionNoRules,
                model: _vm.regionNoForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "type" } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 0 },
                      model: {
                        value: _vm.regionNoForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.regionNoForm, "type", $$v)
                        },
                        expression: "regionNoForm.type",
                      },
                    },
                    [_vm._v("标准层数、户号统一情况使用")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: 1 },
                      model: {
                        value: _vm.regionNoForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.regionNoForm, "type", $$v)
                        },
                        expression: "regionNoForm.type",
                      },
                    },
                    [_vm._v("非标准楼层、户号以及补录情况使用")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "楼号", prop: "buildingNumber" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.regionNoForm.buildingNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.regionNoForm, "buildingNumber", $$v)
                      },
                      expression: "regionNoForm.buildingNumber",
                    },
                  }),
                  _vm._v("请输入数字，特殊有字母可写字母\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "单元", prop: "unitNumber" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.regionNoForm.unitNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.regionNoForm, "unitNumber", $$v)
                      },
                      expression: "regionNoForm.unitNumber",
                    },
                  }),
                  _vm._v("填写数字或字母，无单元的填写1\n      "),
                ],
                1
              ),
              _vm._v(" "),
              _vm.regionNoForm.type == "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "层数", prop: "d3" } },
                    [
                      _c("el-input", {
                        attrs: { type: "input" },
                        model: {
                          value: _vm.regionNoForm.d3,
                          callback: function ($$v) {
                            _vm.$set(_vm.regionNoForm, "d3", $$v)
                          },
                          expression: "regionNoForm.d3",
                        },
                      }),
                      _vm._v("填写共多少层\n      "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.regionNoForm.type == "1"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "层号", prop: "d4" } },
                    [
                      _c("el-input", {
                        attrs: { type: "input" },
                        model: {
                          value: _vm.regionNoForm.d4,
                          callback: function ($$v) {
                            _vm.$set(_vm.regionNoForm, "d4", $$v)
                          },
                          expression: "regionNoForm.d4",
                        },
                      }),
                      _vm._v(
                        "填写第XXX层，如该单元楼层从2开始，就写 2,3,4,5....(英文逗号分割)\n      "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "户号", prop: "homeNumber" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.regionNoForm.homeNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.regionNoForm, "homeNumber", $$v)
                      },
                      expression: "regionNoForm.homeNumber",
                    },
                  }),
                  _vm._v(
                    "如每层户号是101，102，103，104，105 此处填写，样例：01/02/03/04/05\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.viewRegionNo } },
                [_vm._v("预览")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addRegionNo } },
                [_vm._v("生成")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogViewRegionNoVisible, title: "预览" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogViewRegionNoVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.regionNoList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.id"),
                  prop: "id",
                  sortable: "",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "小区", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.region))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "楼号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.buildingNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单元", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.unitNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "户号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.homeNumber))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogViewRegionHomeNumberVisible,
            title: "小区白名单明细",
            top: "10px",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogViewRegionHomeNumberVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-select",
                {
                  staticClass: "filter-item",
                  attrs: { filterable: "", placeholder: "请选择单元号" },
                  model: {
                    value: _vm.whiteDetailForm.buildingNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.whiteDetailForm, "buildingNumber", $$v)
                    },
                    expression: "whiteDetailForm.buildingNumber",
                  },
                },
                _vm._l(_vm.buildingNumberList, function (obj) {
                  return _c("el-option", {
                    key: obj.buildingNumber,
                    attrs: {
                      label: obj.buildingNumber,
                      value: obj.buildingNumber,
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.getRegionHomeNumbers },
                },
                [_vm._v("\n        查询\n        ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.regionHomeNumberList,
                border: "",
                fit: "",
                "highlight-current-row": "",
                height: "700",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.id"),
                  prop: "id",
                  sortable: "",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.$index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "楼号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.buildingNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "单元号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.unitNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "门牌号", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.homeNumber))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delById(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }