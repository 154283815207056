var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.listQuery.street,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "street", $$v)
              },
              expression: "listQuery.street",
            },
          }),
          _vm._v(" "),
          _vm.hasPerm("voluntarysetting:select")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.getList()
                    },
                  },
                },
                [_vm._v("查询")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("voluntarysetting:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加设置")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "序号", prop: "id", sortable: "", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.timeSlot))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导奖励", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.coin))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "招募人数", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.number))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "预约督导参与说明", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.rules))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "志愿督导手册", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", {
                      staticClass: "table-td-cell",
                      domProps: { innerHTML: _vm._s(scope.row.manual) },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.operator))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updateTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color: scope.row.state == 1 ? "#67C23A" : "#C03639",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.state == 0 ? "关闭" : "开启"))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "230",
              "class-name": "fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("voluntarysetting:update")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("修改")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "90%",
            top: "20px",
            visible: _vm.dialogVisible,
            title: "督导设置",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-width": "150px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "志愿督导街道", required: true } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.form.street,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "street", $$v)
                      },
                      expression: "form.street",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "督导时间设置:", required: true } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        "background-color": "#169BD5",
                        color: "white",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addWorkTimeDialog()
                        },
                      },
                    },
                    [_vm._v("添加工作时间")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", required: "" } },
                    _vm._l(_vm.workTime, function (element, i) {
                      return _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t" +
                            _vm._s(element.begTime) +
                            "-" +
                            _vm._s(element.endTime) +
                            " "
                        ),
                        _c("img", {
                          staticStyle: { width: "15px", height: "15px" },
                          attrs: {
                            src: "http://pic.touduila.com/FuHWSkXgBb9j0DBpi_PRZ5uc8M58",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.delWorktimeConfirm(i)
                            },
                          },
                        }),
                      ])
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "督导奖励:", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.coin,
                        expression: "form.coin",
                      },
                    ],
                    attrs: { type: "number", rows: "10", cols: "100%" },
                    domProps: { value: _vm.form.coin },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "coin", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "招募报名人数:", required: true } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.number,
                        expression: "form.number",
                      },
                    ],
                    attrs: { type: "number", rows: "10", cols: "100%" },
                    domProps: { value: _vm.form.number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "number", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "预约督导参与说明:" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 10 },
                    model: {
                      value: _vm.form.rules,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "rules", $$v)
                      },
                      expression: "form.rules",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "志愿督导手册:" } },
                [
                  _c("Editor", {
                    model: {
                      value: _vm.form.manual,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "manual", $$v)
                      },
                      expression: "form.manual",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态:", required: true } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加工作时间", visible: _vm.dismouthpiecetime },
          on: {
            "update:visible": function ($event) {
              _vm.dismouthpiecetime = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.worktimeforms } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "督导员工作时间设置" } },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "任意时间点",
                    },
                    model: {
                      value: _vm.worktimeforms.begTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.worktimeforms, "begTime", $$v)
                      },
                      expression: "worktimeforms.begTime",
                    },
                  }),
                  _vm._v("\n\t\t\t\t至\n\t\t\t\t"),
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "任意时间点",
                    },
                    model: {
                      value: _vm.worktimeforms.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.worktimeforms, "endTime", $$v)
                      },
                      expression: "worktimeforms.endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dismouthpiecetime = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addWorktimeConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }