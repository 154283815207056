var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("list:pass")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exportimg },
                },
                [_vm._v("一键审核通过")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("list:nopass")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  attrs: { type: "danger" },
                  on: { click: _vm.exportnotimg },
                },
                [_vm._v("一键审核不通过")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("审核状态")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.statuslist, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("打卡类型")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.typeInfo,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "typeInfo", $$v)
                },
                expression: "listQuery.typeInfo",
              },
            },
            _vm._l(_vm.typeIns, function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("客户端")]),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.role,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "role", $$v)
                },
                expression: "listQuery.role",
              },
            },
            _vm._l(_vm.datas, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("街道")]),
          _vm._v(" "),
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.searchStreet,
              callback: function ($$v) {
                _vm.searchStreet = $$v
              },
              expression: "searchStreet",
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "filter-item" }, [_vm._v("昵称")]),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "120px" },
            attrs: { placeholder: "请输入昵称" },
            model: {
              value: _vm.listQuery.userName,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "userName", $$v)
              },
              expression: "listQuery.userName",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.getList },
            },
            [_vm._v("查询")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            "default-expand-all": "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户昵称", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(" " + _vm._s(scope.row.userName))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "上传图片",
              "class-name": "status-col",
              width: "400px",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.pic.split(","), function (i, index) {
                    return scope.row.pic
                      ? _c("img", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "20px",
                          },
                          attrs: { src: "http://pic.touduila.com/" + i },
                          on: {
                            click: function ($event) {
                              return _vm.imgs(scope.row.pic, index)
                            },
                          },
                        })
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打卡类型", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "奖励积分", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.coin))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.auditMethod == 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("自动审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.auditMethod == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("人工审核")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "red" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("待审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 1
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#67C23A" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("审核通过")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == 2
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#C03639" },
                            attrs: { type: "text" },
                          },
                          [_vm._v("审核不通过")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核备注", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属小程序", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(_vm.datas, function (item, index) {
                    return scope.row.role == item.value
                      ? _c("span", [_vm._v(_vm._s(item.name))])
                      : _vm._e()
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "所属街道", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "回收重量", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weight))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核时间", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.checkTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "审核人", "class-name": "status-col" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.adminName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              "class-name": "small-padding fixed-width",
              width: "200",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("list:check")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.beizhu(scope.row)
                              },
                            },
                          },
                          [_vm._v("审核")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("list:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.deletes(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getLists,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "数据审核" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "打卡类型",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.form.typeInfo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "typeInfo", $$v)
                      },
                      expression: "form.typeInfo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "奖励积分",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "coin", $$v)
                      },
                      expression: "form.coin",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择状态" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.communityList, function (obj) {
                      return _c("el-option", {
                        key: obj.id,
                        attrs: { label: obj.region, value: obj.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "上传图", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "https://pic.touduila.com/" + item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 6 },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.typeInfo == "低碳回收"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "回收重量",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", disabled: "" },
                        model: {
                          value: _vm.form.weight,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "weight", $$v)
                          },
                          expression: "form.weight",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.beizhuVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: {
              width: "100%",
              src: "http://pic.touduila.com/" + _vm.dialogImageUrl,
              alt: "",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "图片", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }