import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
import dizhiService from '@/api/dizhi';
export default {
  name: 'Jiedao',
  props: {
    value: [String, Number, Object]
  },
  data: function data() {
    return {
      // 社区列表
      streetList: []
    };
  },
  computed: {
    selectVal: {
      get: function get() {
        if (this.value) {
          return this.value;
        } else {
          return null;
        }
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  created: function created() {
    this.getJiedao();
  },
  methods: {
    // 获取街道
    getJiedao: function getJiedao() {
      var _this = this;

      dizhiService.getUserStreets().then(function (res) {
        _this.streetList = res.data;
      });
    }
  }
};