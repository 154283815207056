//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/chuyu';
import waves from '@/directive/waves'; // Waves directive

import { parseTime } from '@/utils';
import Pagination from '@/components/Pagination';
export default {
  name: 'Jifenduihuan',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      list: null,
      total: 0,
      listLoading: true,
      Options: [{
        name: '未审核',
        value: 0
      }, {
        name: '不通过',
        value: -1
      }, {
        name: '通过',
        value: 1
      }],
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20
      }
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.jifenduihuanList(this.listQuery).then(function (res) {
        console.log(res);
        _this.list = res.data.list;
        _this.total = res.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    shenhe: function shenhe(row, status) {
      var _this2 = this;

      Service.updateChuyuStatus({
        'id': row.id,
        'status': status,
        'weixinId': row.userId
      }).then(function (response) {
        _this2.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        });

        _this2.getList();
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    exportExcel: function exportExcel() {
      location.href = '/operator/kitchenWaste/exportRecycleCoinRecordInfoList';
    }
  }
};