"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.split");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _Editor = _interopRequireDefault(require("@/components/Editor"));

var _service = _interopRequireDefault(require("@/api/service"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Editor: _Editor.default
  },
  data: function data() {
    return {
      datas: '',
      listLoading: false,
      list: [],
      //查询条件
      // listQuery: {
      // 	key: '',
      // },
      dialogVisible: false,
      //添加字段
      form: {
        typeInfo: '',
        //
        pic: [],
        //图片
        state: '',
        //
        type: ''
      },
      juese: [],
      rules: {
        typeInfo: [{
          required: true,
          message: "类型名称不能为空"
        }],
        pic: [{
          required: true,
          message: "图片不能为空"
        }]
      },
      formLabelWidth: '120px',
      uploadPic: [],
      dialogImageUrl: [],
      imgdialogVisible: false
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    //限制图片大小
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this2 = this;

      var isSize = new Promise(function (resolve, reject) {
        var width = 760;
        var height = 530;

        var _URL = window.URL || window.webkitURL;

        var img = new Image();

        img.onload = function () {
          var valid = img.width <= width && img.height <= height;
          valid ? resolve() : reject();
        };

        img.src = _URL.createObjectURL(file);
      }).then(function () {
        return file;
      }, function () {
        _this2.$message.error('上传图片像素建议小于等于760*530!');

        return promise.reject();
      });
      return isSize;
    },
    //获取当前时间
    getCurrentTime: function getCurrentTime() {
      var _this = this;

      var yy = new Date().getFullYear();
      var mm = new Date().getMonth() + 1;
      var dd = new Date().getDate();
      var hh = new Date().getHours();
      var mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      var ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
      return _this.gettime;
    },
    //查询按钮
    handleFilter: function handleFilter() {
      this.getList();
    },
    //获取列表
    getList: function getList() {
      var _this3 = this;

      _service.default.getTypeList().then(function (res) {
        console.log(res);
        _this3.list = res.data.data;
      });
    },
    //添加
    Addnews: function Addnews() {
      var _this4 = this;

      Object.keys(this.form).forEach(function (key) {
        _this4.form[key] = '';
      });
      this.uploadPic = [];
      this.form.state = 0;
      this.dialogVisible = true;
    },
    //修改
    editnews: function editnews(row) {
      var _this5 = this;

      Object.keys(this.form).forEach(function (key) {
        _this5.form[key] = row[key];
      });
      this.form.type = row.type;
      this.form.typeInfo = row.typeInfo;
      this.uploadPic = this.form.pic.split(',');
      this.form.state = row.state;
      this.dialogVisible = true;
    },
    //图片上传
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];
      this.form.pic = response.key;
      this.uploadPic.push(this.form.pic);
      this.$refs.upload.clearFiles();
    },
    //删除已上传的图片
    handleRemove: function handleRemove(item) {
      var _this6 = this;

      var index = this.uploadPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (index > -1) {
          _this6.uploadPic.splice(index, 1);

          _this6.$message({
            type: 'success',
            message: '已删除'
          });
        }
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //点击放大图片
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.imgdialogVisible = true;
    },
    //确定添加/修改
    Saves: function Saves() {
      var _this7 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          console.log(_this7.form);

          if (_this7.form.type) {
            _service.default.updateType(_this7.form).then(function (res) {
              if (res.statusCode == 301) {
                _this7.$message({
                  type: 'error',
                  message: res.data.data
                }); // this.dialogVisible = false

              } else {
                _this7.$message({
                  type: 'success',
                  message: "修改成功！"
                });

                _this7.getList();

                _this7.dialogVisible = false;
              }
            });
          } else {
            _service.default.addType(_this7.form).then(function (res) {
              if (res.statusCode == 301) {
                _this7.$message({
                  type: 'error',
                  message: res.data.data
                }); // this.dialogVisible = false

              } else {
                _this7.$message({
                  type: 'success',
                  message: "添加成功！"
                });

                _this7.getList();

                _this7.dialogVisible = false;
              }
            });
          }
        }
      });
      console.log(this.form);
    }
  }
};
exports.default = _default;