import request from '@/utils/request';
var Service = {
  getProblemReport: function getProblemReport(params) {
    return request({
      url: '/inspect/problemReport',
      method: 'post',
      params: params
    });
  },
  editProblemDesc: function editProblemDesc(params) {
    return request({
      url: '/inspect/problemDesc',
      method: 'post',
      params: params
    });
  },
  getPunchInRecord: function getPunchInRecord(params) {
    return request({
      url: '/inspect/punchInRecord',
      method: 'get',
      params: params
    });
  },
  setproblemState: function setproblemState(params) {
    return request({
      url: '/inspect/problemState',
      methods: 'get',
      params: params
    });
  }
};
export default Service;