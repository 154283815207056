import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/sys';
import waves from '@/directive/waves'; // waves directive

import Pagination from '@/components/Pagination'; // secondary package based on el-pagination

export default {
  name: 'Sysrole',
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  filters: {},
  data: function data() {
    var _ref;

    return _ref = {
      passWord: '',
      // jiedao:'',
      jiedaohao: [],
      rules: {
        passWord: [{
          required: true,
          message: "密码不能为空"
        }]
      },
      checkAll: false,
      isIndeterminate: true,
      jiedao: [{
        juese: '塘沽街道',
        id: 100
      }, {
        juese: '泰达街道',
        id: 101
      }, {
        juese: '新北街道',
        id: 102
      }, {
        juese: '寨上街道',
        id: 103
      }, {
        juese: '北塘街道',
        id: 104
      }, {
        juese: '中新生态城',
        id: 105
      }],
      juesehao: [],
      tableKey: 0,
      list: null,
      total: 0
    }, _defineProperty(_ref, "checkAll", false), _defineProperty(_ref, "isIndeterminate", true), _defineProperty(_ref, "listLoading", true), _defineProperty(_ref, "listQuery", {
      passWord: '',
      userName: '',
      roleIds: [],
      streetIds: []
    }), _defineProperty(_ref, "temp", {
      id: undefined,
      importance: 1,
      remark: '',
      timestamp: new Date(),
      title: '',
      type: '',
      status: 'published'
    }), _defineProperty(_ref, "dialogFormVisible", false), _defineProperty(_ref, "userSelFormVisible", false), _defineProperty(_ref, "authUserQuery", {
      username: '',
      page: 1,
      limit: 10,
      roleid: ''
    }), _defineProperty(_ref, "authUserList", []), _defineProperty(_ref, "selUserQuery", {
      username: '',
      roleId: ''
    }), _defineProperty(_ref, "selUserList", []), _defineProperty(_ref, "addSelUserList", []), _defineProperty(_ref, "isRoleEdit", false), _defineProperty(_ref, "roleFormVisible", false), _defineProperty(_ref, "roleForm", {
      roleId: '',
      remark: '',
      roleName: ''
    }), _defineProperty(_ref, "roleFormTemp", {
      roleId: '',
      remark: '',
      roleName: ''
    }), _defineProperty(_ref, "roleRules", {
      roleName: [{
        required: true,
        message: '不允许为空',
        trigger: 'blur'
      }],
      remark: [{
        required: true,
        message: '不允许为空',
        trigger: 'blur'
      }]
    }), _defineProperty(_ref, "authFormVisible", false), _defineProperty(_ref, "currentAuthData", null), _defineProperty(_ref, "treelist", null), _defineProperty(_ref, "treeChecked", []), _defineProperty(_ref, "defaultProps", {
      children: 'children',
      label: 'name'
    }), _defineProperty(_ref, "userName", ''), _defineProperty(_ref, "ids", ''), _ref;
  },
  watch: {
    juesehao: function juesehao(e) {
      // console.log(e)
      this.listQuery.roleIds = e.join(",");
    },
    jiedaohao: function jiedaohao(e) {
      // console.log(e)
      this.listQuery.streetIds = e.join(",");
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    unique: function unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] == arr[j]) {
            arr.splice(j, 1);
            j--;
          }
        }
      }

      return arr;
    },
    handleCheckAllChange: function handleCheckAllChange(val) {
      var _this = this;

      console.log(val); // this.jiedaohao = val ? this.jiedao : [];

      this.isIndeterminate = false;

      if (val == true) {
        this.jiedao.forEach(function (item, index) {
          // row.streets.forEach((i,index)=>{
          // console.log(i)
          // if(item.juese == i){
          _this.jiedaohao.push(item.id); // console.log(this.listQuery.roleIds)
          // 	}
          // })

        });
        this.listQuery.streetIds = this.unique(this.jiedaohao);
        console.log(this.listQuery.streetIds);
      } else {
        this.jiedaohao = [];
        this.listQuery.streetIds = [];
      }
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      var checkedCount = value.length;
      this.checkAll = checkedCount === this.jiedaohao.length; // console.log(this.checkAll)
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.jiedaohao.length;
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.getzhanghao(this.userName).then(function (res) {
        console.log(res);
        _this2.list = res.data.data;
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    addRole: function addRole() {
      this.jiedaohao = [];
      this.juesehao = [];
      this.listQuery.roleIds = [];
      this.listQuery.streetIds = [];
      this.dialogFormVisible = true;
    },
    // 点击添加
    Sure: function Sure() {
      var _this3 = this;

      var self = this;
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this3.listQuery.streetIds == []) {
            _this3.$notify({
              title: '失败',
              message: '请选择数据权限',
              type: 'error',
              duration: 2000
            });
          } else {
            if (_this3.ids) {
              _this3.listQuery.userId = _this3.ids;
              Service.xiugai(_this3.listQuery).then(function (res) {
                console.log(res);

                if (res.data.error) {
                  _this3.$notify({
                    title: '失败',
                    message: res.data.error,
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  _this3.$notify({
                    title: '成功',
                    message: '修改成功',
                    type: 'success',
                    duration: 1500,
                    onClose: function onClose() {
                      self.getList();
                      self.dialogFormVisible = false;
                      location.reload();
                    }
                  });
                }
              }).catch(function (res) {
                _this3.$notify({
                  title: '失败',
                  message: '请为用户分配数据权限',
                  type: 'error',
                  duration: 2000
                });
              });
            } else {
              Service.yanzheng(_this3.listQuery.userName).then(function (res) {
                console.log(res);

                if (res.data.data == false) {
                  _this3.$notify({
                    title: '失败',
                    message: '用户名已存在',
                    type: 'error',
                    duration: 2000
                  });
                } else {
                  Service.addRoles(_this3.listQuery).then(function (res) {
                    console.log(res);

                    if (res.data.error) {
                      _this3.$notify({
                        title: '失败',
                        message: res.data.error,
                        type: 'error',
                        duration: 2000
                      });
                    } else {
                      _this3.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success',
                        duration: 1500,
                        onClose: function onClose() {
                          self.getList();
                          self.dialogFormVisible = false;
                        }
                      });
                    }
                  }).catch(function (res) {
                    _this3.$notify({
                      title: '失败',
                      message: '请为用户分配数据权限',
                      type: 'error',
                      duration: 2000
                    });
                  });
                }
              });
            }
          }
        }
      });
    },
    editRole: function editRole(row) {
      var _this4 = this;

      console.log(row);
      this.dialogFormVisible = true;
      this.ids = row.id;
      this.jiedaohao = [];
      this.juesehao = [];
      this.listQuery.passWord = row.psd;
      this.listQuery.userName = row.name;
      this.juese.forEach(function (item, index) {
        // console.log(item)
        row.roles.forEach(function (i, index) {
          // console.log(i)
          if (item.juese == i) {
            _this4.juesehao.push(item.id); // console.log(this.listQuery.roleIds)

          }
        });
      });
      this.jiedao.forEach(function (item, index) {
        row.streets.forEach(function (i, index) {
          // console.log(i)
          if (item.juese == i) {
            _this4.jiedaohao.push(item.id); // console.log(this.listQuery.roleIds)

          }
        });
      });
    },
    delRole: function delRole(row) {
      var self = this;
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        Service.delRoles(row.id).then(function (res) {
          console.log(res);
          self.$message({
            type: 'success',
            message: '删除成功!',
            onClose: function onClose() {
              self.getList();
            }
          });
        });
      }).catch(function () {// this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    authRole: function authRole(row) {
      var _this5 = this;

      this.authFormVisible = true;
      this.currentAuthData = row;
      server.getRoleTree(row.roleId).then(function (res) {
        _this5.treelist = res;
        _this5.treeChecked = _this5.getChild(res);
      });
    },
    getChild: function getChild(arr) {
      var self = this;
      var tmp = [];
      arr.forEach(function (item) {
        if (item.checked) {
          tmp.push(item.id);
        }

        if (item.children) {
          tmp = tmp.concat(self.getChild(item.children));
        }
      });
      return tmp;
    },
    setMenuRole: function setMenuRole() {
      var _this6 = this;

      var roleId = this.currentAuthData.roleId;
      var menuids = [];
      var tree = this.$refs.tree.getCheckedNodes();
      console.log(tree);
      tree.forEach(function (item) {
        menuids.push(item.id);
      });
      server.setMenuRoles({
        roleId: roleId,
        menuids: menuids
      }).then(function (res) {
        _this6.authFormVisible = false;

        _this6.$message({
          type: 'success',
          message: '权限配置成功'
        });
      });
    },
    userRole: function userRole(row) {
      console.log(row);
      this.authUserQuery.roleid = row.roleId;
      this.getRoleUserList();
    },
    getRoleUserList: function getRoleUserList() {
      var _this7 = this;

      server.getRoleUserList(this.authUserQuery).then(function (res) {
        if (!res.code) {
          _this7.authUserList = res.page.list;
        }
      });
    },
    showSelUser: function showSelUser() {
      this.userSelFormVisible = true;
      this.selUserQuery.roleId = this.authUserQuery.roleid;
      this.getSelUserList();
    },
    getSelUserList: function getSelUserList() {
      var _this8 = this;

      server.getSelRoleUserList(this.selUserQuery).then(function (res) {
        if (!res.code) {
          _this8.selUserList = res.list;
        }
      });
    },
    // handleSelectionChange(val) {
    //   this.addSelUserList = val
    // },
    delRoleUser: function delRoleUser(row) {
      var roleId = this.authUserQuery.roleid;
      var userId = row.userId;
      var self = this;
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        server.delRoleUser({
          roleId: roleId,
          userId: userId
        }).then(function (res) {
          self.$message({
            type: 'success',
            message: '删除成功!',
            onClose: function onClose() {
              self.getRoleUserList();
            }
          });
        });
      }).catch(function () {// this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    addSelUser: function addSelUser() {
      var self = this;
      var roleId = this.selUserQuery.roleId;
      var userIds = [];
      this.addSelUserList.forEach(function (item) {
        userIds.push({
          roleId: roleId,
          userId: item.userId
        });
      });
      server.setSelRoleUserList(userIds).then(function (res) {
        self.userSelFormVisible = false;
        self.$message({
          type: 'success',
          message: '添加成功!',
          onClose: function onClose() {
            self.getRoleUserList();
          }
        });
      });
    }
  }
};