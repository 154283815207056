//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      listLoading: false,
      list: JSON.parse(this.$route.query.data) ? JSON.parse(this.$route.query.data) : []
    };
  },
  created: function created() {
    console.log(this.list);
  },
  methods: {}
};