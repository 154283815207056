import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/components/Input/Inputnum.js';
import qs from 'qs';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination'; // Secondary package based on el-pagination

import noel from '@/components/street/noeladdstreet';
import { parseTime } from '@/utils';
export default {
  name: 'Order',
  components: {
    Pagination: Pagination,
    noel: noel
  },
  directives: {
    waves: waves
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var _statusMap;

      var statusMap = (_statusMap = {
        '10': 'success',
        '5': 'info'
      }, _defineProperty(_statusMap, "10", 'primary'), _defineProperty(_statusMap, '-1', 'danger'), _statusMap);
      return statusMap[status];
    },
    statusName: function statusName(status) {
      var statusMap = {
        '1': '派单中',
        '5': '回收中',
        '10': '已完成',
        '-1': '已删除'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      lists: {
        num: ''
      },
      dialog: false,
      dialoges: false,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      mapVisible: false,
      beizhuVisible: false,
      tableKey: 0,
      list: [],
      total: 0,
      listLoading: true,
      listQuery: {
        street: '',
        region: '',
        community: '',
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        reportStatus: ''
      },
      beizhuTemp: {
        orderId: '',
        remark: ''
      },
      Options: [{
        name: '查询全部',
        value: ''
      }, {
        name: '已上报',
        value: 1
      }, {
        name: '未上报',
        value: 0
      }],
      dialogs: false,
      alertlist: ''
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    noelchange: function noelchange(data) {
      this.listQuery.street = data.street;
      this.listQuery.community = data.community;
      this.listQuery.region = data.region;
    },
    addrChange: function addrChange(data) {
      // console.log(data)
      this.listQuery.community = data.community;
      this.listQuery.number = data.floor;
      this.listQuery.region = data.region;
      this.listQuery.street = data.street;
      this.listQuery.streetId = data.streetId;
      this.listQuery.communityId = data.communityId;
      this.listQuery.regionId = data.regionId;
      this.listQuery.number = data.floor;
    },
    toogleExpand: function toogleExpand(e) {
      this.dialogs = true;
      this.alertlist = e.goods;
    },
    exportExcel: function exportExcel() {
      Service.exportAccountWasteRecord(this.listQuery).then(function (res) {
        console.log(res);
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var elink = document.createElement('a');
          elink.download = '导出工作记录信息.xls';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      Service.getAccountWasteRecord(this.listQuery).then(function (response) {
        console.log(response.data.data);
        _this.list = response.data.data.list;
        _this.total = response.data.data.totalRows;
        setTimeout(function () {
          _this.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    }
  }
};