import request from '@/utils/request';
/**
 * 回收员管理-督导员工作数据
 */

var Service = {
  // 加载页面
  getList: function getList(params) {
    return request({
      url: '/operation/recycler/work/list',
      method: 'post',
      params: params
    });
  },
  // 评分审核
  checkSave: function checkSave(params) {
    return request({
      url: '/operation/recycler/audit/score',
      method: 'post',
      params: params
    });
  },
  // 获取基础工作量
  settingLoad: function settingLoad() {
    return request({
      url: '/operation/recycler/get/basic',
      method: 'get'
    });
  },
  // 设置基础工作量
  settingSave: function settingSave(params) {
    return request({
      url: '/operation/recycler/set/basic',
      method: 'post',
      params: params
    });
  },
  // 发放
  send: function send(params) {
    return request({
      url: '/operation/recycler/grant/score',
      method: 'post',
      params: params
    });
  },
  // 一键发放
  lotsend: function lotsend(params) {
    return request({
      url: '/operation/recycler/grant/scores?ids=' + params,
      method: 'post'
    });
  }
  /*add(params) {
    return request({
      url: '/recyclingBinsAdv/insert',
      method: 'post',
  	headers: {
  		'Content-Type': 'application/json'
  	},
  	data: params
    })
  },
  update(params) {
    return request({
      url: '/recyclingBinsAdv/update',
      method: 'post',
  	headers: {
  		'Content-Type': 'application/json'
  	},
  	data: params
    })
  }*/

};
export default Service;