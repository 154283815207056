var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                "allow-create": "",
                placeholder: "请选择状态",
              },
              model: {
                value: _vm.listQuery.state,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "state", $$v)
                },
                expression: "listQuery.state",
              },
            },
            _vm._l(_vm.states, function (obj) {
              return _c("el-option", {
                key: obj.type,
                attrs: { label: obj.info, value: obj.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入点位名称" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _vm.hasPerm("point:select")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.handleFilter },
                },
                [_vm._v("\n\t\t\t查询\n\t\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("point:add")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { type: "primary", icon: "el-icon-edit" },
                  on: { click: _vm.addPoint },
                },
                [_vm._v("\n\t\t\t添加\n\t\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { margin: "auto" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "社区" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      scope.row.community
                        ? _c("span", [_vm._v(_vm._s(scope.row.community))])
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "小区" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.region))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "点位名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.pointName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "点位图", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: {
                        src: "https://pic.touduila.com/" + scope.row.pointPic,
                        width: "50",
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.loadImage(scope.row.pointPic)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "点位类型", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.pointTypeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "关联设备", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function ($event) {
                            return _vm.chakan(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.state == -1
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("禁用"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == 0
                      ? _c("span", [_vm._v("待审核")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == 1
                      ? _c("span", { staticStyle: { color: "#169BD5" } }, [
                          _vm._v("待完善"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.state == 2
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("通过"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.adminName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("point:update")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editPoint(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("point:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.delPoint(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("point:log")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "info", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.viewLog(scope.row)
                              },
                            },
                          },
                          [_vm._v("操作记录")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("point:code")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.erweima(scope.row)
                              },
                            },
                          },
                          [_vm._v("打卡码")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加/编辑", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "dataForm", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "80px" } },
                [
                  _c("addrselect", {
                    attrs: {
                      street: _vm.form.street,
                      community: _vm.form.community,
                      region: _vm.form.region,
                      floor: _vm.form.floor,
                      showFloor: false,
                    },
                    on: { addrChange: _vm.addrChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "点位名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "pointName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      placeholder: "命名案例：向阳小区2号楼北侧",
                    },
                    model: {
                      value: _vm.form.pointName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pointName", $$v)
                      },
                      expression: "form.pointName",
                    },
                  }),
                  _c("span", { staticStyle: { color: "gray" } }, [
                    _vm._v("*公共区域可直接命名，如XX社区点位"),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "点位类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "pointType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.form.pointType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pointType", $$v)
                        },
                        expression: "form.pointType",
                      },
                    },
                    _vm._l(_vm.pointTypeList, function (obj) {
                      return _c("el-option", {
                        key: obj.type,
                        attrs: { label: obj.typeInfo, value: obj.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地图坐标(经纬度)" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "35%" },
                    attrs: { autocomplete: "off", placeholder: "经度" },
                    model: {
                      value: _vm.form.longitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "longitude", $$v)
                      },
                      expression: "form.longitude",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "35%" },
                    attrs: { autocomplete: "off", placeholder: "维度" },
                    model: {
                      value: _vm.form.latitude,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "latitude", $$v)
                      },
                      expression: "form.latitude",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maps,
                      expression: "maps",
                    },
                  ],
                  staticClass: "amap-wrap",
                },
                [
                  _c("div", { attrs: { id: "pickerBox" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kong,
                          expression: "kong",
                        },
                      ],
                      staticClass: "el-input__inner",
                      attrs: {
                        id: "pickerInput",
                        placeholder: "输入地名进行选点",
                      },
                      domProps: { value: _vm.kong },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.kong = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "poiInfo" } }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "searchResults" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: { height: "400px", width: "100%" },
                    attrs: { id: "amapContainer" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "点位图" } }, [
                _c(
                  "div",
                  { staticStyle: { float: "left" } },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        attrs: {
                          action: "/operator/tools/upload",
                          "list-type": "picture-card",
                          name: "photo",
                          "show-file-list": false,
                          "on-success": _vm.uploadImg,
                          "auto-upload": true,
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-plus",
                          attrs: { slot: "default" },
                          slot: "default",
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { float: "left" } }, [
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    _vm._l(_vm.uploadPic, function (item) {
                      return _c(
                        "li",
                        { staticClass: "el-upload-list__item is-success" },
                        [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: {
                              src: "http://pic.touduila.com/" + item,
                              alt: "",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(item)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "state" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.state,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "state", $$v)
                        },
                        expression: "form.state",
                      },
                    },
                    _vm._l(_vm.states, function (item) {
                      return _c("el-radio", { attrs: { label: item.type } }, [
                        _vm._v(_vm._s(item.info)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", rows: 3 },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addOrUpdatePoint },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "打印/下载",
            visible: _vm.disdayin,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disdayin = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.dayin()
                },
              },
            },
            [_vm._v("打印")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.xiazai()
                },
              },
            },
            [_vm._v("下载")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作记录",
            visible: _vm.disOperLog,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disOperLog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.operLogList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "街道" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "社区" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          scope.row.community
                            ? _c("span", [_vm._v(_vm._s(scope.row.community))])
                            : _vm._e(),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "小区" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.region))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "点位名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.pointName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "点位图", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          attrs: {
                            src:
                              "https://pic.touduila.com/" + scope.row.pointPic,
                            width: "50",
                            alt: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "点位类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.pointTypeInfo))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.state == -1
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("禁用"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 0
                          ? _c("span", [_vm._v("待审核")])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 1
                          ? _c("span", { staticStyle: { color: "#169BD5" } }, [
                              _vm._v("待完善"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.state == 2
                          ? _c("span", { staticStyle: { color: "green" } }, [
                              _vm._v("通过"),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "操作人", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.adminName))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "备注", align: "center", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.remark))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "点位", visible: _vm.dialog, top: "10px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: "http://pic.touduila.com/" + _vm.images, alt: "" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联设备",
            visible: _vm.disDialogDevice,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disDialogDevice = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.pointDeviceList,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", width: "50" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return [_c("span", [_vm._v(_vm._s($index + 1))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "设备码" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.mbId))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _c("span", [
                            _vm._v(_vm._s(scope.row.deviceTypeInfo)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.deviceState))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }