//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import jiedao from '@/components/street/jiedao';
import Service from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import { parseTime } from '@/utils';
import Pagination from '@/components/Pagination';
export default {
  name: 'Chuyulist',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      searchStreet: null,
      dialogImageUrl: '',
      chanames: '',
      tableKey: 0,
      form: {
        name: '',
        phone: ''
      },
      chalists: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date().getTime() - 3600 * 1000 * 24 * 90, new Date()],
      list: null,
      total: 0,
      totals: 0,
      listLoading: true,
      Options: [{
        name: '未审核',
        value: 0
      }, {
        name: '不通过',
        value: -1
      }, {
        name: '通过',
        value: 1
      }],
      datas: [{
        name: '居民小程序',
        value: 1
      }, {
        name: '泰达投对小程序',
        value: 80
      }],
      listQuery: {
        startTime: parseTime(new Date().getTime() - 3600 * 1000 * 24 * 90, '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}'),
        currentPage: 1,
        numOfPage: 20,
        key: '',
        street: ''
      },
      dialogFormVisible: false,
      lQuery: {
        title: '',
        key: '',
        role: 1,
        type: 0,
        currentPage: 1,
        numOfPage: 20,
        status: 1
      },
      UserId: '',
      datalist: null,
      shows: false,
      dialogVisible: false
    };
  },
  watch: {
    time: function time(val) {
      this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
      this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
    },
    dialogFormVisible: function dialogFormVisible(val) {
      if (val == false) {
        this.shows = false, this.datalist = '', this.chanames = '', this.lQuery.title = '';
      }
    },
    searchStreet: function searchStreet(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.dialogVisible = true;
    },
    tiaochu: function tiaochu() {
      this.shows = true;
    },
    changeUserLocation: function changeUserLocation(use) {
      console.log(use);
      this.UserId = use.id;
      this.chanames = use.nickName;
      this.datalist = null;
      this.shows = false;
    },
    handleFilters: function handleFilters() {
      this.en();
    },
    Tjchuyu: function Tjchuyu(userId, Name) {
      var n = userId;
      this.UserId = userId;
      this.lQuery.title = Name;
    },
    en: function en() {
      var _this = this;

      Service.owerList(this.lQuery).then(function (response) {
        console.log(response.data);
        _this.datalist = response.data.list;
        _this.totals = response.data.totalRows;
      });
    },
    handleF: function handleF() {
      this.en();
    },
    Sures: function Sures() {
      var _this2 = this;

      if (this.chanames == '') {
        this.$message({
          message: '请选择姓名',
          type: 'warning'
        });
      } else {
        Service.TianChuyu(this.UserId).then(function (response) {
          _this2.$message({
            message: '投递成功',
            type: 'success'
          });

          _this2.getList();

          setTimeout(function () {
            _this2.dialogFormVisible = false;
          }, 1000);
        }).catch(function (err) {
          _this2.$message.error('该用户没有绑定厨余袋');

          setTimeout(function () {
            _this2.dialogFormVisible = false;
          }, 1000);
        });
      }
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.chuyuceping(this.listQuery).then(function (response) {
        console.log(response);
        _this3.list = response.data.data.list;
        _this3.total = response.data.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this3.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    shenhe: function shenhe(row, status) {
      var _this4 = this;

      Service.updateChuyuStatus({
        'id': row.id,
        'status': status,
        'weixinId': row.userId
      }).then(function (response) {
        _this4.$notify({
          title: '成功',
          message: '修改成功',
          type: 'success',
          duration: 2000
        });

        _this4.getList();
      });
    },
    handleFilter: function handleFilter() {
      this.getList();
    },
    exportExcel: function exportExcel() {
      location.href = '/operator/kitchenWaste/export?startTime=' + this.listQuery.startTime + '&endTime=' + this.listQuery.endTime;
    },
    addjilu: function addjilu() {
      this.dialogFormVisible = true;
    }
  }
};