import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination'; // import Service from '@/api/deviceqr'

import waves from '@/directive/waves'; // Waves directive

import service from '@/api/service';
export default {
  components: {
    Pagination: Pagination
  },
  directives: {
    waves: waves
  },
  props: {
    dataHeight: {
      type: String,
      default: '650px'
    }
  },
  data: function data() {
    return {
      formLabelWidth: '160px',
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        street: "",
        // 街道
        position: "" //位置

      },
      total: 0,
      list: [],
      streets: [],
      // 街道列表
      positions: [],
      // 场景位置
      dialog: false,
      images: ''
    };
  },
  watch: {
    "listQuery.street": function listQueryStreet(val) {
      // console.log(val);
      if (val == "") {
        this.positions = [];
        this.listQuery.position = "";
      }
    }
  },
  created: function created() {
    this.getJiedao(); // 街道列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      service.picRecordList(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.totalRows;
        _this.listLoading = false;
      });
    },
    del: function del(row) {
      var _this2 = this;

      //console.log(row)
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        service.deleteRecord(row.id).then(function (res) {
          if (res.data.error) {
            _this2.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.getList();
        });
      }).catch(function () {});
    },
    getJiedao: function getJiedao() {
      var _this3 = this;

      service.getSceneLocation(this.listQuery.street).then(function (res) {
        _this3.streets = res.data.data;
      });
    },
    streetChange: function streetChange(street) {
      // 街道列表改变赋值
      this.listQuery.street = street;
      this.getSceneLocation();
    },
    getSceneLocation: function getSceneLocation() {
      var _this4 = this;

      //场景位置
      service.getSceneLocation(this.listQuery.street).then(function (res) {
        _this4.positions = res.data.data;
      });
    },
    imgs: function imgs(e) {
      var _this5 = this;

      this.dialog = true;
      setTimeout(function () {
        _this5.images = e.split(',');

        _this5.$refs.carousel.setActiveItem(0);
      }, 200);
    }
  }
};