import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Editor from "@/components/Editor";
import Service from '@/api/service';
export default {
  components: {
    Editor: Editor
  },
  data: function data() {
    return {
      datas: '',
      listLoading: false,
      list: [],
      //查询条件
      listQuery: {
        key: ''
      },
      dialogVisible: false,
      //添加新闻字段
      form: {
        title: '',
        //标题
        pic: [],
        //图片
        sort: '',
        //排序
        status: '',
        //发布状态
        newsJson: '',
        //文本内容
        author: '',
        //作者
        newsTime: '',
        //发布时间
        number: '',
        //阅读量
        client: '',
        //适用客户端
        summary: '',
        //简介
        id: ''
      },
      juese: [],
      rules: {
        title: [{
          required: true,
          message: "新闻不能为空"
        }],
        pic: [{
          required: true,
          message: "封面图不能为空"
        }],
        sort: [{
          required: true,
          message: "排序不能为空"
        }],
        summary: [{
          required: true,
          message: "简介不能为空"
        }],
        newsJson: [{
          required: true,
          message: "文本内容不能为空"
        }]
      },
      juesehao: [],
      formLabelWidth: '120px',
      uploadPic: [],
      dialogImageUrl: [],
      imgdialogVisible: false
    };
  },
  watch: {
    juesehao: function juesehao(e) {
      this.form.client = e.join(",");
    }
  },
  created: function created() {
    this.getList();
    this.getClient();
  },
  methods: {
    //限制图片大小
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var _this2 = this;

      var isSize = new Promise(function (resolve, reject) {
        var width = 760;
        var height = 530;

        var _URL = window.URL || window.webkitURL;

        var img = new Image();

        img.onload = function () {
          var valid = img.width <= width && img.height <= height;
          valid ? resolve() : reject();
        };

        img.src = _URL.createObjectURL(file);
      }).then(function () {
        return file;
      }, function () {
        _this2.$message.error('上传图片像素建议小于等于760*530!');

        return promise.reject();
      });
      return isSize;
    },
    //获取客户端白名单
    getClient: function getClient() {
      var _this3 = this;

      Service.Websiteclient().then(function (res) {
        console.log(res);
        _this3.juese = res.data;
      });
    },
    //获取当前时间
    getCurrentTime: function getCurrentTime() {
      var _this = this;

      var yy = new Date().getFullYear();
      var mm = new Date().getMonth() + 1;
      var dd = new Date().getDate();
      var hh = new Date().getHours();
      var mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
      var ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss;
      return _this.gettime;
    },
    //查询按钮
    handleFilter: function handleFilter() {
      this.getList();
    },
    //获取新闻列表
    getList: function getList() {
      var _this4 = this;

      Service.WebsitewebNews(this.listQuery).then(function (res) {
        console.log(res);
        _this4.list = res.data.data;
      });
    },
    //添加新闻
    Addnews: function Addnews() {
      var _this5 = this;

      delete this.form.id;
      this.juesehao = [];
      Object.keys(this.form).forEach(function (key) {
        _this5.form[key] = '';
      });
      this.form.status = 1;
      this.form.sort = 100;
      this.dialogVisible = true;
    },
    //修改新闻
    editnews: function editnews(row) {
      var _this6 = this;

      Object.keys(this.form).forEach(function (key) {
        _this6.form[key] = row[key];
      });
      this.form.id = row.id; // this.newsdetail(row.id)

      row.status == '待发布' ? this.form.status = 0 : this.form.status = 1;
      this.uploadPic = this.form.pic.split(',');
      this.juesehao = [];
      this.juese.forEach(function (item, index) {
        row.client.split(',').forEach(function (i, index) {
          if (item == i) {
            _this6.juesehao.push(item);
          }
        });
      });
      this.dialogVisible = true;
    },
    //删除新闻
    delnews: function delnews(rows) {
      var _this7 = this;

      this.$confirm('删除新闻, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        Service.deletenewslist({
          id: rows.id
        }).then(function (res) {
          _this7.$message({
            type: 'success',
            message: '已删除'
          });

          _this7.getList();
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //新闻详情
    // newsdetail(id){
    // 	Service.WebsitenewsDetails({id:id}).then((res)=>{
    // 		res.data.data?this.form.newsJson = res.data.data.newsJson:this.form.newsJson = ''
    // 	})
    // },
    //图片上传
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];
      this.form.pic = response.key;
      this.uploadPic.push(this.form.pic);
      this.$refs.upload.clearFiles();
    },
    //删除已上传的图片
    handleRemove: function handleRemove(item) {
      var _this8 = this;

      var index = this.uploadPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (index > -1) {
          _this8.uploadPic.splice(index, 1);

          _this8.$message({
            type: 'success',
            message: '已删除'
          });
        }
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //点击放大图片
    handlePictureCardPreview: function handlePictureCardPreview(files) {
      this.dialogImageUrl = files;
      this.imgdialogVisible = true;
    },
    //确定添加/修改新闻
    Saves: function Saves() {
      var _this9 = this;

      !this.form.newsTime ? this.form.newsTime = this.getCurrentTime() : this.form.newsTime;
      this.form.status == 1 ? this.form.status = '已发布' : this.form.status = '待发布';
      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          Service.WebsiteaddNews(_this9.form).then(function (res) {
            _this9.$message({
              type: 'success',
              message: res.data.data
            });

            _this9.getList();

            _this9.dialogVisible = false;
          });
        }
      });
      console.log(this.form);
    }
  }
};