var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticStyle: {
            "text-align": "center",
            "font-size": "24px",
            "line-height": "57px",
          },
        },
        [_vm._v(_vm._s(_vm.name))]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "商品信息", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.forms } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品名称",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "goodsName", $$v)
                      },
                      expression: "forms.goodsName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "商品价格",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.coin,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "coin", $$v)
                      },
                      expression: "forms.coin",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "商品入库", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "font-size": "24px",
                "line-height": "57px",
                "text-align": "center",
              },
            },
            [_vm._v(_vm._s(_vm.name))]
          ),
          _vm._v(" "),
          _c(
            "el-form",
            { attrs: { model: _vm.ruku } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "入库数量",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.ruku.goods,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruku, "goods", $$v)
                      },
                      expression: "ruku.goods",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "入库人", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.ruku.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruku, "name", $$v)
                      },
                      expression: "ruku.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "入库图片",
                    prop: "img",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        limit: 1,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.uploadPic
                    ? _c(
                        "ul",
                        {
                          staticClass:
                            "el-upload-list el-upload-list--picture-card",
                        },
                        [
                          _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.uploadPic,
                                  alt: "",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleRemove(_vm.uploadPic)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "el-icon-delete" })]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "货道", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.ways))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "商品名称", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.goodsName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "价格", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.coin))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "库存", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.goods))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "已兑换", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.alreadyGoods))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "入库记录", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.rukuad(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "success", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.rukus(scope.row)
                          },
                        },
                      },
                      [_vm._v("入库")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }