//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import Service from '@/api/kitchensorting';
import waves from '@/directive/waves'; // Waves directive

import ServiceStreet from '@/api/service';
import draggable from 'vuedraggable';
import { parseTime } from "@/utils";
export default {
  components: {
    Pagination: Pagination,
    draggable: draggable
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      time: [new Date(), new Date()],
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        mbId: "",
        // 查询条件
        startTime: parseTime(new Date(), '{y}-{m}-{d}'),
        endTime: parseTime(new Date(), '{y}-{m}-{d}')
      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      list: [],
      places: []
    };
  },
  watch: {
    time: function time(val) {
      if (val) {
        this.listQuery.startTime = parseTime(val[0], '{y}-{m}-{d}');
        this.listQuery.endTime = parseTime(val[1], '{y}-{m}-{d}');
      } else {
        this.listQuery.startTime = '';
        this.listQuery.endTime = '';
      }
    }
  },
  created: function created() {
    this.getPlaces(); // 街道列表

    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      Service.getReport1(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    getPlaces: function getPlaces() {
      var _this2 = this;

      Service.getPlaces().then(function (res) {
        console.log(res);
        _this2.places = res.data;
      });
    },
    placeChange: function placeChange(mbId) {
      // 街道列表改变赋值
      this.listQuery.mbId = mbId;
    }
  }
};