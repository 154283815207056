var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-position": "left",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动标题", prop: "title" } },
            [
              _c("el-input", {
                attrs: { type: "input" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动说明", prop: "introduce" } },
            [
              _c("el-input", {
                attrs: {
                  autosize: { minRows: 10, maxRows: 10 },
                  type: "textarea",
                  placeholder: "请填写说明",
                },
                model: {
                  value: _vm.form.introduce,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "introduce", $$v)
                  },
                  expression: "form.introduce",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "每日参与次数", prop: "perDayNumber" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.perDayNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "perDayNumber", $$v)
                  },
                  expression: "form.perDayNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "单次题目数量", prop: "singleNumber" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.singleNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "singleNumber", $$v)
                  },
                  expression: "form.singleNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "及格题目数", prop: "pass" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.pass,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pass", $$v)
                  },
                  expression: "form.pass",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "活动奖励积分", prop: "coin" } },
            [
              _c("el-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.form.coin,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "coin", $$v)
                  },
                  expression: "form.coin",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.updateData } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }