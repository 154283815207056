var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入设备编码或设备名称" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _vm.hasPerm("devicedtu:select")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.getList },
                },
                [_vm._v(" 查询 ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("devicedtu:insert")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v(" 添加设备 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "50" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备编码", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mbId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "关联点位" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.pointName))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备位置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.street) +
                          "，" +
                          _vm._s(scope.row.community) +
                          " ，" +
                          _vm._s(scope.row.region)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "坐标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.lon) + "," + _vm._s(scope.row.lat)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.times))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "心跳状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v(_vm._s(scope.row.heartbeatStatus))]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v("(" + _vm._s(scope.row.updateTime) + ")"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备照片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      scope.row.pic
                        ? _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src: "http://pic.touduila.com/" + scope.row.pic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.loadImage(scope.row.pic)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递次数", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.deliverNum) + "次")]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.addPerson))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备来源", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.formId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.createTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "更新时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.updateTime))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "270" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("devicedtu:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.editChoice(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("devicedtu:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.danger(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("devicedtu:setting")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.getMouthpieceList(scope.row)
                              },
                            },
                          },
                          [_vm._v("投口设置")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("devicedtu:mainboard") && !scope.row.name
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addMainBoardViewTiHuan(scope.row)
                              },
                            },
                          },
                          [_vm._v("替换原设备主板")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("devicedtu:newmainboard") && scope.row.name
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.addMainBoardView(scope.row)
                              },
                            },
                          },
                          [_vm._v("更换新设备主板")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加和编辑DTU设备信息",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "datas", attrs: { model: _vm.forms, rules: _vm.rule } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备编码",
                    "label-width": "80px",
                    prop: "mbId",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: _vm.disabled },
                    model: {
                      value: _vm.forms.mbId,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "mbId", $$v)
                      },
                      expression: "forms.mbId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备名称",
                    prop: "name",
                    "label-width": "80px",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "name", $$v)
                      },
                      expression: "forms.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "", "label-width": "80px" } },
                [
                  _c("pointSelect", {
                    attrs: {
                      street: _vm.addr.street,
                      community: _vm.addr.community,
                      region: _vm.addr.region,
                      streetId: _vm.addr.streetId,
                      communityId: _vm.addr.communityId,
                      regionId: _vm.addr.regionId,
                      showFloor: false,
                      mark: _vm.addr.mark,
                      pointName: _vm.addr.pointName,
                    },
                    on: { addrChange: _vm.addrChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "地图坐标(经纬度)" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "35%" },
                    attrs: { autocomplete: "off", placeholder: "经度" },
                    model: {
                      value: _vm.forms.lon,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "lon", $$v)
                      },
                      expression: "forms.lon",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "35%" },
                    attrs: { autocomplete: "off", placeholder: "维度" },
                    model: {
                      value: _vm.forms.lat,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "lat", $$v)
                      },
                      expression: "forms.lat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maps,
                      expression: "maps",
                    },
                  ],
                  staticClass: "amap-wrap",
                },
                [
                  _c("div", { attrs: { id: "pickerBox" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kong,
                          expression: "kong",
                        },
                      ],
                      staticClass: "el-input__inner",
                      attrs: {
                        id: "pickerInput",
                        placeholder: "输入地名进行选点",
                      },
                      domProps: { value: _vm.kong },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.kong = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "poiInfo" } }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "searchResults" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: { height: "400px", width: "100%" },
                    attrs: { id: "amapContainer" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "定时投递时间" } },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "开始时间",
                    },
                    on: { change: _vm.changeStartTime },
                    model: {
                      value: _vm.forms.begTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "begTime", $$v)
                      },
                      expression: "forms.begTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "结束时间",
                    },
                    on: { change: _vm.changeEndTime },
                    model: {
                      value: _vm.forms.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "endTime", $$v)
                      },
                      expression: "forms.endTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备来源",
                    "label-width": "80px",
                    prop: "formId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "请选择设备来源",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.forms.formId,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "formId", $$v)
                        },
                        expression: "forms.formId",
                      },
                    },
                    _vm._l(_vm.sources, function (i) {
                      return _c("el-option", {
                        key: i.value,
                        attrs: {
                          label: i.value + "-" + i.label,
                          value: i.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设备图片", "label-width": "80px" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    [
                      _vm.forms.pic
                        ? _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.forms.pic,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addOrUpdateDtu },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "DTU投口参数配置",
            visible: _vm.dismouthpiece,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dismouthpiece = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addMouthpiece },
                },
                [_vm._v(" 添加一条 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.copyMouthpiece },
                },
                [_vm._v(" 复制一条 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteMouthpiece },
                },
                [_vm._v(" 重置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listMouthpieceLoading,
                      expression: "listMouthpieceLoading",
                    },
                  ],
                  key: _vm.tableKeyMouthpiece,
                  ref: "listMouthpieceRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listMouthpiece,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", align: "center", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [_c("span", [_vm._v(_vm._s($index + 1))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投口", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.num))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投递类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "120px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.typesChange(row)
                                  },
                                },
                                model: {
                                  value: row.type,
                                  callback: function ($$v) {
                                    _vm.$set(row, "type", $$v)
                                  },
                                  expression: "row.type",
                                },
                              },
                              _vm._l(_vm.typesOptions, function (item) {
                                return _c("el-option", {
                                  key: item.type,
                                  attrs: {
                                    label: item.typeInfo,
                                    value: item.type,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投递积分" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c("el-input", {
                              attrs: {
                                autocomplete: "off",
                                type: "number",
                                placeholder: "投递积分",
                              },
                              model: {
                                value: row.coin,
                                callback: function ($$v) {
                                  _vm.$set(row, "coin", $$v)
                                },
                                expression: "row.coin",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer_jifen",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.mouthpieceConfirm },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [_vm._v("\n\t\t\t\t操作说明：\n\t\t\t")]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n\t\t\t\t1、投口1默认位于设备从左数第一个投口，依次排序添加。\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n\t\t\t\t2、重置按钮慎重操作，为清空投口配置，从新设置。\n\t\t\t"
                ),
              ]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.mainBoardDialag,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mainBoardDialag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "mainBoardForm",
              attrs: { model: _vm.mainBoardForm, rules: _vm.mainBoardFormRule },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请输入新设备编码",
                    "label-width": "150px",
                    prop: "newMbId",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.mainBoardForm.newMbId,
                      callback: function ($$v) {
                        _vm.$set(_vm.mainBoardForm, "newMbId", $$v)
                      },
                      expression: "mainBoardForm.newMbId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mainBoardDialag = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveMainBoard },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.mainBoardTiHuanDialag,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.mainBoardTiHuanDialag = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "mainBoardTiHuanForm",
              attrs: {
                model: _vm.mainBoardTiHuanForm,
                rules: _vm.mainBoardTiHuanFormRule,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "请输入原设备编码",
                    "label-width": "150px",
                    prop: "oldMbId",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.mainBoardTiHuanForm.oldMbId,
                      callback: function ($$v) {
                        _vm.$set(_vm.mainBoardTiHuanForm, "oldMbId", $$v)
                      },
                      expression: "mainBoardTiHuanForm.oldMbId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mainBoardTiHuanDialag = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveMainBoardTiHuan },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "设备图片", visible: _vm.dialog, top: "10px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "100%" },
            attrs: { src: "http://pic.touduila.com/" + _vm.images, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }