import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    return request({
      url: '/operation/stats/week_data',
      method: 'get',
      params: params
    });
  },
  delById: function delById(id) {
    return request({
      url: '/operation/stats/week_delete?id=' + id,
      method: 'get'
    });
  },
  updateWeekData: function updateWeekData(data) {
    return request({
      url: '/operation/stats/updateWeekData',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  },
  addWeekData: function addWeekData(data) {
    return request({
      url: '/operation/stats/addWeekData',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(data)
    });
  }
};
export default Service;