"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.number.constructor");

var _dizhi = _interopRequireDefault(require("@/api/dizhi"));

//
//
//
//
//
//
var _default = {
  name: 'Shequ',
  props: {
    value: [String, Number, Object],
    jiedao: [String, Number, Object]
  },
  data: function data() {
    return {
      // 社区列表
      list: []
    };
  },
  computed: {
    selectVal: {
      get: function get() {
        if (this.value) {
          return this.value;
        } else {
          return null;
        }
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    'jiedao': function jiedao(val) {
      if (val) {
        this.getData(val.street);
      } else {
        this.value = '';
      }
    }
  },
  methods: {
    // 获取街道
    getData: function getData(val) {
      var _this = this;

      if (val) {
        _dizhi.default.shequList({
          streetName: val
        }).then(function (res) {
          var list = res.data;
          _this.list = list;

          if (!list.includes(_this.value)) {
            _this.selectVal = '';
          }
        });
      } else {
        this.list = [];
      }
    }
  }
};
exports.default = _default;