"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _dizhi = _interopRequireDefault(require("@/api/dizhi"));

var _waves = _interopRequireDefault(require("@/directive/waves"));

var _utils = require("@/utils");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// Waves directive
var map = null;
var polygon = null;
var _default = {
  name: 'Jiedao',
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    waves: _waves.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      id: null,
      geofence: '',
      form: {
        province: '',
        city: '',
        street: '',
        lan: 0,
        lon: 0,
        geofence: ''
      },
      temp: {
        province: '',
        city: '',
        street: '',
        lan: 0,
        lon: 0,
        geofence: ''
      },
      titles: '添加街道'
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList(street) {
      var _this = this;

      this.listLoading = true;
      var param = Object.assign({}, this.listQuery, street || {});

      _dizhi.default.streetList(param).then(function (res) {
        console.log(res);
        var response = res.data;
        _this.list = response; // this.total = response.data.totalRows

        _this.listLoading = false;
      });
    },
    updateDizhi: function updateDizhi(row, status) {// Service.updateChuyuStatus({
      //   'id': row.id,
      //   'status': status,
      //   'weixinId': row.userId
      // }).then(response => {
      //   this.$notify({
      //     title: '成功',
      //     message: '修改成功',
      //     type: 'success',
      //     duration: 2000
      //   })
      //   this.getList()
      // })
    },
    initMap: function initMap(lng, lat) {
      var self = this;
      var province = this.form.province;
      map = new AMap.Map('amapContainer', {
        center: [lng || 117.706347, lat || 39.023235],
        resizeEnable: true,
        zoom: 16
      });
      var toolBar = new AMap.ToolBar({
        position: {
          top: '110px',
          right: '40px'
        }
      });
      var controlBar = new AMap.ControlBar({
        position: {
          top: '10px',
          right: '10px'
        }
      });
      map.addControl(toolBar);
      map.addControl(controlBar);
      this.addTool();
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: province,
          input: 'pickerInput'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$notify({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.form.lan = positionResult.position.lat;
          self.form.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$notify({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        });

        if (lng && lat) {
          positionPicker.start([lng, lat]);
        } // 选取了某个POI


        poiPicker.on('poiPicked', function (poiResult) {
          self.form.lan = poiResult.item.location.lat;
          self.form.lon = poiResult.item.location.lng;
          map.setCenter(poiResult.item.location);
          positionPicker.start(poiResult.item.location);
        });
      }
    },
    add: function add() {
      var _this2 = this;

      this.titles = '添加街道';
      this.dialogVisible = true;
      this.form = Object.assign({}, this.temp);
      setTimeout(function () {
        _this2.initMap();
      }, 500);
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        title: '',
        // 标题
        type: 1,
        // 单项选择
        time: 10,
        // 答题时间
        answer: '',
        // 答题
        question: '' // question

      };
    },
    showUpdate: function showUpdate(row) {
      var _this3 = this;

      this.titles = '修改街道';
      this.dialogVisible = true;
      console.log(row);
      this.form = row;
      setTimeout(function () {
        _this3.initMap(row.lon, row.lan);

        _this3.setgeofence(row.geofence);
      }, 500);
    },
    save: function save() {
      var _this4 = this;

      console.log(this.form);
      var data = this.form;

      if (data.id) {
        _dizhi.default.updateStreet(data).then(function (res) {
          _this4.dialogVisible = false;

          _this4.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            duration: 2000
          });

          _this4.getList();
        });
      } else {
        _dizhi.default.addStreet(data).then(function (res) {
          if (res.data.error) {
            _this4.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this4.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success',
            duration: 2000
          });

          _this4.getList();

          _this4.dialogVisible = false;
        });
      }
    },
    del: function del(row) {
      var _this5 = this;

      console.log(row);
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _dizhi.default.delStreet(row.id).then(function (res) {
          if (res.data.body.data != 'ok') {
            _this5.$message({
              type: 'error',
              message: res.data.body.data
            });

            return false;
          }

          _this5.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this5.getList();
        });
      }).catch(function () {});
    },
    close: function close() {
      this.dialogVisible = false;
    },
    setgeofence: function setgeofence(geo) {
      if (!geo) return;
      var geofence = JSON.parse(decodeURIComponent(geo));
      var data = [];
      geofence.forEach(function (item) {
        if (item.lng && item.lat) {
          data.push([item.lng, item.lat]);
        }
      });

      if (data.length) {
        console.log(data);
        this.draw(data);
      }
    },
    draw: function draw(path) {
      polygon = new AMap.Polygon({
        path: path
      });
      this.polyEditor.setTarget(polygon);
      this.polyEditor.open();
      map.add([polygon]);
      map.setFitView();
    },
    addTool: function addTool() {
      var self = this;
      var polyEditor = this.polyEditor = new AMap.PolygonEditor(map);
      polyEditor.on('add', function (data) {
        polygon = data.target;
        polyEditor.addAdsorbPolygons(polygon);
        polygon.on('dblclick', function () {
          polyEditor.setTarget(polygon);
          polyEditor.open();
        });
        polygon.on('rightclick', function () {
          map.remove(polygon);
        });
      });
      polyEditor.on('end', function (data) {
        var polygon2 = data.target;

        if (polygon2) {
          self.geofence = polygon2.getPath();
          self.update();
        }
      });
    },
    update: function update() {
      var geofence = this.geofence;
      var regionId = this.id;
      var weilan = [];
      geofence.forEach(function (item) {
        weilan.push({
          lat: item.lat,
          lng: item.lng
        });
        console.log(item);
      });
      this.form.geofence = JSON.stringify(weilan);
    },
    start: function start() {
      this.polyEditor.close();
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.polyEditor.open();
    },
    closedraw: function closedraw() {
      this.polyEditor.close();
    },
    restart: function restart() {
      map.clearMap();
    }
  }
};
exports.default = _default;