import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ServiceAiPicTag from '@/api/aipic';
import waves from '@/directive/waves'; // Waves directive

export default {
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      dialogAddOrUpdateFormVisible: false,
      // 添加或编辑标签
      formLabelWidth: '160px',
      form: {
        id: "",
        label: "",
        // 标签
        level: "",
        // 级次
        parentId: "" // 父id

      },
      list: [],
      // 标签数据集
      rules: {
        label: [{
          required: true,
          message: "标签名称是必填项"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      ServiceAiPicTag.getAiPicTags().then(function (res) {
        //console.log(res);
        _this.list = res.data.data;
      });
    },
    addParentAiPicTag: function addParentAiPicTag() {
      this.form.id = 0;
      this.form.label = '';
      this.form.level = 1;
      this.form.parentId = 0;
      this.dialogAddOrUpdateFormVisible = true;
    },
    addSonAiPicTag: function addSonAiPicTag(row) {
      this.form.id = 0;
      this.form.label = "";
      this.form.level = Number(row.level) + 1;
      this.form.parentId = row.id;
      this.dialogAddOrUpdateFormVisible = true;
    },
    editAiPicTag: function editAiPicTag(row) {
      console.log(row);
      this.form.id = row.id;
      this.form.label = row.label;
      this.form.level = row.level;
      this.form.parentId = row.parentId;
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this2.form.id == 0) {
            // 添加
            ServiceAiPicTag.insertAiPicTag(_this2.form).then(function (res) {
              if (res.data.statusCode != 200) {
                _this2.$message({
                  message: "添加失败",
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '添加成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          } else {
            // 修改
            ServiceAiPicTag.updateAiPicTag(_this2.form).then(function (res) {
              if (res.data.statusCode != 200) {
                _this2.$message({
                  message: "修改失败",
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '修改成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          }
        }
      });
    },
    delAiPicTag: function delAiPicTag(row) {
      var _this3 = this;

      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        ServiceAiPicTag.deleteAiPicTagById(row.id).then(function (res) {
          if (res.data.statusCode != 200) {
            _this3.$message({
              message: "删除失败",
              type: 'error'
            });

            return false;
          } else {
            _this3.$message({
              type: 'success',
              message: '删除成功!'
            });
          }

          _this3.getList();
        });
      }).catch(function () {});
    }
  }
};