var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("el-date-picker", {
              attrs: {
                type: "month",
                "value-format": "yyyy-MM",
                placeholder: "选择月",
              },
              model: {
                value: _vm.listQuery.workDate,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "workDate", $$v)
                },
                expression: "listQuery.workDate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "text-align": "center",
              "font-size": "20px",
              "font-weight": "bold",
              "padding-top": "50px",
            },
          },
          [
            _vm.data.street
              ? _c("div", { staticStyle: { display: "inline-block" } }, [
                  _vm._v(_vm._s(_vm.data.street) + " -  "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.community
              ? _c("div", { staticStyle: { display: "inline-block" } }, [
                  _vm._v(_vm._s(_vm.data.community) + " -  "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.region
              ? _c("div", { staticStyle: { display: "inline-block" } }, [
                  _vm._v(_vm._s(_vm.data.region) + " -  "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.name
              ? _c("div", { staticStyle: { display: "inline-block" } }, [
                  _vm._v(_vm._s(_vm.data.name)),
                ])
              : _vm._e(),
            _vm._v("督导员打卡记录\n\t\t"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: {
            width: "95%",
            margin: "20px auto 0",
            "font-size": "14px",
          },
          attrs: { data: _vm.tableData, stripe: "", border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "位置", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.address, function (item, index) {
                    return _c("p", [_vm._v(_vm._s(item))])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打卡设备定位", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.deviceName, function (item, index) {
                    return _c("p", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(item) + "打卡位"),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工作时间", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._l(scope.row.workTime, function (item, index) {
                      return _c("p", { staticClass: "link-type" }, [
                        _vm._v(_vm._s(item)),
                      ])
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v("【" + _vm._s(scope.row.time) + "h】"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "日期" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", { staticClass: "text-center" }, [
                      _vm._v(_vm._s(scope.row.workDate)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "次数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("p", { staticClass: "link-type text-center" }, [
                      _vm._v(_vm._s(scope.row.num)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        style: {
                          color: scope.row.state == "正常" ? "green" : "red",
                        },
                      },
                      [_vm._v(_vm._s(scope.row.state))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备码", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.mbId, function (item, index) {
                    return _c("p", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(item)),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "打卡图片", width: "350" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.pic, function (item, index) {
                    return _c("img", {
                      staticStyle: {
                        width: "60px",
                        height: "60px",
                        margin: "10px",
                      },
                      attrs: { src: item },
                      on: {
                        click: function ($event) {
                          return _vm.imgs(item)
                        },
                      },
                    })
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.remark, function (item, index) {
                    return _c("p", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(item)),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.beizhus(scope.row.workDate, scope.row)
                          },
                        },
                      },
                      [_vm._v(" 备注\n\t\t\t\t")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0 && _vm.radio1 == 2,
            expression: "total>0 && radio1 == 2",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加备注", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.beizhudata } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.beizhudata.workDate,
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.beizhudata.fangshi,
                        callback: function ($$v) {
                          _vm.$set(_vm.beizhudata, "fangshi", $$v)
                        },
                        expression: "beizhudata.fangshi",
                      },
                    },
                    _vm._l(_vm.downlist, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "补时长", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "H.m",
                      format: "H:m",
                      placeholder: "任意时间点",
                    },
                    model: {
                      value: _vm.beizhudata.time,
                      callback: function ($$v) {
                        _vm.$set(_vm.beizhudata, "time", $$v)
                      },
                      expression: "beizhudata.time",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.beizhudata.remark,
                        expression: "beizhudata.remark",
                      },
                    ],
                    staticStyle: { width: "100%", "min-height": "200px" },
                    domProps: { value: _vm.beizhudata.remark },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.beizhudata, "remark", $event.target.value)
                      },
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v("提 交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "打卡图片", visible: _vm.dialogs },
          on: {
            "update:visible": function ($event) {
              _vm.dialogs = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: _vm.zhanshiimg, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }