"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.regexp.split");

var _typeof2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/typeof.js"));

var _defineProperty2 = _interopRequireDefault(require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/defineProperty.js"));

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _jiedao = _interopRequireDefault(require("@/components/street/jiedao"));

var _service = _interopRequireDefault(require("@/api/service"));

var _Editor = _interopRequireDefault(require("@/components/Editor"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    jiedao: _jiedao.default,
    Editor: _Editor.default
  },
  data: function data() {
    var _ref;

    return _ref = {
      dialogVisible: false,
      form: {
        id: '',
        street: '',
        rules: '',
        manual: '',
        number: "",
        timeSlot: "",
        coin: "",
        state: 0
      },
      rules: {}
    }, (0, _defineProperty2.default)(_ref, "dialogVisible", false), (0, _defineProperty2.default)(_ref, "list", null), (0, _defineProperty2.default)(_ref, "total", 0), (0, _defineProperty2.default)(_ref, "listLoading", true), (0, _defineProperty2.default)(_ref, "listQuery", {
      currentPage: 1,
      numOfPage: 20,
      street: ''
    }), (0, _defineProperty2.default)(_ref, "dismouthpiecetime", false), (0, _defineProperty2.default)(_ref, "worktimeforms", {
      // 设备投口添加时间prop
      begTime: "",
      endTime: ""
    }), (0, _defineProperty2.default)(_ref, "workTime", []), _ref;
  },
  watch: {
    "listQuery.street": function listQueryStreet(val) {
      if ((0, _typeof2.default)(val) == "object") {
        this.listQuery.street = val['street'];
      } else if (typeof val == "string") {
        this.listQuery.street = val;
      } else {
        this.listQuery.street = '';
      }
    },
    "form.street": function formStreet(val) {
      if ((0, _typeof2.default)(val) == "object") {
        this.form.street = val['street'];
      } else if (typeof val == "string") {
        this.form.street = val;
      } else {
        this.form.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    addWorkTimeDialog: function addWorkTimeDialog() {
      // 添加投口投递时间显示
      this.worktimeforms.begTime = "";
      this.worktimeforms.endTime = "";
      this.dismouthpiecetime = true;
    },
    addWorktimeConfirm: function addWorktimeConfirm() {
      // 添加工作时间
      if (this.worktimeforms.begTime == "" || this.worktimeforms.begTime == null) {
        this.$message({
          type: 'error',
          message: '开始时间不能不能为空!'
        });
        return;
      }

      if (this.worktimeforms.endTime == "" || this.worktimeforms.endTime == null) {
        this.$message({
          type: 'error',
          message: '结束时间不能不能为空!'
        });
        return;
      }

      if (this.worktimeforms.begTime >= this.worktimeforms.endTime) {
        this.$message({
          type: 'error',
          message: '开始时间不能大于等于结束时间!'
        });
        return;
      }

      this.workTime.push({
        "begTime": this.worktimeforms.begTime,
        "endTime": this.worktimeforms.endTime
      });
      this.dismouthpiecetime = false; // console.log(this.listMouthpiece);
    },
    delWorktimeConfirm: function delWorktimeConfirm(i) {
      // 删除工作时间
      this.workTime.splice(i, 1);
    },
    add: function add() {
      this.form.id = '';
      this.form.street = "";
      this.form.rules = "";
      this.form.manual = "";
      this.form.number = "";
      this.form.timeSlot = "";
      this.form.coin = "";
      this.form.state = 0;
      this.workTime = []; //console.log(this.form);

      this.dialogVisible = true;
    },
    edit: function edit(row) {
      this.workTime = [];
      this.form.id = row.id;
      this.form.street = row.street;
      this.form.rules = row.rules;
      this.form.manual = row.manual;
      this.form.number = row.number;
      this.form.timeSlot = row.timeSlot;
      this.form.coin = row.coin;
      this.form.state = row.state;
      this.dialogVisible = true;
      console.log(this.form); // 7:00-12:00,16:00-18:00

      var workTime = [];

      if (this.form.timeSlot) {
        var strTimes = this.form.timeSlot.split(",");

        for (var i = 0; i < strTimes.length; i++) {
          var begTime = strTimes[i].split('-')[0];
          var endTime = strTimes[i].split('-')[1];
          workTime.push({
            "begTime": begTime,
            "endTime": endTime
          });
        }
      }

      this.workTime = workTime;
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      console.log(this.listQuery);

      _service.default.getVoluntarysettingList(this.listQuery).then(function (res) {
        _this.list = res.data.data.list;
        _this.total = res.data.data.totalRows;
        _this.listLoading = false;
      });
    },
    save: function save() {
      var _this2 = this;

      if (!this.form.street) {
        this.$notify({
          title: '失败',
          message: '街道不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (this.workTime.length == 0) {
        this.$notify({
          title: '失败',
          message: '督导时间不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (!this.form.coin) {
        this.$notify({
          title: '失败',
          message: '督导奖励不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (this.form.coin && this.form.coin < 0) {
        this.$notify({
          title: '失败',
          message: '督导奖励填写有误',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (!this.form.number) {
        this.$notify({
          title: '失败',
          message: '招募报名人数不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      if (this.form.number && this.form.number < 0) {
        this.$notify({
          title: '失败',
          message: '招募报名人数填写有误',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      this.form.timeSlot = '';
      this.workTime.forEach(function (item) {
        _this2.form.timeSlot = _this2.form.timeSlot + item.begTime + "-" + item.endTime + ",";
      });
      this.form.timeSlot = this.form.timeSlot.slice(0, -1);

      if (this.form.id) {
        _service.default.updateVoluntarysetting(this.form).then(function (res) {
          if (res.data.statusCode == 200) {
            _this2.$notify({
              title: '成功',
              message: '操作成功',
              type: 'success',
              duration: 2000
            });

            _this2.dialogVisible = false;

            _this2.getList();
          } else {
            _this2.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });
          }
        });
      } else {
        _service.default.addVoluntarysetting(this.form).then(function (res) {
          if (res.data.statusCode == 200) {
            _this2.$notify({
              title: '成功',
              message: '操作成功',
              type: 'success',
              duration: 2000
            });

            _this2.dialogVisible = false;

            _this2.getList();
          } else {
            _this2.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    }
  }
};
exports.default = _default;