//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      // console.log(this.$store.state.tagsView.cachedViews)
      return this.$store.state.tagsView.cachedViews;
    },
    key: function key() {
      return this.$route.fullPath;
    }
  }
};