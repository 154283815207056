import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import qs from 'qs';
import Service from '@/api/device';
import dizhiservice from '@/api/service';
import waves from '@/directive/waves'; // Waves directive

import jiedao from '@/components/street/jiedao';
import Pagination from '@/components/Pagination';
export default {
  name: 'Shangbao',
  components: {
    Pagination: Pagination,
    jiedao: jiedao
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      ids: '',
      dialogsd: false,
      streetList: [{
        text: '全部',
        type: 0
      }, {
        text: '未解决',
        type: 1
      }, {
        text: '已解决',
        type: 2
      }],
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        searchData: '',
        // mbId:'',
        street: '',
        currentPage: 1,
        numOfPage: 20,
        problemState: 0
      },
      id: null,
      geofence: '',
      street: null,
      form: {
        id: '',
        desc: ''
      },
      forms: {
        pic: []
      },
      temp: {
        id: '',
        desc: ''
      },
      dialog: false,
      imgs: '',
      imgsp: [],
      uploadPic: []
    };
  },
  watch: {
    street: function street(val) {
      if (val) {
        this.listQuery.street = val.street;
      } else {
        this.listQuery.street = '';
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    uploadImg: function uploadImg(response, file, fileList) {
      fileList = [];

      if (!this.forms.pic.includes(response.key)) {
        this.forms.pic.push(response.key);
      }

      this.uploadPic = this.forms.pic;
      this.$refs.upload.clearFiles();
    },
    handleRemove: function handleRemove(item) {
      var _this = this;

      var index = this.uploadPic.indexOf(item);
      this.$confirm('删除图片, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (index > -1) {
          _this.uploadPic.splice(index, 1);
        }
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(e) {
      var _this2 = this;

      console.log(e);
      this.imgsp = [];
      e.forEach(function (item, index) {
        _this2.imgsp.push(item.id);
      });
    },
    exportPic: function exportPic(e) {
      console.log(this.imgsp);
      location.href = '/inspect/exportPic?ids=' + this.imgsp;
    },
    exportList: function exportList() {
      dizhiservice.exportLists(this.listQuery).then(function (res) {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob);
        } else {
          var elink = document.createElement('a');
          elink.download = '报表.xls';
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          document.body.removeChild(elink);
        }
      });
    },
    getList: function getList() {
      var _this3 = this;

      this.listLoading = true;
      Service.getProblemReport(this.listQuery).then(function (res) {
        var response = res.data;
        _this3.list = response.data.list;
        _this3.total = response.data.totalRows;
        _this3.listLoading = false;
      });
    },
    showUpdate: function showUpdate(row) {
      this.dialogVisible = true;
      this.form = {
        id: row.id,
        desc: row.problemDesc
      };
    },
    save: function save() {
      var _this4 = this;

      console.log(this.form);
      var data = this.form;

      if (data.id) {
        Service.editProblemDesc(data).then(function (res) {
          _this4.dialogVisible = false;

          _this4.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            duration: 2000
          });

          _this4.getList();
        });
      }
    },
    Surejiejue: function Surejiejue() {
      var _this5 = this;

      this.$confirm('确认此问题已解决？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        console.log(_this5.uploadPic[0]);
        Service.setproblemState({
          id: _this5.ids,
          pic: _this5.uploadPic[0],
          problemState: 2
        }).then(function (res) {
          _this5.$message({
            type: 'success',
            message: '已提交'
          });

          _this5.dialogsd = false;

          _this5.getList();
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    SureUpdate: function SureUpdate(e) {
      this.ids = e.id;
      this.dialogsd = true;
    },
    dakai: function dakai(e) {
      console.log(e);
      var arr = [];
      this.dialog = true;

      if (Array.isArray(e)) {
        this.imgs = e;
      } else {
        arr.push(e);
        this.imgs = arr;
      }
    },
    close: function close() {
      this.dialogVisible = false;
    }
  }
};