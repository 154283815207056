import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dizhiService from '@/api/dizhi';
import waves from '@/directive/waves'; // Waves directive

import Pagination from '@/components/Pagination';
import Street from '@/components/street/street';
import jiedao from '@/components/street/jiedao';
import shequ from '@/components/street/shequ';
import xiaoqu from '@/components/street/xiaoqu';
var map = null;
var polygon = null;
export default {
  name: 'Xiaoquguanli',
  components: {
    Pagination: Pagination,
    Street: Street,
    jiedao: jiedao,
    shequ: shequ,
    xiaoqu: xiaoqu
  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      dialogVisible: false,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        currentPage: 1,
        numOfPage: 20
      },
      id: null,
      geofence: '',
      street: null,
      // 存放街道
      town: null,
      form: {
        province: '天津市',
        city: '滨海新区',
        street: '',
        region: '',
        community: '',
        lat: 0,
        lon: 0,
        households: 0,
        // 户数
        lable: '',
        geofence: '[{"lat":39.024911,"lng":117.67622},{"lat":39.024433,"lng":117.676244},{"lat":39.024455,"lng":117.676866},{"lat":39.024477,"lng":117.676888}]' // 默认，易于以后查找

      },
      temp: {
        province: '天津市',
        city: '滨海新区',
        street: '',
        region: '',
        community: '',
        lat: 0,
        lon: 0,
        households: 0,
        // 户数
        lable: '',
        geofence: '[{"lat":39.024911,"lng":117.67622},{"lat":39.024433,"lng":117.676244},{"lat":39.024455,"lng":117.676866},{"lat":39.024477,"lng":117.676888}]'
      },
      info: {
        streetId: '',
        townId: '',
        property: '',
        households: '',
        name: '',
        phone: '',
        address: '',
        geofence: '',
        lable: 0
      },
      rules: {
        property: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }],
        housing: [{
          required: true,
          message: 'title is required',
          trigger: 'blur'
        }]
      },
      options: [{
        value: 2,
        label: '中心生态城'
      }, {
        value: 1,
        label: '其他街道'
      }],
      value: '',
      kong: '',
      shequ: '',
      jiedao: '',
      titles: '添加小区',
      regionNoTitle: '添加户号白名单',
      dialogRegionNoVisible: false,
      // 显示户号dialog
      regionNoForm: {
        type: "",
        buildingNumber: "",
        unitNumber: "1",
        // 默认1
        d3: "",
        d4: "",
        numberOfPlies: "",
        // 层数、层号
        homeNumber: "",
        region: "" // 小区

      },
      regionNoRules: {
        // 验证必填
        type: [{
          required: true,
          message: '类型必选',
          trigger: 'blur'
        }],
        buildingNumber: [{
          required: true,
          message: '楼号必填',
          trigger: 'blur'
        }],
        unitNumber: [{
          required: true,
          message: '单元必填',
          trigger: 'blur'
        }],
        d3: [{
          required: true,
          message: '层数必填',
          trigger: 'blur'
        }],
        d4: [{
          required: true,
          message: '层号必填',
          trigger: 'blur'
        }],
        homeNumber: [{
          required: true,
          message: '户号必填',
          trigger: 'blur'
        }]
      },
      regionNoList: [],
      //预览列表
      dialogViewRegionNoVisible: false,
      regionHomeNumberList: [],
      //小区户号列表
      dialogViewRegionHomeNumberVisible: false,
      buildingNumberList: [],
      whiteDetailForm: {
        region: "",
        buildingNumber: ""
      }
    };
  },
  watch: {
    street: function street(val) {
      if (val) {
        this.form.streetId = val.id;
        this.form.street = val.street;
        this.jiedao = val.street;
      }
    },
    town: function town(val) {
      if (val) {
        this.form.townId = val.id;
        this.form.community = val.name;
        this.shequ = val.name;
      }
    },
    "regionNoForm.type": function regionNoFormType(val) {
      if (val) {
        this.regionNoForm.type = val;
      }
    }
  },
  created: function created() {
    this.getList();
  },
  methods: {
    addrChange: function addrChange(data) {
      console.log(data);
      this.listQuery.community = data.community;
      this.listQuery.floor = data.floor;
      this.listQuery.region = data.region;
      this.listQuery.street = data.street;
      this.getList();
    },
    streetSelect: function streetSelect(e) {
      console.log(e);
    },
    communitySelect: function communitySelect(e) {
      console.log(e);
    },
    getList: function getList() {
      var _this = this;

      this.listLoading = true;
      dizhiService.xiaoquList(this.listQuery).then(function (res) {
        console.log(res);
        var response = res.data;
        _this.list = response.list;
        _this.total = response.totalRows;
        _this.listLoading = false;
      });
    },
    showInfoClick: function showInfoClick(e) {
      var self = this;
      map.clearMap(); //清理地图
      // console.log(self.form);

      var lng = e.lnglat.lng; //获取经度

      var lat = e.lnglat.lat; //获取纬度

      self.form.lat = lat;
      self.form.lon = lng;
      var marker = new AMap.Marker({
        position: [lng, lat] //offset: new AMap.Pixel(-13, -30)

      });
      map.add(marker); //加载点
    },
    initMap: function initMap(lon, lat) {
      var self = this;
      var city = this.form.city;
      map = new AMap.Map('amapContainer', {
        center: [117.706347, 39.023235],
        resizeEnable: true,
        zoom: 16
      });
      map.on("click", this.showInfoClick);
      var toolBar = new AMap.ToolBar({
        position: {
          top: '110px',
          right: '40px'
        }
      });
      var controlBar = new AMap.ControlBar({
        position: {
          top: '10px',
          right: '10px'
        }
      });
      map.addControl(toolBar);
      map.addControl(controlBar);
      this.addTool();
      AMapUI.loadUI(['misc/PoiPicker', 'misc/PositionPicker'], function (PoiPicker, PositionPicker) {
        var poiPicker = new PoiPicker({
          city: city,
          suggest: self.form.region,
          input: 'pickerInput'
        }); // 初始化poiPicker

        poiPickerReady(poiPicker, PositionPicker);
      });

      function poiPickerReady(poiPicker, PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMarker',
          map: map
        });
        positionPicker.on('success', function (positionResult) {
          self.$notify({
            title: '当前点',
            message: positionResult.nearestJunction,
            type: 'success',
            duration: 2000
          });
          self.form.lat = positionResult.position.lat;
          self.form.lon = positionResult.position.lng;
        });
        positionPicker.on('fail', function (positionResult) {
          self.$notify({
            title: '失败',
            message: '拖拽选取失败',
            type: 'fail',
            duration: 2000
          });
        });

        if (lon && lat) {
          positionPicker.start([lon, lat]);
        } // 选取了某个POI


        poiPicker.on('poiPicked', function (poiResult) {
          self.form.lat = poiResult.item.location.lat;
          self.form.lon = poiResult.item.location.lng;
          map.setCenter(poiResult.item.location);
          positionPicker.start(poiResult.item.location);
        });
      }
    },
    addRegion: function addRegion() {
      var _this2 = this;

      this.titles = '添加小区';
      this.dialogVisible = true;
      this.form = Object.assign({}, this.temp);
      this.kong = '';
      setTimeout(function () {
        _this2.initMap(117.706347, 39.023235);
      }, 500);
    },
    resetTemp: function resetTemp() {
      this.temp = {
        id: undefined,
        title: '',
        // 标题
        type: 1,
        // 单项选择
        time: 10,
        // 答题时间
        answer: '',
        // 答题
        question: '' // question

      };
      this.kong = '';
    },
    showUpdate: function showUpdate(row) {
      var _this3 = this;

      this.titles = '修改小区';
      this.dialogVisible = true;
      this.kong = '';
      console.log(row);
      this.form = row; // this.town = row.community
      // this.street = row.street

      setTimeout(function () {
        _this3.initMap(row.lon, row.lat); // this.setgeofence(row.geofence)// 删除围栏

      }, 500);
    },
    del: function del(row) {
      var _this4 = this;

      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        dizhiService.delxiaoqu(row.id).then(function (res) {
          if (res.data.body.data != 'ok') {
            _this4.$message({
              type: 'error',
              message: res.data.body.data
            });

            return false;
          }

          _this4.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this4.getList();
        });
      }).catch(function () {});
    },
    save: function save() {
      var _this5 = this;

      console.log(this.form);

      if (this.form.street == '泰达街道') {
        this.form.lable = 3;
      } else if (this.form.street == '中新生态城') {
        this.form.lable = 2;
      }

      if (this.form.geofence == '') {
        this.$notify({
          title: '失败',
          message: '围栏不能为空',
          type: 'error',
          duration: 2000
        });
        return false;
      }

      console.log(this.form);
      var data = this.form;

      if (data.id) {
        dizhiService.updatexiaoqu(data).then(function (res) {
          _this5.dialogVisible = false;

          _this5.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            duration: 2000
          });

          _this5.getList();
        });
      } else {
        data.street = this.jiedao;
        data.community = this.shequ;
        dizhiService.addxiaoqu(data).then(function (res) {
          if (res.data.error) {
            _this5.$notify({
              title: '失败',
              message: res.data.error,
              type: 'error',
              duration: 2000
            });

            return false;
          }

          _this5.dialogVisible = false;

          _this5.$notify({
            title: '成功',
            message: '新增成功',
            type: 'success',
            duration: 2000
          });

          _this5.getList();
        });
      }
    },
    close: function close() {
      this.dialogVisible = false;
    },
    setgeofence: function setgeofence(geo) {
      var geofence = JSON.parse(decodeURIComponent(geo));
      var data = [];
      geofence.forEach(function (item) {
        if (item.lng && item.lat) {
          data.push([item.lng, item.lat]);
        }
      });

      if (data.length) {
        console.log(data);
        this.draw(data);
      }
    },
    draw: function draw(path) {
      polygon = new AMap.Polygon({
        path: path
      });
      this.polyEditor.setTarget(polygon);
      this.polyEditor.open();
      map.add([polygon]);
      map.setFitView();
    },
    addTool: function addTool() {
      var self = this;
      var polyEditor = this.polyEditor = new AMap.PolygonEditor(map);
      polyEditor.on('add', function (data) {
        polygon = data.target;
        polyEditor.addAdsorbPolygons(polygon);
        polygon.on('dblclick', function () {
          polyEditor.setTarget(polygon);
          polyEditor.open();
        });
        polygon.on('rightclick', function () {
          map.remove(polygon);
        });
      });
      polyEditor.on('end', function (data) {
        var polygon2 = data.target;

        if (polygon2) {
          self.geofence = polygon2.getPath();
          self.update();
        }
      });
    },
    update: function update() {
      var geofence = this.geofence;
      var regionId = this.id;
      var weilan = [];
      geofence.forEach(function (item) {
        weilan.push({
          lat: item.lat,
          lng: item.lng
        });
        console.log(item);
      });
      this.form.geofence = JSON.stringify(weilan);
    },
    start: function start() {
      this.polyEditor.close();
      this.polyEditor.setTarget();
      this.polyEditor.open();
      this.polyEditor.open(); //map.off('click', this.showInfoClick);
    },
    closedraw: function closedraw() {
      this.polyEditor.close(); //map.on("click", this.showInfoClick);
    },
    restart: function restart() {
      map.clearMap(); //map.on('click', this.showInfoClick);
    },
    addRegionNoDialog: function addRegionNoDialog(row) {
      // 添加户号
      this.dialogRegionNoVisible = true;
      this.regionNoForm.region = row.region;
      this.regionNoForm.type = 0; // 默认值 标准楼层录入/非标准楼层录入(0/1)

      this.regionNoForm.buildingNumber = ""; // 楼号

      this.regionNoForm.unitNumber = "1"; // 单元号

      this.regionNoForm.d3 = ""; // 层数

      this.regionNoForm.d4 = ""; // 层号

      this.regionNoForm.homeNumber = ""; // 户号
    },
    addRegionNo: function addRegionNo() {
      var _this6 = this;

      // 添加
      var self = this;

      if (self.regionNoForm.type == '0') {
        self.regionNoForm.numberOfPlies = self.regionNoForm.d3;
      } else {
        self.regionNoForm.numberOfPlies = self.regionNoForm.d4;
      }

      self.$refs["regionNoForm"].validate(function (valid) {
        if (valid) {
          dizhiService.whiteInsert(self.regionNoForm).then(function (res) {
            console.log(res.data.error);

            if (res.data.error != null) {
              _this6.$message({
                message: res.data.error,
                type: "error",
                duration: 4500
              });
            } else {
              _this6.$message({
                message: "生成成功",
                type: "success",
                duration: 1500,
                onClose: function onClose() {//self.getList()
                }
              });
            }

            _this6.dialogRegionNoVisible = false;
          });
        }
      });
    },
    viewRegionNo: function viewRegionNo() {
      var _this7 = this;

      // 预览
      this.dialogViewRegionNoVisible = true;
      /* regionNoForm:{
        type:"",
        buildingNumber:"",
        unitNumber:"",
        d3:"",
        d4:"",
        numberOfPlies:""// 层数、层号
        homeNumber:"",
      },*/

      if (this.regionNoForm.type == '0') {
        this.regionNoForm.numberOfPlies = this.regionNoForm.d3;
      } else {
        this.regionNoForm.numberOfPlies = this.regionNoForm.d4;
      }

      dizhiService.whitePreview(this.regionNoForm).then(function (res) {
        var response = res.data.data;
        _this7.regionNoList = response;
        _this7.listLoading = false;
      });
    },
    // 单运号列表+白名单明细
    getWhiteRegionDetail: function getWhiteRegionDetail(row) {
      var _this8 = this;

      this.dialogViewRegionHomeNumberVisible = true;
      this.whiteDetailForm.buildingNumber = "";
      this.whiteDetailForm.region = row.region;
      this.getBuildingNumbers(row);
      setTimeout(function () {
        _this8.getRegionHomeNumbers();
      }, 500);
    },
    getRegionHomeNumbers: function getRegionHomeNumbers() {
      var _this9 = this;

      // 白名单列表
      dizhiService.getRegionHomeNumbers(this.whiteDetailForm).then(function (res) {
        var response = res.data.data;
        _this9.regionHomeNumberList = response;
        _this9.listLoading = false;
      });
    },
    getBuildingNumbers: function getBuildingNumbers(row) {
      var _this10 = this;

      // 下拉单元号
      dizhiService.getBuildingNumbers(row.region).then(function (res) {
        var response = res.data.data;
        _this10.buildingNumberList = response;

        if (response.length != 0) {
          _this10.whiteDetailForm.buildingNumber = response[0].buildingNumber;
        }
      });
    },
    delById: function delById(row) {
      var _this11 = this;

      // 通过id删除楼号
      console.log(row);
      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        dizhiService.deleteRegionBuildingUnitById(row.id).then(function (res) {
          if (res.data.statusCode != 200) {
            _this11.$message({
              type: 'error',
              message: res.data.data
            });

            return false;
          }

          _this11.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this11.getRegionHomeNumbers();
        });
      }).catch(function () {});
    }
  }
};