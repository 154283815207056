import request from '@/utils/request'; // 

var Service = {
  getList: function getList(params) {
    // 行动站-列表
    return request({
      url: '/td/device/backend/manage/xdz/list',
      method: 'get',
      params: params
    });
  },
  xdxManageDelete: function xdxManageDelete(params) {
    // 行动站-删除
    return request({
      url: '/td/device/backend/manage/xdz/delete?uuid=' + params,
      method: 'get'
    });
  },
  xdzManageAdd: function xdzManageAdd(params) {
    // 行动站-添加
    return request({
      url: '/td/device/backend/manage/xdz/add',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    });
  },
  xdzManageUpdate: function xdzManageUpdate(params) {
    // 行动站-修改
    return request({
      url: '/td/device/backend/manage/xdz/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    });
  },
  xdzManageInformation: function xdzManageInformation(params) {
    // 行动站列表-不分页
    return request({
      url: '/td/device/backend/manage/xdz/information',
      method: 'get',
      params: params
    });
  },
  gpManageList: function gpManageList(params) {
    // 设备管理-列表
    return request({
      url: '/td/device/backend/manage/gp/list',
      method: 'get',
      params: params
    });
  },
  gpManageDelete: function gpManageDelete(params) {
    // 设备管理-删除
    return request({
      url: '/td/device/backend/manage/gp/delete?mbId=' + params,
      method: 'get'
    });
  },
  gpManageUpdate: function gpManageUpdate(params) {
    // 设备管理-修改
    return request({
      url: '/td/device/backend/manage/gp/update',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(params)
    });
  },
  xdzManageGp: function xdzManageGp(params) {
    // 行动站-标牌管理-添加标牌(标牌绑定)
    return request({
      url: '/td/device/backend/manage/xdz/gp',
      method: 'post',
      params: params
    });
  },
  xdzManageGpList: function xdzManageGpList(params) {
    // 行动站-标牌管理-列表
    return request({
      url: '/td/device/backend/manage/xdz/gp/list',
      method: 'get',
      params: params
    });
  },
  xdzManageGpDelete: function xdzManageGpDelete(params) {
    // 行动站-标牌管理-删除标牌
    return request({
      url: '/td/device/backend/manage/xdz/gp/delete?gpCode=' + params,
      method: 'get'
    });
  },
  xdzManageGpUnbound: function xdzManageGpUnbound(params) {
    // 行动站-标牌管理-解绑
    return request({
      url: '/td/device/backend/manage/xdz/gp/unbound?gpCode=' + params,
      method: 'get'
    });
  },
  xdzManageGpUsageRecord: function xdzManageGpUsageRecord(params) {
    // 行动站--标牌管理-标牌使用记录
    return request({
      url: '/td/device/backend/manage/xdz/gp/usage_record',
      method: 'get',
      params: params
    });
  }
};
export default Service;