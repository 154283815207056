import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/service';
import Pagination from '@/components/Pagination';
import addrselect from '@/components/street/addrselect';
export default {
  name: 'Zhongduanguanli',
  components: {
    Pagination: Pagination,
    addrselect: addrselect
  },
  data: function data() {
    return {
      locations: [],
      total: 0,
      list: null,
      listLoading: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      forms: {
        mbId: '',
        community: '',
        number: '',
        region: '',
        smjAddress: '',
        smjName: '',
        street: ''
      },
      time: '',
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        key: ''
      },
      bins: '',
      times: '',
      op: '',
      addr: {
        community: '',
        region: '',
        street: '',
        floor: ''
      },
      louhao: '',
      stree: ''
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    shoplist: function shoplist(e) {
      this.$router.push({
        path: '/device/shoplists',
        query: {
          ids: e.mbId,
          name: e.smjName
        }
      });
    },
    Sure: function Sure() {
      var _this = this;

      if (!this.forms.smjName) {
        this.$message({
          message: '设备名称不能为空',
          type: 'error'
        });
      } else if (!this.addr.street || !this.addr.community || !this.addr.region || !this.addr.floor) {
        this.$message({
          message: '设备地址不能为空',
          type: 'error'
        });
      } else {
        this.forms.street = this.addr.street;
        this.forms.community = this.addr.community;
        this.forms.region = this.addr.region;
        this.forms.number = this.addr.floor;
        this.forms.smjAddress = this.addr.street + ' - ' + this.addr.community + ' - ' + this.addr.region + ' - ' + this.addr.floor;
        setTimeout(function (res) {
          Service.exitduihuanji(_this.forms).then(function (res) {
            _this.dialogFormVisible = false;
            console.log(res);

            _this.$message({
              message: '修改成功',
              type: 'success'
            });

            _this.getList();
          });
        }, 500);
      }
    },
    toogleExpand: function toogleExpand(row) {
      this.getNList(row.id);
      var $table = this.$refs.table;
      this.list.map(function (item) {
        if (row.mbId != item.mbId) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },
    handleFilter: function handleFilter() {
      // this.community = ''
      // this.region = ''
      // this.street = ''
      // this.louhao = ''
      this.dialogFormVisible = true;
    },
    view: function view(row) {
      this.$router.push({
        name: 'chaoshishangbao',
        query: {
          mbId: row.mbId,
          name: row.deviceName
        }
      });
    },
    edit: function edit(e) {
      console.log(e);
      this.dialogFormVisible = true;
      this.forms.mbId = e.mbId;
      this.forms.smjName = e.smjName;

      if (e['smjAddress']) {
        console.log('起始');
        var addr = e['smjAddress'].split("-");
        console.log(addr);

        if (addr.length == 4) {
          console.log('可以');
          this.addr.street = addr[0];
          this.addr.community = addr[1];
          this.addr.region = addr[2];
          this.addr.floor = addr[3];
        }
      } else {
        this.addr.street = null;
        this.addr.community = null;
        this.addr.region = null;
        this.addr.floor = null;
      }
    },
    addrChange: function addrChange(data) {
      this.addr.community = data.community;
      this.addr.floor = data.floor;
      this.addr.region = data.region;
      this.addr.street = data.street;
    },
    getList: function getList() {
      var _this2 = this;

      this.listLoading = true;
      Service.duihuanjilist(this.listQuery).then(function (response) {
        _this2.list = response.data.data.list;
        _this2.total = response.data.data.totalRows; // Just to simulate the time of the request

        setTimeout(function () {
          _this2.listLoading = false;
        }, 1.5 * 1000);
      });
    },
    getNList: function getNList(id) {
      var _this3 = this;

      Service.getNbinsList(id).then(function (res) {
        console.log(res);
        _this3.locations = res.data;
      });
    },
    xuanze: function xuanze() {
      console.log(1);
    },
    routr: function routr(row) {
      var deviceGpsLng = row.deviceGpsLng;
      var deviceGpsLat = row.deviceGpsLat;
      this.$router.push({
        path: '/device/map',
        query: {
          deviceGpsLng: deviceGpsLng,
          deviceGpsLat: deviceGpsLat
        }
      });
    },
    Search: function Search() {
      this.getList();
    }
  }
};