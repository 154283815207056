var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("jiedao", {
            staticClass: "filter-item",
            model: {
              value: _vm.listStreet,
              callback: function ($$v) {
                _vm.listStreet = $$v
              },
              expression: "listStreet",
            },
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "姓名或电话模糊查询" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 查 询\n\t\t\t")]
          ),
          _vm._v(" "),
          _vm.hasPerm("huishouyuan:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.exportlist()
                    },
                  },
                },
                [_vm._v(" 导出打卡记录\n\t\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weixinId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: { src: scope.row.avatarUrl, width: "40", alt: "" },
                    }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "照片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "touxiang" },
                      [
                        _c("el-avatar", {
                          attrs: {
                            size: 60,
                            src: "http://pic.touduila.com/" + scope.row.pic,
                            shape: "square",
                          },
                        }),
                        _vm._v(" "),
                        scope.row.pic == "FqFLMiq_5hB0V5DIK3Y4mc42e4wU"
                          ? _c(
                              "el-upload",
                              {
                                ref: "upload",
                                attrs: {
                                  "show-file-list": false,
                                  "on-success": _vm.uploadImg(scope.row),
                                  "auto-upload": true,
                                  action: "/operator/tools/upload",
                                  name: "photo",
                                },
                              },
                              [_c("i", { staticClass: "el-icon-plus" })]
                            )
                          : _c("i", {
                              staticClass: "el-icon-delete",
                              on: {
                                click: function ($event) {
                                  return _vm.delImg(scope.row)
                                },
                              },
                            }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "督导小区", width: "100px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.showEdit(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(scope.row.street)),
                        _c("br"),
                        _vm._v("\n\t\t\t\t\t\t" + _vm._s(scope.row.communit)),
                        _c("br"),
                        _vm._v(_vm._s(scope.row.region)),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { type: "expand", label: "权限" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-form",
                      { attrs: { inline: "" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "数据上报权限" } },
                          [
                            !scope.row.binsRole
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shngbao(
                                          0,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.binsRole
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shngbao(
                                          1,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "派单权限" } },
                          [
                            scope.row.role == 10
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shngbaos(
                                          0,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.role == 100
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shngbaos(
                                          1,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "额外绑袋权限" } },
                          [
                            !scope.row.recyclerState
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.bangdai(
                                          0,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.recyclerState
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.bangdai(
                                          1,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "设备维护权限" } },
                          [
                            !scope.row.prosRole
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shebei(0, scope.row.weixinId)
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.prosRole
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.shebei(1, scope.row.weixinId)
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "成果上传权限" } },
                          [
                            !scope.row.resultsUpload
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.chenguo(
                                          0,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("开启")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.resultsUpload
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.chenguo(
                                          1,
                                          scope.row.weixinId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("关闭")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "督导员管理" } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { color: "#67C23A" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setting(scope.row)
                                  },
                                },
                              },
                              [_vm._v("设置")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        scope.row.recycleRole == 1
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "投对智能箱房管理" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "#67C23A" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deviceSetting(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("设置")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "巡检记录" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.dudaoxq(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看详情")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "本月打卡情况" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.abnormal))]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.dakaxq(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "本月工作时长" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.thisMonthTime) + "h"),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工作时间", width: "110" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.workTimes)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工作周报(本周/本月/本季度)", width: "800" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "baobiaowrap" },
                      [
                        _c("ul", { staticClass: "baobiao" }, [
                          _c("li", { staticStyle: { width: "45px" } }),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "45px" } }, [
                            _vm._v("周"),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "45px" } }, [
                            _vm._v("月"),
                          ]),
                          _vm._v(" "),
                          _c("li", { staticStyle: { width: "45px" } }, [
                            _vm._v("季度"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm._l(scope.row.list, function (item) {
                          return _c("ul", { staticClass: "baobiao" }, [
                            _c("li", [_vm._v(_vm._s(item.category))]),
                            _vm._v(" "),
                            _c("li", [_vm._v(_vm._s(item.thisWeek))]),
                            _vm._v(" "),
                            _c("li", [_vm._v(_vm._s(item.thisMonth))]),
                            _vm._v(" "),
                            _c("li", [_vm._v(_vm._s(item.thisQuarter))]),
                          ])
                        }),
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusFilter")(scope.row.status),
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("statusName")(scope.row.status)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              fixed: "right",
              align: "center",
              width: "150",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "margin-xs",
                        attrs: { size: "mini", type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情\n\t\t\t\t\t")]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.hasPerm("huishouyuan:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.showEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != 10 && _vm.hasPerm("huishouyuan:pass")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.tongguo(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 通过\n\t\t\t\t\t")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != -1 && _vm.hasPerm("huishouyuan:pass")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.jujue(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 拒绝\n\t\t\t\t\t")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogPvVisible, title: "回收员详情" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPvVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20, align: "center" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("姓名： " + _vm._s(_vm.detailData.name)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("电话： " + _vm._s(_vm.detailData.phone)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("身份证： " + _vm._s(_vm.detailData.identity)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("籍贯： " + _vm._s(_vm.detailData.home)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("住址： " + _vm._s(_vm.detailData.apartment)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.detailData.weixinUser
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "姓名： " + _vm._s(_vm.detailData.weixinUser.nickName)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.detailData.weixinUser.avatarUrl,
                          width: "30",
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "国家： " + _vm._s(_vm.detailData.weixinUser.country)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "省： " + _vm._s(_vm.detailData.weixinUser.province)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("市： " + _vm._s(_vm.detailData.weixinUser.city)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "openId： " + _vm._s(_vm.detailData.weixinUser.openId)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "unionId： " + _vm._s(_vm.detailData.weixinUser.unionId)
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.areas
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                _vm._l(_vm.detailData.areas, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("社区：" + _vm._s(item.areaInfo)),
                    ]),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.tools
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                _vm._l(_vm.detailData.tools, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("工具： " + _vm._s(item.toolInfo)),
                    ]),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateComment },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.editVisible, title: "编辑地址信息" },
          on: {
            "update:visible": function ($event) {
              _vm.editVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editForm",
              attrs: { model: _vm.streetForm, "label-position": "left" },
            },
            [
              _c("addrselect", {
                attrs: {
                  street: _vm.streetForm.street,
                  community: _vm.streetForm.communit,
                  region: _vm.streetForm.region,
                  showFloor: false,
                },
                on: { addrChange: _vm.addrChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "1", border: "" },
                  model: {
                    value: _vm.radio1,
                    callback: function ($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1",
                  },
                },
                [_vm._v("管理员")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "2", border: "" },
                  model: {
                    value: _vm.radio1,
                    callback: function ($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1",
                  },
                },
                [_vm._v("督导员/回收员")]
              ),
              _vm._v(" "),
              _c(
                "el-radio",
                {
                  attrs: { label: "3", border: "" },
                  model: {
                    value: _vm.radio1,
                    callback: function ($$v) {
                      _vm.radio1 = $$v
                    },
                    expression: "radio1",
                  },
                },
                [_vm._v("商户")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateAddr } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.beizhuVisible, title: "导出打卡记录" },
          on: {
            "update:visible": function ($event) {
              _vm.beizhuVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请选择统计时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.agreeChange },
                      model: {
                        value: _vm.radioTreaty,
                        callback: function ($$v) {
                          _vm.radioTreaty = $$v
                        },
                        expression: "radioTreaty",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [
                        _vm._v("日汇总统计"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [
                        _vm._v("月汇总统计"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.radioTreaty == 1
                ? _c("el-form-item", { attrs: { label: "请选择统计时间" } }, [
                    _c(
                      "div",
                      { staticClass: "filter-item" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            "picker-options": _vm.pickerOptions,
                            type: "daterange",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            align: "right",
                          },
                          model: {
                            value: _vm.time,
                            callback: function ($$v) {
                              _vm.time = $$v
                            },
                            expression: "time",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.radioTreaty == 2
                ? _c("el-form-item", { attrs: { label: "请选择统计时间" } }, [
                    _c(
                      "div",
                      { staticClass: "filter-item" },
                      [
                        _c("el-date-picker", {
                          attrs: { type: "month", placeholder: "选择月份" },
                          model: {
                            value: _vm.times,
                            callback: function ($$v) {
                              _vm.times = $$v
                            },
                            expression: "times",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "请选择督导员所属街道:" } },
                [
                  _c("jiedao", {
                    staticClass: "filter-item",
                    model: {
                      value: _vm.searchStreet,
                      callback: function ($$v) {
                        _vm.searchStreet = $$v
                      },
                      expression: "searchStreet",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.beizhuVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sures } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "督导员管理权限设置",
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { rules: _vm.rules, model: _vm.listQuery },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "街道权限" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isIndeterminate },
                      on: { change: _vm.handleCheckAllChange },
                      model: {
                        value: _vm.checkAll,
                        callback: function ($$v) {
                          _vm.checkAll = $$v
                        },
                        expression: "checkAll",
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.jiedaohao,
                        callback: function ($$v) {
                          _vm.jiedaohao = $$v
                        },
                        expression: "jiedaohao",
                      },
                    },
                    _vm._l(_vm.jiedaos, function (item) {
                      return _c("el-checkbox", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.juese)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "状态",
                    "label-width": _vm.formLabelWidth,
                    prop: "state",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.manageRole,
                        callback: function ($$v) {
                          _vm.manageRole = $$v
                        },
                        expression: "manageRole",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投对智能箱房管理权限设置",
            visible: _vm.dialogDeviceFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDeviceFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: { rules: _vm.rules, model: _vm.listQuery },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "街道权限" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { indeterminate: _vm.isDeviceIndeterminate },
                      on: { change: _vm.handleDeviceCheckAllChange },
                      model: {
                        value: _vm.deviceCheckAll,
                        callback: function ($$v) {
                          _vm.deviceCheckAll = $$v
                        },
                        expression: "deviceCheckAll",
                      },
                    },
                    [_vm._v("全选")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.deviceJiedaohao,
                        callback: function ($$v) {
                          _vm.deviceJiedaohao = $$v
                        },
                        expression: "deviceJiedaohao",
                      },
                    },
                    _vm._l(_vm.jiedaos, function (item) {
                      return _c("el-checkbox", { attrs: { label: item.id } }, [
                        _vm._v(_vm._s(item.juese)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.deviceManageRole,
                        callback: function ($$v) {
                          _vm.deviceManageRole = $$v
                        },
                        expression: "deviceManageRole",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogDeviceFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveDevice } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }