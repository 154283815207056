import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
import _defineProperty from "D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import ServiceAiPicDataset from '@/api/aipic';
import waves from '@/directive/waves'; // Waves directive

import { getToken } from "@/utils/auth";
import * as imageConversion from 'image-conversion'; //import vueSeamlessScroll from 'vue-seamless-scroll' 

export default {
  components: {
    Pagination: Pagination //vueSeamlessScroll

  },
  directives: {
    waves: waves
  },
  props: ['imageUrl', 'check'],
  data: function data() {
    var _ref;

    return _ref = {
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        label: "" // 查询条件

      },
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      dialogAddOrUpdateFormVisible: false
    }, _defineProperty(_ref, "formLabelWidth", '160px'), _defineProperty(_ref, "form", {
      id: "",
      label: "" // 数据集

    }), _defineProperty(_ref, "list", []), _defineProperty(_ref, "rules", {
      label: [{
        required: true,
        message: "数据集名称是必填项"
      }]
    }), _defineProperty(_ref, "dialogImportFormVisible", false), _defineProperty(_ref, "importPicForm", {
      // 导入图像素材form
      datasetId: "",
      label: ""
    }), _defineProperty(_ref, "AllLoading", []), _defineProperty(_ref, "oldImg", null), _defineProperty(_ref, "dialogImageUrl", ''), _defineProperty(_ref, "checkUpload", false), _defineProperty(_ref, "fileList", []), _defineProperty(_ref, "returnFileList", []), _defineProperty(_ref, "showFile", []), _defineProperty(_ref, "urlStr", []), _defineProperty(_ref, "editFile", []), _defineProperty(_ref, "dialogVisible", false), _defineProperty(_ref, "uploadFileUrl", "/aiPicDataset/upload/file"), _defineProperty(_ref, "headers", {
      Authorization: "Authorization" + getToken()
    }), _defineProperty(_ref, "dialogTagFormVisible", false), _defineProperty(_ref, "tagForm", {
      id: "",
      // 明细id
      datasetId: "",
      // 数据集id
      pic: "",
      // key
      url: "",
      // 访问的url
      tagId: "" // 标签id

    }), _defineProperty(_ref, "mainImgUrl", ''), _defineProperty(_ref, "imgUrlList", [
      /*{
      cnsId: "2611",
      url: "http://pic.touduila.com/FvLCrEHcWFF-3Gfmfkf6pG7RJwTr"},
      {
      cnsId: "2612",
      url: "http://pic.touduila.com/Ft4TLofdHyHru5JXkjV0VHbPMjQA"},*/
    ]), _defineProperty(_ref, "imgActiveIndex", 0), _defineProperty(_ref, "imgDistance", 0), _defineProperty(_ref, "allDistance", 0), _defineProperty(_ref, "items", [
      /*{ type: '', label: '标签一' },
      { type: 'success', label: '标签二' },
      { type: 'info', label: '标签三' },
      { type: 'danger', label: '标签四' },
      { type: 'warning', label: '标签五' }*/
    ]), _ref;
  },
  mounted: function mounted() {
    // 在mounted时候赋值，子组件只更新一次，后面重新选择后展示此组件的数据，不再更新
    this.oldImg = this.imageUrl;
    this.stringToImage(this.oldImg);
  },
  watch: {
    imageUrl: function imageUrl(val) {
      // // console.log('val')
      // // console.log(val)
      this.oldImg = val;
      this.stringToImage(this.oldImg);
    }
  },
  created: function created() {
    this.getList();
  },
  computed: {
    imgStyle: function imgStyle() {
      return {
        transform: "translate3d(".concat(this.imgDistance, "px, 0, 0)") // 计算移动的距离(x,y,z)

      };
    }
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      ServiceAiPicDataset.getAiPicDatasetListPage(this.listQuery).then(function (res) {
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    addAiPicDataset: function addAiPicDataset(row) {
      this.form.id = 0;
      this.form.label = "";
      this.dialogAddOrUpdateFormVisible = true;
    },
    editAiPicDataset: function editAiPicDataset(row) {
      console.log(row);
      this.form.id = row.id;
      this.form.label = row.label;
      this.dialogAddOrUpdateFormVisible = true;
    },
    sure: function sure() {
      var _this2 = this;

      this.$refs['dataForm'].validate(function (valid) {
        if (valid) {
          if (_this2.form.id == 0) {
            // 添加
            ServiceAiPicDataset.insertAiPicDataset(_this2.form).then(function (res) {
              if (res.data.statusCode != 200) {
                _this2.$message({
                  message: "添加失败",
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '添加成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          } else {
            // 修改
            ServiceAiPicDataset.updateAiPicDataset(_this2.form).then(function (res) {
              if (res.data.statusCode != 200) {
                _this2.$message({
                  message: "修改失败",
                  type: 'error'
                });
              } else {
                _this2.$message({
                  message: '修改成功',
                  type: 'success'
                });

                _this2.dialogAddOrUpdateFormVisible = false;

                _this2.getList();
              }
            });
          }
        }
      });
    },
    importAiPicDataset: function importAiPicDataset(row) {
      // 导入图像弹框
      // console.log(row);
      this.importPicForm.datasetId = row.id;
      this.importPicForm.label = row.label;
      this.fileList = [];
      this.returnFileList = [];
      this.showFile = [];
      this.urlStr = [];
      this.editFile = [];
      this.dialogImportFormVisible = true;
    },
    stringToImage: function stringToImage(imageString) {
      var _this3 = this;

      this.showFile = [];
      this.editFile = [];

      if (imageString != null && imageString != '' && imageString != undefined) {
        this.urlStr = imageString.split(",");
        this.urlStr.forEach(function (item) {
          var obj = new Object();
          var obj1 = new Object();
          obj1.url = item;

          if (item.indexOf("http") < 0) {
            item = process.env.VUE_APP_BASE_MINIO_API + item;
          }

          obj.url = item;

          _this3.showFile.push(obj);

          _this3.editFile.push(obj1);
        });
      }
    },
    arryToString: function arryToString(arry) {
      var imgString = "";

      if (arry.length > 0) {
        for (var i = 0; i < arry.length; i++) {
          if (i < arry.length - 1) {
            imgString += arry[i].url + ",";
          } else {
            imgString += arry[i].url;
          }
        }
      }

      return imgString;
    },
    // 点击查看图像
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleOnChange: function handleOnChange(file, fileList) {
      var _this4 = this;

      if (file.name.indexOf(',') !== -1) {
        this.msgError("上传的文件名称中不允许出现英文逗号！");
        this.handleRemoveThis(file);
        return false;
      } else {
        var isLt2M = file.size / 1024 / 1024 < 1.5;

        if (!isLt2M) {
          console.log(file); // return new Promise((resolve) => {
          // 压缩到600KB,这里的600就是要压缩的大小,可自定义

          imageConversion.compressAccurately(file.raw, 600).then(function (res) {
            file.raw = new window.File([res], file.name, {
              type: file.type
            });
            file.size = file.raw.size;

            _this4.fileList.push(file);
          }); // })
        } else {
          this.fileList.push(file);
        }
      }
    },
    // 文件上传
    submitUpload: function submitUpload() {
      var _this5 = this;

      var fileList = [];
      var _this$$refs$pictureUp = this.$refs.pictureUpload,
          uploadFiles = _this$$refs$pictureUp.uploadFiles,
          action = _this$$refs$pictureUp.action,
          data = _this$$refs$pictureUp.data; //console.log(this.$refs.pictureUpload);

      if (uploadFiles.length == 0) {
        this.msgError("请上传图片！");
        return;
      } // 全屏禁用开启


      this.AllLoading = this.$loading({
        lock: true,
        text: '图像上传中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // console.log(uploadFiles)
      // uploadFiles.forEach(file => {
      //   imageConversion.compressAccurately(file.raw, 600).then(res => {
      //     file.raw = new window.File([res], file.name, { type: file.type })
      //     file.size = file.raw.size
      //   });
      // })
      //console.log(uploadFiles);
      //console.log(data);
      //data.push({"datasetId":this.importPicForm.datasetId});
      //console.log(data);
      //console.log(action);
      //return;

      this.uploadFiles({
        uploadFiles: uploadFiles,
        data: data,
        action: action,
        success: function success(response) {
          var res = JSON.parse(response); // this.$refs.uploadFile.clearFiles();
          // console.log(res)

          _this5.msgSuccess(res.data.data);

          _this5.AllLoading.close();
          /*res.data.fileName.forEach(item => {
          	let obj = new Object();
          	let obj1 = new Object();
          	obj.url = process.env.VUE_APP_BASE_MINIO_API + item;
          	obj1.url = item;
          	this.editFile.push(obj1)
          	this.showFile.push(obj)
          	var imgString = this.arryToString(this.editFile)
          	this.$emit('returnUrl', imgString)
          })*/


          _this5.dialogImportFormVisible = false;

          _this5.getList();
        },
        error: function error(_error) {
          _this5.msgError("图像上传失败！");

          _this5.AllLoading.close();

          console.log('图像上传失败！', _error);
        }
      });
    },

    /**
     * 自定义上传文件
     * @param fileList 文件列表
     * @param data 上传时附带的额外参数
     * @param url 上传的URL地址
     * @param success 成功回调
     * @param error 失败回调
     */
    uploadFiles: function uploadFiles(_ref2) {
      var _uploadFiles = _ref2.uploadFiles,
          headers = _ref2.headers,
          data = _ref2.data,
          action = _ref2.action,
          success = _ref2.success,
          error = _ref2.error;
      var form = new FormData(); // 文件对象

      _uploadFiles.map(function (file) {
        return form.append("filedatas", file.raw);
      }); // 附件参数


      for (var key in data) {
        form.append(key, data[key]);
      }

      var xhr = new XMLHttpRequest(); // 异步请求

      xhr.open("post", action, true); // 设置请求头

      xhr.setRequestHeader("Authorization", getToken());

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status >= 200 && xhr.status < 300 || xhr.status == 304) {
            success && success(xhr.responseText);
          } else {
            error && error(xhr.status);
          }
        }
      };

      xhr.send(form);
    },
    // 移除图像
    handleRemoveThis: function handleRemoveThis(file) {
      // console.log(file)
      // 在控件中移除图像
      this.$refs.pictureUpload.handleRemove(file); // 获取图像在数组中的位置

      var number = this.showFile.indexOf(file); // 获取返回的文件url中此文件的url

      var file = process.env.VUE_APP_BASE_MINIO_API + this.editFile[number].url.toString();
      var fileItemList = file.split('/'); // 执行文件删除

      /*delImg(fileItemList[4]).then(response => {
      	console.log(response)
      })*/

      this.editFile.splice(number, 1);
      this.showFile.splice(number, 1);
      var imgString = this.arryToString(this.editFile);
      this.$emit('returnUrl', imgString);
    },
    // 上传前
    beforUpload: function beforUpload(file) {
      var suffix = file.substring(file.lastIndexOf(".") + 1).toUpperCase();
      console.log(suffix);

      if (suffix != "JPG" && suffix != "PNG" && suffix != "JPEG" && suffix != "BMP") {
        this.msgError("上传的文件格式不正确，请上传正确的图像格式(JPG,PNG,JPEG,BMP)！");
        return false;
      }

      console.log("上传之前");
      console.log(file);

      if (file.name.indexOf(',') !== -1) {
        this.msgError("上传的文件名称中不允许出现英文逗号！");
        return false;
      } else {
        console.log("aaa"); // this.checkUpload = true

        this.fileList.push(file);
        var isLt2M = file.size / 1024 / 1024 < 1.5;

        if (!isLt2M) {
          return new Promise(function (resolve) {
            // 压缩到100KB,这里的100就是要压缩的大小,可自定义
            imageConversion.compressAccurately(file.raw, 600).then(function (res) {
              res = new File([res], file.name, {
                type: res.type,
                lastModified: Date.now()
              });
              resolve(res);
            });
          });
        }
      }
    },
    // 上传成功
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      // this.checkUpload = false
      // console.log("上传成功")
      // console.log(res)
      // console.log(fileList)
      console.log(res);
      console.log(this.$refs.pictureUpload.uploadFiles);
      this.$message.success("上传成功"); // fileList.forEach(item => {
      //   let obj = new Object();
      //   let obj1 = new Object();
      //   if (item.response !== undefined) {
      //     obj.url = process.env.VUE_APP_BASE_MINIO_API + item.response.fileName;
      //     obj1.url = item.response.fileName;
      //     this.editFile.push(obj1)
      //     this.showFile.push(obj)
      //     var imgString = this.arryToString(this.editFile)
      //     this.$emit('returnUrl', imgString)
      //   }
      // })

      var obj = new Object();
      var obj1 = new Object();
      obj.url = process.env.VUE_APP_BASE_MINIO_API + res.fileName;
      obj1.url = res.fileName;
      this.editFile.push(obj1);
      this.showFile.push(obj);
      var imgString = this.arryToString(this.editFile);
      this.$emit('returnUrl', imgString);
    },
    handleUploadError: function handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败"
      });
      this.loading.close();
    },
    msgError: function msgError(msg) {
      this.$message({
        message: msg,
        type: 'error'
      });
    },
    msgSuccess: function msgSuccess(msg) {
      this.$message({
        message: msg,
        type: 'success'
      });
    },
    addAiPicTagView: function addAiPicTagView(row) {
      var _this6 = this;

      // 添加标注弹框
      this.tagForm.id = ""; // this.tagForm.datasetId = "";

      this.tagForm.pic = "";
      this.tagForm.url = "";
      this.tagForm.datasetId = row.id;
      this.imgUrlList = [];
      this.mainImgUrl = ""; //主页图片
      // 加载数据集列表

      ServiceAiPicDataset.getAiPicDatasetDetailList(this.tagForm.datasetId).then(function (res) {
        console.log(res.data.data);
        res.data.data.forEach(function (item, index) {
          _this6.imgUrlList.push(item);
        });
      }); // 加载标签集

      ServiceAiPicDataset.getAiPicTags().then(function (res) {
        console.log(res.data.data);
        _this6.items = res.data.data;
      });
      this.dialogTagFormVisible = true;
    },
    // 图像浏览标注
    changeImg: function changeImg(item, index) {
      this.mainImgUrl = item.url;
      this.imgActiveIndex = index;
      /*tagForm:{
      	id:"",// 明细id
      	datasetId:"",// 数据集id
      	pic:"",// key
      	url:"",// 访问的url
      	tagId:"",// 标签id
         },*/

      this.copyTagForm(item);
      this.resetImg();
    },
    resetImg: function resetImg() {
      this.$refs.imgDiv.style.zoom = '100%';
      var left = document.querySelector('.left');
      var img = document.querySelector('.img');
      img.style.left = 0 + 'px';
      img.style.top = 0 + 'px';
    },
    imgLeft: function imgLeft() {
      var _this7 = this;

      if (this.imgActiveIndex > 0) {
        this.imgActiveIndex--; // 索引值-1

        this.imgUrlList.forEach(function (item, index) {
          // 循环小图,通过判断索引值赋值给大图
          if (_this7.imgActiveIndex === index) {
            _this7.mainImgUrl = item.url;

            _this7.copyTagForm(item);
          }
        });
      }

      if (this.imgActiveIndex >= 4) {
        var index = 0;
        var temp = window.setInterval(function () {
          // 利用定时器实现图像左右移动的动画效果
          if (index < 48) {
            // 移动次数(48次)
            _this7.imgDistance += 2; // 每次向左移动的距离 (移动总距离为48*this.imgDistance)

            index++;
            return;
          } else {
            window.clearInterval(temp); // 移动完清除定时器
          }
        }, 10);
      }

      this.resetImg();
    },
    imgRight: function imgRight() {
      var _this8 = this;

      if (this.imgActiveIndex < this.imgUrlList.length - 1) {
        this.imgActiveIndex++;
        this.imgUrlList.forEach(function (item, index) {
          if (_this8.imgActiveIndex === index) {
            _this8.mainImgUrl = item.url;

            _this8.copyTagForm(item);
          }
        });

        if (this.imgActiveIndex >= 5) {
          this.allDistance = -96 * (this.imgActiveIndex - 4);
          var index = 0;
          var temp = window.setInterval(function () {
            if (index < 48) {
              _this8.imgDistance -= 2; // 每次向右移动的距离

              index++;
              return;
            } else {
              window.clearInterval(temp);
            }
          }, 10);
        }
      } else if (this.imgActiveIndex === this.imgUrlList.length - 1) {
        // 到达最后一张图像，再点击跳转回第一张
        this.imgActiveIndex = 0;
        this.mainImgUrl = this.imgUrlList[0].url;
        var item = {};
        item.id = this.imgUrlList[0].id;
        item.datasetId = this.imgUrlList[0].datasetId;
        item.pic = this.imgUrlList[0].pic;
        item.url = this.imgUrlList[0].url;
        this.copyTagForm(item);
        var index = 0;

        var _temp = window.setInterval(function () {
          // 利用定时器实现图像左右移动的动画效果
          if (index < Math.abs(_this8.allDistance / 2)) {
            // 取绝对值再除
            _this8.imgDistance += 2; // 每次向左移动的距离 (移动总距离为48*this.imgDistance)

            index++;
            return;
          } else {
            window.clearInterval(_temp); // 移动完清除定时器
          }
        }, 1);
      }

      this.resetImg();
    },
    // 滚轮滑动
    gunlun: function gunlun(event) {
      //let direction = e.deltaY > 0 ? 'down' : 'up'
      //if (direction === 'up') {
      // 滑轮向上滚动
      //} else {
      // 滑轮向下滚动
      //}

      /* 获取当前页面的缩放比 若未设置zoom缩放比，则为默认100%，即1，原图大小 */
      var zoom = parseInt(this.$refs.imgDiv.style.zoom) || 100;
      /* event.wheelDelta 获取滚轮滚动值并将滚动值叠加给缩放比zoom wheelDelta统一为±120，其中正数表示为向上滚动，负数表示向下滚动 */

      zoom += event.wheelDelta / 12;
      /* 最小范围 和 最大范围 的图像缩放尺度 */

      if (zoom >= 80 && zoom < 500) {
        this.$refs.imgDiv.style.zoom = zoom + "%";
      }

      return false;
    },
    // 拖动图像
    dropImage: function dropImage(e) {
      e.preventDefault(); // 获取元素

      var left = document.querySelector('.left');
      var img = document.querySelector('.img');
      var x = e.pageX - img.offsetLeft;
      var y = e.pageY - img.offsetTop; // 添加鼠标移动事件

      left.addEventListener('mousemove', move);

      function move(e) {
        img.style.left = e.pageX - x + 'px';
        img.style.top = e.pageY - y + 'px';
      } // 添加鼠标抬起事件，鼠标抬起，将事件移除


      img.addEventListener('mouseup', function () {
        left.removeEventListener('mousemove', move);
      }); // 鼠标离开父级元素，把事件移除

      left.addEventListener('mouseout', function () {
        left.removeEventListener('mousemove', move);
      });
    },
    saveTag: function saveTag(item) {
      var _this9 = this;

      //console.log(item);

      /*tagForm:{
      	id:"",// 明细id
      	datasetId:"",// 数据集id
      	pic:"",// key
      	url:"",// 访问的url
      	tagId:"",// 标签id
         },*/
      this.tagForm.tagId = item.id; // console.log(this.tagForm);

      if (this.tagForm.id == '' || this.tagForm.id == null) {
        this.msgError("请选择图像！");
        return false;
      }

      ServiceAiPicDataset.updatePicTag(this.tagForm).then(function (res) {
        if (res.data.statusCode != 200) {
          _this9.$message({
            message: "添加失败",
            type: 'error'
          });
        } else {
          _this9.$message({
            message: '修改成功',
            type: 'success'
          });
        }

        ServiceAiPicDataset.getAiPicDatasetDetailList(_this9.tagForm.datasetId).then(function (res) {
          console.log(res.data.data);
          _this9.imgUrlList = [];
          res.data.data.forEach(function (item, index) {
            _this9.imgUrlList.push(item);
          });
        });
      });
    },
    copyTagForm: function copyTagForm(item) {
      this.tagForm.id = item.id;
      this.tagForm.datasetId = item.datasetId;
      this.tagForm.pic = item.pic;
      this.tagForm.url = item.url;

      if (item.tagId != 0 && item.tagId != '') {
        this.tagForm.tagId = item.tagId;
      } else {
        this.tagForm.tagId = "";
      }

      console.log(this.tagForm);
    }
  }
};