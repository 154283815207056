"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = checkPermission;
exports.hasBtnPermission = hasBtnPermission;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _store = _interopRequireDefault(require("@/store"));

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = _store.default.getters && _store.default.getters.roles;
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });

    if (!hasPermission) {
      return false;
    }

    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}

function hasBtnPermission(permission) {
  var btns = _store.default.getters.buttons; //console.log(btns);

  if (btns == undefined) {
    return false;
  } // return true;


  return btns.indexOf(permission) > -1;
}