var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              attrs: { placeholder: "" },
              on: {
                change: function ($event) {
                  return _vm.getList()
                },
              },
              model: {
                value: _vm.listQuery.status,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "status", $$v)
                },
                expression: "listQuery.status",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: {
                click: function ($event) {
                  return _vm.getList()
                },
              },
            },
            [_vm._v(" 查 询  ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.id"),
              prop: "id",
              sortable: "custom",
              align: "center",
              width: "65",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.weixinId))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "头像" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.weixinUser
                      ? _c("img", {
                          attrs: {
                            src: scope.row.weixinUser["avatarUrl"],
                            width: "40",
                            alt: "",
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "电话" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.phone)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单数量" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.orderCount)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单列表" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.apartment)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "负责区域", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.areas, function (item) {
                    return _c("el-tag", { key: item.id }, [
                      _vm._v(_vm._s(item.areaInfo)),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "工具", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.tools, function (item) {
                    return _c("el-tag", { key: item.id }, [
                      _vm._v(_vm._s(item.toolInfo)),
                    ])
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "创建时间", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.createTime)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.auditComment)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", "class-name": "status-col", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("statusFilter")(scope.row.status),
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("statusName")(scope.row.status)))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.handleDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(" 详情 ")]
                    ),
                    _vm._v(" "),
                    scope.row.status != 10 &&
                    _vm.hasPerm("xiaoquhuishouyuan:pass")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "success" },
                            on: {
                              click: function ($event) {
                                return _vm.tongguo(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 通过 ")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status != -1 &&
                    _vm.hasPerm("xiaoquhuishouyuan:pass")
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.jujue(scope.row)
                              },
                            },
                          },
                          [_vm._v(" 拒绝 ")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogPvVisible, title: "回收员详情" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPvVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20, align: "center" } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("姓名： " + _vm._s(_vm.detailData.name)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("电话： " + _vm._s(_vm.detailData.phone)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("身份证： " + _vm._s(_vm.detailData.identity)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("籍贯： " + _vm._s(_vm.detailData.home)),
                ]),
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 24 } }, [
                _c("div", { staticClass: "grid-content" }, [
                  _vm._v("住址： " + _vm._s(_vm.detailData.apartment)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.detailData.weixinUser
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                [
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "姓名：  " + _vm._s(_vm.detailData.weixinUser.nickName)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("img", {
                        attrs: {
                          src: _vm.detailData.weixinUser.avatarUrl,
                          width: "30",
                          alt: "",
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "国家：  " + _vm._s(_vm.detailData.weixinUser.country)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "省：  " + _vm._s(_vm.detailData.weixinUser.province)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("市：  " + _vm._s(_vm.detailData.weixinUser.city)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "openId：  " + _vm._s(_vm.detailData.weixinUser.openId)
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v(
                        "unionId：  " +
                          _vm._s(_vm.detailData.weixinUser.unionId)
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.areas
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                _vm._l(_vm.detailData.areas, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("社区：" + _vm._s(item.areaInfo)),
                    ]),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.detailData.tools
            ? _c(
                "el-row",
                { attrs: { gutter: 20, align: "center" } },
                _vm._l(_vm.detailData.tools, function (item) {
                  return _c("el-col", { key: item.id, attrs: { span: 12 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm._v("工具：  " + _vm._s(item.toolInfo)),
                    ]),
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }