//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from '@/components/Pagination';
import ServiceAiPicCheck from '@/api/aipic';
import waves from '@/directive/waves'; // Waves directive

export default {
  components: {
    Pagination: Pagination //vueSeamlessScroll

  },
  directives: {
    waves: waves
  },
  data: function data() {
    return {
      options: [{
        value: '0',
        label: '未审核'
      }, {
        value: '1',
        label: '已审核'
      }],
      listQuery: {
        currentPage: 1,
        numOfPage: 20,
        checkStatus: "" // 查询条件

      },
      list: [],
      // 审核检查列表
      total: 0,
      formLabelWidth: '160px',
      listLoading: true,
      // 弹框
      dialogCheckFormVisible: false,
      form: {
        id: "",
        qiniuPic: "",
        datasetId: "",
        tagId: ""
      },
      datasetList: [],
      // 数据集列表
      items: [] // 标签列表

    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    handleFilter: function handleFilter() {
      this.getList();
    },
    getList: function getList() {
      var _this = this;

      ServiceAiPicCheck.getAiPicCheckListPage(this.listQuery).then(function (res) {
        console.log(res.data.rowsCount);
        _this.list = res.data.list;
        _this.total = res.data.rowsCount;
        _this.listLoading = false;
      });
    },
    delAiPicCheck: function delAiPicCheck(row) {
      var _this2 = this;

      this.$confirm('是否确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        ServiceAiPicCheck.deleteAiPicCheckById(row.id).then(function (res) {
          //console.log(res);
          if (res.data.statusCode != 200) {
            _this2.$message({
              message: "删除失败",
              type: 'error'
            });

            return false;
          } else {
            _this2.$message({
              type: 'success',
              message: '删除成功!'
            });
          }

          _this2.getList();
        });
      }).catch(function () {});
    },
    addAiPicCheckView: function addAiPicCheckView(row) {
      // 添加审核弹框
      this.getDatasetList();
      this.getTagList();
      this.form.datasetId = "";
      this.form.tagId = "";
      this.form.id = row.id;
      this.form.qiniuPic = row.qiniuPic;
      this.dialogCheckFormVisible = true;
    },
    // 数据集
    getDatasetList: function getDatasetList() {
      var _this3 = this;

      ServiceAiPicCheck.getDatasetList().then(function (res) {
        //console.log(res.data);
        _this3.datasetList = res.data.data;
      });
    },
    // 加载标签集
    getTagList: function getTagList() {
      var _this4 = this;

      ServiceAiPicCheck.getAiPicTags().then(function (res) {
        //console.log(res.data.data);
        _this4.items = res.data.data;
      });
    },
    datasetChange: function datasetChange(id) {
      // 数据集列表改变赋值
      this.form.datasetId = id;
    },
    tagChange: function tagChange(item) {
      // 标签赋值
      this.form.tagId = item.id;
    },
    // 审核
    check: function check() {
      var _this5 = this;

      //console.log(this.form);
      if (this.form.datasetId == '') {
        this.$message({
          message: "请选择数据集",
          type: 'error'
        });
        return false;
      }

      if (this.form.tagId == '') {
        this.$message({
          message: "请选择标签集",
          type: 'error'
        });
        return false;
      }

      ServiceAiPicCheck.updateAiPicCheck(this.form).then(function (res) {
        if (res.data.statusCode != 200) {
          _this5.$message({
            message: res.data.error,
            type: 'error'
          });
        } else {
          _this5.$message({
            message: res.data.data,
            type: 'success'
          });

          _this5.dialogCheckFormVisible = false;

          _this5.getList();
        }
      });
    }
  }
};