var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c(
      "div",
      {
        staticClass: "main-container",
        staticStyle: { "padding-bottom": "50px", margin: "0" },
      },
      [_c("app-main")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }