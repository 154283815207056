import request from '@/utils/request';
var Service = {
  getList: function getList(params) {
    // 加载页面
    return request({
      url: '/recyclingBinsScore/getByMbId?mbId=' + params,
      method: 'get'
    });
  },
  updateRecyclingBinsScore: function updateRecyclingBinsScore(params) {
    return request({
      url: '/recyclingBinsScore/updateRecyclingBinsScore',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  },
  updateRecyclingBinsScoreSettingBymbId: function updateRecyclingBinsScoreSettingBymbId(params) {
    return request({
      url: '/recyclingBinsScore/updateRecyclingBinsScoreSettingBymbId',
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      data: params
    });
  }
};
export default Service;