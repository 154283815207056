var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "span",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "1%", "margin-right": "0.5%" },
            },
            [_vm._v("设备类型")]
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "120px" },
              model: {
                value: _vm.listQuery.type,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "type", $$v)
                },
                expression: "listQuery.type",
              },
            },
            _vm._l(_vm.Options, function (item) {
              return _c("el-option", {
                key: item.info,
                attrs: { label: item.info, value: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c("noel", {
            attrs: {
              street: _vm.listQuery.street,
              community: _vm.listQuery.community,
              region: _vm.listQuery.region,
              showFloor: false,
            },
            on: { addrChange: _vm.noelchange },
          }),
          _vm._v(" "),
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: "请输入查询条件" },
            model: {
              value: _vm.listQuery.key,
              callback: function ($$v) {
                _vm.$set(_vm.listQuery, "key", $$v)
              },
              expression: "listQuery.key",
            },
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.listadd },
            },
            [_vm._v(" 查询 ")]
          ),
          _vm._v(" "),
          _vm.hasPerm("lajixiang:insert")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.add },
                },
                [_vm._v(" 添加设备 ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("lajixiang:exportcode")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exporterweima },
                },
                [_vm._v(" 批量导出二维码\n\t\t\t")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPerm("lajixiang:export")
            ? _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.exortlist },
                },
                [_vm._v(" 导出 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "设备号",
              prop: "id",
              sortable: "custom",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.mark))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v("\n\t\t\t、"),
          _c("el-table-column", {
            attrs: { label: "设备类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.typeInfo))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.kitchenRegion
                      ? _c("span", [
                          _vm._v(_vm._s(scope.row.kitchenRegion.street) + " "),
                          scope.row.kitchenRegion.community
                            ? _c("span", [_vm._v("，")])
                            : _vm._e(),
                          _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(scope.row.kitchenRegion.community) +
                                " "
                            ),
                          ]),
                          _vm._v(" ，"),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(scope.row.kitchenRegion.region) +
                                " ， " +
                                _vm._s(scope.row.number)
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "坐标" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.lon) + "," + _vm._s(scope.row.lat)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "具体地址" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.address))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "设备照片" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      scope.row.pic
                        ? _c("img", {
                            staticStyle: { width: "80%" },
                            attrs: {
                              src: "http://pic.touduila.com/" + scope.row.pic,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.imgs(scope.row.pic, 0)
                              },
                            },
                          })
                        : _vm._e(),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "时间", width: "151" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [_vm._v("创建时间")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.createTime))]),
                    _vm._v(" "),
                    _c("div", [_vm._v("更新时间")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(scope.row.updateTime))]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "上报次数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "link-type" }, [
                      _vm._v(_vm._s(scope.row.recordNum)),
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.shangbao(scope.row)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.comment))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "排行榜", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 25
                      ? _c("span", [
                          scope.row.rankingStatus == "1"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v("已启用"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.rankingStatus == "0"
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("禁用"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != 25
                      ? _c("span", [
                          _c(
                            "span",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("—")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "人脸识别", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 25
                      ? _c("span", [
                          scope.row.faceStatus == "1"
                            ? _c("span", { staticStyle: { color: "green" } }, [
                                _vm._v("已启用"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          scope.row.faceStatus == "0"
                            ? _c("span", { staticStyle: { color: "red" } }, [
                                _vm._v("禁用"),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != 25
                      ? _c("span", [
                          _c(
                            "span",
                            { staticStyle: { "text-align": "center" } },
                            [_vm._v("—")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "状态", width: "70" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == "1"
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("已启用"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.status == "0"
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("禁用"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "心跳状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.heartbeat))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "满桶预警" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.numStatus == "正常"
                      ? _c("span", { staticStyle: { color: "green" } }, [
                          _vm._v("正常"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.numStatus == "预警"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "text-decoration": "underline",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getWarnView(scope.row)
                              },
                            },
                          },
                          [_vm._v("预警")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "投递次数" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "link-type",
                        on: {
                          click: function ($event) {
                            return _vm.getProtoDeliverCount(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.addperson))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "是否迁移" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.type == 25
                      ? _c("span", [_vm._v(_vm._s(scope.row.moveDesc))])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.type != 25 ? _c("span", [_vm._v("-")]) : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "220",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("lajixiang:edit")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:code")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.erweima(scope.row)
                              },
                            },
                          },
                          [_vm._v("二维码")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:scoresetting") &&
                    scope.row.type == 25
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.scoreSetting(scope.row)
                              },
                            },
                          },
                          [_vm._v("积分设置")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:toukousetting") &&
                    scope.row.type == 25
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.getMouthpieceList(scope.row)
                              },
                            },
                          },
                          [_vm._v("投口设置")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:sync") &&
                    scope.row.type == 25 &&
                    scope.row.streetId != "" &&
                    scope.row.streetId != null
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.getSync(scope.row)
                              },
                            },
                          },
                          [_vm._v("信息同步到新设备")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:delete")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.danger(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("lajixiang:log")
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { margin: "5px" },
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.viewLog(scope.row)
                              },
                            },
                          },
                          [_vm._v("操作日志")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, title: "添加设备" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                rules: _vm.rules,
                model: _vm.form,
                "label-position": "left",
                id: "dataForm",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "物业" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.property,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "property", $$v)
                      },
                      expression: "form.property",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "小区" } },
                [
                  _c("el-input", {
                    attrs: { type: "input" },
                    model: {
                      value: _vm.form.housing,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "housing", $$v)
                      },
                      expression: "form.housing",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "设备信息", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "datas",
              attrs: {
                model: _vm.forms,
                rules: _vm.rule,
                "label-width": "170px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "设备编码" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.forms.mark,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "mark", $$v)
                      },
                      expression: "forms.mark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设备名称", prop: "name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "name", $$v)
                      },
                      expression: "forms.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设备类型", prop: "type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "200px" },
                      on: {
                        change: function ($event) {
                          return _vm.changeType($event)
                        },
                      },
                      model: {
                        value: _vm.forms.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "type", $$v)
                        },
                        expression: "forms.type",
                      },
                    },
                    _vm._l(_vm.Options, function (item) {
                      return _c("el-option", {
                        key: item.info,
                        attrs: { label: item.info, value: item.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "设备地址" } },
                [
                  _c("addrselect", {
                    attrs: {
                      street: _vm.addr.street,
                      community: _vm.addr.community,
                      region: _vm.addr.region,
                      floor: _vm.addr.number,
                      streetId: _vm.addr.streetId,
                      communityId: _vm.addr.communityId,
                      regionId: _vm.addr.regionId,
                    },
                    on: { addrChange: _vm.addrChange },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "具体地址", prop: "address" } },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.mapsd } },
                    [_vm._v("选择")]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.forms.address))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "经纬度" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "15%" },
                    attrs: { autocomplete: "off", placeholder: "经度" },
                    model: {
                      value: _vm.forms.lon,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "lon", $$v)
                      },
                      expression: "forms.lon",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-input", {
                    staticStyle: { width: "15%" },
                    attrs: { autocomplete: "off", placeholder: "维度" },
                    model: {
                      value: _vm.forms.lat,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "lat", $$v)
                      },
                      expression: "forms.lat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.maps,
                      expression: "maps",
                    },
                  ],
                  staticClass: "amap-wrap",
                },
                [
                  _c("div", { attrs: { id: "pickerBox" } }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.kong,
                          expression: "kong",
                        },
                      ],
                      staticClass: "el-input__inner",
                      attrs: {
                        id: "pickerInput",
                        placeholder: "输入地名进行选点",
                      },
                      domProps: { value: _vm.kong },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.kong = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "poiInfo" } }),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "searchResults" } }),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticStyle: { height: "500px", width: "100%" },
                    attrs: { id: "amapContainer" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "上传照片" } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        "show-file-list": false,
                        "on-success": _vm.uploadImg,
                        "auto-upload": true,
                        action: "/operator/tools/upload",
                        "list-type": "picture-card",
                        name: "photo",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "el-upload-list el-upload-list--picture-card",
                    },
                    [
                      _vm.forms.pic
                        ? _c(
                            "li",
                            { staticClass: "el-upload-list__item is-success" },
                            [
                              _c("img", {
                                staticClass: "el-upload-list__item-thumbnail",
                                attrs: {
                                  src:
                                    "http://pic.touduila.com/" + _vm.forms.pic,
                                  alt: "",
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.forms.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.forms, "comment", $$v)
                      },
                      expression: "forms.comment",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "投口开关时间设置",
                        prop: "mouthpieceTime",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.forms.mouthpieceTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "mouthpieceTime", $$v)
                          },
                          expression: "forms.mouthpieceTime",
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t参数分别为，投口打开时间，停留时间，关门时间。单位（秒）注英文逗号分割“,”\n\t\t\t\t"
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退出登录时间",
                        prop: "logoutBackSecond",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入数字，单位秒",
                          type: "number",
                        },
                        model: {
                          value: _vm.forms.logoutBackSecond,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "logoutBackSecond", $$v)
                          },
                          expression: "forms.logoutBackSecond",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "主页返回广告页时间",
                        prop: "mainpageBackSecond",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入数字，单位秒",
                          type: "number",
                        },
                        model: {
                          value: _vm.forms.mainpageBackSecond,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "mainpageBackSecond", $$v)
                          },
                          expression: "forms.mainpageBackSecond",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "人脸识别页面返回时间",
                        prop: "faceBackSecond",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: {
                          autocomplete: "off",
                          placeholder: "请输入数字，单位秒",
                          type: "number",
                        },
                        model: {
                          value: _vm.forms.faceBackSecond,
                          callback: function ($$v) {
                            _vm.$set(_vm.forms, "faceBackSecond", $$v)
                          },
                          expression: "forms.faceBackSecond",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    { attrs: { label: "人脸识别状态", prop: "faceStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            placeholder: "请选择人脸识别状态",
                          },
                          model: {
                            value: _vm.forms.faceStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "faceStatus", $$v)
                            },
                            expression: "forms.faceStatus",
                          },
                        },
                        _vm._l(_vm.listsd, function (obj) {
                          return _c("el-option", {
                            key: obj.type,
                            attrs: { label: obj.info, value: obj.type },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.forms.type == 25
                ? _c(
                    "el-form-item",
                    { attrs: { label: "排行榜状态", prop: "rankingStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            "allow-create": "",
                            placeholder: "请选择排行榜状态",
                          },
                          model: {
                            value: _vm.forms.rankingStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.forms, "rankingStatus", $$v)
                            },
                            expression: "forms.rankingStatus",
                          },
                        },
                        _vm._l(_vm.listsd, function (obj) {
                          return _c("el-option", {
                            key: obj.type,
                            attrs: { label: obj.info, value: obj.type },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态", prop: "status" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        "allow-create": "",
                        placeholder: "请选择状态",
                      },
                      model: {
                        value: _vm.forms.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.forms, "status", $$v)
                        },
                        expression: "forms.status",
                      },
                    },
                    _vm._l(_vm.listsd, function (obj) {
                      return _c("el-option", {
                        key: obj.type,
                        attrs: { label: obj.info, value: obj.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.Sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "上报次数", visible: _vm.dialog },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "line-height": "100px",
                "font-size": "26px",
              },
            },
            [_vm._v(_vm._s(_vm.xinxi.name) + "上报记录")]
          ),
          _vm._v(" "),
          _vm.xinxi.kitchenRegion
            ? _c(
                "div",
                { staticStyle: { "text-align": "left", "font-size": "20px" } },
                [
                  _vm._v(
                    _vm._s(_vm.xinxi.kitchenRegion.street) +
                      " -\n\t\t\t\t" +
                      _vm._s(_vm.xinxi.kitchenRegion.community) +
                      " - " +
                      _vm._s(_vm.xinxi.kitchenRegion.region)
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoad,
                  expression: "listLoad",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.lists,
                border: "",
                fit: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: _vm.$t("table.id"),
                  prop: "id",
                  sortable: "custom",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.id))])]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上报人", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.recycler.name))]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上报图片", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.pic
                          ? _c("img", {
                              staticStyle: { width: "80%" },
                              attrs: {
                                src: "http://pic.touduila.com/" + scope.row.pic,
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "上报时间", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.updateTime))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加",
            visible: _vm.disdayin,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disdayin = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.dayin()
                },
              },
            },
            [_vm._v("打印")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "success", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.xiazai()
                },
              },
            },
            [_vm._v("下载")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "积分设置",
            visible: _vm.disscore,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disscore = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "div",
                { staticClass: "filter-container" },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.ss.scoreSetting,
                        callback: function ($$v) {
                          _vm.$set(_vm.ss, "scoreSetting", $$v)
                        },
                        expression: "ss.scoreSetting",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("使用系统默认数据"),
                      ]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("单独配置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listScoreLoading,
                      expression: "listScoreLoading",
                    },
                  ],
                  key: _vm.tableKeyScore,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listScore,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", align: "center", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [_c("span", [_vm._v(_vm._s($index + 1))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投递类型", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.typeInfo))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    autocomplete: "off",
                                    disabled: _vm.disabled,
                                    type: "number",
                                  },
                                  model: {
                                    value: row.weight,
                                    callback: function ($$v) {
                                      _vm.$set(row, "weight", $$v)
                                    },
                                    expression: "row.weight",
                                  },
                                }),
                                _vm._v(" "),
                                $index + 1 === 1
                                  ? _c("span", [
                                      _vm._v(
                                        "投递重量限制（单位：克）。填写至少多少克记为有效投递，0表示开门就算有效投递"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                $index + 1 === 2
                                  ? _c("span", [
                                      _vm._v(
                                        "投递重量限制（单位：克）。填写至少多少克记为有效投递，0表示开门就算有效投递"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                $index + 1 === 3
                                  ? _c("span", [
                                      _vm._v(
                                        "投递重量（单位：克）。1000 相当于计量单位"
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                $index + 1 === 4
                                  ? _c("span", [
                                      _vm._v(
                                        "投递重量（单位：克）。1000 相当于计量单位"
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    autocomplete: "off",
                                    disabled: _vm.disabled,
                                    type: "number",
                                  },
                                  model: {
                                    value: row.score,
                                    callback: function ($$v) {
                                      _vm.$set(row, "score", $$v)
                                    },
                                    expression: "row.score",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            $index + 1 === 1
                              ? _c("span", [
                                  _vm._v("单次奖励积分设置。填写0或正整数"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 2
                              ? _c("span", [
                                  _vm._v("单次奖励积分设置。填写0或正整数"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 3
                              ? _c("span", [
                                  _vm._v(
                                    "奖励积分设置（分）。投递1000克，获得多少积分"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 4
                              ? _c("span", [
                                  _vm._v(
                                    "奖励积分设置（分）。投递1000克，获得多少积分"
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "每日获得积分限制" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [
                            _c(
                              "span",
                              [
                                _c("el-input", {
                                  attrs: {
                                    autocomplete: "off",
                                    disabled: _vm.disabled,
                                    type: "number",
                                  },
                                  model: {
                                    value: row.num,
                                    callback: function ($$v) {
                                      _vm.$set(row, "num", $$v)
                                    },
                                    expression: "row.num",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            $index + 1 === 1
                              ? _c("span", [
                                  _vm._v("0表示不限制，1表示每日只获得1次奖励"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 2
                              ? _c("span", [
                                  _vm._v("0表示不限制，1表示每日只获得1次奖励"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 3
                              ? _c("span", [
                                  _vm._v("每日最多能获取积分的最大值。"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            $index + 1 === 4
                              ? _c("span", [
                                  _vm._v("每日最多能获取积分的最大值。"),
                                ])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer_jifen",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.cancle } }, [
                    _vm._v("取 消"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.SureScore },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "满桶预警列表",
            visible: _vm.diswarning,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.diswarning = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listWarningLoading,
                      expression: "listWarningLoading",
                    },
                  ],
                  key: _vm.tableKeyWarning,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listWarning,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "满桶预警列表", width: "800" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "baobiaowrap" },
                              _vm._l(scope.row.list, function (item) {
                                return _c("ul", { staticClass: "baobiao" }, [
                                  _c(
                                    "li",
                                    { staticStyle: { width: "150px" } },
                                    [_vm._v(_vm._s(item.typeInfo))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "150px" } },
                                    [_vm._v(_vm._s(item.weight) + "千克")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          item.status == 0 ? "正常" : "异常"
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "投递次数报表",
            visible: _vm.disreport,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.disreport = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listReportLoading,
                      expression: "listReportLoading",
                    },
                  ],
                  key: _vm.tableKeyReport,
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listReport,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "当前设备的投递使用情况", width: "800" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "baobiaowrap" },
                              [
                                _c("ul", { staticClass: "baobiao" }, [
                                  _c(
                                    "li",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v("统计周期\\类别")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v("本周")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v("本月")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v("上月")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { staticStyle: { width: "200px" } },
                                    [_vm._v("本季度")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(scope.row.list, function (item) {
                                  return _c("ul", { staticClass: "baobiao" }, [
                                    _c("li", [_vm._v(_vm._s(item.category))]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v(_vm._s(item.thisWeek))]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v(_vm._s(item.thisMonth))]),
                                    _vm._v(" "),
                                    _c("li", [_vm._v(_vm._s(item.lastMonth))]),
                                    _vm._v(" "),
                                    _c("li", [
                                      _vm._v(_vm._s(item.thisQuarter)),
                                    ]),
                                  ])
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "接口参数配置",
            visible: _vm.dismouthpiece,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dismouthpiece = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "filter-container" },
            [
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.addMouthpiece },
                },
                [_vm._v(" 添加一条 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.copyMouthpiece },
                },
                [_vm._v(" 复制一条 ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [{ name: "waves", rawName: "v-waves" }],
                  staticClass: "filter-item",
                  attrs: { type: "primary" },
                  on: { click: _vm.deleteMouthpiece },
                },
                [_vm._v(" 重置")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-container" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.listMouthpieceLoading,
                      expression: "listMouthpieceLoading",
                    },
                  ],
                  key: _vm.tableKeyMouthpiece,
                  ref: "listMouthpieceRef",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.listMouthpiece,
                    border: "",
                    fit: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", align: "center", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          var $index = ref.$index
                          return [_c("span", [_vm._v(_vm._s($index + 1))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投口", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [_c("span", [_vm._v(_vm._s(row.num))])]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投递类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "120px" },
                                on: {
                                  change: function ($event) {
                                    return _vm.typesChange(row)
                                  },
                                },
                                model: {
                                  value: row.type,
                                  callback: function ($$v) {
                                    _vm.$set(row, "type", $$v)
                                  },
                                  expression: "row.type",
                                },
                              },
                              _vm._l(_vm.typesOptions, function (item) {
                                return _c("el-option", {
                                  key: item.type,
                                  attrs: {
                                    label: item.typeInfo,
                                    value: item.type,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "是否验证" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-select",
                              {
                                staticClass: "filter-item",
                                staticStyle: { width: "155px" },
                                model: {
                                  value: row.verifyUserStatus,
                                  callback: function ($$v) {
                                    _vm.$set(row, "verifyUserStatus", $$v)
                                  },
                                  expression: "row.verifyUserStatus",
                                },
                              },
                              _vm._l(_vm.verifyUserOptions, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "background-color": "#169BD5",
                                  color: "white",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.addDeviceTimeDialog(row)
                                  },
                                },
                              },
                              [_vm._v("添加定时投递")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "投递时间" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return _vm._l(row.time, function (element, i) {
                            return _c("span", [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(element.begTime) +
                                  "-" +
                                  _vm._s(element.endTime) +
                                  " "
                              ),
                              _c("img", {
                                staticStyle: { width: "15px", height: "15px" },
                                attrs: {
                                  src: "http://pic.touduila.com/FuHWSkXgBb9j0DBpi_PRZ5uc8M58",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.delMouthpiecetimeConfirm(
                                      row.time,
                                      i
                                    )
                                  },
                                },
                              }),
                            ])
                          })
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer_jifen",
                  staticStyle: { "margin-right": "10px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.mouthpieceConfirm },
                    },
                    [_vm._v("提 交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "添加投口投递时间", visible: _vm.dismouthpiecetime },
          on: {
            "update:visible": function ($event) {
              _vm.dismouthpiecetime = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.mouthpiecetimeforms } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "投口投递时间设置" } },
                [
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "任意时间点",
                    },
                    model: {
                      value: _vm.mouthpiecetimeforms.begTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mouthpiecetimeforms, "begTime", $$v)
                      },
                      expression: "mouthpiecetimeforms.begTime",
                    },
                  }),
                  _vm._v("\n\t\t\t\t\t至\n\t\t\t\t\t"),
                  _c("el-time-picker", {
                    attrs: {
                      "value-format": "HH:mm",
                      format: "HH:mm",
                      placeholder: "任意时间点",
                    },
                    model: {
                      value: _vm.mouthpiecetimeforms.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.mouthpiecetimeforms, "endTime", $$v)
                      },
                      expression: "mouthpiecetimeforms.endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dismouthpiecetime = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addMouthpiecetimeConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "信息同步到新设备", visible: _vm.disSync },
          on: {
            "update:visible": function ($event) {
              _vm.disSync = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.syncforms } },
            [
              _c("div", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(
                  "注意：请确认新的设备编号，输入错误会影响投递数据、以及设备使用"
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticClass: "filter-item",
                    staticStyle: { width: "200px" },
                    attrs: { placeholder: "请输入新设备编码" },
                    model: {
                      value: _vm.syncforms.newMbId,
                      callback: function ($$v) {
                        _vm.$set(_vm.syncforms, "newMbId", $$v)
                      },
                      expression: "syncforms.newMbId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.disSync = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addSyncConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { title: "设备图片", visible: _vm.devicedialog },
          on: {
            "update:visible": function ($event) {
              _vm.devicedialog = $event
            },
          },
        },
        [
          _c(
            "el-carousel",
            {
              ref: "carousel",
              attrs: {
                "indicator-position": "outside",
                height: _vm.dataHeight,
                autoplay: false,
              },
            },
            _vm._l(_vm.images, function (item, index) {
              return _c(
                "el-carousel-item",
                { key: index, attrs: { name: "index" } },
                [
                  _c("img", {
                    staticStyle: { width: "100%", height: "100%" },
                    attrs: { src: "http://pic.touduila.com/" + item, alt: "" },
                  }),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }