var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c("div", [
      _c(
        "div",
        {
          staticClass: "block",
          on: {
            click: function ($event) {
              return _vm.viewFoward("deviceqr")
            },
          },
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "block_detail2" }, [_vm._v("小程序二维码")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "block",
          on: {
            click: function ($event) {
              return _vm.viewFoward("deviceadv")
            },
          },
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "block_detail2" }, [_vm._v("广告页设置")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "block",
          on: {
            click: function ($event) {
              return _vm.viewFoward("devicebanner")
            },
          },
        },
        [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "block_detail2" }, [_vm._v("banner设置")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", [
      _c(
        "div",
        {
          staticClass: "block",
          on: {
            click: function ($event) {
              return _vm.viewFoward("devicequestion")
            },
          },
        },
        [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "block_detail2" }, [
            _vm._v("设备问题联系电话"),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.roleName == "admin"
        ? _c(
            "div",
            {
              staticClass: "block",
              on: {
                click: function ($event) {
                  return _vm.viewFoward("record")
                },
              },
            },
            [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "block_detail2" }, [_vm._v("积分规则")]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.roleName == "admin"
        ? _c(
            "div",
            {
              staticClass: "block",
              on: {
                click: function ($event) {
                  return _vm.viewFoward("deviceaccount")
                },
              },
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "block_detail2" }, [
                _vm._v("硬件app账号管理"),
              ]),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/FrmWkvhNJgkjBad2gNm5GfgKeRQw" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/Fo9yNqvgjKU0qgD7FCyd5bR-R77d" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/Fo9yNqvgjKU0qgD7FCyd5bR-R77d" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/FmdKHk9Asb1zaCQl8X8e56IjzfP9" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/FhtLlW5uWVJL9DGJW4GTP6GrTW1o" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "block_detail1" }, [
      _c("img", {
        attrs: { src: "http://pic.touduila.com/FgxTuwxmY8we0Rotzl30AzJqc66b" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }