//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Service from '@/api/sys';
export default {
  name: '智能厢房配置',
  directives: {},
  props: {},
  filters: {},
  data: function data() {
    return {
      formLabelWidth: '120px',
      roleName: ""
    };
  },
  watch: {},
  created: function created() {
    this.getUserDataInfo();
  },
  methods: {
    viewFoward: function viewFoward(path) {
      this.$router.push({
        path: path
      });
    },
    getUserDataInfo: function getUserDataInfo() {
      var _this = this;

      Service.getUserRoleInfo().then(function (res) {
        _this.roleName = res.data.data.roleName; // console.log(this.roleName);
      });
    }
  }
};