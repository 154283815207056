"use strict";

var _interopRequireDefault = require("D:/workspace/toudui-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.number.constructor");

var _dizhi = _interopRequireDefault(require("@/api/dizhi"));

//
//
//
//
//
//
var _default = {
  name: 'Jiedao',
  props: {
    value: [String, Number, Object]
  },
  data: function data() {
    return {
      // 社区列表
      streetList: []
    };
  },
  computed: {
    selectVal: {
      get: function get() {
        if (this.value) {
          return this.value;
        } else {
          return null;
        }
      },
      set: function set(val) {
        this.$emit('input', val.street);
      }
    }
  },
  created: function created() {
    this.getJiedao();
  },
  methods: {
    // 获取街道
    getJiedao: function getJiedao() {
      var _this = this;

      _dizhi.default.streetList().then(function (res) {
        _this.streetList = res.data;
      });
    }
  }
};
exports.default = _default;