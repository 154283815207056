var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _vm.hasPerm("ruleset:insert")
            ? _c(
                "el-button",
                {
                  staticClass: "filter-item",
                  staticStyle: { "margin-left": "10px" },
                  attrs: { waves: "", type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.add },
                },
                [_vm._v("\n\t\t\t添加场景位置\n\t\t")]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticStyle: { margin: "auto" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "ID", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return [_c("span", [_vm._v(_vm._s($index + 1))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "场景位置" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.position))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "FTP" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.fileDir))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "识别用途" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.cameraUsage == 1
                      ? _c("span", [_vm._v("乱丢包")])
                      : _vm._e(),
                    _vm._v(" "),
                    scope.row.cameraUsage == 2
                      ? _c("span", [_vm._v("混乱投放")])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "街道" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.street))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "负责人" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm.hasPerm("ruleset:edit")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasPerm("ruleset:delete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "danger", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.del(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.currentPage,
          limit: _vm.listQuery.numOfPage,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "currentPage", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "numOfPage", $event)
          },
          pagination: _vm.getList,
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加/编辑",
            visible: _vm.dialogAddOrUpdateFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrUpdateFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "FTP目录",
                    "label-width": _vm.formLabelWidth,
                    prop: "fileDir",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.fileDir,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "fileDir", $$v)
                      },
                      expression: "form.fileDir",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "识别用途",
                    "label-width": _vm.formLabelWidth,
                    prop: "cameraUsage",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.form.cameraUsage,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "cameraUsage", $$v)
                        },
                        expression: "form.cameraUsage",
                      },
                    },
                    _vm._l(_vm.yongtu, function (i) {
                      return _c("el-option", {
                        key: i.val,
                        attrs: { label: i.label, value: i.val },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "场景位置",
                    "label-width": _vm.formLabelWidth,
                    prop: "position",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.position,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "position", $$v)
                      },
                      expression: "form.position",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "街道",
                    "label-width": _vm.formLabelWidth,
                    prop: "street",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择街道" },
                      model: {
                        value: _vm.form.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "street", $$v)
                        },
                        expression: "form.street",
                      },
                    },
                    _vm._l(_vm.streets, function (i) {
                      return _c("el-option", {
                        attrs: { label: i.street, value: i.street },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "负责人",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "" },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    },
                    _vm._l(_vm.names, function (i) {
                      return _c("el-option", { attrs: { label: i, value: i } })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "识别类型标签",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "app-container" },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          staticStyle: { width: "90%" },
                          attrs: { data: _vm.tableTags, "show-header": false },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c(
                                      "el-select",
                                      {
                                        staticClass: "filter-item",
                                        staticStyle: { width: "150px" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.tagChange(row)
                                          },
                                        },
                                        model: {
                                          value: row.id,
                                          callback: function ($$v) {
                                            _vm.$set(row, "id", $$v)
                                          },
                                          expression: "row.id",
                                        },
                                      },
                                      _vm._l(_vm.tagList, function (item) {
                                        return _c("el-option", {
                                          key: item.id,
                                          attrs: {
                                            label: item.label,
                                            value: item.id,
                                          },
                                        })
                                      }),
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  return [
                                    _c("el-input", {
                                      staticStyle: { width: "130px" },
                                      attrs: {
                                        autocomplete: "off",
                                        type: "number",
                                        placeholder: "请输入相似度",
                                      },
                                      model: {
                                        value: row.sim,
                                        callback: function ($$v) {
                                          _vm.$set(row, "sim", $$v)
                                        },
                                        expression: "row.sim",
                                      },
                                    }),
                                    _vm._v("%\n\t\t\t\t\t\t\t"),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: { label: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm.hasPerm("ruleset:delete")
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "danger",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.deleteTags(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      directives: [{ name: "waves", rawName: "v-waves" }],
                      staticClass: "filter-item",
                      attrs: { type: "primary", icon: "el-icon-plus" },
                      on: { click: _vm.addTags },
                    },
                    [_vm._v(" 添加一条 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogAddOrUpdateFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.sure } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }